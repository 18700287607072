<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="theme-common">
      <a-card :bordered="false">
        <template #title>
          <div class="cardHead">
            <node-index-outlined/>
            <span style="position: relative; top: 4px; left: 5px">{{
                threshold.sensorPlace
              }}</span>
          </div>
        </template>
        <template #extra>
          <!-- <a-radio-group size="default"> -->
          <!-- <a-radio-button value="c" @click="edit" v-if="!isEdit"
              >编辑</a-radio-button
            >
            <a-radio-button value="c2" @click="qX" v-if="isEdit"
              >取消</a-radio-button
            >
            <a-radio-button value="c1" @click="editSave" v-if="isEdit"
              >保存</a-radio-button
            >
          </a-radio-group> -->

          <a-radio-group size="default">
            <a-radio-button
                value="b"
                v-if="formState.activeKey == 'Component'"
                @click="add"
            >{{ langObj['新增'] }}
            </a-radio-button
            >
            <a-radio-button value="c" v-if="!isEdit" @click="copy"
            >{{ langObj['复制'] }}
            </a-radio-button
            >
            <a-radio-button value="c" v-if="!isEdit" @click="pasteOverlay"
            >{{ langObj['粘贴覆盖'] }}
            </a-radio-button
            >
            <a-radio-button
                value="d"
                @click="pasteAppend"
                v-if="formState.activeKey == 'Component'"
            >{{ langObj['粘贴追加'] }}
            </a-radio-button
            >
            <a-radio-button value="c" v-if="isEdit" @click="qX"
            >{{ langObj['取消'] }}
            </a-radio-button
            >
            <a-radio-button value="c" v-if="!isEdit" :disabled="!(baseModelSource&&baseModelSource.length)"
                            @click="edit"
            >{{ langObj['编辑'] }}
            </a-radio-button
            >
            <a-radio-button value="c" v-if="isEdit" @click="editSave"
            >{{ langObj['保存'] }}
            </a-radio-button
            >
            <!-- <a-radio-button
              value="d"
              @click="copy"
              v-if="formState.activeKey == 'Component'"
              >复制</a-radio-button
            >
            <a-radio-button
              value="d"
              @click="pasteOverlay"
              v-if="formState.activeKey == 'Component'"
              >粘贴</a-radio-button
            > -->
            <!-- <a-radio-button
              value="d"
              @click="pasteAppend"
              v-if="formState.activeKey == 'Component'"
              >粘贴追加</a-radio-button
            > -->
            <!-- <a-radio-button
                value="d"
                @click="deleteThis"
                v-if="formState.activeKey == 'Component'"
            >{{ langObj['删除'] }}
            </a-radio-button> -->

            <a-popconfirm
              :title="langObj['确定删除所选数据么']+'?'"
              :ok-text="langObj['确定']"
              :cancel-text="langObj['取消']"
              @confirm="deleteThis()"
            >
              <a-radio-button  v-if="formState.activeKey == 'Component'" value="d">{{langObj['删除']}}</a-radio-button>
            </a-popconfirm>

          </a-radio-group>
        </template>
      </a-card>
      <a-tabs v-model:activeKey="formState.activeKey" @change="tabsChange">
        <a-tab-pane key="Common" :tab="langObj['振动参数']" :disabled="isEdit"></a-tab-pane>
        <a-tab-pane
            key="Component"
            :tab="langObj['部件参数']"
            force-render
            :disabled="isEdit"
        ></a-tab-pane>
      </a-tabs>
      <div class="main" style="max-height: 1450px; overflow-y: scroll">
        <a-table
            bordered
            :emptyText="'暂无数据'"
            :columns="columns"
            :data-source="baseModelSource"
            :pagination="false"
            :row-selection="{
          selectedRowKeys: mainSelectedRowKeys,
          onChange: onMainSelectChange,
        }"
            @change="onMainSelectChange"
        >
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'code'">
              <a-tag color="green" v-if="!isEdit">
                {{ record.code }}:{{ record.paramValue }}
              </a-tag>
              <div v-else>
                <a-form :model="record">
                  <a-form-item
                      :label="record.code"
                      name="paramValue"
                      :rules="[{ required: record.required, message: langObj['请输入']+record.code }]">
                    <a-radio-group
                        v-model:value="record.paramValue"
                        v-if="record.dataType == 'radio'">
                      <a-radio
                          :value="item"
                          v-for="item in record.examples"
                          :key="item"
                      >{{ item }}
                      </a-radio
                      >
                    </a-radio-group>
                    <a-input-number
                        v-else-if="record.dataType == 'int'"
                        v-model:value="record.paramValue"
                        :placeholder="langObj['请输入']"
                        :step="1"/>
                    <a-input-number
                        v-else-if="record.dataType == 'float'"
                        v-model:value="record.paramValue"
                        :placeholder="langObj['请输入']"
                        :step="0.01"/>
                    <a-input
                        v-else
                        type="text"
                        v-model:value="record.paramValue"
                        :placeholder="langObj['请输入']"/>
                  </a-form-item>
                </a-form>
              </div>
            </template>
          </template>
        </a-table>
      </div>
      <a-modal
          :width="1000"
          :maskClosable="false"
          v-model:visible="modalState.visible"
          :title="modalState.title"
          :confirm-loading="modalState.confirmLoading"
          @ok="handleOk"
      >
        <div style="max-height: 600px; overflow-y: scroll">
          <a-table class="modal-table"
              bordered
              :columns="columnsZB"
              :pagination="false"
              :data-source="baseMData"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'params'">
                <a-tag
                    color="#2db7f5"
                    v-for="(item, index) in text"
                    :key="index"
                >{{ item.name }}-{{ item.explan }}
                </a-tag
                >
              </template>
              <template v-if="column.dataIndex === 'max_num'">
                <a-radio-group v-model:value="record.count" name="radioGroup">
                  <a-radio value="0">0</a-radio>
                  <a-radio :value="it" v-for="(it,index2) in record.max_num" :key="index2">{{ it }}</a-radio>
                </a-radio-group>
              </template>
            </template>
          </a-table>
        </div>


      </a-modal>
    </div>
  </a-config-provider>
</template>
<script lang="ts">
import request from "../../../common/request";
import { defineComponent, PropType } from "vue";
import { ref, reactive, computed, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { langList } from "@/common/lang";


/**操作日志记录 */
export default defineComponent({
  props: {
    threshold: {
      type: Object,
      default: () => ({}),
    },
    deviceId: {
      type: String,
      default: "",
    },
    baseModel: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    watch(() => props.threshold, (newVal, oldVal) => {
      baseModelSource.value = newVal.deviceParams[
          formState.activeKey
          ].map((t: any) => ({ ...t, key: t.code || t.paramName }));

    })
    let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
      language.value = localStorage.getItem('language') || 'Chinese'
      langObj.value = langList[language.value]
      console.log(language.value, "language.value ")
    }
    getLang()
    const isEdit = ref(false);
    const sensorsSource = ref([]);
    const dataSource = ref([]);
    const pagination = computed(() => ({
      total: 0,
      current: 1,
      pageSize: 10,
    }));
    const modalState = ref({
      visible: false,
      title: langObj.value["新增"],
      confirmLoading: false,
      roleName: "",
      meun: [],
    });

    const formState = reactive({
      activeKey: "Common",
    });
    const columns = [
      {
        title: langObj.value["部件类型"],
        dataIndex: "typeName",
      },
      {
        title: langObj.value["参数释义"],
        dataIndex: "paramExplan",
      },
      {
        title: langObj.value["配置参数"],
        dataIndex: "code",
      },
    ];
    const columnsZB = [
      {
        title: langObj.value["部件类型"],
        dataIndex: "type_name",
      },
      {
        title: langObj.value["参数释义"],
        dataIndex: "params",
      },
      {
        title: langObj.value["备注"],
        dataIndex: "remark",
      },
      {
        title: langObj.value["数量"],
        dataIndex: "max_num",
      },
    ];

    // 编辑
    const edit = () => {
      isEdit.value = true;
    };
    const editSave = () => {
      var param: any = {
        machineId: props.deviceId,
        sensorId: props.threshold.sensorId,
        codes: [],
        type: formState.activeKey,
      };
      var flag = true;
      var msg = "";
      for (var i = 0; i < baseModelSource.value.length; i++) {
        var item: any = baseModelSource.value[i];
        param.codes.push(item.code);
        if (item.required && !item.paramValue) {
          msg = "参数：" + (item.paramName || item.parentCode) + "未输入";
          flag = false;
          break;
        }
        param[item.code || item.paramName || item.parentCode] = item.paramValue;
      }
      if (!flag) {
        message.warning(msg);
        return;
      }
      console.log(JSON.stringify(param));
      const config = {
        headers: {
          requestId: uuidv4(),
        },
      };
      request.put("/api/machines/parameter", param, config).then((res) => {
        if (res) {
          operateLogs('保存参数配置，传感器：' + props.threshold.sensorPlace);
          message.success(langObj.value["操作成功"]);
          isEdit.value = false;
          emit("search");
          // search();
        }
      });
    };
    const qX = () => {
      emit("search");
      isEdit.value = false;
    };
    const handleTableChange = (val: any) => {};

    let baseModelSource = ref<any>();
    const tabsChange = () => {
      baseModelSource.value = props.threshold.deviceParams[
          formState.activeKey
          ].map((t: any) => ({ ...t, key: t.code || t.paramName }));
    };
    baseModelSource.value = props.threshold.deviceParams[
        formState.activeKey
        ].map((t: any) => ({ ...t, key: t.code || t.paramName }));

    const copyInfo = ref();
    const copySensorId = ref();
    const copyType = ref();

    const mainSelectedRowKeys = ref<any>();
    const mainSelectedRows = ref<any>();
    const onMainSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys2);
      console.log("selectedRows changed: ", selectedRows2);
      mainSelectedRowKeys.value = selectedRowKeys2;
      mainSelectedRows.value = selectedRows2;
    };
    // 复制
    const copy = () => {
      if (!mainSelectedRows.value || !mainSelectedRows.value.length) {
        message.warning(langObj.value["请选择复制的内容"]);
        return;
      }
      localStorage.setItem("copyType2", formState.activeKey);
      localStorage.setItem("sensorId2", props.threshold.sensorId);
      localStorage.setItem("copyRows2", JSON.stringify(mainSelectedRows.value));
      message.success(langObj.value["复制成功"]);
    };
    // 粘贴覆盖
    const pasteOverlay = () => {
      if (localStorage.getItem("copyRows2")) {
        copyInfo.value = JSON.parse(localStorage.getItem("copyRows2") || "");
        copySensorId.value = localStorage.getItem("sensorId2");
        copyType.value = localStorage.getItem("copyType2");
        if (copySensorId.value == props.threshold.sensorId) {
          message.warning(langObj.value["不允许粘贴"]);
          return;
        }
        baseModelSource.value = baseModelSource.value.concat(copyInfo.value);
        baseModelSource.value = Array.from(
            baseModelSource.value
                .reduce((m: any, t: any) => m.set(t.code, t), new Map())
                .values()
        );
        message.success(langObj.value["粘贴成功"]);
        isEdit.value = true;
      } else {
        message.warning(langObj.value["没有复制的内容"]);
        return;
      }
    };
    // 粘贴追加
    const pasteAppend = () => {
      if (localStorage.getItem("copyRows2")) {
        copyInfo.value = JSON.parse(localStorage.getItem("copyRows2") || "");
        copySensorId.value = localStorage.getItem("sensorId2");
        copyType.value = localStorage.getItem("copyType2");
        if (copySensorId.value == props.threshold.sensorId) {
          message.warning(langObj.value["不允许粘贴"]);
          return;
        }
        dataSource.value = copyInfo.value.concat(dataSource.value);
        dataSource.value = Array.from(
            dataSource.value
                .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
                .values()
        );
        message.success(langObj.value["粘贴成功"]);
        isEdit.value = true;
      } else {
        message.warning(langObj.value["没有复制的内容"]);
        return;
      }
    };
    // 删除
    const deleteThis = () => {
      if (!mainSelectedRows.value || !mainSelectedRows.value.length) {
        message.warning(langObj.value["请选择内容"]);
        return;
      }
      var param: any = {
        sensorId: props.threshold.sensorId,
        type: formState.activeKey,
        params: [],
        codes: [],
      };
      for (var i = 0; i < mainSelectedRows.value.length; i++) {
        var item: any = mainSelectedRows.value[i];
        param.codes.push(item.code);
        param.params.push(item.paramName);
      }
      console.log(JSON.stringify(param));
      const config = {
        params: param,
        data: param,
        headers: {
          requestId: uuidv4(),
        },
      };
      request.delete("/api/machines/parameter", config).then((res) => {
        if (res) {
          message.success(langObj.value["操作成功"]);
          isEdit.value = false;
          emit("search");
        }
      });
    };
    // 新增
    const add = () => {
      var data = JSON.parse(JSON.stringify(props.baseModel))
      data.forEach((ic: any) => {
        ic.count = 0
      })
      baseMData.value = data
      selectedRowKeys.value = [];
      modalState.value.visible = true;
    };
    const selectedRowKeys = ref<any>();
    const selectedRows = ref<any>();
    const onSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
      console.log("selectedRowKeys changed: ", selectedRowKeys2);
      console.log("selectedRows changed: ", selectedRows2);
      selectedRowKeys.value = selectedRowKeys2;
      selectedRows.value = selectedRows2;
    };

    let baseMData = ref<any>()

    // 添加确定
    const handleOk = () => {
      var selectData = []
      for (var j = 0; j < baseMData.value.length; j++) {
        var element = baseMData.value[j]
        if (Number(element.count) > 0) {
          for (var i = 0; i < Number(element.count); i++) {
            var c = ""
            if (i > 0) {
              c = (i + 1).toString()
            }
            for (var t = 0; t < element.params.length; t++) {
              var element2 = element.params[t]
              selectData.push({
                examples: element2?.examples,
                code: element2.name + c,
                dataType: element2.type,
                paramExplan: element2.explan,
                paramName: element2.name,
                parentCode: element.code,
                required: element2.required,
                type: "Component",
                typeName: element.type_name + c,
              })
            }
          }
        }
      }
      baseModelSource.value = selectData.concat(baseModelSource.value);
      baseModelSource.value = Array.from(
          baseModelSource.value
              .reduce((m: any, t: any) => m.set(t.code, t), new Map())
              .values()
      );
      modalState.value.visible = false;
      isEdit.value = true;
    };

    return {
      baseMData,
      columnsZB,
      selectedRowKeys,
      selectedRows,
      onSelectChange,
      add,
      modalState,
      onMainSelectChange,
      mainSelectedRows,
      deleteThis,
      pasteAppend,
      pasteOverlay,
      copy,
      mainSelectedRowKeys,
      sensorsSource,
      dataSource,
      formState,
      columns,
      handleOk,
      handleTableChange,
      edit,
      pagination,
      tabsChange,
      baseModelSource,
      isEdit,
      qX,
      editSave,
      langObj,
      language
    };
  },
});
</script>
<style scoped>
/deep/ .ant-card-head {
  padding-left: 0;
}

.cardHead {
  color: #1890ff;
  /* border-left:3px solid  #1890ff; */
  padding-left: 0;
  font-size: 18px;
}

.main {
  margin-top: 30px;
}
</style>
