import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"color":"#fff"} }
const _hoisted_2 = { class: "w-full flex" }
const _hoisted_3 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_4 = { class: "w-full flex items-center" }
const _hoisted_5 = { style: {"font-size":"20px"} }
const _hoisted_6 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_7 = { class: "ml-2" }
const _hoisted_8 = {
  class: "mt-4",
  style: {"font-size":"20px"}
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  class: "opt6",
  style: {"margin-top":"8px","color":"#fff"}
}
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "w-full flex justify-center" }

import { onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { langList } from "@/common/lang";
import { getGroupName, transformDate } from "@/common/tools";
import { message } from "ant-design-vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'add-repair',
  props: {
    warningList: {}
  },
  emits: ['success', 'close'],
  setup(__props: any, { emit: __emit }) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

let conditionList = [
  { label: '健康', value: 0, color: '#61c08f' },
  { label: '可用', value: 1, color: '#d2de49' },
  { label: '可用', value: 2, color: '#d2de49' },
  { label: '警戒', value: 3, color: '#cd5f3b' },
  { label: '警戒', value: 4, color: '#cd5f3b' },
  { label: '警戒', value: 5, color: '#cd5f3b' },
  { label: '故障', value: 6, color: '#921e37' },
  { label: '故障', value: 7, color: '#921e37' },
]

const emit = __emit;
const props = __props

let form: any = ref({
  value: "",
  begin: null,
  end: null,
  type: 2,
  machineName: "",
  machineId: "",
  groupId: "",
  groupName: "",
  factoryId: "",
  condition: null,
  faultCause: "",
});
let warningList = ref<any[]>([]);

let conditionAdd = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 3 },
  { label: "故障", value: 6 },
];
let columns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["故障描述"],
    dataIndex: "diagnosticNotes",
  },
];

watch(() => props.warningList, (newVal) => {
  warningList.value = newVal || [];
}, { immediate: true });
onMounted(() => {
  form.value.maintainPerson = JSON.parse(
      localStorage.getItem("memberInfo") || ""
  )?.nickName;
});

const onClose = () => {
  emit('close')
}

let selectedRowKeys = ref<any[]>([]);
const onSelectChange = (_: any) => {
  //selectedRowKeys.value = ev;
};

let machineId = ref("");
let machineInfo: any;
watch(() => machineId.value, async (newVal) => {
  if (!newVal) {
    machineInfo = null;
    form.value.groupName = "";
    form.value.machineName = "";
    form.value.machineId = "";
    form.value.groupId = "";
    form.value.factoryId = "";
    return;
  }
  let machineRes = await request.get('/api/machines/info?machineId=' + newVal);
  machineInfo = machineRes?.data;
  if (machineInfo) {
    machineInfo.groupName = getGroupName(machineInfo.groupId);
    form.value.groupName = machineInfo.groupName;
    form.value.machineName = machineInfo.machineName;
    form.value.machineId = machineInfo.machineId;
    form.value.groupId = machineInfo.groupId;
    form.value.factoryId = machineInfo.factoryId;
  }
});

const onSelect = (record: any, selected: boolean, selectedRows: any[]) => {
  if (selectedRows.length == 0) {
    machineId.value = "";
  } else if (machineId.value === "") {
    machineId.value = record.machineId;
  }
  if (selected) {
    if (selectedRows.findIndex((t: any) => t.machineId !== record.machineId) > -1) {
      message.warn("请选择同一设备的报警内容");
      return;
    }
    selectedRowKeys.value.push(record.id);
  } else {
    selectedRowKeys.value = selectedRowKeys.value.filter((p) => p !== record.id);
  }
}

const submitAdd = async () => {
  let submit = JSON.parse(JSON.stringify(form.value));
  if (!submit.begin) {
    message.warning(langObj.value["请选择开始时间"]);
    return;
  } else {
    submit.begin = transformDate(submit.begin);
  }

  if (!submit.end) {
    message.warning(langObj.value["请选择结束时间"]);
    return;
  } else {
    submit.end = transformDate(submit.end);
  }

  if (!submit.maintainPerson) {
    message.warning(langObj.value["请填写检修人员"]);
    return;
  }

  if (!submit.condition && submit.condition !== 0) {
    message.warning(langObj.value["请选择现场设备状态"]);
    return;
  }

  if (!submit.faultCause) {
    message.warning(langObj.value["请填写故障原因"]);
    return;
  }

  if (!submit.maintainMessage) {
    message.warning(langObj.value["请填写检修内容"]);
    return;
  }

  let list = warningList.value.filter((p: any) =>
      selectedRowKeys.value.includes(p.id)
  );

  submit.list = list.map((d: any) => ({
    date: d.date,
    condition: d.condition,
    diagnosticNotes: d.diagnosticNotes,
    id: d.id,
  }));

  if (fileList.value && fileList.value.length) {
    submit.pictures = fileList.value.map((p: any) => ({
      url: p.url,
    }));
  } else {
    submit.pictures = [];
  }

  let data: any = {
    repairReport: submit,
    date: submit.end,
    machineIds: [machineId.value],
    factoryId: submit.factoryId,
    pictures: submit.pictures,
    type: 2,
    workflow: true,
  };

  let result = await request.post("/api/reports", data);

  if (result && result.status) {
    message.success(langObj.value["新增成功"]);
    selectedRowKeys.value = []
    machineId.value = "";
    emit('success');
  } else {
    message.warning(langObj.value["操作失败"] + "!");
  }
};


//**图片上传* */
let fileList = ref<any>([]);
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = file.url;
};

let uploadFile = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
      _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: onClose
      }, [
        _createVNode(_unref(CloseOutlined), { style: {"font-size":"20px"} })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(langObj)['添加检修记录']), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(langObj)['故障信息(请勾选此次维修的故障项)']), 1)
    ]),
    _createVNode(_component_a_table, {
      "row-selection": { selectedRowKeys: _unref(selectedRowKeys),  onChange: onSelectChange, onSelect: onSelect },
      columns: _unref(columns),
      "data-source": _unref(warningList),
      pagination: false,
      rowKey: "id",
      style: {"background-color":"#020f46"}
    }, {
      bodyCell: _withCtx(({ column, text }) => [
        (column.dataIndex === 'condition')
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === text)?.label || ""]), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["row-selection", "columns", "data-source"]),
    _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(langObj)['维修详情']), 1),
    _createVNode(_component_a_form, {
      ref: "formRef",
      name: "advanced_search",
      class: "ant-advanced-search-form",
      model: _unref(form),
      "label-col": { style: { width: '180px' } }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, { gutter: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "begin",
                  label: _unref(langObj)['开始时间'],
                  rules: [{ required: true, message: _unref(langObj)['请选择开始时间'] }],
                  allowClear: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_date_picker, {
                      value: _unref(form).begin,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(form).begin) = $event)),
                      "show-time": "",
                      placeholder: _unref(langObj)['请选择时间']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "end",
                  label: _unref(langObj)['结束时间'],
                  rules: [{ required: true, message: _unref(langObj)['请选择结束时间'] }],
                  allowClear: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_date_picker, {
                      value: _unref(form).end,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(form).end) = $event)),
                      "show-time": "",
                      placeholder: _unref(langObj)['请选择时间']
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "value",
                  label: _unref(langObj)['设备名称'],
                  rules: [{ required: true }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      readonly: "",
                      value: _unref(form).machineName,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(form).machineName) = $event)),
                      style: {"width":"210px"}
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "value",
                  label: _unref(langObj)['设备区域'],
                  rules: [{ required: true }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      readonly: "",
                      value: _unref(form).groupName,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(form).groupName) = $event)),
                      style: {"width":"210px"}
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "maintainPerson",
                  label: _unref(langObj)['检修人员'],
                  rules: [{ required: true }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(form).maintainPerson,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(form).maintainPerson) = $event)),
                      style: {"width":"210px"}
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "condition",
                  label: _unref(langObj)['现场设备状态'],
                  rules: [{ required: true }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(form).condition,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(form).condition) = $event)),
                      style: {"width":"210px"},
                      placeholder: _unref(langObj)['请选择现场设备状态'],
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionAdd), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)[item.label]), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "faultCause",
                  label: _unref(langObj)['故障原因'],
                  rules: [{ required: true }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(form).faultCause,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(form).faultCause) = $event)),
                      placeholder: _unref(langObj)['请输入故障原因'],
                      rows: 4
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  name: "maintainMessage",
                  label: _unref(langObj)['检修内容'],
                  rules: [{ required: true }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(form).maintainMessage,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(form).maintainMessage) = $event)),
                      placeholder: _unref(langObj)['请输入检修内容'],
                      rows: 4
                    }, null, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }),
            _createVNode(_component_a_col, { span: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  style: {"margin-left":"10px"},
                  name: "value",
                  label: _unref(langObj)['检修图片']
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_upload, {
                      "list-type": "picture-card",
                      onPreview: handlePreview,
                      "custom-request": _unref(uploadFile),
                      "auto-upload": false,
                      accept: "image",
                      "max-count": 9
                    }, {
                      default: _withCtx(() => [
                        (!_unref(fileList) || _unref(fileList).length < 9)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_unref(PlusOutlined), {
                                class: "opt6",
                                style: {"color":"#fff"}
                              }),
                              _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(langObj)['上传']), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["custom-request"]),
                    _createVNode(_component_a_modal, {
                      visible: previewVisible.value,
                      title: previewTitle.value,
                      footer: null,
                      onCancel: handleCancel
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("img", {
                          alt: "example",
                          style: {"width":"100%"},
                          src: previewImage.value
                        }, null, 8, _hoisted_11)
                      ]),
                      _: 1
                    }, 8, ["visible", "title"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", {
        onClick: submitAdd,
        style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"}
      }, _toDisplayString(_unref(langObj)['提交']), 1)
    ])
  ]))
}
}

})