import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full box1 p-3 whiteBg" }
const _hoisted_2 = { class: "w-full flex items-center" }
const _hoisted_3 = { style: {"width":"120px"} }
const _hoisted_4 = ["id"]

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { transformDate2, transformDate } from "../../../common/tools";
import { Dayjs } from 'dayjs';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";


export default /*@__PURE__*/_defineComponent({
  __name: 'chart4',
  props: {
    sensorInfo: null,
},
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let plainOptions:any = ref([])
let checkedList = ref([])

let date: any = ref(null)


const props: any = __props;
let sensorInfo: any = ref()
sensorInfo.value = props.sensorInfo

watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);

let chartList = ref([])
let loading:any = ref(false)

onMounted(() => {
    init()
})

const init = () => {
    console.log('sensorInfo', sensorInfo.value)
    if (!sensorInfo.value?.id) return

    if (sensorInfo.value.dataType && sensorInfo.value.dataType.length) {
        if (sensorInfo.value.dataType.includes('高频加速度')) {
            sensorInfo.value.dataType.map((d: any, i: any) => {
                if (d === '高频加速度') {
                    sensorInfo.value.dataType.unshift(sensorInfo.value.dataType.splice(i, 1)[0]);
                }
            })
        }

        plainOptions.value = sensorInfo.value.dataType.map((d:any) => ({label: langObj.value[d], value: d}));
    }
   
//     console.log('checkedList.value', checkedList.value)
// return
    if (plainOptions.value.length) {
        if(checkedList.value?.length > 0) {
            // checkedList.value =  checkedList.value
        } else {
            checkedList.value = [plainOptions.value[0]?.value]
        }
        
        getChartData()
    }
}

const changeType = (ev: any) => {
    getChartData()
}

const getChartData = async () => {
    loading.value = true
    chartList.value = []
    let begin = ''
    let end = ''
    if (date.value && date.value.length === 2) {
        begin = transformDate2(date.value[0], 1)
        end = transformDate2(date.value[1], 2)
    } else {
        end = transformDate(new Date())
        begin = transformDate(new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 1)))
    }

    // end = '2023/07/31 09:55:10'
    // begin = '2023/07/22 13:00:11'

    let search = {
        sensorId: sensorInfo.value.id,
        type: checkedList.value,
        begin,
        end,
    }

    let result = await request.post('/api/external/sensors/quota', search)

    if (result && result.data) {
        chartList.value = result.data
    } else {
        loading.value = false
    }

    setTimeout(() => {
        loading.value = false
        chartList.value.map((d: any, index: any) => {
            initChart({ xData: d.date, yData: d.value }, 'chart4' + index, langObj.value[`${d.type}(${d.unit})`])
        })
    }, 200);


}


const initChart = (data: any, id: any, title: any) => {
    let { xData, yData } = data
    xData = xData.reverse()
    yData = yData.reverse()
    const ec = echarts as any;
    if (!document.getElementById(id)) return
    let myChartA = ec.init(document.getElementById(id));

    let option = {
        color: theme.value=='white'? ["#274CE0"]:["#00FFF4"],
        title: {
            text: title,
            textStyle: {
                color: theme.value=='white'? '#333':'#fff'
            },
            left: 'center',
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        grid: {
            top: '8%',
        },
        toolbox: {
            trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLabel: {
                showMaxLabel: true
            },
            splitLine: {
                show: true,
                lineStyle: {
                    opacity: 0.3
                }
            },
            data: xData
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: yData,
                type: 'line'
            }
        ]
    };

    myChartA && myChartA.setOption(option)
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
    dates.value = val;
};



return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(langObj)['采样时间']+"："), 1),
          _createVNode(_component_a_range_picker, {
            onChange: getChartData,
            style: {"width":"300px","margin-left":"20px"},
            value: _unref(date),
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(date) ? (date).value = $event : date = $event)),
            "disabled-date": disabledDate,
            onCalendarChange: onCalendarChange
          }, null, 8, ["value"]),
          _createVNode(_component_a_spin, { spinning: _unref(loading) }, {
            default: _withCtx(() => [
              _createVNode(_component_a_checkbox_group, {
                onChange: changeType,
                style: {"margin-left":"20px"},
                value: _unref(checkedList),
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_isRef(checkedList) ? (checkedList).value = $event : checkedList = $event)),
                options: _unref(plainOptions)
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(plainOptions), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_checkbox, {
                      key: index,
                      value: item.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "options"])
            ]),
            _: 1
          }, 8, ["spinning"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(chartList), (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "mt-4 w-full"
          }, [
            _createVNode(_component_a_spin, { spinning: _unref(loading) }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  id: 'chart4' + index,
                  style: {"width":"100%","height":"350px"}
                }, null, 8, _hoisted_4)
              ]),
              _: 2
            }, 1032, ["spinning"])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})