<template>
  <div style="color: #fff">
    <div class="w-full flex">
      <div class="flex-1"></div>
      <div class="cursor-pointer" @click="onClose">
        <close-outlined style="font-size: 20px"/>
      </div>
    </div>
    <div class="text-center w-full" style="font-size: 24px">
      {{ langObj['添加检修记录'] }}
    </div>

    <div class="w-full flex items-center">
          <span style="font-size: 20px"
          > {{ langObj['故障信息(请勾选此次维修的故障项)'] }}
         </span
         >
    </div>

    <a-table :row-selection="{ selectedRowKeys: selectedRowKeys,  onChange: onSelectChange, onSelect: onSelect }"
             :columns="columns"
             :data-source="warningList"
             :pagination="false"
             rowKey="id"
             style="background-color: #020f46">
      <template #bodyCell="{ column, text }">
        <template v-if="column.dataIndex === 'condition'">
          <div class="flex items-center">
            <div class="ml-2">
              {{ langObj[conditionList.find((p: any) => p.value === text)?.label || ""] }}
            </div>
          </div>
        </template>
      </template>
    </a-table>

    <div class="mt-4" style="font-size: 20px">{{ langObj['维修详情'] }}</div>

    <a-form ref="formRef"
            name="advanced_search"
            class="ant-advanced-search-form"
            :model="form"
            :label-col="{ style: { width: '180px' } }">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item name="begin" :label="langObj['开始时间']"
                       :rules="[{ required: true, message: langObj['请选择开始时间'] }]"
                       :allowClear="false">
            <a-date-picker v-model:value="form.begin" show-time :placeholder="langObj['请选择时间']"/>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="end" :label="langObj['结束时间']"
                       :rules="[{ required: true, message: langObj['请选择结束时间'] }]"
                       :allowClear="false">
            <a-date-picker v-model:value="form.end" show-time :placeholder="langObj['请选择时间']"/>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="value" :label="langObj['设备名称']" :rules="[{ required: true }]">
            <a-input readonly v-model:value="form.machineName" style="width: 210px"/>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="value" :label="langObj['设备区域']" :rules="[{ required: true }]">
            <a-input readonly v-model:value="form.groupName" style="width: 210px"/>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="maintainPerson" :label="langObj['检修人员']" :rules="[{ required: true }]">
            <a-input v-model:value="form.maintainPerson" style="width: 210px"/>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item name="condition" :label="langObj['现场设备状态']" :rules="[{ required: true }]">
            <a-select v-model:value="form.condition" style="width: 210px" :placeholder="langObj['请选择现场设备状态']"
                      allowClear>
              <a-select-option v-for="(item, index) in conditionAdd" :key="index" :value="item.value">
                {{ langObj[item.label] }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item name="faultCause" :label="langObj['故障原因']" :rules="[{ required: true }]">
            <a-textarea v-model:value="form.faultCause" :placeholder="langObj['请输入故障原因']" :rows="4"/>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item name="maintainMessage" :label="langObj['检修内容']" :rules="[{ required: true }]">
            <a-textarea v-model:value="form.maintainMessage" :placeholder="langObj['请输入检修内容']" :rows="4"/>
          </a-form-item>
        </a-col>

        <a-col :span="24">
          <a-form-item style="margin-left: 10px" name="value" :label="langObj['检修图片']">
            <a-upload list-type="picture-card"
                      @preview="handlePreview"
                      :custom-request="uploadFile"
                      :auto-upload="false"
                      accept="image"
                      :max-count="9">
              <div v-if="!fileList || fileList.length < 9">
                <plus-outlined class="opt6" style="color: #fff"/>
                <div class="opt6" style="margin-top: 8px; color: #fff">
                  {{ langObj['上传'] }}
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible"
                     :title="previewTitle"
                     :footer="null"
                     @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage"/>
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <div class="w-full flex justify-center">
      <div @click="submitAdd" style="
              background-color: #072499;
              padding: 5px 10px;
              width: 100px;
              font-size: 20px;
              text-align: center;
              cursor: pointer;
            ">
        {{ langObj['提交'] }}
      </div>
    </div>
  </div>


</template>

<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { langList } from "@/common/lang";
import { getGroupName, transformDate } from "@/common/tools";
import { message } from "ant-design-vue";


let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

let conditionList = [
  { label: '健康', value: 0, color: '#61c08f' },
  { label: '可用', value: 1, color: '#d2de49' },
  { label: '可用', value: 2, color: '#d2de49' },
  { label: '警戒', value: 3, color: '#cd5f3b' },
  { label: '警戒', value: 4, color: '#cd5f3b' },
  { label: '警戒', value: 5, color: '#cd5f3b' },
  { label: '故障', value: 6, color: '#921e37' },
  { label: '故障', value: 7, color: '#921e37' },
]

const emit = defineEmits(['success', 'close']);
const props = defineProps<{ warningList: any[] }>()

let form: any = ref({
  value: "",
  begin: null,
  end: null,
  type: 2,
  machineName: "",
  machineId: "",
  groupId: "",
  groupName: "",
  factoryId: "",
  condition: null,
  faultCause: "",
});
let warningList = ref<any[]>([]);

let conditionAdd = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 3 },
  { label: "故障", value: 6 },
];
let columns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["故障描述"],
    dataIndex: "diagnosticNotes",
  },
];

watch(() => props.warningList, (newVal) => {
  warningList.value = newVal || [];
}, { immediate: true });
onMounted(() => {
  form.value.maintainPerson = JSON.parse(
      localStorage.getItem("memberInfo") || ""
  )?.nickName;
});

const onClose = () => {
  emit('close')
}

let selectedRowKeys = ref<any[]>([]);
const onSelectChange = (_: any) => {
  //selectedRowKeys.value = ev;
};

let machineId = ref("");
let machineInfo: any;
watch(() => machineId.value, async (newVal) => {
  if (!newVal) {
    machineInfo = null;
    form.value.groupName = "";
    form.value.machineName = "";
    form.value.machineId = "";
    form.value.groupId = "";
    form.value.factoryId = "";
    return;
  }
  let machineRes = await request.get('/api/machines/info?machineId=' + newVal);
  machineInfo = machineRes?.data;
  if (machineInfo) {
    machineInfo.groupName = getGroupName(machineInfo.groupId);
    form.value.groupName = machineInfo.groupName;
    form.value.machineName = machineInfo.machineName;
    form.value.machineId = machineInfo.machineId;
    form.value.groupId = machineInfo.groupId;
    form.value.factoryId = machineInfo.factoryId;
  }
});

const onSelect = (record: any, selected: boolean, selectedRows: any[]) => {
  if (selectedRows.length == 0) {
    machineId.value = "";
  } else if (machineId.value === "") {
    machineId.value = record.machineId;
  }
  if (selected) {
    if (selectedRows.findIndex((t: any) => t.machineId !== record.machineId) > -1) {
      message.warn("请选择同一设备的报警内容");
      return;
    }
    selectedRowKeys.value.push(record.id);
  } else {
    selectedRowKeys.value = selectedRowKeys.value.filter((p) => p !== record.id);
  }
}

const submitAdd = async () => {
  let submit = JSON.parse(JSON.stringify(form.value));
  if (!submit.begin) {
    message.warning(langObj.value["请选择开始时间"]);
    return;
  } else {
    submit.begin = transformDate(submit.begin);
  }

  if (!submit.end) {
    message.warning(langObj.value["请选择结束时间"]);
    return;
  } else {
    submit.end = transformDate(submit.end);
  }

  if (!submit.maintainPerson) {
    message.warning(langObj.value["请填写检修人员"]);
    return;
  }

  if (!submit.condition && submit.condition !== 0) {
    message.warning(langObj.value["请选择现场设备状态"]);
    return;
  }

  if (!submit.faultCause) {
    message.warning(langObj.value["请填写故障原因"]);
    return;
  }

  if (!submit.maintainMessage) {
    message.warning(langObj.value["请填写检修内容"]);
    return;
  }

  let list = warningList.value.filter((p: any) =>
      selectedRowKeys.value.includes(p.id)
  );

  submit.list = list.map((d: any) => ({
    date: d.date,
    condition: d.condition,
    diagnosticNotes: d.diagnosticNotes,
    id: d.id,
  }));

  if (fileList.value && fileList.value.length) {
    submit.pictures = fileList.value.map((p: any) => ({
      url: p.url,
    }));
  } else {
    submit.pictures = [];
  }

  let data: any = {
    repairReport: submit,
    date: submit.end,
    machineIds: [machineId.value],
    factoryId: submit.factoryId,
    pictures: submit.pictures,
    type: 2,
    workflow: true,
  };

  let result = await request.post("/api/reports", data);

  if (result && result.status) {
    message.success(langObj.value["新增成功"]);
    selectedRowKeys.value = []
    machineId.value = "";
    emit('success');
  } else {
    message.warning(langObj.value["操作失败"] + "!");
  }
};


//**图片上传* */
let fileList = ref<any>([]);
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = file.url;
};

let uploadFile = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};
</script>


<style scoped lang="less">

</style>
