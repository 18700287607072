import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "top" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"color":"#fff"} }

import request from "../../common/request";
import { useRouter } from "vue-router";
import { ref, reactive, toRaw } from "vue";
import { computed, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { icons } from "../../common/icons";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { number } from "vue-types";
import { getGroupName } from "../../common/tools";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

import { langList } from "../../common/lang";

export default /*@__PURE__*/_defineComponent({
  __name: 'SingleWash',
  setup(__props) {

let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
        language.value = localStorage.getItem('language') || 'Chinese'
        langObj.value = langList[language.value]
    }
    getLang()


operateLogs('访问数据清洗');
const router = useRouter();
let loading = ref(false);
const formStateRef = ref<any>();
const dataSource = ref([]);
let machineList:any = ref([])

/**操作日志记录 */
const deviceFormState = reactive({
  keyWord: "",
  groupId: "",
  groupIds: [],
  skip: 1,
  take: 10,
});

// 新增的模态框
const modalState = reactive({
  visible: false,
  title: langObj.value["数据清洗配置"],
  confirmLoading: false,
  roleName: "",
  meun: [],
});

let numberType = ref<any>()
let formMenu: any = ref({
  enable: true,
  machineName: "",
  slices: "",
  dataType: "",
  filterType: "",
  filters: [],
  machineId: "",
  factoryId: "",
  machineIds: [],
});
// 选择设备的模态框
const deviceModal = reactive({
  visible: false,
  title: langObj.value["选择设备"],
  confirmLoading: false,
})
// 选择设备
const choose = () => {
  deviceModal.title = langObj.value["选择设备"];
  deviceModal.visible = true;
};

const formState = reactive({
  keyWork: "",
  skip: 1,
  take: 10,
});
let WashList = ref([]);
// 表格
const columns = [
  {
    title:langObj.value["设备名称"],
    dataIndex: "machineIds",
    align: "center",
  },
  {
    title: langObj.value["切片数"],
    dataIndex: "slices",
    align: "center",
  },
  {
    title: langObj.value["数据类型"],
    dataIndex: "dataType",
    align: "center",
  },
  {
    title: langObj.value["滤波方式"],
    dataIndex: "filterType",
    align: "center",
  },
  {
    title: langObj.value["滤波频段"],
    dataIndex: "filters",
    align: "center",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
    align: "center",
    width: "260px"
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["数据清洗配置"];
  formMenu.value = {
    slices: "",
    dataType: "",
    filterType: "",
    filters: [],
    machineId: "",
    machineIds: [],
    factoryId: ""
  }
  filtersList.value = []
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};

let filtersList: any = ref([])

const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
// 编辑
const editThis = async (val: any) => {
  modalState.title =  langObj.value["编辑"];
  formMenu.value = JSON.parse(JSON.stringify(val))

  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};

// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let postForm = {
        ...formMenu.value,
        factoryId,
      }

      if (postForm.date || postForm.id) {
        // return
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.put("/api/single-wash", postForm, config).then((res) => {
          if (res) {
            operateLogs('修改数据，数据名称：' + postForm.machineName + ",id:" + postForm.id);
            modalState.visible = false;
            message.success(langObj.value["操作成功"]);
            getWashList()
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/single-wash", postForm, config).then((res) => {
          if (res) {
            operateLogs('新增数据，数据名称：' + postForm.machineName);
            formMenu.value.machineId = res.data
            modalState.visible = false;
            message.success(langObj.value["操作成功"]);
            getWashList()
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};



// 选择设备的表格数据
const deviceColumns = [
  {
    title: langObj.value['设备名称'],
    dataIndex: "machineName"
  }, {
    title: langObj.value['设备区域'],
    dataIndex: "groupId"
  }, {
    title: langObj.value['安装位置'],
    dataIndex: "position"
  }, {
    title: langObj.value['操作'],
    dataIndex: "act",
  },
]
let factoryId = localStorage.getItem('factory_id') || '';
let treeData:any = ref([])
// 查询
const search = async() => {
  const config = {
    params: {
      factoryId: factoryId,
      keyWord: deviceFormState.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  // request.get("/api/machines", config).then((res: any) => {
  //   if (res) {
  //     dataSource.value = res.data;
  //   }
  // });

  let result = await request.get("/api/machines", config)
  if(result && result.data) {
    machineList.value = result.data
    let groupIds = result.data.map((d:any) => d.groupId)
    groupIds = [...new Set(groupIds)]
    treeData.value = []
    let list:any = []
    groupIds.map((g:any) => {
      let first = {
        title: getGroupName(g),
        value: g,
        key: g,
        children: [],
      }
      let machineList = result.data.filter((p:any) => p.groupId == g)
      let second = machineList.map((d:any) => ({
        title: d.machineName,
        value: d.id,
        key: d.id
      }))
      first.children = second
      list.push(first)
    })
   

    treeData.value = list
  }

 

};

// 选择设备
const chooseOk = (record: any) => {
  formMenu.value.machineName = record.machineName
  formMenu.value.factoryId = record.factoryId
  formMenu.value.machineId = record.id
  deviceModal.visible = false;
  getWashList()
}


// 数据类型
const dataTypeList = [
  { label: "HZ与LZ", value: 1 },
  { label: "HZ", value: 2 },
  { label: "LZ", value: 3 },
]
// 滤波方式
const filterTypeList = [
  { label: langObj.value['带通滤波'], value: 'pass' },
  { label: langObj.value['带阻滤波'], value: 'stop' },
]

// 新增行
const addRow = () => {
  formMenu.value.filters.push({})
}

// 删除当前行
const deleteRow = (index: number) => {
  formMenu.value.filters.splice(index, 1)
}
// 删除数据
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id, date: val.date },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/single-wash", config).then((res) => {
    if (res) {
      operateLogs('删除设备，设备名称：' + val.machineName + ",id:" + val.id);
      message.success(langObj.value["操作成功"]);
      getWashList()
    } else {
    }
  });
};

// 获取表格数据
const getWashList = async () => {
  const config = {
    params: { factoryId: factoryId},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/single-wash', config)
  // WashList.value = result.data.map((item: any) => {
  //   return {
  //     ...item,
  //     dataType: convertQuantityToText(item.dataType),
  //     filterType: item.filterType == 'pass' ? '带通滤波' : '带阻滤波',
  //   }
  // })

  WashList.value = []

  if(result?.data?.length) {
    let dates = result.data.filter((d:any) => d.date).map((p:any) => p.date)
    dates = [ ... new Set(dates)]
    let list1:any = []
    let list2:any = result.data.filter((p:any) => !p.date)
    list2.map((d:any) => {
      d.machineIds = [d.machineId]
      delete d.machineId
    })
    dates.map((d:any) => {
      let list = result.data.filter((p:any) => p.date == d)
      let machineIds = list.map((p:any) => p.machineId)
      list1.push({
        machineIds,
        slices: list[0].slices,
        factoryId: list[0].factoryId,
        dataType: list[0].dataType,
        filterType: list[0].filterType,
        filters: list[0].filters,
        updateAt: list[0].updateAt,
        date: d,
      })
    })

    WashList.value = list1.concat(list2)
  }
}

const convertQuantityToText = (dataType: number) => {
  if (dataType == 1) {
    return 'HZ与LZ'
  } else if (dataType == 2) {
    return 'HZ'
  } else {
    return 'LZ'
  }
}
// 在组件加载完成后执行一些操作
onMounted(async () => {
  getWashList()
  search();
});

const getMachineName = (ev:any) => {
  let names:any = []
  ev.map((d:any) => {
    let info = machineList.value.find((p:any) => p.id === d)
    if(info) {
      names.push(info.machineName)
    }
  })
  return  names.join(' ， ')
}




return (_ctx: any,_cache: any) => {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 40px"} }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                type: "primary",
                onClick: add,
                class: "btn"
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_plus_outlined)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)['新增']), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_a_table, {
              style: {"margin-top":"10px"},
              bordered: "",
              columns: columns,
              dataSource: _unref(WashList),
              pagination: false,
              loading: _unref(loading),
              size: "small"
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createVNode(_component_a_popconfirm, {
                        title: _unref(langObj)['确定删除此条数据么']+'?',
                        "ok-text": _unref(langObj)['确定'],
                        "cancel-text": _unref(langObj)['取消'],
                        onConfirm: ($event: any) => (deleteThis(record))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            type: "primary",
                            danger: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_delete_outlined),
                              _createTextVNode(_toDisplayString(_unref(langObj)['删除']), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 2
                      }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]),
                      _createVNode(_component_a_button, {
                        type: "primary",
                        class: "plft",
                        onClick: ($event: any) => (editThis(record))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_edit_outlined),
                          _createTextVNode(_toDisplayString(_unref(langObj)['编辑']), 1)
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex == 'filters')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.filters, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createElementVNode("span", null, "[ " + _toDisplayString(item.start) + " - " + _toDisplayString(item.stop) + " ]", 1)
                        ]))
                      }), 128)),
                      _withDirectives(_createElementVNode("div", null, _cache[11] || (_cache[11] = [
                        _createElementVNode("span", null, " [null , null] ", -1)
                      ]), 512), [
                        [_vShow, record.filters == '']
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex == 'machineIds')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createTextVNode(_toDisplayString(getMachineName(text)), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex == 'filterType')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createTextVNode(_toDisplayString(filterTypeList.find(p => p.value === text)?.label), 1)
                    ], 64))
                  : _createCommentVNode("", true),
                (column.dataIndex == 'dataType')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                      _createTextVNode(_toDisplayString(dataTypeList.find(p => p.value === text)?.label), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["dataSource", "loading"]),
            _createVNode(_component_a_modal, {
              width: 850,
              visible: modalState.visible,
              "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((modalState.visible) = $event)),
              title: modalState.title,
              "confirm-loading": modalState.confirmLoading,
              maskClosable: false,
              onOk: handleOk
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  model: _unref(formMenu),
                  ref_key: "formStateRef",
                  ref: formStateRef,
                  name: "basic",
                  autocomplete: "off",
                  "label-col": { style: { width: '130px' } }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: _unref(langObj)['设备'],
                      name: "machineIds",
                      onChange: _cache[1] || (_cache[1] = ($event: any) => (clearValidate('machineName'))),
                      rules: [
            { required: true, message: _unref(langObj)['请选择'], trigger: 'blur' },
          ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_tree_select, {
                          value: _unref(formMenu).machineIds,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formMenu).machineIds) = $event)),
                          style: {"width":"100%"},
                          "tree-data": _unref(treeData),
                          "tree-checkable": "",
                          "allow-clear": "",
                          "search-placeholder": "请选择设备"
                        }, null, 8, ["value", "tree-data"])
                      ]),
                      _: 1
                    }, 8, ["label", "rules"]),
                    _createVNode(_component_a_form_item, {
                      label: _unref(langObj)['切片数'],
                      name: "slices",
                      rules: [
            { required: true, message: _unref(langObj)['请输入切片数'], trigger: 'blur' },
          ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input_number, {
                          style: {"width":"100%"},
                          value: _unref(formMenu).slices,
                          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formMenu).slices) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }, 8, ["label", "rules"]),
                    _createVNode(_component_a_form_item, {
                      label: _unref(langObj)['数据类型'],
                      name: "dataType",
                      rules: [
            { required: true, message: _unref(langObj)['请选择数据类型'], trigger: 'blur' },
          ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          ref: "select",
                          options: _unref(numberType),
                          value: _unref(formMenu).dataType,
                          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formMenu).dataType) = $event)),
                          placeholder: _unref(langObj)['请选择'],
                          onChange: _cache[4] || (_cache[4] = ($event: any) => (clearValidate('dataType'))),
                          allowClear: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(dataTypeList, (item) => {
                              return _createVNode(_component_a_select_option, {
                                key: item.value,
                                value: item.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"])
                            }), 64))
                          ]),
                          _: 1
                        }, 8, ["options", "value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label", "rules"]),
                    _createVNode(_component_a_form_item, {
                      label: _unref(langObj)['滤波方式'],
                      name: "filterType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_select, {
                          ref: "select",
                          options: _unref(numberType),
                          value: _unref(formMenu).filterType,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formMenu).filterType) = $event)),
                          placeholder: _unref(langObj)['请选择'],
                          onChange: _cache[6] || (_cache[6] = ($event: any) => (clearValidate('filterType'))),
                          allowClear: ""
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(filterTypeList, (item) => {
                              return _createVNode(_component_a_select_option, {
                                key: item.value
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.label), 1)
                                ]),
                                _: 2
                              }, 1024)
                            }), 64))
                          ]),
                          _: 1
                        }, 8, ["options", "value", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, {
                      style: {"display":"flex","justify-content":"space-between"},
                      label: _unref(langObj)['滤波频段'],
                      name: "filters",
                      layout: "vertical"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, {
                              span: 8,
                              offset: 8
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "link",
                                  onClick: addRow
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)['新增行']), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_form_item, { "wrapper-col": { span: 25, offset: 6 } }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formMenu).filters, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            style: {"margin-bottom":"10px"},
                            key: index
                          }, [
                            _createVNode(_component_a_input_number, {
                              value: item.start,
                              "onUpdate:value": ($event: any) => ((item.start) = $event),
                              style: {"width":"180px"}
                            }, null, 8, ["value", "onUpdate:value"]),
                            _cache[12] || (_cache[12] = _createElementVNode("span", { style: {"color":"#fff"} }, "-", -1)),
                            _createVNode(_component_a_input_number, {
                              value: item.stop,
                              "onUpdate:value": ($event: any) => ((item.stop) = $event),
                              style: {"width":"180px"}
                            }, null, 8, ["value", "onUpdate:value"]),
                            _createElementVNode("span", {
                              style: {"font-size":"large","margin-left":"13px"},
                              onClick: ($event: any) => (deleteRow(index))
                            }, [
                              _createVNode(_component_CloseCircleOutlined, { style: {"color":"#fff !important"} })
                            ], 8, _hoisted_3)
                          ]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model"])
              ]),
              _: 1
            }, 8, ["visible", "title", "confirm-loading"]),
            _createVNode(_component_a_modal, {
              width: 900,
              visible: deviceModal.visible,
              "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((deviceModal.visible) = $event)),
              title: deviceModal.title,
              maskClosable: false,
              footer: null
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  layout: "inline",
                  maskClosable: false,
                  visible: deviceFormState.visible,
                  "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((deviceFormState.visible) = $event)),
                  title: deviceFormState.title,
                  "confirm-loading": deviceFormState.confirmLoading
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(langObj)['全部设备']) + "(" + _toDisplayString(dataSource.value.length) + ")", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: deviceFormState.keyWord,
                                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((deviceFormState.keyWord) = $event)),
                                  placeholder: _unref(langObj)['搜索设备信息'],
                                  class: "ipt"
                                }, null, 8, ["value", "placeholder"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_col, { span: 10 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_button, {
                                  type: "primary",
                                  onClick: search
                                }, {
                                  icon: _withCtx(() => [
                                    _createVNode(_component_SearchOutlined)
                                  ]),
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)['搜索']), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["visible", "title", "confirm-loading"]),
                _createVNode(_component_a_table, {
                  bordered: "",
                  emptyText: '暂无数据',
                  columns: deviceColumns,
                  "data-source": dataSource.value,
                  pagination: false,
                  loading: _unref(loading)
                }, {
                  bodyCell: _withCtx(({ column, text, record }) => [
                    (column.dataIndex === 'act')
                      ? (_openBlock(), _createBlock(_component_a_button, {
                          key: 0,
                          type: "link",
                          onClick: ($event: any) => (chooseOk(record))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['选择']), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["data-source", "loading"])
              ]),
              _: 1
            }, 8, ["visible", "title"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})