import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, isRef as _isRef } from "vue"
import _imports_0 from '@/assets/zhenduan.png'
import _imports_1 from '@/assets/weixiu.png'
import _imports_2 from '@/assets/jiancha.png'
import _imports_3 from '@/assets/menxian.png'


const _hoisted_1 = {
  class: "w-full flex",
  style: {"min-height":"80vh"}
}
const _hoisted_2 = { class: "flex-1 ml-4" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "w-full flex items-center" }
const _hoisted_5 = { style: {"font-size":"24px"} }
const _hoisted_6 = { style: {"transform":"translateY(-3px)"} }
const _hoisted_7 = { style: {"transform":"translateY(-3px)"} }
const _hoisted_8 = { class: "box mt-4 flex" }
const _hoisted_9 = { class: "flex-1 ml-4" }
const _hoisted_10 = {
  class: "box mt-4",
  style: {"padding":"0"}
}
const _hoisted_11 = {
  class: "w-full",
  style: {"border-bottom":"1px solid #495985"}
}
const _hoisted_12 = {
  class: "flex items-center px-5",
  style: {"font-size":"18px"}
}
const _hoisted_13 = { class: "p-5" }
const _hoisted_14 = {
  key: 0,
  class: "zmt"
}
const _hoisted_15 = {
  key: 0,
  id: "yulan",
  style: {"margin":"auto","padding":"20px","width":"800px","height":"600px","position":"relative"}
}
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_18 = { class: "w-full flex" }
const _hoisted_19 = { class: "w-full flex items-center" }
const _hoisted_20 = { style: {"width":"35%"} }
const _hoisted_21 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_22 = { style: {"width":"35%"} }
const _hoisted_23 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_24 = { style: {"width":"30%","word-wrap":"break-word"} }
const _hoisted_25 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_26 = { class: "w-full flex" }
const _hoisted_27 = {
  class: "mr-2",
  style: {"font-size":"12px","opacity":"0.6"}
}
const _hoisted_28 = { key: 1 }
const _hoisted_29 = {
  class: "box1 mt-4",
  style: {"width":"100%"}
}
const _hoisted_30 = { key: 0 }
const _hoisted_31 = { key: 1 }
const _hoisted_32 = { key: 2 }
const _hoisted_33 = { key: 3 }
const _hoisted_34 = { key: 4 }
const _hoisted_35 = {
  key: 5,
  class: "flex items-center"
}
const _hoisted_36 = { style: {"background":"#fff"} }
const _hoisted_37 = ["onClick"]
const _hoisted_38 = { key: 2 }
const _hoisted_39 = { class: "w-full" }
const _hoisted_40 = { class: "flex items-center" }
const _hoisted_41 = {
  class: "box1 mt-4",
  style: {"width":"100%"}
}
const _hoisted_42 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { key: 1 }
const _hoisted_45 = { key: 2 }
const _hoisted_46 = { key: 3 }
const _hoisted_47 = { class: "ml-2" }
const _hoisted_48 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_49 = { class: "ml-2" }
const _hoisted_50 = ["onClick"]
const _hoisted_51 = { key: 1 }
const _hoisted_52 = ["onClick"]
const _hoisted_53 = { style: {"color":"#fff"} }
const _hoisted_54 = { class: "w-full flex" }
const _hoisted_55 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_56 = { class: "tips" }
const _hoisted_57 = { class: "main" }
const _hoisted_58 = {
  key: 0,
  id: "container",
  class: "rotateThemeBg"
}
const _hoisted_59 = ["src"]
const _hoisted_60 = {
  id: "rotate",
  class: "lipButton"
}
const _hoisted_61 = { class: "gh3d" }
const _hoisted_62 = {
  id: "repart",
  class: "lipButton"
}
const _hoisted_63 = { class: "m_r" }
const _hoisted_64 = { class: "list" }
const _hoisted_65 = { class: "item flex items-center mb-4" }
const _hoisted_66 = { class: "lleft" }
const _hoisted_67 = { class: "lright" }
const _hoisted_68 = ["onClick"]
const _hoisted_69 = { class: "w-full flex justify-center mt-5" }
const _hoisted_70 = { style: {"color":"#fff"} }
const _hoisted_71 = { class: "w-full flex" }
const _hoisted_72 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_73 = { class: "w-full flex justify-center" }
const _hoisted_74 = { style: {"color":"#fff"} }
const _hoisted_75 = { class: "w-full flex" }
const _hoisted_76 = { style: {"color":"#fff"} }
const _hoisted_77 = { class: "w-full flex" }
const _hoisted_78 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_79 = { class: "w-full mt-4" }
const _hoisted_80 = { key: 0 }
const _hoisted_81 = { style: {"margin-top":"8px","color":"#fff"} }
const _hoisted_82 = { class: "w-full flex justify-center mt-5" }
const _hoisted_83 = { style: {"color":"#fff"} }
const _hoisted_84 = { class: "w-full flex" }
const _hoisted_85 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_86 = { class: "w-full mt-4" }
const _hoisted_87 = { class: "flex items-center" }
const _hoisted_88 = { class: "flex items-center" }
const _hoisted_89 = {
  class: "w-full",
  style: {"border":"1px solid #0d53b7cc"}
}
const _hoisted_90 = ["onClick"]
const _hoisted_91 = { key: 0 }
const _hoisted_92 = { style: {"margin-top":"8px","color":"#fff"} }
const _hoisted_93 = { class: "w-full flex justify-center mt-4" }
const _hoisted_94 = ["src"]

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import {
  getFactoryName,
  getGroupName,
  transformDate2,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import machineListLeftComponent from "../components/machine-list-left.vue";
import pictureListComponent from "../components/picture-list.vue";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import {
  getGroupListByFactory,
  getRealFactoryIdList,
} from "../../common/tools";
import DownLoadComponent from "../components/downLoad.vue";
import SelectMachineComponent from "./select-machine.vue";
import SelectSensorComponent from "./select-sensor.vue";
import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'info',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

// #region 总貌图弹框
const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = file.url;
};
function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};
const visibleReEdit = ref(true);
let customRequest3D = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
    visibleReEdit.value = false;
    // config_3d.value.picture = res.data.url;
    config_3d.value = {
      id: config_3d.value?.id,
      picture: res.data,
      marks: [],
    };
    markList.value = [];
    setTimeout(() => {
      visibleReEdit.value = true;
      setTimeout(() => {
        $("#container").ZoomMark({
          markColor: "red",
          afterMark: addRowToTabel,
          showMarkNumber: false,
          markList: [],
        });
      }, 800);
    }, 200);
  }
};

let visibleEdit = ref(false);

let markList = ref([]);

let config_3d: any = ref({
  _id: "",
  marks: [],
});

let line = ref([]);

const load3D = async (type: any) => {
  config_3d.value = { marks: [] };
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-3D", config);

  if (result) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);
      if (res.data && res.data.picture) {
        result.data = {
          picture: res.data.picture,
          marks: [],
        };
      }
    }

    config_3d.value = result.data || {
      marks: [],
    };
    markList.value = config_3d.value.marks;
  }

  if (type == 2) {
    setTimeout(() => {
      $("#container").ZoomMark({
        markColor: "red",
        afterMark: addRowToTabel,
        showMarkNumber: false,
        markList: [],
      });
      setTimeout(() => {
        if (config_3d.value.img) {
          $("#container").ZoomMark("config", config_3d.value.img);
        }

        setTimeout(() => {
          if (config_3d.value.marks.length) {
            $("#container").ZoomMark("addMarks", config_3d.value.marks);
          }
        }, 100);
      }, 100);

      $("#reset").click(function () {
        $("#container").ZoomMark("reset");
      });
      $("#rotate").click(function () {
        angle = angle + 90 >= 360 ? 0 : angle + 90;

        $("#container").ZoomMark("rotate", angle);
      });
    }, 100);
  } else {
    line.value = [];
    for (var i = 0; i < config_3d.value.marks.length; i++) {
      var item = config_3d.value.marks[i];
      if (item.bind.indexOf("a-") > -1) {
        var find = config_3d.value.marks.find(
          (t) =>
            t.bind.substring(2, t.bind.length) ==
            item.bind.substring(2, item.bind.length) &&
            t.bind.indexOf("b-") > -1
        );
        if (find) {
          var distance = Math.sqrt(
            Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
          );
          var angle_d =
            Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
          line.value.push({
            ...item,
            distance: distance,
            angle: angle_d,
          });
        }
      }
    }
  }

  get3DData();
};

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: machine_id.value,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    config_3d.value.marks.map((d: any) => {
      if (d.bind.includes("b-")) {
        let list = d.bind.split("-");
        if (list && list.length === 2) {
          let sensorId = list[1];
          let sensorInfo = result.data.sensors.find(
            (p: any) => p.id === sensorId
          );
          if (sensorInfo) {
            d.sensorPlace = sensorInfo.sensorPlace;
          } else {
            d.sensorPlace = "";
          }

          let info = result.data.quotas.find(
            (p: any) => p.sensorId === sensorId
          );
          if (info) {
            if (info.values.length) {
              let sz = info.values.find((p: any) => p.type === "速度Z");
              d.sz = sz ? sz.value.toFixed(2) : "0";
              let hz = info.values.find((p: any) => p.type === "高频加速度");
              d.hz = hz ? hz.value.toFixed(2) : "0";
              let temp = info.values.find((p: any) => p.type === "温度");
              d.temp = temp ? temp.value.toFixed(2) : "0";
            }
            d.date = info.date
          }
          let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
            (p: any) => p.id === sensorId
          );
          if (stableInfo) {
            delete stableInfo.id;
            let list = Object.values(stableInfo).sort();
            let score: any = 100;
            if (list && list.length) {
              score = list[0];
            }

            if (score >= 80 && score <= 100) {
              d.condition = 0;
            }
            if (score >= 60 && score < 80) {
              d.condition = 1;
            }
            if (score >= 30 && score < 60) {
              d.condition = 3;
            }
            if (score >= 0 && score < 30) {
              d.condition = 6;
            }
          }
        }
      }
    });
  }
};

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7", valueR: 1 },
  {
    label: "可用",
    value: [1, 2],
    color: "#d2de49",
    color2: "#d2de49",
    valueR: 2,
  },
  {
    label: "警戒",
    value: [3, 4, 5],
    color: "#cd5f3b",
    color2: "#cd5f3b",
    valueR: 3,
  },
  {
    label: "故障",
    value: [6, 7],
    color: "#921e37",
    color2: "#921e37",
    valueR: 4,
  },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};
const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

let options = ref([]);

const save3D = async () => {
  var setting = $("#container").ZoomMark("getSetting");
  // if (markList.value.length == 0) {
  //   message.warning("请选择3D标点");
  //   return;
  // }
  if (markList.value.filter((t) => !t.bind).length > 1) {
    message.warning("请设置每个标点内容");
    return;
  }

  markList.value.forEach((t, index) => {
    var find = setting.marks.find((t2) => t2.id == t.id);
    t.x = find.x;
    t.y = find.y;
  });
  var postData = {
    id: config_3d.value?.id,
    machineId: machine_id.value,
    picture: config_3d.value?.picture,
    marks: markList.value,
    img: setting.img,
  };
  // 保存
  if (postData.id) {
    var result = await request.put("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      visibleEdit.value = false;
      load3D(1);
    }
  } else {
    var result = await request.post("/api/machine-3D", postData);
    if (result && result.status == 200) {
      message.success("保存成功");
      visibleEdit.value = false;
      config_3d.value._id = result.data;
      load3D(1);
    }
  }
};

const markShow = () => {
  if (!machineInfo.value.machineTypeId) {
    message.warning("请选择设备类型后编辑");
    return;
  }

  visibleEdit.value = true;
  load3D(2);
};

// $('#container').ZoomMark('getSetting');
const deleteTag = (item: any, index: any) => {
  markList.value.splice(index, 1);
  $("#container").ZoomMark("deleteMark", index);
  markList.value.map((d: any, i: any) => {
    d.id = i + 1
  })
  var setting = $("#container").ZoomMark("getSetting");

};

var angle = 0;

function addRowToTabel(marks) {
  for (var i = 0; i < marks.length; i++) {
    if (!markList.value.filter((t) => t.id == marks[i].id).length) {
      markList.value.push({ ...marks[i], bind: "" });
    }
  }
  // $('#marksTable').find('tbody').append('<tr id="row_'+marks[marks.length-1].id+'"><td><span class="colorSpan"style="background-color:'+marks[marks.length-1].color+'">'+($('#marksTable').find('tbody').find('tr').length+1)+'</span></td><td >'+marks[marks.length-1].color+'</td><td><textarea name="a" style="width:200px;height:60px;"></textarea></td><td><img src="example/assets/delete.png" onclick="deleteMark('+marks[marks.length-1].id+')"></td></tr>');
}

function deleteMark(id) {
  $("#container").ZoomMark("deleteMark", id);

  $("#row_" + id).remove();
  updateTableId();
}

function updateTableId() {
  $("#marksTable")
    .find("tbody")
    .find(".colorSpan")
    .each(function (index, value) {
      $(this).html(index + 1);
    });
}

// #endregion

let value = ref("");
let menuSelect = ref(1);
let machine_id = ref("");
let machineInfo: any = ref({});

const sensorColumns = [
  {
    title: langObj.value["传感器SN"],
    dataIndex: "sensorSn",
  },
  {
    title: langObj.value["测点位置"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["所属设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["测点ID"],
    dataIndex: "id",
  },
  {
    title: langObj.value["转频提取测点"],
    dataIndex: "fcSensorPlace",
  },
  {
    title: langObj.value["轴参数"],
    dataIndex: "parameter",
  },
  // {
  //   title: langObj.value["开机指标"],
  //   dataIndex: "threshold",
  // },
  {
    title: langObj.value["传感器电量"],
    dataIndex: "hardware",
  },
  {
    title: getLanguage('智能采集有效期', language.value),
    dataIndex: "validity",
  },
  {
    title: getLanguage('智能诊断巡检配置', language.value),
    dataIndex: "inspectionSubs",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
let warnTypeList = [
  { label: "智能诊断", value: 1 },
  { label: "门限报警", value: 4 },
];
let warningColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "contactName",
  },
  {
    title: langObj.value["处理状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
let handleStatusList = [
  { label: "未处理 ", value: 1 },
  { label: "已处理 ", value: 2 },
  { label: "已关闭 ", value: 3 },
];
let warnSearchModel: any = ref({
  machineId: "",
  date: null,
  type: null,
  condition: null,
  skip: 1,
  take: 20,
  total: 0,
});

const warmPagination = computed(() => ({
  total: warnSearchModel.value.total,
  current: warnSearchModel.value.skip,
  pageSize: warnSearchModel.value.take,
}));

let data: any = ref([]);
let warningList: any = ref([]);
let searchModel: any = ref({});

let selectedRowKeys: any = ref();

// let conditionList = [
//   { label: "健康", value: [0], color: "#61c08f" },
//   { label: "可用", value: [1, 2], color: "#d2de49" },
//   { label: "警戒", value: [3, 4, 5], color: "#cd5f3b" },
//   { label: "故障", value: [6, 7], color: "#921e37" },
// ];

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
];
let picList: any = ref([]);
let picListAll: any = ref([]);
let isEditable: any = ref(false);

let options2: any = ref([])
let categoryAll: any = ref([])
onMounted(() => {
  isEditable.value = true
  getMachineAll()
  getSearch3()
})

const getSearch3 = async () => {
  let config: any = {
    params: {

    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    categoryAll.value = JSON.parse(JSON.stringify(result.data))
  }

  options.value = []
  let first = categoryAll.value.map((p: any) => p.firstCategory)
  first = [... new Set(first)]
  let list: any = []
  first.map((f: any) => {
    let firstList: any = {
      label: f,
      value: f,
      children: []
    }
    let second = categoryAll.value.filter((p: any) => p.firstCategory === f).map((p: any) => p.secondCategory)
    second = [... new Set(second)]
    second.map((s: any) => {
      let secondList: any = {
        label: s,
        value: s,
        children: []
      }
      let third = categoryAll.value.filter((p: any) => p.firstCategory === f && p.secondCategory === s).map((p: any) => p.thirdCategory)
      third = [... new Set(third)]
      third.map((t: any) => {
        let thirdList: any = {
          label: t,
          value: t,
        }
        secondList.children.push(thirdList)
      })
      firstList.children.push(secondList)
    })

    list.push(firstList)
  })

  options2.value = list
}

// 获取左侧列表设备id
const selectMachine = (ev: any) => {
  machine_id.value = ev;
  init();
};

const init = async () => {
  getMachinrTypeAll();
  picList.value = [];
  search();
  load3D(1);
  loadDatatype();
  getMachineType();
};

const getMachinrTypeAll = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
  }

  getMachineInfo();
};

const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (machineInfo.value.machineTypeId) {
      let info: any = machineTypeListAll.value.find(
        (p: any) => p.id === machineInfo.value.machineTypeId
      );
      if (info) {
        machineInfo.value.typeName = `${info.FirstCategory}/${info.SecondCategory}/${info.ThirdCategory}`;
      } else {
        machineInfo.value.typeName = "";
      }
    }

    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
        p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.scoreWkStatus
    ) {
      machineInfo.value.status = statusList.find(
        (p: any) => p.value === machineInfo.value.workStatus.scoreWkStatus
      );
    } else {
      machineInfo.value.status = statusList[0];
    }
  }

  if (machineInfo.value.pictures?.length > 0) {
    picList.value = machineInfo.value.pictures;
  }
  getSensorList();
};

const getSensorList = async () => {
  options.value = []
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.pictures?.length > 0) {
        picList.value = picList.value.concat(d.pictures);
      }
    });

    data.value = result.data;
    // 总貌图选项
    for (var i = 0; i < data.value.length; i++) {
      options.value.push({
        label: data.value[i].sensorPlace + "-标点",
        value: "a-" + data.value[i].id,
      });
      options.value.push({
        label: data.value[i].sensorPlace + "-标签",
        value: "b-" + data.value[i].id,
      });
    }
  }

  picListAll.value = picList.value;
};

// 报警

const changeWarnTable = (pagination: any) => {
  warnSearchModel.value.skip = pagination.current;
  warnSearchModel.value.take = pagination.pageSize;
  getWarningList();
};

const search = () => {
  warnSearchModel.value.skip = 1;
  getWarningList();
};

const reset = () => {
  warnSearchModel.value = {
    ...warnSearchModel.value,
    date: null,
    type: null,
    condition: null,
  };
};

const getWarningList = async () => {
  let config: any = {
    params: {
      skip: warnSearchModel.value.skip,
      take: warnSearchModel.value.take,
      machineId: machine_id.value,
      typeList: [1, 4],
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (warnSearchModel.value.type) {
    delete config.params.typeList;
    config.params.type = warnSearchModel.value.type;
  }

  if (warnSearchModel.value.date && warnSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnSearchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(
      new Date(warnSearchModel.value.date[1]),
      2
    );
  }

  if (warnSearchModel.value.condition) {
    config.params.conditionList = conditionList.find(
      (p: any) => p.valueR === warnSearchModel.value.condition
    )?.value;
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    warningList.value = result.data.list;
    warnSearchModel.value.total = result.data.total;
  }
};

// 关闭报警
let formClose: any = ref({
  closeCause: "",
});
let visibledel = ref(false);

const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev;
};

// 关闭警报
const closeWarning = () => {
  if (!selectedRowKeys.value) {
    message.warning(langObj.value["请选择报警"]);
    return;
  }

  let list2 = warningList.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  for (let d of list2) {
    if (d.handleStatus && (d.handleStatus === 2 || d.handleStatus === 3)) {
      message.warning(langObj.value["请选择未处理的报警"]);
      return;
    }
  }
  formClose.value.closeCause = "";
  visibledel.value = true;
};

const submitClose = async () => {
  if (!formClose.value.closeCause) {
    message.warning("请填写关闭原因");
    return;
  }

  let form = {
    closeCause: formClose.value.closeCause,
    list: [],
  };

  let list = warningList.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  if (list && list.length) {
    form.list = list.map((d: any) => ({
      date: d.date,
      condition: d.condition,
      diagnosticNotes: d.diagnosticNotes,
      id: d.id,
    }));
  }

  let result = await request.post("/api/workflows/close", form);
  if (result && result.status == 200) {
    message.success(langObj.value["关闭成功"] + "!");
    visibledel.value = false;
    getWarningList();
  } else {
    message.warning(langObj.value["操作失败"]);
  }
};

// 查看已关闭
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};

let visibleReport = ref(false);
let reportId = ref("");
let mrDref = ref();
const viewReport = (ev: any) => {
  // console.log("ev", ev);
  // if(ev.type === 2 && ev.reportIds.length) {
  //     getRepairInfo(ev.reportIds[0])
  //     visibleRepair.value = true
  // }

  visibleReport.value = true;
  reportId.value = ev.reportIds[0];
  setTimeout(() => {
    mrDref.value.show(ev.reportIds[0]);
  }, 500);
};
const cancel = (ev: any) => {
  if (ev) {
    visibleReport.value = false;
    reportId.value = "";
  }
};

// 选择菜单
const selectMenu = (ev: any) => {
  menuSelect.value = ev;
};

//编辑设备
let machineFileList: any = ref([]);
let machineForm: any = ref({
  machineCode: null,
  groupId: null,
  type: null,
  machineName: "",
  position: null,
  supplier: null,
  model: null,
  machineType: null,
  machineTypeId: null,
  sn: null,
  sort: null,
  experiment: false,
  remark: null,
  picture: "",
  factoryId: "",
  parentFactoryId: "",
  params: {},
  bitNumber: null,
  machinePower: null,
  mode: [],
});

let machineTypeListAll: any = ref([]);
let visibleAddMachine = ref(false);
let macForm = ref();
let machineTypeList = ref([]);
let groupList: any = ref([]);
let whetherList = [
  { label: "是", value: true },
  { label: "否", value: false },
];

const editMachine = async () => {
  groupList.value = getGroupListByFactory();

  machineFileList.value = [];
  let dataValue = JSON.parse(JSON.stringify(machineInfo.value));
  if (!dataValue.params) {
    dataValue.params = {}
  }
  if (!dataValue.mode) {
    dataValue.mode = []
  }
  delete dataValue.machineTypeAll;
  machineForm.value = dataValue;
  if (dataValue.machineTypeId) {
    let info: any = machineTypeListAll.value.find(
      (p: any) => p.id === dataValue.machineTypeId
    );
    if (info) {
      machineForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      machineForm.value.type = null
    }

  }

  if (machineForm.value.pictures?.length > 0) {
    machineForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = machineForm.value.pictures;
  }

  visibleAddMachine.value = true;
  setTimeout(() => {
    macForm.value.clearValidate();
  }, 200);
};

// 选择区域
const selectGroupM = (ev: any) => {
  machineForm.value.factoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.factoryId;
  machineForm.value.parentFactoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.parentId;
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      // console.log('list1', list1)
      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.SecondCategory === d2 && p.FirstCategory === d1)
          .map((p: any) => p.ThirdCategory);
        // console.log('list2', list2)

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    machineTypeList.value = list;

  }
};

const submitMachine = () => {
  macForm.value?.clearValidate();
  macForm.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(machineForm.value));

      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 1,
        }));
      }

      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      let result = form.id
        ? await request.put("/api/machines/info", form)
        : await request.post("/api/machines", form);
      if (result && result.status === 200) {
        message.success("操作成功");
        visibleAddMachine.value = false;
        init();
      } else {
        message.warning("操作失败,请联系管理员!");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 下载报警
let downLoadVisible = ref(false);
let downLoadList: any = ref([]);
const downLoad = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 100000,
      machineId: machine_id.value,
      typeList: [1, 4],
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (warnSearchModel.value.type) {
    delete config.params.typeList;
    config.params.type = warnSearchModel.value.type;
  }

  if (warnSearchModel.value.date && warnSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnSearchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(
      new Date(warnSearchModel.value.date[1]),
      2
    );
  }

  if (warnSearchModel.value.condition) {
    config.params.conditionList = conditionList.find(
      (p: any) => p.valueR === warnSearchModel.value.condition
    )?.value;
  }

  let result = await request.get("/api/workflows", config);
  let list: any = [];
  if (result) {
    list = result.data.list;
  }
  downLoadList.value = list;
  downLoadVisible.value = true;
};

// 新增编辑测点
let visibleAddSensor = ref(false);
let selectMachineVisible = ref(false);
let selectSensorVisible = ref(false);
let sensorForm: any = ref({
  sensorSn: null,
  sensorPlace: "",
  machineName: "",
  machineId: "",
  fcSensorId: "",
  fcSensorPlace: "",
  machineTypeId: "",
  national: "",
  remark: "",
  type: null,
  sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
  disable: false,
  onOff: null,
  quotaInterval: null,
  inspectionSubId: null,
  inspectionSubs: null
});
let powerEtcList: any = ref([]);
let dataTypeList = ref<any>();
const sensorAddColumns = [
  {
    title: langObj.value["传感器编号"],
    dataIndex: "sonId",
  },
  {
    title: langObj.value["安装坐标"],
    dataIndex: "coordinate",
  },
  {
    title: langObj.value["轴名称"],
    dataIndex: "sensorGroupName",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "value",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
let sensorForm2: any = ref();

const loadDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      dataTypeList.value = res.data.map((t: any) => ({
        value: t.name,
        label: t.name,
      }));
    }
  });
};

//新增
const addSensor = () => {
  sensorForm.value = {
    sensorSn: null,
    sensorPlace: "",
    machineName: "",
    machineId: "",
    fcSensorId: "",
    fcSensorPlace: "",
    machineTypeId: "",
    national: null,
    remark: "",
    dataType: [],
    type: null,
    sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
    disable: false,
    onOff: null,
    quotaInterval: null,
    inspectionSubId: null,
    inspectionSubs: null
  };
  machineFileList.value = [];
  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm2.value.clearValidate();
  }, 200);
};
let speedList: any = ref([])

// 选择巡检设备细分
const changeISub = (ev: any) => {
  if (ev?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === ev[0] && p.secondCategory === ev[1] && p.thirdCategory === ev[2])
  } else {
    speedList.value = []
  }
}

// 编辑
const editSensor = async (ev: any) => {
  machineFileList.value = [];
  let evValue = JSON.parse(JSON.stringify(ev));
  sensorForm.value = evValue;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
      (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      sensorForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      sensorForm.value.type = null
    }

  }
  if (ev.machine) {
    sensorForm.value.machineName = ev.machine.machineName;
  }

  if (sensorForm.value.pictures?.length > 0) {
    sensorForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = sensorForm.value.pictures;
  }

  if (sensorForm.value?.inspectionSubs?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === sensorForm.value.inspectionSubs[0] && p.secondCategory === sensorForm.value.inspectionSubs[1] && p.thirdCategory === sensorForm.value.inspectionSubs[2])
  } else {
    speedList.value = []
  }

  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm2.value.clearValidate();
  }, 200);
};

// 选择类型
const changeThirdTypeId = async (ev: any) => {
  if (ev && ev.length === 3) {
    let info = machineTypeListAll.value.find(
      (p: any) =>
        p.FirstCategory === ev[0] &&
        p.SecondCategory === ev[1] &&
        p.ThirdCategory === ev[2]
    );
    sensorForm.value.machineTypeId = info.id;

    let config = {
      params: { id: info.id },
      headers: {
        requestId: uuidv4(),
      },
    };

    let result = await request.get(
      "/api/sensors/machine-types/power-etc",
      config
    );
    if (!result?.data) {
      powerEtcList.value = [];
      sensorForm.value.national = null;
      return;
    }

    powerEtcList.value = result.data;
    if (result.data.length > 0) {
      sensorForm.value.national = result.data[0].powerEtc;
    } else {
      sensorForm.value.national = null;
    }
  }
};

const addSensorParmar = () => {
  sensorForm.value.sensorGroup.push({ coordinate: "" });
};

const deleteSensorParmar = (ev: any) => {
  sensorForm.value.sensorGroup.splice(ev, 1);
};

// 选择设备
const selectMachineS = () => {
  selectMachineVisible.value = true;
};

const selectMachineSen = (ev: any) => {
  selectMachineVisible.value = false;
  if (ev) {
    sensorForm.value.machineId = ev.id;
    sensorForm.value.machineName = ev.machineName;
  } else {
    sensorForm.value.machineId = "";
    sensorForm.value.machineName = "";
  }
};

//选择转频提取测点
const selectSensorS = () => {
  selectSensorVisible.value = true;
};

const selectSensorSen = (ev: any) => {
  selectSensorVisible.value = false;
  if (ev) {
    sensorForm.value.fcSensorId = ev.id;
    sensorForm.value.fcSensorPlace = ev.sensorPlace;
  } else {
    sensorForm.value.fcSensorId = "";
    sensorForm.value.fcSensorPlace = "";
  }
};

const submitSensor = () => {
  sensorForm2.value?.clearValidate();
  sensorForm2.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(sensorForm.value));
      if (form.inspectionSubs?.length !== 3) {
        form.inspectionSubs = []
      }
      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 9,
        }));
      }

      let result: any = form.id
        ? await request.put("/api/sensors/info", form)
        : await request.post("/api/sensors", form);

      if (result && result.status) {
        message.success("操作成功");
        visibleAddSensor.value = false;
        getSensorList();
      } else {
        message.warning("操作失败");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 获取有效期
const getValidity = (ev: any) => {
  if (!ev) {
    return ''
  }

  let now = new Date().getTime()
  let end = new Date(ev).getTime()

  if (now >= end) {
    return '已过期'
  } else {
    let date = Math.round((end - now) / (1000 * 60 * 60 * 24))
    return date + '天'
  }
}

// 获取巡检设备细分
const getInspectionSubs = (ev: any) => {
  if (ev?.length !== 3) {
    return ''
  } else {
    return ev.join('/')
  }
}

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "odd-row" : "even-row";
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


// 查看图片
let previewImage2 = ref('')
let previewVisible2 = ref(false)
let previewTitle2 = ref('')

const handlePreview2 = async (file: any) => {
  previewImage2.value = file.url;
  previewVisible2.value = true;
  previewTitle2.value = "图片";
};

const handleCancel2 = () => {
  previewImage2.value = '';
  previewVisible2.value = false;
  previewTitle2.value = "";
}


// 可复制的设备3D图
let machineId_copy = ref('')
let machineList_copy = ref([])

// 获取该工厂所有设备
const getMachineAll = async () => {
  machineList_copy.value = []
  let config = {
    parmar: {
      factoryId: localStorage.getItem('factory_id')
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let factory_id = ''
  if (localStorage.getItem('factory_id')) {
    factory_id = localStorage.getItem('factory_id')
  } else {
    return
  }

  let result = await request.post("/api/machines/all", { factoryIds: [factory_id] })
  console.log('result', result)
  if (result?.data?.length > 0) {
    machineList_copy.value = result.data
  }

}

// 选择覆盖的设备
const selectMachineCopy = async () => {

  let config: any = {
    params: {
      machineId: machineId_copy.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let id = config_3d.value?.id
  config_3d.value = { marks: [] };
  let result = await request.get("/api/machine-3D", config);
  if (result?.data) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);
      if (res.data && res.data.picture) {
        result.data = {
          picture: res.data.picture,
          marks: [],
        };
      }
    }
    result.data.id = id

    config_3d.value = result.data || {
      marks: [],
    };
    markList.value = config_3d.value.marks.map((d: any) => {
      return {
        id: d.id,
        x: d.x,
        y: d.y,
        color: d.color,
        available: d.available,
        bind: '',
      };
    })
  }

  setTimeout(() => {
    $("#container").ZoomMark({
      markColor: "red",
      afterMark: addRowToTabel,
      showMarkNumber: false,
      markList: [],
    });
    setTimeout(() => {
      if (config_3d.value.img) {
        $("#container").ZoomMark("config", config_3d.value.img);
      }

      setTimeout(() => {
        if (config_3d.value.marks.length) {
          $("#container").ZoomMark("addMarks", config_3d.value.marks);
        }
      }, 100);
    }, 100);

    $("#reset").click(function () {
      $("#container").ZoomMark("reset");
    });
    $("#rotate").click(function () {
      angle = angle + 90 >= 360 ? 0 : angle + 90;

      $("#container").ZoomMark("rotate", angle);
    });
  }, 100);


}



return (_ctx: any,_cache: any) => {
  const _component_poweroff_outlined = _resolveComponent("poweroff-outlined")!
  const _component_smile_outlined = _resolveComponent("smile-outlined")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_SyncOutlined = _resolveComponent("SyncOutlined")!
  const _component_SwapOutlined = _resolveComponent("SwapOutlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_switch = _resolveComponent("a-switch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(machineListLeftComponent, {
      onSelectMachine: selectMachine,
      type: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(machineInfo)?.machineName), 1),
          _createVNode(_component_poweroff_outlined, {
            class: "ml-2",
            style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.status?.color }, {"border-radius":"50%","padding":"2px","font-size":"14px"}])
          }, null, 8, ["style"]),
          _createElementVNode("div", {
            style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.status?.color }, {"font-size":"12px","height":"13px","padding":"0 2px","transform":"translateX(-2.5px)","border-radius":"2px"}])
          }, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.status?.label]), 1)
          ], 4),
          _createVNode(_component_smile_outlined, {
            class: "ml-2",
            style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.condition?.color }, {"border-radius":"50%","padding":"2px","font-size":"14px"}])
          }, null, 8, ["style"]),
          _createElementVNode("div", {
            style: _normalizeStyle([{ 'background-color': _unref(machineInfo)?.condition?.color }, {"font-size":"12px","height":"13px","padding":"0 2px","transform":"translateX(-2.5px)","border-radius":"2px"}])
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.condition?.label]), 1)
          ], 4),
          _cache[51] || (_cache[51] = _createElementVNode("div", { class: "flex-1" }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(pictureListComponent, {
          type: 1,
          picList: _unref(picList)
        }, null, 8, ["picList"]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_a_descriptions, {
            layout: "vertical",
            column: 4,
            contentStyle: { color: '#fff' },
            labelStyle: { color: '#7E8BA9' }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备名称']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).machineName), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['所属工厂']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(getFactoryName)(_unref(machineInfo).factoryId)), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备编号']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).machineCode), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备位置']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).position), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备厂家']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).supplier), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备型号']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).model), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备类型']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(langObj)[_unref(machineInfo).typeName] || _unref(machineInfo).typeName), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备类型缩写']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).machineType), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备SN号']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).sn), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['巡检序号']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).sort), 1)
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_descriptions_item, {
                label: _unref(langObj)['设备描述']
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(machineInfo).remark), 1)
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          })
        ]),
        (_unref(isEditable))
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: editMachine,
              class: "mt-4 px-5 py-1 fff cursor-pointer btn-submit",
              style: {"border":"1px solid #0d53b7cc","background":"#072499","height":"33px","color":"#fff","display":"inline-block"}
            }, _toDisplayString(_unref(langObj)['编辑设备']), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", {
              class: _normalizeClass(["py-3 cursor-pointer", { 'txt-select': _unref(menuSelect) === 1 }]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1)))
            }, _toDisplayString(_unref(langObj)['总貌图']), 3),
            _createElementVNode("div", {
              class: _normalizeClass(["py-3 cursor-pointer ml-24", { 'txt-select': _unref(menuSelect) === 2 }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2)))
            }, _toDisplayString(_unref(langObj)['测点列表']), 3),
            _createElementVNode("div", {
              class: _normalizeClass(["py-3 cursor-pointer ml-24", { 'txt-select': _unref(menuSelect) === 3 }]),
              onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMenu(3)))
            }, _toDisplayString(_unref(langObj)['报警记录']), 3)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_unref(menuSelect) === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", null, [
                  (_unref(isEditable))
                    ? (_openBlock(), _createBlock(_component_a_button, {
                        key: 0,
                        type: "primary",
                        class: "fr btn-submit",
                        onClick: markShow
                      }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_EditOutlined)
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_unref(langObj)['编辑']), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                (_unref(config_3d) && _unref(config_3d).img)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("img", {
                        src: _unref(config_3d).picture,
                        style: _normalizeStyle([{"position":"absolute"}, {
                width: _unref(config_3d).img.width + 'px',
                height: _unref(config_3d).img.height + 'px',
                top: _unref(config_3d).img.y + 'px',
                left: _unref(config_3d).img.x + 'px',
                transform: 'rotate(' + _unref(config_3d).img.rotate + 'deg)',
              }])
                      }, null, 12, _hoisted_16),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d).marks, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (item.bind.indexOf('a-') > -1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "d3_point",
                                style: _normalizeStyle({ top: item.y + 'px', left: item.x + 'px' })
                              }, null, 4))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d).marks, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (item.bind.indexOf('b-') > -1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "d3_bq",
                                style: _normalizeStyle([{
                  top: item.y - 50 + 'px',
                  left: item.x - 100 + 'px',
                  'border-color': getColor(item.condition),
                  'box-shadow': getShadow(item.condition),
                }, {"width":"225px","height":"100px"}])
                              }, [
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("div", _hoisted_18, [
                                    _createElementVNode("div", null, _toDisplayString(item.sensorPlace), 1),
                                    _cache[52] || (_cache[52] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                    _createElementVNode("div", {
                                      style: _normalizeStyle([{
                        'background-color': getColor2(item.condition),
                      }, {"width":"15px","height":"15px","border-radius":"50%"}])
                                    }, null, 4)
                                  ]),
                                  _createElementVNode("div", _hoisted_19, [
                                    _createElementVNode("div", _hoisted_20, [
                                      _createElementVNode("div", null, _toDisplayString(item.hz) + "m/s²", 1),
                                      _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(langObj)['振动加速度']), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_22, [
                                      _createElementVNode("div", null, _toDisplayString(item.sz) + "mm/s", 1),
                                      _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(langObj)['振动速度']), 1)
                                    ]),
                                    _createElementVNode("div", _hoisted_24, [
                                      _createElementVNode("div", null, _toDisplayString(item.temp) + "℃", 1),
                                      _createElementVNode("div", _hoisted_25, _toDisplayString(_unref(langObj)['温度']), 1)
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_26, [
                                    _cache[53] || (_cache[53] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                    _createElementVNode("div", _hoisted_27, _toDisplayString(item.date), 1)
                                  ])
                                ])
                              ], 4))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(line), (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createElementVNode("div", {
                            class: "d3_line",
                            style: _normalizeStyle({
                  top: item.y + 5 + 'px',
                  left: item.x + 5 + 'px',
                  width: item.distance + 'px',
                  transform: 'rotate(' + item.angle + 'deg)',
                })
                          }, null, 4)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_unref(menuSelect) === 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                _createElementVNode("div", null, [
                  (_unref(isEditable))
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        onClick: addSensor,
                        class: "px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: _normalizeStyle([{"background-color":"#072499"}, { width: _unref(language) === 'Chinese' ? '100px' : '150px' }])
                      }, _toDisplayString(_unref(langObj)['新建测点']), 5))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createVNode(_component_a_table, {
                    style: {"width":"100%"},
                    size: "small",
                    pagination: false,
                    "row-class-name": getRowClassName,
                    columns: sensorColumns,
                    "data-source": _unref(data)
                  }, {
                    bodyCell: _withCtx(({ column, text, record }) => [
                      (column.dataIndex === 'groupId')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(_unref(getGroupName)(record.machine.groupId)), 1))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'machineName')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(record.machine.machineName), 1))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'hardware')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString((record?.hardware?.vol || record?.hardware?.vol == 0) ? record.hardware.vol + ' %' : ''), 1))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'validity')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(getValidity(record.validityEnd)), 1))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'inspectionSubs')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(getInspectionSubs(record.inspectionSubs)), 1))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'parameter')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.sensorGroup, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", { key: index }, [
                                _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                                  title: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_36, [
                                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)['传感器编号']) + ": " + _toDisplayString(item.sonId), 1),
                                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)['安装坐标']) + ": " + _toDisplayString(item.coordinate), 1),
                                      _createElementVNode("div", null, _toDisplayString(_unref(langObj)['轴名称']) + ": " + _toDisplayString(item.sensorGroupName), 1)
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_tag, { color: "#2db7f5" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.coordinate), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'act')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 6 }, [
                            (_unref(isEditable))
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  onClick: ($event: any) => (editSensor(record)),
                                  class: "theme-table-link"
                                }, _toDisplayString(_unref(langObj)['编辑']), 9, _hoisted_37))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["data-source"])
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(menuSelect) === 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("div", _hoisted_39, [
                  _createVNode(_component_a_form, {
                    model: _unref(searchModel),
                    name: "horizontal_login",
                    layout: "inline",
                    autocomplete: "off"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['报警时间'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(warnSearchModel).date,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(warnSearchModel).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['报警类型'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(warnSearchModel).type,
                            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(warnSearchModel).type) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(langObj)['请选择报警类型']
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(warnTypeList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.value
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, {
                        label: _unref(langObj)['严重程度'],
                        name: "status"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _unref(warnSearchModel).condition,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(warnSearchModel).condition) = $event)),
                            style: {"width":"220px"},
                            allowClear: "",
                            placeholder: _unref(langObj)['请选择严重程度']
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionList), (item, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: item.valueR
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"]),
                      _createVNode(_component_a_form_item, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_40, [
                            _createElementVNode("div", {
                              onClick: reset,
                              class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                            }, _toDisplayString(_unref(langObj)['重置']), 1),
                            _createElementVNode("div", {
                              class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499"},
                              onClick: search
                            }, _toDisplayString(_unref(langObj)['查询']), 1),
                            _createElementVNode("div", {
                              class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                              style: {"background-color":"#072499"},
                              onClick: downLoad
                            }, _toDisplayString(_unref(langObj)['下载报警']), 1),
                            (_unref(isEditable))
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                                  style: {"background-color":"#072499"},
                                  onClick: closeWarning
                                }, _toDisplayString(_unref(langObj)['关闭报警']), 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["model"])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createVNode(_component_a_table, {
                    style: {"width":"100%"},
                    size: "small",
                    "row-class-name": getRowClassName,
                    columns: _unref(warningColumns),
                    "data-source": _unref(warningList),
                    pagination: warmPagination.value,
                    onChange: changeWarnTable,
                    "row-selection": {
                  selectedRowKeys: _unref(selectedRowKeys),
                  onChange: onSelectChange,
                },
                    rowKey: "id"
                  }, {
                    bodyCell: _withCtx(({ column, text, record }) => [
                      (column.dataIndex === 'type')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                            (text === 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_43, _cache[54] || (_cache[54] = [
                                  _createElementVNode("img", {
                                    style: {"width":"20px"},
                                    src: _imports_0,
                                    alt: ""
                                  }, null, -1)
                                ])))
                              : _createCommentVNode("", true),
                            (text === 2)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_44, _cache[55] || (_cache[55] = [
                                  _createElementVNode("img", {
                                    style: {"width":"20px"},
                                    src: _imports_1,
                                    alt: ""
                                  }, null, -1)
                                ])))
                              : _createCommentVNode("", true),
                            (text === 3)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_45, _cache[56] || (_cache[56] = [
                                  _createElementVNode("img", {
                                    style: {"width":"20px"},
                                    src: _imports_2,
                                    alt: ""
                                  }, null, -1)
                                ])))
                              : _createCommentVNode("", true),
                            (text === 4)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_46, _cache[57] || (_cache[57] = [
                                  _createElementVNode("img", {
                                    style: {"width":"20px"},
                                    src: _imports_3,
                                    alt: ""
                                  }, null, -1)
                                ])))
                              : _createCommentVNode("", true),
                            _createElementVNode("div", _hoisted_47, _toDisplayString(_unref(langObj)[_unref(warnTypeList).find((p) => p.value === text)?.label]), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'condition')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                            _createElementVNode("div", {
                              style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {
                        background: _unref(conditionList).find((p) =>
                          p.value.includes(text)
                        )?.color,
                      }])
                            }, null, 4),
                            _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value.includes(text))
                            ?.label]), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'handleStatus')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                            (text === 3)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  onClick: ($event: any) => (viewClose(record))
                                }, [
                                  _createElementVNode("a", null, _toDisplayString(_unref(langObj)['已关闭']), 1)
                                ], 8, _hoisted_50))
                              : _createCommentVNode("", true),
                            (text !== 3)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_51, _toDisplayString(_unref(handleStatusList).find((p) => p.value === text)
                          ? _unref(langObj)[_unref(handleStatusList).find((p) => p.value === text)
                            ?.label]
                          : _unref(langObj)['未处理']), 1))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true),
                      (column.dataIndex === 'act')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            (
                      record.type === 1 &&
                      record.reportIds &&
                      record.reportIds.length
                    )
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 0,
                                  onClick: ($event: any) => (viewReport(record)),
                                  class: "theme-table-link"
                                }, _toDisplayString(_unref(langObj)['诊断报告']), 9, _hoisted_52))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["columns", "data-source", "pagination", "row-selection"])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_a_modal, {
      class: "whiteBg",
      visible: _unref(visibleEdit),
      "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => (_isRef(visibleEdit) ? (visibleEdit).value = $event : visibleEdit = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"18%","height":"600px"},
      width: "1200px",
      height: "600px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_53, [
          _createElementVNode("div", _hoisted_54, [
            _cache[58] || (_cache[58] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(visibleEdit) //@ts-ignore
 ? visibleEdit.value = false : visibleEdit = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_55, _toDisplayString(_unref(langObj)['总貌图']), 1),
          _createElementVNode("div", _hoisted_56, _toDisplayString(_unref(langObj)['左键按住可拖动，右键添加标记']), 1),
          _createElementVNode("div", _hoisted_57, [
            (_unref(visibleEdit) && visibleReEdit.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_58, [
                  _createElementVNode("img", {
                    src: _unref(config_3d).picture
                  }, null, 8, _hoisted_59),
                  _createElementVNode("button", _hoisted_60, [
                    _createElementVNode("div", null, [
                      _createVNode(_component_SyncOutlined, { style: {"font-size":"22px"} })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_unref(langObj)['旋转']), 1)
                  ]),
                  _createElementVNode("span", _hoisted_61, [
                    _createVNode(_component_a_upload, {
                      onPreview: handlePreview,
                      "custom-request": _unref(customRequest3D),
                      "auto-upload": false,
                      accept: "image",
                      "max-count": 1
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", _hoisted_62, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_SwapOutlined, { style: {"font-size":"22px"} })
                          ]),
                          _createTextVNode(" " + _toDisplayString(_unref(langObj)['更换3D图']), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["custom-request"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_63, [
              _createElementVNode("div", _hoisted_64, [
                _createElementVNode("div", _hoisted_65, [
                  _createVNode(_component_a_select, {
                    value: _unref(machineId_copy),
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (_isRef(machineId_copy) ? (machineId_copy).value = $event : machineId_copy = $event)),
                    style: {"width":"200px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList_copy), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.machineName), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"]),
                  _createElementVNode("div", {
                    onClick: selectMachineCopy,
                    class: "btn-default py-1 px-3 mrs",
                    style: {"width":"83px"}
                  }, _toDisplayString('选择覆盖'))
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(markList), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "item",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_66, _toDisplayString(item.id), 1),
                    _createElementVNode("div", _hoisted_67, [
                      _createVNode(_component_a_select, {
                        value: item.bind,
                        "onUpdate:value": ($event: any) => ((item.bind) = $event),
                        style: {"width":"200px"},
                        options: _unref(options)
                      }, null, 8, ["value", "onUpdate:value", "options"])
                    ]),
                    _createElementVNode("div", {
                      class: "licon",
                      onClick: ($event: any) => (deleteTag(item, index))
                    }, [
                      _createVNode(_component_DeleteOutlined)
                    ], 8, _hoisted_68)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("div", {
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_isRef(visibleEdit) //@ts-ignore
 ? visibleEdit.value = false : visibleEdit = false)),
              class: "btn-default py-1 px-6"
            }, _toDisplayString(_unref(langObj)['关闭']), 1),
            _createElementVNode("div", {
              onClick: save3D,
              class: "btn-default py-1 px-6 mrs"
            }, _toDisplayString(_unref(langObj)['保存']), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibledel),
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => (_isRef(visibledel) ? (visibledel).value = $event : visibledel = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_70, [
          _createElementVNode("div", _hoisted_71, [
            _cache[59] || (_cache[59] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_isRef(visibledel) //@ts-ignore
 ? visibledel.value = false : visibledel = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_72, _toDisplayString(_unref(langObj)['关闭报警']), 1),
          _createVNode(_component_a_form, {
            ref: "formRef",
            name: "advanced_search",
            class: "ant-advanced-search-form",
            style: {"margin-top":"20px"},
            model: _unref(formClose)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        name: "closeCause",
                        label: _unref(langObj)['关闭原因'],
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_textarea, {
                            value: _unref(formClose).closeCause,
                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                            placeholder: "",
                            rows: 4
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_73, [
            _createElementVNode("div", {
              onClick: submitClose,
              style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
              class: "btn-submit"
            }, _toDisplayString(_unref(langObj)['提交']), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibledel2),
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => (_isRef(visibledel2) ? (visibledel2).value = $event : visibledel2 = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_74, [
          _createElementVNode("div", _hoisted_75, [
            _cache[60] || (_cache[60] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_isRef(visibledel2) //@ts-ignore
 ? visibledel2.value = false : visibledel2 = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px","color":"#fff !important"} })
            ])
          ]),
          _cache[61] || (_cache[61] = _createElementVNode("div", {
            class: "text-center w-full",
            style: {"font-size":"24px"}
          }, "关闭报警", -1)),
          _createVNode(_component_a_form, {
            ref: "formRef",
            name: "advanced_search",
            class: "ant-advanced-search-form",
            style: {"margin-top":"20px"},
            model: _unref(formClose)
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { gutter: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_form_item, {
                        name: "closeCause",
                        label: "关闭原因",
                        required: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_textarea, {
                            value: _unref(formClose).closeCause,
                            "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                            placeholder: "",
                            rows: 4,
                            readonly: ""
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_drawer, {
      visible: _unref(visibleReport),
      "onUpdate:visible": _cache[16] || (_cache[16] = ($event: any) => (_isRef(visibleReport) ? (visibleReport).value = $event : visibleReport = $event)),
      class: "custom-class",
      size: "large",
      closable: false,
      placement: "right"
    }, {
      title: _withCtx(() => _cache[62] || (_cache[62] = [])),
      default: _withCtx(() => [
        _createVNode(DiagnosticReportDetails, {
          ref_key: "mrDref",
          ref: mrDref,
          reportId: _unref(reportId),
          onCancel: cancel
        }, null, 8, ["reportId"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibleAddMachine),
      "onUpdate:visible": _cache[35] || (_cache[35] = ($event: any) => (_isRef(visibleAddMachine) ? (visibleAddMachine).value = $event : visibleAddMachine = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1300px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_76, [
          _createElementVNode("div", _hoisted_77, [
            _cache[63] || (_cache[63] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[17] || (_cache[17] = ($event: any) => (_isRef(visibleAddMachine) //@ts-ignore
 ? visibleAddMachine.value = false : visibleAddMachine = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_78, _toDisplayString(_unref(machineForm).id ? _unref(langObj)["编辑设备"] : _unref(langObj)["新建设备"]), 1),
          _createElementVNode("div", _hoisted_79, [
            _createVNode(_component_a_form, {
              ref_key: "macForm",
              ref: macForm,
              model: _unref(machineForm),
              layout: "inline",
              autocomplete: "off",
              class: "w-full",
              "label-col": { style: { width: '180px', wordWrap: 'break-word' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备区域'],
                          name: "groupId",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _unref(machineForm).groupId,
                              "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(machineForm).groupId) = $event)),
                              placeholder: _unref(langObj)['请选择设备区域'],
                              onChange: selectGroupM
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备编号'],
                          name: "machineCode"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machineCode,
                              "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_unref(machineForm).machineCode) = $event)),
                              placeholder: _unref(langObj)['请输入设备编号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备名称'],
                          name: "machineName",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machineName,
                              "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_unref(machineForm).machineName) = $event)),
                              placeholder: _unref(langObj)['请输入设备名称']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备位置'],
                          name: "position"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).position,
                              "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_unref(machineForm).position) = $event)),
                              placeholder: _unref(langObj)['请输入设备位置']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备厂家'],
                          name: "supplier"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).supplier,
                              "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_unref(machineForm).supplier) = $event)),
                              placeholder: _unref(langObj)['请输入设备厂家']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备型号'],
                          name: "model"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).model,
                              "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_unref(machineForm).model) = $event)),
                              placeholder: _unref(langObj)['请输入设备型号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备类型'],
                          name: "type"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_cascader, {
                              style: {"max-width":"236px"},
                              value: _unref(machineForm).type,
                              "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_unref(machineForm).type) = $event)),
                              options: _unref(machineTypeList),
                              placeholder: _unref(langObj)['请选择设备类型']
                            }, null, 8, ["value", "options", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备类型缩写'],
                          name: "machineType"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machineType,
                              "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_unref(machineForm).machineType) = $event)),
                              placeholder: _unref(langObj)['请输入设备类型缩写']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备SN号'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).sn,
                              "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_unref(machineForm).sn) = $event)),
                              placeholder: _unref(langObj)['请输入设备SN号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['巡检序号'],
                          name: "sort",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input_number, {
                              controls: false,
                              style: {"width":"100%"},
                              value: _unref(machineForm).sort,
                              "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_unref(machineForm).sort) = $event)),
                              placeholder: "请输入"
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备位号'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).bitNumber,
                              "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_unref(machineForm).bitNumber) = $event)),
                              placeholder: _unref(langObj)['请输入设备位号']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备功率'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).machinePower,
                              "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_unref(machineForm).machinePower) = $event)),
                              placeholder: _unref(langObj)['请输入设备功率']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备ID'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).params.ysshId,
                              "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => ((_unref(machineForm).params.ysshId) = $event)),
                              placeholder: _unref(langObj)['请输入设备ID']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备执行人'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(machineForm).params.ysshExecutor,
                              "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_unref(machineForm).params.ysshExecutor) = $event)),
                              placeholder: _unref(langObj)['请输入设备执行人']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['检测类别'],
                          name: "sn"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_checkbox_group, {
                              value: _unref(machineForm).mode,
                              "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_unref(machineForm).mode) = $event))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_checkbox, { value: 1 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)['在线监测']), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_checkbox, { value: 2 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_unref(langObj)['巡检']), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备描述'],
                          name: "remark"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: _unref(machineForm).remark,
                              "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_unref(machineForm).remark) = $event)),
                              placeholder: "请输入",
                              rows: 4
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备照片']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_upload, {
                              "file-list": _unref(machineFileList),
                              "onUpdate:fileList": _cache[34] || (_cache[34] = ($event: any) => (_isRef(machineFileList) ? (machineFileList).value = $event : machineFileList = $event)),
                              "list-type": "picture-card",
                              onPreview: handlePreview,
                              "custom-request": _unref(customRequest),
                              "auto-upload": false,
                              accept: "image",
                              "max-count": 6
                            }, {
                              default: _withCtx(() => [
                                (!_unref(machineFileList) || _unref(machineFileList).length < 6)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_80, [
                                      _createVNode(_component_plus_outlined),
                                      _createElementVNode("div", _hoisted_81, _toDisplayString(_unref(langObj)['上传']), 1)
                                    ]))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 1
                            }, 8, ["file-list", "custom-request"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { class: "w-full" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_82, [
                      _createElementVNode("div", {
                        onClick: submitMachine,
                        class: "btn-default py-1 px-6 cursor-pointer btn-submit"
                      }, _toDisplayString(_unref(langObj)['提交']), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: 1400,
      visible: _unref(downLoadVisible),
      "onUpdate:visible": _cache[36] || (_cache[36] = ($event: any) => (_isRef(downLoadVisible) ? (downLoadVisible).value = $event : downLoadVisible = $event)),
      title: "下载",
      maskClosable: false,
      footer: false
    }, {
      default: _withCtx(() => [
        _createVNode(DownLoadComponent, {
          onDownLoadCancel: _ctx.downLoadCancel,
          type: 2,
          dataList: _unref(downLoadList)
        }, null, 8, ["onDownLoadCancel", "dataList"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      class: "whiteBg",
      visible: _unref(visibleAddSensor),
      "onUpdate:visible": _cache[50] || (_cache[50] = ($event: any) => (_isRef(visibleAddSensor) ? (visibleAddSensor).value = $event : visibleAddSensor = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_83, [
          _createElementVNode("div", _hoisted_84, [
            _cache[64] || (_cache[64] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[37] || (_cache[37] = ($event: any) => (_isRef(visibleAddSensor) //@ts-ignore
 ? visibleAddSensor.value = false : visibleAddSensor = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_85, _toDisplayString(_unref(sensorForm).id ? _unref(langObj)["编辑测点"] : _unref(langObj)["新建测点"]), 1),
          _createElementVNode("div", _hoisted_86, [
            _createVNode(_component_a_form, {
              ref_key: "sensorForm2",
              ref: sensorForm2,
              model: _unref(sensorForm),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off",
              class: "w-full",
              "label-col": { style: { width: '150px' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['传感器SN'],
                          name: "sensorSn",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(sensorForm).sensorSn,
                              "onUpdate:value": _cache[38] || (_cache[38] = ($event: any) => ((_unref(sensorForm).sensorSn) = $event)),
                              placeholder: _unref(langObj)['请输入传感器SN']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['安装位置'],
                          name: "sensorPlace",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              value: _unref(sensorForm).sensorPlace,
                              "onUpdate:value": _cache[39] || (_cache[39] = ($event: any) => ((_unref(sensorForm).sensorPlace) = $event)),
                              placeholder: _unref(langObj)['请输入安装位置']
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['所属设备'],
                          name: "machineName",
                          required: ""
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_87, [
                              _createVNode(_component_a_input, {
                                value: _unref(sensorForm).machineName,
                                "onUpdate:value": _cache[40] || (_cache[40] = ($event: any) => ((_unref(sensorForm).machineName) = $event)),
                                placeholder: _unref(langObj)['请选择所属设备'],
                                readonly: ""
                              }, null, 8, ["value", "placeholder"]),
                              _createElementVNode("div", {
                                onClick: selectMachineS,
                                class: "cursor-pointer btnTheme"
                              }, _toDisplayString(_unref(langObj)['选择']), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['转频提取'],
                          name: "fcSensorPlace"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_88, [
                              _createVNode(_component_a_input, {
                                value: _unref(sensorForm).fcSensorPlace,
                                "onUpdate:value": _cache[41] || (_cache[41] = ($event: any) => ((_unref(sensorForm).fcSensorPlace) = $event)),
                                placeholder: _unref(langObj)['请选择转频提取测点'],
                                readonly: ""
                              }, null, 8, ["value", "placeholder"]),
                              _createElementVNode("div", {
                                onClick: selectSensorS,
                                class: "cursor-pointer btnTheme"
                              }, _toDisplayString(_unref(langObj)['选择']), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备类型'],
                          name: "type"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_cascader, {
                              style: {"max-width":"236px"},
                              onChange: changeThirdTypeId,
                              value: _unref(sensorForm).type,
                              "onUpdate:value": _cache[42] || (_cache[42] = ($event: any) => ((_unref(sensorForm).type) = $event)),
                              options: _unref(machineTypeList),
                              placeholder: _unref(langObj)['请选择设备类型']
                            }, null, 8, ["value", "options", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['功率'],
                          name: "national"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              style: {"width":"244px"},
                              value: _unref(sensorForm).national,
                              "onUpdate:value": _cache[43] || (_cache[43] = ($event: any) => ((_unref(sensorForm).national) = $event)),
                              placeholder: _unref(langObj)['请选择功率']
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(powerEtcList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.powerEtc
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.powerEtc), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['是否启用'],
                          name: "disable"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_switch, {
                              checked: _unref(sensorForm).disable,
                              "onUpdate:checked": _cache[44] || (_cache[44] = ($event: any) => ((_unref(sensorForm).disable) = $event)),
                              "checked-children": _unref(langObj)['禁用'],
                              "un-checked-children": _unref(langObj)['启用']
                            }, null, 8, ["checked", "checked-children", "un-checked-children"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(getLanguage)('智能诊断巡检配置', _unref(language)),
                          name: "inspectionSubId"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_cascader, {
                              onChange: changeISub,
                              value: _unref(sensorForm).inspectionSubs,
                              "onUpdate:value": _cache[45] || (_cache[45] = ($event: any) => ((_unref(sensorForm).inspectionSubs) = $event)),
                              options: _unref(options2),
                              placeholder: ""
                            }, null, 8, ["value", "options"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 8 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(getLanguage)('转速范围', _unref(language)),
                          name: "defaultSpeed"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              style: {"width":"244px"},
                              value: _unref(sensorForm).defaultSpeed,
                              "onUpdate:value": _cache[46] || (_cache[46] = ($event: any) => ((_unref(sensorForm).defaultSpeed) = $event)),
                              placeholder: _unref(langObj)['请选择']
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(speedList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item._id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.speedRange), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['数据类型']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _unref(sensorForm).dataType,
                              "onUpdate:value": _cache[47] || (_cache[47] = ($event: any) => ((_unref(sensorForm).dataType) = $event)),
                              mode: "multiple",
                              placeholder: _unref(langObj)['请选择数据类型'],
                              options: _unref(dataTypeList)
                            }, null, 8, ["value", "placeholder", "options"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['备注'],
                          name: "remark",
                          class: "w-full"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_textarea, {
                              value: _unref(sensorForm).remark,
                              "onUpdate:value": _cache[48] || (_cache[48] = ($event: any) => ((_unref(sensorForm).remark) = $event)),
                              "show-count": "",
                              maxlength: 200,
                              placeholder: _unref(langObj)['请输入备注'],
                              rows: 4
                            }, null, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, {
                  class: "w-full",
                  gutter: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 24 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['三轴参数'],
                          name: "sn",
                          class: "w-full"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_89, [
                              _createVNode(_component_a_table, {
                                style: {"width":"100%"},
                                size: "small",
                                pagination: false,
                                locale: { emptyText: ' ' },
                                columns: sensorAddColumns,
                                "data-source": _unref(sensorForm).sensorGroup
                              }, {
                                bodyCell: _withCtx(({ column, text, index, record }) => [
                                  (column.dataIndex === 'act')
                                    ? (_openBlock(), _createElementBlock("a", {
                                        key: 0,
                                        onClick: ($event: any) => (deleteSensorParmar(index)),
                                        class: "theme-table-link"
                                      }, _toDisplayString(_unref(langObj)['删除']), 9, _hoisted_90))
                                    : _createCommentVNode("", true),
                                  (column.dataIndex === 'sonId')
                                    ? (_openBlock(), _createBlock(_component_a_input, {
                                        key: 1,
                                        value: record.sonId,
                                        "onUpdate:value": ($event: any) => ((record.sonId) = $event),
                                        placeholder: _unref(langObj)['请输入编号']
                                      }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                    : _createCommentVNode("", true),
                                  (column.dataIndex === 'coordinate')
                                    ? (_openBlock(), _createBlock(_component_a_input, {
                                        key: 2,
                                        value: record.coordinate,
                                        "onUpdate:value": ($event: any) => ((record.coordinate) = $event),
                                        placeholder: _unref(langObj)['请输入安装坐标']
                                      }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                    : _createCommentVNode("", true),
                                  (column.dataIndex === 'sensorGroupName')
                                    ? (_openBlock(), _createBlock(_component_a_input, {
                                        key: 3,
                                        value: _unref(langObj)[record.sensorGroupName],
                                        "onUpdate:value": ($event: any) => ((_unref(langObj)[record.sensorGroupName]) = $event),
                                        placeholder: _unref(langObj)['请输入轴名称']
                                      }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["data-source"]),
                              _createElementVNode("div", {
                                onClick: addSensorParmar,
                                class: "mx-4 my-2 cursor-pointer btnTheme",
                                style: {"width":"48px"}
                              }, _toDisplayString(_unref(langObj)['添加']), 1)
                            ])
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: _unref(langObj)['测点照片']
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_upload, {
                          "file-list": _unref(machineFileList),
                          "onUpdate:fileList": _cache[49] || (_cache[49] = ($event: any) => (_isRef(machineFileList) ? (machineFileList).value = $event : machineFileList = $event)),
                          "list-type": "picture-card",
                          onPreview: handlePreview,
                          "custom-request": _unref(customRequest),
                          "auto-upload": false,
                          accept: "image",
                          "max-count": 6
                        }, {
                          default: _withCtx(() => [
                            (!_unref(machineFileList) || _unref(machineFileList).length < 6)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                                  _createVNode(_component_plus_outlined),
                                  _createElementVNode("div", _hoisted_92, _toDisplayString(_unref(langObj)['上传']), 1)
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["file-list", "custom-request"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_93, [
              _createElementVNode("div", {
                onClick: submitSensor,
                class: "btn-default py-1 px-6 cursor-pointer btn-submit"
              }, _toDisplayString(_unref(langObj)['提交']), 1)
            ])
          ]),
          (_unref(selectMachineVisible))
            ? (_openBlock(), _createBlock(SelectMachineComponent, {
                key: 0,
                onSelectMachineSen: selectMachineSen,
                visible: _unref(selectMachineVisible)
              }, null, 8, ["visible"]))
            : _createCommentVNode("", true),
          (_unref(selectSensorVisible))
            ? (_openBlock(), _createBlock(SelectSensorComponent, {
                key: 1,
                onSelectSensorSen: selectSensorSen,
                visible: _unref(selectSensorVisible)
              }, null, 8, ["visible"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(previewVisible2),
      title: "图片",
      footer: null,
      onCancel: handleCancel2,
      zIndex: 1001
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "example",
          style: {"width":"100%"},
          src: _unref(previewImage2)
        }, null, 8, _hoisted_94)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})