import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"padding":"0px 0","height":"100%"},
  class: "theme-common"
}
const _hoisted_2 = ["src"]

import { useRouter } from "vue-router";
import { computed, onMounted,watch,ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const router = useRouter();

let query =  router.currentRoute.value.query
// 在组件加载完成后执行一些操作
onMounted(() => {
  
});
const ifream = ref<any>();
watch(
  () => router.currentRoute.value.query,
  (newValue: any) => {
    query =  router.currentRoute.value.query
    
  },
  { immediate: true }
)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      src: _unref(query).url,
      id: "mf",
      class: "mf",
      ref_key: "ifream",
      ref: ifream
    }, null, 8, _hoisted_2)
  ]))
}
}

})