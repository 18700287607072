import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, isRef as _isRef, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_4 = {
  key: 0,
  class: "w-full flex items-center justify-items-center"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { style: {"color":"#fff"} }
const _hoisted_13 = { class: "w-full flex" }
const _hoisted_14 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_15 = { style: {"font-size":"18px"} }
const _hoisted_16 = { class: "mt-2 mb-2" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 0,
  class: "w-full flex justify-center"
}
const _hoisted_19 = { style: {"color":"#fff"} }
const _hoisted_20 = { class: "w-full flex" }
const _hoisted_21 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_22 = { class: "my-2" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "flex justify-center ml-2 mt-4" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = ["onClick"]
const _hoisted_28 = ["onClick"]
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_31 = ["onClick"]
const _hoisted_32 = ["id"]
const _hoisted_33 = {
  key: 0,
  class: "w-full flex justify-center mt-4"
}

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList, getFactoryName } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import DiagnosticReportDetails from "./common/DiagnosticReportDetails2.vue";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getGroupName } from "../../common/tools";
import { getLanguage } from "../../common/translate";
import { message } from "ant-design-vue";
import * as echarts from "echarts";

export default /*@__PURE__*/_defineComponent({
  __name: 'DiagnosticReport',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();

const columns = [
    {
        title: langObj.value['更新时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupName',
    },
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },
    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },
    {
        title: getLanguage('反馈状态'),
        dataIndex: 'feedbackStatus',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]

let gradeList = [
  { value: -1, label: "健康", color: '#61c08f' },
  { value: 0, label: "可用", color: '#d2de49' },
  { value: 1, label: "警戒", color: '#cd5f3b' },
  { value: 2, label: "故障", color: '#921e37' },
];

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let searchModel:any = ref({
    groupId: null,
    machineId: null,
    keyword: '',
    date: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    grade:null,
    order: -1
})
let data:any = ref([])
let groupList:any = ref([])
let machineList:any = ref([])
let visible = ref(false)
let reportId = ref('')
let mrDref = ref()

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'odd-row' : 'even-row';
}

onMounted(() => {
    init()
})

const init = () => {
    getGroupList()
    search()
}

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory()
}

// 选择区域
const selectGroup = (ev:any) => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    searchModel.value.machineId = ''
    if(ev) {
        machineList.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineList.value = []
    }
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        grade:null,
        groupId: null,
        machineId: null,
        keyword: '',
        date: null,
    }
}

// 获取数据
const getData = async() => {
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            type: [1],
            keyword: searchModel.value.keyword,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            machineId: searchModel.value.machineId,
            factoryIds: getRealFactoryIdList(),
            grade:searchModel.value.grade,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)

    if(result && result.status === 200) {
        let member = JSON.parse(localStorage.getItem('memberInfo') || '')
        result.data.list.forEach((element: any) => {
            if (element.expertReport) {
            element.expertReport.faultCause = element.expertReport.faultCause
                ? element.expertReport.faultCause.replace(/\n/g, "<br>")
                : "";
            element.gradeText = element.expertReport.grade

            let faultEvents = ''
            element.expertReport.diagnosisDetails.forEach((item2: any, index2:any) => {
                var grade = gradeList.find((t) => t.value == item2.grade);
                item2.gradeText = grade ? grade.label : "";
                faultEvents = faultEvents + item2.faultEvent
            });

            element.faultEvents = faultEvents
            }
            if (element.machines && element.machines[0]) {
            element.machineName = element.machines[0].machineName;
            // element.groupName = member.groups.find((p:any) => p.id === element.machines[0].groupId)?.name
            element.groupName = getGroupName(element.machines[0]?.groupId)
            }
        });

        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if(Object.keys(sorter).length) { 
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};

const details = (ev:any) => {
    visible.value = true
    reportId.value = ev.id
    setTimeout(() => {
        mrDref.value.show(ev.id)
    }, 500);
}

const cancel = (ev:any) => {
    if(ev) {
        visible.value = false
        reportId.value = ''
    }
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


// 报告反馈
const visibleFeedback = ref<any>(false);
const columnsFeedback = [
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },
    
]
let statusList = [
    {label: getLanguage('已反馈') , value: 1},
    {label: getLanguage('已调整') , value: 2},
    {label: getLanguage('已确认') , value: 3},
    {label: getLanguage('已关闭') , value: 4}
]
let feedbackGradeList = [
    {label: getLanguage('准确', language.value), value: 0},
    {label: getLanguage('轻微', language.value), value: 1},
    {label: getLanguage('略轻微', language.value), value: 2},
    {label: getLanguage('严重', language.value), value: 3},
    {label: getLanguage('略严重', language.value), value: 4},
]
let feedbackForm:any = ref({
    
})
const feedback = (ev:any) => {
    console.log('ev', ev)
    feedbackForm.value = {
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        groupId: ev.machines[0]?.groupId,
        reportId: ev.id,
        date: ev.date,
        status: 1,
        degree: null,
        reason: null,
        reports: [
            {
                date: ev.date,
                gradeText: gradeList.find(p => p.value === ev.gradeText)?.label,
                faultEvents: ev.faultEvents,
                content: ev.content,
            }
        ]
    }

    visibleFeedback.value = true
}

const submitFeedback = async() => {
    console.log('feedbackForm.value', feedbackForm.value)
    let result = await request.post('/api/report-feedbacks', feedbackForm.value)
    console.log('result', result)
    if(result?.data) {
        message.success('操作成功!')
        visibleFeedback.value = false
        getData()
    } else {
        message.warning('操作失败!')
    }
}

// 查看报告反馈
const viewFeedback = async(ev:any) => {
    console.log('ev', ev)
    if(!ev.feedbackId) {
        return message.warning('暂无报告反馈')
    }
    let config: any = {
        params: {
            id: ev.feedbackId
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/report-feedbacks/info', config)
    console.log('result', result)

    feedbackForm.value = {
        id: ev.feedbackId,
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        reportId: ev.id,
        date: ev.date,
        status: 1,
        degree: result?.data?.degree,
        reason: result?.data?.reason,
        reports: [
            {
                date: ev.date,
                gradeText: gradeList.find(p => p.value === ev.gradeText)?.label,
                faultEvents: ev.faultEvents,
                content: ev.content,
            }
        ]
    }

    visibleFeedback.value = true
}

// 确认报告反馈
let visibleSure:any = ref(false)
const columnsFeedbackSure = [
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },
    {
        title: getLanguage('客户反馈'),
        dataIndex: 'degree',
    },
    {
        title: getLanguage('客户反馈原因'),
        dataIndex: 'reason',
    },
    
]
let feedbackSureForm:any = ref({})
let sensorFaults:any = ref([])
const surefeedback = async(ev:any) => {

    let config: any = {
        params: {
            id: ev.feedbackId
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/report-feedbacks/info', config)


    feedbackSureForm.value = {
        feedbackId: ev.feedbackId,
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        reportId: ev.id,
        reports: [
            {
                date: ev.date,
                gradeText: getLanguage(gradeList.find(p => p.value === ev.gradeText)?.label),
                faultEvents: ev.faultEvents,
                content: ev.content,
                degree: result?.data?.degree,
                reason: result?.data?.reason,
            }
        ]
    }

    sensorFaults.value = result?.data?.sensorFaults || []


    sensorFaults.value.map((d:any, i:any) => {
        d.title = d.sensorPlace + '-' + d.faultName
        d.type = '1'
        d.date = null
        getchartData(d,i)
    })

    visibleSure.value = true
}


const selectSensorFault = (index:any) => {
    let data = sensorFaults.value[index]
    console.log('data', data)
    getchartData(data, index)
}

const getchartData = async(data:any, index:any) => {
    console.log('data', data)
    let startDate = "";
    let endDate: any = "";
    if (data.type == 0) {
        if (
            data.date &&
            data.date.length === 2
        ) {
        startDate = transformDate(data.date[0]);
        endDate = transformDate(data.date[1]);
        } else {
            message.warn("请选择时间");
            return;
        }
    } else {
        endDate = new Date();
        let num = 7;
        if (data.type == 2) {
        num = 30;
        }
        if (data.type == 3) {
        num = 60;
        }
        startDate = transformDate(new Date(new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num));
    }
    let search = {
        machineId: feedbackForm.value.machineId,
        // machineId: "649a40c3a12ce3eeaadc6a1e",
        sensorId: data.sensorId,
        tarFunc: data.faultFunction,
        funcTag: data.scoreName,
        paramArray: data.originThresholds,
        dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)]
        // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"]
    }

    let result1 = await request.post('/docs/score/trim-threshold', search)
    // return result?.data?.result || []
    search.paramArray = data.adjustThresholds
    let result2 = await request.post('/docs/score/trim-threshold', search)
    let data1 = result1?.data?.result || []
    let data2 = result2?.data?.result || []

    let xData = data1.map((p:any) => p.date) 
    let chartData = {
        xData,
        yDatas: [
            {
                title: getLanguage('调整前趋势'),
                yData: data1.map((p:any) => p[data.scoreName])
            },
            {
                title: getLanguage('调整后趋势'),
                yData: data2.map((p:any) => p[data.scoreName])
            },
        ]
    }

    sensorFaults.value[index].chartData = chartData
    initChartData(chartData, 'reportfeedback'+index)
}


const initChartData = (data:any, id:any) => {
    let {xData, yDatas} = data
    const ec = echarts as any;
    if (!document.getElementById(id)) return;
    let myChart = ec.init(document.getElementById(id));

    let option: any = null;
    let LengData: any[] = [],
    SelectedData = {},
    YaData,
    SeriesData,
    DataInfo: any[] = [],
    colors = [
        "rgb(87,147,243)",
        "rgb(255,165,0)",
        "rgb(0,255,0)",
        "rgb(255,51,51)",
        "rgb(0,255,255)",
        "rgb(0,128,0)",
        "rgb(32, 157, 179)",
        "rgb(120, 32, 179)",
        "rgb(214, 55, 188)",
    ],
    chartSelectlenged:any = {}

    yDatas.map((item: any, index: any) => {
        LengData.push(item.title);
        chartSelectlenged[item.title] = true
        DataInfo.push({
            name: item.title,
            type: "line",
            data: item.yData,
            smooth: false,
            symbolSize: 3,
            connectNulls: true,
        });
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {

        // 如果该图例状态为false时，则跳过 不push
        if (sel[LengData[n]]) {
            YaData.push({
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",
                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                lineStyle: {
                color: "#fff",
                },
            },
            });
        } else {
            Datas[n].data = [];
        }
        Datas[n].yAxisIndex = 0;
        SeriesData.push(Datas[n]);
        }
        if (YaData.length == 0) {
        YaData = [{ type: "value" }];
        }
        option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: "#fff",
                fontSize: 16,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",
                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }
    Init(chartSelectlenged, DataInfo);

    if (option && typeof option === "object") {
        myChart && myChart.clear();
        myChart && myChart.setOption(option, true);
    }

    myChart
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
      // 得到当前的图例显示隐藏状态分别有哪些
      chartSelectlenged = params.selected;
      Init(chartSelectlenged, DataInfo);
      if (option && typeof option === "object") {
        myChart && myChart?.clear();
        myChart && myChart?.setOption(option, true);
      }
    });



}

const sureFeedback = async() => {
    console.log('feedbackSureForm.value', feedbackSureForm.value?.feedbackId)
    let submit = {
        id: feedbackSureForm.value?.feedbackId
    }
    let result = await request.post('/api/report-feedbacks/confirm', submit)
    console.log('result', result)
    if(result?.status == 200) {
        message.success('操作成功！')
        visibleSure.value = false
        getData()
    } else {
        message.warning('操作失败！')
    }

}

const cancelFeedback = async() => {
    console.log('feedbackSureForm.value', feedbackSureForm.value?.feedbackId)
    let submit = {
        id: feedbackSureForm.value?.feedbackId
    }
    let result = await request.post('/api/report-feedbacks/close', submit)
    console.log('result', result)
    if(result?.status == 200) {
        message.success('操作成功！')
        visibleSure.value = false
        getData()
    } else {
        message.warning('操作失败！')
    }

}


// 查看报告反馈
const viewFeedback2 = async(ev:any) => {
    let config: any = {
        params: {
            id: ev.feedbackId
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/report-feedbacks/info', config)

    console.log('result', result)

    feedbackSureForm.value = {
        isView: true,
        feedbackId: ev.feedbackId,
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        reportId: ev.id,

        
        reports: [
            {
                date: ev.date,
                gradeText: getLanguage(gradeList.find(p => p.value === ev.gradeText)?.label),
                faultEvents: ev.faultEvents,
                content: ev.content,
                degree: result?.data?.degree,
                reason: result?.data?.reason,
            }
        ]

    }

    sensorFaults.value = result?.data?.sensorFaults || []

    console.log('sensorFaults.value', sensorFaults.value)

    sensorFaults.value.map((d:any, i:any) => {
        d.title = d.sensorPlace + '-' + d.faultName
        getchartData(d,i)
    })

    visibleSure.value = true
}



return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_a_form, {
        model: _unref(searchModel),
        name: "horizontal_login",
        layout: "inline",
        autocomplete: "off"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['设备区域'],
            name: "groupId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _unref(searchModel).groupId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                style: {"width":"220px"},
                onChange: selectGroup,
                placeholder: _unref(langObj)['选择设备区域']
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['设备'],
            name: "status"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _unref(searchModel).machineId,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
                placeholder: _unref(langObj)['选择设备'],
                style: {"width":"220px"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['更新时间'],
            name: "status"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_range_picker, {
                value: _unref(searchModel).date,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).date) = $event)),
                "disabled-date": disabledDate,
                onCalendarChange: onCalendarChange
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['严重程度'],
            name: "grade"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _unref(searchModel).grade,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchModel).grade) = $event)),
                style: {"width":"220px"},
                placeholder: _unref(langObj)['选择严重程度']
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: "0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['可用']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "1" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['警戒']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: "2" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['故障']), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['搜索'],
            name: "status"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                style: {"width":"220px"},
                placeholder: _unref(langObj)['请输入关键词搜索'],
                value: _unref(searchModel).keyword,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).keyword) = $event))
              }, null, 8, ["placeholder", "value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  onClick: reset,
                  class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                }, _toDisplayString(_unref(langObj)['重置']), 1),
                _createElementVNode("div", {
                  onClick: search,
                  class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                  style: {"background-color":"#072499"}
                }, _toDisplayString(_unref(langObj)['查询']), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        style: {"width":"100%"},
        size: "small",
        "row-class-name": getRowClassName,
        columns: columns,
        "data-source": _unref(data),
        pagination: pagination.value,
        onChange: handleTableChange
      }, {
        bodyCell: _withCtx(({ column, text, record }) => [
          (column.dataIndex === 'gradeText')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("div", {
                  style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%","margin-right":"5px"}, {'background':  _unref(gradeList).find(p => p.value === text)?.color}])
                }, null, 4),
                _createElementVNode("span", null, _toDisplayString(_unref(langObj)[_unref(gradeList).find(p => p.value === text)?.label ]), 1)
              ]))
            : _createCommentVNode("", true),
          (column.dataIndex === 'faultEvents')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(langObj)[text] || text), 1))
            : _createCommentVNode("", true),
          (column.dataIndex === 'feedbackStatus')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(statusList).find(p => p.value === text)?.label || _unref(getLanguage)('未反馈')), 1))
            : _createCommentVNode("", true),
          (column.dataIndex === 'act')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                (!record.feedbackStatus || record.feedbackStatus === 4)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      onClick: ($event: any) => (feedback(record)),
                      style: {},
                      class: "theme-table-link"
                    }, _toDisplayString(_unref(getLanguage)('报告反馈', _unref(language))), 9, _hoisted_7))
                  : _createCommentVNode("", true),
                (record.feedbackStatus === 1)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      onClick: ($event: any) => (viewFeedback(record)),
                      style: {},
                      class: "theme-table-link"
                    }, _toDisplayString(_unref(getLanguage)('查看报告反馈', _unref(language))), 9, _hoisted_8))
                  : _createCommentVNode("", true),
                (record.feedbackStatus === 3)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 2,
                      onClick: ($event: any) => (viewFeedback2(record)),
                      style: {},
                      class: "theme-table-link"
                    }, _toDisplayString(_unref(getLanguage)('查看报告反馈', _unref(language))), 9, _hoisted_9))
                  : _createCommentVNode("", true),
                (record.feedbackStatus === 2)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      onClick: ($event: any) => (surefeedback(record)),
                      style: {},
                      class: "theme-table-link"
                    }, _toDisplayString(_unref(getLanguage)('确认报告反馈', _unref(language))), 9, _hoisted_10))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_divider, { type: "vertical" }),
                _createElementVNode("a", {
                  onClick: ($event: any) => (details(record)),
                  style: {},
                  class: "theme-table-link"
                }, _toDisplayString(_unref(langObj)['查看详情']), 9, _hoisted_11)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["data-source", "pagination"])
    ]),
    _createVNode(_component_a_drawer, {
      visible: _unref(visible),
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      class: "custom-class",
      size: "large",
      closable: false,
      placement: "right"
    }, {
      title: _withCtx(() => _cache[15] || (_cache[15] = [])),
      default: _withCtx(() => [
        _createVNode(DiagnosticReportDetails, {
          ref_key: "mrDref",
          ref: mrDref,
          reportId: _unref(reportId),
          onCancel: cancel
        }, null, 8, ["reportId"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: visibleFeedback.value,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((visibleFeedback).value = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (visibleFeedback.value = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(getLanguage)('报告反馈', _unref(language))), 1),
          _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(feedbackForm).title), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(getLanguage)('选中报告', _unref(language))) + "：", 1),
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            "row-class-name": getRowClassName,
            columns: columnsFeedback,
            "data-source": _unref(feedbackForm).reports,
            pagination: false
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'faultEvents')
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(langObj)[text] || text), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source"]),
          _createVNode(_component_a_form, {
            style: {"margin-top":"20px"},
            model: _unref(feedbackForm),
            autocomplete: "off",
            "label-col": { style: { width: '80px' } }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('报告反馈', _unref(language)),
                required: "",
                autocomplete: "off",
                "auto-complete": "off"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(feedbackForm).degree,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(feedbackForm).degree) = $event)),
                    disabled: _unref(feedbackForm).id
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedbackGradeList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "disabled"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(getLanguage)('反馈原因', _unref(language)),
                name: "reason",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_textarea, {
                    value: _unref(feedbackForm).reason,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(feedbackForm).reason) = $event)),
                    rows: 4,
                    readonly: _unref(feedbackForm).id
                  }, null, 8, ["value", "readonly"])
                ]),
                _: 1
              }, 8, ["label"])
            ]),
            _: 1
          }, 8, ["model"]),
          (!_unref(feedbackForm).id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", {
                  style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                  class: "btn-submit",
                  onClick: _cache[9] || (_cache[9] = ($event: any) => (submitFeedback()))
                }, _toDisplayString(_unref(langObj)['提交']), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      visible: _unref(visibleSure),
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => (_isRef(visibleSure) ? (visibleSure).value = $event : visibleSure = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"30%"},
      width: "1200px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _cache[17] || (_cache[17] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[11] || (_cache[11] = ($event: any) => (_isRef(visibleSure) //@ts-ignore
 ? visibleSure.value = false : visibleSure = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(feedbackSureForm).title), 1),
          _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(getLanguage)('诊断报告')), 1),
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            "row-class-name": getRowClassName,
            columns: columnsFeedbackSure,
            "data-source": _unref(feedbackSureForm).reports,
            pagination: false
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'faultEvents')
                ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_unref(langObj)[text] || text), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'degree')
                ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_unref(feedbackGradeList).find((p) => p.value === text)?.label), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorFaults), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "mt-4",
              key: index
            }, [
              _createElementVNode("div", null, _toDisplayString(item.title), 1),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", {
                  class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': item.type == '1' }]),
                  style: _normalizeStyle([{
                            background:
                            item.type == '1' ? '#072498' : '',
                            }, {"border-radius":"5px 0 0 5px"}]),
                  onClick: ($event: any) => ((item.type = '1'), selectSensorFault(index))
                }, _toDisplayString(_unref(getLanguage)('近一周')), 15, _hoisted_26),
                _createElementVNode("div", {
                  class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': item.type == '2' }]),
                  style: _normalizeStyle({
                            background:
                            item.type == '2' ? '#072498' : '',
                            }),
                  onClick: ($event: any) => ((item.type = '2'), selectSensorFault(index))
                }, _toDisplayString(_unref(getLanguage)('近一月')), 15, _hoisted_27),
                _createElementVNode("div", {
                  class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': item.type == '3' }]),
                  style: _normalizeStyle({
                            background:
                            item.type == '3' ? '#072498' : '',
                            }),
                  onClick: ($event: any) => ((item.type = '3'), selectSensorFault(index))
                }, _toDisplayString(_unref(getLanguage)('近两月')), 15, _hoisted_28),
                _createElementVNode("div", {
                  class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': item.type == '0' }]),
                  style: _normalizeStyle([{
                            background:
                            item.type == '0' ? '#072498' : '',
                            }, {"border-radius":"0 5px 5px 0"}]),
                  onClick: ($event: any) => (item.type = '0')
                }, _toDisplayString(_unref(getLanguage)('自定义')), 15, _hoisted_29),
                (item.type == '0')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createVNode(_component_a_range_picker, {
                        value: item.date,
                        "onUpdate:value": ($event: any) => ((item.date) = $event)
                      }, null, 8, ["value", "onUpdate:value"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  onClick: ($event: any) => (selectSensorFault(index)),
                  class: "btn3 ml-2"
                }, _toDisplayString(_unref(getLanguage)('查询')), 9, _hoisted_31)
              ]),
              _createElementVNode("div", null, [
                _createElementVNode("div", {
                  id: 'reportfeedback'+index,
                  style: {"height":"340px"},
                  class: "w-full"
                }, null, 8, _hoisted_32)
              ]),
              (!_unref(feedbackSureForm).isView)
                ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                    _createElementVNode("div", {
                      style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (sureFeedback()))
                    }, _toDisplayString(_unref(getLanguage)('确认调整')), 1),
                    _createElementVNode("div", {
                      class: "ml-8",
                      style: {"background-color":"#072499","padding":"5px 10px","width":"100px","font-size":"20px","text-align":"center","cursor":"pointer"},
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (cancelFeedback()))
                    }, _toDisplayString(_unref(getLanguage)('取消调整')), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})