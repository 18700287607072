<template>
    <div class="w-full flex px-4 theme-common">
        <div style="width: 33%">
            <div class="w-full flex items-center mb-4">
                <div>{{getLanguage('智能诊断巡检配置', language)}}:</div>
                <div class="ml-4">
                    <a-cascader v-model:value="searchModel.thirdCategory" :options="options" placeholder="" />
                </div>
                <div class="flex-1"></div>
                <div><a-button type="primary" @click="search">{{ getLanguage("搜索", language) }}</a-button></div>
                <div class="ml-4"><a-button type="primary" @click="add"><template #icon><plus-outlined /></template>{{getLanguage("新增", language)}}</a-button>
                </div>
            </div>

            <a-table :scroll="{ y: 820 }" bordered :columns="columns" :data-source="tableData" :pagination="false" size="small">
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.dataIndex === 'act'">
                            <a :style="{color: record._id===select_id ? 'gray !important' : '' }" @click="selectThis(record)">{{getLanguage('选中', language)}}</a>
                            <a-divider type="vertical" />
                            <a-popconfirm :title="getLanguage('确定删除此条数据么', language)" @confirm="deleteThis(record)">
                                <a >{{getLanguage('删除', language)}}</a>
                            </a-popconfirm>
                            <a-divider type="vertical" />
                            <a @click="editThis(record)">{{getLanguage('编辑', language)}}</a>
                    </template>
                </template>
            </a-table>
        </div>

        <div class="flex-1 ml-4">
            <div class="w-full flex items-center" v-if="select_activeKey === '1'">
                <div>{{ select_title }}</div>
                <div class="flex-1"></div>
                <a-button type="primary" @click="selectAdd">{{ getLanguage("新增", language) }}</a-button>
                <a-button class="ml-4" type="primary" @click="isEdit=true">{{ getLanguage("编辑", language) }}</a-button>
                <a-button v-if="isEdit" class="ml-4" type="primary" @click="saveConfig1">{{ getLanguage("保存", language) }}</a-button>
                <a-button v-if="isEdit" class="ml-4" type="primary" @click="qX1">{{ getLanguage("取消", language) }}</a-button>

                <a-button class="ml-4" type="primary" @click="copy1">{{ getLanguage("复制", language) }}</a-button>
                <a-button class="ml-4" type="primary" @click="paste1">{{ getLanguage("粘贴覆盖", language) }}</a-button>
                <a-popconfirm
                    :title="getLanguage('确定删除所选数据么', language) +'?'"
                    :ok-text="getLanguage('确定', language)"
                    :cancel-text="getLanguage('取消', language)"
                    @confirm="deleteThis1()"
                >
                    <a-button class="ml-4" type="primary" >{{ getLanguage("删除", language) }}</a-button>
                </a-popconfirm>
            </div>

            <div class="w-full flex items-center" v-if="select_activeKey === '2'">
                <div>{{ select_title }}</div>
                <div class="flex-1"></div>
                <a-button class="ml-4" type="primary" @click="isEdit=true">{{ getLanguage("编辑", language) }}</a-button>
                <a-button v-if="isEdit" class="ml-4" type="primary" @click="saveConfig2">{{ getLanguage("保存", language) }}</a-button>
                <a-button v-if="isEdit" class="ml-4" type="primary" @click="qX2">{{ getLanguage("取消", language) }}</a-button>

                <a-button class="ml-4" type="primary" @click="copy2">{{ getLanguage("复制", language) }}</a-button>
                <a-button class="ml-4" type="primary" @click="paste2">{{ getLanguage("粘贴", language) }}</a-button>
            </div>

            <div class="box mt-4 p-4">
                <a-tabs v-model:activeKey="select_activeKey" @change="tabsChange">
                    <a-tab-pane key="1" :tab="getLanguage('健康指标', language)" :disabled="isEdit"></a-tab-pane>
                    <a-tab-pane key="2" :tab="getLanguage('转频参数', language)" :disabled="isEdit"></a-tab-pane>
                </a-tabs>

                <div v-if="select_activeKey === '1'">
                    <a-table
                        bordered
                        :emptyText="'暂无数据'"
                        :row-selection="{
                            selectedRowKeys: mainSelectedRowKeys,
                            onChange: onMainSelectChange,
                        }"
                        rowKey="faultId"
                        :columns="columnsExpert"
                        :data-source="select_dataList"
                        :pagination="false"
                        @change="onMainSelectChange"
                    >
                        <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'scores'">
                            <div v-if="!isEdit">
                            <span  v-for="item in record.scores"
                                :key="item.faultId+(item.name || item.scoreName)">
                                <a-tag
                                v-if="!((item.name || item.scoreName).indexOf('Vexp')>-1)"
                                color="green"
                                :key="item.faultId+(item.name || item.scoreName)"
                                >{{ item.name || item.scoreName }}:{{ item.scoreValue }}</a-tag
                            >
                            </span>
                            
                            </div>
                            <div v-else>
                            <a-form
                                layout="inline" >
                            <span
                            v-for="item in record.scores"
                                :key="item.faultId+(item.name || item.scoreName)"
                            >
                            <a-form-item
                            v-if="!((item.name || item.scoreName).indexOf('Vexp')>-1)"
                                :key="item.faultId+(item.name || item.scoreName)"
                                :label="item.name || item.scoreName"
                                :name="item.name || item.scoreName"
                                :required="item.required"
                            >
                                <a-input-number
                                :key="item.faultId+(item.name || item.scoreName)"
                                :step="0.01"
                                v-model:value="item.scoreValue"
                                :placeholder="getLanguage('请输入', language)"
                                />
                            </a-form-item></span>
                            </a-form>
                            </div>
                        </template>
                        </template>
                    </a-table>
                </div>

                <div v-if="select_activeKey === '2'">
                    <a-table
                        bordered
                        :emptyText="'暂无数据'"
                        :columns="columnsDI"
                        :data-source="select_dataList"
                        :pagination="false"
                    >
                        <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'code'">
                            <a-tag color="green" v-if="!isEdit"
                            >{{ record.code }}:{{ record.paramValue }}</a-tag
                            >
                            <div v-else>
                            <a-form-item
                                :label="record.code"
                                :name="record.code"
                                :required="record.required"
                            >
                                <a-radio-group
                                v-model:value="record.paramValue"
                                v-if="record.dataType == 'radio'"
                                >
                                <a-radio
                                    :value="item"
                                    v-for="item in record.examples"
                                    :key="item"
                                    >{{ item }}</a-radio
                                >
                                </a-radio-group>
                                <a-input-number
                                v-else-if="record.dataType == 'int'"
                                v-model:value="record.paramValue"
                                :placeholder="getLanguage('请输入', language)"
                                :step="1"
                                />
                                <a-input-number
                                v-else-if="record.dataType == 'float'"
                                v-model:value="record.paramValue"
                                :placeholder="getLanguage('请输入', language)"
                                :step="0.01"
                                />
                                <a-input
                                v-else
                                type="text"
                                v-model:value="record.paramValue"
                                :placeholder="getLanguage('请输入', language)"
                                />
                            </a-form-item>
                            </div>
                        </template>
                        </template>
                    </a-table>
                </div>

            </div>

          



        </div>







        <a-modal :width="850" :maskClosable="false" v-model:visible="visible" :title="form._id ? '编辑' : '新增'" @ok="submitMain" >
            <a-form :model="form" ref="formStateRef" name="basic" autocomplete="off"
                :label-col="{ style: { width: '150px' } }">
                <a-form-item :label="getLanguage('一级分类', language)" name="firstCategory" required>
                    <a-select
                        v-model:value="form.firstCategory"
                        style="width: 100%"
                        :options="firstCategoryList.map(item => ({ value: item }))"
                        @change="getSecondList"
                    >
                        <template #dropdownRender="{ menuNode: menu }">
                        <v-nodes :vnodes="menu" />
                        <a-divider style="margin: 4px 0" />
                        <div
                            style="padding: 4px 8px; cursor: pointer"
                            @mousedown="e => e.preventDefault()"
                            @click="addItemCategory(1)"
                        >
                            <div class="flex items-center" style="color: #000">
                                <plus-outlined />
                                添加
                            </div>
                        </div>
                        </template>
                    </a-select>
                </a-form-item>

                <a-form-item :label="getLanguage('二级分类', language)" name="secondCategory" required>
                    <a-select
                        v-model:value="form.secondCategory"
                        style="width: 100%"
                        :options="secondCategoryList.map(item => ({ value: item }))"
                        @change="getThirdList"
                    >
                        <template #dropdownRender="{ menuNode: menu }">
                        <v-nodes :vnodes="menu" />
                        <a-divider style="margin: 4px 0" />
                        <div
                            style="padding: 4px 8px; cursor: pointer"
                            @mousedown="e => e.preventDefault()"
                            @click="addItemCategory(2)"
                        >
                            <div class="flex items-center" style="color: #000">
                                <plus-outlined />
                                添加
                            </div>
                        </div>
                        </template>
                    </a-select>
                </a-form-item>

                <a-form-item :label="getLanguage('三级分类', language)" name="thirdCategory" required>
                    <a-select
                        v-model:value="form.thirdCategory"
                        style="width: 100%"
                        :options="thirdCategoryList.map(item => ({ value: item }))"
                    >
                        <template #dropdownRender="{ menuNode: menu }">
                        <v-nodes :vnodes="menu" />
                        <a-divider style="margin: 4px 0" />
                        <div
                            style="padding: 4px 8px; cursor: pointer"
                            @mousedown="e => e.preventDefault()"
                            @click="addItemCategory(3)"
                        >
                            <div class="flex items-center" style="color: #000">
                                <plus-outlined />
                                添加
                            </div>
                        </div>
                        </template>
                    </a-select>
                </a-form-item>

                <a-form-item :label="getLanguage('转速范围', language)" name="thirdCategory" required>
                    <a-textarea v-model:value="form.speedRange" placeholder="" :rows="3" />
                </a-form-item>


                
            </a-form>
        </a-modal>

        <a-modal :width="400" :maskClosable="false" v-model:visible="visibleAddValue" title="新增分类名称" @ok="submitAddValue" >
            <a-input style="width: 350px" v-model:value="addValue" />
        </a-modal>

        <a-modal
            :width="850"
            :maskClosable="false"
            v-model:visible="visible2"
            title="新增"
            @ok="handleOk"
        >
        <div>
            <a-table
                bordered
                :row-selection="{
                    selectedRowKeys: selectedRowKeys,
                    onChange: onSelectChange,
                }"
                :columns="columnsZB"
                :data-source="baseModelSource"
            />
        </div>
        </a-modal>

    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    ref,
    watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import {
    getFactoryName,
    getGroupName,
    transformDate2,
    getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import SelectMachineComponent from "./select-machine.vue";
import SelectSensorComponent from "./select-sensor.vue";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import { Dayjs } from "dayjs";
import ModelItem from './inspectionThresholdItem/ModelItem.vue';

import { getLanguage } from "../../common/translate";
let language = localStorage.getItem("language") || "Chinese";
const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});


let tableData:any = ref([])
const columns = [
    {
        title: getLanguage('一级分类', language),
        dataIndex: "firstCategory",
        align: "center",
    },
    {
        title: getLanguage('二级分类', language),
        dataIndex: "secondCategory",
        align: "center",
    },
    {
        title: getLanguage('三级分类', language),
        dataIndex: "thirdCategory",
        align: "center",
    },
    {
        title: getLanguage('转速范围', language),
        dataIndex: "speedRange",
        align: "center",
    },
    {
        title: getLanguage('操作', language),
        dataIndex: "act",
        align: "center",
    },
]

let visible:any = ref(false)
let form:any = ref({
    firstCategory: null,
    secondCategory: null,
    thirdCategory: null,
    speedRange: null,
})
let firstCategoryList:any = ref([])
let secondCategoryList:any = ref([])
let thirdCategoryList:any = ref([])

let visibleAddValue = ref(false)
let addValue:any = ref('')
let addValueType:any = ref(1)   //1,2,3

let searchModel:any = ref({
    thirdCategory: null
})
let options:any = ref([])
let tableDataAll:any = ref([])

onMounted(() => {
    init();
});

const init = () => {
    search();
};

// 搜索
const search = async () => {
    console.log('sss', searchModel.value.thirdCategory)
    select_activeKey.value = ''
    let config:any = {
        params: { 
            
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    // if(searchModel.value.thirdCategory?.length === 3) {
    //     config.params.firstCategory = searchModel.value.thirdCategory[0]
    //     config.params.secondCategory = searchModel.value.thirdCategory[1]
    //     config.params.thirdCategory = searchModel.value.thirdCategory[2]
    // }
    
    let result = await request.get('/api/external/inspection/threshold', config)
    if(result?.data) {
        tableDataAll.value = JSON.parse(JSON.stringify( result.data))
        if(searchModel.value.thirdCategory?.length === 3) {
            tableData.value = result.data.filter((p:any) => p.firstCategory===searchModel.value.thirdCategory[0] && p.secondCategory===searchModel.value.thirdCategory[1] && p.thirdCategory===searchModel.value.thirdCategory[2])
        } else {
            tableData.value = result.data
        }
         
        getCategoryList()
        getSearch3()
    }
};

// 获取查询三级结构
const getSearch3 = () => {
    options.value = []
    let first = tableDataAll.value.map((p:any) => p.firstCategory)
    first = [... new Set(first)]
    let list:any = []
    first.map((f:any) => {
        let firstList:any = {
            label: f,
            value: f,
            children: []
        }
        let second = tableDataAll.value.filter((p:any) => p.firstCategory === f).map((p:any) => p.secondCategory)
        second = [... new Set(second)]
        second.map((s:any) => {
            let secondList:any = {
                label: s,
                value: s,
                children: []
            }
            let third = tableDataAll.value.filter((p:any) => p.firstCategory === f && p.secondCategory === s).map((p:any) => p.thirdCategory)
            third = [... new Set(third)]
            third.map((t:any) => {
                let thirdList:any = {
                    label: t,
                    value: t,
                }
                secondList.children.push(thirdList)
            })
            firstList.children.push(secondList)
        })

        list.push(firstList)
    })

    options.value = list
}

// 获取三个分类数组
const getCategoryList = () => {
    let first = tableData.value.map((p:any) => p.firstCategory)
    firstCategoryList.value = [... new Set(first)]
}
// 获取二级分类数组
const getSecondList = () => {
    let list = tableData.value.filter((p:any) => p.firstCategory === form.value.firstCategory).map((p:any) => p.secondCategory)
    secondCategoryList.value = [... new Set(list)]
}
// 获取三级分类数组
const getThirdList = () => {
    let list = tableData.value.filter((p:any) => p.firstCategory === form.value.firstCategory && p.secondCategory === form.value.secondCategory).map((p:any) => p.thirdCategory)
    thirdCategoryList.value = [... new Set(list)]
}

// 新增
const add = async () => {
    form.value = {
        firstCategory: null,
        secondCategory: null,
        thirdCategory: null,
        speedRange: null,
    }
    visible.value = true;
};

// 编辑
const editThis = async (ev:any) => {
    form.value = JSON.parse(JSON.stringify(ev))
    getSecondList()
    getThirdList()
    visible.value = true;
};

// 新增分类选择项
const addItemCategory = (ev:any) => {
    addValue.value = ''
    visibleAddValue.value = true
    addValueType.value = ev
}

// 提交分类名称
const submitAddValue = () => {
    if(addValueType.value === 1) {
        firstCategoryList.value.push(addValue.value)
        form.value.firstCategory = addValue.value
    }
    if(addValueType.value === 2) {
        secondCategoryList.value.push(addValue.value)
        form.value.secondCategory = addValue.value
    }
    if(addValueType.value === 3) {
        thirdCategoryList.value.push(addValue.value)
        form.value.thirdCategory = addValue.value
    }
    visibleAddValue.value = false
}

// 提交主表数据
const submitMain = async() => {
    let result = form.value._id ? await request.put('/api/external/inspection/threshold', form.value)
                : await request.post('/api/external/inspection/threshold', form.value)
    if(result?.data) {
        message.success('操作成功')
        visible.value = false
        search()
    } else {
        message.warning('操作失败请重试或联系管理员！')
    }
}

// 删除
const deleteThis = async (ev:any) => {
    const config = {
        params: { 
            id: ev._id
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    
    let result = await request.delete('/api/external/inspection/threshold', config)
    if(result?.data) {
        message.success('操作成功')
        search()
    } else {
        message.warning('操作失败请重试或联系管理员！')
    }
};

// 阈值配置
const columnsExpert = [
    {
        title: getLanguage('细分故障', language) ,
        dataIndex: "segmentFault",
    },
    {
        title: getLanguage('计算方法', language) ,
        dataIndex: "faultFunction",
    },
    {
        title: getLanguage('指标', language) ,
        dataIndex: "scores",
    },
];
const columnsZB = [
    {
        title: getLanguage('细分故障', language),
        dataIndex: "segmentFault",
    },
    {
        title: getLanguage('计算方法', language),
        dataIndex: "faultFunction",
    },
];
const columnsDI = [
    {
        title: getLanguage('部件类型', language),
        dataIndex: "typeName",
    },
    {
        title: getLanguage('参数释义', language),
        dataIndex: "paramExplan",
    },
    {
        title: getLanguage('配置参数', language),
        dataIndex: "code",
    },
];


let select_id = ref('')
let select_title = ref('')
let select_activeKey = ref('')  //1: 健康指标 2: 转频参数
let isEdit = ref(false)
let select_dataList:any = ref([])

let select_threshold:any = ref(null)
const baseModelSource = ref([]);

let mainSelectedRowKeys:any = ref([]);
let mainSelectedRows:any = ref([]);
let visible2 = ref(false)
const selectedRowKeys = ref<any>();
const selectedRows = ref<any>();


// 选中
const selectThis = async (ev:any) => {
    select_activeKey.value = '1'
    isEdit.value = false
    select_id.value = ev._id
    select_title.value = `${ev.firstCategory}/${ev.secondCategory}/${ev.thirdCategory}/${ev.speedRange}`
    loadBaseModelSource()
    getParmarExpert()
};

const tabsChange = () => {
    isEdit.value = false
    if(select_activeKey.value === '1') {
        loadBaseModelSource()
        getParmarExpert()
    }
    if(select_activeKey.value === '2') {
        getDeviceInfo()
    }
    
}

// 获取所有指标
const loadBaseModelSource = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request
    .post("/api/faultinfos", { FeatureType: "" }, config)
    .then((res: any) => {
      if (res) {
        for (var i = 0; i < res.data.length; i++) {
          if(res.data[i]?.scores?.length) {
            res.data[i].scores = res.data[i].scores.map((t: any) => ({
              ...t,
              scoreValue: t.default,
            }));
          } else {
            res.data[i].scores = []
          }
          
        }

        res.data = res.data.map((t: any) => ({ ...t, key: t.id }));
        baseModelSource.value = res.data.filter((p:any) => p.featureType === "Expect");
      }
    });
};

// 获取专业阈值
const getParmarExpert = async() => {
    const config = {
        params: { 
            id: select_id.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    
    let result = await request.get('/api/external/inspection/threshold/expert', config)
    if(result?.data) {
        select_dataList.value = result.data?.thresholds || []
    }
}

const onMainSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
    mainSelectedRowKeys.value = selectedRowKeys2;
    mainSelectedRows.value = selectedRows2;
};

const selectAdd = () => {
    selectedRowKeys.value = select_dataList.value.map((t: any) => t.faultId);
    visible2.value = true;
}

const onSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
    selectedRowKeys.value = selectedRowKeys2;
    selectedRows.value = selectedRows2;
};

// 添加确定
const handleOk = () => {
    var selected = JSON.parse(JSON.stringify(selectedRows.value))
    select_dataList.value = selected.concat(select_dataList.value);
    select_dataList.value = Array.from(
        select_dataList.value
        .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
        .values()
    );
    
    visible2.value = false;
    isEdit.value = true;
};

// 保存专业阈值
const saveConfig1 = async () => {
    var param:any = {
        inspectionSubId: select_id.value,
        faultFunctions: [],
        scoreNames: [],
        featureType: "Expect"
    };
    var flag = true;
    var msg = "";
    for (var i = 0; i < select_dataList.value.length; i++) {
    var item: any = select_dataList.value[i];
    param.scoreNames.push(item.scoreName);
    param.faultFunctions.push(item.faultFunction);
    param[item.scoreName + "_name"] = item.faultName||item.segmentFault;
    for (var j = 0; j < item.scores.length; j++) {
        var t = item.scores[j];
        if((t.name && t.name.includes('Vexp')) || (t.scoreName && t.scoreName.includes('Vexp'))) {
        t.scoreValue = t.default
        }
        param[t.name || t.scoreName] = t.scoreValue || t.default||t.scoreValue ;
        if (!t.scoreValue && t.scoreValue != 0) {
        msg = "参数：" + (item.featureName || item.mainFault) + '的' + t.name + "未输入";
        flag = false;
        break;
        }
    }
    }
    if (!flag) {
        message.warning(msg);
        return;
    }
    request.put("/api/external/inspection/threshold/expert", param).then((res) => {
        if (res) {
            message.success("操作成功");
            isEdit.value = false;
            getParmarExpert()
        }
    });
};

// 取消
const qX1 = () => {
    isEdit.value = false;
    getParmarExpert()
}

// 删除
const deleteThis1 = () => {
    if (!mainSelectedRows.value||!mainSelectedRows.value.length) {
        message.warning(getLanguage('请选择内容', language));
        return;
    }

    var param: any = {
        inspectionSubId: select_id.value,
        featureType: "Expect",
        faultFunctions: [],
        scoreNames: [],
        scores: [],
    };
    for (var i = 0; i < mainSelectedRows.value.length; i++) {
        var item: any = mainSelectedRows.value[i];
        param.scoreNames.push(item.scoreName);
        param.faultFunctions.push(item.faultFunction);
        for (var j = 0; j < item.scores.length; j++) {
            var t = item.scores[j];
            param.scores.push(t.name || t.scoreName);
        }
        }
        const config = {
            params: param,
            data: param,
        headers: {
            requestId: uuidv4(),
        },
    };

    request.delete("/api/external/inspection/threshold/expert", config).then((res) => {
        if (res) {
            message.success('操作成功');
            isEdit.value = false;
            getParmarExpert()
        }
    });
};


// 复制expect
const copy1 = () => {
    if(! mainSelectedRows.value.length) {
        message.warning('请选择后复制')
        return
    }

    localStorage.setItem('threshold-expect', JSON.stringify(mainSelectedRows.value))
    message.success('复制成功')
}

// 粘贴
const paste1 = () => {
    let list = JSON.parse(localStorage.getItem('threshold-expect') || '[]')
    if(!list?.length) {
        message.warning('请复制后粘贴')
        return
    }
    select_dataList.value = list
    mainSelectedRows.value = []
    mainSelectedRowKeys.value = []
    isEdit.value = true
}


// 获取设备参数列表
const getDeviceInfo = async() => {
    const config = {
        params: { 
            id: select_id.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    
    let result = await request.get('/api/external/inspection/threshold/deviceinfo', config)
    if(result?.data) {
        select_dataList.value = result.data?.deviceParams?.Common || []
    }
}

const saveConfig2 = async() => {
    var param: any = {
        inspectionSubId: select_id.value,
        codes: [],
        type: "Common",
    };
    var flag = true;
    var msg = "";
    for (var i = 0; i < select_dataList.value.length; i++) {
        var item: any = select_dataList.value[i];
        param.codes.push(item.code);
        if (item.required && !item.paramValue) {
          msg = "参数：" + (item.paramName || item.parentCode) + "未输入";
          flag = false;
          break;
        }
        param[item.code ||item.paramName || item.parentCode] = item.paramValue;
    }
    if (!flag) {
        message.warning(msg);
        return;
    }
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };
    request.put("/api/external/inspection/threshold/deviceinfo", param, config).then((res) => {
        if (res) {
          message.success('操作成功');
          isEdit.value = false;
          getDeviceInfo
        }
    });
}

const qX2 = () => {
    isEdit.value = false;
    getDeviceInfo()
}

// 复制expect
const copy2 = () => {
    localStorage.setItem('threshold-deviceinfo', JSON.stringify(select_dataList.value))
    message.success('复制成功')
}

// 粘贴
const paste2 = () => {
    let list = JSON.parse(localStorage.getItem('threshold-deviceinfo') || '[]')
    if(!list?.length) {
        message.warning('请复制后粘贴')
        return
    }
    select_dataList.value = list
    isEdit.value = true
}


</script>

<style lang="less" scoped>
    .box {
        border: 1px solid #0D53B7;
        background-color: rgba(7, 36, 153, 0.2);
    }

</style>
