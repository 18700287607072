import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full px-5 py-6 bule_list_page theme-common" }
const _hoisted_2 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }
const _hoisted_9 = { key: 6 }
const _hoisted_10 = { key: 7 }
const _hoisted_11 = { key: 8 }
const _hoisted_12 = { key: 9 }
const _hoisted_13 = { key: 10 }
const _hoisted_14 = { key: 11 }
const _hoisted_15 = { key: 12 }
const _hoisted_16 = { key: 13 }
const _hoisted_17 = { key: 14 }
const _hoisted_18 = { key: 15 }
const _hoisted_19 = { key: 16 }
const _hoisted_20 = { key: 17 }
const _hoisted_21 = { key: 18 }

import { onMounted, ref } from "vue";

import request from "../../common/request";
import { langList } from "../../common/lang";
import {
  getGroupName,
  transformDate2,
  transformDate,
  getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";

import { Dayjs } from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { getLanguage } from "../../common/translate";


export default /*@__PURE__*/_defineComponent({
  __name: 'SensorStatistics',
  setup(__props) {

let date: any = ref(null);

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
});
let groupList: any = ref([]);
let machineList: any = ref([]);
let data = ref([]);

const sensorColumns = [
  {
    title: getLanguage("设备区域", language.value),
    dataIndex: "groupId",
  },
  {
    title: langObj.value["所属设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["测点位置"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["传感器SN"],
    dataIndex: "sensorSn",
  },
  {
    title: langObj.value["传感器电量"],
    dataIndex: "hardware",
  },
  {
    title: langObj.value["高频加速度"],
    dataIndex: "hrms",
  },
  {
    title: langObj.value["温度"],
    dataIndex: "temp",
  },
  {
    title: langObj.value["低频加速度Z"],
    dataIndex: "lzrms",
  },
  {
    title: langObj.value["低频加速度X"],
    dataIndex: "lxrms",
  },
  {
    title: langObj.value["低频加速度Y"],
    dataIndex: "lyrms",
  },
  {
    title: langObj.value["速度Z"],
    dataIndex: "szrms",
  },
  {
    title: langObj.value["速度X"],
    dataIndex: "sxrms",
  },
  {
    title: langObj.value["速度Y"],
    dataIndex: "syrms",
  },
  {
    title: getLanguage("高加长波形Z", language.value),
    dataIndex: "tw",
  },
  {
    title: getLanguage("高加波形Z", language.value),
    dataIndex: "hz",
  },
  {
    title: getLanguage("低加波形Z", language.value),
    dataIndex: "lz",
  },
  {
    title: getLanguage("低加波形X", language.value),
    dataIndex: "lx",
  },
  {
    title: getLanguage("低加波形Y", language.value),
    dataIndex: "ly",
  },
  {
    title: getLanguage("速度波形Z", language.value),
    dataIndex: "sz",
  },
  {
    title: getLanguage("速度波形X", language.value),
    dataIndex: "sz",
  },
  {
    title: getLanguage("速度波形Y", language.value),
    dataIndex: "sy",
  },
];

onMounted(() => {
  getGroupList();
  searchSensor();
});

// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

const getSensorList = async () => {
  data.value = [];
  let begin: any = "";
  let end: any = "";
  if (date.value && date.value.length === 2) {
    begin = transformDate(date.value[0]);
    end = transformDate(date.value[1]);
  } else {
    end = transformDate(new Date());
    begin = transformDate(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1)
    );
  }
  let config: any = {
    params: {
      factoryId: localStorage.getItem("factory_id"),
      end,
      begin,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors/statistics", config);

  if (result && result.status === 200) {
    data.value = result.data;
  }
};

// 获取测点列表
const searchSensor = () => {
  if (!groupList.value?.length) {
    return;
  }
  searchModel.value.groupId = groupList.value[0].id;
  selectGroup(searchModel.value.groupId);
  if (!machineList.value?.length) {
    return;
  }
  searchModel.value.machineId = machineList.value[0].id;
  getSensorList();
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const exportSensorList = () => {
  const tabledata: any = JSON.parse(JSON.stringify(data.value));
  exportToExcel(tabledata, "测点数据统计" + new Date().getTime());
};

const exportToExcel = (data: any, fileName: any) => {
  data = data.map((t: any) => ({
    '设备区域': t.machine?.groupName,
    '所属设备': t.machine?.machineName,
    '测点位置': t.sensorPlace,
    '传感器SN': t.sensorSn,
    '传感器电量':t.hardware?.vol || t?.hardware?.vol == 0 ? t.hardware.vol + " %" : "",
    '高频加速度': (t.hardware.hrms||'-')+'/'+(t.hardware.hrmsTotal||'-'),
    '温度': (t.hardware.temp||'-')+'/'+(t.hardware.tempTotal||'-'),
    '低频加速度Z': (t.hardware.lzrms||'-')+'/'+(t.hardware.lzrmsTotal||'-'),
    '低频加速度X': (t.hardware.lxrms||'-')+'/'+(t.hardware.lxrmsTotal||'-'),
    '低频加速度Y': (t.hardware.lyrms||'-')+'/'+(t.hardware.lyrmsTotal||'-'),
    '速度Z': (t.hardware.szrms||'-')+'/'+(t.hardware.szrmsTotal||'-'),
    '速度X':(t.hardware.sxrms||'-')+'/'+(t.hardware.sxrmsTotal||'-'),
    '速度Y': (t.hardware.syrms||'-')+'/'+(t.hardware.syrmsTotal||'-'),
    '高加长波形Z': (t.hardware.tw||'-')+'/'+(t.hardware.twTotal||'-'),
    '高加波形Z': (t.hardware.hz||'-')+'/'+(t.hardware.hzTotal||'-'),
    '低加波形Z': (t.hardware.lz||'-')+'/'+(t.hardware.lzTotal||'-'),
    '低加波形X': (t.hardware.lx||'-')+'/'+(t.hardware.lxTotal||'-'),
    '低加波形Y': (t.hardware.ly||'-')+'/'+(t.hardware.lyTotal||'-'),
    '速度波形Z':(t.hardware.sz||'-')+'/'+(t.hardware.szTotal||'-'),
    '速度波形X': (t.hardware.sx||'-')+'/'+(t.hardware.sxTotal||'-'),
    '速度波形Y':(t.hardware.sy||'-')+'/'+(t.hardware.syTotal||'-'),
  }));

  const worksheet = XLSX.utils.json_to_sheet(data);

  //worksheet['!merges'] =merges
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(dataBlob, fileName + ".xlsx");
};

return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _ctx.enUS : _ctx.zhCN
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, {
          model: _unref(searchModel),
          name: "horizontal_login",
          layout: "inline",
          autocomplete: "off"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _unref(langObj)['采样时间'],
              name: "date"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_range_picker, {
                  style: {"width":"300px","margin-left":"20px"},
                  value: _unref(date),
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(date) ? (date).value = $event : date = $event)),
                  "disabled-date": disabledDate,
                  "show-time": "",
                  format: "YYYY-MM-DD HH:mm:ss"
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: getSensorList,
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(langObj)["查询"]), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_a_form_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  onClick: exportSensorList,
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(langObj)["导出"]), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            columns: sensorColumns,
            "data-source": _unref(data),
            pagination: false
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'groupId')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(record.machine?.groupName), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'machineName')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(record.machine?.machineName), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'hardware')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(record?.hardware?.vol || record?.hardware?.vol == 0
                  ? record.hardware.vol + " %"
                  : ""), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'hrms')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString((record?.hardware?.hrms || "-") +
                "/" +
                (record?.hardware?.hrmsTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'temp')
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString((record?.hardware?.temp || "-") +
                "/" +
                (record?.hardware?.tempTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'lzrms')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString((record?.hardware?.lzrms || "-") +
                "/" +
                (record?.hardware?.lzrmsTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'lxrms')
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString((record?.hardware?.lxrms || "-") +
                "/" +
                (record?.hardware?.lxrmsTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'lyrms')
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString((record?.hardware?.lyrms || "-") +
                "/" +
                (record?.hardware?.lyrmsTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'szrms')
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString((record?.hardware?.szrms || "-") +
                "/" +
                (record?.hardware?.szrmsTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'sxrms')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString((record?.hardware?.sxrms || "-") +
                "/" +
                (record?.hardware?.sxrmsTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'syrms')
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString((record?.hardware?.syrms || "-") +
                "/" +
                (record?.hardware?.syrmsTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'tw')
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString((record?.hardware?.tw || "-") +
                "/" +
                (record?.hardware?.twTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'hz')
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString((record?.hardware?.hz || "-") +
                "/" +
                (record?.hardware?.hzTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'lz')
                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString((record?.hardware?.lz || "-") +
                "/" +
                (record?.hardware?.lzTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'lx')
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString((record?.hardware?.lx || "-") +
                "/" +
                (record?.hardware?.lxTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'ly')
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString((record?.hardware?.ly || "-") +
                "/" +
                (record?.hardware?.lyTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'sz')
                ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString((record?.hardware?.sz || "-") +
                "/" +
                (record?.hardware?.szTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'sx')
                ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString((record?.hardware?.sx || "-") +
                "/" +
                (record?.hardware?.sxTotal || "-")), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'sy')
                ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString((record?.hardware?.sy || "-") +
                "/" +
                (record?.hardware?.syTotal || "-")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})