import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"#000A32","overflow-y":"scroll","padding-bottom":"30px","color":"#fff"},
  class: "theme-common"
}

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
// import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";






export default /*@__PURE__*/_defineComponent({
  __name: 'template',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})