import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, isRef as _isRef, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/cliqueD3.png'
import _imports_1 from '@/assets/cliqueD1.png'
import _imports_2 from '@/assets/cliqueD2.png'
import _imports_3 from '@/assets/cliqueD4.png'
import _imports_4 from '@/assets/factory-group.png'
import _imports_5 from '@/assets/factory-machine-1-w.png'
import _imports_6 from '@/assets/factory-machine-1.png'
import _imports_7 from '@/assets/factory-machine-2-w.png'
import _imports_8 from '@/assets/factory-machine-2.png'


const _hoisted_1 = {
  key: 0,
  class: "w-full flex px-4 py-2 theme-bg theme-factory theme-common",
  style: {"background-color":"#000A31","color":"#fff","min-height":"92vh"}
}
const _hoisted_2 = { class: "flex flex-col whiteBgShadow" }
const _hoisted_3 = { class: "title flex items-center" }
const _hoisted_4 = { style: {"font-size":"20px"} }
const _hoisted_5 = { style: {"background":"#fff"} }
const _hoisted_6 = {
  class: "flex items-center mt-2",
  style: {"color":"#06DEF5"}
}
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "ml-2 enW" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = { style: {"font-size":"20px"} }
const _hoisted_11 = { style: {"font-size":"14px"} }
const _hoisted_12 = {
  style: {"color":"#fff"},
  class: "whiteBgColorBlack"
}
const _hoisted_13 = {
  class: "flex items-center",
  style: {"color":"#5B9BFF"}
}
const _hoisted_14 = { class: "ml-2 enW" }
const _hoisted_15 = { class: "flex items-center" }
const _hoisted_16 = { style: {"font-size":"20px"} }
const _hoisted_17 = { style: {"font-size":"14px"} }
const _hoisted_18 = {
  style: {"color":"#fff"},
  class: "whiteBgColorBlack"
}
const _hoisted_19 = {
  class: "flex items-center",
  style: {"color":"#5BFEB7"}
}
const _hoisted_20 = { class: "ml-2 enW" }
const _hoisted_21 = { class: "flex items-center" }
const _hoisted_22 = { style: {"font-size":"20px"} }
const _hoisted_23 = { style: {"font-size":"14px"} }
const _hoisted_24 = {
  style: {"color":"#fff"},
  class: "whiteBgColorBlack"
}
const _hoisted_25 = { class: "title flex items-center mt-7" }
const _hoisted_26 = { style: {"font-size":"20px"} }
const _hoisted_27 = { class: "flex items-center mt-5" }
const _hoisted_28 = { class: "box1" }
const _hoisted_29 = { class: "content" }
const _hoisted_30 = {
  class: "flex w-full items-center",
  style: {"justify-content":"center"}
}
const _hoisted_31 = { style: {"font-size":"20px"} }
const _hoisted_32 = {
  class: "ml-2",
  style: {"font-size":"14px"}
}
const _hoisted_33 = { style: {"background":"#fff"} }
const _hoisted_34 = {
  class: "w-full whiteBgColor",
  style: {"text-align":"center","color":"#fff"}
}
const _hoisted_35 = { class: "box1 ml-5" }
const _hoisted_36 = { class: "content" }
const _hoisted_37 = {
  class: "flex w-full items-center",
  style: {"justify-content":"center"}
}
const _hoisted_38 = { style: {"font-size":"20px"} }
const _hoisted_39 = {
  class: "ml-3",
  style: {"font-size":"14px"}
}
const _hoisted_40 = { style: {"background":"#fff"} }
const _hoisted_41 = {
  class: "w-full whiteBgColor",
  style: {"text-align":"center","color":"#fff"}
}
const _hoisted_42 = { class: "title flex items-center mt-7" }
const _hoisted_43 = { style: {"font-size":"20px"} }
const _hoisted_44 = { class: "w-full" }
const _hoisted_45 = { class: "w-full flex" }
const _hoisted_46 = { class: "mr-5" }
const _hoisted_47 = { class: "w-full flex items-center" }
const _hoisted_48 = {
  key: 0,
  style: {"width":"200px","height":"200px"},
  id: "chartA"
}
const _hoisted_49 = { class: "ml-4" }
const _hoisted_50 = { class: "flex items-center" }
const _hoisted_51 = { class: "ml-2" }
const _hoisted_52 = { class: "flex items-center mt-3" }
const _hoisted_53 = { class: "ml-2" }
const _hoisted_54 = { class: "flex items-center mt-3" }
const _hoisted_55 = { class: "ml-2" }
const _hoisted_56 = { class: "flex items-center mt-3" }
const _hoisted_57 = { class: "ml-2" }
const _hoisted_58 = { class: "w-full flex items-center mt-7" }
const _hoisted_59 = { class: "w-full title flex items-center" }
const _hoisted_60 = { style: {"font-size":"20px"} }
const _hoisted_61 = { class: "w-full" }
const _hoisted_62 = {
  class: "w-full flex items-center theme-table-head",
  style: {"background-color":"#020F46"}
}
const _hoisted_63 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_64 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_65 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_66 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_67 = ["title"]
const _hoisted_68 = { class: "table-info" }
const _hoisted_69 = { class: "table-info" }
const _hoisted_70 = { class: "flex-1 ml-4" }
const _hoisted_71 = { class: "w-full flex items-center" }
const _hoisted_72 = { class: "title flex items-center" }
const _hoisted_73 = { style: {"font-size":"20px"} }
const _hoisted_74 = { class: "w-full flex items-center flex-wrap whiteBg" }
const _hoisted_75 = ["onClick"]
const _hoisted_76 = { class: "w-full flex items-center" }
const _hoisted_77 = ["title"]
const _hoisted_78 = { class: "content flex items-center w-full" }
const _hoisted_79 = {
  class: "w-full flex items-center",
  style: {"text-align":"left"}
}
const _hoisted_80 = { style: {"width":"160px"} }
const _hoisted_81 = { class: "size14 opc8" }
const _hoisted_82 = {
  class: "size18",
  style: {"color":"#06DEF5"}
}
const _hoisted_83 = { style: {"width":"160px"} }
const _hoisted_84 = { class: "size14 opc8" }
const _hoisted_85 = {
  class: "size18",
  style: {"color":"#06DEF5"}
}
const _hoisted_86 = {
  class: "w-full flex items-center",
  style: {"text-align":"left"}
}
const _hoisted_87 = { style: {"width":"160px"} }
const _hoisted_88 = { class: "size14 opc8" }
const _hoisted_89 = {
  class: "size18",
  style: {"color":"#5BFEB7"}
}
const _hoisted_90 = { style: {"width":"160px"} }
const _hoisted_91 = { class: "size14 opc8" }
const _hoisted_92 = {
  class: "size18",
  style: {"color":"#5BFEB7"}
}
const _hoisted_93 = {
  class: "w-full flex items-center",
  style: {"text-align":"left"}
}
const _hoisted_94 = { style: {"width":"160px"} }
const _hoisted_95 = { class: "size14 opc8" }
const _hoisted_96 = {
  class: "size18",
  style: {"color":"#B81212"}
}
const _hoisted_97 = { style: {"width":"160px"} }
const _hoisted_98 = { class: "size14 opc8" }
const _hoisted_99 = {
  class: "size18",
  style: {"color":"#DDD246"}
}
const _hoisted_100 = {
  key: 1,
  class: "w-full flex px-3 py-2 theme-bg theme-factory",
  style: {"background-color":"#000A31","color":"#fff","min-height":"92vh"}
}
const _hoisted_101 = { class: "flex flex-col whiteBgShadow" }
const _hoisted_102 = { class: "title flex items-center" }
const _hoisted_103 = { style: {"font-size":"20px"} }
const _hoisted_104 = { class: "mt-4 flex items-center" }
const _hoisted_105 = {
  key: 0,
  id: "chart2A",
  style: {"width":"240px","height":"240px"}
}
const _hoisted_106 = {
  class: "mt-4",
  style: {"text-align":"center"}
}
const _hoisted_107 = {
  key: 0,
  id: "chart2B",
  style: {"width":"240px","height":"240px"}
}
const _hoisted_108 = {
  class: "mt-4",
  style: {"text-align":"center"}
}
const _hoisted_109 = { class: "title flex items-center mt-7" }
const _hoisted_110 = { style: {"font-size":"20px"} }
const _hoisted_111 = { class: "w-full flex items-center mt-7" }
const _hoisted_112 = { class: "w-full title flex items-center" }
const _hoisted_113 = { style: {"font-size":"20px"} }
const _hoisted_114 = { class: "w-full" }
const _hoisted_115 = {
  class: "w-full flex items-center theme-table-head",
  style: {"background-color":"#020F46"}
}
const _hoisted_116 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_117 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_118 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_119 = {
  class: "table-info",
  style: {"opacity":"0.6"}
}
const _hoisted_120 = {
  class: "table-info",
  style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
}
const _hoisted_121 = { class: "table-info" }
const _hoisted_122 = { class: "table-info" }
const _hoisted_123 = { class: "flex-1 ml-4" }
const _hoisted_124 = { class: "w-full flex items-center" }
const _hoisted_125 = { class: "title flex items-center" }
const _hoisted_126 = { style: {"font-size":"20px"} }
const _hoisted_127 = { class: "ml-6" }
const _hoisted_128 = { class: "flex items-center" }
const _hoisted_129 = {
  key: 0,
  class: "picIcon",
  src: _imports_5,
  alt: ""
}
const _hoisted_130 = {
  key: 1,
  class: "picIcon",
  src: _imports_6,
  alt: ""
}
const _hoisted_131 = { class: "ml-2" }
const _hoisted_132 = { class: "flex items-center" }
const _hoisted_133 = {
  key: 0,
  class: "picIcon",
  src: _imports_5,
  alt: ""
}
const _hoisted_134 = {
  key: 1,
  class: "picIcon",
  src: _imports_6,
  alt: ""
}
const _hoisted_135 = { class: "ml-2" }
const _hoisted_136 = { class: "flex items-center" }
const _hoisted_137 = {
  key: 0,
  class: "picIcon",
  src: _imports_7,
  alt: ""
}
const _hoisted_138 = {
  key: 1,
  class: "picIcon",
  src: _imports_8,
  alt: ""
}
const _hoisted_139 = { class: "ml-2" }
const _hoisted_140 = { class: "w-ful mt-5 box3" }
const _hoisted_141 = { class: "w-full flex items-center" }
const _hoisted_142 = {
  class: "ml-2",
  style: {"font-size":"16px"}
}
const _hoisted_143 = {
  class: "ml-2",
  style: {"font-size":"12px"}
}
const _hoisted_144 = { class: "flex items-center flex-wrap" }
const _hoisted_145 = ["onClick"]
const _hoisted_146 = { class: "w-full flex items-center" }
const _hoisted_147 = ["title"]
const _hoisted_148 = ["onClick"]
const _hoisted_149 = ["onClick"]
const _hoisted_150 = ["onClick"]
const _hoisted_151 = ["onClick"]
const _hoisted_152 = {
  class: "w-full flex items-center",
  style: {"margin-top":"5px"}
}
const _hoisted_153 = {
  class: "flex-1 flex",
  style: {"justify-content":"center"}
}
const _hoisted_154 = ["src"]
const _hoisted_155 = { style: {"margin-left":"15px"} }
const _hoisted_156 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_157 = { class: "text-gray-50 pl-2" }
const _hoisted_158 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_159 = { class: "text-gray-50 pl-2" }
const _hoisted_160 = { class: "flex items-center mt-2" }
const _hoisted_161 = { class: "text-gray-50 pl-2" }
const _hoisted_162 = {
  class: "w-full items-center flex",
  style: {"margin-top":"5px"}
}
const _hoisted_163 = { key: 0 }
const _hoisted_164 = {
  key: 1,
  class: "ml-2 textColor",
  style: {"color":"#4EFAEE"}
}
const _hoisted_165 = ["title"]
const _hoisted_166 = { class: "w-full items-center flex" }
const _hoisted_167 = {
  class: "ml-2 textColor",
  style: {"color":"#4EFAEE"}
}
const _hoisted_168 = {
  class: "ml-2 textColor",
  style: {"color":"#4EFAEE"}
}

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import pictureListComponent from "../components/picture-list.vue";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"

export default /*@__PURE__*/_defineComponent({
  __name: 'factory',
  setup(__props) {

const router = useRouter()
// 区域或者设备大屏显示     1:区域  / 2:设备
let selectModel = ref(1)

let factory_id = ''
let factoryList: any = ref([])
let totalTime = ref(0)
let weightedAverage: any = ref(0)
let groupList: any = ref([])
let machineList: any = ref([])
let sensorList: any = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let conditionSelectFac: any = ref('')
let conditionChartData: any = ref([      //设备健康状态统计 数据
    { label: langObj.value['健康'], num: 0, color: '#61c08f' },
    { label: langObj.value['可用'], num: 0, color: '#d2de49' },
    { label: langObj.value['警戒'], num: 0, color: '#cd5f3b' },
    { label: langObj.value['故障'], num: 0, color: '#921e37' },
])
let machineWarningData: any = ref([])

let listSelectFac: any = ref('')
let listSelectGroup: any = ref('')

let value = ref('')

let visibleChart1 = ref(true)
let visibleChart2 = ref(true)



onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    conditionSelectFac.value = factory_id
    listSelectFac.value = factory_id
    loadFactory()
}

// 获取工厂
const loadFactory = async () => {
    let groups: any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if (result.data && result.data.length) {

        factoryList.value = result.data.filter((p: any) => p._id === factory_id || p.parentId === factory_id)


        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === factory_id)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === factory_id)
            let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
            let currentTime: any = new Date(); // 当前时间
            totalTime.value = Math.floor(
                (currentTime - targetTime) / (1000 * 60 * 60)
            );
        }

        result.data.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });

        getMain(groups);
    }

}


// 获取区域列表
const getMain = async (groups: any) => {
    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(factory_id) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    weightedAverage.value = 0
    groupList.value = [];
    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let faultResult = await request.post('/api/faultinfos', { FeatureType: '' })
        result.data.map((d: any) => {
            let sz = 100000000000
            let temp = 100000000000
            d.quota && d.quota.map((q: any) => {
                if (q.values && q.values.length) {
                    let szInfo = q.values.find((p: any) => p.type === '速度Z')
                    if (szInfo) {
                        if (szInfo.value <= sz) {
                            sz = szInfo.value
                        }
                    }
                    let tempInfo = q.values.find((p: any) => p.type === '温度')
                    if (tempInfo) {
                        if (tempInfo.value <= temp) {
                            temp = tempInfo.value
                        }
                    }
                }
            })

            d.temp = temp == 100000000000 ? 0.00 : temp?.toFixed(2)
            d.sz = sz == 100000000000 ? 0 : sz

            let faultTitle = '设备运行正常'
            if (d.workStableStatus && d.workStableStatus.scores && Object.values(d.workStableStatus.scores).length) {
                let score = 100
                let name = ''
                for (let i in d.workStableStatus.scores) {
                    let value = d.workStableStatus.scores[i]
                    if (value <= score) {
                        score = value
                        name = i
                    }
                }
                if(score < 80) {
                    faultTitle = faultResult.data.find((p: any) => p.scoreName === name)?.segmentFault
                }
                
            }
            d.faultTitle = faultTitle
        })



        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }

                // if (d.workStatus.wkStatus) {
                    if (d.workStableStatus.condition === 0) {
                        weightAll = weightAll + 100
                    }
                    if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                        weightAll = weightAll + 80
                    }
                    if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                        weightAll = weightAll + 60
                    }
                    if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                        weightAll = weightAll + 30
                    }
                    count++
                // }
            })
            machineList.value = machineList.value.concat(item2.machines)
            groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }


    setTimeout(() => {
        getConditionChartData()
        getMachineWarningChartData()
        getGroupListDetail()
    }, 500);

}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStableStatus.condition === 0) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    conditionChartData.value = [
        { label: langObj.value['健康'], num: n1, color: '#61c08f' },
        { label: langObj.value['可用'], num: n2, color: '#d2de49' },
        { label: langObj.value['警戒'], num: n3, color: '#cd5f3b' },
        { label: langObj.value['故障'], num: n4, color: '#921e37' },
    ]

    initConditionChart()
}

// 绘制设备健康状态统计图
const initConditionChart = () => {
    if (!document.getElementById("chartA")) return
    let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

    let legendData = conditionChartData.value.map((t: any) => t.label)
    let DData = conditionChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + langObj.value["设备数"]+"：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: theme.value=='white'?'#333':'#fff',
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num),
                textAlign: "center",
                fontSize: 16,
                fill: theme.value=='white'? '#333':'#fff',
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: ['rgb(28, 196, 139)', 'rgb(206, 224, 8)', 'rgb(223, 87, 45)', 'rgb(162, 3, 53)']
    };

    myChart.setOption(options);
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        // options.graphic.style.text = name;
        if(name){
            let nameArr = name.split("：");
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1];
        }
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"]+":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });

}

// 获取设备报警记录
let warningTypeList = [
    { label: '智能诊断', value: 1 },
    { label: '门限报警', value: 4 },
]
const getMachineWarningChartData = async () => {
    machineWarningData.value = []
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === factory_id)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [factory_id]
    }
    let config: any = {
        params: {
            skip: 1,
            take: 7,
            factoryIds,
            typeList: [1, 4]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    if (result && result.data) {
        machineWarningData.value = result.data.list
        machineWarningData.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}

// 获取严重程度名称颜色
const getMachineWarningName = (condition: any) => {
    let name = '健康'
    let color = '#61c08f'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = '#d2de49'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = '#cd5f3b'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}

// 获取区域清单
let groupListData: any = ref([])
let groupList2: any = ref([{ name: langObj.value['全部区域'], sonID: '' }])
const getGroupListDetail = async () => {
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === listSelectFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [listSelectFac.value]
    }
    groupList2.value = [{ name: langObj.value['全部区域'], sonID: '' }]
    //获取未处理事件
    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            typeList: [1],
            handleStatus: 1,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    let noHandeleList: any = []
    if (result && result.data && result.data.list) {
        noHandeleList = result.data.list
    }

    let groList: any = []
    factoryIds.map((facId: any) => {
        let facInfo = factoryList.value.find((p: any) => p._id === facId)
        let list1: any = []
        groupList2.value = groupList2.value.concat(facInfo.groups)
        list1 = facInfo.groups

        list1 && list1.map((g: any) => {
            let machineNum = 0
            let sensorNum = 0
            let machineRun = 0
            machineNum = machineNum + (g.machines ? g.machines.length : 0)

            g.machines && g.machines.map((m: any) => {
                sensorNum = sensorNum + (m.sensors ? m.sensors.length : 0)
                if (m.workStatus && m.workStatus.wkStatus) {
                    machineRun++
                }
            })

            g.machineNum = machineNum
            g.sensorNum = sensorNum
            g.machineRun = machineRun
            g.machineRate = machineNum ? (((machineRun / machineNum) * 100).toFixed(0) + '%') : '0%'


            let noHandleNum = 0
            let warningMachineNum = 0
            g.machines && g.machines.map((m: any) => {
                let noL = noHandeleList.filter((p: any) => p.machineId === m.id)
                if (noL && noL.length) {
                    noHandleNum = noHandleNum + noL.length
                    let conditionL = [
                        { value: 0, list: [0] },
                        { value: 1, list: [1, 2] },
                        { value: 2, list: [3, 4, 5] },
                        { value: 3, list: [6, 7] },
                    ]

                    if (m.workStableStatus && m.workStableStatus.condition) {
                        let c1: any = conditionL.find((p: any) => p.list.includes(m.workStableStatus.condition))?.value
                        let c2: any = conditionL.find((p: any) => p.list.includes(noL[0].condition))?.value
                        if (c1 >= c2) {
                            warningMachineNum++
                        }
                    }
                }
            })
            g.noHandleNum = noHandleNum
            g.warningMachineNum = warningMachineNum
        })
        groList = groList.concat(list1)
    })

    groupListData.value = groList
}

// 选择区域
let groupInfo: any = ref({ machines: [] })
let machineListShow: any = ref([])
let showSearchModel: any = ref({
    follow: null,
    codnition: null,
    status: null
})
let machine_id = ref('')
const selectGroup = async (ev: any) => {
    if (listSelectGroup.value) {
        selectModel.value = 2
        visibleChart2.value = false
        visibleChart2.value = true

        groupInfo.value = groupListData.value.find((p: any) => p.sonID === listSelectGroup.value)

        if (groupInfo.value.machines?.length > 0) {
            getSensorList(groupInfo.value.machines[0])
            getRandarData(groupInfo.value.machines[0])
            getMachineWarningData(groupInfo.value.machines[0])
            machine_id.value = groupInfo.value.machines[0].id
        }

        getMachineListShow()
    } else {
        selectModel.value = 1
        visibleChart1.value = false
        visibleChart1.value = true
        setTimeout(() => {
            getConditionChartData()
        }, 500);
    }

}

const selectGroup2 = (ev: any) => {
    listSelectGroup.value = ev.sonID
    selectGroup(listSelectGroup.value)
}

//筛选显示设备
const getMachineListShow = () => {
    let list = JSON.parse(JSON.stringify(groupInfo.value.machines))

    if (showSearchModel.value.follow == true) {
        list = list.filter((p: any) => p.follow === showSearchModel.value.follow)
    }
    if(showSearchModel.value.follow == false) {
        list = list.filter((p: any) => !p.follow)
    }


    if (showSearchModel.value.status) {
        if (showSearchModel.value.status === 1) {
            list = list.filter((p: any) => p.workStatus && p.workStatus.wkStatus === 1)
        }
        if (showSearchModel.value.status === 2) {
            list = list.filter((p: any) => !p.workStatus || p.workStatus.wkStatus === 0)
        }
    }
    if (showSearchModel.value.codnition) {
        if (showSearchModel.value.codnition === 1) {
            list = list.filter((p: any) => !p.workStableStatus || p.workStableStatus.condition === 0)
        }
        if (showSearchModel.value.codnition === 2) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 1 && p.workStableStatus.condition <= 2)
        }
        if (showSearchModel.value.codnition === 3) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 3 && p.workStableStatus.condition <= 5)
        }
        if (showSearchModel.value.codnition === 4) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 6 && p.workStableStatus.condition <= 7)
        }
    }

    list.map((p: any) => {
        if (p.pictures?.length > 0) {
            p.url = p.pictures[0].url;
        }
    });

    machineListShow.value = list
}


// 获取雷达图数据
const getRandarData = async (machine: any) => {
    let faultnamesSearch = {
        params: { machineId: machine.id },
        headers: {
            requestId: uuidv4(),
        },
    }
    let result = await request.get('/api/machines/faultnames', faultnamesSearch)
    let faultName = result.data.faultNames
    let faultNameII = result.data.faultNameExpects

    let Slist: any = []
    let Tlist: any = []

    if (machine.workStableStatus && machine.workStableStatus.scores) {
        for (let index in machine.workStableStatus.scores) {
            const item = machine.workStableStatus.scores[index];
            if (index.includes('S')) {
                Slist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title
                })
            }

            if (index.includes('T')) {
                Tlist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
                })
            }
        }
    }

    setTimeout(() => {
        initRandarChart(Tlist, 'chart2A')
        initRandarChart(Slist, 'chart2B')
    }, 500);
}

// 雷达图绘制
const initRandarChart = (dataList: any, id: any) => {
    const ec = echarts as any;
    if (!document.getElementById(id)) return
    let myChart = ec.init(document.getElementById(id));
    myChart.clear()

    if (!dataList.length) return

    let data: any = []
    let error: any = []
    let indicator: any = []
    dataList.map((d: any) => {
        data.push(d.score)
        error.push(60)
        let color = ''
        if (d.score >= 0 && d.score < 60) {
            color = '#CD5F3B'
        }
        if (d.score >= 60 && d.score < 80) {
            color = '#D2DE49'
        }
        if (d.score >= 80 && d.score <= 100) {
            color = theme.value =='white'? '#333':'#ffffff'
        }
        let name = ''
        if(language.value == "Chinese" && d.text) {
        if (d.text.length > 8) {
            name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
        } else if (d.text.length > 4 && d.text.length <= 8) {
            name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
        } else {
            name = d.text
        }
        } 

        if(language.value == "English" && d.text) {
        let list1 = d.text.split(' ')

        let str:any = ''
        let num = 0
        let sy = ''
        list1.map((n:any, i:any) => {
            if(i==0) {
            if(n.length <= 10) {
                num = n.length
                str = n
            } else {
                num = 10
                let a1 = n.substring(0,10)
                sy = n.slice(10)
                str = a1
            }
            } else {

            if(sy) {
                str = str + '\n' ;
                let n2 = sy.length + n.length
                if(n2 <= 10) {
                num = n2
                str = str + sy + ' ' + n
                sy = ''
                } else {
                num = 10
                str = str + sy
                let n2 = 10 - sy.length >=0 ? 10 - sy.length : 0

                let a1 = n.substring(0,n2)
                sy = n.slice(10)
                str = str + a1
                }
            } else {
                if(num + n.length <= 10) {
                str = str + ' ' + n
                num = num + n.length
                } else {
                str = str + '\n'  + n
                num = n.length
                }
            }
            }
        })
        name = str
        }

        indicator.push({
            color,
            max: 100,
            text:name
        })
    })

    const option = {
        title: {
            text: ''
        },
        tooltip: {
            position: { right: '-50px' }
        },
        radar: [
            {
                indicator: indicator,
                center: ['50%', '50%'],
                radius: 50,
                startAngle: 90,
                splitNumber: 5,
                axisName: {
                    formatter: '{value}',
                    fontSize: 10,
                },
                name: {
                    formatter: '{value}',
                    textStyle: {
                        color: 'rgba(46, 214, 157, 1)',
                        fontSize: 11
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: [
                            '#605969',
                            '#453A47',
                        ]
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: [
                            'rgb(241,243,250)'
                        ],
                        width: 0
                    }
                }
            }
        ],
        series: [
            {
                name: '当前值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 4
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: data,
                        lineStyle: {
                            width: 1,
                            color: 'rgba(247,220,225,0.1)',
                            normal: {
                                type: 'dotted'
                            }
                        },
                        itemStyle: {
                            color: '#FF4E13',
                            borderWidth: 5
                        },
                        areaStyle: {
                            color: '#F7DCE1'
                        }
                    }
                ]
            },
            {
                name: '预警值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 1
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: error,
                        name: '',
                        lineStyle: {
                            width: 2,
                            color: 'rgba(255, 25, 59, 0.2)'
                        },
                        itemStyle: {
                            color: 'rgba(255, 25, 59, 0.2)',
                            borderWidth: 0
                        }
                    }
                ]
            }
        ]
    }

    option && myChart.setOption(option)
}

// 获取设备报警记录
let machineWarningData2: any = ref([])
const getMachineWarningData = async (machine: any) => {
    let config: any = {
        params: {
            skip: 1,
            take: 7,
            machineId: machine.id,
            typeList: [1, 4]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    if (result && result.data) {
        machineWarningData2.value = result.data.list
        machineWarningData2.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}


//选择设备
let picList: any = ref([])
let picListAll: any = ref([])
let machine_info: any = ref({})
const selectMachine = (ev: any) => {
    machine_info.value = ev
    machine_id.value = ev.id
    getSensorList(ev)
    getRandarData(ev)
    getMachineWarningData(ev)
}

const getSensorList = async (machine: any) => {
    picList.value = [];
    if (machine.pictures?.length > 0) {
        picList.value.push({ name: machine.machineName, url: machine.pictures[0].url });
    }

    let config: any = {
        params: {
            machineId: machine.id,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/sensors", config);

    if (result && result.status === 200) {
        result.data.map((d: any) => {
            if (d.pictures) {
                d.pictures.map((p: any) => {
                    picList.value.push({
                        name: d.sensorPlace,
                        url: p.url
                    })
                })

            }
        })
    }

    picListAll.value = picList.value

};



//关注、取关
const follow = async (ev: any, value: any) => {
    ev.follow = value
    let form = {
        machineId: ev.id,
        follow: value
    }

    let result = await request.post('/api/machines/follow', form)
    if(result?.data) {
        let info = groupInfo.value.machines.find((p:any) => p.id === ev.id)
        if(info) {
            info.follow = value
        }
    }
}


// 查看更多
const viewMore = () => {
    router.push('/warning-manage/warning-list')
}


return (_ctx: any,_cache: any) => {
  const _component_question_circle_outlined = _resolveComponent("question-circle-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_right_outlined = _resolveComponent("right-outlined")!
  const _component_environment_outlined = _resolveComponent("environment-outlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(selectModel) === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(langObj)['监测范围']), 1),
              _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                title: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", null, _toDisplayString(_unref(langObj)['监控设备数：用户所选组织下的监测设备数统计']), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(langObj)['监控测点数：用户所选组织下的监测测点数统计']), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(langObj)['运行设备数：用户所选组织下的监测设备中运行设备数统计']), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(langObj)['运行率：用户所选组织下的监测设备中运行设备数/监测设备总数']), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_question_circle_outlined, {
                    style: {"font-size":"14px"},
                    class: "ml-2"
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[9] || (_cache[9] = _createElementVNode("img", {
                  src: _imports_0,
                  style: {"width":"40px"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_unref(machineList).length), 1),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(langObj)['监控设备数']), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[10] || (_cache[10] = _createElementVNode("img", {
                  src: _imports_1,
                  style: {"width":"40px"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(sensorList).length), 1),
                    _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(getLanguage)("个", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_18, _toDisplayString(_unref(langObj)['监控测点数']), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _cache[11] || (_cache[11] = _createElementVNode("img", {
                  src: _imports_2,
                  style: {"width":"40px"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("span", _hoisted_22, _toDisplayString(getRunMachine(_unref(machineList)).num), 1),
                    _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(getLanguage)("台", _unref(language))) + "(" + _toDisplayString(getRunMachine(_unref(machineList)).rate) + "%)", 1)
                  ]),
                  _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)['运行设备数(运行率)']), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_25, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
              _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(langObj)['全厂运行情况']), 1)
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _cache[13] || (_cache[13] = _createElementVNode("img", {
                  class: "w-full h-full theme-white-hide",
                  src: _imports_3,
                  alt: ""
                }, null, -1)),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("div", _hoisted_30, [
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(totalTime)), 1),
                    _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(getLanguage)("小时", _unref(language))), 1),
                    _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(langObj)['显示该系统为工厂上线运行的时间']), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_question_circle_outlined, {
                          style: {"font-size":"14px"},
                          class: "ml-2"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_34, _toDisplayString(_unref(langObj)['系统持续服务运行 ']), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_35, [
                _cache[14] || (_cache[14] = _createElementVNode("img", {
                  class: "w-full h-full theme-white-hide",
                  src: _imports_3,
                  alt: ""
                }, null, -1)),
                _createElementVNode("div", _hoisted_36, [
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("div", _hoisted_38, _toDisplayString(_unref(weightedAverage)), 1),
                    _createElementVNode("div", _hoisted_39, _toDisplayString(_unref(getLanguage)("分", _unref(language))), 1),
                    _createVNode(_component_a_tooltip, { placement: "topLeft" }, {
                      title: _withCtx(() => [
                        _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(langObj)['针对运行中的设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分']), 1)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_question_circle_outlined, {
                          style: {"font-size":"14px"},
                          class: "ml-2"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_41, _toDisplayString(_unref(langObj)['全厂运行设备健康分数']), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_42, [
              _cache[15] || (_cache[15] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
              _createElementVNode("span", _hoisted_43, _toDisplayString(_unref(langObj)['设备健康状态统计']), 1)
            ]),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", _hoisted_46, [
                  _createVNode(_component_a_select, {
                    ref: "select",
                    value: _unref(conditionSelectFac),
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(conditionSelectFac) ? (conditionSelectFac).value = $event : conditionSelectFac = $event)),
                    style: {"width":"120px"},
                    size: "small",
                    onChange: getConditionChartData,
                    dropdownMatchSelectWidth: false
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item._id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.factoryName), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value"])
                ])
              ]),
              _createElementVNode("div", _hoisted_47, [
                (_unref(visibleChart1))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_48))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_49, [
                  _createElementVNode("div", _hoisted_50, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#61c08f"} }, null, -1)),
                    _createElementVNode("div", _hoisted_51, _toDisplayString(_unref(langObj)['健康']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[0].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _cache[18] || (_cache[18] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
                    _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(langObj)['可用']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[1].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_54, [
                    _cache[19] || (_cache[19] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
                    _createElementVNode("div", _hoisted_55, _toDisplayString(_unref(langObj)['警戒']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[2].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_56, [
                    _cache[20] || (_cache[20] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
                    _createElementVNode("div", _hoisted_57, _toDisplayString(_unref(langObj)['故障']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[3].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_58, [
              _createElementVNode("div", _hoisted_59, [
                _cache[21] || (_cache[21] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
                _createElementVNode("span", _hoisted_60, _toDisplayString(_unref(langObj)['设备报警记录']), 1)
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                onClick: viewMore,
                class: "flex items-center cursor-pointer whiteBgColor",
                style: {"color":"#ffffffe6"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_unref(langObj)['查看更多']), 1),
                _createVNode(_component_right_outlined)
              ])
            ]),
            _createElementVNode("div", _hoisted_61, [
              _createElementVNode("div", _hoisted_62, [
                _createElementVNode("div", _hoisted_63, _toDisplayString(_unref(langObj)['设备名称']), 1),
                _createElementVNode("div", _hoisted_64, _toDisplayString(_unref(langObj)['报警类型']), 1),
                _createElementVNode("div", _hoisted_65, _toDisplayString(_unref(langObj)['严重程度']), 1),
                _createElementVNode("div", _hoisted_66, _toDisplayString(_unref(langObj)['报警时间']), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineWarningData), (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "w-full flex items-center",
                  key: index,
                  style: _normalizeStyle({ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' })
                }, [
                  _createElementVNode("div", {
                    class: "table-info",
                    style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},
                    title: item.machineName
                  }, _toDisplayString(item.machineName), 9, _hoisted_67),
                  _createElementVNode("div", _hoisted_68, _toDisplayString(_unref(langObj)[_unref(warningTypeList).find(p => p.value === item.type)?.label]), 1),
                  _createElementVNode("div", {
                    class: "table-info",
                    style: _normalizeStyle({ 'color': getMachineWarningName(item.condition)?.color })
                  }, _toDisplayString(_unref(langObj)[getMachineWarningName(item.condition)?.name]), 5),
                  _createElementVNode("div", _hoisted_69, _toDisplayString(item.date), 1)
                ], 4))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_70, [
            _createElementVNode("div", _hoisted_71, [
              _createElementVNode("div", _hoisted_72, [
                _cache[23] || (_cache[23] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
                _createElementVNode("span", _hoisted_73, _toDisplayString(_unref(langObj)['区域清单']), 1)
              ]),
              _createElementVNode("span", null, _toDisplayString(_unref(langObj)['显示单位']) + ":", 1),
              _createVNode(_component_a_select, {
                ref: "select",
                value: _unref(listSelectFac),
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_isRef(listSelectFac) ? (listSelectFac).value = $event : listSelectFac = $event)),
                style: {"width":"120px","margin-left":"20px"},
                onChange: getGroupListDetail,
                dropdownMatchSelectWidth: false
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item._id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.factoryName), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"]),
              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "mx-4" }, ">", -1)),
              _createVNode(_component_a_select, {
                ref: "select",
                value: _unref(listSelectGroup),
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_isRef(listSelectGroup) ? (listSelectGroup).value = $event : listSelectGroup = $event)),
                style: {"width":"120px"},
                onChange: selectGroup
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList2), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item.sonID
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"]),
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex-1" }, null, -1))
            ]),
            _createElementVNode("div", _hoisted_74, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupListData), (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  onClick: ($event: any) => (selectGroup2(item)),
                  key: index,
                  class: "box2 mt-4 mr-4"
                }, [
                  _createElementVNode("div", _hoisted_76, [
                    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "shu" }, null, -1)),
                    _createElementVNode("div", {
                      class: "size18 ml-2",
                      title: item.name,
                      style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}
                    }, _toDisplayString(item.name), 9, _hoisted_77)
                  ]),
                  _cache[28] || (_cache[28] = _createElementVNode("div", { class: "heng" }, null, -1)),
                  _createElementVNode("div", _hoisted_78, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_79, [
                        _createElementVNode("div", _hoisted_80, [
                          _createElementVNode("div", _hoisted_81, _toDisplayString(_unref(langObj)['监测设备']), 1),
                          _createElementVNode("div", _hoisted_82, _toDisplayString(item.machineNum) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                        ]),
                        _createElementVNode("div", _hoisted_83, [
                          _createElementVNode("div", _hoisted_84, _toDisplayString(_unref(langObj)['监测测点']), 1),
                          _createElementVNode("div", _hoisted_85, _toDisplayString(item.sensorNum) + _toDisplayString(_unref(getLanguage)("个", _unref(language))), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_86, [
                        _createElementVNode("div", _hoisted_87, [
                          _createElementVNode("div", _hoisted_88, _toDisplayString(_unref(langObj)['运行设备']), 1),
                          _createElementVNode("div", _hoisted_89, _toDisplayString(item.machineRun) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                        ]),
                        _createElementVNode("div", _hoisted_90, [
                          _createElementVNode("div", _hoisted_91, _toDisplayString(_unref(langObj)['运行率']), 1),
                          _createElementVNode("div", _hoisted_92, _toDisplayString(item.machineRate), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_93, [
                        _createElementVNode("div", _hoisted_94, [
                          _createElementVNode("div", _hoisted_95, _toDisplayString(_unref(langObj)['报警设备']), 1),
                          _createElementVNode("div", _hoisted_96, _toDisplayString(item.warningMachineNum) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                        ]),
                        _createElementVNode("div", _hoisted_97, [
                          _createElementVNode("div", _hoisted_98, _toDisplayString(_unref(langObj)['未处理报警']), 1),
                          _createElementVNode("div", _hoisted_99, _toDisplayString(item.noHandleNum) + _toDisplayString(_unref(getLanguage)("条", _unref(language))), 1)
                        ])
                      ])
                    ]),
                    _cache[27] || (_cache[27] = _createElementVNode("img", { src: _imports_4 }, null, -1))
                  ])
                ], 8, _hoisted_75))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(selectModel) === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_100, [
          _createElementVNode("div", _hoisted_101, [
            _createElementVNode("div", _hoisted_102, [
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
              _createElementVNode("span", _hoisted_103, _toDisplayString(_unref(langObj)['设备诊断']), 1)
            ]),
            _createElementVNode("div", _hoisted_104, [
              _createElementVNode("div", null, [
                (_unref(visibleChart2))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_105))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_106, _toDisplayString(_unref(langObj)['健康指标']), 1)
              ]),
              _createElementVNode("div", null, [
                (_unref(visibleChart2))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_107))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_108, _toDisplayString(_unref(langObj)['振动指标']), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_109, [
              _cache[30] || (_cache[30] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
              _createElementVNode("span", _hoisted_110, _toDisplayString(_unref(langObj)['设备照片']), 1)
            ]),
            _createVNode(pictureListComponent, {
              type: 4,
              picList: _unref(picListAll)
            }, null, 8, ["picList"]),
            _createElementVNode("div", _hoisted_111, [
              _createElementVNode("div", _hoisted_112, [
                _cache[31] || (_cache[31] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
                _createElementVNode("span", _hoisted_113, _toDisplayString(_unref(langObj)['设备报警记录']), 1)
              ]),
              _cache[32] || (_cache[32] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                onClick: viewMore,
                class: "flex items-center cursor-pointer whiteBgColor",
                style: {"color":"#ffffffe6"}
              }, [
                _createElementVNode("span", null, _toDisplayString(_unref(langObj)['查看更多']), 1),
                _createVNode(_component_right_outlined)
              ])
            ]),
            _createElementVNode("div", _hoisted_114, [
              _createElementVNode("div", _hoisted_115, [
                _createElementVNode("div", _hoisted_116, _toDisplayString(_unref(langObj)['设备名称']), 1),
                _createElementVNode("div", _hoisted_117, _toDisplayString(_unref(langObj)['报警类型']), 1),
                _createElementVNode("div", _hoisted_118, _toDisplayString(_unref(langObj)['严重程度']), 1),
                _createElementVNode("div", _hoisted_119, _toDisplayString(_unref(langObj)['报警时间']), 1)
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineWarningData2), (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "w-full flex items-center",
                  key: index,
                  style: _normalizeStyle({ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' })
                }, [
                  _createElementVNode("div", _hoisted_120, _toDisplayString(item.machineName), 1),
                  _createElementVNode("div", _hoisted_121, _toDisplayString(_unref(warningTypeList).find(p => p.value === item.type)?.label), 1),
                  _createElementVNode("div", {
                    class: "table-info",
                    style: _normalizeStyle({ 'color': getMachineWarningName(item.condition)?.color })
                  }, _toDisplayString(getMachineWarningName(item.condition)?.name), 5),
                  _createElementVNode("div", _hoisted_122, _toDisplayString(item.date), 1)
                ], 4))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_123, [
            _createElementVNode("div", _hoisted_124, [
              _createElementVNode("div", _hoisted_125, [
                _cache[33] || (_cache[33] = _createElementVNode("div", { class: "shu mr-3" }, null, -1)),
                _createElementVNode("span", _hoisted_126, _toDisplayString(_unref(langObj)['设备清单']), 1)
              ]),
              _createElementVNode("div", _hoisted_127, _toDisplayString(_unref(langObj)['显示单位']) + ":", 1),
              _createVNode(_component_a_select, {
                ref: "select",
                value: _unref(listSelectFac),
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_isRef(listSelectFac) ? (listSelectFac).value = $event : listSelectFac = $event)),
                style: {"width":"120px","margin-left":"20px"},
                onChange: getGroupListDetail,
                dropdownMatchSelectWidth: false
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item._id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.factoryName), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"]),
              _cache[34] || (_cache[34] = _createElementVNode("div", { class: "mx-4" }, ">", -1)),
              _createVNode(_component_a_select, {
                ref: "select",
                value: _unref(listSelectGroup),
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_isRef(listSelectGroup) ? (listSelectGroup).value = $event : listSelectGroup = $event)),
                style: {"width":"120px"},
                onChange: selectGroup,
                dropdownMatchSelectWidth: false
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList2), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item.sonID
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value"]),
              _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createVNode(_component_a_select, {
                ref: "select",
                value: _unref(showSearchModel).follow,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(showSearchModel).follow) = $event)),
                style: {"min-width":"120px"},
                onChange: getMachineListShow
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: null }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_128, [
                        (_unref(theme)=='white')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_129))
                          : (_openBlock(), _createElementBlock("img", _hoisted_130)),
                        _createElementVNode("div", _hoisted_131, _toDisplayString(_unref(langObj)['全部']), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: true }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_132, [
                        (_unref(theme)=='white')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_133))
                          : (_openBlock(), _createElementBlock("img", _hoisted_134)),
                        _createElementVNode("div", _hoisted_135, _toDisplayString(_unref(langObj)['关注']), 1)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: false }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_136, [
                        (_unref(theme)=='white')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_137))
                          : (_openBlock(), _createElementBlock("img", _hoisted_138)),
                        _createElementVNode("div", _hoisted_139, _toDisplayString(_unref(langObj)['未关注']), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]),
              _createVNode(_component_a_select, {
                ref: "select",
                value: _unref(showSearchModel).status,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(showSearchModel).status) = $event)),
                style: {"width":"120px","margin-left":"10px"},
                onChange: getMachineListShow
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: null }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['运行状态']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['运行']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: 2 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['停机']), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"]),
              _createVNode(_component_a_select, {
                ref: "select",
                value: _unref(showSearchModel).codnition,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(showSearchModel).codnition) = $event)),
                style: {"width":"120px","margin-left":"10px"},
                onChange: getMachineListShow
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select_option, { value: null }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['健康状态']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: 1 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['健康']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: 2 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['可用']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: 3 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['警戒']), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_select_option, { value: 4 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_unref(langObj)['故障']), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            _createElementVNode("div", _hoisted_140, [
              _createElementVNode("div", _hoisted_141, [
                _createVNode(_component_environment_outlined, { style: {"color":"#06DEF5","font-size":"14px"} }),
                _createElementVNode("div", _hoisted_142, _toDisplayString(_unref(groupInfo).name), 1),
                _createElementVNode("div", _hoisted_143, "(" + _toDisplayString(_unref(langObj)['设备总数']) + ":" + _toDisplayString(_unref(groupInfo).machineNum) + _toDisplayString(_unref(langObj)['台']) + " | " + _toDisplayString(_unref(langObj)['运行数']) + ":" + _toDisplayString(_unref(groupInfo).machineRun) + _toDisplayString(_unref(langObj)['台']) + " | " + _toDisplayString(_unref(langObj)['报警设备']) + ":" + _toDisplayString(_unref(groupInfo).warningMachineNum) + _toDisplayString(_unref(langObj)['台']) + ")", 1),
                _cache[36] || (_cache[36] = _createElementVNode("div", { class: "flex-1" }, null, -1))
              ]),
              _createElementVNode("div", _hoisted_144, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineListShow), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    onClick: ($event: any) => (selectMachine(item)),
                    key: index,
                    style: _normalizeStyle({ 'background': item.id === _unref(machine_id) ? '#001672' : '#020F46', 'border-color': item?.workStableStatus?.condition ? getMachineWarningName(item?.workStableStatus?.condition).color : '#0d53b7cc' }),
                    class: _normalizeClass(["box4 cursor-pointer mt-3 mr-3", item.id === _unref(machine_id)?'theme-box4-selected':''])
                  }, [
                    _createElementVNode("div", _hoisted_146, [
                      _cache[37] || (_cache[37] = _createElementVNode("div", { class: "shu" }, null, -1)),
                      _createElementVNode("div", {
                        class: "size14 ml-2",
                        style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"},
                        title: item.machineName
                      }, _toDisplayString(item.machineName), 9, _hoisted_147),
                      _cache[38] || (_cache[38] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      (_unref(theme)=='white')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (item.follow)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  class: "picIcon",
                                  onClick: ($event: any) => (follow(item, false)),
                                  src: _imports_5,
                                  alt: ""
                                }, null, 8, _hoisted_148))
                              : _createCommentVNode("", true),
                            (!item.follow)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  class: "picIcon",
                                  onClick: ($event: any) => (follow(item, true)),
                                  src: _imports_7,
                                  alt: ""
                                }, null, 8, _hoisted_149))
                              : _createCommentVNode("", true)
                          ], 64))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (item.follow)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  class: "picIcon",
                                  onClick: ($event: any) => (follow(item, false)),
                                  src: _imports_5,
                                  alt: ""
                                }, null, 8, _hoisted_150))
                              : _createCommentVNode("", true),
                            (!item.follow)
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 1,
                                  class: "picIcon",
                                  onClick: ($event: any) => (follow(item, true)),
                                  src: _imports_8,
                                  alt: ""
                                }, null, 8, _hoisted_151))
                              : _createCommentVNode("", true)
                          ], 64))
                    ]),
                    _cache[43] || (_cache[43] = _createElementVNode("div", { class: "heng" }, null, -1)),
                    _createElementVNode("div", _hoisted_152, [
                      _createElementVNode("div", _hoisted_153, [
                        _createElementVNode("img", {
                          style: {"height":"72px"},
                          src: item.url,
                          alt: ""
                        }, null, 8, _hoisted_154)
                      ]),
                      _createElementVNode("div", _hoisted_155, [
                        (item.workStatus && item.workStatus.wkStatus)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_156, [
                              _cache[39] || (_cache[39] = _createElementVNode("div", { class: "line" }, [
                                _createElementVNode("div", { class: "line animation" })
                              ], -1)),
                              _createElementVNode("span", _hoisted_157, _toDisplayString(_unref(langObj)['运行']), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_158, [
                              _cache[40] || (_cache[40] = _createElementVNode("div", { class: "down" }, [
                                _createElementVNode("div", { class: "down animation" })
                              ], -1)),
                              _createElementVNode("span", _hoisted_159, _toDisplayString(_unref(langObj)['停机']), 1)
                            ])),
                        _createElementVNode("div", _hoisted_160, [
                          _createElementVNode("div", {
                            class: "line",
                            style: _normalizeStyle({ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color })
                          }, [
                            _createElementVNode("div", {
                              class: "line animation",
                              style: _normalizeStyle({ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color })
                            }, null, 4)
                          ], 4),
                          _createElementVNode("span", _hoisted_161, _toDisplayString(_unref(langObj)[getMachineWarningName(item?.workStableStatus?.condition).name ]), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_162, [
                      (_unref(machine_info)?.workStatus?.onLine)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_163, _toDisplayString(_unref(langObj)['累计运行']), 1))
                        : _createCommentVNode("", true),
                      (_unref(machine_info)?.workStatus?.onLine)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_164, _toDisplayString(_unref(machine_info).workStatus.onLine) + _toDisplayString(_unref(langObj)['小时']), 1))
                        : _createCommentVNode("", true),
                      _cache[41] || (_cache[41] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("div", {
                        style: {"max-width":"110px","overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},
                        title: item.faultTitle
                      }, _toDisplayString(_unref(langObj)[item.faultTitle]?_unref(langObj)[item.faultTitle]:item.faultTitle), 9, _hoisted_165)
                    ]),
                    _createElementVNode("div", _hoisted_166, [
                      _createElementVNode("span", null, _toDisplayString(_unref(langObj)['速度均方根']), 1),
                      _createElementVNode("div", _hoisted_167, _toDisplayString(Number(item.sz)?.toFixed(2)) + "mm/s", 1),
                      _cache[42] || (_cache[42] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_unref(langObj)['温度']), 1),
                      _createElementVNode("div", _hoisted_168, _toDisplayString(item.temp) + "℃", 1)
                    ])
                  ], 14, _hoisted_145))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})