<template>
  <div v-if="type === 1" class="box"
    style="width: 250px; height: 85vh; overflow: auto;">
    <div class="w-full flex items-center">
      <a-input v-model:value="search" :placeholder="langObj['请输入设备名称搜索']">
        <template #suffix>
          <search-outlined @click="getMachineList" />
        </template>
      </a-input>
    </div>

    <div class="w-full" style="color: #fff">
      <div v-for="(factory, index1) in dataList" :key="index1"
        style="margin-top: 30px">
        <div class="w-full flex items-center cursor-pointer"
          style="font-size:18px" @click="factory.isShow = !factory.isShow">
          <img class="theme-white-black-img" style="width: 18px;"
            src="@/assets/facIco.png" alt="">
          <div class="flex-1 ml-2 ellipsis theme-font-black-color"
            :title="factory.name">{{ factory.name }}</div>
          <caret-up-outlined v-if="factory.isShow" style="font-size:18px"
            class="theme-font-black-color" />
          <caret-down-outlined v-if="!factory.isShow" style="font-size:18px"
            class="theme-font-black-color" />
        </div>
        <div v-if="factory.isShow">
          <div class="w-full" style="margin-top: 30px"
            v-for="(group, index2) in factory.groupList" :key="index2">
            <div class="w-full flex items-center cursor-pointer"
              style="font-size:16px" @click="group.isShow = !group.isShow">
              <img class="theme-white-black-img" style="width: 16px;"
                src="@/assets/groupIco.png" alt="">
              <div class="flex-1 ml-2 ellipsis theme-font-black-color"
                :title="group.name">{{ group.name }}</div>
              <caret-up-outlined v-if="group.isShow" style="font-size:16px"
                class="theme-font-black-color" />
              <caret-down-outlined v-if="!group.isShow" style="font-size:16px"
                class="theme-font-black-color" />
            </div>

            <div v-if="group.isShow">
              <div class="w-full " style="margin-top: 20px"
                v-for="(machine, index3) in group.machineList" :key="index3">
                <div @click="selectMachine(machine)"
                  :class="{ 'select-machine theme-select-machine': machine.id === selectId }"
                  class="w-full flex items-center machine"
                  style="font-size:14px">
                  <div class="flex-1 theme-font-black-color">
                    {{
                      machine.name }}</div>
                  <div :style="{ 'background-color': machine.condition.color }"
                    style="width: 10px; height: 10px; border-radius:50%; margin-right: 5px ">
                  </div>
                  <!-- <caret-up-outlined style="font-size:20px"/> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div v-if="type === 2" class="box"
    style="width: 250px; height: 85vh; overflow: auto;">
    <div class="w-full flex items-center">
      <a-input v-model:value="search" :placeholder="langObj['请输入测点名称搜索']">
        <template #suffix>
          <search-outlined @click="getSensorList" />
        </template>
      </a-input>
    </div>

    <div class="w-full mt-3" style="color: #fff">
      <div v-for="(factory, index1) in dataList" :key="index1"
        style="margin-top: 30px">
        <div class="w-full flex items-center cursor-pointer"
          style="font-size:18px" @click="factory.isShow = !factory.isShow">
          <img class="theme-white-black-img" style="width: 18px;"
            src="@/assets/facIco.png" alt="">
          <div class="flex-1 ml-2 ellipsis theme-font-black-color"
            :title="factory.name">{{ factory.name }}</div>
          <caret-up-outlined v-if="factory.isShow" style="font-size:18px"
            class="theme-font-black-color" />
          <caret-down-outlined v-if="!factory.isShow" style="font-size:18px"
            class="theme-font-black-color" />
        </div>
        <div v-if="factory.isShow">
          <div class="w-full" style="margin-top: 30px"
            v-for="(group, index2) in factory.groupList" :key="index2">
            <div class="w-full flex items-center cursor-pointer"
              style="font-size:16px" @click="group.isShow = !group.isShow">
              <img class="theme-white-black-img" style="width: 16px;"
                src="@/assets/groupIco.png" alt="">
              <div class="flex-1 ml-2 ellipsis theme-font-black-color"
                :title="group.name">{{ group.name }}</div>
              <caret-up-outlined v-if="group.isShow" style="font-size:16px"
                class="theme-font-black-color" />
              <caret-down-outlined v-if="!group.isShow" style="font-size:16px"
                class="theme-font-black-color" />
            </div>

            <div v-if="group.isShow">
              <div class="w-full" style="margin-top: 20px"
                v-for="(machine, index3) in group.machineList" :key="index3">
                <div class="w-full flex items-center machine"
                  style="font-size:14px"
                  @click="machine.isShow = !machine.isShow">
                  <div class="flex-1 ellipsis theme-font-black-color"
                    :title="machine.name">{{ machine.name }}
                  </div>
                  <div :style="{ 'background-color': machine.condition.color }"
                    style="width: 10px; height: 10px; border-radius:50%; margin-right: 5px ">
                  </div>
                  <caret-up-outlined v-if="machine.isShow"
                    style="font-size:14px" class="theme-font-black-color" />
                  <caret-down-outlined v-if="!machine.isShow"
                    style="font-size:14px" class="theme-font-black-color" />
                </div>

                <div v-if="machine.isShow">
                  <div @click="selectSensor(sensor)" style="margin-top: 20px"
                    :class="{ 'select-machine theme-select-machine': sensor.id === selectId }"
                    class="w-full mt-2 cursor-pointer p-1 flex items-center"
                    v-for="(sensor, index4) in machine.sensorList"
                    :key="index4">
                    <div class="ml-1 flex-1 ellipsis theme-font-black-color">
                      {{ sensor.sensorPlace }}</div>
                    <div :style="{ 'background-color': sensor.condition.color }"
                      style="width: 10px; height: 10px; border-radius:50%; margin-right: 5px ">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div v-if="type === 3" class="box"
    style="width: 250px; height: 85vh; overflow: auto;">
    <div class="w-full flex items-center">
      <a-input v-model:value="search" :placeholder="langObj['请输入设备名称搜索']">
        <template #suffix>
          <search-outlined @click="getMachineList" />
        </template>
      </a-input>
    </div>

    <div class="w-full" style="color: #fff">
      <div v-for="(factory, index1) in dataList" :key="index1"
        style="margin-top: 30px">
        <div class="w-full flex items-center cursor-pointer"
          style="font-size:18px" @click="factory.isShow = !factory.isShow">
          <img class="theme-white-black-img" style="width: 18px;"
            src="@/assets/facIco.png" alt="">
          <div class="flex-1 ml-2 ellipsis theme-font-black-color"
            :title="factory.name">{{ factory.name }}</div>
          <caret-up-outlined v-if="factory.isShow" style="font-size:18px"
            class="theme-font-black-color" />
          <caret-down-outlined v-if="!factory.isShow" style="font-size:18px"
            class="theme-font-black-color" />
        </div>
        <div v-if="factory.isShow">
          <div class="w-full" style="margin-top: 30px"
            v-for="(group, index2) in factory.groupList" :key="index2">
            <div class="w-full flex items-center cursor-pointer"
              style="font-size:16px"
              :class="{ 'select-machine': group.id === selectId }">
              <img class="theme-white-black-img" style="width: 16px;"
                src="@/assets/groupIco.png" alt="">
              <div class="flex-1 ml-2 ellipsis theme-font-black-color"
                :title="group.name" @click="selectGroup(group, factory)">{{
                  group.name
                }}</div>
              <caret-up-outlined @click="group.isShow = !group.isShow"
                v-if="group.isShow" style="font-size:16px"
                class="theme-font-black-color" />
              <caret-down-outlined @click="group.isShow = !group.isShow"
                v-if="!group.isShow" style="font-size:16px"
                class="theme-font-black-color" />
            </div>

            <div v-if="group.isShow">
              <div class="w-full " style="margin-top: 20px"
                v-for="(machine, index3) in group.machineList" :key="index3">
                <div @click="selectMachine(machine)"
                  :class="{ 'select-machine theme-select-machine': machine.id === selectId }"
                  class="w-full flex items-center machine"
                  style="font-size:14px">
                  <div class="flex-1 theme-font-black-color">
                    {{
                      machine.name }}</div>
                  <div :style="{ 'background-color': machine.condition.color }"
                    style="width: 10px; height: 10px; border-radius:50%; margin-right: 5px ">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>






</template>

<script lang="ts" setup>
import { defineProps, defineEmits, onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { langList } from "../../common/lang";
let dataList: any = ref([])
let selectId: any = ref('')
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()
let conditionList = [
  { label: langObj.value['健康'], value: [0], color: '#61c08f' },
  { label: langObj.value['可用'], value: [1, 2], color: '#d2de49' },
  { label: langObj.value['警戒'], value: [3, 4, 5], color: '#cd5f3b' },
  { label: langObj.value['故障'], value: [6, 7], color: '#921e37' },
]

const props = defineProps<{ type?: number, from?: number, mode?: number }>()

let type = ref(props.type || 1);

watch(
  () => props.type,
  () => {
    type.value = props.type || 1;
    init();
  }
);

let search = ref('')

onMounted(() => {
  init()
})

//获取设备列表/测点列表
const init = () => {
  if (props.type === 1 || props.type === 3) {
    if (localStorage.getItem('machine_id')) {
      emits('selectMachine', localStorage.getItem('machine_id'))
    }
    getMachineList()
  }

  if (props.type === 2) {
    if (localStorage.getItem('sensor_id')) {
      emits('selectSensor', localStorage.getItem('sensor_id'))
    }
    getSensorList()
  }
}

const getSensorList = async () => {
  let memberInfo: any = JSON.parse(localStorage.getItem('memberInfo') || '')
  let factory_id = localStorage.getItem('factory_id')
  let factorys = memberInfo.factories.filter((p: any) => p.id === factory_id || p.parentId === factory_id)
  let factoryList: any = []
  if (factorys.length > 1) {
    factoryList = factorys.filter((p: any) => p.parentId)
  } else {
    factoryList = factorys
  }
  let factoryIds = factoryList.map((p: any) => p.id)
  let config: any = {
    params: {
      factoryIds,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (props.mode) {
    config.params.mode = props.mode;
  }
  let result = await request.get('/api/machinesall', config)

  let machienAll = result ? result.data : []
  let machineIds = machienAll.map((p: any) => p.id)
  factoryList.map((fac: any) => {
    let groups = memberInfo.groups.filter((p: any) => p.factoryId === fac.id)
    fac.groupList = groups
    fac.isShow = false
    if (search.value) {
      fac.isShow = true
    }
    for (const group of fac.groupList) {
      let machineList = memberInfo.machines.filter((p: any) => p.groupId === group.id)
      machineList = machineList.filter((p: any) => machineIds.includes(p.id));

      for (const machine of machineList) {
        let machineInfo = machienAll.find((p: any) => p.id === machine.id)
        if (machineInfo && machineInfo.workStatus && machineInfo.workStatus.stableCondition) {
          machine.condition = conditionList.find((p: any) => p.value.includes(machineInfo.workStatus.stableCondition))
        } else {
          machine.condition = conditionList[0]
        }
        if (machineInfo) {
          let sensorList1 = JSON.parse(JSON.stringify(machineInfo.sensorList))
          if (search.value) {
            sensorList1 = sensorList1.filter((p: any) => p.sensorPlace.includes(search.value))
          }
          sensorList1.map((s: any) => {
            let score = 100
            let conditionS = 0
            if (machineInfo?.workStatus?.stableSensors?.length > 0) {
              let sInfo = machineInfo.workStatus.stableSensors.find((p: any) => p.id === s.id)
              if (sInfo) {
                let scInfo = JSON.parse(JSON.stringify(sInfo))
                delete scInfo.id
                let scores: any = Object.values(scInfo) || []
                if (scores?.length > 0) {
                  score = Math.min(...scores);
                }
              }
            }
            if (score >= 0 && score < 30) {
              conditionS = 6
            }
            if (score >= 30 && score < 60) {
              conditionS = 3
            }
            if (score >= 60 && score < 80) {
              conditionS = 1
            }
            if (score >= 80 && score <= 100) {
              conditionS = 0
            }
            s.condition = conditionList.find((p: any) => p.value.includes(conditionS))
          })
          machine.sensorList = sensorList1

        } else {
          machine.sensorList = []
        }
        machine.isShow = false
        if (search.value) {
          group.isShow = true
          fac.isShow = true
          machine.isShow = true
        }
      }
      group.machineList = machineList
      group.isShow = false
      if (search.value) {
        group.isShow = true
        fac.isShow = true
      }
    }
    fac.groupList = fac.groupList.filter((t: any) => t.machineList.length);

  })
  dataList.value = factoryList
  if (!search.value) {
    getSensor()
  }
}

//读取缓存测点
const getSensor = () => {
  let sensor_id = localStorage.getItem('sensor_id')
  let machine_id = localStorage.getItem('machine_id')
  if (machine_id) {
    dataList.value.map((d1: any) => {
      d1.groupList && d1.groupList.map((d2: any) => {
        if (d2.machineList?.length > 0) {
          let machineInfo = d2.machineList.find((p: any) => p.id === machine_id)
          if (machineInfo?.sensorList?.length > 0) {
            let info = machineInfo.sensorList[0]
            if (info) {
              d1.isShow = true
              d2.isShow = true
              machineInfo.isShow = true
              selectId.value = info.id
              localStorage.setItem('sensor_id', selectId.value)
              emits('selectSensor', selectId.value)
            }
          }
        }
      })
    })
  } else {
    let sensorId = dataList.value[0]?.groupList[0]?.machineList[0]?.sensorList[0]?.id
    if (sensorId) {
      selectId.value = sensorId
      dataList.value[0].isShow = true
      dataList.value[0].groupList[0].isShow = true
      dataList.value[0].groupList[0].machineList[0].isShow = true
      localStorage.setItem('sensor_id', sensorId)
      emits('selectSensor', sensorId)
    }
  }




  // if(sensor_id) {
  //     selectId.value = sensor_id
  //     dataList.value.map((d1:any) => {
  //         d1.groupList && d1.groupList.map((d2:any) => {
  //             d2.machineList && d2.machineList.map((d3:any) => {
  //                 let info = d3.sensorList.find((p:any) => p.id === sensor_id)
  //                 if(info) {
  //                     d1.isShow = true
  //                     d2.isShow = true
  //                     d3.isShow = true
  //                 }
  //             })
  //         })
  //     })
  // } else {
  //     let sensorId =  dataList.value[0]?.groupList[0]?.machineList[0]?.sensorList[0]?.id
  //     if(sensorId) {
  //         selectId.value = sensorId
  //         dataList.value[0].isShow = true
  //         dataList.value[0].groupList[0].isShow = true
  //         dataList.value[0].groupList[0].machineList[0].isShow = true
  //         localStorage.setItem('sensor_id', sensorId) 
  //         emits('selectSensor', sensorId)
  //     }
  // }
}



// 选择测点
const selectSensor = (ev: any) => {
  selectId.value = ev.id
  localStorage.setItem('sensor_id', ev.id)
  emits('selectSensor', ev.id)
}

const getMachineList = async () => {
  let memberInfo: any = JSON.parse(localStorage.getItem('memberInfo') || '')
  let factory_id = localStorage.getItem('factory_id')
  let factorys = memberInfo.factories.filter((p: any) => p.id === factory_id || p.parentId === factory_id)
  let factoryList: any = []
  if (factorys.length > 1) {
    factoryList = factorys.filter((p: any) => p.parentId)
  } else {
    factoryList = factorys
  }
  let factoryIds = factoryList.map((p: any) => p.id)
  let config: any = {
    params: {
      factoryIds,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (props.mode) {
    config.params.mode = props.mode;
  }
  let result = await request.get('/api/machinesall', config)
  let machienAll = result ? result.data : []
  let machineIds = machienAll.map((p: any) => p.id);
  factoryList.map((fac: any) => {
    fac.groupList = memberInfo.groups.filter((p: any) => p.factoryId === fac.id)
    fac.isShow = !!search.value;

    for (const group of fac.groupList) {
      let machineList = memberInfo.machines.filter((p: any) => p.groupId === group.id)
      if (search.value) {
        machineList = machineList.filter((p: any) => p.name.includes(search.value))
      }
      machineList = machineList.filter((p: any) => machineIds.includes(p.id))

      for (const machine of machineList) {
        let machineInfo = machienAll.find((p: any) => p.id === machine.id)
        if (machineInfo && machineInfo.workStatus && machineInfo.workStatus.stableCondition) {
          machine.condition = conditionList.find((p: any) => p.value.includes(machineInfo.workStatus.stableCondition))
        } else {
          machine.condition = conditionList[0]
        }
        if (machineInfo && machineInfo.workStatus && machineInfo.workStatus.scoreWkStatus) {
          machine.scoreWkStatus = machineInfo.workStatus.scoreWkStatus
        } else {
          machine.scoreWkStatus = 0
        }

        let pictureList: any = machineInfo?.pictures?.length ? machineInfo.pictures : []

        machine.picture = pictureList.find((p: any) => p.type == 1)?.url

      }
      group.machineList = machineList
      group.isShow = !!search.value;
    }

    fac.groupList = fac.groupList.filter((t: any) => t.machineList.length);
  })
  dataList.value = factoryList

  if (!search.value) {
    getMachine()
  }

}


//读取缓存设备
const getMachine = () => {
  let machine_id = localStorage.getItem('machine_id')
  if (machine_id) {
    selectId.value = machine_id
    dataList.value.map((d1: any) => {
      d1.groupList && d1.groupList.map((d2: any) => {
        let info = d2.machineList.find((p: any) => p.id === machine_id)
        if (info) {
          d1.isShow = true
          d2.isShow = true
        }
      })
    })
  } else {
    let machineId = dataList.value[0]?.groupList[0]?.machineList[0]?.id
    if (machineId) {
      selectId.value = machineId
      dataList.value[0].isShow = true
      dataList.value[0].groupList[0].isShow = true
      localStorage.setItem('machine_id', machineId)
      emits('selectMachine', machineId)
    }
  }
}

// 选择设备
const selectMachine = (ev: any) => {
  localStorage.setItem('machine_id', ev.id)
  selectId.value = ev.id
  emits('selectMachine', ev.id)
}

// 选择区域
const selectGroup = (group: any, factory: any) => {
  selectId.value = group.id
  emits('selectGroup', group)
}

const emits = defineEmits(['selectMachine', 'selectSensor', 'selectGroup'])

</script>

<style lang="less" scoped>
.select-machine {
  background-color: #3136AF;
}

.machine {
  padding: 4px 2px;
  border-radius: 2px;
  cursor: pointer;
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
}

.box::-webkit-scrollbar {
  width: 5px; //修改滚动条宽度
}

.box::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #00249b;
}

.btn {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff
}

.txt-select {
  border-bottom: 2px solid #00FFF4;
}

/deep/ .ant-table {
  background: #020F46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff
}

/deep/ .ant-input {
  border: 0px solid #0d53b7cc !important
}

.ellipsis {
  overflow: hidden;
  /* 隐藏超出部分内容 */
  white-space: nowrap;
  /* 不换行 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
</style>
