<template>
<a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full px-5 py-6 bule_list_page theme-common" >
        <a-form layout="inline">
            <a-form-item :label="getLanguage('轴承型号')">
                <a-input
                    v-model:value="searchModel.bearingModel"
                >
                </a-input>
            </a-form-item>

            <a-form-item label="">
            <a-button type="primary" @click="search">
                <template #icon><SearchOutlined /></template>{{getLanguage('搜索')}}</a-button
            >
            <a-button @click="add" class="ml-4" type="primary" v-if="isEdit">{{getLanguage('新增')}}</a-button>
            </a-form-item>
        </a-form>

        <a-table
            bordered
            :columns="columns"
            :data-source="dataList"
            :pagination="pagination"
            @change="handleTableChange"
        >
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'act'">
                    <div v-if="isEdit">
                        <a @click="edit(record)">编辑</a>
                        <a-divider type="vertical" />
                        <a-popconfirm
                        title="确定删除此条数据么，删除后无法恢复?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="del(record)"
                        >
                        <a-button type="link">删除</a-button>
                        </a-popconfirm>
                    </div>
                </template>
            </template>
        </a-table>

        <a-modal 
            v-model:visible="visible" 
            :title="form.id ? '编辑' : '新增'" 
            :maskClosable="false"
            width="800px"
            @ok="submit"
        >
        <a-form >
            <a-form-item label="轴承型号" required>
                <a-input v-model:value="form.bearingModel"></a-input>
            </a-form-item>
            <a-form-item label="滚子数">
                <a-input v-model:value="form.rollersNum"></a-input>
            </a-form-item>
            <a-form-item label="保持架">
                <a-input-number style="width: 100%;" v-model:value="form.crf" :controls="false" />
            </a-form-item>
            <a-form-item label="滚子或滚柱">
                <a-input-number style="width: 100%;" v-model:value="form.bsf" :controls="false" />
            </a-form-item>
            <a-form-item label="外环">
                <a-input-number style="width: 100%;" v-model:value="form.bpfo" :controls="false" />
            </a-form-item>
            <a-form-item label="内环">
                <a-input-number style="width: 100%;" v-model:value="form.bpfi" :controls="false" />
            </a-form-item>
            <a-form-item label="备注">
                <a-input v-model:value="form.remark"></a-input>
            </a-form-item>
            <a-form-item label="转频">
                <a-input-number style="width: 100%;" v-model:value="form.keyFre" :controls="false" />
            </a-form-item>
            <a-form-item label="保持架频率">
                <a-input-number style="width: 100%;" v-model:value="form.crfFt" :controls="false" />
            </a-form-item>
            <a-form-item label="滚子或滚柱频率">
                <a-input-number style="width: 100%;" v-model:value="form.bsfFt" :controls="false" />
            </a-form-item>
            <a-form-item label="外环频率">
                <a-input-number style="width: 100%;" v-model:value="form.bpfoFt" :controls="false" />
            </a-form-item>
            <a-form-item label="内环频率">
                <a-input-number style="width: 100%;" v-model:value="form.bpfiFt" :controls="false" />
            </a-form-item>

        </a-form>
    
        </a-modal>

    </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { ref, reactive, toRaw, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { operateLogs } from "@/common/logs";
import { getLanguage } from "../../common/translate";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
let language: any =  localStorage.getItem('language') || 'Chinese'

interface Props {
    edit?: boolean 
}

const props = withDefaults(defineProps<Props>(), {
    edit: false,
})

let isEdit:any = props.edit

const columns = [
    {
        title: getLanguage('型号'),
        dataIndex: 'bearingModel',
    },
    {
        title: getLanguage('滚子数'),
        dataIndex: 'rollersNum',
    },
    {
        title: getLanguage('保持架'),
        dataIndex: 'crf',
    },
    {
        title: getLanguage('滚子或滚柱'),
        dataIndex: 'bsf',
    },
    {
        title: getLanguage('外环'),
        dataIndex: 'bpfo',
    },
    {
        title: getLanguage('内环'),
        dataIndex: 'bpfi',
    },
    {
        title: getLanguage('备注'),
        dataIndex: 'remark',
    },
    {
        title: getLanguage('转频'),
        dataIndex: 'keyFre',
    },
    {
        title: getLanguage('保持架频率'),
        dataIndex: 'crfFt',
    },
    {
        title: getLanguage('滚子或滚柱频率'),
        dataIndex: 'bsfFt',
    },
    {
        title: getLanguage('外环频率'),
        dataIndex: 'bpfoFt',
    },
    {
        title: getLanguage('内环频率'),
        dataIndex: 'bpfiFt',
    },
    {
        title: getLanguage('操作'),
        dataIndex: 'act',
    },
]
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
    bearingModel: '',
})
let dataList:any = ref([])
let visible = ref(false)
let form:any = ref({})

onMounted(() => {
    init()
})

const init = () => {
    console.log('lc', location)
    getData()
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const getData = async() => {
    let config = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            bearingModel: searchModel.value.bearingModel,
        },
        headers: {
            requestId: uuidv4(),
        }
    }

    let result = await request.get('/api/bearing-parameters', config)
    console.log('result', result)
    dataList.value = result?.data?.list || []
    searchModel.value.total = result?.data?.count || 0

}

const handleTableChange = (ev:any) => {
    searchModel.value.skip = ev.current
    searchModel.value.take = ev.pageSize
    getData()
}

// 新增
const add = () => {
    form.value = {}
    visible.value = true
}

// 编辑
const edit = (ev:any) => {
    form.value = JSON.parse(JSON.stringify(ev))
    visible.value = true
}


// 提交
const submit = async() => {
    let result = form.value.id ? await request.put('/api/bearing-parameters', form.value)
                                :await request.post('/api/bearing-parameters', form.value)

    console.log('result', result)
    if(result?.data) { 
        message.success('操作成功!')
        visible.value = false
        getData()
    } else {
        message.warning('操作失败!')
    }
}


// 删除
const del = async(ev:any) => {
    let config = {
        params: {
            id: ev.id
        },
        headers: {
            requestId: uuidv4(),
        }
    }

    let result =  await request.delete('/api/bearing-parameters', config)
    if(result?.data) { 
        message.success('操作成功!')
        getData()
    } else {
        message.warning('操作失败!')
    }
}

</script>


<style scoped>
.bule_list_page {
  height: calc(100vh - 100px) !important;
}
</style>