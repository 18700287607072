import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = {
  class: "box",
  style: {"color":"#fff"}
}
const _hoisted_3 = {
  key: 0,
  class: "span-1"
}
const _hoisted_4 = {
  key: 1,
  class: "span-1"
}

import { ref, watch, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from 'ant-design-vue';
import * as echarts from 'echarts';
import request from "../../../common/request";
import { Dayjs } from 'dayjs';


export default /*@__PURE__*/_defineComponent({
  __name: 'select-histrend',
  props: {
    machineInfo:null,
},
  emits: ['sendForm'],
  setup(__props, { emit: __emit }) {

const props:any = __props

watch(() => props.machineInfo, (newValue, oldValue) => {
    init()
})

let sensorList:any = ref([])
let quotaList:any = ref([])
let formState:any = ref({
    machineName: '',
    sensorId: '',
    model: '健康',
    quota: '',
})
let statusInfo:any = null
let markLineShow:any
let faultName:any 
let dataSave:any = {}
let begin:any = ''
let end:any = ''
let faults:any = {}

// 选择振动/健康
const changeModel = (ev:any) => {
   console.log(ev)
   getData()
}

// 选择时间
const selectDate = (ev:any) => {
    getData()
}

const getData = async() => {
    if(!formState.value.sensorId ) {
        if(sensorList.value && sensorList.value.length) {
            formState.value.sensorId = sensorList.value[0].id
        } else {
            return
        }
    }

    let faultSearch = {
        params: { machineId: props.machineInfo.id },
        headers: {
            requestId: uuidv4(),
        },
    }
    let result3:any = await request.get('/api/machines/faultnames', faultSearch) 
    let faultList = {faultNameExpects: [], faultNames: []}
    if(result3 && result3.data) {
        faultList.faultNames = result3.data.faultNames
        faultList.faultNameExpects = result3.data.faultNameExpects
    }

    faults = faultList

    let list:any
    if(formState.value.model === '健康') {
        list = faultList.faultNameExpects
    } else {
        list = faultList.faultNames
    }
    faultName = {}
    list.map((d:any) => {
        faultName[d.column] = d.title
    })

    quotaList.value = Object.values(faultName)

    if(quotaList.value && quotaList.value && !quotaList.value.includes(formState.value.quota)) {
        formState.value.quota = quotaList.value[0]
    }

    if(!formState.value.quota) {
        formState.value.quota = quotaList.value[0]
    }

    let machine_date:any = ''
    if(statusInfo && statusInfo.workStatus && statusInfo.workStatus.date) {
        machine_date = statusInfo.workStatus.date
    }else {
        return
    }

    let beginDate:any
    let endDate:any

    if(formState.value.date && formState.value.date.length === 2) {
        let start = formState.value.date[0]
        let end = formState.value.date[1]
        beginDate = start.$d
        endDate = end.$d
    }else {
        let time = new Date(machine_date)
        let date = time.getTime()
        beginDate = date - 1000 * 60 *60 * 24 * 30
        endDate = machine_date
    }

    begin = tranformDate(beginDate)
    end = tranformDate(endDate)

    let search = {
        params: {
            begin,
            end,
            sensorId: '',
            machineId: props.machineInfo.id,
        },
        headers: {
            requestId: uuidv4(),
        },
    }

    // 获取开关机线
    let result = await request.get('/api/external/machines/score-full', search);

    let scoreAll = result.data || []
    if (scoreAll.length > 0) {
      let markLine: any = {
        data: [],
        silent: true,
      };
      let wkStatus = 0;
      let x = '';
      scoreAll.map((item: any, index: any) => {
        let time = new Date(item.date);
        let month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1);
        let day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate();
        let hours = time.getHours() > 9 ? time.getHours() : '0' + time.getHours();
        let minutes = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes();
        let seconds = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds();
        x = month + '/' + day + ' ' + hours + ':' + minutes + ':' + seconds;
        if (index == 0) {
          wkStatus = item.wkStatus;
        } else {
          if (item.wkStatus != wkStatus) {
            if (item.wkStatus == 1) {
              markLine.data.push({
                name: '开',
                xAxis: x,
                label: { show: true, color: '#fff', formatter: '{b}' },
                lineStyle: { color: 'green' },
              });
            } else {
              markLine.data.push({
                name: '关',
                xAxis: x,
                label: { show: true, color: '#fff', formatter: '{b}' },
                lineStyle: { color: 'rgb(173, 38, 64)' },
              });
            }
            wkStatus = item.wkStatus;
          }
        }
      });
      markLineShow = markLine;
    }

    // 获取显示数据
    search.params.sensorId = formState.value.sensorId
    let result2 = await request.get('/api/external/machines/score-full', search) 

    let chartAll = result2.data || []
    let quotaValue = ''
    for(let i in faultName) {
        let d = faultName[i]
        if(d === formState.value.quota) {
            quotaValue = i
        }
    }

    let xData:any = []
    let yData:any = []
    chartAll.map( (item:any) => {
        xData.push(item.date.slice(5,19))
        yData.push(item[quotaValue])
    })

    dataSave = {xData, yData}
    initChart()
}

// 提交选择
const emits = __emit
const submit = () => {
    let title =  `${formState.value.model === '振动' ? '振动指标诊断健康分数' : '健康诊断分数趋势图'}:${formState.value.quota}`
    let form = {
        title,
        date: dataSave.xData || [],
        value: dataSave.yData || [],
        begin,
        end,
        markLine: markLineShow,
    }
    emits('sendForm', form)
}

const initChart = () => {
    const {xData, yData} = dataSave

    const ec = echarts as any;
    let myChart = ec.init(document.getElementById('divInfoChart'));
    let sensorName = sensorList.value.find((p:any) => p.id === formState.value.sensorId)?.sensorPlace;
    let option = {
      title: {
        text: `${sensorName}  ${formState.value.quota}`,
        left: '40%',
        top: '0',
        textStyle: {
            color: '#fff' 
        }
      },
      grid: {
        left: '6%',
        right: '2%',
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: xData,
      },
      yAxis: {
        type: 'value',
        scale: true,
        min: 0,
        max: 100,
        splitNumber: 5,
      },
      dataZoom: [
        {
          id: 'dataZoomX',
          type: 'slider',
          xAxisIndex: [0],
          filterMode: 'filter',
          bottom: '0',
        },
        {
          type: 'inside',
        },
      ],
      series: [
        {
          data: yData,
          type: 'line',
          markLine: markLineShow,
          connectNulls: true,
        },
      ],
    };

    option && myChart.setOption(option);

}


const tranformDate = (date:any) => {
    let time = new Date(date)
    let year = time.getFullYear()
    let month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
    let day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
    let hour = time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
    let min = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
    let second = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
    return `${year}/${month}/${day} ${hour}:${min}:${second}`
}


const getMachine = async() => {
    const config = {
        params: { id: props.machineInfo.id},
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/machines/workstatus/info', config);
    statusInfo = result.data
    getSensorList()
}

const getSensorList = async() => {
    const config = {
        params: {machineId: props.machineInfo.id},
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/sensors', config)
    
    sensorList.value = result.data
    getData()
}


const init = () => {
    if(!props.machineInfo || !props.machineInfo.id) {
        return
    }
    formState.value.machineName = props.machineInfo.machineName
    getMachine()
    
}

onMounted(() => {
    init()
})


const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      layout: 'inline',
      model: _unref(formState)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "设备名称" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _unref(formState).machineName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formState).machineName) = $event)),
              placeholder: "",
              readonly: true
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "传感器选择" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              ref: "sensorId",
              value: _unref(formState).sensorId,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formState).sensorId) = $event)),
              style: {"width":"200px"},
              onChange: getData
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "振动/健康" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              ref: "model",
              value: _unref(formState).model,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formState).model) = $event)),
              style: {"width":"200px"},
              onChange: changeModel
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['振动', '健康'], (item, index) => {
                  return _createVNode(_component_a_select_option, {
                    key: index,
                    value: item
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"])
                }), 64))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "指标" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              ref: "quota",
              value: _unref(formState).quota,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formState).quota) = $event)),
              style: {"width":"200px"},
              onChange: getData
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(quotaList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "日期范围" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_range_picker, {
              onChange: selectDate,
              "show-time": "",
              value: _unref(formState).date,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formState).date) = $event)),
              format: "YYYY-MM-DD HH:mm:ss",
              "disabled-date": disabledDate,
              onCalendarChange: onCalendarChange
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { "wrapper-col": { } }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: submit,
              class: "btn-submit"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("提交选择")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createElementVNode("div", _hoisted_2, [
      ( _unref(formState).model == '健康' )
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "健康诊断分数趋势图"))
        : _createCommentVNode("", true),
      ( _unref(formState).model == '振动' )
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "振动指标诊断健康分数"))
        : _createCommentVNode("", true),
      _cache[6] || (_cache[6] = _createElementVNode("div", {
        id: "divInfoChart",
        style: {"width":"100%","height":"470px"}
      }, null, -1))
    ])
  ]))
}
}

})