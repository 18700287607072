import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "flex theme-common" }
const _hoisted_2 = { class: "p-3 box w-full flex-1" }
const _hoisted_3 = {
  class: "w-full flex px-4",
  style: {"border-bottom":"1px solid #4a5983","margin-bottom":"20px","display":"none"}
}
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = {
  class: "box ml-4 p-3",
  style: {"border-left":"1px solid #0d53b7cc","width":"800px","overflow-y":"scroll"}
}
const _hoisted_11 = { class: "flex items-center" }
const _hoisted_12 = {
  class: "record flex items-center",
  style: {"color":"#fff"}
}
const _hoisted_13 = { class: "group mr-4" }
const _hoisted_14 = { class: "machine_box" }
const _hoisted_15 = { class: "machine flex items-center" }
const _hoisted_16 = {
  key: 0,
  class: "machine_name"
}
const _hoisted_17 = {
  key: 1,
  class: "point_box flex"
}
const _hoisted_18 = { class: "ml-4 point" }
const _hoisted_19 = { class: "point_name" }
const _hoisted_20 = { class: "flex items-center info_box" }
const _hoisted_21 = { class: "info ml-4" }

import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import * as echarts from "echarts";
import {

  getGroupName,
  transformDate2,
  getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import reportInfo from "./reportInfo.vue";
import DownLoadComponent from "./downLoad.vue";

import { Dayjs } from 'dayjs';

export default /*@__PURE__*/_defineComponent({
  __name: 'reportList',
  setup(__props) {

const value: any = ref(1);
let groupList: any = ref([]);
let reportLoading = ref(false);
let concatLoading = ref(false);
let menuSelect = ref(2); 


let machineList: any = ref([]);
let dataTypeList = ref<any>();
let isEditable: any = ref(false);
let visibleReportCon = ref(false);
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];

const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()



let typeLists: any = [
  { label: langObj.value["基础"], value: 2 },
  { label: langObj.value["智能"], value: 1 },
];
let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
  date: null,
  total: 0,
  type: null,
  userNick: "",
  // conditionList:[1,2,3,4,5,6,7]
});
let inspectionBase: any = ref({
  reportIdObj: {},
  reportInfo: {},
  id: '',
  machineInfo: {},
  fx_code1: 0,
  fx_code1_dis1: 0,
  fx_code2: 0,
  fx_code1_dis2: 0,
  fx_code3: 0,
  fx_code4: 0,
  fx_code5: 0,
  fx_code3_v: 0,
  fx_code4_v: 0,
  fx_code5_v: 0,
  jsdzD: {},
  sdD: {},
  tempD: {},
  directionA: ["z轴", "x轴", "y轴"],
  directionIn: 0,
  directionInB: 0,
  directionInC: 0,
  directionInD: 0,
  fx_code4_v_x: 0,
  fx_code4_v_y: 0,
  fx_code3_xy: 0,
  fx_code3_v_y: 0,
  resultTIME: {},
  resultFFT: {},
  listT: [],
  reportType: 2
})

let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];


let data = ref([]);

const inspectionColumns = [
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
  },

  {
    title: langObj.value["区域"],
    dataIndex: "groupId",
  },

  {
    title: langObj.value["巡检人"],
    dataIndex: "userNick",
  },
  {
    title: langObj.value["巡检日期"],
    dataIndex: "date",
  },
  {
    title: langObj.value["测点"],
    dataIndex: "position",
  },


  {
    title: langObj.value["巡检状态"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["巡检报告类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const concatColumns = [
  {
    title: langObj.value["区域"],
    dataIndex: "groupName",
    slots: { customRender: 'group' },
    customCell: (_: any, index: any) => ({ rowspan: _.span, }),
    align: "center"
  },
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
    slots: { customRender: 'device' },
    customCell: (_: any, index: any) => ({ rowspan: _.span, }),
    align: "center"
  },
  {
    title: "测点1#",
    dataIndex: "sensorName1",
    slots: { customRender: 'sensor1' },
    align: "center"
  },
  {
    title: "测点2#",
    dataIndex: "sensorName2",
    slots: { customRender: 'sensor2' },
    align: "center"
  },
]

const pagination = computed(() => ({
  // showQuickJumper:false,
  // simple:true,
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
const handleTableChange = (pagination: any, filters: any, sorter: any) => {

  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  search();

};

onMounted(() => {
  isEditable.value = true
  // loadDatatype();

  getGroupList();
  search();
});

const loadDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      dataTypeList.value = res.data.map((t: any) => ({
        value: t.name,
        label: langObj.value[t.name],
      }));
    }
  });
};

const getReportInfo = (info: any,index:any) => {
  // inspectionBase.value.reportType = info.type;
  inspectionBase.value.reportIdObj = info;
  selectedRowIndex.value = index;
};


// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

const search = () => {
  // searchModel.value.skip = 1;
  getData();
};

const getData = () => {
  getReportList()
};
const selectMenu = (type:any,conditionList:any)=>{
  menuSelect.value = type;
  searchModel.value.conditionList = conditionList;
  search();
}

//获取报告列表
const getReportList = async () => {
  data.value = [];
  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      factoryId: localStorage.getItem("factory_id"),
      keyword: searchModel.value.keyWord,
      skip: searchModel.value.skip,
      condition: searchModel.value.condition,
      userNick: searchModel.value.userNick,
      take: searchModel.value.take,
      sort: "date",
      order: -1,
      type: searchModel.value.type,
      conditionList:searchModel.value.conditionList
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }
  let result = await request.get("/api/external/inspection", config);

  if (result && result.status === 200) {
    data.value = result.data;
    if (result.data.list.length > 0) {
      getReportInfo(result.data.list[0],0)
    } else {
      getReportInfo({},0)
    }

    searchModel.value.total = result.data.count;
  }
};

// 重置
const reset = () => {
  searchModel.value = {
    ...searchModel.value,
    keyWord: "",
    date: null,
    groupId: null,
    machineId: "",
    type: null,
    condition: null,
    status: null,
    sensorPlace: "",
  };
  search()
};



// 获取报告健康状态
const getReportCondition = (ev: any) => {
  if (ev && ev.condition) {
    return conditionList.find(
      (p: any) => p.value === ev.condition
    );
  } else {
    return conditionList[0];
  }
};

// 获取报告类型
const getReportType = (ev: any) => {
  if (ev && ev.type) {
    return typeLists.find(
      (p: any) => p.value === ev.type
    ).label;
  } else {
    return typeLists[0].label;
  }
};


const selectedRowIndex: any = ref(0);
const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  let className = ""
  if (selectedRowIndex.value == index) {
    className += "active-table"
  } else {
    if (index % 2 === 0) {
      className = "even-row"
    } else {
      className = "odd-row"
    }
  }

  return className
};


const rowClick = (record: any, index: any) => {
  return {
    onclick: (e: any) => {
      selectedRowIndex.value = index;
      // getReportInfo(record)

    }
  }

}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};



// 合并巡检报告功能
let mergeSearchModel: any = ref({
  date: null,
  groupId: null,
  machineId: null,
  type: 1
})
let sensorList: any = ref([])
let mergeDataList: any = ref([])

const resetMerge = () => {
  mergeSearchModel.value = {
    date: null,
    groupId: null,
    machineId: null,
    type: 1
  }
  getMergeData()
}

const concatReport = async (ev: any) => {
  visibleReportCon.value = true;

  await getSensorList()
  getMergeData()
}

//获取测点数据
const getSensorList = async () => {
  let result = await request.get('/api/sensors/all')
  sensorList.value = result?.data || []
}

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  mergeSearchModel.value.machineId = null;
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

// 获取数据
const getMergeData = async () => {
  reportLoading.value = true
  let config: any = {
    params: {
      groupId: mergeSearchModel.value.groupId,
      factoryId: localStorage.getItem("factory_id"),
      machineId: mergeSearchModel.value.machineId,
      skip: 1,
      type: mergeSearchModel.value.type,
      take: 100000000
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (mergeSearchModel.value.date && mergeSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(mergeSearchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(mergeSearchModel.value.date[1]), 2);
  }
  let result = await request.get("/api/external/inspection", config);

  dealWithData(result?.data?.list || [])
}

// 处理数据
const dealWithData = (dataList: any) => {
  let list: any = []
  let groups = []
  if (mergeSearchModel.value.groupId) {
    groups = groupList.value.filter((p: any) => p.id === mergeSearchModel.value.groupId)
  } else {
    groups = groupList.value
  }
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "{}");
  groups.map((g: any) => {
    let machines = []
    if (mergeSearchModel.value.machineId) {
      machines = memberInfo.machines.filter(
        (p: any) => p.id === mergeSearchModel.value.machineId && p.groupId === g.id
      );
    } else {
      machines = memberInfo.machines.filter(
        (p: any) => p.groupId === g.id
      );
    }
    let first: any = {
      groupName: g.name,
      groupId: g.id,
      machines: []
    }
    machines.map((m: any) => {
      let sensors = sensorList.value.filter((p: any) => p.machineId === m.id)
      let sL: any = []
      if (sensors.length > 1) {
        sensors.map((s: any) => {
          let rL = dataList.filter((p: any) => p.sensorId === s.id).map((p: any) => ({ id: p.id, userNick: p.userNick, machineId: p.machineId, sensorId: p.sensorId, date: p.date, selectValue: null }))
          if (rL.length > 0) {
            sL.push({
              id: s.id,
              sensorPlace: s.sensorPlace,
              list: rL
            })
          }


        })
        first.machines.push({
          machineName: m.name,
          machineId: m.id,
          sensors: sL
        })
      }



    })
    // list.push(first)

    // if(first.machines?.map((s:any)=>s.sensors.length>1)){
    //   list.push(first)
    // }
    first.machines?.map((s: any) => {
      if (s.sensors.length > 1 && !list.find((a: any) => a.groupId == first.groupId)) {
        list.push(first)
      }


    })





  })
  reportLoading.value = false
  mergeDataList.value = list

}
const concatReportRequest = (ev: any) => {


  let ids: any = []
  let list = JSON.parse(JSON.stringify(mergeDataList.value))

  for (let g of list) {
    if (g.machines?.length > 0) {
      for (let m of g.machines) {
        // let values = m.sensors.filter((p:any) => p.)
        let lists: any = []
        let numberList: any = []
        for (let s of m.sensors) {
          let values = s.list.filter((p: any) => p.selectValue).map((p: any) => p.selectValue)
          let length1 = values.length
          values = [... new Set(values)]
          let length2 = values.length
          numberList = numberList.concat(values)
          if (length1 !== length2) {
            message.warning('同测点请勿选择相同编号')
            return
          }
          lists.push(s.list.filter((p: any) => p.selectValue))
        }
        numberList = [... new Set(numberList)]
        numberList.map((n: any) => {
          let list: any = []
          lists.map((d: any) => {
            let info = d.find((p: any) => p.selectValue === n)
            if (info) {
              list.push(info.id)
            }
          })
          if (list?.length > 1) {
            ids.push(list)
          }
        })


      }
    }
  }

  console.log('ids', ids)

  if (!ids.length) {
    message.warning('请选择需要合并的报告')
    return
  }
  concatLoading.value = true


  request.post("/api/external/inspection/merge", ids).then((res: any) => {
    
    if (res && res.data) {
      message.success('合并成功');
      visibleReportCon.value = false;
      concatLoading.value = false
      // getMergeData()
      search()
    } else {
      message.error('合并失败');
      concatLoading.value = false;
    }
  })
};

// 导出报告
let downLoadVisible = ref(false)
let downLoadList: any = ref([])
const downLoad = async () => {

  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      factoryId: localStorage.getItem("factory_id"),
      keyword: searchModel.value.keyWord,
      skip: 1,
      condition: searchModel.value.condition,
      userNick: searchModel.value.userNick,
      take: 10000,
      sort: "date",
      order: -1,
      type: searchModel.value.type,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }
  let result = await request.get("/api/external/inspection/all", config);

  if (result && result.status === 200) {
    downLoadList.value = result.data.list;
  }



  downLoadVisible.value = true
}

const downLoadCancel = () => {
  downLoadVisible.value = false
}




return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 1 ? '2px solid #00FFF4' : '' }]),
              class: "py-3 cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1,[])))
            }, _toDisplayString(_unref(langObj)['全部报告']), 5),
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 2 ? '2px solid #00FFF4' : '' }]),
              class: "py-3 ml-12 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2,[1,2,3,4,5,6,7])))
            }, _toDisplayString(_unref(langObj)['报警报告']), 5),
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 3 ? '2px solid #00FFF4' : '' }]),
              class: "py-3 ml-12 cursor-pointer",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMenu(3,[0])))
            }, _toDisplayString(_unref(langObj)['健康报告']), 5)
          ]),
          _createVNode(_component_a_form, {
            model: _unref(searchModel),
            name: "horizontal_login",
            layout: "inline",
            autocomplete: "off"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['区域'],
                name: "groupId",
                allowClear: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(searchModel).groupId,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                    style: {"width":"200px"},
                    allowClear: "",
                    placeholder: _unref(langObj)['请选择区域']
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['设备名称'],
                name: "more"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _unref(searchModel).keyWord,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(searchModel).keyWord) = $event)),
                    placeholder: _unref(langObj)['请输入']
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['巡检报告类型'],
                name: "type"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_select, {
                    value: _unref(searchModel).type,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(searchModel).type) = $event)),
                    style: {"width":"120px"},
                    allowClear: "",
                    placeholder: _unref(langObj)['请选择']
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeLists), (item, index) => {
                        return (_openBlock(), _createBlock(_component_a_select_option, {
                          key: index,
                          value: item.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.label), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['日期'],
                name: "date"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    value: _unref(searchModel).date,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(searchModel).date) = $event)),
                    "disabled-date": disabledDate,
                    onCalendarChange: onCalendarChange
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['巡检人'],
                name: "userNick"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input, {
                    value: _unref(searchModel).userNick,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(searchModel).userNick) = $event)),
                    placeholder: _unref(langObj)['请输入']
                  }, null, 8, ["value", "placeholder"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", {
                      onClick: reset,
                      class: "box px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                    }, _toDisplayString(_unref(langObj)['重置']), 1),
                    _createElementVNode("div", {
                      onClick: getData,
                      class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499"}
                    }, _toDisplayString(_unref(langObj)['查询']), 1),
                    _createElementVNode("div", {
                      onClick: downLoad,
                      class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499"}
                    }, _toDisplayString(_unref(langObj)['导出']), 1),
                    _createElementVNode("div", {
                      class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499"},
                      onClick: concatReport
                    }, _toDisplayString(_unref(langObj)['合并报告']), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: inspectionColumns,
              "data-source": _unref(data).list,
              pagination: pagination.value,
              onChange: handleTableChange
            }, {
              bodyCell: _withCtx(({ column, text, record,index}) => [
                (column.dataIndex === 'groupId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_unref(getGroupName)(record.machine?.groupId)), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'type')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(getReportType(record)), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", {
                        style: _normalizeStyle([{"width":"14px","height":"14px","border-radius":"50%"}, { background: getReportCondition(record)?.color }])
                      }, null, 4),
                      _createElementVNode("div", {
                        class: "ml-1",
                        style: _normalizeStyle({ color: getReportCondition(record)?.color })
                      }, _toDisplayString(_unref(langObj)[getReportCondition(record)?.label]), 5)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      onClick: ($event: any) => (getReportInfo(record,index)),
                      class: "theme-table-link"
                    }, _toDisplayString(_unref(langObj)['查看报告']), 9, _hoisted_9))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source", "pagination"])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(reportInfo, {
            reportIdObj: _unref(inspectionBase).reportIdObj
          }, null, 8, ["reportIdObj"])
        ]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibleReportCon),
          "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => (_isRef(visibleReportCon) ? (visibleReportCon).value = $event : visibleReportCon = $event)),
          closable: true,
          title: _unref(langObj)['合并报告'],
          okText: _unref(langObj)['合并报告'],
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "2000px",
          maskClosable: false,
          confirmLoading: _unref(concatLoading),
          onOk: concatReportRequest
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: _unref(mergeSearchModel),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['巡检日期'],
                  name: "date"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_range_picker, {
                      value: _unref(mergeSearchModel).date,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(mergeSearchModel).date) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备区域'],
                  name: "condition"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(mergeSearchModel).groupId,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(mergeSearchModel).groupId) = $event)),
                      style: {"width":"200px"},
                      allowClear: "",
                      placeholder: _unref(langObj)['请选择区域'],
                      onChange: selectGroup
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备'],
                  name: "userNick"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(mergeSearchModel).machineId,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(mergeSearchModel).machineId) = $event)),
                      style: {"width":"200px"},
                      allowClear: "",
                      placeholder: _unref(langObj)['请选择设备']
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['巡检报告类型'],
                  name: "type"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(mergeSearchModel).type,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(mergeSearchModel).type) = $event)),
                      style: {"width":"120px"},
                      placeholder: _unref(langObj)['请选择']
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeLists), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", {
                        onClick: resetMerge,
                        class: "box px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                      }, _toDisplayString(_unref(langObj)['重置']), 1),
                      _createElementVNode("div", {
                        onClick: getMergeData,
                        class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_unref(langObj)['查询']), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createVNode(_component_a_spin, {
              tip: "Loading...",
              spinning: _unref(reportLoading)
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(mergeDataList), (item1, index1) => {
                    return (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, _toDisplayString(item1.groupName), 1),
                      _createElementVNode("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item1.machines, (item2, index2) => {
                          return (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            (item2.sensors.length > 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(item2.machineName), 1))
                              : _createCommentVNode("", true),
                            (item2.sensors.length > 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.sensors, (item3, index3) => {
                                    return (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                      _createElementVNode("div", _hoisted_19, _toDisplayString(item3.sensorPlace), 1),
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item3.list, (item4, index4) => {
                                        return (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                          _createElementVNode("div", null, [
                                            _createVNode(_component_a_select, {
                                              value: item4.selectValue,
                                              "onUpdate:value": ($event: any) => ((item4.selectValue) = $event),
                                              style: {"width":"60px"},
                                              allowClear: ""
                                            }, {
                                              default: _withCtx(() => [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item3.list, (item5, index5) => {
                                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                                    key: index5,
                                                    value: index5 + 1
                                                  }, {
                                                    default: _withCtx(() => [
                                                      _createTextVNode(_toDisplayString(index5 + 1), 1)
                                                    ]),
                                                    _: 2
                                                  }, 1032, ["value"]))
                                                }), 128))
                                              ]),
                                              _: 2
                                            }, 1032, ["value", "onUpdate:value"])
                                          ]),
                                          _createElementVNode("div", _hoisted_21, [
                                            _createElementVNode("div", null, _toDisplayString(item4.date), 1),
                                            _createElementVNode("div", null, "巡检人：" + _toDisplayString(item4.userNick), 1)
                                          ])
                                        ]))
                                      }), 256))
                                    ]))
                                  }), 256))
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 256))
                      ])
                    ]))
                  }), 256))
                ])
              ]),
              _: 1
            }, 8, ["spinning"])
          ]),
          _: 1
        }, 8, ["visible", "title", "okText", "confirmLoading"]),
        _createVNode(_component_a_modal, {
          width: 2000,
          visible: _unref(downLoadVisible),
          "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(downLoadVisible) ? (downLoadVisible).value = $event : downLoadVisible = $event)),
          title: _unref(langObj)['下载'],
          maskClosable: false,
          footer: false
        }, {
          default: _withCtx(() => [
            _createVNode(DownLoadComponent, {
              onDownLoadCancel: downLoadCancel,
              type: 2,
              dataList: _unref(downLoadList)
            }, null, 8, ["dataList"])
          ]),
          _: 1
        }, 8, ["visible", "title"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})