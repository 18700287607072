import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = {
  class: "w-64 px-2 overflow-y-auto scroll-box h-screen code-box",
  style: {"height":"100%"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { style: {"color":"#f50"} }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "flex mb-3 items-center" }
const _hoisted_7 = {
  key: 0,
  class: "freqx_formTitle"
}
const _hoisted_8 = {
  class: "flex",
  style: {"text-align":"right","justify-content":"flex-end"}
}

import { computed, onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { NodeIndexOutlined } from "@ant-design/icons-vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'On',
  setup(__props) {

class SensorThreshold {
  sensorId: string = "";
  sensorPlace: string = "";
  threshold: number = 0;
}

class MachineOnlineConfig {
  _id: string = "";
  type: "single" | "group" = "group";
  machineId: string = "";
  sensorId: string = "";
  dataType: string = "";
  interval: number = 300;
  threshold: number = 0;
  groupDelay: number = 120;
  groupInterval: number = 300;
  groupSensorThresholds: SensorThreshold[] = [];
}

let currentMachine = ref<any>();
const selectTree = async (key: any, val: any) => {
  if (val.node.type == "machine") {
    currentMachine.value = val.node;
    await getMachineOnlineConfig();
  }
};

let isSingle = computed(() => {
  return !onlineConfig.value.type || onlineConfig.value.type == "single";
});
let isGroup = computed(() => {
  return onlineConfig.value.type == "group";
});

function selectSingle(e: any) {
  if (e.target.checked) {
    onlineConfig.value.type = "single";
  } else {
    onlineConfig.value.type = "group";
  }
}

function selectGroup(e: any) {
  if (e.target.checked) {
    onlineConfig.value.type = "group";
  } else {
    onlineConfig.value.type = "single";
  }
}

let filterKeyWord = ref<any>();
let treeData = ref<any>();

const onExpand = (keys: string[]) => {
  expandedKeys.value = keys;
  autoExpandParent.value = false;
};
const expandedKeys = ref<(string | number)[]>([]);
const autoExpandParent = ref<boolean>(true);

watch(filterKeyWord, () => {
  expandedKeys.value = [];
  sonKey(treeData.value);
  autoExpandParent.value = true;
});
const sonKey = (item: any) => {
  item.forEach((element: any) => {
    if (element.title.indexOf(filterKeyWord.value) > -1) {
      // expandedKeys.value.push(key);
      expandedKeys.value.push(element.key);
    }
    if (element.children && element.children.length) {
      sonKey(element.children);
    }
  });
};
const getParentKey = (
    key: string | number,
    tree: any
): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (
          node.children.some((item: { key: string | number }) => item.key === key)
      ) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};


let factoryList = ref<any>();
let allFactory: any = ref([])
const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId)
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id)
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })

      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let res = await request.post("/api/machines/all", { factoryIds })
  factoryList.value.forEach((element: any) => {
    if (res) {
      element.children.forEach((sonFact: any) => {
        if (sonFact.type == "factory" && sonFact.children) {
          sonFact.children.forEach((groupFact: any) => {
            if (groupFact.type == "group") {
              groupFact.children = res.data
                  .filter((t: any) => t.groupId == groupFact.value)
                  .map((t: any) => ({
                    type: "machine",
                    ...t,
                    value: t.id,
                    label: t.machineName,
                    title: t.machineName,
                    key: t.id,
                  }));
            }
          });
        } else if (sonFact.type == "group") {
          sonFact.children = res.data
              .filter((t: any) => t.groupId == sonFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
        }
      });
    }
  });
  setTimeout(() => {
    treeData.value = factoryList.value;
  }, 100);
};

let onlineConfig = ref<MachineOnlineConfig>(new MachineOnlineConfig())
let sensorList: any = ref([])
let dataTypeList: any = ref([])

onMounted(() => {
  init()
})

const init = () => {
  getDatatype()
  let requestId = uuidv4();
  loadFactory(requestId);
}

const getDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      let list: any = res.data.map((t: any) => ({
        value: t.name,
        label: t.name,
      }));

      dataTypeList.value = list.concat([{ label: '原始波形', value: '原始波形' }])
    }
  });
};

const getMachineOnlineConfig = async () => {
  if (!currentMachine.value) return
  sensorList.value = []

  const config = {
    params: {
      machineId: currentMachine.value.id
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultSensor = await request.get('/api/sensors', config)
  if (resultSensor?.data?.length > 0) {
    sensorList.value = resultSensor.data
  }

  let result = await request.get('/api/machine-online-config/info', config)
  if (result && result.data) {
    onlineConfig.value = result.data
  } else {
    onlineConfig.value = new MachineOnlineConfig()
    onlineConfig.value.machineId = currentMachine.value.id;
  }

  if (!onlineConfig.value.groupSensorThresholds) {
    onlineConfig.value.groupSensorThresholds = [];
  }

  let ss: SensorThreshold[] = onlineConfig.value.groupSensorThresholds || [];
  for (const sensor of sensorList.value) {
    let sensorThreshold = onlineConfig.value.groupSensorThresholds?.find(t => t.sensorId == sensor.id)
    if (sensorThreshold) {
      sensorThreshold.sensorPlace = sensor.sensorPlace
      if (!sensorThreshold.threshold) {
        sensorThreshold.threshold = sensor.defaultPowerOnThreshold || 0;
      }
    } else {
      ss.push({
        sensorId: sensor.id,
        sensorPlace: sensor.sensorPlace,
        threshold: sensor.defaultPowerOnThreshold || 0
      });
    }
  }

  onlineConfig.value.groupSensorThresholds = ss.filter(t => sensorList.value.find((s: any) => s.id == t.sensorId))

}

const submit = async () => {
  let result = onlineConfig.value._id
      ? await request.put('/api/machine-online-config', onlineConfig.value)
      : await request.post('/api/machine-online-config', onlineConfig.value)
  if (result && result.data) {
    message.success('操作成功!')
    await getMachineOnlineConfig()
  } else {
    message.warning('操作失败，请联系管理员')
  }
}



return (_ctx: any,_cache: any) => {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout_content, { style: {"padding":"0 20px","height":"100%"} }, {
      default: _withCtx(() => [
        _createVNode(_component_a_row, {
          type: "flex",
          style: {"height":"100%"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_col, {
              span: 5,
              order: 1,
              style: {"height":"100%"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_input_search, {
                    value: _unref(filterKeyWord),
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filterKeyWord) ? (filterKeyWord).value = $event : filterKeyWord = $event)),
                    style: {"margin-bottom":"8px"},
                    placeholder: "输入区域或设备名称筛选"
                  }, null, 8, ["value"]),
                  _createVNode(_component_a_tree, {
                    class: "draggable-tree",
                    draggable: "",
                    "block-node": "",
                    autoExpandParent: autoExpandParent.value,
                    "expanded-keys": expandedKeys.value,
                    "tree-data": _unref(treeData),
                    onExpand: onExpand,
                    onSelect: selectTree
                  }, {
                    title: _withCtx(({ title }) => [
                      (title.indexOf(_unref(filterKeyWord)) > -1)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                            _createTextVNode(_toDisplayString(title.substr(0, title.indexOf(_unref(filterKeyWord)))) + " ", 1),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(filterKeyWord)), 1),
                            _createTextVNode(" " + _toDisplayString(title.substr(
                                title.indexOf(_unref(filterKeyWord)) + _unref(filterKeyWord).length
                            )), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(title), 1))
                    ]),
                    _: 1
                  }, 8, ["autoExpandParent", "expanded-keys", "tree-data"])
                ])
              ]),
              _: 1
            }),
            (_unref(currentMachine))
              ? (_openBlock(), _createBlock(_component_a_col, {
                  key: 0,
                  span: 19,
                  order: 2,
                  style: {"padding-bottom":"20px"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (_unref(currentMachine))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_unref(currentMachine)?.machineName), 1))
                        : _createCommentVNode("", true),
                      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          onClick: submit
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("保存")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _createVNode(_component_a_row, { class: "mb-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_checkbox, {
                          checked: _unref(isSingle),
                          onChange: selectSingle
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode("单侧点")
                          ])),
                          _: 1
                        }, 8, ["checked"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_row, { class: "mb-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form, {
                          model: _unref(onlineConfig),
                          name: "horizontal_single",
                          layout: "inline",
                          autocomplete: "off"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: "开关机测点",
                              name: "sensorId"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  value: _unref(onlineConfig).sensorId,
                                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(onlineConfig).sensorId) = $event)),
                                  style: {"min-width":"200px"},
                                  placeholder: "请选择测点",
                                  allowClear: ""
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorList), (item, index) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: index,
                                        value: item.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, {
                              label: "数据类型",
                              name: "dataType"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  value: _unref(onlineConfig).dataType,
                                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(onlineConfig).dataType) = $event)),
                                  style: {"min-width":"200px"},
                                  placeholder: "请选择数据类型",
                                  allowClear: ""
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataTypeList), (item, index) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: index,
                                        value: item.value
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.label), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, {
                              label: "间隔（秒）",
                              name: "interval"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input_number, {
                                  style: {"min-width":"100px"},
                                  value: _unref(onlineConfig).interval,
                                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(onlineConfig).interval) = $event)),
                                  controls: false
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, {
                              label: "阈值",
                              name: "threshold"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input_number, {
                                  style: {"min-width":"100px"},
                                  value: _unref(onlineConfig).threshold,
                                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(onlineConfig).threshold) = $event)),
                                  controls: false
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["model"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_row, { class: "mb-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_checkbox, {
                          checked: _unref(isGroup),
                          onChange: selectGroup
                        }, {
                          default: _withCtx(() => _cache[10] || (_cache[10] = [
                            _createTextVNode("半数及以上")
                          ])),
                          _: 1
                        }, 8, ["checked"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_row, { class: "mb-2" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form, {
                          model: _unref(onlineConfig),
                          name: "horizontal_multi",
                          layout: "inline",
                          autocomplete: "off"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: "距首次获取数据延迟（秒）",
                              name: "groupDelay"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input_number, {
                                  style: {"min-width":"100px"},
                                  value: _unref(onlineConfig).groupDelay,
                                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(onlineConfig).groupDelay) = $event)),
                                  controls: false
                                }, null, 8, ["value"]),
                                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "text-white" }, "秒统计", -1))
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_form_item, {
                              label: "间隔（秒）",
                              name: "groupInterval"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input_number, {
                                  style: {"min-width":"100px"},
                                  value: _unref(onlineConfig).groupInterval,
                                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(onlineConfig).groupInterval) = $event)),
                                  controls: false
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["model"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_row, { class: "mb-2" }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createElementVNode("span", null, "开关机测点阈值", -1)
                      ])),
                      _: 1
                    }),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(onlineConfig).groupSensorThresholds, (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_row, { class: "mb-1" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_col, { span: 4 }, {
                            default: _withCtx(() => [
                              _createElementVNode("label", null, [
                                _createVNode(_unref(NodeIndexOutlined)),
                                _createTextVNode(" " + _toDisplayString(item.sensorPlace) + "：", 1)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_a_col, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_input_number, {
                                style: {"min-width":"100px"},
                                value: item.threshold,
                                "onUpdate:value": ($event: any) => ((item.threshold) = $event),
                                controls: false
                              }, null, 8, ["value", "onUpdate:value"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 256))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}
}

})