import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  style: {"padding":"30px 0","height":"calc(100vh - 100px)","overflow-y":"scroll","background-color":"#03155C"},
  class: "bule_list_page2 theme-common"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  style: {"padding":"30px 0","height":"calc(100vh - 100px)","overflow-y":"scroll","background-color":"#03155C"},
  class: "bule_list_page theme-common"
}

import { ref, watch } from "vue";
import { useRouter } from "vue-router";

export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const router = useRouter();
let path:any = ref('')
path.value = router?.currentRoute?.value?.path

watch(
  () => router.currentRoute.value,
  (newValue: any) => {
    path.value = router?.currentRoute?.value?.path
  },
  { immediate: true }
)


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_unref(path) === '/system/eventOrder')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_view, { style: {"height":"100%"} })
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_view, { style: {"height":"100%"} })
        ])
      ]))
}
}

})