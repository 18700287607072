import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "w-full flex whiteBg",
  style: {"color":"#fff"}
}
const _hoisted_2 = { style: {"width":"270px"} }
const _hoisted_3 = {
  class: "w-full box p-2",
  style: {"width":"270px"}
}
const _hoisted_4 = {
  class: "box mt-4",
  style: {"width":"270px"}
}
const _hoisted_5 = {
  key: 0,
  class: "theme-table-link"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex-1 box ml-4 p-3" }
const _hoisted_8 = { class: "w-full flex items-center" }
const _hoisted_9 = { class: "ml-4" }
const _hoisted_10 = { class: "ml-4" }
const _hoisted_11 = ["id"]
const _hoisted_12 = { class: "w-full flex items-center" }
const _hoisted_13 = { class: "ml-4" }
const _hoisted_14 = { class: "ml-4" }
const _hoisted_15 = ["id"]

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { transformDate2, transformDate, getChartDataTool } from "../../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";


export default /*@__PURE__*/_defineComponent({
  __name: 'chart3',
  props: {
    sensorInfo: null,
},
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()
 let typeList = [
  { label: langObj.value["高频加速度(长)"], value: 2, type: "TW", unit: "m/s²" },
  { label: langObj.value["高频加速度"], value: 1, type: "HZ", unit: "m/s²" },
  // {label : '温度', value: 2},
  { label: langObj.value["低频加速度X"], value: 3, type: "LX", unit: "m/s²" },
  { label: langObj.value["低频加速度Y"], value: 4, type: "LY", unit: "m/s²" },
  { label: langObj.value["低频加速度Z"], value: 5, type: "LZ", unit: "m/s²" },
  { label: langObj.value["速度X"], value: 6, type: "SX", unit: "mm/s" },
  { label: langObj.value["速度Y"], value: 7, type: "SY", unit: "mm/s" },
  { label: langObj.value["速度Z"], value: 8, type: "SZ", unit: "mm/s" },
];


const columns = [
{
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["值"],
    dataIndex: "value",
  },
];

let dataList:any = ref([])

const props:any = __props;
let sensorInfo:any = ref()
sensorInfo.value = props.sensorInfo
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
    type: 1,
})
watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);

let chartList:any = ref([])

let selectedRowKeys = ref([])
const onSelectChange = (ev:any) => {
    selectedRowKeys.value = ev
    getChartData()
}

onMounted(() => {
    init()
})

const init = () => {
    if(!sensorInfo.value) return
    getTrendListAll()
}

//获取所有时间点数据
const getTrendListAll = async() => {
    dataList.value = []
    selectedRowKeys.value = []
    chartList.value = []
    let config: any = {
        params: {
            machineId: sensorInfo.value.machineId ,
            sensorId: sensorInfo.value.id ,
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            begin: transformDate(new Date('2021/01/01 00:00:00')),
            end: transformDate(new Date()),
            type: typeList.find((p:any) => p.value === searchModel.value.type)?.type
        },
        headers: {
            requestId: uuidv4(),
        },
    }; 

    let result = await request.get('/api/external/singles', config)

    if(result && result.data) {
        dataList.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

// 获取chart数据
let loading:any = ref(false)
const getChartData = async() => {
    loading.value = true
    chartList.value = []
    for(let index in selectedRowKeys.value) {
        let item = selectedRowKeys.value[index]
        let info = dataList.value.find((p:any) => p._id === item)

        let t = typeList.find((p:any) => p.value === searchModel.value.type)?.type

        let config: any = {
            params: {
                sensorId: info.sensorId ,
                date: info.date,
                type: t,
                latest: true
            },
            headers: {
                requestId: uuidv4(),
            },
        }; 

        let result = await request.get('/api/external/singles/info', config)
        if(!result || !result.data || !result.data.url) {
            return
        }

        let url = result.data.url
        // url = 'http://source.freqx.com/singles/632d1ec9735daf1ba76d4f9f/20231123/null_633434ab735daf1ba76d55e1_HZ_20231123_080000.Single'
        //幅值
        let resultTIME:any = await request.post('/draw/api/time-sort', {url:  [url]})
        let resultFFT:any = await request.post('/draw/api/fft-sort', {url:  [url]}) 
        let resultTIME2 = getChartDataTool(resultTIME?.data)
        let resultFFT2 = getChartDataTool(resultFFT?.data)

        let xDataTime = resultTIME2.axisX
        let yDataTime = resultTIME2.axisY

        let xDataFft = resultFFT2.axisX
        let yDataFft = resultFFT2.axisY

        chartList.value.push({
            type: typeList.find((p:any) => p.value === searchModel.value.type)?.label, 
            date: info.date, 
            timeData: {
                xData: xDataTime,
                yData: yDataTime,
            },
            fftData: {
                xData: xDataFft,
                yData: yDataFft,
            }
        })

        setTimeout(() => {
            initChart({xData: xDataTime,yData: yDataTime}, 'chart3A'+index, '时域图', 's', typeList.find((p:any) => p.value === searchModel.value.type)?.unit)
            initChart({xData: xDataFft,yData: yDataFft}, 'chart3B'+index, '幅值谱图', 'hz', typeList.find((p:any) => p.value === searchModel.value.type)?.unit) 
        }, 200);

        
    }
    loading.value = false
}




// 测试绘图
const initChart = (data:any, id:any, title:any, xUnit:any, yUnit:any) => {
    let {xData, yData} = data
    const ec = echarts as any;
    if(!document.getElementById(id)) return
    let myChartA = ec.init(document.getElementById(id));

    let option = {
        color: theme.value=='white'? ["#274CE0"]:["#00FFF4"],
        title: {
            text: title,
            textStyle: {
                color: '#fff'
            },
            left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
            top: '8%',
        },
        toolbox: {
          trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        xAxis: {
            name: xUnit,
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              showMaxLabel: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.3
              }
            },
            data: xData
        },
        yAxis: {
            name: yUnit,
            type: 'value'
        },
        series: [
            {
                data: yData,
                type: 'line'
            }
        ]
    };

    myChartA && myChartA.setOption(option)
}

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const handleTableChange = (pagination: any) => {
    // 处理分页数据
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getTrendListAll();
};



return (_ctx: any,_cache: any) => {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_radio_group, {
              value: _unref(searchModel).type,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).type) = $event)),
              style: {"width":"100%"},
              onChange: init
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_radio, {
                    key: index,
                    style: {"display":"block"},
                    value: item.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_spin, { spinning: _unref(loading) }, {
              default: _withCtx(() => [
                _createVNode(_component_a_table, {
                  style: {"width":"100%"},
                  size: "small",
                  "row-class-name": getRowClassName,
                  columns: columns,
                  "data-source": _unref(dataList),
                  pagination: pagination.value,
                  onChange: handleTableChange,
                  "row-selection": { selectedRowKeys: _unref(selectedRowKeys), onChange: onSelectChange },
                  rowKey: "_id"
                }, {
                  bodyCell: _withCtx(({ column, text }) => [
                    (column.dataIndex === 'opt')
                      ? (_openBlock(), _createElementBlock("a", _hoisted_5, _toDisplayString(_unref(langObj)['下载']), 1))
                      : _createCommentVNode("", true),
                    (column.dataIndex === 'value')
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (text || text==0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(text.toFixed(2)), 1))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["data-source", "pagination", "row-selection"])
              ]),
              _: 1
            }, 8, ["spinning"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(chartList), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "w-full",
              key: index
            }, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, _toDisplayString(item.type), 1),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(langObj)['采样时间']) + "：" + _toDisplayString(item.date), 1)
              ]),
              _createElementVNode("div", {
                id: 'chart3A'+index,
                style: {"width":"100%","height":"350px"}
              }, null, 8, _hoisted_11)
            ]))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(chartList), (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "w-full",
              key: index
            }, [
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(item.type), 1),
                _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)['采样时间']) + "：" + _toDisplayString(item.date), 1)
              ]),
              _createElementVNode("div", {
                id: 'chart3B'+index,
                style: {"width":"100%","height":"350px"}
              }, null, 8, _hoisted_15)
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})