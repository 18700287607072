<template>
  <div>
   <a-modal 
            v-model:visible="visible"
            :closable="false"
            :footer="null"
            :bodyStyle= "{'background': '#020F46'}"
            style="top: 30%"
            width="1200px"
            :maskClosable="false"
            v-model:z-index="editRindex"
        > 
    <div class="bule_list_page theme-common">
    <a-layout-content style="padding: 0 20px" class="flex">
      <a-tree
          style="height: 80vh; overflow: auto; padding: 10px 0; width: 300px"
          class="draggable-tree"
          block-node
          :tree-data="treeData"
          @select="selectTree"
      />
      <div class="w-full fac-box p-3 ml-4" v-if="dataList.length">
        <div
            style="
            text-align: right;
            margin-bottom: 15px;
            justify-content: flex-end;
          "
            class="flex"
        >
          <div
              class="btn2-o flex items-center cursor-pointer"
              @click="handleOk"
              style="padding: 4px 8px; max-width: 145px; margin-left: 10px"
          >
            {{ getLanguage("保存", language) }}
          </div>
        </div>
        <a-row class="mb-4">
          <a-col span="8">
            <span>合并报警间隔时间：</span>
            <a-input-number v-model:value="form.combineRangeSeconds"
                            style="width: 60px" size="small" :min="0"/>
            <span>秒内报警，合并成一条</span>
          </a-col>
          <a-col span="12">
            <span>不触发报警条件：采集时间与实际报警时间延迟超过</span>
            <a-input-number v-model:value="form.collectExpireHours"
                            style="width: 60px" size="small" :min="0"/>
            <span>小时不对外发送报警消息</span>
          </a-col>
        </a-row>
        <a-table
            bordered
            :columns="columns1"
            :dataSource="dataList"
            :pagination="false">
          <template #bodyCell="{ column, record, text, index }">
            <template v-if="column.dataIndex === 'messageType'">
              <div class="flex items-center">
                <div class="ml-2">{{ getMessageType(Number(text)) }}</div>
              </div>
            </template>
            <template v-if="column.dataIndex === 'outerMembers'">
              <div class="flex items-center">
                <div class="ml-2">
                  <a-select
                      mode="multiple"
                      v-model:value="record.outerMembers"
                      style="width: 300px"
                      placeholder="请选择接收人"
                      :filterOption="filterOption" :dropdownClassName="dropdownClassName" 
                  >
                    <a-select-option
                        v-for="(item, i) in oMembers"
                        :key="item._id"
                    >
                      {{ item.nickName }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </template>
            <template v-if="column.dataIndex === 'outerTargetPlatforms'">
              <div class="flex items-center">
                <div class="ml-2">
                  <a-select
                      mode="multiple"
                      v-model:value="record.outerTargetPlatforms"
                      style="width: 200px"
                      placeholder="请选择推送平台" :dropdownClassName="dropdownClassName" 
                  >
                    <a-select-option
                        v-for="(item, i) in pushTypes"
                        :key="item.code"
                        :value="item.code"
                    >
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </template>
          </template>
        </a-table>
      </div>
      <div class="w-full fac-box p-3 ml-4" v-else>
        <a-alert message="请选择工厂" type="warning"/>
      </div>
    </a-layout-content>
    </div>
    </a-modal>

  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineExpose, defineEmits, computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";

import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { getLanguage } from "../../common/translate";
const router = useRouter();

let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
};
getLang();
let visible = ref(true)
let editRindex = ref("auto");
let dropdownClassName = ref("");

const getMessageType = (t) => {
  switch (t) {
    case 4:
      return getLanguage("智能诊断报警", language.value);
    case 5:
      return getLanguage("设备门限报警", language.value);
  }
};

const getMembers = async (factoryId: any) => {
  const config = {
    params: { factoryId: factoryId, take: 10000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  let res = await request.get("/api/members", config);
  oMembers.value = res?.data?.list
  return res?.data?.list || [];
};

let pushTypes = ref([
  {
    code: 0,
    name: "短信",
  },
  {
    code: 1,
    name: "微信公众号",
  },
]);

let oMembers = ref([]);

let columns1 = [
  {
    title: getLanguage("报警类型", language.value),
    dataIndex: "messageType",
  },
  {
    title: getLanguage("接收人", language.value),
    dataIndex: "outerMembers",
  },
  {
    title: getLanguage("推送平台", language.value),
    dataIndex: "outerTargetPlatforms",
  },
];

class AlarmSettings {
  combineRangeSeconds: number = 30;
  collectExpireHours: number = 4;
}

let form = ref<AlarmSettings>(new AlarmSettings());

const getFactoryAlarmSettings = (factoryId: string) => {
  const config = {
    params: { id: factoryId },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories/setting", config).then((res: any) => {
    if (res?.data?.alarmSettings) {
      form.value = res.data.alarmSettings;
      if (!form.value) {
        form.value = new AlarmSettings()
      }
    } else {
      form.value = new AlarmSettings();
    }
  });
};

async function saveFactoryAlarmSettings() {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  let settings = Object.assign({}, form.value);
  let body = {
    factoryId: _selectedFactoryId,
    alarmSettings: settings
  }
  if (!body.alarmSettings.collectExpireHours || !body.alarmSettings.combineRangeSeconds) {
    message.error(getLanguage('请填写报警间隔', language.value));
    return;
  }
  await request.post("/api/factories/setting", body, config);
}


const handleOk = async () => {
  await saveFactoryAlarmSettings();
  let res = await request.put("/api/push-rule", { list: dataList.value });
  if (res && res.status == 200) {
    message.success(getLanguage("保存推送配置成功", language.value));
    visible.value = false
    emits("savePushRuleDriver", true);
  }
};

const filterOption = (input: any, option: any) => {
  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

let dataList = ref<any>([]);

let _selectedFactoryId: string = "";
let factoryList = ref<any>();
let treeData: any = ref([]);
let allFactory = ref([]);

const loadFactory = async () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  await request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId);
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id);
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory;
        }
        factory.push(d);
      });

      factoryList.value = factory;
      treeData.value = factoryList.value;
    }
  });
};

// 查询
const search = (factoryId: any) => {
  const config = {
    params: { factoryId: factoryId },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/push-rule/info", config).then((res: any) => {
    if (res.data && res.data.length) {
      dataList.value = res.data;
    } else {
      dataList.value = [
        {
          messageType: 4,
          factoryId: factoryId,
          outerMembers: [],
          outerTargetPlatforms: [],
        },
        {
          messageType: 5,
          factoryId: factoryId,
          outerMembers: [],
          outerTargetPlatforms: [],
        },
      ];
    }
  });
};

const selectTree = async (ev: any, info: any) => {
  if (ev && ev.length && info.node.type == "factory") {
    var cutFactory = allFactory.value.find((t) => t._id == info.node.id);
    if (cutFactory) {
      _selectedFactoryId = cutFactory._id;
      await getMembers(cutFactory._id);
      await getFactoryAlarmSettings(cutFactory._id)
      search(cutFactory._id);
    } else {
      _selectedFactoryId = "";
    }
  }
};
onMounted(async () => {
  editRindex.value = props.editRindex
  dropdownClassName =  "dropdownStyleDriver";
  await loadFactory();
  await selectTree([treeData.value[0].id],{ node: treeData.value[0]});
});

const props: any = defineProps({
    visible: null,
    editRindex: null
});
const emits = defineEmits(["savePushRuleDriver"]);

watch(
    () => props.visible,
    (newValue, oldValue) => {
        visible.value = newValue
        init()
    }
);
</script>

<style scoped>
.flex {
}

.code-box-description {
  padding-bottom: 0;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
}

.main {
  margin-top: 30px;
}

.bigFont {
  font-size: 22px;
  font-weight: 700;
}

.freqx_tips {
  margin-top: 4px;
}

.facInfo {
  border: 1px solid gray;
}

.fac-box {
  border: 1px solid rgba(128, 128, 128, 0.384);
}
</style>

<style lang="less" scoped>
/deep/ .ant-table {
  color: #fff;
}

/deep/ .ant-table-thead .ant-table-cell {
  background-color: #020f46;
  color: #fff;
}

/deep/ table tbody tr:hover > td {
  background-color: #020f46 !important;
}

/deep/ .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #020f46 !important;
}

/deep/ .ant-table-tbody > tr > td {
  transition: 0s;
}

/deep/ .ant-table-tbody > tr.ant-table-row-selected > td {
  background: transparent;
  border-color: #fff;
}

/deep/ .ant-form-item-label > label {
  color: #fff;
}

/deep/ .ant-picker {
  background: transparent;
  border: 1px solid #0d53b7cc;
}

/deep/ .ant-picker-suffix {
  color: #fff;
  opacity: 0.6;
}

/deep/ .ant-input {
  background: transparent;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/
.ant-form-item-has-error
:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  background-color: transparent;
  color: #fff;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background-color: transparent;
  border: 1px solid #0d53b7cc;
}

.content-o {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

/deep/ .ant-btn-primary {
  background: #0d53b7cc !important;
  color: #fff !important;
}

.ant-picker-ok {
  .ant-btn-sm {
    color: #000 !important;
  }
}

.btn1-o {
}

.btn1-select-o {
  border-bottom: 1px solid #00fff4;
}

.btn2-o {
  border-radius: 2px;
  border: 1px solid #0d53b7cc;
  background: #07249966;
}

.btn3-o {
  background: #072499;
  padding: 3px 10px;
  cursor: pointer;
}

.img-ico {
  width: 20px;
  height: 20px;
}

.box1-o {
  border: 1px solid #ffffff66;
  border-radius: 2px;
  padding: 1px 4px;
}

.opt6 {
  opacity: 0.6;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}
</style>
