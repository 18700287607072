<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full px-5 py-6 bule_list_page theme-common">
      <a-form
        :model="searchModel"
        name="horizontal_login"
        layout="inline"
        autocomplete="off"
      >
        <a-form-item :label="langObj['采样时间']" name="date">
          <a-range-picker
            style="width: 300px; margin-left: 20px"
            v-model:value="date"
            :disabled-date="disabledDate"
            show-time 
            format="YYYY-MM-DD HH:mm:ss" 
          />
        </a-form-item>

        <a-form-item>
          <a-button @click="getSensorList" type="primary">
            {{ langObj["查询"] }}
          </a-button>
        </a-form-item>

        <a-form-item>
          <a-button @click="exportSensorList" type="primary">
            {{ langObj["导出"] }}
          </a-button>
        </a-form-item>
      </a-form>

      <div class="box mt-4" style="width: 100%">
        <a-table
          style="width: 100%"
          size="small"
          :columns="sensorColumns"
          :data-source="data"
          :pagination="false"
        >
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'groupId'">
              <span>{{ record.machine?.groupName }}</span>
            </template>
            <template v-if="column.dataIndex === 'machineName'">
              <span>{{ record.machine?.machineName }}</span>
            </template>
            <template v-if="column.dataIndex === 'hardware'">
              <span>{{
                record?.hardware?.vol || record?.hardware?.vol == 0
                  ? record.hardware.vol + " %"
                  : ""
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'hrms'">
              <span>{{
                (record?.hardware?.hrms || "-") +
                "/" +
                (record?.hardware?.hrmsTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'temp'">
              <span>{{
                (record?.hardware?.temp || "-") +
                "/" +
                (record?.hardware?.tempTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'lzrms'">
              <span>{{
                (record?.hardware?.lzrms || "-") +
                "/" +
                (record?.hardware?.lzrmsTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'lxrms'">
              <span>{{
                (record?.hardware?.lxrms || "-") +
                "/" +
                (record?.hardware?.lxrmsTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'lyrms'">
              <span>{{
                (record?.hardware?.lyrms || "-") +
                "/" +
                (record?.hardware?.lyrmsTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'szrms'">
              <span>{{
                (record?.hardware?.szrms || "-") +
                "/" +
                (record?.hardware?.szrmsTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'sxrms'">
              <span>{{
                (record?.hardware?.sxrms || "-") +
                "/" +
                (record?.hardware?.sxrmsTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'syrms'">
              <span>{{
                (record?.hardware?.syrms || "-") +
                "/" +
                (record?.hardware?.syrmsTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'tw'">
              <span>{{
                (record?.hardware?.tw || "-") +
                "/" +
                (record?.hardware?.twTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'hz'">
              <span>{{
                (record?.hardware?.hz || "-") +
                "/" +
                (record?.hardware?.hzTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'lz'">
              <span>{{
                (record?.hardware?.lz || "-") +
                "/" +
                (record?.hardware?.lzTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'lx'">
              <span>{{
                (record?.hardware?.lx || "-") +
                "/" +
                (record?.hardware?.lxTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'ly'">
              <span>{{
                (record?.hardware?.ly || "-") +
                "/" +
                (record?.hardware?.lyTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'sz'">
              <span>{{
                (record?.hardware?.sz || "-") +
                "/" +
                (record?.hardware?.szTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'sx'">
              <span>{{
                (record?.hardware?.sx || "-") +
                "/" +
                (record?.hardware?.sxTotal || "-")
              }}</span>
            </template>

            <template v-if="column.dataIndex === 'sy'">
              <span>{{
                (record?.hardware?.sy || "-") +
                "/" +
                (record?.hardware?.syTotal || "-")
              }}</span>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </a-config-provider>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

import request from "../../common/request";
import { langList } from "../../common/lang";
import {
  getGroupName,
  transformDate2,
  transformDate,
  getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";

import { Dayjs } from "dayjs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import { getLanguage } from "../../common/translate";

let date: any = ref(null);

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
});
let groupList: any = ref([]);
let machineList: any = ref([]);
let data = ref([]);

const sensorColumns = [
  {
    title: getLanguage("设备区域", language.value),
    dataIndex: "groupId",
  },
  {
    title: langObj.value["所属设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["测点位置"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["传感器SN"],
    dataIndex: "sensorSn",
  },
  {
    title: langObj.value["传感器电量"],
    dataIndex: "hardware",
  },
  {
    title: langObj.value["高频加速度"],
    dataIndex: "hrms",
  },
  {
    title: langObj.value["温度"],
    dataIndex: "temp",
  },
  {
    title: langObj.value["低频加速度Z"],
    dataIndex: "lzrms",
  },
  {
    title: langObj.value["低频加速度X"],
    dataIndex: "lxrms",
  },
  {
    title: langObj.value["低频加速度Y"],
    dataIndex: "lyrms",
  },
  {
    title: langObj.value["速度Z"],
    dataIndex: "szrms",
  },
  {
    title: langObj.value["速度X"],
    dataIndex: "sxrms",
  },
  {
    title: langObj.value["速度Y"],
    dataIndex: "syrms",
  },
  {
    title: getLanguage("高加长波形Z", language.value),
    dataIndex: "tw",
  },
  {
    title: getLanguage("高加波形Z", language.value),
    dataIndex: "hz",
  },
  {
    title: getLanguage("低加波形Z", language.value),
    dataIndex: "lz",
  },
  {
    title: getLanguage("低加波形X", language.value),
    dataIndex: "lx",
  },
  {
    title: getLanguage("低加波形Y", language.value),
    dataIndex: "ly",
  },
  {
    title: getLanguage("速度波形Z", language.value),
    dataIndex: "sz",
  },
  {
    title: getLanguage("速度波形X", language.value),
    dataIndex: "sz",
  },
  {
    title: getLanguage("速度波形Y", language.value),
    dataIndex: "sy",
  },
];

onMounted(() => {
  getGroupList();
  searchSensor();
});

// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

const getSensorList = async () => {
  data.value = [];
  let begin: any = "";
  let end: any = "";
  if (date.value && date.value.length === 2) {
    begin = transformDate(date.value[0]);
    end = transformDate(date.value[1]);
  } else {
    end = transformDate(new Date());
    begin = transformDate(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 1)
    );
  }
  let config: any = {
    params: {
      factoryId: localStorage.getItem("factory_id"),
      end,
      begin,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors/statistics", config);

  if (result && result.status === 200) {
    data.value = result.data;
  }
};

// 获取测点列表
const searchSensor = () => {
  if (!groupList.value?.length) {
    return;
  }
  searchModel.value.groupId = groupList.value[0].id;
  selectGroup(searchModel.value.groupId);
  if (!machineList.value?.length) {
    return;
  }
  searchModel.value.machineId = machineList.value[0].id;
  getSensorList();
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const exportSensorList = () => {
  const tabledata: any = JSON.parse(JSON.stringify(data.value));
  exportToExcel(tabledata, "测点数据统计" + new Date().getTime());
};

const exportToExcel = (data: any, fileName: any) => {
  data = data.map((t: any) => ({
    '设备区域': t.machine?.groupName,
    '所属设备': t.machine?.machineName,
    '测点位置': t.sensorPlace,
    '传感器SN': t.sensorSn,
    '传感器电量':t.hardware?.vol || t?.hardware?.vol == 0 ? t.hardware.vol + " %" : "",
    '高频加速度': (t.hardware.hrms||'-')+'/'+(t.hardware.hrmsTotal||'-'),
    '温度': (t.hardware.temp||'-')+'/'+(t.hardware.tempTotal||'-'),
    '低频加速度Z': (t.hardware.lzrms||'-')+'/'+(t.hardware.lzrmsTotal||'-'),
    '低频加速度X': (t.hardware.lxrms||'-')+'/'+(t.hardware.lxrmsTotal||'-'),
    '低频加速度Y': (t.hardware.lyrms||'-')+'/'+(t.hardware.lyrmsTotal||'-'),
    '速度Z': (t.hardware.szrms||'-')+'/'+(t.hardware.szrmsTotal||'-'),
    '速度X':(t.hardware.sxrms||'-')+'/'+(t.hardware.sxrmsTotal||'-'),
    '速度Y': (t.hardware.syrms||'-')+'/'+(t.hardware.syrmsTotal||'-'),
    '高加长波形Z': (t.hardware.tw||'-')+'/'+(t.hardware.twTotal||'-'),
    '高加波形Z': (t.hardware.hz||'-')+'/'+(t.hardware.hzTotal||'-'),
    '低加波形Z': (t.hardware.lz||'-')+'/'+(t.hardware.lzTotal||'-'),
    '低加波形X': (t.hardware.lx||'-')+'/'+(t.hardware.lxTotal||'-'),
    '低加波形Y': (t.hardware.ly||'-')+'/'+(t.hardware.lyTotal||'-'),
    '速度波形Z':(t.hardware.sz||'-')+'/'+(t.hardware.szTotal||'-'),
    '速度波形X': (t.hardware.sx||'-')+'/'+(t.hardware.sxTotal||'-'),
    '速度波形Y':(t.hardware.sy||'-')+'/'+(t.hardware.syTotal||'-'),
  }));

  const worksheet = XLSX.utils.json_to_sheet(data);

  //worksheet['!merges'] =merges
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const dataBlob = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(dataBlob, fileName + ".xlsx");
};
</script>
<style scoped>
.bule_list_page {
  height: calc(100vh - 100px) !important;
}
</style>
