import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, vModelText as _vModelText } from "vue"

const _hoisted_1 = {
  id: "pdfCompany",
  class: "bule_list_page_RI p-3 theme-common",
  style: {"color":"#fff","min-height":"90vh"}
}
const _hoisted_2 = { class: "flex items-center my-2" }
const _hoisted_3 = { class: "pr-4" }
const _hoisted_4 = { class: "px-5 py-5 rounded-md flex items-center justify-between" }
const _hoisted_5 = { class: "text-xl pr-2" }
const _hoisted_6 = { class: "text-xl flex items-center space-x-2 text-gray-500 print:sr-only" }
const _hoisted_7 = { class: "my-2 flex items-center flex-wrap space-x-0 md:flex-nowrap md:space-x-2 fff" }
const _hoisted_8 = { class: "rounded-md p-3 my-2 box w-1/2 md:w-1/3 print:1/3" }
const _hoisted_9 = {
  id: "chartA",
  class: "w-full h-72"
}
const _hoisted_10 = { class: "rounded-md p-3 my-2 box w-1/2 md:w-1/3 print:1/3" }
const _hoisted_11 = {
  id: "chartB",
  class: "w-full h-72"
}
const _hoisted_12 = { class: "rounded-md p-3 my-2 box w-full md:w-1/3 print:1/3" }
const _hoisted_13 = {
  id: "chartC",
  class: "w-full h-72"
}
const _hoisted_14 = { class: "rounded-md p-3 box" }
const _hoisted_15 = { class: "flex items-center mb-2" }
const _hoisted_16 = { class: "my-2 fff" }
const _hoisted_17 = {
  key: 0,
  class: "ml-12 flex items-center"
}
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "rounded-md border shadow" }
const _hoisted_20 = { class: "rounded-sm" }
const _hoisted_21 = { class: "flex items-center" }
const _hoisted_22 = { class: "flex items-center" }
const _hoisted_23 = ["onClick"]
const _hoisted_24 = {
  key: 0,
  class: "greenpoint"
}
const _hoisted_25 = { key: 1 }
const _hoisted_26 = {
  key: 2,
  class: "redpoint"
}
const _hoisted_27 = { key: 3 }
const _hoisted_28 = {
  key: 0,
  class: "redpoint"
}
const _hoisted_29 = { key: 1 }
const _hoisted_30 = {
  key: 2,
  class: "greenpoint"
}
const _hoisted_31 = { key: 3 }
const _hoisted_32 = {
  key: 0,
  class: "redpoint"
}
const _hoisted_33 = { key: 1 }
const _hoisted_34 = {
  key: 2,
  class: "greenpoint"
}
const _hoisted_35 = { key: 3 }
const _hoisted_36 = { key: 0 }
const _hoisted_37 = {
  key: 0,
  style: {"color":"#921e37"}
}
const _hoisted_38 = {
  key: 1,
  style: {"color":"#cd5f3b"}
}
const _hoisted_39 = {
  key: 2,
  style: {"color":"#d2de49"}
}
const _hoisted_40 = {
  key: 3,
  style: {"color":"#61c08f"}
}
const _hoisted_41 = { key: 4 }
const _hoisted_42 = { key: 1 }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = { key: 1 }
const _hoisted_45 = { key: 0 }
const _hoisted_46 = { key: 0 }
const _hoisted_47 = { class: "flex items-center w-full print:flex-wrap" }
const _hoisted_48 = { class: "w-36 print:w-full" }
const _hoisted_49 = ["title"]
const _hoisted_50 = ["title"]
const _hoisted_51 = ["title"]
const _hoisted_52 = ["title"]
const _hoisted_53 = ["title"]
const _hoisted_54 = { key: 1 }
const _hoisted_55 = { class: "flex items-center w-full flex-wrap" }
const _hoisted_56 = { class: "w-1/2" }
const _hoisted_57 = { class: "w-1/2" }
const _hoisted_58 = { class: "mt-2 w-full" }
const _hoisted_59 = ["onUpdate:modelValue"]
const _hoisted_60 = { class: "px-2" }
const _hoisted_61 = { class: "mt-3" }
const _hoisted_62 = ["onClick"]
const _hoisted_63 = { key: 0 }
const _hoisted_64 = { key: 1 }
const _hoisted_65 = { key: 0 }
const _hoisted_66 = { key: 1 }
const _hoisted_67 = { key: 2 }
const _hoisted_68 = { key: 0 }
const _hoisted_69 = {
  key: 1,
  hidden: ""
}
const _hoisted_70 = { key: 0 }
const _hoisted_71 = { key: 1 }
const _hoisted_72 = { key: 0 }
const _hoisted_73 = { key: 1 }
const _hoisted_74 = { key: 2 }
const _hoisted_75 = { key: 0 }
const _hoisted_76 = {
  key: 1,
  hidden: ""
}
const _hoisted_77 = {
  class: "w-full flex items-center",
  style: {"color":"#fff","font-size":"16px"}
}
const _hoisted_78 = {
  class: "ml-2",
  id: "pc"
}
const _hoisted_79 = {
  class: "w-full flex items-center",
  style: {"color":"#fff","font-size":"16px"}
}
const _hoisted_80 = {
  class: "ml-2",
  id: "mb"
}
const _hoisted_81 = { class: "text-white theme-font-black-color" }

import request from "../../common/request";
import { transformDate } from "../../common/tools";
import { useRouter, useRoute } from "vue-router";
import { onMounted } from "vue";
import { ref } from "vue";
import { message } from 'ant-design-vue';
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { langList } from "../../common/lang";
import { getGroupName } from "../../common/tools";
import htmlToPdf from '../../common/htmlToPdf';

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  children?: Option[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MWReportInfo',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang();

const route = useRoute()
let reportInfo: any = ref({})
let chartData: any = ref([])
let edit = ref(false)
let weekReports: any = ref([])
let statusList: any = ref([  //设备状态
  { label: langObj.value['故障'], value: '故障' },
  { label: langObj.value['警戒'], value: '警戒' },
  { label: langObj.value['可用'], value: '可用' },
  { label: langObj.value['健康'], value: '健康' },
  { label: langObj.value['短暂开机'], value: '短暂开机' },
  { label: langObj.value['未开机'], value: '未开机' },
  { label: langObj.value['传输异常'], value: '传输异常' },
])
let markList: any = ref([    //变化趋势
  { label: langObj.value['明显发展'], value: '明显发展' },
  { label: langObj.value['发展'], value: '发展' },
  { label: langObj.value['轻微发展'], value: '轻微发展' },
  { label: langObj.value['异常特征减弱'], value: '异常特征减弱' },
  { label: langObj.value['有变化'], value: '有变化' },
  { label: langObj.value['无明显变化'], value: '无明显变化' },
  { label: langObj.value['恢复健康'], value: '恢复健康' },
])
let gradeList = [
  { value: -1, label: '健康' },
  { value: 0, label: '可用' },
  { value: 1, label: '警戒' },
  { value: 2, label: '故障' },
];
let cascaderOptions: any = ref([])

const filter = (inputValue: string, path: Option[]) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

let tableDataSave: any = ref([])
let tableData: any = ref([])
let isShowFaultCause = ref(false)
let isShowFaultSuggest = ref(false)

let faultInfoList: any = []

let reportType: any = ref(3)

let columns = [
  {
    title: langObj.value['设备名称'],
    dataIndex: 'machineName',
    slots: { customRender: 'machineName' },
    customCell: (_: any, index: any) => ({ rowspan: _.span, }),
    align: "center"
  },
  {
    title: langObj.value['设备区域'],
    dataIndex: 'groupName',
    sorter: true,
    customCell: (_: any, index: any) => ({ rowspan: _.span, }),
    align: "center"
  },
  {
    title: langObj.value['设备状态'],
    dataIndex: 'status',
    sorter: true,
    customCell: (_: any) => ({ rowspan: _.span, }),
    slots: { customRender: 'status' },
    align: "center"
  },
  {
    title: langObj.value['状态变化'],
    dataIndex: 'mark',
    sorter: true,
    slots: { customRender: 'mark' },
    customCell: (_: any) => ({ rowspan: _.span, }),
    align: "center"
  },
    /*
  {
    title: langObj.value['网关状态'] || '网关状态',
    dataIndex: 'isGatewayOnline',
    sorter: true,
    slots: { customRender: 'isGatewayOnline' },
    customCell: (_: any) => ({ rowspan: _.span, }),
    align: "center"
  },
     */
    /*
  {
    title: langObj.value['电量'] || '电量',
    dataIndex: 'isLowBattery',
    sorter: true,
    slots: { customRender: 'isLowBattery' },
    customCell: (_: any) => ({ rowspan: _.span, }),
    align: "center"
  },
  {
    title: langObj.value['数据延迟'] || '数据延迟',
    dataIndex: 'isDataDelay',
    sorter: true,
    slots: { customRender: 'isDataDelay' },
    customCell: (_: any) => ({ rowspan: _.span, }),
    align: "center"
  },
     */
  {
    title: langObj.value['诊断结果'],
    dataIndex: 'diagnosisDetails',
    slots: { customRender: 'diagnosisDetails' },
    align: "center"
  },
  {
    width: '200px',
    dataIndex: 'faultCause',
    slots: { title: 'faultCauseTitle', customRender: 'faultCause' },
    align: "center"
  },
  {
    width: '200px',
    dataIndex: 'faultSuggest',
    slots: { title: 'faultSuggestTitle', customRender: 'faultSuggest' },
    align: "center"
  },
  {
    title: reportType.value === 3 ? langObj.value['本周检修/检查'] : langObj.value['本月检修/检查'],
    dataIndex: 'repairList',
    slots: { customRender: 'repairList' },
    align: "center"
  }
]

let chartShow = ref(false)

let startTime: any = ref('')
let endTime: any = ref('')
let diagnosticManagerList: any = ref([])
let diagnosticManager: any = ref('')

let lockInterval: any = null
let lockId: string | null;
let lockError = ref<string>("");
// 修改报告
const handleEdit = async () => {
  if (edit.value === true) {
    return;
  }

  if (lockInterval) {
    clearInterval(lockInterval);
  }
  // 请求锁定
  let reportId = route.params.id;
  lockId = sessionStorage.getItem("reportLockId");
  if (!lockId) {
    lockId = uuidv4();
    sessionStorage.setItem("reportLockId", lockId);
  }

  let lockRes: any = await request.post('/api/reports/lock/acquire?__ignoreError=true', {
    reportId: reportId,
    lockId: lockId
  })
  if (!lockRes || lockRes.status !== 200) {
    showLockModal.value = true;
    lockError = lockRes?.message || "该报告已被其他客户端锁定，请稍候再试";
    return;
  }

  lockInterval = setInterval(async () => {
    // 定时刷新锁，被其他客户端强占时，会自动弹出message
    let lockRes: any = await request.post('/api/reports/lock/acquire?__ignoreError=true', {
      reportId: reportId,
      lockId: lockId
    })
    if (!lockRes || lockRes.status !== 200) {
      showLockModal.value = true;
      if (lockInterval) clearInterval(lockInterval);
    }
  }, 1000 * 20)

  // 重新获取一次报告，检查是否被其他客户端更新过
  let infoRes: any = await request.get("/api/reports/info?id=" + route.params.id);
  if (infoRes && infoRes.status === 200) {
    if (reportInfo.value.version &&
      infoRes.data.version &&
      reportInfo.value.version !== infoRes.data.version) {
      reportInfo.value = infoRes.data
      await dealWithData()
    }
  }

  diagnosticManager.value = ''
  edit.value = true
}

let showLockModal = ref<boolean>(false);
const releaseLock = async (force: boolean = false) => {
  let reportId = route.params.id;
  if (lockInterval) {
    clearInterval(lockInterval)
    lockInterval = null
  }
  await request.post('/api/reports/lock/release', { reportId: reportId, lockId: lockId, force: force })
}

const forceAcquireLock = async () => {
  showLockModal.value = false;
  await releaseLock(true);
  handleEdit()
}

// 取消
const handleCancle = () => {
  edit.value = false
  releaseLock().catch(_ => { })
  dealWithData()
}

// 保存
const handleSave = async () => {
  let machineIds = tableDataSave.value.map((p: any) => p.machineId)
  machineIds = [...new Set(machineIds)]
  let weekReport: any = []
  machineIds.map((machineId: any) => {
    let info = tableDataSave.value.find((p: any) => p.machineId === machineId)
    let list = tableDataSave.value.filter((p: any) => p.machineId === machineId)
    let diagnosisDetails: any = []
    list && list.length && list.map((d: any) => {
      if (Array.isArray(d.faultEvent)) {
        d.faultEvent = d.faultEvent.pop()
      }
      diagnosisDetails.push({
        diagnoseMessage: d.diagnoseMessage,
        faultCause: d.faultCause,
        faultEvent: d.faultEvent,
        faultSuggest: d.faultSuggest,
        grade: d.grade,
      })
    })

    weekReport.push({
      groupId: info.groupId, //
      groupName: info.groupName,
      machineId: info.machineId,
      machineName: info.machineName,
      mark: info.mark,
      markSort: markList.value.findIndex((ele: any) => ele.label === info.mark),
      status: info.status,
      statusSort: statusList.value.findIndex((ele: any) => ele.label === info.status),
      diagnosisDetails,
    })

  })

  weekReport.map((d: any, index: any) => {
    let oldInfo = weekReports.value.find((p: any) => p.machineId === d.machineId)

    let isChanged = false
    if (d.status !== oldInfo.status) {
      isChanged = true
    }
    if (d.mark !== oldInfo.mark) {
      isChanged = true
    }

    let newList = d.diagnosisDetails.map((p: any) => ({
      diagnoseMessage: p.diagnoseMessage,
      faultCause: p.faultCause,
      faultEvent: p.faultEvent,
      faultSuggest: p.faultSuggest,
      grade: p.grade,
    }))

    let oldList = oldInfo.diagnosisDetails?.map((p: any) => ({
      diagnoseMessage: p.diagnoseMessage,
      faultCause: p.faultCause,
      faultEvent: p.faultEvent,
      faultSuggest: p.faultSuggest,
      grade: p.grade,
    }))

    if (JSON.stringify(newList) !== JSON.stringify(oldList)) {
      isChanged = true
    }
    d.isChanged = isChanged
  })

  let params = {}

  if (reportInfo.value.type === 3) {
    params = {
      ...reportInfo.value,
      weekReport
    }
  } else {
    params = {
      ...reportInfo.value,
      monthReport: weekReport
    }
  }

  let result = await request.put('/api/reports', params)
  if (result?.data) {
    edit.value = false
    init()
    message.success('操作成功')
    releaseLock().catch(_ => { })
  } else {
    message.warning('操作失败')
  }

}

// 新增
const handleCreate = (index: any) => {
  let data = tableData.value.find((p: any) => p.machineId === tableData.value[index].machineId && p.span)
  data.span++
  let form = {
    ...tableData.value[index],
    diagnoseMessage: "",
    faultCause: "",
    faultEvent: "",
    faultSuggest: "",
    grade: -1,
    span: 0,
    faultSuggestList: [],
    faultCauseList: [],
  }

  tableData.value.splice(index + 1, 0, form)
}

// 删除
const deleteEle = (index: any) => {

  if (tableData.value[index].span) {
    if (tableData.value[index].span > 1) {
      // 下级继承
      tableData.value[index + 1].span = tableData.value[index].span - 1
      tableData.value.splice(index, 1)
    } else {
      if (tableData.value[index].span === 1) {
        tableData.value[index] = {
          ...tableData.value[index],
          diagnoseMessage: null,
          faultCause: null,
          faultEvent: null,
          faultSuggest: null,
          grade: null,
          span: 1,
          faultSuggestList: null,
          faultCauseList: null,
        }
      } else {
        tableData.value.splice(index, 1)
      }
    }
  } else {
    // 上级span-1
    let data = tableData.value.find((p: any) => p.machineId === tableData.value[index].machineId && p.span)
    data.span--
    tableData.value.splice(index, 1)
  }
}

// 设备跳转
const router = useRouter()
const handleMachineName = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
  if (ev.machineId && memberInfo.machines.find((p: any) => p.id === ev.machineId)) {
    localStorage.setItem('machineId', ev.machineId)
    router.push('/diagnosis/intelligent')
  }
}

// 本周设备健康总览绘图
const initChartA = () => {
  const ec = echarts as any;
  if (!document.getElementById('chartA')) return

  let myChart = ec.init(document.getElementById('chartA'));
  myChart.clear()
  if (!chartData.value || !chartData.value.length) {
    myChart.clear()
    return
  }
  const option = {
    color: chartData.value.map((p: any) => p.color),

    tooltip: {
      trigger: 'item',
    },
    legend: {
      bottom: 'center',
      orient: 'vertical',
      left: '5%',
      textStyle: {
        color: theme.value == 'white' ? '#333' : '#fff',
      },
      formatter: function (name: any) {
        return `${langObj.value[name]} ( ${option.series[0].data.find((p: any) => p.name === name)?.value} )`;
      },
    },
    series: [
      {
        name: langObj.value['设备状态'],
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 3,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'normal',
          },
        },
        labelLine: {
          show: false,
        },
        data: chartData.value,
      },
    ],
  };

  myChart.setOption(option);
}

// 本周设备事件提醒总览
const initChartB = (status1: any, status2: any, status3: any) => {
  if (!document.getElementById("chartB")) return
  let myChart = echarts.init(document.getElementById("chartB") as HTMLDivElement);
  let option = {
    grid: {
      top: '4%',
      left: '0%',
      right: '0%',
      bottom: '0%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      show: false,
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: [langObj.value['智能诊断'], langObj.value['门限报警']],
        axisLabel: {
          color: theme.value == 'white' ? '#333' : '#fff',
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        splitLine: {    //网格线
          lineStyle: {
            color: 'rgba(255,255,255,0.5)',
            type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
          },
          show: true //隐藏或显示
        },
      }
    ],
    series: [
      {
        name: langObj.value['可用'],
        type: 'bar',
        barGap: 0,
        emphasis: {
          focus: 'series'
        },
        data: status1,
        itemStyle: {
          color: '#d2de49'
        }
      },
      {
        name: langObj.value['警戒'],
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: status2,
        itemStyle: {
          color: '#cd5f3b'
        }
      },
      {
        name: langObj.value['故障'],
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        data: status3,
        itemStyle: {
          color: '#921e37'
        }
      },
    ]
  };

  myChart && myChart.setOption(option)
}

// 故障模式统计(本年)
const initChartC = () => {
  let data = reportInfo.value.expertFaultModeStatistics || {}
  let chartData: any[] = [];

  Object.keys(data).forEach(ele => {
    let min = {
      value: data[ele],
      name: ele,
    }
    chartData.push(min)
  })

  const ec = echarts as any;
  if (!document.getElementById('chartC')) return

  let myChart = ec.init(document.getElementById('chartC'));
  myChart.clear()
  const option = {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      bottom: 'center',
      orient: 'vertical',
      left: '2%',
      textStyle: {
        color: theme.value == 'white' ? '#333' : '#fff',
      },
      formatter: function (name: any) {
        return `${langObj.value[name] || name}`;
      },
    },
    series: [
      {
        name: langObj.value['故障模式'],
        type: 'pie',
        radius: ['50%', '70%'],
        center: ['65%', '50%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 3,
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'normal'
          }
        },
        labelLine: {
          show: false
        },
        data: chartData
      }
    ]
  };


  myChart.setOption(option)

}


// 处理数据
const dealWithData = async () => {
  const {
    weekReport,
    monthReport,
  } = reportInfo.value;

  columns[7].title = reportInfo.value.type === 3 ? langObj.value['本周检修/检查'] : langObj.value['本月检修/检查']

  let reportList: any = reportInfo.value.type === 3 ? weekReport : monthReport

  let statusList2 = reportList.map((d: any) => d.status);
  let healthList = statusList2.filter((p: any) => p === '健康');
  let availableList = statusList2.filter((p: any) => p === '可用');
  let alertList = statusList2.filter((p: any) => p === '警戒');
  let faultList = statusList2.filter((p: any) => p === '故障');
  let notbootList = statusList2.filter((p: any) => p === '未开机');
  let noScoreList = statusList2.filter((p: any) => p === '传输异常');

  chartData.value = [];
  healthList.length && chartData.value.push({ value: healthList.length, name: '健康', sort: 0, color: '#1CC48B' });
  availableList.length && chartData.value.push({
    value: availableList.length,
    name: '可用',
    sort: 1,
    color: '#CEE008'
  });
  alertList.length && chartData.value.push({ value: alertList.length, name: '警戒', sort: 2, color: '#DF572D' });
  faultList.length && chartData.value.push({ value: faultList.length, name: '故障', sort: 3, color: '#A20335' });
  notbootList.length && chartData.value.push({
    value: notbootList.length,
    name: '未开机',
    sort: 4,
    color: 'rgb(162, 166, 171)'
  });
  noScoreList.length && chartData.value.push({
    value: noScoreList.length,
    name: '传输异常',
    sort: 5,
    color: 'rgb(162, 166, 121)'
  })

  if (reportList && reportList.length > 0) {
    reportList.forEach((element: any, i: any) => {
      element.classIndex = i
      element.markSort = markList.value.findIndex((ele: any) => ele.value === element.mark)
      element.statusSort = statusList.value.findIndex((ele: any) => ele.value == element.status)
      if (element.repair) {
        element.repairList = element.repair.split("|")
      } else {
        element.repairList = []
      }
    });

    weekReports.value = JSON.parse(JSON.stringify(reportList))
  }

  let config = {
    params: {
      factoryId: reportInfo.value.factoryId,
      begin: startTime.value,
      end: endTime.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  }

  let warningResult = await request.get('/api/report/warning', config)
  console.log('warningResult', warningResult)
  let status1 = [0, 0]
  let status2 = [0, 0]
  let status3 = [0, 0]
  if (warningResult?.data) {
    status1 = [warningResult.data.type1[0], warningResult.data.type4[0]]
    status2 = [warningResult.data.type1[1], warningResult.data.type4[1]]
    status3 = [warningResult.data.type1[2], warningResult.data.type4[2]]
  }

  chartShow.value = true
  setTimeout(() => {
    initChartA()
    initChartB(status1, status2, status3)
    initChartC()
  }, 1000);

  let sortTwo = (a: any, b: any) => {
    if (a.statusSort > b.statusSort) {
      return 1
    } else if (a.statusSort === b.statusSort) {
      if (a.markSort > b.markSort) {
        return 1
      } else {
        return -1
      }
    } else {
      return -1
    }
  }

  reportList.sort((a: any, b: any) => { return sortTwo(a, b) })

  // 新表格数据
  tableData.value = []
  reportList && reportList.map((item: any) => {
    if (item.diagnosisDetails && item.diagnosisDetails.length) {
      item.diagnosisDetails.map((son: any, index: any) => {
        tableData.value.push({
          ...son,
          machineName: item.machineName,
          machineId: item.machineId,
          // groupName: item.groupName,
          groupName: getGroupName(item.groupId),
          mark: item.mark,
          status: item.status,
          span: index === 0 ? item.diagnosisDetails.length : 0,
          groupId: item.groupId,
          repairList: item.repairList,
          classIndex: item.classIndex,
          isDataDelay: item.isDataDelay,
          isGatewayOnline: item.isGatewayOnline,
          isLowBattery: item.isLowBattery,
        })
      })
    } else {
      tableData.value.push({
        machineName: item.machineName,
        machineId: item.machineId,
        // groupName: item.groupName,
        groupName: getGroupName(item.groupId),
        mark: item.mark,
        status: item.status,
        span: 1,
        groupId: item.groupId,
        repairList: item.repairList,
        classIndex: item.classIndex,
        isDataDelay: item.isDataDelay,
        isGatewayOnline: item.isGatewayOnline,
        isLowBattery: item.isLowBattery,
      })
    }
  })

  tableData.value.map((d: any) => {
    if (d.faultCause) {
      d.faultCauseList = d.faultCause.replaceAll('\n', '\r\n').split('\r\n').filter((d: any) => d)
    }
    if (d.faultSuggest) {
      d.faultSuggestList = d.faultSuggest.replaceAll('\n', '\r\n').split('\r\n').filter((d: any) => d)
    }
  })

  tableDataSave.value = JSON.parse(JSON.stringify(tableData.value))
  selectManage(null)
}

// 排序
const tableChange = (pagination: any, filters: any, sorter: any) => {
  if (!sorter.order) {
    return
  }

  function compare(property: any, sort: any) {
    return function (a: any, b: any) {
      var value1 = a[property];
      var value2 = b[property];
      if (sort === 'ascend') {
        return value1 >= value2 ? 1 : -1
      } else {
        return value1 <= value2 ? 1 : -1
      }
      // return sort === 'ascend' ? value1 >= value2 : value2 < value1
    }
  }

  // 设备区域
  console.log('sorter.field', sorter.field)
  console.log('sorter.order', sorter.order)
  console.log('tableData.value', tableData.value)
  if (sorter.field === 'groupName') {
    tableData.value.sort(compare(sorter.field, sorter.order))
  }

  // 设备状态
  if (sorter.field === 'status') {
    let machineIds: any = tableData.value.map((p: any) => p.machineId)
    machineIds = [...new Set(machineIds)]
    let machines: any = []
    machineIds.map((d: any) => {
      let list = tableData.value.filter((p: any) => p.machineId === d)
      machines.push({
        list,
        statusSort: statusList.value.findIndex((ele: any) => ele.label === langObj.value[list[0].status])
      })
    })
    machines.sort(compare('statusSort', sorter.order))
    let tableList: any = []
    machines.map((d: any) => {
      tableList = tableList.concat(d.list)
    })

    tableData.value = tableList
  }

  // 状态变化
  if (sorter.field === 'mark') {
    let machineIds: any = tableData.value.map((p: any) => p.machineId)
    machineIds = [...new Set(machineIds)]
    let machines: any = []
    machineIds.map((d: any) => {
      let list = tableData.value.filter((p: any) => p.machineId === d)
      machines.push({
        list,
        markSort: markList.value.findIndex((ele: any) => ele.label === langObj.value[list[0].mark])
      })
    })
    machines.sort(compare('markSort', sorter.order))
    let tableList: any = []
    machines.map((d: any) => {
      tableList = tableList.concat(d.list)
    })

    tableData.value = tableList
  }

}

// 选择诊断经理
const selectManage = async (ev: any) => {
  let list = tableDataSave.value
  if (ev) {
    const config = {
      params: {
        diagnosticManager: ev
      },
      headers: {
        requestId: uuidv4(),
      },
    }
    let result = await request.get('/api/principal/all', config)
    let machineIds: any = []
    if (result?.data?.length) {
      machineIds = result.data.map((p: any) => p.machineId)
    }
    tableData.value = list.filter((p: any) => machineIds.includes(p.machineId))
  } else {
    tableData.value = list
  }
}

// 获取faultInfos
const getFaultInfos = () => {
  cascaderOptions.value = [];
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.post("/api/faultinfos", { FeatureType: '' }, config).then((res: any) => {
    if (res) {
      faultInfoList = res.data
      let list = res.data
      let expects = list.filter((item: { featureType: string; }) => item.featureType === "Expect")
      let commons = list.filter((item: { featureType: string; }) => item.featureType === "Common")
      let others = list.filter((item: { featureType: string; }) => item.featureType === "Other")
      let expectList: any[] = []
      let commonsList: any[] = []
      let othersList: any[] = []
      expects.forEach((item: any) => {
        if (expectList.findIndex((ele: any) => ele.value === item.segmentFault) === -1) {
          expectList.push({
            value: item.segmentFault,
            label: item.segmentFault,
          })
        }
      })

      commons.forEach((item: any) => {
        if (commonsList.findIndex((ele: any) => ele.value === item.segmentFault) === -1) {
          commonsList.push({
            value: item.segmentFault,
            label: item.segmentFault,
          })
        }
      })

      others.forEach((item: any) => {
        if (othersList.findIndex((ele: any) => ele.value === item.segmentFault) === -1) {
          othersList.push({
            value: item.segmentFault,
            label: item.segmentFault,
          })
        }
      })

      cascaderOptions.value = [
        {
          value: '专业指标',
          label: langObj.value['专业指标'],
          children: expectList,
        },
        {
          value: '振动指标',
          label: langObj.value['振动指标'],
          children: commonsList,
        },
        {
          value: '其他',
          label: langObj.value['其他'],
          children: othersList,
        },
      ];
    }
  });
}

// 选择fault
const selectFault = (ev: any, index: any) => {
  if (ev && ev.length === 2) {
    let faultInfo = faultInfoList.find((p: any) => p.segmentFault === ev[1])

    if (faultInfo.faultCause) {
      tableData.value[index].faultCause = faultInfo.faultCause;
      tableData.value[index].faultCauseList = faultInfo.faultCause.split('\r\n').filter((d: any) => d)
    } else {
      tableData.value[index].faultCause = ''
      tableData.value[index].faultCauseList = []
    }

    if (faultInfo.faultSuggest) {
      tableData.value[index].faultSuggest = faultInfo.faultSuggest;
      tableData.value[index].faultSuggestList = faultInfo.faultSuggest.split('\r\n').filter((d: any) => d)
    } else {
      tableData.value[index].faultSuggest = ''
      tableData.value[index].faultSuggestList = []
    }

  }
}


let visible = ref(false)
let urlpc: any = ref('')
let urlmb: any = ref('')
const share = () => {
  urlpc.value = location.host + '/#/week-report/' + route.params.id + '?lang=' + language.value
  urlmb.value = location.host + '/#/week-report-mobile/' + route.params.id + '?lang=' + language.value
  visible.value = true
}

//复制功能
const copyUrl = (rf: any) => {
  let node = document.getElementById(rf)
  if (window.getSelection() && node) {
    window.getSelection()?.selectAllChildren(node);
  }
  let copy = document.execCommand("Copy");
  if (copy) {
    document.execCommand("Copy");
    message.success('复制完成')
  } else {
    message.warning('由于浏览器版本问题，复制失败，请手动复制~')
  }
}

let isEditable: any = ref(false)
const init = () => {
  chartShow.value = false
  getFaultInfos()
  const config = {
    params: {
      id: route.params.id
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  reportInfo.value = {}
  request.get("/api/reports/info", config).then((res) => {
    if (res && res.status === 200) {
      // 演示账号
      isEditable.value = true


      reportInfo.value = res.data
      reportType.value = res.data.type
      endTime.value = transformDate(reportInfo.value.date)
      let num = reportType.value === 3 ? 7 : 30
      startTime.value = transformDate(new Date(endTime.value).getTime() - (1000 * 60 * 60 * 24 * num))

      dealWithData()
    }
  });
}

onMounted(() => {
  init()
  getManagers()
})

const getReportTitle = (title: any) => {
  if (!title) {
    title = ''
  }
  if (language.value == 'English') {
    return title.replace('周报', 'weekly report')
  } else {
    return title
  }
}


// 获取诊断经理列表
const getManagers = async () => {
  const config = {
    params: {
      factoryId: "65e29561f747d7e32bbed049",
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultRole = await request.get("/api/roles", config)
  let rolelist: any = []
  if (resultRole?.data?.list) {
    rolelist = resultRole.data.list
  }
  let diagnosticManagerRoleId = rolelist.find((p: any) => p.name === '诊断工程师')?.id

  if (diagnosticManagerRoleId) {
    let config2 = {
      params: {
        roleId: diagnosticManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      diagnosticManagerList.value = result2.data.list
    }
  }

  console.log('diagnosticManagerList', diagnosticManagerList.value)
}

// 下载pdf
let loadingDown = ref(false)
const handleDown = async () => {
  loadingDown.value = true;	// 动画加载事件
  // 调用htmlToPdf工具函数
  let result = await htmlToPdf.getPdf('周报', 'a1');
  console.log('result', result)
  loadingDown.value = false
  if (result) {
    message.success('下载成功')
  } else {
    message.warning('下载失败，请重试')
  }

}


const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return record.classIndex % 2 === 0 ? "even-row" : "odd-row";
};

// 返回报告列表
const goBack = () => {
  localStorage.setItem('report_type', '3')
  router.back()
}



return (_ctx: any,_cache: any) => {
  const _component_RollbackOutlined = _resolveComponent("RollbackOutlined")!
  const _component_ShareAltOutlined = _resolveComponent("ShareAltOutlined")!
  const _component_EditOutlined = _resolveComponent("EditOutlined")!
  const _component_download_outlined = _resolveComponent("download-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_CopyOutlined = _resolveComponent("CopyOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_spin, {
      size: "large",
      spinning: _unref(loadingDown)
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_RollbackOutlined, {
              onClick: goBack,
              class: "ml-5 cursor-pointer",
              style: {"font-size":"18px"}
            }),
            _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(langObj)['报告统计时间']) + ": " + _toDisplayString(_unref(startTime)) + " -- " + _toDisplayString(_unref(endTime)), 1),
            _createElementVNode("span", null, _toDisplayString(_unref(langObj)['报告日期']) + ": " + _toDisplayString(_unref(endTime)), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(getReportTitle(_unref(reportInfo).title)), 1),
              _createElementVNode("span", null, _toDisplayString(_unref(endTime)), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_unref(isEditable))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (share())),
                    "nz-button": "",
                    nzType: "text"
                  }, [
                    _createVNode(_component_ShareAltOutlined, { class: "cursor-pointer" })
                  ]))
                : _createCommentVNode("", true),
              (_unref(isEditable))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (handleEdit())),
                    "nz-button": "",
                    nzType: "text",
                    class: "ml-2"
                  }, [
                    _createVNode(_component_EditOutlined, { class: "cursor-pointer" })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                onClick: _cache[2] || (_cache[2] = ($event: any) => (handleDown())),
                "nz-button": "",
                nzType: "text",
                class: "ml-2"
              }, [
                _createVNode(_component_download_outlined)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, _toDisplayString(_unref(reportType) === 3 ? _unref(langObj)['本周设备健康总览'] :
              _unref(langObj)['本月设备健康总览']), 1),
              _withDirectives(_createElementVNode("div", _hoisted_9, null, 512), [
                [_vShow, _unref(chartShow)]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", null, _toDisplayString(_unref(reportType) === 3 ? _unref(langObj)['本周设备事件提醒总览'] :
              _unref(langObj)['本月设备事件提醒总览']), 1),
              _withDirectives(_createElementVNode("div", _hoisted_11, null, 512), [
                [_vShow, _unref(chartShow)]
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", null, _toDisplayString(_unref(langObj)['故障模式统计(本年)']), 1),
              _withDirectives(_createElementVNode("div", _hoisted_13, null, 512), [
                [_vShow, _unref(chartShow)]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("h4", _hoisted_16, _toDisplayString(_unref(langObj)['设备详情清单']), 1),
              (_unref(edit))
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "mr-2" }, "诊断经理：", -1)),
                    _createVNode(_component_a_select, {
                      onChange: selectManage,
                      value: _unref(diagnosticManager),
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_isRef(diagnosticManager) ? (diagnosticManager).value = $event : diagnosticManager = $event)),
                      allowClear: "",
                      style: {"width":"200px"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(diagnosticManagerList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item._id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.userName), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]))
                : _createCommentVNode("", true),
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              (_unref(edit))
                ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createElementVNode("button", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (handleCancle())),
                      class: "mr-4 bg-gray-200 rounded-sm px-4 py-1 cursor-pointer hover:bg-opacity-50"
                    }, _toDisplayString(_unref(langObj)['取消']), 1),
                    _createElementVNode("button", {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (handleSave())),
                      class: "mr-1 bg-blue-500 text-gray-50 rounded-sm px-4 py-1 cursor-pointer hover:bg-opacity-60"
                    }, _toDisplayString(_unref(langObj)['保存']), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_a_table, {
                  dataSource: _unref(tableData),
                  columns: _unref(columns),
                  pagination: false,
                  onChange: tableChange,
                  size: "small",
                  "row-class-name": getRowClassName
                }, {
                  faultCauseTitle: _withCtx(() => [
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("span", null, _toDisplayString(_unref(langObj)['故障原因']), 1),
                      _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      (!_unref(isShowFaultCause) && !_unref(edit))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(isShowFaultCause) //@ts-ignore
 ? isShowFaultCause.value = true : isShowFaultCause = true))
                          }, _toDisplayString(_unref(langObj)['展开']), 1))
                        : _createCommentVNode("", true),
                      (_unref(isShowFaultCause) && !_unref(edit))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_isRef(isShowFaultCause) //@ts-ignore
 ? isShowFaultCause.value = false : isShowFaultCause = false))
                          }, _toDisplayString(_unref(langObj)['隐藏']), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  faultSuggestTitle: _withCtx(() => [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("span", null, _toDisplayString(_unref(langObj)['建议及措施']), 1),
                      _cache[20] || (_cache[20] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      (!_unref(isShowFaultSuggest) && !_unref(edit))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            onClick: _cache[8] || (_cache[8] = ($event: any) => (_isRef(isShowFaultSuggest) //@ts-ignore
 ? isShowFaultSuggest.value = true : isShowFaultSuggest = true))
                          }, _toDisplayString(_unref(langObj)['展开']), 1))
                        : _createCommentVNode("", true),
                      (_unref(isShowFaultSuggest) && !_unref(edit))
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            onClick: _cache[9] || (_cache[9] = ($event: any) => (_isRef(isShowFaultSuggest) //@ts-ignore
 ? isShowFaultSuggest.value = false : isShowFaultSuggest = false))
                          }, _toDisplayString(_unref(langObj)['隐藏']), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  machineName: _withCtx(({ text, record }) => [
                    _createElementVNode("a", {
                      onClick: ($event: any) => (handleMachineName(record))
                    }, _toDisplayString(text), 9, _hoisted_23)
                  ]),
                  isGatewayOnline: _withCtx(({ text }) => [
                    (text === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24))
                      : _createCommentVNode("", true),
                    (text === true)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(_unref(langObj)['在线'] || '在线'), 1))
                      : _createCommentVNode("", true),
                    (text === false)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_26))
                      : _createCommentVNode("", true),
                    (text === false)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_unref(langObj)['离线'] || '离线'), 1))
                      : _createCommentVNode("", true)
                  ]),
                  isLowBattery: _withCtx(({ text }) => [
                    (text === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_28))
                      : _createCommentVNode("", true),
                    (text === true)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_unref(langObj)['低'] || '低'), 1))
                      : _createCommentVNode("", true),
                    (text === false)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_30))
                      : _createCommentVNode("", true),
                    (text === false)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_unref(langObj)['正常'] || '正常'), 1))
                      : _createCommentVNode("", true)
                  ]),
                  isDataDelay: _withCtx(({ text }) => [
                    (text === true)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32))
                      : _createCommentVNode("", true),
                    (text === true)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_unref(langObj)['是'] || '是'), 1))
                      : _createCommentVNode("", true),
                    (text === false)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_34))
                      : _createCommentVNode("", true),
                    (text === false)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_unref(langObj)['否'] || '否'), 1))
                      : _createCommentVNode("", true)
                  ]),
                  status: _withCtx(({ text, record }) => [
                    (!_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                          (text === '故障')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_unref(langObj)[text]), 1))
                            : (text === '警戒')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_unref(langObj)[text]), 1))
                              : (text === '可用')
                                ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(_unref(langObj)[text]), 1))
                                : (text === '健康')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(_unref(langObj)[text]), 1))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_41, _toDisplayString(_unref(langObj)[text]), 1))
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                          _createVNode(_component_a_select, {
                            class: "p-2 rounded-md border border-gray-200 w-full focus:outline-none",
                            value: record.status,
                            "onUpdate:value": ($event: any) => ((record.status) = $event),
                            options: _unref(statusList)
                          }, null, 8, ["value", "onUpdate:value", "options"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  mark: _withCtx(({ text, record }) => [
                    (!_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                          _createElementVNode("span", null, _toDisplayString(_unref(langObj)[text]), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                          (_unref(edit))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
                                _createVNode(_component_a_select, {
                                  class: "p-2 rounded-md border border-gray-200 w-full focus:outline-none",
                                  value: record.mark,
                                  "onUpdate:value": ($event: any) => ((record.mark) = $event),
                                  options: _unref(markList)
                                }, null, 8, ["value", "onUpdate:value", "options"])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  diagnosisDetails: _withCtx(({ text, record, index }) => [
                    (!_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                          _createElementVNode("div", _hoisted_47, [
                            _createElementVNode("div", _hoisted_48, [
                              (record.grade === -1)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    title: _unref(langObj)[record.faultEvent] || record.faultEvent,
                                    class: "px-2 py-1 rounded-sm w-full",
                                    style: {"color":"#61c08f","border":"1px solid #61c08f"}
                                  }, _toDisplayString(_unref(langObj)[record.faultEvent] || record.faultEvent), 9, _hoisted_49))
                                : _createCommentVNode("", true),
                              (record.grade === 0)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    title: _unref(langObj)[record.faultEvent] || record.faultEvent,
                                    class: "px-2 py-1 rounded-sm w-full",
                                    style: {"color":"#CEE008","border":"1px solid #CEE008"}
                                  }, _toDisplayString(_unref(langObj)[record.faultEvent] || record.faultEvent), 9, _hoisted_50))
                                : _createCommentVNode("", true),
                              (record.grade === 1)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 2,
                                    title: _unref(langObj)[record.faultEvent] || record.faultEvent,
                                    class: "px-2 py-1 rounded-sm w-full",
                                    style: {"color":"#DF572D","border":"1px solid #DF572D"}
                                  }, _toDisplayString(_unref(langObj)[record.faultEvent] || record.faultEvent), 9, _hoisted_51))
                                : _createCommentVNode("", true),
                              (record.grade === 2)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 3,
                                    title: _unref(langObj)[record.faultEvent] || record.faultEvent,
                                    class: "px-2 py-1 rounded-sm w-full",
                                    style: {"color":"#A20335","border":"1px solid #A20335"}
                                  }, _toDisplayString(_unref(langObj)[record.faultEvent] || record.faultEvent), 9, _hoisted_52))
                                : _createCommentVNode("", true)
                            ]),
                            _createElementVNode("div", {
                              title: record.diagnoseMessage,
                              class: "flex-1 px-2 print:py-2"
                            }, _toDisplayString(record.diagnoseMessage), 9, _hoisted_53)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                          _createElementVNode("div", _hoisted_55, [
                            _createElementVNode("div", _hoisted_56, [
                              _createVNode(_component_a_cascader, {
                                class: "w-full",
                                value: record.faultEvent,
                                "onUpdate:value": ($event: any) => ((record.faultEvent) = $event),
                                options: _unref(cascaderOptions),
                                "show-search": { filter: filter },
                                onChange: ($event: any) => (selectFault($event, index)),
                                allowClear: false
                              }, null, 8, ["value", "onUpdate:value", "options", "show-search", "onChange"])
                            ]),
                            _createElementVNode("div", _hoisted_57, [
                              _createVNode(_component_a_select, {
                                placeholder: _unref(langObj)['严重程度'],
                                value: record.grade,
                                "onUpdate:value": ($event: any) => ((record.grade) = $event),
                                class: "p-2 rounded-md border border-gray-200 w-full focus:outline-none"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(gradeList), (grade, index) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: index,
                                      value: grade.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_unref(langObj)[grade.label]), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1032, ["placeholder", "value", "onUpdate:value"])
                            ]),
                            _createElementVNode("div", _hoisted_58, [
                              _withDirectives(_createElementVNode("textarea", {
                                style: {"width":"100%","background-color":"#04165D","color":"#fff","padding":"5px","border":"1px solid rgba(13, 83, 183, 0.8)","min-height":"80px"},
                                "onUpdate:modelValue": ($event: any) => ((record.diagnoseMessage) = $event)
                              }, null, 8, _hoisted_59), [
                                [_vModelText, record.diagnoseMessage]
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_60, [
                            _createVNode(_component_CloseCircleOutlined, {
                              onClick: ($event: any) => (deleteEle(index)),
                              class: "cursor-pointer"
                            }, null, 8, ["onClick"])
                          ]),
                          _createElementVNode("div", _hoisted_61, [
                            _createElementVNode("button", {
                              onClick: ($event: any) => (handleCreate(index)),
                              class: "mr-1 bg-blue-300 text-gray-50 rounded-md px-4 py-1 cursor-pointer hover:bg-blue-400 w-full"
                            }, _toDisplayString(_unref(langObj)['新增']), 9, _hoisted_62)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  faultCause: _withCtx(({ record }) => [
                    (_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                          _createVNode(_component_a_textarea, {
                            value: record.faultCause,
                            "onUpdate:value": ($event: any) => ((record.faultCause) = $event),
                            rows: 4
                          }, null, 8, ["value", "onUpdate:value"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_64, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.faultCauseList || [], (txt, i) => {
                            return (_openBlock(), _createElementBlock("div", { key: i }, [
                              (i <= 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_65, _toDisplayString(txt), 1))
                                : _createCommentVNode("", true),
                              (i > 1 && _unref(isShowFaultCause))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_66, _toDisplayString(txt), 1))
                                : _createCommentVNode("", true),
                              (i > 1 && !_unref(isShowFaultCause))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                                    (i === 2)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_68, "......"))
                                      : _createCommentVNode("", true),
                                    (i > 2)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_69))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                  ]),
                  faultSuggest: _withCtx(({ record }) => [
                    (_unref(edit))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_70, [
                          _createVNode(_component_a_textarea, {
                            value: record.faultSuggest,
                            "onUpdate:value": ($event: any) => ((record.faultSuggest) = $event),
                            rows: 4
                          }, null, 8, ["value", "onUpdate:value"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_71, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.faultSuggestList || [], (txt, i) => {
                            return (_openBlock(), _createElementBlock("div", { key: i }, [
                              (i <= 1)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_72, _toDisplayString(txt), 1))
                                : _createCommentVNode("", true),
                              (i > 1 && _unref(isShowFaultSuggest))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_73, _toDisplayString(txt), 1))
                                : _createCommentVNode("", true),
                              (i > 1 && !_unref(isShowFaultSuggest))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_74, [
                                    (i === 2)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_75, "......"))
                                      : _createCommentVNode("", true),
                                    (i > 2)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_76))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]))
                          }), 128))
                        ]))
                  ]),
                  repairList: _withCtx(({ text }) => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(text, (repair, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: i,
                        class: "py-1 border-b border-gray-300 text-xs"
                      }, _toDisplayString(repair), 1))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["dataSource", "columns"])
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["spinning"]),
    _createVNode(_component_a_modal, {
      width: 850,
      maskClosable: false,
      visible: _unref(visible),
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      footer: null,
      title: _unref(langObj)['周报转发：复制链接转发，用户可在电脑端或手机端查看']
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_77, [
          _createElementVNode("div", null, _toDisplayString(_unref(langObj)['pc端链接']) + ":", 1),
          _createElementVNode("div", _hoisted_78, _toDisplayString(_unref(urlpc)), 1),
          _cache[21] || (_cache[21] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", {
            class: "cursor-pointer",
            onClick: _cache[10] || (_cache[10] = ($event: any) => (copyUrl('pc')))
          }, [
            _createVNode(_component_CopyOutlined)
          ])
        ]),
        _createElementVNode("div", _hoisted_79, [
          _createElementVNode("div", null, _toDisplayString(_unref(langObj)['手机端链接']) + ":", 1),
          _createElementVNode("div", _hoisted_80, _toDisplayString(_unref(urlmb)), 1),
          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
          _createElementVNode("div", {
            class: "cursor-pointer",
            onClick: _cache[11] || (_cache[11] = ($event: any) => (copyUrl('mb')))
          }, [
            _createVNode(_component_CopyOutlined)
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "title"]),
    _createVNode(_component_a_modal, {
      visible: _unref(showLockModal),
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => (_isRef(showLockModal) ? (showLockModal).value = $event : showLockModal = $event)),
      title: _unref(langObj)['提示']
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          key: "back",
          onClick: _cache[13] || (_cache[13] = () => { _isRef(showLockModal) //@ts-ignore
 ? showLockModal.value = false : showLockModal = false })
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(langObj)['关闭']), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_button, {
          key: "submit",
          type: "primary",
          onClick: _cache[14] || (_cache[14] = ($event: any) => (forceAcquireLock()))
        }, {
          default: _withCtx(() => _cache[23] || (_cache[23] = [
            _createTextVNode("强制占用")
          ])),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_81, _toDisplayString(_unref(lockError)), 1)
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ]))
}
}

})