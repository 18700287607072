import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full theme-common" }
const _hoisted_2 = { class: "flex justify-center items-center mb-7" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import { computed, defineComponent, onMounted, ref } from "vue";
import { getLanguage } from "../../../common/translate";

import EventOrderComponent from "./EventOrder.vue";
import ThresholdAlarmComponent from "./ThresholdAlarm.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
}
getLang()

let menuSelect = ref(1)

const selectMenu = (ev:any) => {
    menuSelect.value = ev
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["btn1 theme_equipment_btn", _unref(menuSelect)===1?'theme_equipment_btn_select':'']),
        style: _normalizeStyle({'background': _unref(menuSelect) === 1 ? '#072499' : ''}),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1)))
      }, _toDisplayString(_unref(getLanguage)('智能报警工单',_unref(language))), 7),
      _createElementVNode("div", {
        class: _normalizeClass(["btn1 theme_equipment_btn", _unref(menuSelect)===2?'theme_equipment_btn_select':'']),
        style: _normalizeStyle({'background': _unref(menuSelect) === 2 ? '#072499' : ''}),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2)))
      }, _toDisplayString(_unref(getLanguage)('门限报警工单',_unref(language))), 7)
    ]),
    (_unref(menuSelect) === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(EventOrderComponent)
        ]))
      : _createCommentVNode("", true),
    (_unref(menuSelect) === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(ThresholdAlarmComponent)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})