import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "whiteBg" }
const _hoisted_2 = { class: "cardHead" }
const _hoisted_3 = { style: {"position":"relative","top":"4px","left":"5px"} }
const _hoisted_4 = {
  class: "main",
  style: {"max-height":"1450px","overflow-y":"scroll"}
}
const _hoisted_5 = { key: 1 }

import request from "../../../common/request";
import { computed, onMounted } from "vue";
import { ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { NodeIndexOutlined } from "@ant-design/icons-vue"
/**操作日志记录 */
import { operateLogs } from "@/common/logs";

const DefaultRangeCount = 12;
const DefaultLimitCount = 4;
const DefaultIntervalDays = 7;
const DefaultIntervalTimes = 1;

interface Props {
  sensor: any,
  machineId: string,
  expertCare?: boolean | null
}


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupAlarmItem',
  props: {
    sensor: {},
    machineId: {},
    expertCare: { type: [Boolean, null] }
  },
  emits: ['search'],
  setup(__props: any, { emit: __emit }) {

class QuotaGroupAlarmItem {
  _id: string | null;
  sensorId: string = "";
  sensorPlace: string = "";
  machineId: string = "";
  dataTypes: number[] = [];
  dataTypeNames: string[] = [];
  flag: string = ""
  enable: boolean = false;
  level1RangeCount: number = DefaultRangeCount;
  level1LimitCount: number = DefaultLimitCount;
  level1IntervalDays: number = DefaultIntervalDays;
  level1IntervalTimes: number = DefaultIntervalTimes;
  level2RangeCount: number = DefaultRangeCount;
  level2LimitCount: number = DefaultLimitCount;
  level2IntervalDays: number = DefaultIntervalDays;
  level2IntervalTimes: number = DefaultIntervalTimes
  level3RangeCount: number = DefaultRangeCount;
  level3LimitCount: number = DefaultLimitCount;
  level3IntervalDays: number = DefaultIntervalDays;
  level3IntervalTimes: number = DefaultIntervalTimes;
}


let hasConfigs = computed(() => {
  return dataSource.value && dataSource.value.table && dataSource.value.table.length > 0;
})

const props = __props
const emit = __emit;
watch(
    () => props.sensor,
    (newVal, oldVal) => {
      dataSource.value = props.sensor;
    },
);
onMounted(() => {
  console.log(props)
})
const dataSource = ref();

dataSource.value = props.sensor;

const columns = [
  {
    title: "数据类型",
    dataIndex: "dataTypesName",
  },
  {
    title: "开启报警",
    dataIndex: "enable",
  },
  {
    title: "可用报警",
    dataIndex: "level1Threshold",
  },
  {
    title: "警戒报警",
    dataIndex: "level2Threshold",
  },
  {
    title: "故障报警",
    dataIndex: "level3Threshold",
  }
];
const editSave = () => {
  let items: any = dataSource.value.table;
  let flag = true;
  let msg = "";
  for (let i = 0; i < items.length; i++) {
    let item: QuotaGroupAlarmItem = items[i];
    if (item.enable &&
        (!item.level1RangeCount || !item.level2RangeCount || !item.level3RangeCount ||
            !item.level1LimitCount || !item.level2LimitCount || !item.level3LimitCount ||
            !item.level1IntervalDays || !item.level2IntervalDays || !item.level3IntervalDays ||
            !item.level1IntervalTimes || !item.level2IntervalTimes || !item.level3IntervalTimes)) {
      msg = "开启报警的笔数、报警间隔必须配置";
      flag = false;
      break;
    }
  }
  if (!flag) {
    message.error(msg);
    return;
  }
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };

  console.log(props.expertCare)
  request.put("/api/sensors/quota-group-alarm", {
    expertCare: props.expertCare,
    machineId: props.machineId,
    sensorId: props.sensor.id,
    list: items
  }, config).then((res) => {
    if (res) {
      message.success("操作成功");
      emit("search");
      operateLogs("保存组合值报警规则，传感器：" + items[0].sensorPlace);
    }
  });
};


return (_ctx: any,_cache: any) => {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_card, { bordered: false }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(NodeIndexOutlined)),
          _createElementVNode("span", _hoisted_3, _toDisplayString(dataSource.value.sensorPlace), 1)
        ])
      ]),
      extra: _withCtx(() => [
        _createVNode(_component_a_radio_group, { size: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: "c",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (editSave()))
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("保存")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_a_table, {
        bordered: "",
        columns: columns,
        "data-source": dataSource.value.table,
        pagination: false
      }, {
        emptyText: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "text-white" }, "请先组合数据类型并应用模型", -1)
        ])),
        bodyCell: _withCtx(({ column, text, record }) => [
          (column.dataIndex === 'enable')
            ? (_openBlock(), _createBlock(_component_a_switch, {
                key: 0,
                checked: record.enable,
                "onUpdate:checked": ($event: any) => ((record.enable) = $event),
                "checked-children": "开",
                "un-checked-children": "关"
              }, null, 8, ["checked", "onUpdate:checked"]))
            : _createCommentVNode("", true),
          (column.dataIndex === 'dataTypesName')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(record.dataTypeNames?.join('+')), 1))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level1Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level1RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level1RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[3] || (_cache[3] = _createTextVNode(" 笔内累计 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level1LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level1LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[4] || (_cache[4] = _createTextVNode(" 笔， 触发报警 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level1IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level1IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[5] || (_cache[5] = _createTextVNode(" 天 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level1IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level1IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[6] || (_cache[6] = _createTextVNode(" 次 "))
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level2Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level2RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level2RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[7] || (_cache[7] = _createTextVNode(" 笔内累计 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level2LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level2LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[8] || (_cache[8] = _createTextVNode(" 笔， 触发报警 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level2IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level2IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[9] || (_cache[9] = _createTextVNode(" 天 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level2IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level2IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[10] || (_cache[10] = _createTextVNode(" 次 "))
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level3Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level3RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level3RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[11] || (_cache[11] = _createTextVNode(" 笔内累计 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level3LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level3LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[12] || (_cache[12] = _createTextVNode(" 笔， 触发报警 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level3IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level3IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[13] || (_cache[13] = _createTextVNode(" 天 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level3IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level3IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[14] || (_cache[14] = _createTextVNode(" 次 "))
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["data-source"])
    ])
  ]))
}
}

})