<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div style="padding: 16px 16px;    height: calc(100vh - 100px);
    background-color: rgb(0, 10, 50);
    overflow-y: scroll;
    padding-bottom: 30px; background-color: #000A32; color: #fff" class="theme-common">
        <div class="box">
            <div class="w-full theme-box-title" style="border-bottom: 1px solid #495985;">
                <div class="flex items-center px-5" style="font-size: 18px;">
                    <div class="py-3 cursor-pointer" :class="{'txt-select': menuSelect === 1}" @click="selectMenu(1)">{{langObj['诊断报告']}}({{counts.count1}})</div>
                    <div class="py-3 cursor-pointer ml-24" :class="{'txt-select': menuSelect === 2}" @click="selectMenu(2)">{{langObj['检修报告']}}({{counts.count2}})</div>
                    <div class="py-3 cursor-pointer ml-24" :class="{'txt-select': menuSelect === 3}" @click="selectMenu(3)">{{langObj['周报']}}({{counts.count3}})</div>
                </div>
            </div>

            <div class="w-full p-5">
                <div v-if="menuSelect === 1">
                    <DiagnosticReportComponent></DiagnosticReportComponent>
                </div>
                <div v-if="menuSelect === 2">
                    <MaintenanceReportComponent></MaintenanceReportComponent>
                </div>
                <div v-if="menuSelect === 3">
                    <WeekReportComponent></WeekReportComponent>
                </div>
            </div>



        </div>
    </div>
</a-config-provider>
</template>


<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList } from "../../common/tools";
import { useRouter } from "vue-router";
import request from "../../common/request";
// import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import DiagnosticReportComponent from "./DiagnosticReport.vue";
import MaintenanceReportComponent from "./MaintenanceReport.vue";
import WeekReportComponent from "./WeekReport.vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { getMemberOnboarding } from "../../common/tools";
let menuSelect:any = ref(1)

const selectMenu = (ev:any) => {
    menuSelect.value = ev
}
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
onMounted(() => {
    init()

    //新注册用户，未经过引导的需弹窗进行引导
    if(!getMemberOnboarding()){
        selectMenu(3);
    }
})

const init = () => {
    getCount()
    if(localStorage.getItem('report_type')) {
        menuSelect.value = JSON.parse(localStorage.getItem('report_type') || '1')
        localStorage.removeItem('report_type')
    }
}
let counts:any = ref({
  "count1": 0,
  "count2": 0,
  "count3": 0
})
// 获取计数统计
const getCount = async() => {
    let config: any = {
        params: {
            factoryIds: getRealFactoryIdList()
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/reports/count', config)
    counts.value = result.data
}
</script>

<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.txt-select {
    border-bottom: 2px solid #00FFF4;
}

.btn {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff
}

/deep/ .ant-table {
    background: #020F46;
    color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
}

/deep/ .ant-empty-normal {
    color: #fff;
}

</style>
