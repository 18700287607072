import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, isRef as _isRef, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/zhenduan.png'
import _imports_1 from '@/assets/weixiu.png'
import _imports_2 from '@/assets/jiancha.png'
import _imports_3 from '@/assets/menxian.png'


const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "w-full p-5 text-center" }
const _hoisted_3 = { class: "text-red-600 text-3xl" }
const _hoisted_4 = { class: "w-full p-5" }
const _hoisted_5 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_6 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "ml-2" }
const _hoisted_12 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_13 = { class: "ml-2" }
const _hoisted_14 = {
  style: {"width":"100%","text-align":"center"},
  class: "mt-4"
}

import { computed, onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import dayjs from 'dayjs';
import { langList } from "@/common/lang";
import { getRealFactoryIdList } from "@/common/tools";
import { useRoute } from "vue-router"
import AddRepair from "@/components/warning-manage/add-repair.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'warning-popup',
  emits: ['close', 'alarm'],
  setup(__props, { emit: __emit }) {

const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
let langObj: any = ref({})
let language: any = ref('Chinese')
getLang()

let typeList = [
  { label: '智能诊断', value: 1 },
  { label: '门限报警', value: 4 },
]

let conditionList = [
  { label: '健康', value: 0, color: '#61c08f' },
  { label: '可用', value: 1, color: '#d2de49' },
  { label: '可用', value: 2, color: '#d2de49' },
  { label: '警戒', value: 3, color: '#cd5f3b' },
  { label: '警戒', value: 4, color: '#cd5f3b' },
  { label: '警戒', value: 5, color: '#cd5f3b' },
  { label: '故障', value: 6, color: '#921e37' },
  { label: '故障', value: 7, color: '#921e37' },
]

let warningColumns = [
  {
    title: langObj.value['报警时间'],
    dataIndex: 'date',
  },
  {
    title: langObj.value['报警类型'],
    dataIndex: 'type',
  },
  {
    title: langObj.value['严重程度'],
    dataIndex: 'condition',
  },
  {
    title: langObj.value['设备'],
    dataIndex: 'machineName',
  },
  {
    title: langObj.value['报警内容'],
    dataIndex: 'diagnosticNotes',
  }
]

let factory_id = localStorage.getItem('factory_id') || '';

const emit = __emit;

let enableAlarmPopup = ref(false);

const route = useRoute()

watch(route, (to) => {
  checkAndAlarm().catch(() => { });
}, { flush: 'pre', immediate: true, deep: true })

onMounted(async () => {
  let threeMonthAgo = dayjs().subtract(3, 'month');
  latestAlarmTime.value = threeMonthAgo.format('YYYY/MM/DD HH:mm:ss')
  startAlarmTime.value = latestAlarmTime.value;
  await getFactorySettings(factory_id);
  if (enableAlarmPopup.value) {
    setTimeout(() => {
      checkAndAlarm();
    }, 2000);
  }
});

async function checkAndAlarm() {
  if (!enableAlarmPopup.value) return;
  await fetchWorkflows();
  if (workflows.value.length > 0) alarm();
}

let latestAlarmTime = ref<string>(); // 最新报警起始时间(增量获取报警列表)
let startAlarmTime = ref<string>();  // 界面显示的报警起始时间

let timeout: any = null;

function alarm() {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (workflows.value?.length === 0) return;
    visibleRepairModal.value = false;
    emit("alarm")
    clearTimeout(timeout);
  }, 500);
}

function startAlarmJob() {
  setInterval(() => {
    if (workflows.value.length > 0) alarm();
  }, 8000);
}

function startFetchJob() {
  setInterval(() => {
    fetchWorkflows();
  }, 60000);
  setTimeout(async () => {
    await fetchWorkflows()
    alarm();
  }, 2000);
}


async function fetchWorkflows() {
  let list = await getWorkflows();

  workflows.value = list || [];

  //latestAlarmTime.value = dayjs().format('YYYY/MM/DD HH:mm:ss')
}

let workflows = ref<any[]>([]);
let total = computed(() => workflows.value.length);
const getWorkflows = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 1000,
      handleStatus: 1,
      factoryIds: getRealFactoryIdList(),
      typeList: [1]
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (latestAlarmTime.value) {
    config.params.begin = latestAlarmTime.value;
  }

  let result = await request.get('/api/workflows', config)
  return result?.data.list || [];
}

const getFactorySettings = async (factoryId: string) => {
  if (!factoryId) return
  const config = {
    params: { id: factoryId },
    headers: {
      requestId: uuidv4(),
    },
  };
  let res = await request.get("/api/factories/setting", config)
  if (res?.data?.enableAlarmPopup) {
    enableAlarmPopup.value = res.data.enableAlarmPopup;
  }
};

let visibleRepairModal = ref(false);

function addRepairModal() {
  visibleRepairModal.value = true;
}

async function addRepairSuccess() {
  visibleRepairModal.value = false;
  await fetchWorkflows();
  if (workflows.value.length === 0) {
    emit('close');
  }
}


return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, "报警：自" + _toDisplayString(_unref(startAlarmTime)) + "起，系统共报警" + _toDisplayString(_unref(total)) + "条（详情见下表），请尽快处理！", 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            columns: _unref(warningColumns),
            "data-source": _unref(workflows),
            pagination: {},
            rowKey: "id"
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'type')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (text === 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[2] || (_cache[2] = [
                          _createElementVNode("img", {
                            style: {"width":"20px"},
                            src: _imports_0,
                            alt: ""
                          }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    (text === 2)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[3] || (_cache[3] = [
                          _createElementVNode("img", {
                            style: {"width":"20px"},
                            src: _imports_1,
                            alt: ""
                          }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    (text === 3)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[4] || (_cache[4] = [
                          _createElementVNode("img", {
                            style: {"width":"20px"},
                            src: _imports_2,
                            alt: ""
                          }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    (text === 4)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _cache[5] || (_cache[5] = [
                          _createElementVNode("img", {
                            style: {"width":"20px"},
                            src: _imports_3,
                            alt: ""
                          }, null, -1)
                        ])))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(langObj)[_unref(typeList).find(p => p.value === text)?.label || ""]), 1)
                  ]))
                : _createCommentVNode("", true),
              (column.dataIndex === 'condition')
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    _createElementVNode("div", {
                      style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, {'background': _unref(conditionList).find((p) => p.value === text)?.color}])
                    }, null, 4),
                    _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === text)?.label || ""]), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["columns", "data-source"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: addRepairModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(langObj)['添加检修记录']), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createVNode(_component_a_modal, {
      width: 1000,
      maskClosable: false,
      closable: false,
      footer: null,
      visible: _unref(visibleRepairModal),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visibleRepairModal) ? (visibleRepairModal).value = $event : visibleRepairModal = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(AddRepair, {
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_isRef(visibleRepairModal) //@ts-ignore
 ? visibleRepairModal.value = false : visibleRepairModal=false)),
          onSuccess: addRepairSuccess,
          "warning-list": _unref(workflows)
        }, null, 8, ["warning-list"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})