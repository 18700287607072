<template>
    <div class="w-full">
        <a-form :layout="'inline'" :model="formState" >
            <a-form-item label="设备名称">
                <a-input v-model:value="formState.machineName" placeholder="" :readonly="true"/>
            </a-form-item>
            <a-form-item label="传感器选择">
                <a-select
                ref="sensorId"
                v-model:value="formState.sensorId"
                style="width: 200px"
                @change="getData"
                >
                    <a-select-option v-for="(item,index) in sensorList" :key="index" :value="item.id">{{ item.sensorPlace }}</a-select-option>
                </a-select>
            </a-form-item>
            <!-- <a-form-item label="振动/健康">
                <a-select
                ref="model"
                v-model:value="formState.model"
                style="width: 200px"
                @change="changeModel"
                >
                    <a-select-option v-for="(item,index) in ['振动', '健康']" :key="index" :value="item">{{ item }}</a-select-option>
                </a-select>
            </a-form-item> -->
           
            <a-form-item label="日期范围">
                <a-range-picker @change="selectDate" v-model:value="formState.date" show-time format="YYYY-MM-DD HH:mm:ss"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
            </a-form-item>

            <a-form-item label="类型">
                <a-select
                v-model:value="formState.type"
                style="width: 200px"
                >
                    <a-select-option v-for="(item,index) in typeList" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="内容">
                <a-input v-model:value="formState.content" />
            </a-form-item>


            <a-form-item :wrapper-col="{ }">
                <a-button type="primary" @click="submit" class="btn-submit">提交选择</a-button>
            </a-form-item>
        </a-form>

        <div class="mt-4 fff">
            <div class="span-1">通用</div>
            <div>
                <a-radio-group v-model:value="formState.quota" button-style="solid" @change="selectQuota1">
                    <a-radio-button v-for="(item, index) in quotaList1"  :key="index" :value="item.value">{{ item.label }}</a-radio-button>
                </a-radio-group>
            </div>
        </div>

        <div class="mt-4 fff">
            <div class="span-1">专业</div>
            <div>
                <a-radio-group v-model:value="formState.quota" button-style="solid" @change="selectQuota2">
                    <a-radio-button v-for="(item, index) in quotaList2"  :key="index" :value="item.value">{{ item.label }}</a-radio-button>
                </a-radio-group>
            </div>
        </div>

        <div class="box" style="color: #fff;">
            <span class="span-1" v-if=" formState.model == '健康' ">健康诊断分数趋势图</span>
            <span class="span-1" v-if=" formState.model == '振动' ">振动指标诊断健康分数</span>
            <div id="divInfoChart" style="width: 100%; height: 470px"></div>
        </div>

    </div>
    
</template>

<script lang="ts" setup>
import { ref, defineProps, watch, onMounted, defineEmits } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from 'ant-design-vue';
import * as echarts from 'echarts';
import request from "../../../common/request";
import { Dayjs } from 'dayjs';

const props:any = defineProps({
    machineInfo:null,
})

watch(() => props.machineInfo, (newValue, oldValue) => {
    init()
})

let typeList = ref([
    {label: '高频', value: 'hz'},
    {label: '低频X', value: 'lx'},
    {label: '低频Y', value: 'ly'},
    {label: '低频Z', value: 'lz'},
])
let quotaList1 = [
    { label: '0~0.3KHz均方根', value: 'Trms_300',   },
    { label: '0~1KHz均方根', value: 'Trms_1000',  },
    { label: '1K~10KHz均方根', value: 'Trms_1000_10000',  },
    { label: '10K~25.6KHz均方根', value: 'Trms_10000_25600',  },
    { label: '1K~25.6KHz均方根', value: 'Trms_1000_25600',  },
    { label: '0~25.6KHz均方根', value: 'Trms',  },
    { label: '优化峭度01', value: 'TLinKurt' , },
    { label: '平稳冲击烈度', value: 'TImpactIntensity',  },
    { label: '线性峭度02', value: 'Tlin_kurt',  },
    { label: '峭度因子', value: 'Tkurt',  },
    { label: '峰峰值', value: 'Tff', },
    { label: '优化峰值', value: 'TAccelerationPeak',  },
    { label: '优化偏度', value: 'Tlin_bias',  },
    { label: '速度均方根', value: 'TVelocityRMS',  },
    { label: '频率集中度', value: 'Tcon',  },
    { label: '0.3~1KHz均方根', value: 'Trms_300_1000',  },
    { label: '1K~3KHz均方根', value: 'Trms_1000_3000',  },
    { label: '3K~6KHz均方根', value: 'Trms_3000_6000' , },
    { label: '6K~12KHz均方根', value: 'Trms_6000_12000' , },
    { label: '12000~25600Hz均方根', value: 'Trms_12000_25600' , },
    { label: '6K~10KHz均方根', value: 'Trms_6000_10000' , },
    { label: '优化均方根', value: 'TAccelerationRMS',  },
    { label: '偏度因子', value: 'Tbias' , },
    { label: '裕度因子', value: 'Tmargin' , },
    { label: '峰值指标', value: 'Tpp' , },
    { label: '包络信息熵', value: 'Tent_e' , },
    { label: '包络幅值峭度', value: 'Tenv_kurt' , },
    { label: '脉冲因子', value: 'Tpulse',  },
    { label: '波形因子', value: 'Twave',  },
    { label: '均值频率', value: 'TF9', },
    { label: '主频带位置', value: 'Tmainf',  },
    { label: '时域信息熵', value: 'Tent_t',  },
    { label: '频域信息熵', value: 'Tent_f',  },
    { label: '电流指标', value: 'Tcurrent',  },
]

let quotaList2:any = ref([])


let formState:any = ref({
    machineName: '',
    sensorId: null,
    date: null,
    content: ''
})

let sensorList:any = ref([])

onMounted(() => {
    init()
})


const init = () => {
    if(!props.machineInfo || !props.machineInfo.id) {
        return
    }
    formState.value.machineName = props.machineInfo.machineName
    formState.value.quota = 'Trms_300'
    formState.value.type = 'hz'
    formState.value.typeIndex = 1
    getMachine()
    getQuotaList()
}

const getQuotaList = async() => {
    quotaList2.value = []
    const config = {
        params: { machineId: props.machineInfo.id},
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/machines/quota/names', config);
    console.log('result333333', result)
    if(result?.data?.length) {
        quotaList2.value = result.data.map((d:any) => ({label: d.value, value: d.value, name: d.name}))
    }
}


const getMachine = async() => {
    // const config = {
    //     params: { id: props.machineInfo.id},
    //     headers: {
    //         requestId: uuidv4(),
    //     },
    // };

    // let result = await request.get('/api/external/machines/workstatus/info', config);
    // statusInfo = result.data
    getSensorList()
}

const getSensorList = async() => {
    const config = {
        params: {machineId: props.machineInfo.id},
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/sensors', config)
    
    sensorList.value = result.data
    getData()
}

// 选择时间
const selectDate = (ev:any) => {
    getData()
}

// 选择通用指标
const selectQuota1 = (ev:any) => {
    console.log('ev', ev)
    typeList.value = [
        {label: '高频', value: 'hz'},
        {label: '低频X', value: 'lx'},
        {label: '低频Y', value: 'ly'},
        {label: '低频Z', value: 'lz'},
    ]
    let typeInfo = typeList.value.find((p:any) => p.value === formState.value.type)
    if(!typeInfo) {
        formState.value.type = 'hz'
    }
    formState.value.typeIndex = 1
    getData()
}

// 选择专业指标
const selectQuota2 = (ev:any) => {
    console.log('ev', ev)
    let quotaInfo = quotaList2.value.find((p:any) => p.value === formState.value.quota)
    console.log('quotaInfo', quotaInfo)
    typeList.value = [
        {label: quotaInfo.name, value: quotaInfo.name}
    ]
    formState.value.type = quotaInfo.name
    formState.value.typeIndex = 2
    getData()
}


// 获取图数据
const getData = () => {
    console.log('11111')
}





</script>

<style scoped>
    .box {
        width: 100%;
        margin-top: 16px;
    }

    .span-1 {
        font-size: 18px;
        font-weight: bold;
    }
    .fff {
        color: #fff
    }
</style>