<script setup lang="ts">
import { onMounted, ref } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

const { dataTypes } = defineProps(['dataTypes'])

onMounted(() => {
  loadMachineTypes();
});

let tempModelQuery = ref({
  date: null,
  begin: "",
  end: "",
  machineTypeId: null,
  dataType: null,
});
const dateRange = ref<[Dayjs, Dayjs] | []>([]);

const dates = ref<any>();
const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

let machineTypes: any = ref([]);

let loadMachineTypes = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-type/all", config);
  if (result) {
    machineTypes.value = result.data;
  }
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

const tempColumns = [
  {
    title: "模板号",
    dataIndex: "version",
    width: "60px"
  },
  {
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    //title: langObj.value["可用报警阈值"],
    title: "可用报警阈值",
    dataIndex: "level1Threshold",
  },
  {
    //title: langObj.value["警戒报警阈值"],
    title: "警戒报警阈值",
    dataIndex: "level2Threshold",
  },
  {
    //title: langObj.value["故障报警阈值"],
    title: "故障报警阈值",
    dataIndex: "level3Threshold",
  },
  {
    title: langObj.value["设备类型"],
    dataIndex: "machineTypeName",
  },
  {
    title: langObj.value["数据类型"],
    dataIndex: "dataTypeName",
    minWidth: "130px"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

let models = ref([]);

let detailColumns = [
  {
    title: langObj.value["可用报警"],
    dataIndex: "level1",
  },
  {
    title: langObj.value["警戒报警"],
    dataIndex: "level2",
  },
  {
    title: langObj.value["故障报警"],
    dataIndex: "level3",
  },
  {
    title: langObj.value["熔断报警"] || "熔断报警",
    dataIndex: "level4",
  },
]
let detailTableSource = ref([{ level1: "", level2: "", level3: "", level4: "" }]);

let useSensorIdsOld: any = ref([]);
let unUseSensors: any = ref([]);
let useSensors: any = ref([]);
let unUseSensorIds: any = ref([]);
let useSensorIds: any = ref([]);

let selectSimpleModel: any = ref({});
let selectUnUseSensor = () => {
};

let selectUseSensor = () => {
};

let loadTempSimpleList = async () => {
  // clear
  detailTableSource.value = [{}];
  useSensorIdsOld = []
  useSensors.value = [];
  unUseSensors.value = [];
  useSensorIds.value = [];
  unUseSensorIds.value = [];

  if (dateRange.value && dateRange.value.length == 2) {
    tempModelQuery.value.begin = dateRange.value[0].toISOString();
    tempModelQuery.value.end = dateRange.value[1].toISOString();
  } else {
    tempModelQuery.value.begin = "";
    tempModelQuery.value.end = "";
  }

  let config: any = {
    params: {
      machineType: tempModelQuery.value.machineTypeId,
      dataType: tempModelQuery.value.dataType,
      factoryId: localStorage.getItem("factory_id"),
      begin: tempModelQuery.value.begin,
      end: tempModelQuery.value.end,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/simple-model", config);
  if (result) {
    models.value = result.data;
  }
};

let deleteSimpleModel = async (record: any) => {
  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/quota/simple-model", config).then((res) => {
    if (res) {
      message.success("操作成功");
      loadTempSimpleList();
    } else {
    }
  });
};

let onClickDetail = async (record: any) => {
  selectSimpleModel.value = record;
  detailTableSource.value = [{
    level1: `${record.level1RangeCount || 0}笔内累计${record.level1LimitCount || 0}笔大于阈值:${record.level1Threshold || ""}`,
    level2: `${record.level2RangeCount || 0}笔内累计${record.level2LimitCount || 0}笔大于阈值:${record.level2Threshold || ""}`,
    level3: `${record.level3RangeCount || 0}笔内累计${record.level3LimitCount || 0}笔大于阈值:${record.level3Threshold || ""}`,
    level4: `阈值:${record.level3Threshold || ""}`
  }]

  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/simple-model/bind-infos", config);
  if (result) {
    const groupedData: any = {};
    const groupedDataUn: any = {};

    result.data.binds.map((item: any) => {
      if (!groupedData[item.groupId]) {
        groupedData[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedData[item.groupId].machines[item.machineId]) {
        groupedData[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedData[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    useSensorIds.value = result.data.binds.map((p: any) => p.sensorId);
    useSensorIdsOld.value = useSensorIds.value;

    useSensors.value = Object.keys(groupedData).map((groupId) => ({
      key: groupId,
      title: groupedData[groupId].groupName,
      checkable: false,

      children: Object.keys(groupedData[groupId].machines).map((machineId) => ({
        key: machineId,
        title: groupedData[groupId].machines[machineId].machineName,
        checkable: false,
        children: groupedData[groupId].machines[machineId].sensors,
      })),
    }));

    result.data.unBinds?.map((item: any) => {
      if (!groupedDataUn[item.groupId]) {
        groupedDataUn[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedDataUn[item.groupId].machines[item.machineId]) {
        groupedDataUn[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedDataUn[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    const treeDataUn = Object.keys(groupedDataUn).map((groupId) => ({
      key: groupId,
      title: groupedDataUn[groupId].groupName,
      checkable: false,
      children: Object.keys(groupedDataUn[groupId].machines).map(
          (machineId) => ({
            key: machineId,
            checkable: false,
            title: groupedDataUn[groupId].machines[machineId].machineName,
            children: groupedDataUn[groupId].machines[machineId].sensors,
          })
      ),
    }));

    unUseSensorIds.value = [];
    unUseSensors.value = treeDataUn;
  }
};

let submitUnUseModel = async () => {
  if (unUseSensorIds.value.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/simple-model",
        { id: selectSimpleModel.value.id, sensorIds: unUseSensorIds.value },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      await onClickDetail(selectSimpleModel.value);
    }
  }
};

let submitReplaceUseModel = async () => {
  let diff = useSensorIds.value
      .concat(useSensorIdsOld.value)
      .filter(
          (item: any) =>
              !useSensorIds.value.includes(item) ||
              !useSensorIdsOld.value.includes(item)
      );
  if (diff.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/simple-model/un-bind",
        { id: selectSimpleModel.value.id, sensorIds: diff },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      onClickDetail(selectSimpleModel.value);
    }
  }
};

</script>

<template>
  <a-row :gutter="24">
    <a-col :span="12">
      <div class="box-list w-full whiteBg" style="width: 100%">
        <div>
          <a-form
              name="horizontal_login"
              layout="inline"
              autocomplete="off">
            <a-form-item :label="langObj['时间']" name="status">
              <a-range-picker style="width: 230px"
                              v-model:value="dateRange"
                              :disabled-date="disabledDate"
                              @calendarChange="onCalendarChange"/>
            </a-form-item>

            <a-form-item :label="langObj['设备类型']" name="status">
              <a-select style="width: 250px"
                        v-model:value="tempModelQuery.machineTypeId"
                        allowClear
                        :placeholder="langObj['请选择']"
              >
                <a-select-option
                    v-for="(item, index) in machineTypes"
                    :key="index"
                    :value="item.id"
                >
                  {{
                    item.FirstCategory +
                    "/" +
                    item.SecondCategory +
                    "/" +
                    item.ThirdCategory
                  }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item :label="langObj['数据类型']" name="status">
              <a-select style="width: 120px"
                        v-model:value="tempModelQuery.dataType"
                        allowClear
                        :placeholder="langObj['请选择']"
              >
                <a-select-option
                    v-for="(item, index) in dataTypes"
                    :key="index"
                    :value="item.code"
                >
                  {{ langObj[item.name] }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item>
              <div class="flex items-center">
                <div
                    @click="loadTempSimpleList"
                    class="btn3 px-5 py-1 fff cursor-pointer ml-4"
                    style="background-color: #072499;color:white"
                >
                  {{ langObj["查询"] }}
                </div>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <a-table
            style="width: 100%;height: calc(100% - 70px);overflow-y: auto;"
            size="small"
            bordered
            :row-class-name="getRowClassName"
            :columns="tempColumns"
            :data-source="models"
            :pagination="false">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'act'">
              <a class="theme-table-link">
                {{ langObj["删除"] }}</a
              >
              <a style="color: #00fff4 !important;" class="ms-1"
                 @click="onClickDetail(record)">
                {{ langObj["查看"] }}</a>
              <a-popconfirm
                  :title="langObj['确定删除此条数据么'] + '?'"
                  :ok-text="langObj['确定']"
                  :cancel-text="langObj['取消']"
                  @confirm="deleteSimpleModel(record)">

              </a-popconfirm>
            </template>
          </template>
        </a-table>
      </div>
    </a-col>
    <a-col :span="12">
      <a-row>
        <a-col :span="24">
          <div class="w-full items-center box-sm whiteBg">
            <div class="text-white bold mb-1 ">模板详情</div>
            <a-table size="small" bordered :columns="detailColumns" :data-source="detailTableSource"
                     style="width: 100%" :pagination="false">
            </a-table>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="24" class="mt-2">
        <a-col :span="12">
          <div class="w-full items-center box-detail">
            <div class="flex-1 text-center">未使用模板的设备</div>
            <div
                style="height: calc(100% - 10px);"
                class="mt-2 overflow-y-auto">
              <a-tree
                  v-model:checkedKeys="unUseSensorIds"
                  checkable
                  :tree-data="unUseSensors"
                  v-if="unUseSensors.length"
                  @check="selectUnUseSensor"
                  defaultExpandAll
              >
                <template #title="{ title, key }">
                  {{ title }}
                </template>
              </a-tree>
            </div>
            <div class="flex-1 text-center mt-2">
              <a-button type="primary" class="btn3" @click="submitUnUseModel"
              >使用模板
              </a-button
              >
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="w-full items-center box-detail">
            <div class="flex-1 text-center">已使用模板的设备</div>

            <div
                style="height: calc(100% - 10px);"
                class="mt-2 overflow-y-auto"
            >
              <a-tree
                  v-model:checkedKeys="useSensorIds"
                  checkable
                  :tree-data="useSensors"
                  @check="selectUseSensor"
                  v-if="useSensors.length"
                  defaultExpandAll
              >
                <template #title="{ title, key }">
                  {{ title }}
                </template>
              </a-tree>
            </div>
            <div class="flex-1 text-center mt-2">
              <a-button type="primary" class="btn3" @click="submitReplaceUseModel"
              >替换模板
              </a-button
              >
            </div>
          </div>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>

<style scoped lang="less">

/deep/ .ant-tree {
  background: none;
  color: white;
  height: 100%
}

/deep/ .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}

/deep/ .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}

.box-sm {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 10px 10px;
  width: 100%;
}

.box-detail {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 10px 10px 20px;
  width: 100%;
  height: calc(100vh - 400px);
}

.box-list {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 10px 10px 20px;
  width: 100%;
  height: calc(100vh - 250px);
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
  width: 100%;
  height: calc(100vh - 310px);
}

.btn3 {
  border: 1px solid #0d53b7;
  background-color: #072498;
  cursor: pointer;
}
</style>
