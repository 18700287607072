import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { style: {"color":"#fff"} }
const _hoisted_2 = { class: "w-full flex" }
const _hoisted_3 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_4 = { class: "p-3 w-full" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["onClick"]

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { getFactoryName, getGroupName, transformDate2 } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";


export default /*@__PURE__*/_defineComponent({
  __name: 'select-sensor',
  props: {
    visible: null,
    machineId: null,
    editRindex2: null
},
  emits: ["selectSensorSen"],
  setup(__props, { emit: __emit }) {

let visible = ref(true)
let editRindex2 = ref("auto");
let groupList = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()
const sensorColumns = [
    {
        title: langObj.value['传感器SN'],
        dataIndex: 'sensorSn',
    },  
    {
        title: langObj.value['测点位置'],
        dataIndex: 'sensorPlace',
    },  
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupId',
    },  
    {
        title: langObj.value['所属设备'],
        dataIndex: 'machineName',
    },  
    {
        title: langObj.value['测点ID'],
        dataIndex: 'id',
    },  
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },  
]
const props: any = __props;
let searchModel:any = ref({
    groupId: '',
})
let data:any = ref([])
let machineList = ref([])
let machine_id = ref('')

const emits = __emit;

watch(
    () => props.visible,
    (newValue, oldValue) => {
        visible.value = newValue
        machine_id.value = props.machineId
        init()
    }
);

onMounted(() => {
    machine_id.value = props.machineId
    editRindex2.value = props.editRindex2
    init()
})

const cancel = () => {
    visible.value = false
    emits("selectSensorSen", false);
}

const select = (ev:any) => {
    visible.value = false
    emits("selectSensorSen", ev);
}

const init = () => {
    searchSensor()
}

// 获取测点列表
const searchSensor = () => {
    if(!machine_id.value) {
        return
    }
    searchModel.value.machineId = machine_id.value
    getData()
}

const getData = async() => {
    let config: any = {
        params: {
            machineId: searchModel.value.machineId,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get('/api/sensors', config)
        
    if(result && result.status === 200) {
        data.value = result.data
    }
}

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

return (_ctx: any,_cache: any) => {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      visible: _unref(visible),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      closable: false,
      footer: null,
      bodyStyle: {'background': '#020F46'},
      style: {"top":"30%"},
      width: "1300px",
      maskClosable: false,
      "z-index": _unref(editRindex2),
      "onUpdate:zIndex": _cache[2] || (_cache[2] = ($event: any) => (_isRef(editRindex2) ? (editRindex2).value = $event : editRindex2 = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: cancel
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(langObj)['选择设备']), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_form, {
              model: _unref(searchModel),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['测点位置'],
                  name: "status"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(searchModel).sensorPlace,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).sensorPlace) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", {
                        onClick: getData,
                        class: "box px-5 py-1 fff cursor-pointer ml-4",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_unref(langObj)['查询']), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: sensorColumns,
                "data-source": _unref(data)
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'groupId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(getGroupName)(record.machine.groupId)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'machineName')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(record.machine.machineName), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 2,
                        onClick: ($event: any) => (select(record)),
                        class: "theme-table-link"
                      }, _toDisplayString(_unref(langObj)['选择']), 9, _hoisted_9))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "z-index"])
  ]))
}
}

})