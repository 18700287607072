<template>
  <div class="pl-1">
    <div class="flex items-center">
      <div class="size20 ">{{ inspectionBase.reportInfoAll.type == 1 ? langObj["智能诊断报告"] : langObj["基础巡检报告"] }}</div>
      <div class="flex-1 text-center">
        <div>{{ inspectionBase.reportInfoAll.machineName }}</div>
        <div>{{ getGroupName(reportIdObj?.machine?.groupId) }}</div>
      </div>
      <div>
        <a-select ref="select" v-model:value="inspectionBase.sensorId" style="min-width: 150px" @change="handleChangeS">
          <a-select-option v-for="(item, index) in sensorList" :key="index" :value="item.id">
            {{ item.sensorPlace }}
          </a-select-option>
        </a-select>
      </div>
    </div>

    <div class="w-full text-center p-3">{{ langObj['巡检状态'] }}：<text
        :style="{ color: getReportCondition(inspectionBase.reportInfo)?.color }"> {{
          langObj[getReportCondition(inspectionBase.reportInfo)?.label] }}</text></div>
    <div v-show="inspectionBase.reportInfoAll.type == 1 && inspectionBase.listT.length > 0 && isShowRander"
      class="w-full ">{{
        langObj['健康指标'] }}</div>
    <div v-show="inspectionBase.reportInfoAll.type == 1 && inspectionBase.listT.length > 0 && isShowRander"
      class="w-full flex">
      <div style="width:300px">

        <div id="chartA" style="width:300px;height:300px"></div>
      </div>
      <div class="flex-1"></div>
      <div class=" p-3 box  mr-4 mb-4" style="width:370px">
        <a-table style="width: 100%" size="small" :columns="stateColumns" :data-source="inspectionBase.listT"
          :pagination="false">
        </a-table>
      </div>
    </div>

    <div v-show="inspectionBase.reportInfoAll.type == 1">
      <div class="w-full mb-2">{{ langObj['基础指标'] }}</div>
      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">FX轴承因子
            <a-popover title="FX轴承因子">
              <template #content>
                <div class="con_wrap">
                  <div v-if="inspectionBase.value?.reportInfo?.sensorModel > 21">

                    <div class="con_wrap">
                      <p class="tit_wrap">指标释义 </p>
                      <p class="tit_con">
                        监测电机、水泵、风机、离心压缩机等旋转设备滚动轴承故障的指标。
                      </p>
                      <p class="tit_wrap">适用场景 </p>
                      <p class="tit_con">
                        对轴承损伤较为敏感，适用于电机、水泵、风机、离心压缩机等旋转设备的滚动轴承监测，不能在齿轮箱或其他存在强背景噪音的设备上使用。
                      </p>
                      <p class="tit_wrap">故障原因 </p>
                      <p class="tit_con">
                        <text>1.设备存在滚动轴承异常、部件碰摩等故障</text>
                        <text>2.设备存在工况导致的异常冲击</text>
                        <text>3.设备存在轴承润滑不良等故障</text>

                      </p>


                      <div class="grid_wrap">
                        <div class="grid_item">
                          <div>分数</div>
                          <div>建议客户操作</div>
                        </div>
                        <div class="grid_item">
                          <div>80分以上</div>
                          <div>无需操作</div>
                        </div>
                        <div class="grid_item">
                          <div>70-80分</div>
                          <div>关注设备分数变化情况</div>
                        </div>
                        <div class="grid_item">
                          <div>60-70分</div>
                          <div>注意设备运行时是否存在异响，定期改善润滑，关注设备状态及分数变化情况</div>
                        </div>
                        <div class="grid_item">
                          <div>40-60分</div>
                          <div>注意设备运行时是否存在异响，密切关注设备状态及分数变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划</div>
                        </div>
                        <div class="grid_item">
                          <div>40分以下</div>
                          <div>及时安排检查，根据现场检查结果更新维护计划</div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div v-else>

                    <div class="con_wrap">
                      <p class="tit_wrap">指标释义 </p>
                      <p class="tit_con">
                        监测电机、水泵、风机、离心压缩机等旋转设备滚动轴承故障的指标。
                      </p>
                      <p class="tit_wrap">适用场景 </p>
                      <p class="tit_con">
                        对轴承损伤较为敏感，适用于电机、水泵、风机、离心压缩机等旋转设备的滚动轴承监测，不能在齿轮箱或其他存在强背景噪音的设备上使用；传感器频响较低，对早中期故障不敏感。
                      </p>
                      <p class="tit_wrap">故障原因 </p>
                      <p class="tit_con">
                        <text>1.设备存在滚动轴承异常、部件碰摩等故障</text>
                        <text>2.设备存在工况导致的异常冲击</text>
                      </p>


                      <div class="grid_wrap">
                        <div class="grid_item">
                          <div>分数</div>
                          <div>建议客户操作</div>
                        </div>
                        <div class="grid_item">
                          <div>健康</div>
                          <div>注意设备运行时是否存在异响</div>
                        </div>
                        <div class="grid_item">
                          <div>异常</div>
                          <div>注意设备运行时是否存在异响，密切关注设备状态变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划</div>
                        </div>
                      </div>
                    </div>

                  </div>

                </div>


              </template>
              <QuestionCircleOutlined />
            </a-popover>


          </div>
          <div>FX-Bearing Factor</div>
        </div>
        <div class="flex-1"></div>
        <div>{{ getTextZc(inspectionBase.fx_code1) }}</div>

      </div>
      <div class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">100</div>
        </div>
        <div class="score-60">
          <div class="score-txt">80</div>
        </div>
        <div class="score-30">
          <div class="score-txt">60</div>
        </div>
        <div class="score-0">
          <div class="score-txt">30</div>
        </div>

        <div :style="{ right: inspectionBase.fx_code1_dis1 + '%', backgroundColor: getColor(inspectionBase.fx_code1) }"
          class="score-posi"></div>
      </div>
      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">FX冲击因子
            <a-popover title="FX冲击因子">
              <template #content>
                <div class="con_wrap">
                  <div v-if="inspectionBase.value?.reportInfo?.sensorModel > 21">

                    <div class="con_wrap">
                      <p class="tit_wrap">指标释义 </p>
                      <p class="tit_con">
                        监测电机、水泵、风机、离心压缩机、齿轮箱等旋转设备周期性冲击故障的指标。
                      </p>
                      <p class="tit_wrap">适用场景 </p>
                      <p class="tit_con">
                        对周期性冲击较为感敏，适用于电机、水泵、风机、离心压缩机、齿轮箱等旋转设备的监测，不能在存在强背景噪音的设备上使用。
                      </p>
                      <p class="tit_wrap">故障原因 </p>
                      <p class="tit_con">
                        <text>1.设备存在部件碰摩、滚动轴承异常、齿轮啮合不良等规律性冲击故障</text>
                        <text>2.设备存在工况导致的规律性冲击</text>
                      </p>


                      <div class="grid_wrap">
                        <div class="grid_item">
                          <div>分数</div>
                          <div>建议客户操作</div>
                        </div>
                        <div class="grid_item">
                          <div>80分以上</div>
                          <div>无需操作</div>
                        </div>
                        <div class="grid_item">
                          <div>70-80分</div>
                          <div>关注设备分数变化情况</div>
                        </div>
                        <div class="grid_item">
                          <div>60-70分</div>
                          <div>注意设备运行时是否存在异响，定期改善润滑，关注设备状态及分数变化情况</div>
                        </div>
                        <div class="grid_item">
                          <div>40-60分</div>
                          <div>注意设备运行时是否存在异响，密切关注设备状态及分数变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划</div>
                        </div>
                        <div class="grid_item">
                          <div>40分以下</div>
                          <div>及时安排检查，根据现场检查结果更新维护计划</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>

                    <div class="con_wrap">
                      <p class="tit_wrap">指标释义 </p>
                      <p class="tit_con">
                        监测电机、水泵、风机、离心压缩机、齿轮箱等旋转设备周期性冲击故障的指标。
                      </p>
                      <p class="tit_wrap">适用场景 </p>
                      <p class="tit_con">
                        对周期性冲击较为感敏，适用于电机、水泵、风机、离心压缩机、齿轮箱等旋转设备的监测，不能在存在强背景噪音的设备上使用；传感器频响较低，对早中期故障不敏感。
                      </p>
                      <p class="tit_wrap">故障原因 </p>
                      <p class="tit_con">
                        <text>1.设备存在部件碰摩、滚动轴承异常、齿轮啮合不良等规律性冲击故障</text>
                        <text>2.设备存在工况导致的规律性冲击</text>
                      </p>

                      <div class="grid_wrap">
                        <div class="grid_item">
                          <div>分数</div>
                          <div>建议客户操作</div>
                        </div>
                        <div class="grid_item">
                          <div>健康</div>
                          <div>注意设备运行时是否存在异响</div>
                        </div>
                        <div class="grid_item">
                          <div>异常</div>
                          <div>注意设备运行时是否存在异响，密切关注设备状态变化情况，并结合现场实际情况适时安排检查，根据现场检查结果更新维护计划</div>
                        </div>
                      </div>

                    </div>


                  </div>

                </div>


              </template>
              <QuestionCircleOutlined />
            </a-popover>
          </div>
          <div>FX-Impulsive Factor</div>
        </div>
        <div class="flex-1"></div>
        <div>{{ getTextCj(inspectionBase.fx_code2) }}</div>

      </div>
      <div class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">100</div>
        </div>
        <div class="score-60">
          <div class="score-txt">80</div>
        </div>
        <div class="score-30">
          <div class="score-txt">60</div>
        </div>
        <div class="score-0">
          <div class="score-txt">30</div>
        </div>

        <div :style="{ right: inspectionBase.fx_code1_dis2 + '%', backgroundColor: getColor(inspectionBase.fx_code2) }"
          class="score-posi"></div>
      </div>

      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['速度均方根'] }}
            <a-popover title="速度均方根">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">指标释义 </p>
                  <p class="tit_con">
                    速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。
                  </p>
                  <p class="tit_wrap">适用场景 </p>
                  <p class="tit_con">
                    覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。
                  </p>
                  <p class="tit_wrap">故障原因 </p>
                  <p class="tit_con">
                    <text>1.设备存在转子不平衡、转子不对中、机械松动等轴系故障</text>
                    <text>2.设备存在螺杆啮合不良、齿轮啮合不良等故障</text>
                    <text>3.设备存在轴瓦碰摩、轴瓦磨损等故障</text>
                    <text>4.设备存在工况导致的低频振动</text>



                  </p>
                </div>


              </template>
              <QuestionCircleOutlined />
            </a-popover>


            <a-select @change="bindPickerChange1" style="margin-left: 10px;" v-model:value="inspectionBase.directionIn"
              :placeholder="langObj['请选择']">
              <a-select-option v-for="(item, index) in inspectionBase.directionA" :key="index" :value="index">
                {{ item }}
              </a-select-option>
            </a-select>

          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code3_v.toFixed(4) }}mm/s</div>

      </div>

      <div v-if="!!inspectionBase.sdD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_30 || 0 }}</div>
        </div>

        <div :style="{ left: inspectionBase.fx_code3 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code3) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code3_v.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>



      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['加速度均方根'] }}
            <a-popover title="加速度均方根">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">指标释义 </p>
                  <p class="tit_con">
                    加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。
                  </p>
                  <p class="tit_wrap">适用场景 </p>
                  <p class="tit_con">
                    覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。
                  </p>
                  <p class="tit_wrap">故障原因 </p>
                  <p class="tit_con">
                    <text>1.设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障</text>
                    <text>2.设备存在轴承润滑不良、共振、电磁振动等故障</text>
                    <text>3.设备存在液力冲击、工况异常等故障</text>
                  </p>
                </div>


              </template>
              <QuestionCircleOutlined />
            </a-popover>



            <a-select @change="bindPickerChange2" style="margin-left: 10px;" v-model:value="inspectionBase.directionInB"
              :placeholder="langObj['请选择']">
              <a-select-option v-for="(item, index) in inspectionBase.directionA" :key="index" :value="index">
                {{ item }}
              </a-select-option>
            </a-select>

          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code4_v.toFixed(4) }}m/s²</div>

      </div>

      <div v-if="!!inspectionBase.jsdzD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_30 || 0 }}</div>
        </div>

        <div :style="{ left: inspectionBase.fx_code4 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code4) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code4_v.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>
      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['温度'] }}
            <!-- <QuestionCircleOutlined /> -->



          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code5_v.toFixed(4) }}℃</div>

      </div>
      <div v-if="!!inspectionBase.tempD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.tempD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.tempD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.tempD?.threshold_30 || 0 }}</div>
        </div>

        <div :style="{ left: inspectionBase.fx_code5 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code5) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code5_v.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>
      
     
      <template v-if="inspectionBase.isShowFFZ">
        <div class="flex w-full items-center mb-2">
          <div>
            <div class="flex items-center">{{ langObj['位移峰峰值'] }}
              <a-select @change="bindPickerChangeF" style="margin-left: 10px;"
                v-model:value="inspectionBase.directionInF" :placeholder="langObj['请选择']">
                <a-select-option v-for="(item, index) in inspectionBase.directionA" :key="index" :value="index">
                  {{ item }}
                </a-select-option>
              </a-select>

            </div>
            <div></div>
          </div>
          <div class="flex-1"></div>
          <div>{{ inspectionBase.fx_code6_v.toFixed(4) }}μm</div>
        </div>
        <div v-if="!!inspectionBase.ffz.threshold_80" class="flex w-full score-box mb-10">
          <div class="score-80">
            <div class="score-txt">0</div>
          </div>
          <div class="score-60">
            <div class="score-txt">{{ inspectionBase.ffz?.threshold_80 || 0 }}</div>
          </div>
          <div class="score-30">
            <div class="score-txt">{{ inspectionBase.ffz?.threshold_60 || 0 }}</div>
          </div>
          <div class="score-0">
            <div class="score-txt">{{ inspectionBase.ffz?.threshold_30 || 0 }}</div>
          </div>

          <div :style="{ left: inspectionBase.fx_code6 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code6) }"
            class="score-posi"></div>
        </div>
        <div v-else class="score-default  mb-10">
          <div class="score-txt">{{ inspectionBase.fx_code6_v.toFixed(4) }}</div>
          <div class="score-txt">0</div>
          <div class="score-posi"></div>
        </div>

      </template>
    </div>


    <div v-show="inspectionBase.reportInfoAll.type == 2">
      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['速度均方根'] + " Z" + getLanguage('轴', language) }}
            <a-popover :title="getLanguage('速度均方根', language)">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">{{ getLanguage('指标释义', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。', language) }}
                  </p>
                  <p class="tit_wrap">{{ getLanguage('适用场景', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。', language) }}
                  </p>
                  <p class="tit_wrap">{{ getLanguage('故障原因', language) }} </p>
                  <p class="tit_con">
                    <text>1.{{ getLanguage('设备存在转子不平衡、转子不对中、机械松动等轴系故障', language) }} </text>
                    <text>2.{{ getLanguage('设备存在螺杆啮合不良、齿轮啮合不良等故障', language) }}</text>
                    <text>3.{{ getLanguage('设备存在轴瓦碰摩、轴瓦磨损等故障', language) }}</text>
                    <text>4.{{ getLanguage('设备存在工况导致的低频振动', language) }}</text>
                  </p>
                </div>
              </template>
              <QuestionCircleOutlined />
            </a-popover>




          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code3_v.toFixed(4) }}mm/s</div>

      </div>

      <div v-if="!!inspectionBase.sdD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_30 || 0 }}</div>
        </div>

        <div :style="{ left: inspectionBase.fx_code3 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code3) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code3_v.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>

      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['速度均方根'] + " X" + getLanguage('轴', language) }}
            <a-popover :title="getLanguage('速度均方根', language)">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">{{ getLanguage('指标释义', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。', language) }}
                  </p>
                  <p class="tit_wrap">{{ getLanguage('适用场景', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。', language) }}
                  </p>
                  <p class="tit_wrap">{{ getLanguage('故障原因', language) }} </p>
                  <p class="tit_con">
                    <text>1.{{ getLanguage('设备存在转子不平衡、转子不对中、机械松动等轴系故障', language) }} </text>
                    <text>2.{{ getLanguage('设备存在螺杆啮合不良、齿轮啮合不良等故障', language) }}</text>
                    <text>3.{{ getLanguage('设备存在轴瓦碰摩、轴瓦磨损等故障', language) }}</text>
                    <text>4.{{ getLanguage('设备存在工况导致的低频振动', language) }}</text>
                  </p>
                </div>
              </template>
              <QuestionCircleOutlined />
            </a-popover>
          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code3_v1.toFixed(4) }}mm/s</div>

      </div>

      <div v-if="!!inspectionBase.sdD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_30 || 0 }}</div>
        </div>

        <div
          :style="{ left: inspectionBase.fx_code3_1 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code3_1) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code3_v1.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>

      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['速度均方根'] + ' Y' + getLanguage('轴', language) }}
            <a-popover :title="getLanguage('速度均方根', language)">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">{{ getLanguage('指标释义', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('速度均方根就是指频率10-1000Hz以内物体振动速度的均方根值，又名振动速度有效值或振动烈度。', language) }}
                  </p>
                  <p class="tit_wrap">{{ getLanguage('适用场景', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('覆盖故障类型广，如电机、水泵、风机、齿轮箱、螺杆压缩机、汽轮机等有对应速度均方根国标的设备，其他可使用通用国标或结合经验设定阈值的设备。', language) }}
                  </p>
                  <p class="tit_wrap">{{ getLanguage('故障原因', language) }} </p>
                  <p class="tit_con">
                    <text>1.{{ getLanguage('设备存在转子不平衡、转子不对中、机械松动等轴系故障', language) }} </text>
                    <text>2.{{ getLanguage('设备存在螺杆啮合不良、齿轮啮合不良等故障', language) }}</text>
                    <text>3.{{ getLanguage('设备存在轴瓦碰摩、轴瓦磨损等故障', language) }}</text>
                    <text>4.{{ getLanguage('设备存在工况导致的低频振动', language) }}</text>
                  </p>
                </div>
              </template>
              <QuestionCircleOutlined />
            </a-popover>
          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code3_v2.toFixed(4) }}mm/s</div>

      </div>

      <div v-if="!!inspectionBase.sdD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.sdD?.threshold_30 || 0 }}</div>
        </div>

        <div
          :style="{ left: inspectionBase.fx_code3_2 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code3_2) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code3_v2.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>
      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['加速度均方根'] + " Z" + getLanguage('轴', language) }}
            <a-popover title="加速度均方根">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">{{ getLanguage('指标释义', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。', language) }}

                  </p>
                  <p class="tit_wrap">{{ getLanguage('适用场景', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。', language) }}

                  </p>
                  <p class="tit_wrap">{{ getLanguage('故障原因', language) }} </p>
                  <p class="tit_con">
                    <text>1.{{ getLanguage('设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障', language) }}</text>
                    <text>2.{{ getLanguage('设备存在轴承润滑不良、共振、电磁振动等故障', language) }} </text>
                    <text>3.{{ getLanguage('设备存在液力冲击、工况异常等故障', language) }}</text>
                  </p>
                </div>
              </template>
              <QuestionCircleOutlined />
            </a-popover>
          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code4_v.toFixed(4) }}m/s²</div>

      </div>

      <div v-if="!!inspectionBase.jsdzD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_30 || 0 }}</div>
        </div>

        <div :style="{ left: inspectionBase.fx_code4 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code4) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code4_v.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>

      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['加速度均方根'] + " X" + getLanguage('轴', language) }}
            <a-popover title="加速度均方根">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">{{ getLanguage('指标释义', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。', language) }}

                  </p>
                  <p class="tit_wrap">{{ getLanguage('适用场景', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。', language) }}

                  </p>
                  <p class="tit_wrap">{{ getLanguage('故障原因', language) }} </p>
                  <p class="tit_con">
                    <text>1.{{ getLanguage('设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障', language) }}</text>
                    <text>2.{{ getLanguage('设备存在轴承润滑不良、共振、电磁振动等故障', language) }} </text>
                    <text>3.{{ getLanguage('设备存在液力冲击、工况异常等故障', language) }}</text>
                  </p>
                </div>
              </template>
              <QuestionCircleOutlined />
            </a-popover>

          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code4_v1.toFixed(4) }}m/s²</div>

      </div>

      <div v-if="!!inspectionBase.jsdzD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_30 || 0 }}</div>
        </div>

        <div
          :style="{ left: inspectionBase.fx_code4_1 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code4_1) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code4_v1.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>


      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['加速度均方根'] + " Y" + getLanguage('轴', language) }}
            <a-popover title="加速度均方根">
              <template #content>
                <div class="con_wrap">
                  <p class="tit_wrap">{{ getLanguage('指标释义', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('加速度均方根就是指1/2采样频率范围以内物体振动加速度的均方根值。', language) }}

                  </p>
                  <p class="tit_wrap">{{ getLanguage('适用场景', language) }} </p>
                  <p class="tit_con">
                    {{ getLanguage('覆盖故障类型广，如电机、水泵、风机、压缩机、齿轮箱等旋转设备和活塞压缩机等往复式设备，需结合经验设定阈值。', language) }}

                  </p>
                  <p class="tit_wrap">{{ getLanguage('故障原因', language) }} </p>
                  <p class="tit_con">
                    <text>1.{{ getLanguage('设备存在滚动轴承异常、部件碰摩、齿轮啮合不良、螺杆啮合不良等故障', language) }}</text>
                    <text>2.{{ getLanguage('设备存在轴承润滑不良、共振、电磁振动等故障', language) }} </text>
                    <text>3.{{ getLanguage('设备存在液力冲击、工况异常等故障', language) }}</text>
                  </p>
                </div>
              </template>
              <QuestionCircleOutlined />
            </a-popover>
          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code4_v2.toFixed(4) }}m/s²</div>

      </div>

      <div v-if="!!inspectionBase.jsdzD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.jsdzD?.threshold_30 || 0 }}</div>
        </div>

        <div
          :style="{ left: inspectionBase.fx_code4_2 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code4_2) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code4_v2.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>







      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['温度'] }}
            <!-- <QuestionCircleOutlined /> -->



          </div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>{{ inspectionBase.fx_code5_v.toFixed(4) }}℃</div>

      </div>
      <div v-if="!!inspectionBase.tempD.threshold_80" class="flex w-full score-box mb-10">
        <div class="score-80">
          <div class="score-txt">0</div>
        </div>
        <div class="score-60">
          <div class="score-txt">{{ inspectionBase.tempD?.threshold_80 || 0 }}</div>
        </div>
        <div class="score-30">
          <div class="score-txt">{{ inspectionBase.tempD?.threshold_60 || 0 }}</div>
        </div>
        <div class="score-0">
          <div class="score-txt">{{ inspectionBase.tempD?.threshold_30 || 0 }}</div>
        </div>

        <div :style="{ left: inspectionBase.fx_code5 + '%', backgroundColor: getColorLeft(inspectionBase.fx_code5) }"
          class="score-posi"></div>
      </div>
      <div v-else class="score-default  mb-10">
        <div class="score-txt">{{ inspectionBase.fx_code5_v.toFixed(4) }}</div>
        <div class="score-txt">0</div>
        <div class="score-posi"></div>
      </div>




    </div>

    <div
      v-if="inspectionBase.isShowFFTTIME && inspectionBase.reportInfoAll.type == 2 || inspectionBase.reportInfoAll.type == 1">
      <div class="flex w-full items-center mb-2">

        <div>
          <div class="flex items-center">{{ langObj['时域图'] }}</div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>
          <a-select @change="bindPickerChange3" style="margin-left: 10px;" v-model:value="inspectionBase.directionInC"
            :placeholder="langObj['请选择']">
            <a-select-option v-for="(item, index) in inspectionBase.directionA" :key="index" :value="index">
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div id="chartB" style="height:300px"></div>

      <div class="flex w-full items-center mb-2">
        <div>
          <div class="flex items-center">{{ langObj['幅值图'] }}</div>
          <div></div>
        </div>
        <div class="flex-1"></div>
        <div>
          <a-select @change="bindPickerChange4" style="margin-left: 10px;" v-model:value="inspectionBase.directionInD"
            :placeholder="langObj['请选择']">
            <a-select-option v-for="(item, index) in inspectionBase.directionA" :key="index" :value="index">
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div id="chartC" style="height:300px"></div>
    </div>




    <div class="w-full mb-2 mt-2">{{ langObj['现场情况'] }}</div>
    <div class="w-full ">{{ langObj['备注'] }}：</div>
    <div class="w-full mb-2">{{ inspectionBase.reportInfo?.remark }}</div>
    <div class="w-full mb-2">{{ langObj['现场照片'] }}</div>
    <div class="flex" style="flex-wrap: wrap;">
      <div style="width:100px;height: 100px;overflow: hidden;margin-right: 10px;margin-bottom: 10px;"
        v-for="(item, index) in inspectionBase.reportInfo?.pictures" :key="index">
        <div class="w-full text-center">{{ item.remark }}</div>
        <a-image class="w-full" :src="item.url" :alt="item.remark" />
      </div>
    </div>

  </div>



</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  defineProps
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import * as echarts from "echarts";
import {
  getGroupListByFactory,
  getGroupName,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";

import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];

let typeLists = [
  { label: "基础", value: 2 },
  { label: "智能", value: 1 },
];


const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()

let isShowRander = ref(true)  //是否在有效期


const bindPickerChange1 = (e: any) => {

  inspectionBase.value.directionIn = e;
  let zz = "Z";
  if (e == 0) {
    zz = "Z"
  } else if (e == 1) {
    zz = "X";
  } else {
    zz = "Y";
  }

  inspectionBase.value.fx_code3_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == zz)?.value || 0;

  inspectionBase.value.fx_code3 = YZCOM(inspectionBase.value.sdD, inspectionBase.value.fx_code3_v);


};

const bindPickerChange2 = (e: any) => {

  inspectionBase.value.directionInB = e;
  let zz = "Z";
  if (e == 0) {
    zz = "Z"
    inspectionBase.value.fx_code4_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 112)?.value || 0;
  } else if (e == 1) {
    zz = "X";
    inspectionBase.value.fx_code4_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == zz)?.value || 0;
  } else {
    zz = "Y";
    inspectionBase.value.fx_code4_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == zz)?.value || 0;
  }



  inspectionBase.value.fx_code4 = YZCOM(inspectionBase.value.jsdzD, inspectionBase.value.fx_code4_v);


};
const bindPickerChangeF = (e: any) => {

  inspectionBase.value.directionInF = e;
  let zz = "Z";
  if (e == 0) {
    zz = "Z"
  } else if (e == 1) {
    zz = "X";
  } else {
    zz = "Y";
  }

  inspectionBase.value.fx_code6_v = inspectionBase.value.reportInfo?.wkstatus?.find((it: any) => it.code == 308 && it.coordinate == zz)?.value || 0;

  inspectionBase.value.fx_code6 = YZCOM(inspectionBase.value.ffz, inspectionBase.value.fx_code6_v);


};



const bindPickerChange3 = (e: any) => {



  inspectionBase.value.directionInC = e;
  let url = "";

  if (e == 0) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Z")?.url
  } else if (e == 1) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "X")?.url
  } else if (e == 2) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Y")?.url
  }
  if (document.getElementById("chartB")) {
    let myChart = echarts.init(document.getElementById("chartB"));
    myChart && myChart.clear()
  }
  if (!url) {



    return false
  }

  getFftTime(url);


};


const bindPickerChange4 = (e: any) => {

  inspectionBase.value.directionInD = e;
  let url = "";

  if (e == 0) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Z")?.url
  } else if (e == 1) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "X")?.url
  } else if (e == 2) {
    url = inspectionBase.value.reportInfo.waves?.find((tt: any) => tt.coordinate == "Y")?.url
  }
  if (document.getElementById("chartC")) {
    let myChart = echarts.init(document.getElementById("chartC"));
    myChart && myChart.clear()
  }

  if (!url) {

    return false
  }

  getFft(url);


};

let inspectionBase: any = ref({
  reportInfo: {},
  isShowFFTTIME: false,
  isShowFFZ: false,
  reportInfoAll: {
    type: 2,
  },
  sensorId: "",
  machineInfo: {},
  fx_code1: 0,
  fx_code1_dis1: 0,
  fx_code2: 0,
  fx_code1_dis2: 0,
  fx_code3: 0,
  fx_code3_1: 0,
  fx_code3_2: 0,
  fx_code4: 0,
  fx_code4_1: 0,
  fx_code4_2: 0,
  fx_code5: 0,
  fx_code6: 0,
  fx_code3_v: 0,
  fx_code3_v1: 0,
  fx_code3_v2: 0,
  fx_code4_v: 0,
  fx_code4_v1: 0,
  fx_code4_v2: 0,
  fx_code5_v: 0,
  fx_code6_v: 0,
  jsdzD: {},
  sdD: {},
  tempD: {},
  ffz: {},
  directionA: ["z轴", "x轴", "y轴"],
  directionIn: 0,
  directionInB: 0,
  directionInC: 0,
  directionInD: 0,
  directionInF: 0,
  fx_code4_v_x: 0,
  fx_code4_v_y: 0,
  fx_code3_xy: 0,
  fx_code3_v_y: 0,
  resultTIME: {},
  resultFFT: {},
  listT: [],
  reportType: 1
})
let props = defineProps({
  reportIdObj: {
    type: Object,
    default: {}
  }
})

let reportIdObj: any = ref({})
watch(
  () => props.reportIdObj,
  (newValue, oldValue) => {
    reportIdObj.value = props.reportIdObj

    getSensorList()

  }
);
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];






const stateColumns = [
  {
    title: langObj.value["故障模式"],
    dataIndex: "text",
  },
  {
    title: langObj.value["分数"],
    dataIndex: "score",
  },

];


let groupList: any = ref([]);


onMounted(() => {

  // isEditable.value = true
  //   loadDatatype();

  //   getGroupList();
  //   search();
  
  inspectionBase.value.isShowFFZ =  localStorage.getItem('isShowFFZ') || false
  console.log(1111)

});

const clearData = () => {
  if (document.getElementById("chartA")) {
    let myChart = echarts.init(document.getElementById("chartA"));
    myChart && myChart.clear()
  }
  if (document.getElementById("chartB")) {
    let myChart = echarts.init(document.getElementById("chartB"));
    myChart && myChart.clear()
  }
  if (document.getElementById("chartC")) {
    let myChart = echarts.init(document.getElementById("chartC"));
    myChart && myChart.clear()
  }


  inspectionBase.value = {
    ...inspectionBase.value,
    reportInfo: {},
    // machineInfo: {},
    fx_code1: 0,
    fx_code1_dis1: 0,
    fx_code2: 0,
    fx_code1_dis2: 0,
    fx_code3: 0,
    fx_code3_1: 0,
    fx_code3_2: 0,
    fx_code4: 0,
    fx_code4_1: 0,
    fx_code4_2: 0,
    fx_code5: 0,
    fx_code6: 0,
    fx_code3_v: 0,
    fx_code3_v1: 0,
    fx_code3_v2: 0,
    fx_code4_v: 0,
    fx_code4_v1: 0,
    fx_code4_v2: 0,
    fx_code5_v: 0,
    fx_code6_v: 0,
    jsdzD: {},
    sdD: {},
    tempD: {},
    ffz: {},
    directionA: ["z轴", "x轴", "y轴"],
    directionIn: 0,
    directionInB: 0,
    directionInC: 0,
    directionInD: 0,
    directionInF: 0,
    fx_code4_v_x: 0,
    fx_code4_v_y: 0,
    fx_code3_xy: 0,
    fx_code3_v_y: 0,
    resultTIME: {},
    resultFFT: {},
    listT: [],


  }
}



const getReportInfo = (id: any) => {

  clearData()
  
  inspectionBase.value.reportInfoAll = { type: 2 }


  if (!id) {
    return false;
  }

  const config: any = {
    params: {
      id: id,

    },
    headers: {
      requestId: uuidv4(),
    },

  };
  request.get("/api/external/inspection/info", config).then((res: any) => {
    if (res) {


      inspectionBase.value.reportInfoAll = res.data;


      if (sensorList.value.length == 1) {
        infoFun(res.data);
      } else {
        let res2 = res.data.inspectionInfoList?.find((tt: any) => tt.sensorId == inspectionBase.value.sensorId)
        infoFun(res2)
      }


    }
  });
};


const infoFun = (res: any) => {
  inspectionBase.value.reportInfo = res;
  inspectionBase.value.directionIn = 0;
  inspectionBase.value.directionInB = 0;
  inspectionBase.value.directionInF = 0;
  let url = inspectionBase.value.reportInfo?.waves?.find((v: any) => v.coordinate == "Z")?.url
  getFaultName(inspectionBase.value.reportInfo)
  getSCoreV()
  if (!url) {
    inspectionBase.value.isShowFFTTIME = false
    inspectionBase.value.resultTIME = [];
    initChartBC(inspectionBase.value.resultTIME, 'chartB', 's', 'm/s²')
    inspectionBase.value.resultFFT = [];
    initChartBC(inspectionBase.value.resultFFT, 'chartC', 'hz', 'm/s²');
    return false
  }
  inspectionBase.value.isShowFFTTIME = true

  getFftTime(url);
  getFft(url)
}


const getFftTime = (url: any) => {

  const config: any = {
    params: {
      url: [url],

    },

    headers: {
      requestId: uuidv4(),
    },

  };
  request.post("/draw/api/time", { url: [url] }).then((res: any) => {
    if (res && res.code == 200) {
      let axisX = res?.data?.axisX || []
      if (axisX.length > 0) {
        inspectionBase.value.isShowFFTTIME = true
      } else {
        inspectionBase.value.isShowFFTTIME = false
      }


      inspectionBase.value.resultTIME = res.data;
      initChartBC(inspectionBase.value.resultTIME, 'chartB', 's', 'm/s²')

    } else {
      inspectionBase.value.isShowFFTTIME = false
    }
  });
};
const getFft = async (url: any) => {


  const config: any = {
    params: {
      url: [url],
    },

    headers: {
      requestId: uuidv4(),
    },
  };
  request.post("/draw/api/fft", { url: [url] }).then((res: any) => {
    if (res && res.code == 200) {
      let axisX = res?.data?.axisX || []
      if (axisX.length > 0) {
        inspectionBase.value.isShowFFTTIME = true
      } else {
        inspectionBase.value.isShowFFTTIME = false
      }
      inspectionBase.value.resultFFT = res.data;
      initChartBC(inspectionBase.value.resultFFT, 'chartC', 'hz', 'm/s²')
    } else {
      inspectionBase.value.isShowFFTTIME = false
    }
  });
};


// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};
const sensorList: any = ref([])
//获取测点数据
const getSensorList = async () => {


  inspectionBase.value.sensorId = ""
  sensorList.value = [];
  let senSearch = {
    params: { machineId: reportIdObj.value.machineId },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/sensors', senSearch)

  let sensorList1 = result?.data || []
  if (reportIdObj.value?.sensorIds) {

    reportIdObj.value?.sensorIds?.map((s: any) => {
      sensorList.value.push(sensorList1.find((tt: any) => tt.id == s));
    })
  }
  if (reportIdObj.value?.sensorId) {

    sensorList.value.push(sensorList1.find((tt: any) => tt.id == reportIdObj.value?.sensorId));
  }


  if (sensorList.value.length > 0) {
    let validityEnd = sensorList.value[0]?.validityEnd
    if (validityEnd && new Date(validityEnd).getTime() > new Date().getTime()) {
      isShowRander.value = true
    } else {
      isShowRander.value = false
    }
    inspectionBase.value.sensorId = sensorList.value[0].id
  } else {
    isShowRander.value = true
  }
  getReportInfo(reportIdObj.value.id)



}

const handleChangeS = (e: any) => {

  if (e) {
    clearData()
    inspectionBase.value.sensorId = e;
    let res = inspectionBase.value.reportInfoAll?.inspectionInfoList?.find((tt: any) => tt.sensorId == inspectionBase.value.sensorId)

    infoFun(res)
  }
}



// 获取报告健康状态
const getReportCondition = (ev: any) => {
  if (ev && ev.condition) {
    return conditionList.find(
      (p: any) => p.value === ev.condition
    );
  } else {
    return conditionList[0];
  }
};




const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};










const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};






const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const getDis = (fx_code: any) => {
  if (fx_code == 100) {
    return 93
  } else if (fx_code == 0) {
    return 0
  } else {
    return fx_code - 4;
  }
};
const getSCoreV = () => {
  inspectionBase.value.fx_code1 = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 301)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 301)[0].value : 0;
  inspectionBase.value.fx_code1_dis1 = getDis(inspectionBase.value.fx_code1);
  inspectionBase.value.fx_code2 = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 302)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 302)[0].value : 0;
  inspectionBase.value.fx_code1_dis2 = getDis(inspectionBase.value.fx_code2)
  let code3_val = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == "Z")?.value || 0;
  inspectionBase.value.fx_code3_v = code3_val;
  let code3_val1 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == "X")?.value || 0;
  inspectionBase.value.fx_code3_v1 = code3_val1;
  let code3_val2 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 107 && it.coordinate == "Y")?.value || 0;
  inspectionBase.value.fx_code3_v2 = code3_val2;
  //112 z轴 高频 101 xy轴 低频
  let code4_val = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 112)?.value || 0;
  inspectionBase.value.fx_code4_v = code4_val;
  let code4_val1 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == 'X')?.value || 0;
  inspectionBase.value.fx_code4_v1 = code4_val1;
  let code4_val2 = inspectionBase.value?.reportInfo?.wkstatus?.find((it: any) => it.code == 101 && it.coordinate == 'Y')?.value || 0;
  inspectionBase.value.fx_code4_v2 = code4_val2;
  let code5_val = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 100)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 100)[0].value : 0;
  inspectionBase.value.fx_code5_v = code5_val;

  let code6_val = inspectionBase.value.reportInfo?.wkstatus?.filter((it: any) => it.code == 308)[0] ? inspectionBase.value.reportInfo?.wkstatus
    .filter((it: any) => it.code == 308)[0].value : 0;
  inspectionBase.value.fx_code6_v = code6_val;

  let jsdz = null,
    sd = null,
    ffz = null,
    temp = null;
  if (inspectionBase.value.reportInfo?.threshold) {
    jsdz = inspectionBase.value.reportInfo?.threshold?.find((p: any) => p.code == 112) || {};
    inspectionBase.value.jsdzD = jsdz;
    sd = inspectionBase.value.reportInfo?.threshold?.find((item: any) => item.code == 107) || {};
    inspectionBase.value.sdD = sd;
    temp = inspectionBase.value.reportInfo?.threshold?.find((item: any) => item.code == 100) || {};
    inspectionBase.value.tempD = temp;
    ffz = inspectionBase.value.reportInfo?.threshold?.find((item: any) => item.code == 308) || {};
    inspectionBase.value.ffz = ffz;
    inspectionBase.value.fx_code3 = YZCOM(sd, code3_val);
    inspectionBase.value.fx_code3_1 = YZCOM(sd, code3_val1);
    inspectionBase.value.fx_code3_2 = YZCOM(sd, code3_val2);
    inspectionBase.value.fx_code4 = YZCOM(jsdz, code4_val);
    inspectionBase.value.fx_code4_1 = YZCOM(jsdz, code4_val1);
    inspectionBase.value.fx_code4_2 = YZCOM(jsdz, code4_val2);
    inspectionBase.value.fx_code5 = YZCOM(temp, code5_val);
    inspectionBase.value.fx_code6 = YZCOM(ffz, code6_val);
  }
};

const getFaultName = (info: any) => {
  if (!info?.machineId) {
    return false
  }
  const config: any = {
    params: {
      machineId: info.machineId
    },
    headers: {
      requestId: uuidv4(),
    },

  };
  request.get("/api/machines/faultnames", config).then((res: any) => {
    if (res) {
      // inspectionBase.value.faultName = res.data.faultNames;
      // inspectionBase.value.faultNameII = res.data.faultNameExpects;
      inspectionBase.value.reportInfo.vibrationLabel = {};
      inspectionBase.value.reportInfo.healthLabel = {};

      if (res.data?.faultNameExpects.length > 0) {
        res.data.faultNameExpects.map((d: any) => {
          inspectionBase.value.reportInfo.healthLabel[d.column] = d.title
        })
        getRandarData()
      }
      if (!res.data.faultNameExpects && info.inspectionSubId || res.data.faultNameExpects?.length == 0 && info.inspectionSubId) {
        getFaultName2(info.inspectionSubId)
      }





    }
  });
};

const getFaultName2 = (id: any) => {
  const config: any = {
    params: {
      id: id
    },
    headers: {
      requestId: uuidv4(),
    },

  };
  request.get("/api/external/inspection/faultNames", config).then((res: any) => {
    if (res) {
      // inspectionBase.value.faultName = res.data.faultNames;
      // inspectionBase.value.faultNameII = res.data.faultNameExpects;
      inspectionBase.value.reportInfo.vibrationLabel = {};
      inspectionBase.value.reportInfo.healthLabel = {};

      if (res.data.faultNameExpects) {
        res.data.faultNameExpects.map((d: any) => {
          inspectionBase.value.reportInfo.healthLabel[d.column] = d.title
        })
        getRandarData()
      }


      // getRandarData()


    }
  });
};


const getRandarData = () => {
  inspectionBase.value.listT = []
  let statusInfo = inspectionBase.value.reportInfo;
  let healthLabel = statusInfo.healthLabel;

  let scoreList = statusInfo.inspectionScoreFinalPartsExpertFit;

  // let changeCondition = 1;

  let listS = [];
  let Slist = [];
  let Tlist = [];

  // 处理数据
  // for (let index in scoreList2) {
  // 	const item = scoreList2[index];
  // 	if (index.startsWith('S')) {
  // 		Slist.push({
  // 			quota: index,
  // 			score: item,
  // 			text: vibrationLabel[index] || '',
  // 		})
  // 	}

  // }


  for (let index in scoreList) {
    const item2 = scoreList[index];
    if (index.includes('T')) {
      Tlist.push({
        quota: index,
        score: item2,
        text: healthLabel[index] || '',
      })
    }

  }

  inspectionBase.value.listT = Tlist;
  if (Tlist.length > 0) {

    initChart(Tlist, 'chartA')

  }


};


// 雷达图绘制
const initChart = (dataList: any, id: any) => {
  if (!document.getElementById("chartA")) return
  let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

  // if (!dataList.length) return

  let data: any = []
  let error: any = []
  let indicator: any = []

  dataList.map((d: any) => {
    data.push(d.score)
    error.push(60)
    let color = ''
    if (d.score >= 0 && d.score < 60) {
      color = '#CD5F3B'
    }
    if (d.score >= 60 && d.score < 80) {
      color = '#D2DE49'
    }
    if (d.score >= 80 && d.score <= 100) {
      color = '#A6A6A6'
    }

    indicator.push({
      color,
      max: 100,
      text: d.text.length > 4 ? (d.text.substring(0, 4) + '\n' + d.text.substring(4, d
        .text.length)) : d.text,
    })
  })


  const option = {
    // grid: {
    // 	right: '19%',
    // 	left: '10%',
    // 	top: '10%',
    // 	bottom: '0%'
    // },
    title: {
      text: ''
    },
    tooltip: {
      // position: ['20%', '0%']
      confine: true
    },
    radar: [{
      indicator: indicator,
      center: ['50%', '50%'],
      radius: 70,
      nameGap: 1,
      startAngle: 90,
      splitNumber: 5,
      name: {
        formatter: '{value}',
        textStyle: {
          color: 'rgba(46, 214, 157, 1)',
          fontSize: 13
        }
      },
      splitArea: {
        areaStyle: {
          color: [
            '#605969',
            '#453A47',
          ]
        }
      },
      axisLine: {
        lineStyle: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      splitLine: {
        lineStyle: {
          color: [
            'rgb(241,243,250)'
          ],
          width: 0
        }
      }
    }],
    series: [{
      name: '当前值',
      type: 'radar',
      emphasis: {
        lineStyle: {
          width: 4
        }
      },
      symbolSize: 2, // 拐点的大小
      data: [{
        value: data,
        lineStyle: {
          width: 1,
          color: 'rgba(247,220,225,0.1)',
          normal: {
            type: 'dotted'
          }
        },
        itemStyle: {
          color: '#FF4E13',
          borderWidth: 5
        },
        areaStyle: {
          color: '#F7DCE1'
        }
      }]
    },
    {
      name: '预警值',
      type: 'radar',
      emphasis: {
        lineStyle: {
          width: 1
        }
      },
      symbolSize: 2, // 拐点的大小
      data: [{
        value: error,
        name: '',
        lineStyle: {
          width: 2,
          color: 'rgba(255, 25, 59, 0.2)'
        },
        itemStyle: {
          color: 'rgba(255, 25, 59, 0.2)',
          borderWidth: 0
        }
      }]
    }
    ]
  }
  myChart.setOption(option)

};





const initChartBC = (data: any, id: any, xUnit: any, yUnit: any) => {
  if (!document.getElementById(id)) return
  let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);
  myChart.clear()

  if (!data) {

    return false
  }

  let axisX = data?.axisX?.map((tt: any) => Number(tt.toFixed(4))) || [];
  let axisY = data?.axisY?.map((tt: any) => Number(tt.toFixed(4))) || [];

  let options = {
    color: theme.value == 'white' ? ["#274CE0"] : ["#00FFF4"],
    grid: {
      left: "4%",
      top: '5%',
      right: "10%",
      containLabel: true
    },

    tooltip: {
      trigger: 'axis',
      // position: ['10%', '0%'],
      axisPointer: {
        type: 'cross'
      }
    },
    toolbox: {
      trigger: 'axis'
    },
    dataZoom: [{
      id: "dataZoomX",
      type: "slider",
      xAxisIndex: [0],
      filterMode: 'filter',
      realtime: false, //拖动时，是否实时更新系列的视图
    },
    { type: "inside" }
    ],
    xAxis: {
      name: xUnit,
      type: 'category',

      axisTick: {
        alignWithLabel: true
      },
      axisLabel: {
        showMaxLabel: true,
        rotate: 30
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3
        }
      },
      data: axisX
    },
    yAxis: {
      name: yUnit,
      type: 'value',
      axisLabel: {
        width: '60', // 将内容的宽度固定
        overflow: 'truncate', // 超出的部分截断
        truncate: '...', // 截断的部分用...代替
      }
    },
    series: [{
      data: axisY,
      type: 'line',
      // sampling: 'average', //降采样策略
    }]
  };
  myChart.setOption(options);


};


const YZCOM = (obj: any, value: any) => {
  if (!value) {
    return 0
  }
  if (!obj) {
    return {}
  }
  if (!obj.threshold_80) {
    return false
  }
  if (!obj.threshold_60) {
    return false
  }
  if (!obj.threshold_30) {
    return false
  }
  if (value > 0 && value <= obj.threshold_80) {
    let a = obj.threshold_80 - 0;
    if ((value / a) * 20 >= 18) {
      return (value / a) * 20 - 7
    } else {
      return (value / a) * 20
    }

  }
  if (value > obj.threshold_80 && value <= obj.threshold_60) {
    let a = obj.threshold_60 - obj.threshold_80;
    let b = value - obj.threshold_80
    if ((b / a) * 20 >= 18) {
      return (b / a) * 20 + 20 - 7
    } else {
      return (b / a) * 20 + 20
    }

  }
  if (value > obj.threshold_60 && value <= obj.threshold_30) {
    let a = obj.threshold_30 - obj.threshold_60;
    let b = value - obj.threshold_60
    if ((b / a) * 30 >= 25) {
      return (b / a) * 30 + 40 - 7
    } else {
      return (b / a) * 30 + 40
    }

  }
  if (value > obj.threshold_30) {
    return 70 + generateRandomNumber()
  }
};

const getTextZc = (score: any) => {
  // 轴承因子分数在85分以上    无异常，85分以下   有异常
  if (inspectionBase.value.reportInfo?.sensorModel) {
    if (inspectionBase.value.reportInfo.sensorModel == 21) {
      if (!score) {
        return 0 + "分"
      }
      if (score >= 85) {
        return "健康"
      } else {
        return "异常"
      }
    } else {
      return score + "分"
    }
  } else {
    return "--"
  }



};
const getTextCj = (score: any) => {
  if (inspectionBase.value.reportInfo?.sensorModel) {

    if (inspectionBase.value.reportInfo.sensorModel == 21) {
      if (!score) {
        return 0 + "分"
      }

      if (score >= 82) {
        return "健康"
      } else {
        return "异常"
      }
    } else {
      return score + "分"
    }
  } else {
    return "--"
  }


};



const generateRandomNumber = () => {
  return Math.floor(Math.random() * 23); // 生成 0 到 23 之间的随机整数
};
const getColorFxZc = (score: any) => {
  if (!score) {
    return "#1CC48B"
  }
  let color = '#1CC48B'
  if (score >= 85) {
    color = '#1CC48B'
  } else {
    color = '#B81212'
  }
  return color
};
const getColorFxCj = (score: any) => {
  if (!score) {
    return "#1CC48B"
  }
  let color = '#1CC48B'
  if (score >= 82) {
    color = '#1CC48B'
  } else {
    color = '#B81212'
  }
  return color
};

const getColor = (score: any) => {
  let color = '#1CC48B'
  if (score >= 80 && score <= 100) {
    color = '#1CC48B'
  }
  if (score >= 60 && score < 80) {
    color = '#DDD246'
  }
  if (score >= 30 && score < 60) {
    color = '#CB6A34'
  }
  if (score >= 0 && score < 30) {
    color = '#B81212'
  }

  return color
};
const getColorLeft = (score: any) => {

  if (!score) {
    return '#1CC48B'
  }
  let color = '#1CC48B'
  if (score >= 70 && score <= 100) {
    color = '#B81212'
  }
  if (score >= 40 && score < 70) {
    color = '#CB6A34'
  }
  if (score >= 20 && score < 40) {
    color = '#DDD246'
  }
  if (score >= 0 && score < 20) {
    color = '#1CC48B'
  }

  return color
};





</script>

<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

.size20 {
  font-size: 20px;
}

.score-box {
  // margin-top: 20px;
  position: relative;

  .score-txt {
    position: absolute;
    top: 20px;
    left: -5px;
    font-size: 12px;
    color: #9a9ba0;
  }

  .score-80 {
    background: #1CC48B;
    width: 20%;
    height: 13px;
    border-radius: 6px 0 0 6px;
    position: relative;
  }

  .score-60 {
    background: #DDD246;
    width: 20%;
    height: 13px;
    position: relative;
  }

  .score-30 {
    background: #CB6A34;
    width: 30%;
    height: 13px;
    position: relative;
  }

  .score-0 {
    background: #B81212;
    width: 30%;
    height: 13px;
    border-radius: 0 6px 6px 0;
    position: relative;
  }

  .score-posi {
    position: absolute;
    right: 88%;
    top: -6px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #1CC48B;
    box-shadow: 0 0 2px 0 #00000040 inset;
  }
}

.score-default {
  margin-top: 10px;
  background: #072499;
  width: 100%;
  height: 13px;
  border-radius: 6px;
  position: relative;

  .score-txt {
    position: absolute;
    top: 20px;

    font-size: 12px;
    color: #9a9ba0;

    &:nth-of-type(1) {
      left: 0px;
    }

    &:nth-of-type(2) {
      right: 0px;
    }

  }

  .score-posi {
    position: absolute;
    right: 50%;
    top: -6px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #072499;
    box-shadow: 0 0 2px 0 #00000040 inset;
  }
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 0px solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}

.con_wrap {
  // width: 688.46rpx;
  // background-color: #fff;
  border-radius: 10rpx;
  box-sizing: border-box;
  padding: 19rpx 30rpx;
  margin: 0 auto;

}

.grid_wrap {
  // width: 630rpx;
  // border: 1px solid #ccc;
  margin: 0 auto;
  margin-top: 10rpx;

  .grid_item {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    margin: 10px 0;

    div:nth-of-type(1) {
      width: 80px;
    }

    div:nth-of-type(2) {
      flex: 1;
    }
  }
}


.tit_wrap {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 26.92rpx;
  font-style: normal;
  font-weight: 700;
}

.tit_con {
  color: #fff;
  font-family: "PingFang SC";
  font-size: 26.92rpx;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 38.46rpx;
  margin-top: 15.38rpx;

  text {
    display: block;
  }
}
</style>