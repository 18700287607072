import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "w-full px-5 py-6 bule_list_page theme-common" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]

import request from "../../common/request";
import { ref, reactive, toRaw, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { operateLogs } from "@/common/logs";
import { getLanguage } from "../../common/translate";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
interface Props {
    edit?: boolean 
}


export default /*@__PURE__*/_defineComponent({
  __name: 'bearing',
  props: {
    edit: { type: Boolean, default: false }
  },
  setup(__props: any) {

let language: any =  localStorage.getItem('language') || 'Chinese'

const props = __props

let isEdit:any = props.edit

const columns = [
    {
        title: getLanguage('型号'),
        dataIndex: 'bearingModel',
    },
    {
        title: getLanguage('滚子数'),
        dataIndex: 'rollersNum',
    },
    {
        title: getLanguage('保持架'),
        dataIndex: 'crf',
    },
    {
        title: getLanguage('滚子或滚柱'),
        dataIndex: 'bsf',
    },
    {
        title: getLanguage('外环'),
        dataIndex: 'bpfo',
    },
    {
        title: getLanguage('内环'),
        dataIndex: 'bpfi',
    },
    {
        title: getLanguage('备注'),
        dataIndex: 'remark',
    },
    {
        title: getLanguage('转频'),
        dataIndex: 'keyFre',
    },
    {
        title: getLanguage('保持架频率'),
        dataIndex: 'crfFt',
    },
    {
        title: getLanguage('滚子或滚柱频率'),
        dataIndex: 'bsfFt',
    },
    {
        title: getLanguage('外环频率'),
        dataIndex: 'bpfoFt',
    },
    {
        title: getLanguage('内环频率'),
        dataIndex: 'bpfiFt',
    },
    {
        title: getLanguage('操作'),
        dataIndex: 'act',
    },
]
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
    bearingModel: '',
})
let dataList:any = ref([])
let visible = ref(false)
let form:any = ref({})

onMounted(() => {
    init()
})

const init = () => {
    console.log('lc', location)
    getData()
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const getData = async() => {
    let config = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            bearingModel: searchModel.value.bearingModel,
        },
        headers: {
            requestId: uuidv4(),
        }
    }

    let result = await request.get('/api/bearing-parameters', config)
    console.log('result', result)
    dataList.value = result?.data?.list || []
    searchModel.value.total = result?.data?.count || 0

}

const handleTableChange = (ev:any) => {
    searchModel.value.skip = ev.current
    searchModel.value.take = ev.pageSize
    getData()
}

// 新增
const add = () => {
    form.value = {}
    visible.value = true
}

// 编辑
const edit = (ev:any) => {
    form.value = JSON.parse(JSON.stringify(ev))
    visible.value = true
}


// 提交
const submit = async() => {
    let result = form.value.id ? await request.put('/api/bearing-parameters', form.value)
                                :await request.post('/api/bearing-parameters', form.value)

    console.log('result', result)
    if(result?.data) { 
        message.success('操作成功!')
        visible.value = false
        getData()
    } else {
        message.warning('操作失败!')
    }
}


// 删除
const del = async(ev:any) => {
    let config = {
        params: {
            id: ev.id
        },
        headers: {
            requestId: uuidv4(),
        }
    }

    let result =  await request.delete('/api/bearing-parameters', config)
    if(result?.data) { 
        message.success('操作成功!')
        getData()
    } else {
        message.warning('操作失败!')
    }
}


return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_form, { layout: "inline" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('轴承型号')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _unref(searchModel).bearingModel,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).bearingModel) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, { label: "" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: search
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_SearchOutlined)
                  ]),
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)('搜索')), 1)
                  ]),
                  _: 1
                }),
                (_unref(isEdit))
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      onClick: add,
                      class: "ml-4",
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(getLanguage)('新增')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_table, {
          bordered: "",
          columns: columns,
          "data-source": _unref(dataList),
          pagination: pagination.value,
          onChange: handleTableChange
        }, {
          bodyCell: _withCtx(({ column, text, record }) => [
            (column.dataIndex === 'act')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_unref(isEdit))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createElementVNode("a", {
                          onClick: ($event: any) => (edit(record))
                        }, "编辑", 8, _hoisted_3),
                        _createVNode(_component_a_divider, { type: "vertical" }),
                        _createVNode(_component_a_popconfirm, {
                          title: "确定删除此条数据么，删除后无法恢复?",
                          "ok-text": "是",
                          "cancel-text": "否",
                          onConfirm: ($event: any) => (del(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, { type: "link" }, {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createTextVNode("删除")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["onConfirm"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["data-source", "pagination"]),
        _createVNode(_component_a_modal, {
          visible: _unref(visible),
          "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
          title: _unref(form).id ? '编辑' : '新增',
          maskClosable: false,
          width: "800px",
          onOk: submit
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: "轴承型号",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(form).bearingModel,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(form).bearingModel) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "滚子数" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(form).rollersNum,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(form).rollersNum) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "保持架" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).crf,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(form).crf) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "滚子或滚柱" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).bsf,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(form).bsf) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "外环" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).bpfo,
                      "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(form).bpfo) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "内环" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).bpfi,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(form).bpfi) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "备注" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(form).remark,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(form).remark) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "转频" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).keyFre,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(form).keyFre) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "保持架频率" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).crfFt,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(form).crfFt) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "滚子或滚柱频率" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).bsfFt,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(form).bsfFt) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "外环频率" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).bpfoFt,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(form).bpfoFt) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, { label: "内环频率" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input_number, {
                      style: {"width":"100%"},
                      value: _unref(form).bpfiFt,
                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(form).bpfiFt) = $event)),
                      controls: false
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["visible", "title"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})