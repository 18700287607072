import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","background-color":"#000A32","overflow-y":"scroll","padding-bottom":"30px","color":"#fff"},
  class: "theme-common"
}
const _hoisted_2 = { class: "box" }
const _hoisted_3 = {
  class: "w-full theme-box-title",
  style: {"border-bottom":"1px solid #495985"}
}
const _hoisted_4 = {
  class: "flex items-center px-5",
  style: {"font-size":"18px"}
}
const _hoisted_5 = { class: "w-full p-5" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 2 }

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList } from "../../common/tools";
import { useRouter } from "vue-router";
import request from "../../common/request";
// import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import DiagnosticReportComponent from "./DiagnosticReport.vue";
import MaintenanceReportComponent from "./MaintenanceReport.vue";
import WeekReportComponent from "./WeekReport.vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { getMemberOnboarding } from "../../common/tools";

export default /*@__PURE__*/_defineComponent({
  __name: 'list',
  setup(__props) {

let menuSelect:any = ref(1)

const selectMenu = (ev:any) => {
    menuSelect.value = ev
}
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
onMounted(() => {
    init()

    //新注册用户，未经过引导的需弹窗进行引导
    if(!getMemberOnboarding()){
        selectMenu(3);
    }
})

const init = () => {
    getCount()
    if(localStorage.getItem('report_type')) {
        menuSelect.value = JSON.parse(localStorage.getItem('report_type') || '1')
        localStorage.removeItem('report_type')
    }
}
let counts:any = ref({
  "count1": 0,
  "count2": 0,
  "count3": 0
})
// 获取计数统计
const getCount = async() => {
    let config: any = {
        params: {
            factoryIds: getRealFactoryIdList()
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/external/reports/count', config)
    counts.value = result.data
}

return (_ctx: any,_cache: any) => {
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["py-3 cursor-pointer", {'txt-select': _unref(menuSelect) === 1}]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1)))
              }, _toDisplayString(_unref(langObj)['诊断报告']) + "(" + _toDisplayString(_unref(counts).count1) + ")", 3),
              _createElementVNode("div", {
                class: _normalizeClass(["py-3 cursor-pointer ml-24", {'txt-select': _unref(menuSelect) === 2}]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2)))
              }, _toDisplayString(_unref(langObj)['检修报告']) + "(" + _toDisplayString(_unref(counts).count2) + ")", 3),
              _createElementVNode("div", {
                class: _normalizeClass(["py-3 cursor-pointer ml-24", {'txt-select': _unref(menuSelect) === 3}]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMenu(3)))
              }, _toDisplayString(_unref(langObj)['周报']) + "(" + _toDisplayString(_unref(counts).count3) + ")", 3)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_unref(menuSelect) === 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(DiagnosticReportComponent)
                ]))
              : _createCommentVNode("", true),
            (_unref(menuSelect) === 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(MaintenanceReportComponent)
                ]))
              : _createCommentVNode("", true),
            (_unref(menuSelect) === 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(WeekReportComponent)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})