import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cardHead" }
const _hoisted_2 = { style: {"position":"relative","top":"4px","left":"5px"} }
const _hoisted_3 = {
  class: "main whiteBg",
  style: {"max-height":"1450px","overflow-y":"scroll"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_node_index_outlined = _resolveComponent("node-index-outlined")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_card, {
      class: "whiteBg",
      bordered: false
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_node_index_outlined),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.dataSource.sensorPlace), 1)
        ])
      ]),
      extra: _withCtx(() => [
        _createVNode(_component_a_radio_group, { size: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: "c",
              onClick: _ctx.copy
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("复制")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_radio_group, { size: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: "c",
              onClick: _ctx.pasteOverlay
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("粘贴")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_radio_group, { size: "default" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_radio_button, {
              value: "c",
              onClick: _ctx.editSave
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("保存")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        bordered: "",
        emptyText: '暂无数据',
        columns: _ctx.columns,
        "data-source": _ctx.dataSource.table,
        pagination: false
      }, {
        bodyCell: _withCtx(({ column, text, record }) => [
          (column.dataIndex === 'enable')
            ? (_openBlock(), _createBlock(_component_a_switch, {
                key: 0,
                checked: record.enable,
                "onUpdate:checked": ($event: any) => ((record.enable) = $event),
                "checked-children": "开",
                "un-checked-children": "关"
              }, null, 8, ["checked", "onUpdate:checked"]))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level1Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level1RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level1RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[3] || (_cache[3] = _createTextVNode(" 笔内累计 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level1LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level1LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[4] || (_cache[4] = _createTextVNode(" 笔大于阈值: ")),
                _createVNode(_component_a_input_number, {
                  value: record.level1Threshold,
                  "onUpdate:value": ($event: any) => ((record.level1Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[5] || (_cache[5] = _createTextVNode(" 触发报警 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level1IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level1IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[6] || (_cache[6] = _createTextVNode(" 天 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level1IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level1IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[7] || (_cache[7] = _createTextVNode(" 次 "))
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level2Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level2RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level2RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[8] || (_cache[8] = _createTextVNode(" 笔内累计 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level2LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level2LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[9] || (_cache[9] = _createTextVNode(" 笔大于阈值: ")),
                _createVNode(_component_a_input_number, {
                  value: record.level2Threshold,
                  "onUpdate:value": ($event: any) => ((record.level2Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[10] || (_cache[10] = _createTextVNode(" 触发报警 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level2IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level2IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[11] || (_cache[11] = _createTextVNode(" 天 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level2IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level2IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[12] || (_cache[12] = _createTextVNode(" 次 "))
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level3Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                _createVNode(_component_a_input_number, {
                  value: record.level3RangeCount,
                  "onUpdate:value": ($event: any) => ((record.level3RangeCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[13] || (_cache[13] = _createTextVNode(" 笔内累计 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level3LimitCount,
                  "onUpdate:value": ($event: any) => ((record.level3LimitCount) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[14] || (_cache[14] = _createTextVNode(" 笔大于阈值: ")),
                _createVNode(_component_a_input_number, {
                  value: record.level3Threshold,
                  "onUpdate:value": ($event: any) => ((record.level3Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[15] || (_cache[15] = _createTextVNode(" 触发报警 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level3IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level3IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[16] || (_cache[16] = _createTextVNode(" 天 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level3IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level3IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[17] || (_cache[17] = _createTextVNode(" 次 "))
              ], 64))
            : _createCommentVNode("", true),
          (column.dataIndex === 'level4Threshold')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _cache[18] || (_cache[18] = _createTextVNode(" 阈值: ")),
                _createVNode(_component_a_input_number, {
                  value: record.level4Threshold,
                  "onUpdate:value": ($event: any) => ((record.level4Threshold) = $event),
                  controls: false,
                  style: {"width":"70px"},
                  size: "small",
                  min: 0
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[19] || (_cache[19] = _createTextVNode(" , ")),
                _createVNode(_component_a_input_number, {
                  value: record.level4IntervalDays,
                  "onUpdate:value": ($event: any) => ((record.level4IntervalDays) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[20] || (_cache[20] = _createTextVNode(" 天 ")),
                _createVNode(_component_a_input_number, {
                  value: record.level4IntervalTimes,
                  "onUpdate:value": ($event: any) => ((record.level4IntervalTimes) = $event),
                  controls: false,
                  style: {"width":"45px"},
                  size: "small",
                  min: 1
                }, null, 8, ["value", "onUpdate:value"]),
                _cache[21] || (_cache[21] = _createTextVNode(" 次 "))
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["columns", "data-source"])
    ])
  ]))
}