<template>
   <a-config-provider :locale="language === 'English' ? enUS : zhCN">
  <div class="theme-common">
    <a-layout-content style="padding: 0 20px;height: 100%">
      <a-row type="flex" style="height: 100%">
        <a-col :span="5" :order="1" style="height: 100%">
          <div
            class="w-64 px-2 overflow-y-auto scroll-box h-screen code-box"
            style="height: 100%"
          >
            <a-input-search
              v-model:value="filterKeyWord"
              style="margin-bottom: 8px"
              :placeholder="langObj['输入区域或设备名称筛选']"
            />
            <a-tree
              class="draggable-tree"
              draggable
              block-node
              :autoExpandParent="autoExpandParent"
              :expanded-keys="expandedKeys"
              :tree-data="treeData"
              @expand="onExpand"
              @select="selectTree"
            >
              <template #title="{ title }">
                <span v-if="title.indexOf(filterKeyWord) > -1">
                  {{ title.substr(0, title.indexOf(filterKeyWord)) }}
                  <span style="color: #f50">{{ filterKeyWord }}</span>
                  {{
                    title.substr(
                      title.indexOf(filterKeyWord) + filterKeyWord.length
                    )
                  }}
                </span>
                <span v-else>{{ title }}</span>
              </template>
            </a-tree>
          </div>
        </a-col>
        <a-col :span="19" :order="2">
          <div v-for="(item, index) in sensorsSource" :key="item.sensorId"> 
            <dcp-item  :threshold="item" :baseModel="baseModelSource" :key="item.sensorId" :deviceId="currentMachine.key"  @search="search"></dcp-item>
          </div>
          <div v-if="!sensorsSource || !sensorsSource.length">
            <a-alert :message="langObj['未选择设备或没有配置测点']" type="warning" show-icon />
          </div>
        </a-col>
      </a-row>
    </a-layout-content>
  </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { onMounted } from "vue";
import { ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import DcpItem from './common/DcpItem.vue';
import { getRealFactoryIdListById } from "../../common/tools";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { langList } from "../../common/lang";
let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
        language.value = localStorage.getItem('language') || 'Chinese'
        langObj.value = langList[language.value]
    }
    getLang()
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
operateLogs('访问参数配置'); 
/**操作日志记录 */


const sensorsSource = ref<any>([]);

// 查询
const search = async() => {
  const config = {
    params: { id: currentMachine.value.key },
    headers: {
      requestId: uuidv4(),
    },
  };
  var result = await request.get("/api/machines/parameter", config);
  if (result) {
   
    const sConfig = {
      params: { machineId: currentMachine.value.key },
      headers: {
        requestId: uuidv4(),
      },
    };
    var sResult = await request.get("/api/sensors", sConfig);

    if(sResult){
      var sensorsSources:any = []

      sResult.data.map((d:any) => {
        let resultInfo = result?.data.find((p:any) => p.sensorId === d.id)
        resultInfo.machineIdList = [d.machineId]
        sensorsSources.push(resultInfo)
      })

      // result.data.forEach((item: any) => {
      //   item.machineIdList = [item.machineId];
      //   if(sResult.data.filter((t:any)=>t.id ==item.sensorId).length){
      //     sensorsSources.push(item)
      //   }
      // });
      sensorsSource.value = sensorsSources
    }
  } else {
    sensorsSource.value = [];
  }
};

/* 左侧区域-设备 数据加载 */
// 在组件加载完成后执行一些操作
let currentMachine = ref<any>();
const selectTree = (key: any, val: any) => {
  if (val.node.type == "machine") {
    currentMachine.value = val.node;
    search();
  }
};



let filterKeyWord = ref<any>();
let treeData = ref<any>();

const onExpand = (keys: string[]) => {
  expandedKeys.value = keys;
  autoExpandParent.value = false;
};
const expandedKeys = ref<(string | number)[]>([]);
const autoExpandParent = ref<boolean>(true);

watch(filterKeyWord, () => {
  expandedKeys.value = [];
  sonKey(treeData.value, "");
  autoExpandParent.value = true;
});
const sonKey = (item: any) => {
  item.forEach((element: any) => {
    if (element.title.indexOf(filterKeyWord.value) > -1) {
      // expandedKeys.value.push(key);
      expandedKeys.value.push(element.key);
    }
    if (element.children && element.children.length) {
      sonKey(element.children, element.key);
    }
  });
};
const getParentKey = (
  key: string | number,
  tree: any
): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (
        node.children.some((item: { key: string | number }) => item.key === key)
      ) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

// 加载model基础数据
const baseModelSource = ref([]);
const loadBaseModelSource = () => {
  const config = {
    params: { type: 'Component' },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/machines/parameter/configs", config).then((res: any) => {
    if (res) {
      res.data=res.data.map((t:any)=>({...t,key:t.id}))
      baseModelSource.value = res.data
    }
  });
};
loadBaseModelSource()


//***角色车间***//
onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
});

let factoryList = ref<any>();
let allFactory:any = ref([])
const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory:any = [];
      let parentFs = list.filter((p:any) => !p.parentId)
      parentFs.map((d:any) => {
        let sonFactory = list.filter((p:any) => p.parentId === d.id)
        if(sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })
      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
const loadMachine = async(requestId: any) => {
  let factoryIds = allFactory.value.map((p:any) => p._id)
  let res = await request.post("/api/machines/all", {factoryIds})
  factoryList.value.forEach((element: any) => {
    if (res) {
        element.children.forEach((sonFact: any) => {
          if (sonFact.type == "factory" && sonFact.children) {
            sonFact.children.forEach((groupFact: any) => {
              if (groupFact.type == "group") {
                groupFact.children = res.data
                  .filter((t: any) => t.groupId == groupFact.value)
                  .map((t: any) => ({
                    type: "machine",
                    ...t,
                    value: t.id,
                    label: t.machineName,
                    title: t.machineName,
                    key: t.id,
                  }));
              }
            });
          } else if (sonFact.type == "group") {
            sonFact.children = res.data
              .filter((t: any) => t.groupId == sonFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
          }
        });
      }
  });
  setTimeout(() => {
    treeData.value = factoryList.value
  }, 100);
};
</script>
<style scoped>
.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #0d53b7cc;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
  max-height: 100%;
}
/deep/ .ant-card-head{
  padding-left: 0px;
}
.cardHead{
  color:#1890ff;
  /* border-left:3px solid  #1890ff; */
  padding-left: 0px;
  font-size: 18px;
}
.main {
  margin-top: 30px;
}
</style>