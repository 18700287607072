<template>
   <a-config-provider :locale="language === 'English' ? enUS : zhCN">
  <div class="theme-common">
    <a-layout-content style="padding: 0 40px">
      <div class="top">
        <a-button type="primary" @click="add" class="btn">
          <template #icon><plus-outlined /></template>{{langObj['新增']}}</a-button>
      </div>
      <a-table style="margin-top: 10px; " bordered :columns="columns" :dataSource="WashList" :pagination="false"
        :loading="loading" size="small">
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'act'">
            <a-popconfirm :title="langObj['确定删除此条数据么']+'?'" :ok-text="langObj['确定']" :cancel-text="langObj['取消']" @confirm="deleteThis(record)">
              <a-button type="primary" danger><delete-outlined />{{langObj['删除']}}</a-button>
            </a-popconfirm>
            <a-button type="primary" class="plft" @click="editThis(record)"><edit-outlined />{{langObj['编辑']}}</a-button>
          </template>

          <template v-if="column.dataIndex == 'filters'">
            <div v-for="(item, index) in record.filters" :key="index">
              <span>[ {{ item.start }} - {{ item.stop }} ]</span>
            </div>
            <div v-show="record.filters == ''">
              <span> [null , null] </span>
            </div>
          </template>

          <template v-if="column.dataIndex == 'machineIds'">
              {{ getMachineName(text) }}
          </template>

          <template v-if="column.dataIndex == 'filterType'">
              {{ filterTypeList.find(p => p.value === text)?.label }}
          </template>
          
          <template v-if="column.dataIndex == 'dataType'">
              {{ dataTypeList.find(p => p.value === text)?.label }}
          </template>
        </template>
      </a-table>
      <!-- 新增清洗配置 -->
      <a-modal :width="850" v-model:visible="modalState.visible" :title="modalState.title"
        :confirm-loading="modalState.confirmLoading" :maskClosable="false" @ok="handleOk">
        <a-form :model="formMenu" ref="formStateRef" name="basic" autocomplete="off"
          :label-col="{ style: { width: '130px' } }">
          <a-form-item :label="langObj['设备']" name="machineIds" @change="clearValidate('machineName')" :rules="[
            { required: true, message: langObj['请选择'], trigger: 'blur' },
          ]">
            <!-- <a-input-search disabled v-model:value="formMenu.machineName" :placeholder="langObj['请选择']">
              <template #enterButton>
                <a-button type="primary" @click="choose">{{langObj['选择']}}</a-button>
              </template>
            </a-input-search> -->
            <a-tree-select
              v-model:value="formMenu.machineIds"
              style="width: 100%"
              :tree-data="treeData"
              tree-checkable
              allow-clear
              search-placeholder="请选择设备"
            />
          </a-form-item>
          <a-form-item :label="langObj['切片数']" name="slices" :rules="[
            { required: true, message: langObj['请输入切片数'], trigger: 'blur' },
          ]">
            <a-input-number style="width: 100%;" v-model:value="formMenu.slices">
            </a-input-number>
          </a-form-item>
          <a-form-item :label="langObj['数据类型']" name="dataType" :rules="[
            { required: true, message: langObj['请选择数据类型'], trigger: 'blur' },
          ]">
            <a-select ref="select" :options="numberType" v-model:value="formMenu.dataType" :placeholder="langObj['请选择']"
              @change="clearValidate('dataType')" allowClear>
              <a-select-option v-for="item in dataTypeList" :key="item.value" :value="item.value">{{ item.label
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="langObj['滤波方式']" name="filterType">
            <a-select ref="select" :options="numberType" v-model:value="formMenu.filterType" :placeholder="langObj['请选择']"
              @change="clearValidate('filterType')" allowClear>
              <a-select-option v-for="item in filterTypeList" :key="item.value">{{ item.label }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item style="display: flex; justify-content: space-between;" :label="langObj['滤波频段']" name="filters"
            layout="vertical">
            <a-row>
              <a-col :span="8" :offset="8"> <a-button type="link" @click="addRow">{{langObj['新增行']}}</a-button></a-col>
            </a-row>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 25, offset: 6 }">
            <div style="margin-bottom: 10px;" v-for="(item, index) in formMenu.filters" :key="index">
              <!-- {{ item.start }} -----{{ item.stop }} -->
              <a-input-number v-model:value="item.start" style="width: 180px;" />
              <span style="color: #fff ">-</span>
              <a-input-number v-model:value="item.stop" style="width: 180px;" />
              <span style="font-size: large;margin-left: 13px;" @click="deleteRow(index)">
                <CloseCircleOutlined style="color: #fff !important" />
              </span>
            </div>
          </a-form-item>
        </a-form>
      </a-modal>
      <!-- 选择设备的模态框 -->
      <a-modal :width="900" v-model:visible="deviceModal.visible" :title="deviceModal.title" :maskClosable="false"
        :footer="null">
        <a-form layout="inline" :maskClosable="false" v-model:visible="deviceFormState.visible"
          :title="deviceFormState.title" :confirm-loading="deviceFormState.confirmLoading">
          <a-form-item>
            <span style="color:#fff">{{langObj['全部设备']}}({{dataSource.length}})</span>
          </a-form-item>
          <a-form-item>
            <a-row>
              <a-col :span="12"><a-input v-model:value="deviceFormState.keyWord" :placeholder="langObj['搜索设备信息']"
                  class="ipt" /></a-col>
              <a-col :span="10"> <a-button type="primary" @click="search">
                  <template #icon>
                    <SearchOutlined />
                  </template>{{langObj['搜索']}}</a-button></a-col>
            </a-row>
          </a-form-item>
        </a-form>
        <a-table bordered :emptyText="'暂无数据'" :columns="deviceColumns" :data-source="dataSource" :pagination="false"
          :loading="loading">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'act'">
              <a-button type="link" @click="chooseOk(record)">{{langObj['选择']}}</a-button>
            </template>
          </template>
        </a-table>
      </a-modal>
    </a-layout-content>
  </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { ref, reactive, toRaw } from "vue";
import { computed, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { icons } from "../../common/icons";
import { message } from "ant-design-vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { number } from "vue-types";
import { getGroupName } from "../../common/tools";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

import { langList } from "../../common/lang";
let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
        language.value = localStorage.getItem('language') || 'Chinese'
        langObj.value = langList[language.value]
    }
    getLang()


operateLogs('访问数据清洗');
const router = useRouter();
let loading = ref(false);
const formStateRef = ref<any>();
const dataSource = ref([]);
let machineList:any = ref([])

/**操作日志记录 */
const deviceFormState = reactive({
  keyWord: "",
  groupId: "",
  groupIds: [],
  skip: 1,
  take: 10,
});

// 新增的模态框
const modalState = reactive({
  visible: false,
  title: langObj.value["数据清洗配置"],
  confirmLoading: false,
  roleName: "",
  meun: [],
});

let numberType = ref<any>()
let formMenu: any = ref({
  enable: true,
  machineName: "",
  slices: "",
  dataType: "",
  filterType: "",
  filters: [],
  machineId: "",
  factoryId: "",
  machineIds: [],
});
// 选择设备的模态框
const deviceModal = reactive({
  visible: false,
  title: langObj.value["选择设备"],
  confirmLoading: false,
})
// 选择设备
const choose = () => {
  deviceModal.title = langObj.value["选择设备"];
  deviceModal.visible = true;
};

const formState = reactive({
  keyWork: "",
  skip: 1,
  take: 10,
});
let WashList = ref([]);
// 表格
const columns = [
  {
    title:langObj.value["设备名称"],
    dataIndex: "machineIds",
    align: "center",
  },
  {
    title: langObj.value["切片数"],
    dataIndex: "slices",
    align: "center",
  },
  {
    title: langObj.value["数据类型"],
    dataIndex: "dataType",
    align: "center",
  },
  {
    title: langObj.value["滤波方式"],
    dataIndex: "filterType",
    align: "center",
  },
  {
    title: langObj.value["滤波频段"],
    dataIndex: "filters",
    align: "center",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
    align: "center",
    width: "260px"
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["数据清洗配置"];
  formMenu.value = {
    slices: "",
    dataType: "",
    filterType: "",
    filters: [],
    machineId: "",
    machineIds: [],
    factoryId: ""
  }
  filtersList.value = []
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};

let filtersList: any = ref([])

const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
// 编辑
const editThis = async (val: any) => {
  modalState.title =  langObj.value["编辑"];
  formMenu.value = JSON.parse(JSON.stringify(val))

  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};

// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let postForm = {
        ...formMenu.value,
        factoryId,
      }

      if (postForm.date || postForm.id) {
        // return
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.put("/api/single-wash", postForm, config).then((res) => {
          if (res) {
            operateLogs('修改数据，数据名称：' + postForm.machineName + ",id:" + postForm.id);
            modalState.visible = false;
            message.success(langObj.value["操作成功"]);
            getWashList()
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/single-wash", postForm, config).then((res) => {
          if (res) {
            operateLogs('新增数据，数据名称：' + postForm.machineName);
            formMenu.value.machineId = res.data
            modalState.visible = false;
            message.success(langObj.value["操作成功"]);
            getWashList()
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};



// 选择设备的表格数据
const deviceColumns = [
  {
    title: langObj.value['设备名称'],
    dataIndex: "machineName"
  }, {
    title: langObj.value['设备区域'],
    dataIndex: "groupId"
  }, {
    title: langObj.value['安装位置'],
    dataIndex: "position"
  }, {
    title: langObj.value['操作'],
    dataIndex: "act",
  },
]
let factoryId = localStorage.getItem('factory_id') || '';
let treeData:any = ref([])
// 查询
const search = async() => {
  const config = {
    params: {
      factoryId: factoryId,
      keyWord: deviceFormState.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  // request.get("/api/machines", config).then((res: any) => {
  //   if (res) {
  //     dataSource.value = res.data;
  //   }
  // });

  let result = await request.get("/api/machines", config)
  if(result && result.data) {
    machineList.value = result.data
    let groupIds = result.data.map((d:any) => d.groupId)
    groupIds = [...new Set(groupIds)]
    treeData.value = []
    let list:any = []
    groupIds.map((g:any) => {
      let first = {
        title: getGroupName(g),
        value: g,
        key: g,
        children: [],
      }
      let machineList = result.data.filter((p:any) => p.groupId == g)
      let second = machineList.map((d:any) => ({
        title: d.machineName,
        value: d.id,
        key: d.id
      }))
      first.children = second
      list.push(first)
    })
   

    treeData.value = list
  }

 

};

// 选择设备
const chooseOk = (record: any) => {
  formMenu.value.machineName = record.machineName
  formMenu.value.factoryId = record.factoryId
  formMenu.value.machineId = record.id
  deviceModal.visible = false;
  getWashList()
}


// 数据类型
const dataTypeList = [
  { label: "HZ与LZ", value: 1 },
  { label: "HZ", value: 2 },
  { label: "LZ", value: 3 },
]
// 滤波方式
const filterTypeList = [
  { label: langObj.value['带通滤波'], value: 'pass' },
  { label: langObj.value['带阻滤波'], value: 'stop' },
]

// 新增行
const addRow = () => {
  formMenu.value.filters.push({})
}

// 删除当前行
const deleteRow = (index: number) => {
  formMenu.value.filters.splice(index, 1)
}
// 删除数据
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id, date: val.date },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/single-wash", config).then((res) => {
    if (res) {
      operateLogs('删除设备，设备名称：' + val.machineName + ",id:" + val.id);
      message.success(langObj.value["操作成功"]);
      getWashList()
    } else {
    }
  });
};

// 获取表格数据
const getWashList = async () => {
  const config = {
    params: { factoryId: factoryId},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/single-wash', config)
  // WashList.value = result.data.map((item: any) => {
  //   return {
  //     ...item,
  //     dataType: convertQuantityToText(item.dataType),
  //     filterType: item.filterType == 'pass' ? '带通滤波' : '带阻滤波',
  //   }
  // })

  WashList.value = []

  if(result?.data?.length) {
    let dates = result.data.filter((d:any) => d.date).map((p:any) => p.date)
    dates = [ ... new Set(dates)]
    let list1:any = []
    let list2:any = result.data.filter((p:any) => !p.date)
    list2.map((d:any) => {
      d.machineIds = [d.machineId]
      delete d.machineId
    })
    dates.map((d:any) => {
      let list = result.data.filter((p:any) => p.date == d)
      let machineIds = list.map((p:any) => p.machineId)
      list1.push({
        machineIds,
        slices: list[0].slices,
        factoryId: list[0].factoryId,
        dataType: list[0].dataType,
        filterType: list[0].filterType,
        filters: list[0].filters,
        updateAt: list[0].updateAt,
        date: d,
      })
    })

    WashList.value = list1.concat(list2)
  }
}

const convertQuantityToText = (dataType: number) => {
  if (dataType == 1) {
    return 'HZ与LZ'
  } else if (dataType == 2) {
    return 'HZ'
  } else {
    return 'LZ'
  }
}
// 在组件加载完成后执行一些操作
onMounted(async () => {
  getWashList()
  search();
});

const getMachineName = (ev:any) => {
  let names:any = []
  ev.map((d:any) => {
    let info = machineList.value.find((p:any) => p.id === d)
    if(info) {
      names.push(info.machineName)
    }
  })
  return  names.join(' ， ')
}



</script>

<style lang="less" scoped>
.top {
  display: flex;
  justify-content: right;
}

form.ant-form.ant-form-horizontal {
  padding-right: 150px;
}

.ant-col-8 {
  max-width: 11.333333%;
}

.ant-row {
  justify-content: flex-end;
}

.ant-form-inline {
  justify-content: space-between;
}

.ipt {
  width: 248px;
}
</style>
