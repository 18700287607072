import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import '@/assets/driverjs/theme.css';
import { Modal } from 'ant-design-vue';
import request from "./request";


const driverObj = driver({
    popoverClass: 'register-driver',        // 封装driver.js弹窗的类名
    animate: true,                    // 是否进行动画
    opacity: 0.75,                    // 背景不透明度(0表示只有弹窗，没有覆盖层)
    padding: 10,                      // 元素到边缘的距离
    allowClose: false,                 // 点击覆盖层是否应该关闭
    overlayClickNext: false,          // 下一步点击覆盖层是否应该移动
    keyboardControl: false,            // 允许通过键盘控制(esc键关闭，箭头键移动)
    showButtons:  [
        'next',
        'previous',
        'close'
    ],// 在页脚显示控制按钮
    doneBtnText: '开始使用',          // final按钮文本
    closeBtnText: '跳过',            // 关闭按钮文本
    nextBtnText: '下一步',        // 下一个按钮文本
    prevBtnText: '上一步',    // 上一个按钮文本
    stageBackground: '#ffffff'       // 高亮元素背后的舞台背景颜色
});

const highlightStep = (step) => {
    driverObj.highlight(step);
}

const getPopover = (elname, title, description, prevBtnText, side, align, next, prev, islast) => {
    let showButtonsArr = ['close'];
    if(next || islast){
        showButtonsArr.push('next');
    }
    if(prev){
        showButtonsArr.push('previous');
    }
    return ({
    element: elname,        // 要突出显示的查询选择器字符串或节点
    stageBackground: '#ffffff',   // 这将覆盖在驱动程序中设置的
    popover: {                    // 如果为空或未指定弹窗，则不会有弹窗
        className: 'popover-class', // 除了驱动程序选项中的一般类名外，还要包装这个特定步骤弹出窗口
        title: title,       // popover 标题
        description:description, // popover 描述
        showButtons: showButtonsArr,
        closeBtnText: '跳过',      // 关闭按钮文本
        nextBtnText: islast ? '开始使用':'下一步',        // 下一个按钮文本
        prevBtnText: prevBtnText,    // 上一个按钮文本
        side:side,
        align:align,
        onNextClick: () => {
            if(islast){
                skip_done_pop(false);
                return;
            }
            if(next){
                next();
                return;
            }
        },// 从当前步骤移动到下一步时调用
        onPrevClick: () => {
            if(prev){
                prev();
                return;
            }
        },// 从当前步骤移动到上一步时调用
        onCloseClick: () =>{
            skip_done_pop(true);
            return;
        }//关闭
    }
})}
//跳过/结束新用户引导
const skip_done_pop = async(isclose) => {
    Modal.confirm({
        title: isclose ? '是否跳过新用户引导？':'是否结束新用户引导？',
        okText: '确定',
        cancelText: '取消',
        wrapClassName:'ant-modal-re',
        zIndex:1000000003,
        async onOk() {
            //更新新注册用户引导状态
            let result2 = await request.get("/api/member/endboarding");
            localStorage.setItem("memberInfo", JSON.stringify(result2?.data));
            //关闭引导窗
            driverObj.destroy();
        },
        onCancel() {
            
        },
    });
}

export {
    highlightStep,
    getPopover
}