import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = {
  class: "box w-full whiteBg",
  style: {"width":"100%"}
}
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "theme-table-link" }
const _hoisted_5 = { class: "w-full items-center box" }
const _hoisted_6 = {
  style: {"height":"calc(100% - 10px)"},
  class: "mt-2 overflow-y-auto"
}
const _hoisted_7 = { class: "flex-1 text-center mt-2" }
const _hoisted_8 = { class: "w-full items-center box" }
const _hoisted_9 = {
  style: {"height":"calc(100% - 10px)"},
  class: "mt-2 overflow-y-auto"
}
const _hoisted_10 = { class: "flex-1 text-center mt-2" }

import { onMounted, ref } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'group-model',
  props: ['dataTypes'],
  setup(__props) {

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();



let tempModelQuery = ref({
  begin: "",
  end: "",
  machineTypeId: null,
  dataType: null,
});

onMounted(() => {
  loadMachineTypes();
});

const dateRange = ref<[Dayjs, Dayjs] | []>([]);
const dates = ref<any>();
const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

let machineTypes: any = ref([]);

let loadMachineTypes = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-type/all", config);
  if (result) {
    machineTypes.value = result.data;
  }
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

let unUseSensorIds: any = ref([]);
let useSensorIds: any = ref([]);

let useSensorIdsOld: any = ref([]);
let unUseSensors: any = ref([]);
let useSensors: any = ref([]);

let selectUnUseSensor = () => {
};

let selectUseSensor = () => {
};

const tempColumns = [
  {
    title: "模板号",
    dataIndex: "version",
  },
  {
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["设备类型"],
    dataIndex: "machineTypeName",
  },
  {
    title: langObj.value["数据类型"],
    dataIndex: "dataTypesName",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

let selectSimpleModel: any = ref({});
let models = ref([]);
let loadTempSimpleList = async () => {
  // clear
  useSensorIdsOld = []
  useSensors.value = [];
  unUseSensors.value = [];
  useSensorIds.value = [];
  unUseSensorIds.value = [];
  
  if (dateRange.value && dateRange.value.length == 2) {
    tempModelQuery.value.begin = dateRange.value[0].toISOString();
    tempModelQuery.value.end = dateRange.value[1].toISOString();
  } else {
    tempModelQuery.value.begin = "";
    tempModelQuery.value.end = "";
  }

  let config: any = {
    params: {
      machineType: tempModelQuery.value.machineTypeId,
      dataType: tempModelQuery.value.dataType,
      factoryId: localStorage.getItem("factory_id"),
      begin: tempModelQuery.value.begin,
      end: tempModelQuery.value.end,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/group-model", config);
  if (result) {
    models.value = result.data;
  }
};

let deleteSimpleModel = async (record: any) => {
  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/quota/group-model", config).then((res) => {
    if (res) {
      message.success("操作成功");
      loadTempSimpleList();
    } else {
    }
  });
};

let loadBingInfos = async (record: any) => {
  selectSimpleModel.value = record;

  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/group-model/bind-infos", config);
  if (result) {
    const groupedData: any = {};
    const groupedDataUn: any = {};

    result.data.binds.map((item: any) => {
      if (!groupedData[item.groupId]) {
        groupedData[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedData[item.groupId].machines[item.machineId]) {
        groupedData[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedData[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    useSensorIds.value = result.data.binds.map((p: any) => p.sensorId);
    useSensorIdsOld.value = useSensorIds.value;

    useSensors.value = Object.keys(groupedData).map((groupId) => ({
      key: groupId,
      title: groupedData[groupId].groupName,
      checkable: false,

      children: Object.keys(groupedData[groupId].machines).map((machineId) => ({
        key: machineId,
        title: groupedData[groupId].machines[machineId].machineName,
        checkable: false,
        children: groupedData[groupId].machines[machineId].sensors,
      })),
    }));

    result.data.unBinds?.map((item: any) => {
      if (!groupedDataUn[item.groupId]) {
        groupedDataUn[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedDataUn[item.groupId].machines[item.machineId]) {
        groupedDataUn[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedDataUn[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    const treeDataUn = Object.keys(groupedDataUn).map((groupId) => ({
      key: groupId,
      title: groupedDataUn[groupId].groupName,
      checkable: false,
      children: Object.keys(groupedDataUn[groupId].machines).map(
          (machineId) => ({
            key: machineId,
            checkable: false,
            title: groupedDataUn[groupId].machines[machineId].machineName,
            children: groupedDataUn[groupId].machines[machineId].sensors,
          })
      ),
    }));

    unUseSensorIds.value = [];
    unUseSensors.value = treeDataUn;
  }
};

let submitUnUseModel = async () => {
  if (unUseSensorIds.value.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/group-model",
        { id: selectSimpleModel.value.id, sensorIds: unUseSensorIds.value },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      await loadBingInfos(selectSimpleModel.value);
    }
  }
};

let submitReplaceUseModel = async () => {
  let diff = useSensorIds.value
      .concat(useSensorIdsOld.value)
      .filter(
          (item: any) =>
              !useSensorIds.value.includes(item) ||
              !useSensorIdsOld.value.includes(item)
      );
  if (diff.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/group-model/un-bind",
        { id: selectSimpleModel.value.id, sensorIds: diff },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      await loadBingInfos(selectSimpleModel.value);
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, { gutter: 24 }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { span: 12 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_a_form, {
                name: "horizontal_login",
                layout: "inline",
                autocomplete: "off"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['时间'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_range_picker, {
                        style: {"width":"230px"},
                        value: dateRange.value,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((dateRange).value = $event)),
                        "disabled-date": disabledDate,
                        onCalendarChange: onCalendarChange
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['设备类型'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        style: {"width":"250px"},
                        value: _unref(tempModelQuery).machineTypeId,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(tempModelQuery).machineTypeId) = $event)),
                        allowClear: "",
                        placeholder: _unref(langObj)['请选择']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineTypes), (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.id
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.FirstCategory + "/" + item.SecondCategory + item.ThirdCategory), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['数据类型'],
                    name: "status"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        style: {"width":"120px"},
                        value: _unref(tempModelQuery).dataType,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(tempModelQuery).dataType) = $event)),
                        allowClear: "",
                        placeholder: _unref(langObj)['请选择']
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.dataTypes, (item, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: item.code
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)[item.name]), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", {
                          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_unref(loadTempSimpleList) && _unref(loadTempSimpleList)(...args))),
                          class: "btn px-5 py-1 cursor-pointer ml-4",
                          style: {"background-color":"#072499","color":"white"}
                        }, _toDisplayString(_unref(langObj)["查询"]), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_a_table, {
              style: {"width":"100%","height":"calc(100% - 70px)","overflow-y":"auto"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: tempColumns,
              "data-source": _unref(models),
              pagination: false
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("a", {
                        style: {"color":"#00fff4 !important","margin-right":"5px"},
                        onClick: ($event: any) => (_unref(loadBingInfos)(record))
                      }, _toDisplayString(_unref(langObj)["查看"]), 9, _hoisted_3),
                      _createVNode(_component_a_popconfirm, {
                        title: _unref(langObj)['确定删除此条数据么'] + '?',
                        "ok-text": _unref(langObj)['确定'],
                        "cancel-text": _unref(langObj)['取消'],
                        onConfirm: ($event: any) => (_unref(deleteSimpleModel)(record))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", _hoisted_4, _toDisplayString(_unref(langObj)["删除"]), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 6 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex-1 text-center" }, "未使用模板的设备", -1)),
            _createElementVNode("div", _hoisted_6, [
              (_unref(unUseSensors).length)
                ? (_openBlock(), _createBlock(_component_a_tree, {
                    key: 0,
                    checkedKeys: _unref(unUseSensorIds),
                    "onUpdate:checkedKeys": _cache[4] || (_cache[4] = ($event: any) => (_isRef(unUseSensorIds) ? (unUseSensorIds).value = $event : unUseSensorIds = $event)),
                    checkable: "",
                    "tree-data": _unref(unUseSensors),
                    onCheck: _unref(selectUnUseSensor),
                    defaultExpandAll: ""
                  }, {
                    title: _withCtx(({ title, key }) => [
                      _createTextVNode(_toDisplayString(title), 1)
                    ]),
                    _: 1
                  }, 8, ["checkedKeys", "tree-data", "onCheck"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_a_button, {
                type: "primary",
                class: "btn3",
                onClick: _unref(submitUnUseModel)
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode("使用模板 ")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 6 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex-1 text-center" }, "已使用模板的设备", -1)),
            _createElementVNode("div", _hoisted_9, [
              (_unref(useSensors).length)
                ? (_openBlock(), _createBlock(_component_a_tree, {
                    key: 0,
                    checkedKeys: _unref(useSensorIds),
                    "onUpdate:checkedKeys": _cache[5] || (_cache[5] = ($event: any) => (_isRef(useSensorIds) ? (useSensorIds).value = $event : useSensorIds = $event)),
                    checkable: "",
                    "tree-data": _unref(useSensors),
                    onCheck: _unref(selectUseSensor),
                    defaultExpandAll: ""
                  }, {
                    title: _withCtx(({ title, key }) => [
                      _createTextVNode(_toDisplayString(title), 1)
                    ]),
                    _: 1
                  }, 8, ["checkedKeys", "tree-data", "onCheck"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_a_button, {
                type: "primary",
                class: "btn3",
                onClick: _unref(submitReplaceUseModel)
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode("替换模板 ")
                ])),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})