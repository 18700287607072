<template>
  <div v-if="path === '/system/eventOrder'">
    <div style="padding:30px 0;height: calc(100vh - 100px);overflow-y: scroll;background-color:#03155C;" class="bule_list_page2 theme-common">
      <router-view style="height: 100%;"/>
    </div>
  </div>
  <div v-else>
    <div style="padding:30px 0;height: calc(100vh - 100px);overflow-y: scroll;background-color:#03155C;" class="bule_list_page theme-common">
      <router-view style="height: 100%;"/>
    </div>
  </div>
 
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
let path:any = ref('')
path.value = router?.currentRoute?.value?.path

watch(
  () => router.currentRoute.value,
  (newValue: any) => {
    path.value = router?.currentRoute?.value?.path
  },
  { immediate: true }
)

</script>
