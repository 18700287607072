import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "w-full flex items-center mt-4" }
const _hoisted_3 = { class: "flex items-center event-btns" }
const _hoisted_4 = { class: "main" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 6 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["onUpdate:modelValue", "onChange"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 5 }
const _hoisted_15 = {
  class: "flex items-center",
  style: {"color":"#000"}
}
const _hoisted_16 = {
  class: "flex items-center",
  style: {"color":"#000"}
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { key: 2 }
const _hoisted_20 = { key: 3 }
const _hoisted_21 = { key: 4 }

import request from "../../../common/request";
import { transformDate, getGroupListByFacID, getGroupName } from "../../../common/tools";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import { message, TableProps } from "ant-design-vue";
import { v4 as uuidv4 } from "uuid";
import addReportComponent from "../../report4/diagnosticReport-add/diagnosticReport-add.vue";
import { Dayjs } from 'dayjs';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";


export default /*@__PURE__*/_defineComponent({
  __name: 'EventOrder',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()

let conditionList = [
  { label: langObj.value['健康'], value: 0 },
  { label: langObj.value['可用上'], value: 1 },
  { label: langObj.value['可用下'], value: 2 },
  { label: langObj.value['警戒上'], value: 3 },
  { label: langObj.value['警戒中'], value: 4 },
  { label: langObj.value['警戒下'], value: 5 },
  { label: langObj.value['故障上'], value: 6 },
  { label: langObj.value['故障下'], value: 7 },
  { label: ' ', value: -1 },
]

let switchList = [
  { label: langObj.value['是'], value: true },
  { label: langObj.value['否'], value: false },
]

let expertProcessList = [
  { label: langObj.value['调整阈值'], value: 0 },
  { label: langObj.value['专家报告'], value: 1 },
  { label: langObj.value['其他'], value: 3 },
  { label: langObj.value['未处理'], value: -1 }
]

let expertProcessListAll = [
  { label: langObj.value['调整阈值'], value: 0 },
  { label: langObj.value['专家报告'], value: 1 },
  { label: langObj.value['状态清零'], value: 2 },
  { label: langObj.value['其他'], value: 3 },
  { label: langObj.value['未处理'], value: -1 }
]

let statusList = [
  { label: langObj.value['诊断待处理'], value: 0 },
  { label: langObj.value['完成'], value: 3 },
]

let currentConditionList = [
  { label: "可用", value: 1, realValue: [1, 2] },
  { label: "警戒及以下", value: 2, realValue: [3, 4, 5, 6, 7] },
  { label: "不限", value: null, realValue: null },
]

let internalNotesList: any = ref([
  '开关机异常',
  '偶然冲击',
  '工况干扰',
  '数据清洗',
  '阈值问题',
  '通用指标不合理',
  '通用指标有问题',
  '专业指标不合理',
  '转频提取错误',
  '特征提取错误',
  '信号处理',
  '硬件采集问题',
  '硬件位置异常',
  '程序错误',
])

const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});

let addIString: any = ref('')
let addIVisible = ref(false)  //新增诊断判断内部备注

const router = useRouter();
const loading = ref(false);
const formRef = ref();
let visible = ref(false)
let visibleEdit = ref(false)
let visibleEditRelease = ref(false)  //放出报告编辑
let visibleAddRreport = ref(false)
let visibleView = ref(false)
let reportMachineInfo: any = ref(null)
let isCommit = ref(true)
let form: any = ref({
  list: [{
    factoryId: '',
    machineId: '',
    sensorId: '',
    scoreName: '',
    date: null,
    condition: null,
    expertDiagnosisResult: null,
    expertProcess: null,
    display: false,
  }],
  diagnosticNotes: '',
  reportId: '',
})

let viewForm: any = ref({
  list: [{
    factoryId: '',
    machineName: '',
    sensorPlace: '',
    scoreName: '',
    date: null,
    condition: null,
    expertDiagnosisResult: null,
    expertProcess: null,
    display: false,
  }],
  diagnosticNotes: '',
  reportId: '',
})

let editSelectOrder: any = ref([])
let editForm: any = ref({
  diagnosticNotes: '',
  reportId: '',
  display: false,
})
let editFormRelease: any = ref({
  diagnosticNotes: '',
  reportId: '',
  display: false,
})

let factoryList: any = ref([])
let machineList: any = ref([])
let machineSearchList: any = ref([])
let groupSearchList: any = ref([])
let sensorList: any = ref([])
let faultList: any = ref([])

const searchModel: any = ref({
  skip: 1,
  take: 20,
  total: 0,
  factoryId: null,
  machineId: null,
  date: null,
  sorter: true,
  sendReport: null
});

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
  showSizeChanger: true,
}));

let dataList: any = ref([]);
const columns = [
  {
    title: langObj.value["项目"],
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: langObj.value["区域"],
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: langObj.value["指标名称"],
    dataIndex: "faultName",
    align: "center"
  },
  {
    title: langObj.value["发生时间"],
    dataIndex: "date",
    defaultSortOrder: 'ascend',
    sorter: true,
    align: "center"
  },
  {
    title: langObj.value["内部事件类型"],
    dataIndex: "createBy",
    align: "center"
  },
  {
    title: langObj.value["当前状态"],
    dataIndex: "condition",
    align: "center"
  },
  {
    title: langObj.value["上一小时稳定状态"],
    dataIndex: "prevCondition",
    align: "center"
  },
  {
    title: langObj.value["是否改变状态"],
    dataIndex: "changed",
    align: "center"
  },
  // {
  //   title: "是否前台显示",
  //   dataIndex: "display",
  // },

  {
    title: langObj.value["是否已放出"],
    dataIndex: "sendReport",
    align: "center"
  },
  {
    title: langObj.value["状态突破笔数"],
    dataIndex: "breakCount",
    align: "center"
  },
  {
    title: langObj.value["稳定状态"],
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },

  {
    title: langObj.value["诊断处理"],
    dataIndex: "expertProcess",
    align: "center"
  },
  {
    title: langObj.value["事件进展"],
    dataIndex: "status",
    align: "center"
  },
  {
    title: langObj.value["选择"],
    dataIndex: "select",
    align: "center"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
    align: "center"
  },
];

const addColumns = [
  {
    title: langObj.value["项目"],
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineId",
    align: "center"
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorId",
    align: "center"
  },
  {
    title: langObj.value["指标名称"],
    dataIndex: "scoreName",
    align: "center"
  },
  {
    title: langObj.value["发生时间"],
    dataIndex: "date",
    align: "center"
  },
  {
    title: langObj.value["当前状态"],
    dataIndex: "condition",
    align: "center"
  },
  {
    title: langObj.value["稳定状态"],
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },
  {
    title: langObj.value["诊断处理"],
    dataIndex: "expertProcess",
    align: "center"
  },
  {
    title: langObj.value["是否前台显示"],
    dataIndex: "display",
    align: "center"
  },
]

const viewColumns = [
  {
    title: langObj.value["项目"],
    dataIndex: "factoryId",
    align: "center"
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
    align: "center"
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
    align: "center"
  },
  {
    title: langObj.value["指标名称"],
    dataIndex: "scoreName",
    align: "center"
  },
  {
    title: langObj.value["发生时间"],
    dataIndex: "date",
    align: "center"
  },
  {
    title: langObj.value["当前状态"],
    dataIndex: "condition",
    align: "center"
  },
  {
    title: langObj.value["稳定状态"],
    dataIndex: "expertDiagnosisResult",
    align: "center"
  },

  {
    title: langObj.value["诊断处理"],
    dataIndex: "expertProcess",
    align: "center"
  },
  {
    title: langObj.value["是否前台显示"],
    dataIndex: "display",
    align: "center"
  },
]

let selectTag = ref(0)  //0: 待完成 1: 已完成
let factoryAll: any = ref([])
let reportCreateInfo: any = ref(null)
let diagnosticManagerList: any = ref([])
let accountManagerList: any = ref([])
let sendReportList: any = ref([
  { value: -1, name: "不限" },
  { value: 1, name: "是" },
  { value: 2, name: "否" },
])

onMounted(() => {
  init()
})

const init = () => {
  getManagers()
  getAdmin()
  search()
  getFactoryList()
}

// 获取诊断/客户经理列表
const getManagers = async () => {
  const config = {
    params: {
      factoryId: "65e29561f747d7e32bbed049",
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultRole = await request.get("/api/roles", config)
  let rolelist: any = []
  if (resultRole?.data?.list) {
    rolelist = resultRole.data.list
  }
  let diagnosticManagerRoleId = rolelist.find((p: any) => p.name === '诊断工程师')?.id
  let accountManagerRoleId = rolelist.find((p: any) => p.name === '客户支持')?.id

  if (diagnosticManagerRoleId) {
    let config2 = {
      params: {
        roleId: diagnosticManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      diagnosticManagerList.value = result2.data.list
    }
  }

  if (accountManagerRoleId) {
    let config2 = {
      params: {
        roleId: accountManagerRoleId,
        factoryId: "65e29561f747d7e32bbed049",
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result2 = await request.get("/api/members", config2)
    if (result2?.data?.list) {
      accountManagerList.value = result2.data.list
    }
  }
}

let admin = ref(false)
const getAdmin = () => {
  let member = JSON.parse(localStorage.getItem('memberInfo') || '')
  admin.value = member?.admin
}

// 获取工厂列表
const getFactoryList = async () => {
  let result = await request.get('/api/factories')
  if (result && result.data) {
    factoryAll.value = result.data
    let parentIds = result.data.map((p: any) => p.parentId)
    parentIds = [...new Set(parentIds)]
    let list = result.data.filter((p: any) => !parentIds.includes(p._id))
    let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
    list = list.filter((p: any) => memberInfo.factoryIds.includes(p._id))
    factoryList.value = list
  }
}

// 获取查询设备列表
const selectFactorySearch = async (ev: any) => {
  if (ev) {
    groupSearchList.value = getGroupListByFacID(ev)
    console.log('groupSearchList', groupSearchList.value)
  } else {
    groupSearchList.value = []
  }
  searchModel.value.groupId = null
  searchModel.value.machineId = null
}

// 获取查询是否已放出
const selectSendReport = async (ev: any) => {

  if (ev) {
    searchModel.value.sendReport = ev

  } else {
    searchModel.value.sendReport = null

  }

}


// 选择查询区域
const selectGroupSearch = async (ev: any) => {
  if (ev) {
    const config = {
      params: {
        factoryId: searchModel.value.factoryId,
        groupId: searchModel.value.groupId,
      },
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.get('/api/machines', config)
    if (result && result.data) {
      machineSearchList.value = result.data
    } else {
      machineSearchList.value = []
    }
  } else {
    machineSearchList.value = []
  }
}

const tagSelect = (ev: any) => {
  searchModel.value.skip = 1
  selectTag.value = ev
  search()
}

const handleTableChange = (ev: any, a: any, sorter: any) => {
  searchModel.value = {
    ...searchModel.value,
    skip: ev.current,
    take: ev.pageSize,
  }
  if (sorter.order && sorter.field) {
    searchModel.value.sorter = sorter.order === 'descend' ? false : true
  }
  search()
}

// 查询
const search = async () => {
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    searchModel.value.begin = transformDate(searchModel.value.date[0])
    searchModel.value.end = transformDate(searchModel.value.date[1])
  } else {
    searchModel.value.begin = ''
    searchModel.value.end = ''
  }

  const config = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      factoryId: searchModel.value.factoryId,
      machineId: searchModel.value.machineId,
      status: selectTag.value === 0 ? 0 : 3,
      begin: searchModel.value.begin,
      end: searchModel.value.end,
      sorter: searchModel.value.sorter,
      diagnosticManager: searchModel.value.diagnosticManager,
      accountManager: searchModel.value.accountManager,
      type: 1,
      sendReport: searchModel.value.sendReport
    } as any,
    headers: {
      requestId: uuidv4(),
    },
  };

  if (searchModel.value.condition) {
    config.params.conditionList = currentConditionList.find((p: any) => p.value === searchModel.value.condition)?.realValue || []
  }

  let result = await request.get('/api/workflows/info', config)
  if (result && result.data) {
    dataList.value = result.data.list
    let indexMD = 0
    let dataMD = ''
    dataList.value.map((d: any) => {
      d.groupName = getGroupName(d.groupId)
      let md = d.machineId + d.date
      if (dataMD === md) {
        d.indexMD = indexMD
      } else {
        indexMD++
        d.indexMD = indexMD
        dataMD = md
      }
    })
    // searchModel.value.skip = 1
    searchModel.value.total = result.data.total
  }
};


// 新增
const add = () => {
  form.value = {
    list: [{
      factoryId: '',
      machineId: '',
      sensorId: '',
      scoreName: '',
      date: null,
      condition: null,
      expertDiagnosisResult: null,
      expertProcess: null,
      display: false,
    }],
    diagnosticNotes: '',
    reportId: '',
  }
  visible.value = true;
};

// 选择工厂
const selectFactory = async (ev: any) => {
  const config = {
    params: {
      factoryId: ev,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/machines', config)
  if (result && result.data) {
    machineList.value = result.data
  } else {
    machineList.value = []
  }
}

// 选择设备
const selectMachine = async (ev: any) => {
  const config = {
    params: {
      machineId: ev,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/sensors', config)
  if (result && result.data) {
    sensorList.value = result.data
  } else {
    sensorList.value = []
  }


  let result2 = await request.get('/api/machines/faultnames', config)
  if (result2 && result2.data) {
    faultList.value = result2.data.faultNameExpects.concat(result2.data.faultNames)
  } else {
    faultList.value = []
  }

  // 获取当前状态
  const configS = {
    params: {
      id: ev,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result3 = await request.get('/api/external/machines/workstatus/info', configS)
  if (result3 && result3.data && result3.data.workStableStatus && result3.data.workStableStatus.condition) {
    form.value.list[0].condition = result3.data.workStableStatus.condition
  } else {
    form.value.list[0].condition = 0
  }
}

// 添加专家报告
const addReport = () => {
  if (!form.value.list[0].factoryId) {
    message.warning('请选择项目')
    return
  }
  if (!form.value.list[0].machineId) {
    message.warning('请选择设备')
    return
  }
  if (!form.value.list[0].sensorId) {
    message.warning('请选择测点')
    return
  }

  let machine = machineList.value.find((p: any) => p.id === form.value.list[0].machineId)
  machine.sensorId = form.value.list[0].sensorId
  reportMachineInfo.value = machine
  visibleAddRreport.value = true
}

// 获取专家报告Id
const getReportId = (ev: any) => {
  if (ev) {
    form.value.reportId = ev
    console.log(ev, "evvvvv")
    editForm.value.reportId = ev;
    visibleAddRreport.value = false
  }
}

// 提交
let subLoading: any = ref(false)
const addSubmit = async () => {
  if (!form.value.list[0].factoryId) {
    message.warning('请选择项目')
    return
  }
  if (!form.value.list[0].machineId) {
    message.warning('请选择设备')
    return
  }
  if (!form.value.list[0].sensorId) {
    message.warning('请选择测点')
    return
  }
  if (!form.value.list[0].scoreName) {
    message.warning('请选择指标名称')
    return
  }
  if (!form.value.list[0].date) {
    message.warning('请选择发生时间')
    return
  }
  if (!form.value.list[0].expertDiagnosisResult) {
    message.warning('请选择稳定状态')
    return
  }
  if (!form.value.list[0].expertProcess) {
    message.warning('请选择诊断处理')
    return
  }
  subLoading.value = true

  let submitForm = {
    display: form.value.list[0].display,
    machineId: form.value.list[0].machineId,
    sensorId: form.value.list[0].sensorId,
    date: transformDate(form.value.list[0].date),
    expertDiagnosisResult: form.value.list[0].expertDiagnosisResult,
    condition: form.value.list[0].condition,
    expertProcess: form.value.list[0].expertProcess,
    scoreName: form.value.list[0].scoreName,
    faultName: faultList.value.find((p: any) => p.column === form.value.list[0].scoreName)?.title,
    status: 3,
    diagnosticNotes: form.value.diagnosticNotes,
    reportIds: form.value.reportId ? [form.value.reportId] : [],
  }

  let result = await request.post('/api/workflows/info', submitForm)
  if (result && result.status === 200) {
    subLoading.value = false
    message.success('操作成功')
    visible.value = false
    search()
  } else {
    subLoading.value = false
    message.warning('新增失败，请联系管理员')
  }

}

// 验证数据
const verifySure = (index: any, ev: any) => {
  if (!ev.isClick) {
    isCommit.value = true
    return
  }

  let list = dataList.value.filter((p: any) => p.isClick)
  let list1: any
  let dates = list.map((p: any) => p.date)
  dates = [...new Set(dates)]
  if (!dates || !dates.length) {
    isCommit.value = true
    return
  }
  if (dates && dates.length > 1) {
    message.warning('请选中所有同一时间的工单！')
    isCommit.value = true
    return
  } else {
    list1 = dataList.value.filter((p: any) => p.date === dates[0] && p.machineId === ev.machineId)
    if (list.length !== list1.length && !ev.isClick) {
      isCommit.value = true
      return
    }
    list1.map((d: any) => { d.isClick = true })
  }

  for (let item of list1) {
    if ((!item.expertDiagnosisResult && item.expertDiagnosisResult !== 0) || item.expertDiagnosisResult < 0) {
      isCommit.value = true
      message.warning('请选择稳定状态！')
      return
    }

    if ((!item.expertProcess && item.expertProcess !== 0) || item.expertProcess < 0 || item.expertProcess == 4) {
      isCommit.value = true
      message.warning('请选择诊断处理！')
      return
    }
  }
  isCommit.value = false
}

let reportinfo = ref(null)

// 确认提交
const sure = async () => {
  let list = dataList.value.filter((p: any) => p.isClick)
  console.log('list', list)
  let sensorPlaces = [...new Set(list.map((p: any) => p.sensorPlace))]
  console.log('sensorPlaces', sensorPlaces)
  let string = ''

  sensorPlaces.map((d: any) => {
    let str: any = `${d}：`
    let infos = list.filter((p: any) => p.sensorPlace === d)
    infos.map((s: any, i: any) => {
      if (i == infos.length - 1) {
        str = str + s.faultName + '；'
      } else {
        str = str + s.faultName + '，'
      }
    })
    string = string + str
  })

  // list.map((d:any) => {
  //   string = string + d.sensorPlace + '：' + d.faultName + '；'
  // })

  const config = {
    params: {
      machineId: list[0].machineId,
      date: list[0].date,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/workflows/info/count', config)
  if (result.status === 200 && result.data) {
    if (list.length === result.data) {
      editSelectOrder.value = list

      editForm.value = {
        diagnosticNotes: string,
        reportId: '',
        display: false,
      }
      if (list[0]?.sendReport) {

        //addEditReport(false);
        
        
        let config = {
          params: {
            id: list[0].id,
          },
          headers: {
            requestId: uuidv4(),
          },
        };
        let result = await request.get('/api/workflows/infos', config)
        editForm.value.reportId=result?.data?.reportIds[0] || ""
        // 查询专家报告
        config = {
          params: {
            id: editForm.value.reportId,
          },
          headers: {
            requestId: uuidv4(),
          },
        };
        let resultReport = await request.get('/api/reports/info', config)
        reportCreateInfo.value = resultReport.data
        reportMachineInfo.value = {...resultReport.data.machines[0]}
        visibleEditRelease.value = true;
      } else {
        addEditReport(false);
        visibleEdit.value = true
      }

    } else {
      message.warning('所选测点当前时间点存在未选择的工单')
    }
  }
}

// 添加诊断判断内部备注
const addinternalNote = () => {
  addIString.value = ''
  addIVisible.value = true
}

const submitAddInternalNote = () => {
  internalNotesList.value.push(addIString.value)
  editForm.value.internalNotes = addIString.value
  addIVisible.value = false
}
const addReportRef = ref()
// 保存任务处理
const editSubmit = async () => {
  subLoading.value = true
  let list = JSON.parse(JSON.stringify(editSelectOrder.value))
  list.map((d: any) => {
    delete d.isClick
    d.status = 3
  })

  let submitForm = {
    display: editForm.value.display,
    diagnosticNotes: editForm.value.diagnosticNotes,
    internalNotes: editForm.value.internalNotes,
    reportIds: editForm.value.reportId ? [editForm.value.reportId] : [],
    workflowInfos: list,
  }

  if(editSelectOrder.value[0]?.sendReport){
    addReportRef.value?.submit()

  }
  let result = await request.put('/api/workflows/info', submitForm)

  if (result && result.status === 200) {
    subLoading.value = false
    message.success('操作成功')
    visibleEdit.value = false
    visibleEditRelease.value = false
    search()
  } else {
    subLoading.value = false
    message.warning('操作失败, 请联系管理员!')
  }

}

// 保存已经放出的工单内容
const editSubmitRelease = async () => {
  subLoading.value = true

  let list = editSelectOrder.value.map((p: any) => ({
    faultName: p.faultName,
    scoreName: p.scoreName,
    expertDiagnosisResult: p.expertDiagnosisResult,
    id: p.id,
    sensorId: p.sensorId,
  }))
  let conditionL = list.map((p: any) => p.expertDiagnosisResult)
  let max = Math.max(...conditionL)
  let info = list.find((p: any) => p.expertDiagnosisResult === max)
  const config = {
    machineId: editSelectOrder.value[0].machineId,
    date: editSelectOrder.value[0].date,
    conditionList: list,
    sensorId: info?.sensorId
  };



  let result = await request.put('/api/reports', config)

  if (result && result.status === 200) {
    subLoading.value = false
    message.success('操作成功')
    visibleEditRelease.value = false
    search()
  } else {
    subLoading.value = false
    message.warning('操作失败, 请联系管理员!')
  }

}



// 专家处理添加专家报告
const addEditReport = async (flag: any) => {
  // return
  let list = editSelectOrder.value.map((p: any) => ({
    faultName: p.faultName,
    scoreName: p.scoreName,
    expertDiagnosisResult: p.expertDiagnosisResult,
    id: p.id,
    sensorId: p.sensorId,
  }))
  let conditionL = list.map((p: any) => p.expertDiagnosisResult)
  let max = Math.max(...conditionL)
  let info = list.find((p: any) => p.expertDiagnosisResult === max)
  const config = {
    machineId: editSelectOrder.value[0].machineId,
    date: editSelectOrder.value[0].date,
    conditionList: list,
    sensorId: info?.sensorId
  };
  let result = await request.post('/api/reports-export-auto', config)

  reportCreateInfo.value = null
  if (result && result.data) {
    reportCreateInfo.value = result.data
  }

  reportMachineInfo.value = {
    machineName: editSelectOrder.value[0].machineName,
    id: editSelectOrder.value[0].machineId,
    factoryId: editSelectOrder.value[0].factoryId,
  }
  visibleAddRreport.value = flag
}


// 取消选择
const cancelClick = () => {
  dataList.value.map((d: any) => { d.isClick = false })
  isCommit.value = true
}



// 查看
const viewDetail = async (ev: any) => {
  viewForm.value = ref({
    list: [{
      factoryId: '',
      machineName: '',
      sensorPlace: '',
      scoreName: '',
      date: null,
      condition: null,
      expertDiagnosisResult: null,
      expertProcess: null,
      display: null,
    }],
    diagnosticNotes: '',
    internalNotes: '',
    reportId: '',
  })
  visibleView.value = true

  const config = {
    params: {
      id: ev.id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get('/api/workflows/infos', config)
  if (result && result.data) {
    let info = result.data
    viewForm.value = {
      list: [{
        factoryId: info.factoryId,
        machineName: info.machineName,
        sensorPlace: info.sensorPlace,
        scoreName: info.scoreName,
        date: info.date,
        condition: info.condition,
        expertDiagnosisResult: info.expertDiagnosisResult,
        expertProcess: info.expertProcess,
        display: info.display,
      }],
      diagnosticNotes: info.diagnosticNotes,
      internalNotes: info.internalNotes,
      reportId: info.reportIds && info.reportIds.length ? info.reportIds[0] : '',
    }
  }


}

// 删除
const deleteOrder = async (ev: any) => {
  let config = {
    params: { id: ev.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.delete('/api/workflows/info', config)
  if (result && result.data) {
    setTimeout(() => {
      search()
      message.success('删除成功')
    }, 1000);
  } else {
    message.success('删除失败')
  }
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};



const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  let className = ""

  if (record.indexMD % 2 === 0) {
    className = "odd-row "
  } else {
    className = "even-row "
  }

  if (record.waringStatus == 1) {
    className += "textColr"
  }


  return className
};



return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, { type: "flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 24,
                  order: 2
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form, { layout: "inline" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['项目']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: searchModel.value.factoryId,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((searchModel.value.factoryId) = $event)),
                              style: {"width":"200px"},
                              dropdownMatchSelectWidth: false,
                              onChange: selectFactorySearch,
                              allowClear: "",
                              placeholder: _unref(langObj)['请选择项目']
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item._id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.factoryName), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['区域']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: searchModel.value.groupId,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((searchModel.value.groupId) = $event)),
                              style: {"width":"200px"},
                              dropdownMatchSelectWidth: false,
                              onChange: selectGroupSearch,
                              allowClear: "",
                              placeholder: _unref(langObj)['请选择']
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupSearchList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['是否已放出']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: searchModel.value.sendReport,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((searchModel.value.sendReport) = $event)),
                              style: {"width":"200px"},
                              dropdownMatchSelectWidth: false,
                              onChange: selectSendReport,
                              allowClear: "",
                              placeholder: _unref(langObj)['请选择']
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sendReportList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.name), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['设备']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: searchModel.value.machineId,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((searchModel.value.machineId) = $event)),
                              style: {"width":"200px"},
                              dropdownMatchSelectWidth: false,
                              allowClear: "",
                              placeholder: _unref(langObj)['请选择设备']
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineSearchList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.machineName), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "placeholder"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, {
                          label: _unref(langObj)['发生时间']
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_range_picker, {
                              value: searchModel.value.date,
                              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((searchModel.value.date) = $event)),
                              style: {"width":"400px"},
                              "show-time": "",
                              format: "YYYY/MM/DD HH:mm:ss",
                              allowClear: "",
                              "disabled-date": disabledDate,
                              onCalendarChange: onCalendarChange
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }, 8, ["label"]),
                        _createVNode(_component_a_form_item, { label: "当前状态" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              allowClear: "",
                              value: searchModel.value.condition,
                              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((searchModel.value.condition) = $event)),
                              style: {"width":"160px"}
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(currentConditionList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_form_item, { label: "诊断经理" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              allowClear: "",
                              showSearch: true,
                              value: searchModel.value.diagnosticManager,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((searchModel.value.diagnosticManager) = $event)),
                              style: {"width":"180px"}
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(diagnosticManagerList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item._id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.nickName), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_form_item, { label: "客户经理" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              allowClear: "",
                              showSearch: true,
                              value: searchModel.value.accountManager,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((searchModel.value.accountManager) = $event)),
                              style: {"width":"180px"}
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(accountManagerList), (item, index) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: index,
                                    value: item._id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.nickName), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_form_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (searchModel.value.skip = 1, search()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_unref(langObj)['查询']), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_button, {
                              type: "primary",
                              onClick: add,
                              class: "ml-4"
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_plus_outlined)
                              ]),
                              default: _withCtx(() => [
                                _createTextVNode(" " + _toDisplayString(_unref(langObj)['新增']), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", {
                        style: {"margin-left":"50px","font-size":"16px","padding":"0 10px 5px 10px","cursor":"pointer"},
                        class: _normalizeClass({ 'select-tag': _unref(selectTag) == 0 }),
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (tagSelect(0)))
                      }, _toDisplayString(_unref(langObj)['诊断工单(待完成)']), 3),
                      _createElementVNode("div", {
                        style: {"margin-left":"50px","font-size":"16px","padding":"0 10px 5px 10px","cursor":"pointer"},
                        class: _normalizeClass({ 'select-tag': _unref(selectTag) == 1 }),
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (tagSelect(1)))
                      }, _toDisplayString(_unref(langObj)['诊断工单(已完成)']), 3),
                      _cache[36] || (_cache[36] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_a_button, {
                          onClick: sure,
                          disabled: _unref(isCommit),
                          class: "btn-submit"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['确认提交']), 1)
                          ]),
                          _: 1
                        }, 8, ["disabled"]),
                        _createVNode(_component_a_button, {
                          onClick: cancelClick,
                          class: "ml-2 btn-reset"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['取消选择']), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_a_table, {
                        bordered: "",
                        emptyText: '暂无数据',
                        columns: columns,
                        "data-source": _unref(dataList),
                        pagination: pagination.value,
                        loading: loading.value,
                        onChange: handleTableChange,
                        size: "small",
                        "row-class-name": getRowClassName
                      }, {
                        bodyCell: _withCtx(({ column, text, record, index }) => [
                          (column.dataIndex === 'factoryName')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createTextVNode(_toDisplayString(_unref(factoryAll).find(p => p._id ===
                      record.factoryId)?.factoryName), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'machineName')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                (record.cancel)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                      _createElementVNode("del", null, _toDisplayString(text), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(text), 1))
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'condition')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createTextVNode(_toDisplayString(_unref(langObj)[_unref(conditionList).find(p => p.value === text)?.label]), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'prevCondition')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                _createTextVNode(_toDisplayString(_unref(langObj)[_unref(conditionList).find(p => p.value === text)?.label]), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'changed')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                _createTextVNode(_toDisplayString(_unref(langObj)[_unref(switchList).find(p => p.value === text)?.label]), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'display')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                                _createTextVNode(_toDisplayString(_unref(langObj)[_unref(switchList).find(p => p.value === text)?.label]), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'sendReport')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(langObj)[text ? '是' : '否']), 1))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'breakCount')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                                _createElementVNode("span", null, _toDisplayString(_unref(langObj)['可用']) + ":" + _toDisplayString((record?.breakCount?.h1 + record?.breakCount?.h2) || 0) + _toDisplayString(_unref(langObj)['笔']) + "    ", 1),
                                _createElementVNode("span", null, _toDisplayString(_unref(langObj)['警戒']) + ":" + _toDisplayString((record?.breakCount?.h3 + record?.breakCount?.h4 +
                      record?.breakCount?.h5) || 0) + _toDisplayString(_unref(langObj)['笔']) + "    ", 1),
                                _createElementVNode("span", null, _toDisplayString(_unref(langObj)['故障']) + ":" + _toDisplayString((record?.breakCount?.h6 + record?.breakCount?.h7) || 0) + _toDisplayString(_unref(langObj)['笔']), 1)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'expertDiagnosisResult')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 8 }, [
                                (record.status !== 3)
                                  ? (_openBlock(), _createBlock(_component_a_select, {
                                      key: 0,
                                      value: record.expertDiagnosisResult,
                                      "onUpdate:value": ($event: any) => ((record.expertDiagnosisResult) = $event),
                                      style: {"width":"100%"},
                                      options: _unref(conditionList),
                                      onChange: ($event: any) => (verifySure(index, record))
                                    }, null, 8, ["value", "onUpdate:value", "options", "onChange"]))
                                  : _createCommentVNode("", true),
                                (record.status === 3)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(conditionList).find(p => p.value ===
                        record.expertDiagnosisResult)?.label), 1))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'expertProcess')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 9 }, [
                                (record.status !== 3)
                                  ? (_openBlock(), _createBlock(_component_a_select, {
                                      key: 0,
                                      disabled: record.sendReport,
                                      value: record.expertProcess,
                                      "onUpdate:value": ($event: any) => ((record.expertProcess) = $event),
                                      style: {"width":"100%"},
                                      options: _unref(expertProcessList),
                                      onChange: ($event: any) => (verifySure(index, record))
                                    }, null, 8, ["disabled", "value", "onUpdate:value", "options", "onChange"]))
                                  : _createCommentVNode("", true),
                                (record.status === 3)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_unref(expertProcessListAll).find(p => p.value === record.expertProcess)?.label), 1))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'status')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 10 }, [
                                (record.cancel)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(langObj)['已处理']), 1))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(statusList).find(p => p.value === text)?.label), 1))
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'select')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 11 }, [
                                (!record.cancel && record.status !== 3)
                                  ? _withDirectives((_openBlock(), _createElementBlock("input", {
                                      key: 0,
                                      type: "checkbox",
                                      style: {"width":"16px","height":"16px","cursor":"pointer"},
                                      "onUpdate:modelValue": ($event: any) => ((record.isClick) = $event),
                                      onChange: ($event: any) => (verifySure(index, record))
                                    }, null, 40, _hoisted_12)), [
                                      [_vModelCheckbox, record.isClick]
                                    ])
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true),
                          (column.dataIndex === 'act')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 12 }, [
                                _createElementVNode("a", {
                                  onClick: ($event: any) => (viewDetail(record))
                                }, _toDisplayString(_unref(langObj)['查看']), 9, _hoisted_13),
                                (_unref(admin))
                                  ? (_openBlock(), _createBlock(_component_a_divider, {
                                      key: 0,
                                      type: "vertical"
                                    }))
                                  : _createCommentVNode("", true),
                                (_unref(admin))
                                  ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                                      key: 1,
                                      title: _unref(langObj)['确认删除'] + '？',
                                      "ok-text": _unref(langObj)['确认'],
                                      "cancel-text": _unref(langObj)['取消'],
                                      onConfirm: ($event: any) => (deleteOrder(record))
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("a", null, _toDisplayString(_unref(langObj)['删除']), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["data-source", "pagination", "loading"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          width: 1500,
          visible: _unref(visible),
          "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
          title: _unref(langObj)['新增'],
          footer: null,
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_table, {
              ref: "addTable",
              style: {"background-color":"transparent"},
              columns: addColumns,
              "data-source": _unref(form).list,
              pagination: false
            }, {
              bodyCell: _withCtx(({ column, record }) => [
                (column.dataIndex === 'factoryId')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 0,
                      value: record.factoryId,
                      "onUpdate:value": ($event: any) => ((record.factoryId) = $event),
                      style: {"min-width":"150px"},
                      onChange: selectFactory
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item._id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.factoryName), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'machineId')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 1,
                      value: record.machineId,
                      "onUpdate:value": ($event: any) => ((record.machineId) = $event),
                      style: {"min-width":"150px"},
                      onChange: selectMachine
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.machineName), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'sensorId')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 2,
                      value: record.sensorId,
                      "onUpdate:value": ($event: any) => ((record.sensorId) = $event),
                      style: {"min-width":"150px"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'scoreName')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 3,
                      value: record.scoreName,
                      "onUpdate:value": ($event: any) => ((record.scoreName) = $event),
                      style: {"min-width":"150px"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(faultList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.column
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'date')
                  ? (_openBlock(), _createBlock(_component_a_date_picker, {
                      key: 4,
                      value: record.date,
                      "onUpdate:value": ($event: any) => ((record.date) = $event),
                      "show-time": "",
                      placeholder: _unref(langObj)['请选择日期']
                    }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_unref(conditionList).find(p => p.value === record.condition)?.label), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'expertDiagnosisResult')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 6,
                      value: record.expertDiagnosisResult,
                      "onUpdate:value": ($event: any) => ((record.expertDiagnosisResult) = $event),
                      style: {"min-width":"80px"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(conditionList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'expertProcess')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 7,
                      value: record.expertProcess,
                      "onUpdate:value": ($event: any) => ((record.expertProcess) = $event),
                      style: {"min-width":"100px"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(expertProcessList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'display')
                  ? (_openBlock(), _createBlock(_component_a_select, {
                      key: 8,
                      value: record.display,
                      "onUpdate:value": ($event: any) => ((record.display) = $event),
                      style: {"width":"100%"}
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(switchList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.value
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["value", "onUpdate:value"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source"]),
            _createVNode(_component_a_form, {
              style: {"margin-top":"20px"},
              model: _unref(form),
              name: "basic",
              "label-col": { span: 4 },
              "wrapper-col": { span: 20 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['诊断判断备注'],
                  name: "diagnosticNotes",
                  rules: [{ required: true, message: _unref(langObj)['请填写诊断判断备注'] }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(form).diagnosticNotes,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(form).diagnosticNotes) = $event)),
                      placeholder: "",
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['专家报告id'],
                  name: "专家报告id"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(form).reportId,
                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(form).reportId) = $event)),
                      placeholder: "",
                      readonly: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, { "wrapper-col": { offset: 4, span: 20 } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      onClick: addSubmit,
                      style: {"background-color":"#fff"},
                      loading: _unref(subLoading),
                      class: "btn-submit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['提交']), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"]),
                    _createVNode(_component_a_button, {
                      onClick: addReport,
                      class: "ml-4 btn-reset",
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['添加专家报告']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title"]),
        _createVNode(_component_a_modal, {
          width: "800px",
          visible: _unref(visibleAddRreport),
          "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => (_isRef(visibleAddRreport) ? (visibleAddRreport).value = $event : visibleAddRreport = $event)),
          title: _unref(langObj)['新增'],
          footer: null,
          maskClosable: false,
          zIndex: 1001
        }, {
          default: _withCtx(() => [
            _createVNode(addReportComponent, {
              reportMachineInfo: _unref(reportMachineInfo),
              reportCreateInfo: _unref(reportCreateInfo),
              showSubmitPut: false,
              onSendId: getReportId
            }, null, 8, ["reportMachineInfo", "reportCreateInfo"])
          ]),
          _: 1
        }, 8, ["visible", "title"]),
        _createVNode(_component_a_modal, {
          width: 1300,
          visible: _unref(visibleEdit),
          "onUpdate:visible": _cache[22] || (_cache[22] = ($event: any) => (_isRef(visibleEdit) ? (visibleEdit).value = $event : visibleEdit = $event)),
          title: _unref(langObj)['诊断处理'],
          footer: null,
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              style: {"margin-top":"20px"},
              model: _unref(editForm),
              name: "basic",
              "label-col": { span: 4 },
              "wrapper-col": { span: 20 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['诊断判断内部备注'],
                  name: "internalNotes",
                  rules: [{ required: true, message: _unref(langObj)['请填写'] }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(editForm).internalNotes,
                      "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(editForm).internalNotes) = $event)),
                      style: {"width":"100%"},
                      options: _unref(internalNotesList).map(item => ({ value: item }))
                    }, {
                      dropdownRender: _withCtx(({ menuNode: menu }) => [
                        _createVNode(_unref(VNodes), { vnodes: menu }, null, 8, ["vnodes"]),
                        _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                        _createElementVNode("div", {
                          style: {"padding":"4px 8px","cursor":"pointer"},
                          onMousedown: _cache[15] || (_cache[15] = e => e.preventDefault()),
                          onClick: _cache[16] || (_cache[16] = ($event: any) => (addinternalNote()))
                        }, [
                          _createElementVNode("div", _hoisted_15, [
                            _createVNode(_component_plus_outlined),
                            _cache[37] || (_cache[37] = _createTextVNode(" 添加 "))
                          ])
                        ], 32)
                      ]),
                      _: 1
                    }, 8, ["value", "options"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['诊断判断备注'],
                  name: "diagnosticNotes",
                  rules: [{ required: true, message: _unref(langObj)['请填写诊断判断备注'] }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(editForm).diagnosticNotes,
                      "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_unref(editForm).diagnosticNotes) = $event)),
                      placeholder: "",
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['专家报告id'],
                  name: "reportId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(editForm).reportId,
                      "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_unref(editForm).reportId) = $event)),
                      placeholder: "",
                      readonly: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['是否前台显示'],
                  name: "display"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      value: _unref(editForm).display,
                      "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_unref(editForm).display) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: true }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['是']), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: false }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['否']), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, { "wrapper-col": { offset: 4, span: 20 } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      onClick: editSubmit,
                      loading: _unref(subLoading)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['保存任务处理']), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"]),
                    _createVNode(_component_a_button, {
                      onClick: _cache[21] || (_cache[21] = ($event: any) => (addEditReport(true))),
                      class: "ml-4",
                      type: "primary"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['添加专家报告']), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title"]),
        _createVNode(_component_a_modal, {
          width: 1300,
          visible: _unref(visibleEditRelease),
          "onUpdate:visible": _cache[29] || (_cache[29] = ($event: any) => (_isRef(visibleEditRelease) ? (visibleEditRelease).value = $event : visibleEditRelease = $event)),
          title: _unref(langObj)['诊断处理'],
          footer: null,
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              style: {"margin-top":"20px"},
              model: _unref(editFormRelease),
              name: "basic",
              "label-col": { span: 4 },
              "wrapper-col": { span: 20 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['诊断判断内部备注'],
                  name: "internalNotes",
                  rules: [{ required: true, message: _unref(langObj)['请填写'] }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(editForm).internalNotes,
                      "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_unref(editForm).internalNotes) = $event)),
                      style: {"width":"100%"},
                      options: _unref(internalNotesList).map(item => ({ value: item }))
                    }, {
                      dropdownRender: _withCtx(({ menuNode: menu }) => [
                        _createVNode(_unref(VNodes), { vnodes: menu }, null, 8, ["vnodes"]),
                        _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                        _createElementVNode("div", {
                          style: {"padding":"4px 8px","cursor":"pointer"},
                          onMousedown: _cache[23] || (_cache[23] = e => e.preventDefault()),
                          onClick: _cache[24] || (_cache[24] = ($event: any) => (addinternalNote()))
                        }, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_plus_outlined),
                            _cache[38] || (_cache[38] = _createTextVNode(" 添加 "))
                          ])
                        ], 32)
                      ]),
                      _: 1
                    }, 8, ["value", "options"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['诊断判断备注'],
                  name: "diagnosticNotes",
                  rules: [{ required: true, message: _unref(langObj)['请填写诊断判断备注'] }]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      value: _unref(editForm).diagnosticNotes,
                      "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_unref(editForm).diagnosticNotes) = $event)),
                      placeholder: "",
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['专家报告id'],
                  name: "reportId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(editForm).reportId,
                      "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_unref(editForm).reportId) = $event)),
                      placeholder: "",
                      readonly: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['是否前台显示'],
                  name: "display"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_radio_group, {
                      value: _unref(editForm).display,
                      "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_unref(editForm).display) = $event))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_radio, { value: true }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['是']), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_radio, { value: false }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(langObj)['否']), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['专家报告'],
                  name: "diagnosticNotes"
                }, {
                  default: _withCtx(() => [
                    _createVNode(addReportComponent, {
                      ref_key: "addReportRef",
                      ref: addReportRef,
                      reportMachineInfo: _unref(reportMachineInfo),
                      reportCreateInfo: _unref(reportCreateInfo),
                      showSubmitPut: true,
                      onSendId: getReportId
                    }, null, 8, ["reportMachineInfo", "reportCreateInfo"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, { "wrapper-col": { offset: 4, span: 20 } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      onClick: editSubmit,
                      loading: _unref(subLoading)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(langObj)['保存任务处理']), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title"]),
        _createVNode(_component_a_modal, {
          width: 1300,
          visible: _unref(visibleView),
          "onUpdate:visible": _cache[33] || (_cache[33] = ($event: any) => (_isRef(visibleView) ? (visibleView).value = $event : visibleView = $event)),
          title: _unref(langObj)['查看'],
          footer: null,
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_table, {
              ref: "viewTable",
              columns: viewColumns,
              "data-source": _unref(viewForm).list,
              pagination: false
            }, {
              bodyCell: _withCtx(({ column, record, text }) => [
                (column.dataIndex === 'factoryId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_unref(factoryAll).find(p => p._id === text)?.factoryName), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(_unref(langObj)[_unref(conditionList).find(p => p.value === text)?.label]), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'expertDiagnosisResult')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(_unref(langObj)[_unref(conditionList).find(p => p.value === text)?.label]), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'expertProcess')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_unref(langObj)[_unref(expertProcessList).find(p => p.value === text)?.label]), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'display')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_unref(langObj)[_unref(switchList).find(p => p.value === text)?.label]), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source"]),
            _createVNode(_component_a_form, {
              style: {"margin-top":"20px"},
              model: _unref(viewForm),
              name: "basic",
              "label-col": { span: 4 },
              "wrapper-col": { span: 20 }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['诊断判断内部备注'],
                  name: "internalNotes"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      readonly: "",
                      value: _unref(viewForm).internalNotes,
                      "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => ((_unref(viewForm).internalNotes) = $event)),
                      placeholder: " ",
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['诊断判断备注'],
                  name: "diagnosticNotes"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_textarea, {
                      readonly: "",
                      value: _unref(viewForm).diagnosticNotes,
                      "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_unref(viewForm).diagnosticNotes) = $event)),
                      placeholder: " ",
                      rows: 4
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['专家报告id'],
                  name: "专家报告id"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(viewForm).reportId,
                      "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_unref(viewForm).reportId) = $event)),
                      placeholder: "",
                      readonly: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title"]),
        _createVNode(_component_a_modal, {
          width: 400,
          maskClosable: false,
          visible: _unref(addIVisible),
          "onUpdate:visible": _cache[35] || (_cache[35] = ($event: any) => (_isRef(addIVisible) ? (addIVisible).value = $event : addIVisible = $event)),
          title: "新增诊断判断内部备注",
          onOk: submitAddInternalNote
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              style: {"width":"350px"},
              value: _unref(addIString),
              "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => (_isRef(addIString) ? (addIString).value = $event : addIString = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})