import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "w-full flex whiteBg",
  style: {"color":"#fff"}
}
const _hoisted_2 = { style: {"width":"270px"} }
const _hoisted_3 = {
  class: "w-full box p-2",
  style: {"width":"270px"}
}
const _hoisted_4 = {
  class: "box mt-4",
  style: {"width":"270px"}
}
const _hoisted_5 = {
  key: 0,
  class: "theme-table-link"
}
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "flex-1 box ml-4 p-3" }
const _hoisted_8 = {
  key: 0,
  id: "chart7",
  style: {"width":"100%","height":"500px"}
}

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { transformDate2, transformDate, getChartDataTool } from "../../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";


export default /*@__PURE__*/_defineComponent({
  __name: 'chart7',
  props: {
    sensorInfo: null,
},
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()
let typeList = [
  { label: langObj.value["高频加速度(长)"], value: 2, type: "TW", unit: "m/s²" },
  { label: langObj.value["高频加速度"], value: 1, type: "HZ", unit: "m/s²" },
  // {label : '温度', value: 2},
  { label: langObj.value["低频加速度X"], value: 3, type: "LX", unit: "m/s²" },
  { label: langObj.value["低频加速度Y"], value: 4, type: "LY", unit: "m/s²" },
  { label: langObj.value["低频加速度Z"], value: 5, type: "LZ", unit: "m/s²" },
  { label: langObj.value["速度X"], value: 6, type: "SX", unit: "mm/s" },
  { label: langObj.value["速度Y"], value: 7, type: "SY", unit: "mm/s" },
  { label: langObj.value["速度Z"], value: 8, type: "SZ", unit: "mm/s" },
];



const columns = [
{
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["值"],
    dataIndex: "value",
  },
];


let dataList:any = ref([])
let chartA:any 
let visible = ref(true)

const props:any = __props;
let sensorInfo:any = ref()
sensorInfo.value = props.sensorInfo
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
    type: 1,
})
watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);

onMounted(() => {
    init()
})

const init = () => {
    if(!sensorInfo.value || !sensorInfo.value.id) return
    getTrendListAll()
}

let selectedRowKeys:any = ref([])
const onSelectChange = (ev:any) => {
    if(ev.length) {
        selectedRowKeys.value = [ ev[ev.length-1] ]
    } else {
        selectedRowKeys.value = []
    }
    getChartData()
}


const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const handleTableChange = (pagination: any) => {
    // 处理分页数据
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getTrendListAll();
};

//获取所有时间点数据
const getTrendListAll = async() => {
    dataList.value = []
    selectedRowKeys.value = []
    let config: any = {
        params: {
            machineId: sensorInfo.value.machineId ,
            sensorId: sensorInfo.value.id ,
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            begin: transformDate(new Date('2021/01/01 00:00:00')),
            end: transformDate(new Date()),
            type: typeList.find((p:any) => p.value === searchModel.value.type)?.type
        },
        headers: {
            requestId: uuidv4(),
        },
    }; 

    let result = await request.get('/api/external/singles', config)

    if(result && result.data) {
        dataList.value = result.data.list
        searchModel.value.total = result.data.count
        // if(dataList.value.length) {
        //     selectedRowKeys.value = [dataList.value[0]._id]
        //     getChartData()
        // }
    }
}

const getChartData = async() => {
    if(selectedRowKeys.value.length) {
        let info = dataList.value.find((p:any) => p._id === selectedRowKeys.value[0])
        let config: any = {
            params: {
                sensorId: info.sensorId ,
                date: info.date,
                type: typeList.find((p:any) => p.value === searchModel.value.type)?.type,
            },
            headers: {
                requestId: uuidv4(),
            },
        }; 
        let result = await request.get('/api/external/singles/info', config)
        if(result && result.data && result.data.url) {
            let res = await request.post('/draw/api/ceps-sort', {url:  [result.data.url]})
            if(res && res.data) {
                visible.value = false
                visible.value = true
                let timeData = getChartDataTool(res?.data)
                setTimeout(() => {
                    initChart(timeData)
                }, 200);
                
            }
        }else {
            chartA && chartA.clear()
        }
       
    } else {
        chartA && chartA.clear()
    }
}

getChartData()

const initChart = (data:any) => {
    console.log(data)
    let {axisX, axisY} = data
    const ec = echarts as any;
    if(!document.getElementById('chart7')) return
    chartA = ec.init(document.getElementById('chart7'));
    console.log('chartA', chartA)
    let option = {
        color: theme.value=='white'? ["#274CE0"]:["#00FFF4"],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
            top: '8%',
        },
        toolbox: {
          trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        xAxis: {
            name: '倒频率-s',
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              showMaxLabel: true
            },
            splitLine: {
              show: false,
              lineStyle: {
                opacity: 0.3
              }
            },
            data: axisX
        },
        yAxis: {
            name: '幅值-m/s²',
            type: 'value',
            splitLine: {
              show: true,
            },
        },
        series: [
            {
                data: axisY,
                type: 'line'
            }
        ]
    };

    chartA && chartA.setOption(option)
}



return (_ctx: any,_cache: any) => {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_radio_group, {
              value: _unref(searchModel).type,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).type) = $event)),
              style: {"width":"100%"},
              onChange: init
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_radio, {
                    key: index,
                    style: {"display":"block"},
                    value: item.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: columns,
              "data-source": _unref(dataList),
              pagination: pagination.value,
              onChange: handleTableChange,
              "row-selection": { selectedRowKeys: _unref(selectedRowKeys), onChange: onSelectChange, hideSelectAll: true },
              rowKey: "_id"
            }, {
              bodyCell: _withCtx(({ column, text }) => [
                (column.dataIndex === 'opt')
                  ? (_openBlock(), _createElementBlock("a", _hoisted_5, _toDisplayString(_unref(langObj)['下载']), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'value')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (text || text==0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(text.toFixed(2)), 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source", "pagination", "row-selection"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_unref(visible))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})