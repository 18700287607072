import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "mt-10",
  style: {"color":"red"}
}

import { defineComponent, onMounted, onUnmounted, ref, watch, computed } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";



export default /*@__PURE__*/_defineComponent({
  __name: 'downLoad',
  props: {
    type: null,
    dataList: null,
},
  emits: ['downLoadCancel'],
  setup(__props, { emit: __emit }) {

const props:any = __props;
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let type = ref(1)   //1、智能诊断分数下载
let dataList:any = ref([])
let data = ref([])

let searchModel = ref({
    skip: 1,
    take: 20,
    total: 0,
})

let pagination = computed(() => ({
    total: searchModel.value.total,
    current: searchModel.value.skip,
    pageSize: searchModel.value.take,
}));

let columns1 = [
    {
        title:  langObj.value['时间'],
        dataIndex: "date",
    },
    {
        title:  langObj.value['设备名称'],
        dataIndex: "machineName",
        // align: "center"
    },
    {
        title:  langObj.value['故障模式'],
        dataIndex: "title",
    },
    {
        title: langObj.value['健康度'],
        dataIndex: "score",
    },
]

let columns2 = [
    {
        title:  langObj.value['时间'],
        dataIndex: "date",
    },
    {
        title:  langObj.value['报警类型'],
        dataIndex: "typeName",
        // align: "center"
    },
    {
        title:  langObj.value['严重程度'],
        dataIndex: "conditionName",
    },
    {
        title:  langObj.value['设备'],
        dataIndex: "machineName",
    },
    {
        title:  langObj.value['测点'],
        dataIndex: "sensorPlace",
    },
    {
        title:  langObj.value['报警内容'],
        dataIndex: "diagnosticNotes",
    },
    {
        title:  langObj.value['处理状态'],
        dataIndex: "handleStatusName",
    },
]




watch(
    () => props.dataList,
    (newValue, oldValue) => {
        dataList.value = props.dataList
        init();
    }
);



onMounted(() => {
    getLang()
    type.value = props.type
    dataList.value = props.dataList
    init() 
})

const init = () => {
    if(!dataList.value || !dataList.value.length) return
    // dataList.value = dataList.value.splice(0, 20)
    searchModel.value.skip = 1
    searchModel.value.take = 20
    searchModel.value.total = dataList.value.length
    if(type.value === 1) {
        dealWithData1()
    }
    if(type.value === 2) {
        dealWithData2()
    }
}

const handleTableChange = (page:any) => {
    searchModel.value.skip = page.current
    searchModel.value.take = page.pageSize
}

const dealWithData1 = () => {
    // console.log('dataList', dataList.value)
}

const dealWithData2 = () => {
    let typeList = [
        {label: langObj.value['智能诊断'], value: 1},
        {label: langObj.value['维修记录'], value: 2},
        {label: langObj.value['设备检查'], value: 3},
        {label: langObj.value['门限报警'], value: 4},
    ]

    let conditionList = [
        {label: langObj.value['健康'], value: 0, color: '#61c08f'},
        {label: langObj.value['可用'], value: 1, color: '#d2de49'},
        {label: langObj.value['可用'], value: 2, color: '#d2de49'},
        {label: langObj.value['警戒'], value: 3, color: '#cd5f3b'},
        {label: langObj.value['警戒'], value: 4, color: '#cd5f3b'},
        {label: langObj.value['警戒'], value: 5, color: '#cd5f3b'},
        {label: langObj.value['故障'], value: 6, color: '#921e37'},
        {label: langObj.value['故障'], value: 7, color: '#921e37'},
    ]

    dataList.value.map((d:any) => {
        d.typeName = typeList.find((p:any) => p.value == d.type)?.label
        d.conditionName = conditionList.find((p:any) => p.value == d.condition)?.label
        if(!d.handleStatus || d.handleStatus==1) {
            d.handleStatusName = langObj.value['未处理']
        }
        if(d.handleStatus==2) {
            d.handleStatusName = langObj.value['已处理']
        }
        if(d.handleStatus==3) {
            d.handleStatusName = langObj.value['已关闭']
        }
        d.diagnosticNotes = d.diagnosticNotes.replace(',', '，')
    })

    dataList.value = dataList.value.map((d:any) => ({
        date: d.date,
        typeName: d.typeName,
        conditionName: d.conditionName,
        machineName: d.machineName,
        sensorPlace: d.sensorPlace ? d.sensorPlace : '',
        diagnosticNotes: d.diagnosticNotes,
        handleStatusName: d.handleStatusName,
    }))
}


const downLoad = () => {
    let str = '';
    if (type.value == 1) {
        str = `${getLanguage('时间', language.value)},${getLanguage('设备名称', language.value)},${getLanguage('故障模式', language.value)},${getLanguage('健康度', language.value)}\n`;
    }
    if (type.value == 2) {
        str = `${getLanguage('时间', language.value)},${getLanguage('报警类型', language.value)},${getLanguage('严重程度', language.value)},${getLanguage('设备', language.value)},${getLanguage('测点', language.value)},${getLanguage('报警内容', language.value)},${getLanguage('处理状态', language.value)}\n`;
    }

    for (let i = 0; i < dataList.value.length; i++) {
        for (let item in dataList.value[i]) {
            str += `${dataList.value[i][item] + '\t'},`;
        }
        str += '\n';
    }


    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str);
    //通过创建a标签实现
    let link = document.createElement('a');
    link.href = uri;
    //对下载的文件命名
    link.download = '导出.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
        cancel()
    }, 1000);
}


const cancel = () => {
    emits('downLoadCancel', true)
}

const emits = __emit

return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(type)===1)
      ? (_openBlock(), _createBlock(_component_a_table, {
          key: 0,
          bordered: "",
          columns: _unref(columns1),
          dataSource: _unref(dataList)
        }, null, 8, ["columns", "dataSource"]))
      : _createCommentVNode("", true),
    (_unref(type)===2)
      ? (_openBlock(), _createBlock(_component_a_table, {
          key: 1,
          bordered: "",
          columns: _unref(columns2),
          dataSource: _unref(dataList)
        }, null, 8, ["columns", "dataSource"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_unref(langObj)['请耐心等待，完成加载后，点击下载即可下载文件，请勿反复点击下载或关闭本网站']) + "。 ", 1),
    _createElementVNode("div", {
      onClick: downLoad,
      class: "mt-4 px-5 py-1 fff cursor-pointer",
      style: {"border":"1px solid #0d53b7cc","display":"inline-block","background":"#07249933","color":"#fff"}
    }, _toDisplayString(_unref(langObj)['下载']), 1)
  ]))
}
}

})