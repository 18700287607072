import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {} }
const _hoisted_2 = { class: "box theme-common" }
const _hoisted_3 = { class: "w-full p-5" }
const _hoisted_4 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_5 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_6 = { class: "ml-2" }
const _hoisted_7 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_8 = { class: "ml-2" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { style: {"color":"#fff"} }
const _hoisted_12 = { class: "w-full flex" }
const _hoisted_13 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}

import { computed, onMounted, ref } from "vue";
import request from "../../common/request";
import { transformDate2, getGroupListByFactory } from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import { langList } from "@/common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'threshold-warning-list',
  props: {
    query: {}
  },
  setup(__props: any, { expose: __expose }) {

const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}

let langObj: any = ref({})
let language: any = ref('Chinese')
getLang()

let props = __props

let conditionList = [
  { label: '健康', value: 0, color: '#61c08f' },
  { label: '可用', value: 1, color: '#d2de49' },
  { label: '可用', value: 2, color: '#d2de49' },
  { label: '警戒', value: 3, color: '#cd5f3b' },
  { label: '警戒', value: 4, color: '#cd5f3b' },
  { label: '警戒', value: 5, color: '#cd5f3b' },
  { label: '故障', value: 6, color: '#921e37' },
  { label: '故障', value: 7, color: '#921e37' },
]

let currentConditionList = [
  { label: "可用", value: 1, realValue: [1, 2] },
  { label: "警戒及以下", value: 2, realValue: [3, 4, 5, 6, 7] },
  { label: "不限", value: null, realValue: null },
]


let statusList = [
  { label: '未处理', value: 1 },
  { label: '已处理', value: 2 },
  { label: '已关闭', value: 3 },
]

let warningColumns = [
  {
    title: "项目",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: langObj.value['报警时间'],
    dataIndex: 'date',
  },
  {
    title: langObj.value['当前状态'],
    dataIndex: 'scoreCondition',
  },
  {
    title: langObj.value['严重程度'],
    dataIndex: 'condition',
  },
  {
    title: langObj.value['设备'],
    dataIndex: 'machineName',
  },
  {
    title: langObj.value['报警内容'],
    dataIndex: 'diagnosticNotes',
  },
  {
    title: langObj.value['联系人'],
    dataIndex: 'contactName',
  },
  {
    title: langObj.value['处理状态'],
    dataIndex: 'handleStatus',
  },
  /*
  {
    title: langObj.value['操作'],
    dataIndex: 'act',
  },
  */
]
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let data: any = ref([])
let searchModel: any = ref({
  type: 4,
  expertCertification: false,
  groupId: null,
  machineId: null,
  date: null,
  skip: 1,
  take: 20,
  condition: [],
  handleStatus: null,
})
let selectedRowKeys: any = ref()
let groupList: any = ref([])
let machineList: any = ref([])
let formClose: any = ref({
  closeCause: ''
})
let isEditable: any = ref(false)
let isAdmin: any = ref(false)

onMounted(() => {
  isEditable.value = true
  getAdmin()
  getGroupList()
  search()
  getLang()
})

const getAdmin = () => {
  let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '{}')
  isAdmin.value = memberInfo?.admin
}


// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory()
}

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
  searchModel.value.machineId = ''
  if (ev) {
    machineList.value = memberInfo.machines.filter((p: any) => p.groupId === ev)
  } else {
    machineList.value = []
  }
}

const search = () => {
  searchModel.value.skip = 1
  Object.assign(searchModel.value, props.query, {
    type: 4,
    expertCertification: false,
  })
  getData()
}

const getData = async () => {
  let config: any = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      machineId: searchModel.value.machineId,
      type: searchModel.value.type,
      handleStatus: searchModel.value.handleStatus,
      expertCertification: false,
      factoryId: searchModel.value.factoryId,
      begin: searchModel.value.begin,
      end: searchModel.value.end,
      //factoryIds: getRealFactoryIdList()
    },
    headers: {
      requestId: uuidv4(),
    },
  };


  if (!searchModel.value.type) {
    config.params.typeList = [1, 4]
  }

  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  if (searchModel.value.condition) {
    config.params.conditionList = currentConditionList.find((p: any) => p.value === searchModel.value.condition)?.realValue
  }

  let result = await request.get('/api/workflows', config)

  if (result) {
    data.value = result.data.list
    searchModel.value.total = result.data.total
  }
}

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    let order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};


//复选框选择
const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev
}

// 查看已关闭
let visibledel2 = ref(false)
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause
  visibledel2.value = true
}

//查看报告
let visibleDR: any = ref(false)
let reportDRId: any = ref('')
let mrDref: any = ref()

const cancelDR = () => {
  visibleDR.value = false
}

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? 'even-row' : 'odd-row';
}


const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

__expose({
  search,
});


return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: _unref(warningColumns),
                "data-source": _unref(data),
                "row-selection": { selectedRowKeys: _unref(selectedRowKeys), onChange: onSelectChange },
                pagination: pagination.value,
                onChange: handleTableChange,
                rowKey: "id"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'scoreCondition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, { 'background': _unref(conditionList).find((p) => p.value === text)?.color }])
                        }, null, 4),
                        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) =>
                      p.value === text)?.label ?? ""]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'condition')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("div", {
                          style: _normalizeStyle([{"width":"12px","height":"12px","border-radius":"50%"}, { 'background': _unref(conditionList).find((p) => p.value === text)?.color }])
                        }, null, 4),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) =>
                      p.value === text)?.label ?? ""]), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'handleStatus')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        (text === 3)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: ($event: any) => (viewClose(record))
                            }, [
                              _createElementVNode("a", null, _toDisplayString(_unref(langObj)['已关闭']), 1)
                            ], 8, _hoisted_9))
                          : _createCommentVNode("", true),
                        (text !== 3)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(statusList).find(p => p.value === text) ?
                    _unref(langObj)[_unref(statusList).find(p => p.value === text)?.label ?? ""]
                    :
                    ""), 1))
                          : _createCommentVNode("", true)
                      ], 64))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "data-source", "row-selection", "pagination"])
            ])
          ])
        ]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibledel2),
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(visibledel2) ? (visibledel2).value = $event : visibledel2 = $event)),
          closable: false,
          footer: null,
          bodyStyle: { 'background': '#020F46' },
          style: {"top":"30%"},
          width: "1200px",
          maskClosable: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", {
                  class: "cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_isRef(visibledel2) //@ts-ignore
 ? visibledel2.value = false : visibledel2 = false))
                }, [
                  _createVNode(_component_close_outlined, { style: {"font-size":"20px","color":"#fff !important"} })
                ])
              ]),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(langObj)['关闭报警']), 1),
              _createVNode(_component_a_form, {
                ref: "formRef",
                name: "advanced_search",
                class: "ant-advanced-search-form",
                style: {"margin-top":"20px"},
                model: _unref(formClose)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { gutter: 24 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            name: "closeCause",
                            label: "关闭原因",
                            required: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _unref(formClose).closeCause,
                                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formClose).closeCause) = $event)),
                                placeholder: "",
                                rows: 4,
                                readonly: ""
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model"])
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_drawer, {
          visible: _unref(visibleDR),
          "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_isRef(visibleDR) ? (visibleDR).value = $event : visibleDR = $event)),
          class: "custom-class",
          size: "large",
          closable: false,
          placement: "right"
        }, {
          title: _withCtx(() => _cache[5] || (_cache[5] = [])),
          default: _withCtx(() => [
            _createVNode(DiagnosticReportDetails, {
              ref_key: "mrDref",
              ref: mrDref,
              reportId: _unref(reportDRId),
              onCancel: cancelDR
            }, null, 8, ["reportId"])
          ]),
          _: 1
        }, 8, ["visible"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})