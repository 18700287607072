<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
  <div class="theme-common">
    <a-layout-content style="padding: 0 20px">
      <a-form layout="inline">
        <a-form-item>
          <a-button type="primary" @click="add">
            <template #icon><plus-outlined /></template>{{langObj['新增']}}</a-button>
        </a-form-item>
      </a-form>
      <div class="main">
        <a-table bordered :columns="columns" :row-key="(record) => record.id" :dataSource="tableData" :pagination="false"
          :loading="loading" size="small">
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'icon'">
              <component style="font-size: 30px" v-if="text" :is="icons[text] + '-outlined'" />
            </template>
            <template v-if="column.dataIndex === 'id'" >
              <a-popconfirm :title="langObj['确定删除此条数据么']+'?'" :ok-text="langObj['确定']" :cancel-text="langObj['取消']" @confirm="deleteThis(record)">
                <a-button type="primary" danger><delete-outlined />{{langObj['删除']}}</a-button>
              </a-popconfirm>

              <a-button type="primary" class="plft" @click="editThis(record)"><edit-outlined />{{langObj['编辑']}}</a-button>
            </template>
          </template>
        </a-table>
      </div>
    </a-layout-content>
    <a-modal :width="850" v-model:visible="modalState.visible" :title="modalState.title"
    :ok-text="langObj['确定']" :cancel-text="langObj['取消']"
      :confirm-loading="modalState.confirmLoading" :maskClosable="false" @ok="handleOk">
      <a-form :model="formMenu" ref="formStateRef" name="basic" autocomplete="off"
        :label-col="{ style: { width: '150px' } }">
        <a-form-item :label="langObj['上级菜单']" name="parentId">
          <a-select v-model:value="formMenu.parentId" show-search placeholder="" :options="options"
            :filter-option="filterOption"></a-select>
        </a-form-item>
        <a-form-item :label="langObj['菜单名称']" name="title" :rules="[
          { required: true, message: langObj['请输入菜单名称']+'!', trigger: 'blur' },
        ]">
          <a-input v-model:value="formMenu.title" />
        </a-form-item>
        <a-form-item :label="langObj['菜单Code']" name="code" :rules="[
          { required: true, message: langObj['请输入菜单Code']+'!', trigger: 'blur' },
        ]">
          <a-input v-model:value="formMenu.code" />
        </a-form-item>
        <a-form-item :label="langObj['菜单类型']" name="type">
          <a-radio-group v-model:value="formMenu.type" name="radioGroup">
            <a-radio :value="1">{{langObj['按钮']}}</a-radio>
            <a-radio :value="2">{{langObj['链接']}}</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="formMenu.type == 2" :label="langObj['路由地址']" name="route" :rules="[
          { required: true, message: langObj['请输入路由地址']+'!', trigger: 'blur' },
        ]">
          <a-input v-model:value="formMenu.route" />
        </a-form-item>
        <a-form-item :label="langObj['菜单排序']" name="sort">
          <a-input-number id="inputNumber" v-model:value="formMenu.sort" :min="1" style="width: 100%" />
        </a-form-item>
        <a-form-item :label="langObj['菜单状态']" name="status">
          <a-radio-group v-model:value="formMenu.status" name="radioGroup">
            <a-radio :value="true">{{langObj['开启']}}</a-radio>
            <a-radio :value="false">{{langObj['禁用']}}</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { computed, onMounted } from "vue";
import { ref, reactive, toRaw } from "vue";
import type { SelectProps } from "ant-design-vue";
import { icons } from "../../common/icons";
import Icon from "@ant-design/icons-vue";
import { v4 as uuidv4 } from "uuid";
import { message, FormInstance } from "ant-design-vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
const router = useRouter();
let loading = ref(false);
const formRef = ref();

const formState = reactive({
  roleName: "",
  skip: 1,
  take: 10,
});

const visible = ref<boolean>(false);

const hide = () => {
  visible.value = false;
};
const selectIcon = (iconUrl: any) => {
  formMenu.value.icon = iconUrl;
};
const modalState = reactive({
  visible: false,
  title: "新增",
  confirmLoading: false,
  roleName: "",
  meun: [],
});
let formMenu = ref({
  title: "",
  code: "",
  parentId: "0",
  route: "",
  sort: 1,
  status: true,
  icon: "",
  type: 1,
  id: "",
});
const formStateRef = ref<any>();
let tableData = ref([]);
const columns = [
  {
    title: langObj.value["菜单名称"],
    dataIndex: "title",
    width: "20%",
    align: "center"
  },
  {
    title: langObj.value["菜单Code"],
    dataIndex: "code",
    width: "20%",
    align: "center"
  },
  {
    title: langObj.value["菜单路由"],
    dataIndex: "route",
    width: "20%",
    align: "center"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "id",
    align: "center"
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["新增"];
  formMenu.value = {
    title: "",
    code: "",
    parentId: "0",
    route: "",
    sort: 1,
    status: true,
    icon: "",
    type: 1,
    id: "",
  };
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate()
  }, 200);
};

// 保存
const handleOk = () => {
  formStateRef.value.validate().then((result: any) => {
    if (formMenu.value.id) {
      const config = {
        headers: {
          requestId: uuidv4(),
        },
      };
      request.put("/api/menus/info", formMenu.value, config).then((res) => {
        if (res) {
          modalState.visible = false;
          message.success("操作成功");
          search();
        }
      });
    } else {
      const config = {
        params: formMenu.value,
        headers: {
          requestId: uuidv4(),
        },
      };
      request.post("/api/menus", formMenu.value, config).then((res) => {
        if (res) {
          modalState.visible = false;
          message.success("操作成功");
          search();
        }
      });
    }
  }).catch((err: any) => {

    console.log(err); //失败后执行

  });
};

const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/menus/info", config).then((res) => {
    if (res) {
      message.success("操作成功");
      search();
    } else {
    }
  });
};


const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
const resetForm = () => {
  formStateRef.value.resetFields();
};

const editThis = (val: any) => {
  modalState.title = langObj.value["编辑"];
  modalState.visible = true;
  formMenu.value = JSON.parse(JSON.stringify(val));
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500)

};

// 查询
const search = () => {
  loading.value = true;
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/menus", config).then((res: any) => {
    if (res) {
      var oneLevel = res.data.filter((t: any) => t.parentId == 0);
      oneLevel.forEach((t: any) => {
        var list = res.data.filter((t2: any) => t2.parentId == t.id);
        if (list.length) {
          t.children = list;
        }
      });
      tableData.value = oneLevel;
      options.value = res.data
        .filter((t: any) => t.parentId == 0)
        .map((t: any) => ({
          value: t.id,
          label: langObj.value[t.title],
        }));
      options.value.unshift({ value: "0", label: "--"+ langObj.value['一级菜单'] +"--" });
      loading.value = false;
    }
  });
};
// 在组件加载完成后执行一些操作
onMounted(() => {
  search();
});

const filterOption = (input: string, option: any) => {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const options = ref([{ value: "0", label: "--一级菜单--" }]);
</script>

<style scoped>
.plft {
  margin-left: 10px;
}

.iconPick div {
  padding: 10px;
}

.main {
  margin-top: 30px;
}
</style>