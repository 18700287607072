<template>
    <div style="padding: 16px 16px; min-height: 100vh; background-color: #000A32"
        class="theme-common">
        <div class="w-full h-full flex">
            <!-- 左侧设备列表 -->
            <div style="min-width: 250px; margin-top: 31px;">
                <div style="width: 250px; position: fixed;"
                    :style="{ 'top': scrollTop }">
                    <machineListLeftComponent @selectSensor="selectSensor"
                        :type="2" :mode="1"> </machineListLeftComponent>
                </div>
            </div>

            <!-- 右侧内容 -->
            <div class="flex-1 ml-4 whiteBg" style="color: #fff;">
                <div style="width: 100%;" class="flex items-center box">
                    <div @click="selectMenu = item.value"
                        :class="[item.value === selectMenu ? 'menu-select' : '']"
                        class="menu ml-8" v-for="item in menuList"
                        :key="item.value">{{ item.label }}</div>
                </div>

                <div class="w-full mt-4">
                    <!-- 趋势波形图 -->
                    <chart1Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 1"></chart1Component>
                    <!-- 包络解调 -->
                    <chart2Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 2"></chart2Component>
                    <!-- 多时域频谱 -->
                    <chart3Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 3"></chart3Component>
                    <!-- 指标监测 -->
                    <chart4Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 4"></chart4Component>
                    <!-- 指标值对比分析 -->
                    <chart5Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 5"></chart5Component>
                    <!-- 诊断分数趋势 -->
                    <chart6Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 6"></chart6Component>
                    <!-- 倒频谱 -->
                    <chart7Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 7"></chart7Component>
                    <!-- 轴心轨迹 -->
                    <chart8Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 8"></chart8Component>
                    <!-- 瀑布图 -->
                    <chart9Component :sensorInfo="sensorInfo"
                        v-if="selectMenu === 9"></chart9Component>
                </div>





            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import machineListLeftComponent from "../components/machine-list-left.vue";
import { v4 as uuidv4 } from "uuid";
import chart1Component from "./chart/chart1.vue";
import chart2Component from "./chart/chart2.vue";
import chart3Component from "./chart/chart3.vue";
import chart4Component from "./chart/chart4.vue";
import chart5Component from "./chart/chart5.vue";
import chart6Component from "./chart/chart6.vue";
import chart7Component from "./chart/chart7.vue";
import chart8Component from "./chart/chart8.vue";
import chart9Component from "./chart/chart9.vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()

let menuList = [
    {label: langObj.value['趋势波形图'], value: 1},
    {label: langObj.value['包络解调'], value: 2},
    {label: langObj.value['多时域频谱'], value: 3},
    {label: langObj.value['指标监测'], value: 4},
    {label: langObj.value['指标值对比分析'], value: 5},
    {label: langObj.value['诊断分数趋势'], value: 6},
    {label: langObj.value['倒频谱'], value: 7},
    // {label: '轴心轨迹', value: 8},
    {label: langObj.value['瀑布图'], value: 9},
]

let selectMenu = ref(1)
let sensor_id = ref('')
let sensorInfo:any = ref(null)
let scrollTop = ref('120px')

const selectSensor = (ev:any) => {
    sensor_id.value = ev
    init()
}

const init = () => {
    getSensorInfo()
}

const handleEvent = (e: any) => {
    if(e.target.localName == 'body') {
        if( e.srcElement.scrollTop >= 120) {
            scrollTop.value = '5px'
        } else {
            scrollTop.value = '120px'
        }
    }
    
};

window.addEventListener('scroll', handleEvent, true)

onUnmounted(() => {
    window.removeEventListener("scroll", handleEvent, true)

})

const getSensorInfo = async() => {
    sensorInfo.value = {}
    let config: any = {
        params: {
            id: sensor_id.value ,
        },
        headers: {
            requestId: uuidv4(),
        },
    }; 
    let result = await request.get('/api/sensors/info', config)

    if(result && result.data) {
        sensorInfo.value = result.data
    } 

    console.log('sensorInfo.value', sensorInfo.value)
}


</script>


<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
    width: 250px;
}

.menu {
    padding: 10px 0;
    cursor: pointer;
}

.menu-select {
    border-bottom: 2px solid #00FFF4;
}



</style>

