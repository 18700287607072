<template>
    <div class="w-full">
        <div>
            <a-form
                :model="searchModel"
                name="horizontal_login"
                layout="inline"
                autocomplete="off"
            >
                <a-form-item
                    :label="langObj['设备区域']"
                    name="groupId"
                >
                    <a-select
                        v-model:value="searchModel.groupId"
                        style="width: 220px"
                        @change="selectGroup"
                        :placeholder="langObj['请选择设备区域']"
                    >
                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                    :label="langObj['设备']"
                    name="status"
                    >
                    <a-select
                        v-model:value="searchModel.machineId"
                        style="width: 220px"
                        :placeholder="langObj['请选择设备']"
                    >
                    <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                    :label="langObj['更新时间']"
                    name="status"
                >
                    <a-range-picker v-model:value="searchModel.date"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
                </a-form-item>
                
                <a-form-item
                    :label="langObj['搜索']"
                    name="status"
                    >
                    <a-input style="width: 220px"  v-model:value="searchModel.keyword" :placeholder="langObj['请输入关键词搜索']" />
                </a-form-item>

                <a-form-item>
                    <div class="flex items-center">
                        <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">{{langObj['重置']}}</div>

                        <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499;">{{langObj['查询']}}</div>
                       
                    </div>
                </a-form-item>
                

            </a-form>
        </div>

        <div class="box mt-4" style="width: 100%;">
            <a-table 
            style="width: 100%;" 
            size="small" 
            :row-class-name="getRowClassName" 
            :columns="columns" 
            :data-source="data"
            :pagination="pagination"
            @change="handleTableChange"
            >
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'act'">
                        <a @click="details(record)" style="" class="theme-table-link">{{langObj['查看详情']}}</a>
                    </template>
                </template>
            </a-table>
        </div>

        <a-drawer v-model:visible="visible" class="custom-class" :closable="false" size="large" placement="right">
            <template #title>
            </template>
            <maintenanceReportDetails ref="mrD" @cancel="cancel"></maintenanceReportDetails>
        </a-drawer>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList } from "../../common/tools";
import maintenanceReportDetails from "./common/maintenanceReportDetails.vue";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getGroupName } from "../../common/tools";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();

const columns = [
    {
        title: langObj.value['报告编号'],
        dataIndex: 'id',
    },
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupName',
    },
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },
    {
        title: langObj.value['检修内容'],
        dataIndex: 'maintainMessage',
    },
    {
        title: langObj.value['提交时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['检修人'],
        dataIndex: 'maintainPerson',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]
let searchModel:any = ref({
    groupId: null,
    machineId: null,
    keyword: '',
    date: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    order: -1
})
let data:any = ref([])
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let groupList:any = ref([])
let machineList:any = ref([])

let visible = ref(false)
let mrD = ref()

onMounted(() => {
    init()
})

const init = () => {
    getGroupList()
    search()
}

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory()
}

// 选择区域
const selectGroup = (ev:any) => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    searchModel.value.machineId = ''
    if(ev) {
        machineList.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineList.value = []
    }
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        groupId: null,
        machineId: null,
        keyword: '',
        date: null,
    }
}

const getData = async() => {
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            type: [2],
            keyword: searchModel.value.keyword,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            machineId: searchModel.value.machineId,
            factoryIds: getRealFactoryIdList(),
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)
    let member = JSON.parse(localStorage.getItem('memberInfo') || '')

    if(result && result.status === 200) {
        console.log(result)


        result.data.list.forEach((element: any) => {
            if (element.machines && element.machines[0]) {
                element.machineName = element.machines[0].machineName;
                // element.groupName = member.groups.find((p:any) => p.id === element.machines[0].groupId)?.name
                element.groupName = getGroupName(element.machines[0]?.groupId)
            }

            if(element.repairReport) {
                element.disposition = element.repairReport.disposition
                element.maintainMessage = element.repairReport.maintainMessage
                element.maintainPerson = element.repairReport.maintainPerson
            }
        })






        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}


const details = (ev:any) => {
    console.log(ev)
    visible.value = true
    setTimeout(() => {
    mrD.value.show(ev.id)
    }, 1000);
}

const cancel = (ev:any) => {
    if(ev) {
        visible.value = false
    }
}


const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if(Object.keys(sorter).length) { 
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};





const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};



</script>


<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}
.btn {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff
}

/deep/ .ant-table-tbody > tr > td {
    border-bottom: 0px solid #fff
}

/deep/ .ant-picker-input > input {
    color: #fff;
}

/deep/ .ant-picker-clear span {
    background: #000 !important;
}

// /deep/ .anticon-close svg {
//     color: #000 !important;
// }

</style>
