import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_4 = ["onClick"]

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList } from "../../common/tools";
import maintenanceReportDetails from "./common/maintenanceReportDetails.vue";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getGroupName } from "../../common/tools";

export default /*@__PURE__*/_defineComponent({
  __name: 'MaintenanceReport',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();

const columns = [
    {
        title: langObj.value['报告编号'],
        dataIndex: 'id',
    },
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupName',
    },
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },
    {
        title: langObj.value['检修内容'],
        dataIndex: 'maintainMessage',
    },
    {
        title: langObj.value['提交时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['检修人'],
        dataIndex: 'maintainPerson',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]
let searchModel:any = ref({
    groupId: null,
    machineId: null,
    keyword: '',
    date: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    order: -1
})
let data:any = ref([])
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let groupList:any = ref([])
let machineList:any = ref([])

let visible = ref(false)
let mrD = ref()

onMounted(() => {
    init()
})

const init = () => {
    getGroupList()
    search()
}

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory()
}

// 选择区域
const selectGroup = (ev:any) => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    searchModel.value.machineId = ''
    if(ev) {
        machineList.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineList.value = []
    }
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        groupId: null,
        machineId: null,
        keyword: '',
        date: null,
    }
}

const getData = async() => {
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            type: [2],
            keyword: searchModel.value.keyword,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            machineId: searchModel.value.machineId,
            factoryIds: getRealFactoryIdList(),
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)
    let member = JSON.parse(localStorage.getItem('memberInfo') || '')

    if(result && result.status === 200) {
        console.log(result)


        result.data.list.forEach((element: any) => {
            if (element.machines && element.machines[0]) {
                element.machineName = element.machines[0].machineName;
                // element.groupName = member.groups.find((p:any) => p.id === element.machines[0].groupId)?.name
                element.groupName = getGroupName(element.machines[0]?.groupId)
            }

            if(element.repairReport) {
                element.disposition = element.repairReport.disposition
                element.maintainMessage = element.repairReport.maintainMessage
                element.maintainPerson = element.repairReport.maintainPerson
            }
        })






        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}


const details = (ev:any) => {
    console.log(ev)
    visible.value = true
    setTimeout(() => {
    mrD.value.show(ev.id)
    }, 1000);
}

const cancel = (ev:any) => {
    if(ev) {
        visible.value = false
    }
}


const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if(Object.keys(sorter).length) { 
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};





const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};




return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_a_form, {
        model: _unref(searchModel),
        name: "horizontal_login",
        layout: "inline",
        autocomplete: "off"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['设备区域'],
            name: "groupId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _unref(searchModel).groupId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                style: {"width":"220px"},
                onChange: selectGroup,
                placeholder: _unref(langObj)['请选择设备区域']
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['设备'],
            name: "status"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _unref(searchModel).machineId,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).machineId) = $event)),
                style: {"width":"220px"},
                placeholder: _unref(langObj)['请选择设备']
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: index,
                      value: item.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['更新时间'],
            name: "status"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_range_picker, {
                value: _unref(searchModel).date,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(searchModel).date) = $event)),
                "disabled-date": disabledDate,
                onCalendarChange: onCalendarChange
              }, null, 8, ["value"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, {
            label: _unref(langObj)['搜索'],
            name: "status"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_input, {
                style: {"width":"220px"},
                value: _unref(searchModel).keyword,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(searchModel).keyword) = $event)),
                placeholder: _unref(langObj)['请输入关键词搜索']
              }, null, 8, ["value", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_a_form_item, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  onClick: reset,
                  class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                }, _toDisplayString(_unref(langObj)['重置']), 1),
                _createElementVNode("div", {
                  onClick: search,
                  class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                  style: {"background-color":"#072499"}
                }, _toDisplayString(_unref(langObj)['查询']), 1)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_table, {
        style: {"width":"100%"},
        size: "small",
        "row-class-name": getRowClassName,
        columns: columns,
        "data-source": _unref(data),
        pagination: pagination.value,
        onChange: handleTableChange
      }, {
        bodyCell: _withCtx(({ column, text, record }) => [
          (column.dataIndex === 'act')
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                onClick: ($event: any) => (details(record)),
                style: {},
                class: "theme-table-link"
              }, _toDisplayString(_unref(langObj)['查看详情']), 9, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["data-source", "pagination"])
    ]),
    _createVNode(_component_a_drawer, {
      visible: _unref(visible),
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      class: "custom-class",
      closable: false,
      size: "large",
      placement: "right"
    }, {
      title: _withCtx(() => _cache[5] || (_cache[5] = [])),
      default: _withCtx(() => [
        _createVNode(maintenanceReportDetails, {
          ref_key: "mrD",
          ref: mrD,
          onCancel: cancel
        }, null, 512)
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})