<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="bule_list_page theme-common">
      <a-layout-content style="padding: 0 20px">
        <a-form layout="inline">
          <a-form-item :label="getLanguage('工厂', language.value)">
            <a-select
              v-model:value="formState.factoryId"
              style="min-width: 200px"
              :placeholder="getLanguage('选择工厂', language.value)"
              allowClear
            >
              <a-select-option
                v-for="(item, index) in factoryList"
                :key="index"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label="getLanguage('账号', language.value)">
            <a-input v-model:value="formState.keyWork" placeholder="" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="search">{{
              getLanguage("搜索", language.value)
            }}</a-button>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="add">
              <template #icon>
                <plus-outlined />
              </template>
              {{ langObj["新增"] }}
            </a-button>
          </a-form-item>
        </a-form>
        <div class="main">
          <a-table
            bordered
            :emptyText="langObj['暂无数据']"
            :columns="columns"
            :data-source="dataSource"
            :pagination="pagination"
            :loading="loading"
            @change="handleTableChange"
            size="small"
          >
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'machines'">
                <span v-for="(item, index) in text" :key="index">
                  <a-tag
                    color="#2db7f5"
                  >
                    {{
                     item.machineName
                    }}
                  </a-tag>
                </span>
              </template>
              <template v-if="column.dataIndex === 'topics'">
                <div v-for="(item, index) in text" :key="index">
                   {{ item }}
                </div>
              </template>
              <template v-if="column.dataIndex === 'id'">
                <a-popconfirm
                  :title="langObj['确定删除此条数据么'] + '?'"
                  :ok-text="langObj['确定']"
                  :cancel-text="langObj['取消']"
                  @confirm="deleteThis(record)"
                >
                  <a-button type="primary" danger>
                    <delete-outlined />
                    {{ langObj["删除"] }}
                  </a-button>
                </a-popconfirm>
                <a-button type="primary" class="plft" @click="editThis(record)">
                  <edit-outlined />
                  {{ langObj["编辑"] }}
                </a-button>
              </template>
            </template>
          </a-table>
        </div>
      </a-layout-content>
      <a-modal
        :width="850"
        :maskClosable="false"
        v-model:visible="modalState.visible"
        :title="modalState.title"
        :cancelText="langObj['关闭']"
        :okText="langObj['确定']"
        :confirm-loading="modalState.confirmLoading"
        @ok="handleOk"
      >
        <a-form
          :model="modalForm"
          ref="formStateRef"
          name="basic"
          autocomplete="off"
          :label-col="{ style: { width: '150px' } }"
        >
          <a-form-item
            :label="langObj['工厂']"
            name="factoryId"
            @change="clearValidate('factoryId')"
            :rules="[
              {
                required: true,
                message: langObj['请选择工厂'],
                trigger: 'blur',
              },
            ]"
          >
            <a-select
              v-model:value="modalForm.factoryId"
              style="width: 100%"
              :placeholder="langObj['选择工厂']"
              allowClear
            >
              <a-select-option
                v-for="(item, index) in factoryList"
                :key="index"
                :value="item.id"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label="getLanguage('订阅号', language.value)"
            name="username"
            @change="changeUserName"
            :rules="[
              {
                required: true,
                message: getLanguage('请输入订阅号', language.value),
                trigger: 'blur',
              },
            ]"
          >
            <a-input
              v-model:value="modalForm.username"
              :placeholder="getLanguage('输入订阅号', language.value)"
            />
            <a-button type="primary" @click="makeUserName()">
              {{ getLanguage("生成", language.value) }}
            </a-button>
          </a-form-item>

          <a-form-item
            :label="getLanguage('订阅验证码', language.value)"
            name="password"
            @change="clearValidate('password')"
            :rules="[
              {
                required: true,
                message: getLanguage('请输入订阅验证码', language.value),
                trigger: 'blur',
              },
            ]"
          >
            <a-input
              v-model:value="modalForm.password"
              :placeholder="getLanguage('请输入订阅验证码', language.value)"
            />
            <a-button type="primary" @click="makePassword()">
              {{ getLanguage("生成", language.value) }}
            </a-button>
          </a-form-item>

          <a-form-item
            :label="getLanguage('实时订阅主题', language.value)"
            name="topics"
            @change="clearValidate('topics')"
          >
            <a-input v-model:value="modalForm.topics[0]" readonly />
          </a-form-item>

          <a-form-item
            :label="getLanguage('历史查询主题', language.value)"
            name="topics"
            @change="clearValidate('topics')"
          >
            <a-input v-model:value="modalForm.topics[1]" readonly />
          </a-form-item>

          <a-form-item
            :label="getLanguage('历史订阅主题', language.value)"
            name="topics"
            @change="clearValidate('topics')"
          >
            <a-input v-model:value="modalForm.topics[2]" readonly />
          </a-form-item>

          <a-form-item
            :label="getLanguage('设备', language.value)"
            name="machineIds"
            @change="clearValidate('machineIds')"
            :rules="[
              {
                required: true,
                message: getLanguage('请选择设备', language.value),
                trigger: 'blur',
              },
            ]"
          >
            <a-tree
              v-model:checkedKeys="modalForm.machineIds"
              checkable
              :checkStrictly="true"
              :tree-data="factoryList"
              @check="selectTree"
            >
              <template #title="{ title, key }">
                {{ title }}
              </template>
            </a-tree>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </a-config-provider>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "@/common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { getLanguage } from "../../common/translate";

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();
const router = useRouter();
const loading = ref(false);
const formRef = ref();
/**操作日志记录 */
import { operateLogs } from "@/common/logs";

operateLogs("访问角色管理");
/**操作日志记录 */
const formState = reactive({
  keyWork: "",
  skip: 1,
  take: 10,
  factoryId: "",
  total: 0,
});

const modalState = reactive({
  visible: false,
  title: langObj.value["新增"],
  confirmLoading: false,
});

let modalForm = reactive({
  username: "",
  password: "",
  machineIds: [],
  topics: ["", "", ""],
  qos: 1,
  id: 0,
  factoryId: "",
});

let total: any = ref(0);

let pagination = computed(() => ({
  total: formState.total,
  current: formState.skip,
  pageSize: formState.take,
}));

const dataSource = ref<any>();
const columns = [
  {
    title: getLanguage("订阅号", language.value),
    dataIndex: "username",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("订阅验证码", language.value),
    dataIndex: "password",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("QOS", language.value),
    dataIndex: "qos",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("主题", language.value),
    dataIndex: "topics",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("设备名称", language.value),
    dataIndex: "machines",
    width: "55%",
    align: "center",
  },
  {
    title: getLanguage("操作", language.value),
    dataIndex: "id",
    align: "center",
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["新增"];
  modalForm = reactive({
    username: "",
    password: "",
    machineIds: [],
    topics: [],
    qos: 1,
    id: 0,
    factoryId: "",
  });
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 200);
};
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/roles/info", config).then((res) => {
    if (res) {
      operateLogs("删除mqtt配置：" + val.name + ",id:" + val.id);
      message.success("操作成功");
      search();
    }
  });
};
const formStateRef = ref<any>();

const editThis = (val: any) => {
  modalState.title = langObj.value["编辑"];
  modalState.visible = true;
  modalForm = reactive(JSON.parse(JSON.stringify(val)));
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
const resetForm = () => {
  formStateRef.value.resetFields();
};
const checkedNodes = ref<any>([]);
const selectTree = (key: any, val: any) => {
  checkedNodes.value = val.checkedNodes;
};

const changeUserName = () => {
  modalForm.topics[0] = "aifreqx/" + modalForm.username + "/data";
  modalForm.topics[1] = "aifreqx/" + modalForm.username + "/query";
  modalForm.topics[2] = "aifreqx/" + modalForm.username + "/result";
};

const makeUserName = () => {
  modalForm.username = uuidv4().substring(0, 21).replaceAll("-", "");
  changeUserName();
};

const makePassword = () => {
  modalForm.password = uuidv4().replaceAll("-", "");
};

// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let form = JSON.parse(JSON.stringify(modalForm));

      if (form.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/factory/mqtt-setting", form, config).then((res) => {
          if (res) {
            operateLogs("修改mqtt配置：" + form.roleName + ",id:" + form.id);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/factory/mqtt-setting", form, config).then((res) => {
          if (res) {
            operateLogs("添加mqtt配置：" + form.roleName);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  formState.skip = pagination.current;
  formState.take = pagination.pageSize;
  search();
};
// 查询
const search = () => {
  const config = {
    params: {
      skip: formState.skip,
      take: formState.take,
      keyword: formState.keyWork,
      factoryId: formState.factoryId,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factory/mqtt-settings", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      formState.total = res.data.count;
      // total.vlaue = res.data.count
    }
  });
};
search();

//***角色车间***//
onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
  search();
});

let factoryList = ref<any>();

let allFactory = ref<any>([]);
let allGroup = ref<any>([]);
let allMachine = ref<any>([]);

const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      res.data.forEach((element: any) => {
        allGroup.value = allGroup.value.concat(element.groups);
      });
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId);
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id);
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory;
        }
        factory.push(d);
      });
      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};

const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id);
  let config = {
    headers: {
      requestId: requestId,
    },
  };
  let res = await request.post(
    "/api/machines/all",
    { factoryIds: factoryIds },
    config
  );

  factoryList.value.forEach((element: any) => {
    allMachine.value = res.data.map((t: any) => t.id);
    element.children.forEach((sonFact: any) => {
      if (sonFact.type == "factory" && sonFact.children) {
        sonFact.children.forEach((groupFact: any) => {
          if (groupFact.type == "group") {
            groupFact.children = res.data
              .filter((t: any) => t.groupId == groupFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
          }
        });
      } else if (sonFact.type == "group") {
        sonFact.children = res.data
          .filter((t: any) => t.groupId == sonFact.value)
          .map((t: any) => ({
            type: "machine",
            ...t,
            value: t.id,
            label: t.machineName,
            title: t.machineName,
            key: t.id,
          }));
      }
    });
  });
};
</script>
<style scoped>
.main {
  margin-top: 30px;
}
</style>
