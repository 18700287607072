import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "w-full theme-common" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  id: "pdfCompany",
  style: {"color":"#fff","background":"rgb(2, 15, 70)"}
}
const _hoisted_9 = {
  class: "text-center w-full mb-4",
  style: {"font-size":"24px"}
}
const _hoisted_10 = { class: "w-full flex mb-4 items-center" }
const _hoisted_11 = { class: "flex-1 text-center" }
const _hoisted_12 = { class: "w-full text-center mb-2" }
const _hoisted_13 = { class: "w-full text-center mb-2" }
const _hoisted_14 = { class: "w-full text-center mb-4" }
const _hoisted_15 = { class: "w-full text-center mb-2" }
const _hoisted_16 = { class: "w-full text-center mb-4" }
const _hoisted_17 = { class: "flex items-center justify-center mb-4" }
const _hoisted_18 = { class: "ml-12 mr-5" }
const _hoisted_19 = { class: "flex items-center cursor-pointer" }
const _hoisted_20 = { class: "ml-2" }
const _hoisted_21 = { class: "flex items-center cursor-pointer mt-3" }
const _hoisted_22 = { class: "ml-2" }
const _hoisted_23 = { class: "flex items-center cursor-pointer mt-3" }
const _hoisted_24 = { class: "ml-2" }
const _hoisted_25 = { class: "flex items-center cursor-pointer mt-3" }
const _hoisted_26 = { class: "ml-2" }
const _hoisted_27 = { class: "w-full text-center mb-2" }
const _hoisted_28 = { class: "w-full mb-2" }
const _hoisted_29 = ["id"]
const _hoisted_30 = {
  class: "w-full flex items-center",
  style: {"color":"#fff","font-size":"16px"}
}
const _hoisted_31 = {
  class: "ml-2",
  id: "pc"
}
const _hoisted_32 = {
  class: "w-full flex items-center",
  style: {"color":"#fff","font-size":"16px"}
}
const _hoisted_33 = {
  class: "ml-2",
  id: "mb"
}

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import * as echarts from 'echarts';
import request from "../../common/request";
import { message } from "ant-design-vue";
import { transformDate, transformDate2, transformDate3, getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";
import { getFactoryName, getGroupListByFactory } from "../../common/tools";
import htmlToPdf from '../../common/htmlToPdf';
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default /*@__PURE__*/_defineComponent({
  __name: 'reportWeek',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
let visibleReport = ref(false);
let visible = ref(false)
let urlpc: any = ref('')
let urlmb: any = ref('')
const route = useRoute()
let loadingFlag = ref(false)
const share = () => {
    urlpc.value = location.host + '/#/week-report/' + route.params.id + '?lang=' + language.value
    urlmb.value = location.host + '/#/week-report-mobile/' + route.params.id + '?lang=' + language.value
    visible.value = true
}
// 容器id="pdfCompany"
const pdfFunc = () => {
    loadingFlag.value = true;	// 动画加载事件
    // 调用htmlToPdf工具函数
    htmlToPdf.getPdf('周报',loadingFlag.value, 'a4' );
    // 定时器模拟按钮loading动画的时间
    setTimeout(() => {
        loadingFlag.value = false;
    }, 1000);
};


//复制功能
const copyUrl = (rf: any) => {
    let node = document.getElementById(rf)
    if (window.getSelection() && node) {
        window.getSelection()?.selectAllChildren(node);
    }
    let copy = document.execCommand("Copy");
    if (copy) {
        document.execCommand("Copy");
        message.success('复制完成')
    } else {
        message.warning('由于浏览器版本问题，复制失败，请手动复制~')
    }
}
const router = useRouter();
const pagination = computed(() => ({
    total: searchModel.value.total,
    current: searchModel.value.skip,
    pageSize: searchModel.value.take,
}));
let searchModel: any = ref({
    date: null,
    type: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    order: -1,
    groupId: null
})
let conditionNum: any = ref({
    n1: 1,
    n2: 2,
    n3: 3,
    n4: 0,
});
let groupList: any = ref([]);
let weekReport: any = ref({
    inspectionReport: []
});
let weekReports: any = ref([]);

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory();
};
const columns = [
    {
        title: langObj.value['报告周期'],
        dataIndex: 'cycle',
    },
    {
        title: langObj.value['报告标题'],
        dataIndex: 'title',
    },

    {
        title: langObj.value['健康设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count1',
    },
    {
        title: langObj.value['可用设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count2',
    },
    {
        title: langObj.value['警戒设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count3',
    },
    {
        title: langObj.value['故障设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count4',
    },
    {
        title: langObj.value['分析周期'],
        dataIndex: 'cycle2',
    },
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]

let data: any = ref([])

onMounted(() => {
    init()
})

const init = () => {

    search()
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        date: null,
        type: null,
    }
    search()
}
const getReportInfo = async (id: any) => {
    let config: any = {
        params: {
            id: id,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get('/api/reports/info', config);
    groupList.value = [{
        name: '全部',
        sonID: '',
    }]
    if (result && result.data) {
        weekReport.value = result.data;

        if(language.value === 'English') {
            weekReport.value.title = weekReport.value.title.replace('周报', 'week report')
        }


        let groups = result.data.inspectionReport?.map((d: any) => ({
            sonID: d.groupId,
            name: d.groupName
        }))
        let groupIds = [...new Set(groups?.map((d: any) => d.sonID))]
        groupIds?.map((d: any) => {
            groupList.value.push({
                name: groups.find((p: any) => p.sonID === d)?.name,
                sonID: d
            })
        })

        setTimeout(() => {
            // weekReport.value.inspectionReport = []
            getMachineNum()
            getMachineSattaus();
            if (weekReport.value && weekReport.value.inspectionReport) {
                weekReports.value = JSON.parse(JSON.stringify(weekReport.value?.inspectionReport || []))
                weekReports.value.map((d: any, i: any) => {
                    getMachineTrend(d, 'chartC' + i)
                })
            }
            
        }, 500);


    }

};


// 本周巡检设备数
const getMachineNum = () => {
    console.log(weekReport.value.inspectionReport)
    if (!weekReport.value.inspectionReport) {
        return false
    }
    let inspectionReport = JSON.parse(JSON.stringify(weekReport.value.inspectionReport))
    let list = []
    inspectionReport.map((d: any) => {
        list = list.concat(d.trend)
    })
    list = list.sort((a: any, b: any) => a.date - b.date)
    let dates = [...new Set(list.map((d: any) => d.date).map((p: any) => p.substring(5, 10)))]
    let yData = []
    dates.map((d: any) => {
        yData.push(list.filter((p: any) => p.date.includes(d)).length)
    })
    let xData = dates
    if (!document.getElementById('chartA')) return
    let myChart = echarts.init(document.getElementById('chartA') as HTMLDivElement);



    let option = {
        grid: {
            left: '12%',
            right: '5%',
            top: '15%',
            bottom: '15%'
        },
        xAxis: {
            type: 'category',
            data: xData
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: yData,
            type: 'line'
        }]
    }
    myChart.setOption(option)

    // this.$refs.chartA.init(echarts, (chart) => {
    // 	this.$refs.chartA.setOption(option);
    // });
};
// 本周健康设备总览
const getMachineSattaus = () => {
    if (!weekReport.value.inspectionReport) {
        return false
    }
    let inspectionReport = JSON.parse(JSON.stringify(weekReport.value.inspectionReport))
    conditionNum.value.n1 = inspectionReport.filter((p: any) => p.condition == 0)?.length
    conditionNum.value.n2 = inspectionReport.filter((p: any) => p.condition >= 1 && p.condition <= 2)?.length
    conditionNum.value.n3 = inspectionReport.filter((p: any) => p.condition >= 3 && p.condition <= 5)?.length
    conditionNum.value.n4 = inspectionReport.filter((p: any) => p.condition >= 6 && p.condition <= 7)?.length
    if (!document.getElementById('chartB')) return
    let myChart = echarts.init(document.getElementById('chartB') as HTMLDivElement);

    let DData = [{
        value: conditionNum.value.n1,
        name: '健康设备数：' + conditionNum.value.n1
    },
    {
        value: conditionNum.value.n2,
        name: '可用设备数：' + conditionNum.value.n2
    },
    {
        value: conditionNum.value.n3,
        name: '警戒设备数：' + conditionNum.value.n3
    },
    {
        value: conditionNum.value.n4,
        name: '故障设备数：' + conditionNum.value.n4
    },
    ]
    let option = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: "总设备数:" + (conditionNum.value.n1 + conditionNum.value.n2 + conditionNum.value.n3 + conditionNum.value.n4),
                textAlign: "center",
                fontSize: 12,
                fill: theme.value=='white'? "#333" :"#ffffff",
            },
            tooltip: {
                show: false,
            },
        },
        series: [{
            name: "",
            type: "pie",
            radius: ["65%", "90%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: "center",
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: 12,
                    fontWeight: "bold",
                    formatter: "{b}\n{c} ({d}%)",
                },
            },
            labelLine: {
                show: false,
            },
            data: DData,
        },],
        color: ['#61c08f', '#d2de49', '#cd5f3b', '#921e37']
    };
    myChart.setOption(option)
    // this.$refs.chartB.init(echarts, (chart) => {
    //     this.$refs.chartB.setOption(option);
    // });
};

// 周报设备详情
const getMachineTrend = (data: any, id: any) => {
    let xData = []
    let yData = []
    if (!document.getElementById(id)) return
    let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);

    data?.trend?.map((d: any) => {
        xData.push(d.date.substring(5, 16))
        let condition = d.condition || 0
        if (condition == 0) {
            yData.push(0)
        }
        if (condition >= 1 && condition <= 2) {
            yData.push(1)
        }
        if (condition >= 3 && condition <= 5) {
            yData.push(2)
        }
        if (condition >= 6 && condition <= 7) {
            yData.push(3)
        }
    })

    let option = {
        grid: {
            left: '12%',
            right: '5%',
            top: '15%',
            bottom: '15%'
        },
        xAxis: {
            type: 'category',
            data: xData,
            axisLine: {
                show: false
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10
                },
            }
        },
        yAxis: {
            type: 'value',
            min: 0, //給个最小值
            max: 3, //给个最大值
            splitNumber: 4, //必须加将坐标轴分割成指定数量的段数
            splitLine: {
                lineStyle: {
                    type: "dashed", //虚线
                    color: ['#61c08f', '#d2de49', '#cd5f3b', '#921e37']
                },
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10
                },
                formatter: function (value) {
                    if (value == 0) {
                        return `{a|${'健康'}}`
                    }
                    if (value == 1) {
                        return `{b|${'可用'}}`
                    }
                    if (value == 2) {
                        return `{c|${'警戒'}}`
                    }
                    if (value == 3) {
                        return `{d|${'故障'}}`
                    }
                },
                rich: {
                    a: {
                        color: '#61c08f'
                    },
                    b: {
                        color: '#d2de49'
                    },
                    c: {
                        color: '#cd5f3b'
                    },
                    d: {
                        color: '#921e37'
                    },
                }
            },
        },
        series: [{
            data: yData,
            type: 'line'
        }]
    }

    // 画图
    myChart.setOption(option)
    // this.$refs[id][0].init(echarts, (chart) => {
    // 	this.$refs[id][0].setOption(option);
    // });
};





const getData = async () => {
    let type = searchModel.value.type ? [searchModel.value.type] : [3, 4]
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            factoryIds: getRealFactoryIdList(),
            type:[10]
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)


    if (result && result.status === 200) {
        result.data.list.map((d: any) => {
            let count1 = 0, count2 = 0, count3 = 0, count4 = 0
            d.inspectionReport && d.inspectionReport.map((i: any) => {
                if (i.condition == 0) {
                    count1++
                }
                if (i.condition >=1 && i.condition<=2) {
                    count2++
                }
                if (i.condition >=3 && i.condition <=5) {
                    count3++
                }
                if (i.condition >=6 && i.condition <=7) {
                    count4++
                }
            })
            d.count1 = count1
            d.count2 = count2
            d.count3 = count3
            d.count4 = count4

            if (language.value === 'English') {
                d.title = d.title.replace('周报', 'weekly report')
            }
        })


        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

const details = (ev: any) => {
    // router.push('/report/mwDetail/' + ev.id)
    visibleReport.value = true;
    getGroupList()
    getReportInfo(ev.id)
}



const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        searchModel.value.sort = sorter.field
        searchModel.value.order = order
    }
    // 处理分页数据
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getData();
};

const getDate = (date: any) => {
    date = new Date(date)
    let year = date.getFullYear()
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    let w = date.getDay(),
        d = date.getDate();
    if (w == 0) {
        w = 7;
    }

    if (language.value == 'Chinese') {
        return year + '年' + month + '月' + ' ' + '第' + Math.ceil((d + 6 - w) / 7) + '周'
    } else {
        let monList = [
            { label: 'January', value: '01' },
            { label: 'February', value: '02' },
            { label: 'March', value: '03' },
            { label: 'April', value: '04' },
            { label: 'May', value: '05' },
            { label: 'June', value: '06' },
            { label: 'July', value: '07' },
            { label: 'August', value: '08' },
            { label: 'January', value: '09' },
            { label: 'October', value: '10' },
            { label: 'November', value: '11' },
            { label: 'December', value: '12' },
        ]
        return monList.find((p: any) => p.value == month)?.label + ' ' + year + ',' + 'Week' + Math.ceil((d + 6 - w) / 7)
    }


}
const getDateCycle = (date: any) => {
    let time = new Date(date)
    let startDate = time.getTime() - 1000 * 60 * 60 * 24 * 7
    return `${getSimpleDate(startDate)} - ${getSimpleDate(time)}`
};
const getSimpleDate = (date: any) => {
    let time = new Date(date)
    let year = time.getFullYear();
    let mon = (time.getMonth() + 1) <= 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
    let day = time.getDate() <= 9 ? '0' + time.getDate() : time.getDate();
    return year + '/' + mon + '/' + day
};
const getDate2 = (ev: any, type: any) => {
    let day = type === 3 ? 7 : 30
    let date = new Date(ev).getTime() - (1000 * 60 * 60 * 24 * day)
    let begin = transformDate3(new Date(date))
    return begin + ' ~ ' + transformDate3(ev)
}

const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
    dates.value = val;
};



return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_CopyOutlined = _resolveComponent("CopyOutlined")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createVNode(_component_a_form, {
            model: _unref(searchModel),
            name: "horizontal_login",
            layout: "inline",
            autocomplete: "off"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, {
                label: _unref(langObj)['报告时间'],
                name: "status"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_range_picker, {
                    value: _unref(searchModel).date,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).date) = $event)),
                    "disabled-date": disabledDate,
                    onCalendarChange: onCalendarChange
                  }, null, 8, ["value"])
                ]),
                _: 1
              }, 8, ["label"]),
              _createVNode(_component_a_form_item, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      onClick: reset,
                      class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                    }, _toDisplayString(_unref(langObj)['重置']), 1),
                    _createElementVNode("div", {
                      onClick: search,
                      class: "btn px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                      style: {"background-color":"#072499"}
                    }, _toDisplayString(_unref(langObj)['查询']), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            size: "small",
            "row-class-name": getRowClassName,
            columns: columns,
            "data-source": _unref(data),
            pagination: pagination.value,
            onChange: handleTableChange
          }, {
            bodyCell: _withCtx(({ column, text, record }) => [
              (column.dataIndex === 'cycle')
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(getDate(record.date)), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'cycle2')
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(getDate2(record.date, record.type)), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'type')
                ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(text == 3 ? _unref(langObj)['周报'] : _unref(langObj)['月报']), 1))
                : _createCommentVNode("", true),
              (column.dataIndex === 'act')
                ? (_openBlock(), _createElementBlock("a", {
                    key: 3,
                    onClick: ($event: any) => (details(record)),
                    class: "theme-table-link"
                  }, _toDisplayString(_unref(langObj)['查看详情']), 9, _hoisted_7))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-source", "pagination"])
        ]),
        _createVNode(_component_a_modal, {
          visible: _unref(visibleReport),
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => (_isRef(visibleReport) ? (visibleReport).value = $event : visibleReport = $event)),
          closable: true,
          okText: "生成PDF",
          bodyStyle: { background: '#020F46' },
          style: {"top":"30%"},
          width: "700px",
          maskClosable: false,
          onOk: pdfFunc
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(langObj)["巡检周报"]), 1),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(weekReport).title), 1)
              ]),
              _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(langObj)['报告周期']) + "：" + _toDisplayString(getDate(_unref(weekReport).date) || "--"), 1),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(langObj)['分析周期']) + "：" + _toDisplayString(getDateCycle(_unref(weekReport).date) || "--"), 1),
              _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)['报告时间']) + "：" + _toDisplayString(_unref(weekReport).date || "--"), 1),
              _createElementVNode("div", _hoisted_15, _toDisplayString(_unref(langObj)['本周巡检设备数']), 1),
              _cache[11] || (_cache[11] = _createElementVNode("div", {
                id: "chartA",
                class: "mb-2",
                style: {"width":"100%","height":"200px"}
              }, null, -1)),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(langObj)['本周巡检设备健康总览']), 1),
              _createElementVNode("div", _hoisted_17, [
                _cache[10] || (_cache[10] = _createElementVNode("div", {
                  id: "chartB",
                  style: {"width":"200px","height":"200px"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _cache[6] || (_cache[6] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#61c08f"} }, null, -1)),
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(langObj)['健康']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionNum).n1) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[7] || (_cache[7] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(langObj)['可用']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionNum).n2) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _cache[8] || (_cache[8] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
                    _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)['警戒']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionNum).n3) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _cache[9] || (_cache[9] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(langObj)['故障']) + "：", 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(conditionNum).n4) + _toDisplayString(_unref(getLanguage)("台", _unref(language))), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(langObj)['设备详情']), 1),
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_a_select, {
                  value: _unref(searchModel).groupId,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                  style: {"display":"block","width":"200px","margin":"0 auto"},
                  allowClear: "",
                  placeholder: _unref(langObj)['请选择区域']
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item.id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "placeholder"])
              ]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(weekReport).inspectionReport, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "mb-2",
                  id: 'chartC' + index,
                  key: index,
                  style: {"width":"100%","height":"200px"}
                }, null, 8, _hoisted_29))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["visible"]),
        _createVNode(_component_a_modal, {
          width: 850,
          maskClosable: false,
          visible: _unref(visible),
          "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
          footer: null,
          title: _unref(langObj)['周报转发：复制链接转发，用户可在电脑端或手机端查看']
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", null, _toDisplayString(_unref(langObj)['pc端链接']) + ": ", 1),
              _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(urlpc)), 1),
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (copyUrl('pc')))
              }, [
                _createVNode(_component_CopyOutlined)
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("div", null, _toDisplayString(_unref(langObj)['手机端链接']) + ": ", 1),
              _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(urlmb)), 1),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createElementVNode("div", {
                class: "cursor-pointer",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (copyUrl('mb')))
              }, [
                _createVNode(_component_CopyOutlined)
              ])
            ])
          ]),
          _: 1
        }, 8, ["visible", "title"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})