import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bule_list_page theme-common" }
const _hoisted_2 = { class: "main" }

import request from "../../common/request";
import { useRouter } from "vue-router";
import { ref, reactive, onMounted, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "@/common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { getLanguage } from "../../common/translate";

import { operateLogs } from "@/common/logs";


export default /*@__PURE__*/_defineComponent({
  __name: 'MqttSetting',
  setup(__props) {

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();
const router = useRouter();
const loading = ref(false);
const formRef = ref();
/**操作日志记录 */
operateLogs("访问角色管理");
/**操作日志记录 */
const formState = reactive({
  keyWork: "",
  skip: 1,
  take: 10,
  factoryId: "",
  total: 0,
});

const modalState = reactive({
  visible: false,
  title: langObj.value["新增"],
  confirmLoading: false,
});

let modalForm = reactive({
  username: "",
  password: "",
  machineIds: [],
  topics: ["", "", ""],
  qos: 1,
  id: 0,
  factoryId: "",
});

let total: any = ref(0);

let pagination = computed(() => ({
  total: formState.total,
  current: formState.skip,
  pageSize: formState.take,
}));

const dataSource = ref<any>();
const columns = [
  {
    title: getLanguage("订阅号", language.value),
    dataIndex: "username",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("订阅验证码", language.value),
    dataIndex: "password",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("QOS", language.value),
    dataIndex: "qos",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("主题", language.value),
    dataIndex: "topics",
    width: "15%",
    align: "center",
  },
  {
    title: getLanguage("设备名称", language.value),
    dataIndex: "machines",
    width: "55%",
    align: "center",
  },
  {
    title: getLanguage("操作", language.value),
    dataIndex: "id",
    align: "center",
  },
];
// 新增
const add = () => {
  modalState.title = langObj.value["新增"];
  modalForm = reactive({
    username: "",
    password: "",
    machineIds: [],
    topics: [],
    qos: 1,
    id: 0,
    factoryId: "",
  });
  modalState.visible = true;
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 200);
};
const deleteThis = (val: any) => {
  const config = {
    params: { id: val.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/roles/info", config).then((res) => {
    if (res) {
      operateLogs("删除mqtt配置：" + val.name + ",id:" + val.id);
      message.success("操作成功");
      search();
    }
  });
};
const formStateRef = ref<any>();

const editThis = (val: any) => {
  modalState.title = langObj.value["编辑"];
  modalState.visible = true;
  modalForm = reactive(JSON.parse(JSON.stringify(val)));
  setTimeout(() => {
    formStateRef.value.clearValidate();
  }, 500);
};
const clearValidate = (name: any) => {
  formStateRef.value.clearValidate(name);
};
const resetForm = () => {
  formStateRef.value.resetFields();
};
const checkedNodes = ref<any>([]);
const selectTree = (key: any, val: any) => {
  checkedNodes.value = val.checkedNodes;
};

const changeUserName = () => {
  modalForm.topics[0] = "aifreqx/" + modalForm.username + "/data";
  modalForm.topics[1] = "aifreqx/" + modalForm.username + "/query";
  modalForm.topics[2] = "aifreqx/" + modalForm.username + "/result";
};

const makeUserName = () => {
  modalForm.username = uuidv4().substring(0, 21).replaceAll("-", "");
  changeUserName();
};

const makePassword = () => {
  modalForm.password = uuidv4().replaceAll("-", "");
};

// 保存
const handleOk = () => {
  formStateRef.value
    .validate()
    .then((result: any) => {
      let form = JSON.parse(JSON.stringify(modalForm));

      if (form.id) {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/factory/mqtt-setting", form, config).then((res) => {
          if (res) {
            operateLogs("修改mqtt配置：" + form.roleName + ",id:" + form.id);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      } else {
        const config = {
          headers: {
            requestId: uuidv4(),
          },
        };
        request.post("/api/factory/mqtt-setting", form, config).then((res) => {
          if (res) {
            operateLogs("添加mqtt配置：" + form.roleName);
            modalState.visible = false;
            message.success("操作成功");
            search();
          }
        });
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  formState.skip = pagination.current;
  formState.take = pagination.pageSize;
  search();
};
// 查询
const search = () => {
  const config = {
    params: {
      skip: formState.skip,
      take: formState.take,
      keyword: formState.keyWork,
      factoryId: formState.factoryId,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factory/mqtt-settings", config).then((res: any) => {
    if (res) {
      dataSource.value = res.data.list;
      formState.total = res.data.count;
      // total.vlaue = res.data.count
    }
  });
};
search();

//***角色车间***//
onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
  search();
});

let factoryList = ref<any>();

let allFactory = ref<any>([]);
let allGroup = ref<any>([]);
let allMachine = ref<any>([]);

const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      res.data.forEach((element: any) => {
        allGroup.value = allGroup.value.concat(element.groups);
      });
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId);
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id);
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory;
        }
        factory.push(d);
      });
      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};

const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id);
  let config = {
    headers: {
      requestId: requestId,
    },
  };
  let res = await request.post(
    "/api/machines/all",
    { factoryIds: factoryIds },
    config
  );

  factoryList.value.forEach((element: any) => {
    allMachine.value = res.data.map((t: any) => t.id);
    element.children.forEach((sonFact: any) => {
      if (sonFact.type == "factory" && sonFact.children) {
        sonFact.children.forEach((groupFact: any) => {
          if (groupFact.type == "group") {
            groupFact.children = res.data
              .filter((t: any) => t.groupId == groupFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
          }
        });
      } else if (sonFact.type == "group") {
        sonFact.children = res.data
          .filter((t: any) => t.groupId == sonFact.value)
          .map((t: any) => ({
            type: "machine",
            ...t,
            value: t.id,
            label: t.machineName,
            title: t.machineName,
            key: t.id,
          }));
      }
    });
  });
};

return (_ctx: any,_cache: any) => {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 20px"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, { layout: "inline" }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('工厂', _unref(language).value)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: formState.factoryId,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((formState.factoryId) = $event)),
                      style: {"min-width":"200px"},
                      placeholder: _unref(getLanguage)('选择工厂', _unref(language).value),
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('账号', _unref(language).value)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: formState.keyWork,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((formState.keyWork) = $event)),
                      placeholder: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: search
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(getLanguage)("搜索", _unref(language).value)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: add
                    }, {
                      icon: _withCtx(() => [
                        _createVNode(_component_plus_outlined)
                      ]),
                      default: _withCtx(() => [
                        _createTextVNode(" " + _toDisplayString(_unref(langObj)["新增"]), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_table, {
                bordered: "",
                emptyText: _unref(langObj)['暂无数据'],
                columns: columns,
                "data-source": dataSource.value,
                pagination: _unref(pagination),
                loading: loading.value,
                onChange: handleTableChange,
                size: "small"
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'machines')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(text, (item, index) => {
                        return (_openBlock(), _createElementBlock("span", { key: index }, [
                          _createVNode(_component_a_tag, { color: "#2db7f5" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.machineName), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'topics')
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(text, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(item), 1))
                      }), 128))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'id')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                        _createVNode(_component_a_popconfirm, {
                          title: _unref(langObj)['确定删除此条数据么'] + '?',
                          "ok-text": _unref(langObj)['确定'],
                          "cancel-text": _unref(langObj)['取消'],
                          onConfirm: ($event: any) => (deleteThis(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_button, {
                              type: "primary",
                              danger: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_delete_outlined),
                                _createTextVNode(" " + _toDisplayString(_unref(langObj)["删除"]), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"]),
                        _createVNode(_component_a_button, {
                          type: "primary",
                          class: "plft",
                          onClick: ($event: any) => (editThis(record))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_edit_outlined),
                            _createTextVNode(" " + _toDisplayString(_unref(langObj)["编辑"]), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"])
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["emptyText", "data-source", "pagination", "loading"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_a_modal, {
          width: 850,
          maskClosable: false,
          visible: modalState.visible,
          "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((modalState.visible) = $event)),
          title: modalState.title,
          cancelText: _unref(langObj)['关闭'],
          okText: _unref(langObj)['确定'],
          "confirm-loading": modalState.confirmLoading,
          onOk: handleOk
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form, {
              model: _unref(modalForm),
              ref_key: "formStateRef",
              ref: formStateRef,
              name: "basic",
              autocomplete: "off",
              "label-col": { style: { width: '150px' } }
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['工厂'],
                  name: "factoryId",
                  onChange: _cache[3] || (_cache[3] = ($event: any) => (clearValidate('factoryId'))),
                  rules: [
              {
                required: true,
                message: _unref(langObj)['请选择工厂'],
                trigger: 'blur',
              },
            ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(modalForm).factoryId,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(modalForm).factoryId) = $event)),
                      style: {"width":"100%"},
                      placeholder: _unref(langObj)['选择工厂'],
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factoryList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value", "placeholder"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('订阅号', _unref(language).value),
                  name: "username",
                  onChange: changeUserName,
                  rules: [
              {
                required: true,
                message: _unref(getLanguage)('请输入订阅号', _unref(language).value),
                trigger: 'blur',
              },
            ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).username,
                      "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(modalForm).username) = $event)),
                      placeholder: _unref(getLanguage)('输入订阅号', _unref(language).value)
                    }, null, 8, ["value", "placeholder"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (makeUserName()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(getLanguage)("生成", _unref(language).value)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('订阅验证码', _unref(language).value),
                  name: "password",
                  onChange: _cache[8] || (_cache[8] = ($event: any) => (clearValidate('password'))),
                  rules: [
              {
                required: true,
                message: _unref(getLanguage)('请输入订阅验证码', _unref(language).value),
                trigger: 'blur',
              },
            ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).password,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(modalForm).password) = $event)),
                      placeholder: _unref(getLanguage)('请输入订阅验证码', _unref(language).value)
                    }, null, 8, ["value", "placeholder"]),
                    _createVNode(_component_a_button, {
                      type: "primary",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (makePassword()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(getLanguage)("生成", _unref(language).value)), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["label", "rules"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('实时订阅主题', _unref(language).value),
                  name: "topics",
                  onChange: _cache[10] || (_cache[10] = ($event: any) => (clearValidate('topics')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).topics[0],
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(modalForm).topics[0]) = $event)),
                      readonly: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('历史查询主题', _unref(language).value),
                  name: "topics",
                  onChange: _cache[12] || (_cache[12] = ($event: any) => (clearValidate('topics')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).topics[1],
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(modalForm).topics[1]) = $event)),
                      readonly: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('历史订阅主题', _unref(language).value),
                  name: "topics",
                  onChange: _cache[14] || (_cache[14] = ($event: any) => (clearValidate('topics')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_input, {
                      value: _unref(modalForm).topics[2],
                      "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(modalForm).topics[2]) = $event)),
                      readonly: ""
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, {
                  label: _unref(getLanguage)('设备', _unref(language).value),
                  name: "machineIds",
                  onChange: _cache[16] || (_cache[16] = ($event: any) => (clearValidate('machineIds'))),
                  rules: [
              {
                required: true,
                message: _unref(getLanguage)('请选择设备', _unref(language).value),
                trigger: 'blur',
              },
            ]
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tree, {
                      checkedKeys: _unref(modalForm).machineIds,
                      "onUpdate:checkedKeys": _cache[15] || (_cache[15] = ($event: any) => ((_unref(modalForm).machineIds) = $event)),
                      checkable: "",
                      checkStrictly: true,
                      "tree-data": _unref(factoryList),
                      onCheck: selectTree
                    }, {
                      title: _withCtx(({ title, key }) => [
                        _createTextVNode(_toDisplayString(title), 1)
                      ]),
                      _: 1
                    }, 8, ["checkedKeys", "tree-data"])
                  ]),
                  _: 1
                }, 8, ["label", "rules"])
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _: 1
        }, 8, ["visible", "title", "cancelText", "okText", "confirm-loading"])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})