import Aes from "crypto-js/aes";
import CryptoJS from "crypto-js";

const transformDate = (date:any) => {
    const time = new Date(date)
    const year = time.getFullYear()
    const month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
    const day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
    const hour = time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
    const min = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
    const second = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
    return `${year}/${month}/${day} ${hour}:${min}:${second}`
}

const transformDate2 = (date:any, type:any) => {
    const time = new Date(date)
    const year = time.getFullYear()
    const month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
    const day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
    
    if(type === 1) {
        return `${year}/${month}/${day} 00:00:00`
    } else if (type === 2) {
        return `${year}/${month}/${day} 23:59:59`
    }
}

const transformDate3 = (date:any) => {
    const time = new Date(date)
    const year = time.getFullYear()
    const month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
    const day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
    return `${year}/${month}/${day}`
}

const getRealFactoryIdList = () => {
    const factory_id = localStorage.getItem('factory_id')
    if (!factory_id) return [];

    const memberInfo:any = JSON.parse(localStorage.getItem('memberInfo') || '')
    const factories = memberInfo.factories || []
    // console.log('factories', factories)
    // console.log('factory_id', factory_id)
    let factoryIds:any = []
    const list = factories.filter((p:any) => p.parentId === factory_id)

    if(list && list.length) {
        factoryIds = list.map((p:any) => p.id)
    } else {
        factoryIds = [factory_id]
    }
    return factoryIds
}

const getRealFactoryIdListById = (factory_id:any) => {
    if (!factory_id) return [];
    const memberInfo:any = JSON.parse(localStorage.getItem('memberInfo') || '')
    const factories = memberInfo.factories || []

    // console.log('factories', factories)
    // console.log('factory_id', factory_id)
    let factoryIds:any = []
    const list = factories.filter((p:any) => p.parentId === factory_id)

    if(list && list.length) {
        factoryIds = list.map((p:any) => p.id)
    } else {
        factoryIds = [factory_id]
    }
    return factoryIds
}

const getFactoryName = (id:any) => {
    const memberInfo:any = JSON.parse(localStorage.getItem('memberInfo') || '')
    const factories = memberInfo.factories
    const info = factories.find((p:any) => p.id === id)
    return info ? info.name : ''
}

const getGroupName = (id:any) => {
    const memberInfo:any = JSON.parse(localStorage.getItem('memberInfo') || '')
    const groups = memberInfo.groups
    const info = groups.find((p:any) => p.id === id)
    return info ? info.name : ''
}

const getGroupListByFactory = () => {
    const memberInfo:any = JSON.parse(localStorage.getItem('memberInfo') || '')
    const groups = memberInfo.groups || []
    const factory_id = localStorage.getItem('factory_id')
    const groupList = groups.filter((p:any) => p.factoryId === factory_id || p.parentId === factory_id)
    return groupList
}

const getGroupListByFacID = (factoryId:any) => {
    const memberInfo:any = JSON.parse(localStorage.getItem('memberInfo') || '[]')
    const groups = memberInfo.groups || []
    const factory_id = factoryId
    const groupList = groups.filter((p:any) => p.factoryId === factory_id || p.parentId === factory_id)
    return groupList
}

const getChartDataTool = (data:any) => {
    if(!data) {
        return {axisX: [], axisY: []}
    }
    const xData:any = []
    const {axisX, axisY} = data
    const start = 0
    const length = axisY.length
    const interval = axisX
    console.log('interval', interval)
    for (let i = 1; i <= length; i++) {
        const x = start + i * interval
        xData.push(x)
    }
    return {axisX: xData, axisY}
}

const getMemberOnboarding = () =>{
    const memberInfo:any = JSON.parse(localStorage.getItem('memberInfo') || '[]')
    if(memberInfo.isRegistered){
        if (!memberInfo.onboardstatus)
        {
          // 新注册用户,未完成新用户引导的，需进行新用户引导
          return false;
        }
        return true;
    }else{
        return true;
    }
}

const _k = (function () { return '$Y6hE3' + '%ZCGTn0' + 'b9Sl20mm' + "VANksF4WQuq" })();
export const encryptAes = (text: string): string => {
    let key = CryptoJS.enc.Utf8.parse(_k);
    return Aes.encrypt(text, key,{mode: CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7}).toString();
}

export function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export {
    transformDate,
    transformDate2,
    transformDate3,
    getRealFactoryIdList,
    getFactoryName,
    getGroupName,
    getGroupListByFactory,
    getRealFactoryIdListById,
    getGroupListByFacID,
    getChartDataTool,
    getMemberOnboarding
}
