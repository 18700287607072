<template>
  <div class="box">
    <div class="w-full flex px-4 theme_equipment_tab theme-box-title whiteBg" style="border-bottom: 1px solid #4a5983">
      <div style="font-size: 18px" class="py-3 cursor-pointer" @click="selectMenu(1)"
        :class="menuSelect === 1 ? 'theme_equipment_tab_select' : ''">
        {{ langObj['设备列表'] }}
      </div>

      <div style="font-size: 18px" class="py-3 ml-12 cursor-pointer" @click="selectMenu(2)"
        :class="menuSelect === 2 ? 'theme_equipment_tab_select' : ''">
        {{ langObj['测点列表'] }}
      </div>
    </div>

    <div class="p-3 w-full whiteBg">
      <div v-if="menuSelect === 1">
        <a-form :model="searchModel" name="horizontal_login" layout="inline" autocomplete="off">
          <a-form-item :label="langObj['设备区域']" name="groupId" allowClear>
            <a-select v-model:value="searchModel.groupId" style="width: 220px" allowClear
              :placeholder="langObj['请选择区域']">
              <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['健康状态']" name="condition">
            <a-select v-model:value="searchModel.condition" style="width: 220px" allowClear
              :placeholder="langObj['请选择健康状态']">
              <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['运行状态']" name="status">
            <a-select v-model:value="searchModel.status" style="width: 220px" allowClear
              :placeholder="langObj['请选择运行状态']">
              <a-select-option v-for="(item, index) in statusList" :key="index" :value="item.value">
                {{ langObj[item.label] }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="getLanguage('是否配置提资信息')" name="isUpgradeMachineType">
            <a-select v-model:value="searchModel.isUpgradeMachineType" style="width: 220px" allowClear
              :placeholder="langObj['请选择']">
              <a-select-option v-for="(item, index) in whetherList" :key="index" :value="item.value">
                {{ langObj[item.label] }}
              </a-select-option>
            </a-select>
          </a-form-item>


          <a-form-item :label="langObj['搜索']" name="status">
            <a-input v-model:value="searchModel.keyWord" :placeholder="langObj['请输入设备名称/编号']" />
          </a-form-item>

          <a-form-item>
            <div class="flex items-center">
              <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">
                {{ langObj['重置'] }}
              </div>

              <div @click="getData" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                style="background-color: #072499">
                {{ langObj['查询'] }}
              </div>

              <div v-if="isEditable" @click="addMachine" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit addMachineDriver"
                style="background-color: #072499">
                {{ langObj['新建设备'] }}
              </div>

              <div v-if="isEditable" @click="visibleImportMachine = true"
                class="box px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499">
                {{ langObj['导入设备'] || '导入设备' }}
              </div>

            </div>
          </a-form-item>
        </a-form>

        <div class="box mt-4" style="width: 100%">
          <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="machineColumns"
            :data-source="data" :pagination="false">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'machineTypeAll'">
                <span>{{ getLanguage(text, language) }}</span>
              </template>

              <template v-if="column.dataIndex === 'isUpgradeMachineType'">
                <span>{{ getLanguage(whetherList.find((p:any) => p.value === text)?.label || '否') }}</span>
              </template>

              <template v-if="column.dataIndex === 'mode'">
                <span>{{ getMode(record.mode) }}</span>
              </template>

              <template v-if="column.dataIndex === 'factoryId'">
                <span>{{ getFactoryName(text) }}</span>
              </template>

              <template v-if="column.dataIndex === 'groupId'">
                <span>{{ getGroupName(text) }}</span>
              </template>

              <template v-if="column.dataIndex === 'experiment'">
                <span>{{
                  whetherList.find((p) => p.value === text)?.label
                }}</span>
              </template>

              <template v-if="column.dataIndex === 'condition'">
                <div class="flex items-center">
                  <div style="width: 14px; height: 14px; border-radius: 50%"
                    :style="{ background: getMachienCondition(record)?.color }"></div>
                  <div class="ml-1" :style="{ color: getMachienCondition(record)?.color }">
                    {{ langObj[getMachienCondition(record)?.label] }}
                  </div>
                </div>
              </template>

              <template v-if="column.dataIndex === 'status'">
                <div class="ml-1" :style="{ color: getMachienStatus(record)?.color }">
                  {{ langObj[getMachienStatus(record)?.label] }}
                </div>
              </template>

              <template v-if="column.dataIndex === 'act'">
                <a v-if="isEditable" @click="editMachine(record)" class="theme-table-link" style="color: #00fff4"> {{
                  langObj['编辑'] }}</a>
                <a-divider type="vertical" />
                <a @click="openMachineWarining(record)" style="color: #00fff4" class="theme-table-link"> {{
                  langObj['报警记录'] }}</a>
                <a-divider type="vertical" />
                <a @click="viewMachine(record)" style="color: #00fff4" class="theme-table-link"> {{ langObj['健康总览']
                  }}</a>
              </template>
            </template>
          </a-table>
        </div>
      </div>

      <div v-if="menuSelect === 2">
        <a-form :model="searchModel" name="horizontal_login" layout="inline" autocomplete="off">
          <a-form-item :label="langObj['设备区域']" name="groupId">
            <a-select v-model:value="searchModel.groupId" style="width: 220px" @change="selectGroup">
              <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item :label="langObj['设备']" name="machineId">
            <a-select v-model:value="searchModel.machineId" style="width: 220px">
              <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          、

          <a-form-item :label="langObj['测点位置']" name="status">
            <a-input :placeholder="langObj['请输入测点位置']" v-model:value="searchModel.keyWord" />
          </a-form-item>

          <a-form-item>
            <div class="flex items-center">
              <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">
                {{ langObj['重置'] }}
              </div>

              <div @click="getSensorList" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                style="background-color: #072499">
                {{ langObj['查询'] }}
              </div>

              <div v-if="isEditable" @click="addSensor" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                style="background-color: #072499">
                {{ langObj['新建测点'] }}
              </div>
            </div>
          </a-form-item>
        </a-form>

        <div class="box mt-4" style="width: 100%">
          <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="sensorColumns"
            :data-source="data">
            <template #bodyCell="{ column, text, record }">
              <template v-if="column.dataIndex === 'groupId'">
                <span>{{ getGroupName(record.machine.groupId) }}</span>
              </template>
              <template v-if="column.dataIndex === 'machineName'">
                <span>{{ record.machine.machineName }}</span>
              </template>
              <template v-if="column.dataIndex === 'hardware'">
                <span>{{
                  (record?.hardware?.vol || record?.hardware?.vol == 0) ? record.hardware.vol + ' %' : ''
                }}</span>
              </template>
              <template v-if="column.dataIndex === 'validity'">
                <span>{{ getValidity(record.validityEnd) }}</span>
              </template>
              <template v-if="column.dataIndex === 'inspectionSubs'">
                <span>{{ getInspectionSubs(record.inspectionSubs) }}</span>
              </template>
              <template v-if="column.dataIndex === 'act'">
                <a v-if="isEditable" @click="editSensor(record)" class="theme-table-link editSensorDriver">{{ langObj['编辑'] }}</a>
              </template>
              <template v-if="column.dataIndex === 'parameter'">
                <div class="flex items-center">
                  <div v-for="(item, index) in record.sensorGroup" :key="index">
                    <a-tooltip placement="topLeft">
                      <template #title>
                        <div style="background: #fff">
                          <div>{{ langObj['传感器编号'] }}: {{ item.sonId }}</div>
                          <div>{{ langObj['安装坐标'] }}: {{ item.coordinate }}</div>
                          <div>{{ langObj['轴名称'] }}: {{ langObj[item.sensorGroupName] }}</div>
                        </div>
                      </template>
                      <a-tag color="#2db7f5">{{ item.coordinate }}</a-tag>
                    </a-tooltip>
                  </div>
                </div>
              </template>
            </template>
          </a-table>
        </div>
      </div>
    </div>

    <!-- 新建测点 -->
    <a-modal class="whiteBg" v-model:visible="visibleAddSensor" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
      style="top: 30%" width="1300px" :maskClosable="false" v-model:z-index="editRindex">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddSensor = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">
          {{ sensorForm.id ? langObj["编辑测点"] : langObj["新建测点"] }}
        </div>

        <div class="w-full mt-4">
          <a-form ref="sensorForm1" :model="sensorForm" name="horizontal_login" layout="inline" autocomplete="off"
            class="w-full" :label-col="{ style: { width: '150px' } }">
            <a-row class="w-full" :gutter="24">
              <a-col :span="8">
                <a-form-item :label="langObj['是否有网关']" name="isDas">
                  <a-switch v-model:checked="sensorForm.isDas" :checked-children="langObj['是']"
                    :un-checked-children="langObj['否']" />
                </a-form-item>
              </a-col>
              <a-col :span="8">
                <a-form-item :label="langObj['数采器编码']" name="dasCode" :required="sensorForm.isDas">
                  <a-input v-model:value="sensorForm.dasCode" :placeholder="langObj['请输入数采器编码']"></a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row class="w-full" :gutter="24">
              <a-col :span="8">
                <a-form-item :label="langObj['传感器SN']" name="sensorSn" required>
                  <a-input v-model:value="sensorForm.sensorSn" :placeholder="langObj['请输入传感器SN']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['安装位置']" name="sensorPlace" required>
                  <a-input v-model:value="sensorForm.sensorPlace" :placeholder="langObj['请输入安装位置']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['所属设备']" name="machineName" required>
                  <div class="flex items-center">
                    <a-input v-model:value="sensorForm.machineName" :placeholder="langObj['请选择所属设备']"
                      readonly></a-input>
                    <div @click="selectMachineS" class="cursor-pointer btnTheme">
                      {{ langObj['选择'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="w-full" :gutter="24">
              <a-col :span="8">
                <a-form-item :label="langObj['转频提取']" name="fcSensorPlace">
                  <div class="flex items-center">
                    <a-input v-model:value="sensorForm.fcSensorPlace" :placeholder="langObj['请选择转频提取测点']"
                      readonly></a-input>
                    <div @click="selectSensorS" class="cursor-pointer btnTheme" >
                      {{ langObj['选择'] }}
                    </div>
                  </div>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['设备类型']" name="type">
                  <a-cascader style="max-width:236px" @change="changeThirdTypeId" v-model:value="sensorForm.type"
                    :options="machineTypeList" :placeholder="langObj['请选择设备类型']" :dropdownClassName="dropdownClassName"  />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="langObj['功率']" name="national">
                  <a-select style="width: 244px" v-model:value="sensorForm.national" :placeholder="langObj['请选择功率']" :dropdownClassName="dropdownClassName" >
                    <a-select-option v-for="(item, index) in powerEtcList" :key="index" :value="item.powerEtc">{{
                      item.powerEtc }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <!-- <a-col :span="8">
                <a-form-item label="主测点" name="onOff" required>
                  <a-switch v-model:checked="sensorForm.onOff" checked-children="开启" un-checked-children="关闭" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item label="指标间隔" name="quotaInterval" required>
                  <a-input-number style="width: 244px" id="inputNumber" v-model:value="sensorForm.quotaInterval" />
                </a-form-item>
              </a-col> -->

              <a-col :span="8">
                <a-form-item :label="langObj['是否启用']" name="disable">
                  <a-switch v-model:checked="sensorForm.disable" :checked-children="langObj['禁用']"
                    :un-checked-children="langObj['启用']" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="getLanguage('智能诊断巡检配置', language)" name="inspectionSubId">
                  <!-- <a-switch v-model:checked="sensorForm.inspectionSubId" :checked-children="langObj['禁用']" :un-checked-children="langObj['启用']" /> -->
                  <a-cascader @change="changeISub" v-model:value="sensorForm.inspectionSubs" :options="options" :dropdownClassName="dropdownClassName" 
                    placeholder="" />
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="getLanguage('转速范围', language)" name="defaultSpeed">
                  <a-select style="width: 244px" v-model:value="sensorForm.defaultSpeed" :placeholder="langObj['请选择']" :dropdownClassName="dropdownClassName" >
                    <a-select-option v-for="(item, index) in speedList" :key="index" :value="item._id">{{
                      item.speedRange }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="8">
                <a-form-item :label="getLanguage('测点排序', language)" name="sort">
                  <a-input-number style="width: 225px" v-model:value="sensorForm.sort" />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['数据类型']">
                  <a-select v-model:value="sensorForm.dataType" mode="multiple" :placeholder="langObj['请选择数据类型']" :dropdownClassName="dropdownClassName" >
                    <a-select-option v-for="(item, index) in dataTypeList" :key="index" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['默认展示数据类型']">
                  <a-select v-model:value="sensorForm.defaultDataType" mode="multiple"
                    :placeholder="langObj['请选择数据类型']" :dropdownClassName="dropdownClassName" >
                    <a-select-option v-for="(item, index) in sensorForm.dataType" :key="index" :value="item">
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="w-full" :gutter="24">
              <a-col :span="24">
                <a-form-item :label="langObj['备注']" name="remark" class="w-full">
                  <a-textarea v-model:value="sensorForm.remark" show-count :maxlength="200"
                    :placeholder="langObj['请输入备注']" :rows="4" />
                </a-form-item>
              </a-col>
            </a-row>

            <a-row class="w-full" :gutter="24">
              <a-col :span="24">
                <a-form-item :label="langObj['三轴参数']" name="sn" class="w-full">
                  <div class="w-full" style="border: 1px solid #0d53b7cc">
                    <a-table style="width: 100%" size="small" :pagination="false" :locale="{ emptyText: ' ' }"
                      :columns="sensorAddColumns" :data-source="sensorForm.sensorGroup">
                      <template #bodyCell="{ column, text, index, record }">
                        <template v-if="column.dataIndex === 'act'">
                          <a @click="deleteSensorParmar(index)" class="theme-table-link">{{ langObj['删除'] }}</a>
                        </template>
                        <template v-if="column.dataIndex === 'sonId'">
                          <a-input v-model:value="record.sonId" :placeholder="langObj['请输入编号']"></a-input>
                        </template>
                        <template v-if="column.dataIndex === 'coordinate'">
                          <a-input v-model:value="record.coordinate" :placeholder="langObj['请输入安装坐标']"></a-input>
                        </template>
                        <template v-if="column.dataIndex === 'sensorGroupName'">
                          <a-input v-model:value="record.sensorGroupName" :placeholder="langObj['请输入轴名称']"></a-input>
                        </template>
                      </template>
                    </a-table>
                    <div class="flex items-center">
                      <div @click="addSensorParmar" class="ml-4 my-2 cursor-pointer btnTheme">
                        {{ langObj['添加'] }}
                      </div>
                      <div @click="initNodeInfo" class="ml-4 my-2 cursor-pointer btnTheme" >
                        {{ langObj['生成三轴参数'] }}
                      </div>
                    </div>
                  </div>
                </a-form-item>
              </a-col>
            </a-row>

            <a-col :span="24">
              <a-form-item :label="langObj['测点照片']">
                <a-upload v-model:file-list="machineFileList" list-type="picture-card" @preview="handlePreview"
                  :custom-request="customRequest" :auto-upload="false" accept="image" :max-count="6">
                  <div v-if="!machineFileList || machineFileList.length < 6">
                    <plus-outlined />
                    <div style="margin-top: 8px; color: #fff">{{ langObj['上传'] }}</div>
                  </div>
                </a-upload>
              </a-form-item>
            </a-col>
          </a-form>

          <div class="w-full flex justify-center mt-4">
            <div @click="submitSensor" class="btn-default py-1 px-6 cursor-pointer btn-submit">
              {{ langObj['提交'] }}

            </div>
          </div>
        </div>
      </div>
    </a-modal>

    <!-- 新建设备 -->
    <a-modal v-model:visible="visibleAddMachine" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
      style="top: 30%" width="1300px" :maskClosable="false" v-model:z-index="editRindex">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddMachine = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">
          {{ machineForm.id ? langObj["编辑设备"] : langObj["新建设备"] }}
        </div>

        <div class="w-full mt-4">
          <a-form ref="macForm" :model="machineForm" layout="inline" autocomplete="off" class="w-full"
            :label-col="{ style: { width: '180px', wordWrap: 'break-word' } }">
            <a-row class="w-full" :gutter="24">
              <a-col :span="12">
                <a-form-item :label="langObj['设备区域']" name="groupId" required>
                  <a-select v-model:value="machineForm.groupId" :placeholder="langObj['请选择设备区域']"
                    @change="selectGroupM" :dropdownClassName="dropdownClassName" >
                    <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备编号']" name="machineCode">
                  <a-input v-model:value="machineForm.machineCode" :placeholder="langObj['请输入设备编号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备名称']" name="machineName" required>
                  <a-input v-model:value="machineForm.machineName" :placeholder="langObj['请输入设备名称']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备位置']" name="position">
                  <a-input v-model:value="machineForm.position" :placeholder="langObj['请输入设备位置']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备厂家']" name="supplier">
                  <a-input v-model:value="machineForm.supplier" :placeholder="langObj['请输入设备厂家']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备型号']" name="model">
                  <a-input v-model:value="machineForm.model" :placeholder="langObj['请输入设备型号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备类型']" name="type">
                  <a-cascader style="max-width:236px" v-model:value="machineForm.type" :options="machineTypeList"
                    :placeholder="langObj['请选择设备类型']" :dropdownClassName="dropdownClassName"  />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备类型缩写']" name="machineType">
                  <a-input v-model:value="machineForm.machineType" :placeholder="langObj['请输入设备类型缩写']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备SN号']" name="sn">
                  <a-input v-model:value="machineForm.sn" :placeholder="langObj['请输入设备SN号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['巡检序号']" name="sort" required>
                  <a-input-number :controls="false" style="width: 100%" v-model:value="machineForm.sort"
                    :placeholder="langObj['请输入']" />
                  <!-- <a-input v-model:value="machineForm.sort" placeholder="请输入设备序号" ></a-input> -->
                </a-form-item>
              </a-col>


              <a-col :span="12">
                <a-form-item :label="langObj['设备位号']" name="sn">
                  <!-- <a-input-number
                    :controls="false"
                    style="width: 100%"
                    v-model:value="machineForm.bitNumber"
                    placeholder="请输入设备位号"
                  /> -->
                  <a-input v-model:value="machineForm.bitNumber" :placeholder="langObj['请输入设备位号']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备功率']" name="sn">
                  <a-input v-model:value="machineForm.machinePower" :placeholder="langObj['请输入设备功率']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备ID']" name="sn">
                  <a-input v-model:value="machineForm.params.ysshId" :placeholder="langObj['请输入设备ID']"></a-input>
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['设备执行人']" name="sn">
                  <a-input v-model:value="machineForm.params.ysshExecutor" :placeholder="langObj['请输入设备执行人']"></a-input>
                </a-form-item>
              </a-col>

              <a-col style="display: none;" :span="12">
                <a-form-item :label="langObj['智能诊断巡检配置']" name="threshold">
                  <a-cascader @change="selectThreshold" style="max-width:236px" v-model:value="machineForm.thresholdIns"
                    :options="thresholdList" :placeholder="langObj['请选择设备细分']" />
                </a-form-item>
              </a-col>

              <a-col :span="12">
                <a-form-item :label="langObj['检测类别']" name="mode" required>
                  <a-checkbox-group v-model:value="machineForm.mode">
                    <a-checkbox :value="1">{{ langObj['在线监测'] }}</a-checkbox>
                    <a-checkbox :value="2">{{ langObj['巡检'] }}</a-checkbox>
                  </a-checkbox-group>
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['设备描述']" name="remark">
                  <a-textarea v-model:value="machineForm.remark" placeholder="请输入" :rows="4" />
                </a-form-item>
              </a-col>

              <a-col :span="24">
                <a-form-item :label="langObj['设备照片']">
                  <a-upload v-model:file-list="machineFileList" list-type="picture-card" @preview="handlePreview"
                    :custom-request="customRequest" :auto-upload="false" accept="image" :max-count="6">
                    <div v-if="!machineFileList || machineFileList.length < 6">
                      <plus-outlined />
                      <div style="margin-top: 8px; color: #fff">{{ langObj['上传'] }}</div>
                    </div>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>

            <a-form-item class="w-full">
              <div class="w-full flex justify-center mt-5">
                <div @click="submitMachine" class="btn-default py-1 px-6 cursor-pointer btn-submit">
                  {{ langObj['提交'] }}
                </div>
                <a-spin :spinning="addLoading">
                  <div v-if="isShowNext && !machineForm.id" @click="addMachineNew" class="ml-8 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('下一步') }}
                  </div>
                  <div v-if="isShowNext && machineForm.id" @click="addMachineNewNewtEdit" class="ml-8 btn-default py-1 px-6 cursor-pointer btn-submit">
                    {{ getLanguage('下一步') }}
                  </div>
                </a-spin>
              </div>
              <div v-if="isShowNext" class="w-full text-center mt-2" style="color: #fff">{{ getLanguage("请点击'下一步'为设备配置参数，配置完成后系统才能生成对应的健康分数！若为收集到设备提资信息，请先点击'保存'!") }}</div>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-modal>

    <!-- 提资信息 -->
    <a-modal v-model:visible="visibleAddMachineNew" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
    style="top: 30%" width="1300px" :maskClosable="false" :zIndex="editRindex+1">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleAddMachineNew = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">
          {{ langObj["新建设备"] }}
        </div>

        <a-form :model="addMachineForm" >
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-item name="machineTypeId" :label="getLanguage('设备类型')" required >
                <a-cascader style="max-width:236px" v-model:value="addMachineForm.type" :options="machineTypeList"
                :placeholder="langObj['请选择设备类型']" @change="selectMT" :dropdownClassName="dropdownClassName" />
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="langObj['设备类型缩写']" name="machineType">
                <a-input v-model:value="addMachineForm.machineType" :placeholder="langObj['请输入设备类型缩写']"></a-input>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="getLanguage('设备是否频繁启停')" required>
                <a-radio-group v-model:value="addMachineForm.machineTypeDetails.isFrequentStart" >
                  <a-radio :value="true">{{getLanguage('是')}}</a-radio>
                  <a-radio :value="false">{{getLanguage('否')}}</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="getLanguage('设备3D图')" >
                <a-select
                  v-model:value="addMachineForm.machineTypeDetails.pictureUrl"
                  :dropdownClassName="dropdownClassName"
                >
                  <a-select-option v-for="(item,index) in pic3Ds" :key="index" :value="item.url">
                    <div class="flex items-center">
                      <a-image height="30px" :src="item.url" />
                      <div ml-2>{{ item.remark }}</div>
                    </div>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item :label="getLanguage('选择测点')" required>
                <div class="flex">
                  <a-tree-select
                    v-model:value="selectSensorList"
                    style="width: 100%"
                    tree-checkable
                    :tree-data="sensorTreeData"
                    :dropdownClassName="dropdownClassName"
                  />
                  <a-button @click="selectSensor">{{ getLanguage('确认选择') }}</a-button>

                </div>
              </a-form-item>
            </a-col>

          </a-row>
        </a-form>

        <div class="mt-4 part" v-for="(item, index) in addMachineForm.machineTypeDetails.parts" :key="index">
          <div class="w-full flex items-center">
            <div>{{ getLanguage('部件') }}:</div>
            <div>{{ item?.partName }}</div>
            <div class="ml-2">({{ getLanguage('请完善部件信息') }})</div>
          </div>

          <div class="mt-2 ml-8" style="border-left: 2px solid gray">
            <div class="w-full flex items-center flex-wrap table1 ml-8">
              <div style="width: 200px">
                <div class="w-full text-center mb-2">{{ getLanguage('额定转速') }}(rpm)<span style="color: red">*</span></div>
                <div class="mt-2" >
                  <a-input-number style="width: 200px" v-model:value="item.params.Rated_speed" :controls="false" />
                </div>
              </div>

              <!-- <div style="width: 200px" class="ml-4">
                <div class="w-full text-center mb-2">{{ getLanguage('额定功率') }}(kw)<span style="color: red">*</span></div>
                <div class="mt-2" >
                  <a-input-number style="width: 200px" v-model:value="item.params.PowerFre" :controls="false" />
                </div>
              </div> -->

              <div style="width: 200px" class="ml-4">
                <div class="w-full text-center mb-2">{{ getLanguage('转速范围') }}<span style="color: red">*</span></div>
                <div class="mt-2 flex" >
                  <a-input-number style="width: 90px" v-model:value="item.params.startFre" :controls="false" />
                  <div class="mx-1">——</div>
                  <a-input-number style="width: 90px" v-model:value="item.params.stopFre" :controls="false" />
                </div>
              </div>

              <div v-for="(item2, index2) in item.segments.columns" :key="index2" style="width: 200px" class="ml-4">
                <div class="w-full text-center mb-2">{{ getLanguage(item2) }}<span style="color: red">*</span></div>
                <div class="mt-2 flex" >
                  <a-select
                    v-model:value="item.segments['data'+index2]"
                    style="width: 200px"
                    @change="selectPartParmar(item.segments, index2)"
                  >
                    <a-select-option v-for="(s, sIndex) in item.segments['columnsData'+index2]" :key="sIndex" :value="s">{{ s }}</a-select-option>
                  </a-select>
                </div>
              </div>
            </div>

            <div class="mt-4" v-for="(structure, index2) in item.structures" :key="index2">
              <div class="flex items-center">
                <div>—</div>
                <div class="ml-2">{{ getLanguage('结构') }}: </div>
                <div class="ml-1">{{ getLanguage(structure.machineStructureName) }}</div>
                <div v-if="structure.sensorPlace">——</div>
                <div v-if="structure.sensorPlace">{{ getLanguage(structure.sensorPlace) }}</div>
                <div class="ml-2">({{ getLanguage('请完善结构信息，若该结构信息不全，将不会显示该测点的健康指标') }})</div>
              </div>

              <div class="w-full flex items-center flex-wrap table1 ml-8 mt-2">
                <div v-for="(parmar, index3) in structure.parmarList" :key="index3" 
                style="width: 200px" :class="{'ml-4': index3!==0}" >
                  <div class="w-full text-center mb-2">{{ getLanguage(parmar.label) }}</div>
                  <div class="mt-2" >
                    <div v-if="parmar.type === 'select'">
                      <a-select
                        v-model:value="parmar.value"
                        style="width: 200px"
                        :options="parmar.option"
                        :showSearch="true"
                      ></a-select>
                    </div>
                    <div v-else-if="parmar.type === 'string'">
                      <a-input style="width: 200px" v-model:value="parmar.value" />
                    </div>
                    <div v-else>
                      <a-input-number style="width: 200px" v-model:value="parmar.value" :controls="false" />
                    </div>
                  </div>
                </div>
              </div>
            </div>




          </div>



        </div>

        <a-form-item class="w-full">
          <div class="w-full flex justify-center mt-5">
            <div @click="submitMachineNew" class="btn-default py-1 px-6 cursor-pointer btn-submit">
              {{ langObj['提交'] }}
            </div>
          </div>
        </a-form-item>
      </div>
  
    </a-modal>

    <!-- 报警记录 -->
    <a-modal class="whiteBg" v-model:visible="visibleWarning" :closable="false" :footer="null"
      :bodyStyle="{ background: '#020F46' }" style="top: 30%" width="1400px" :maskClosable="false">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleWarning = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">{{ langObj['报警记录'] }}</div>

        <div class="w-full mt-4">
          <div class="w-full">
            <a-form :model="searchModel" name="horizontal_login" layout="inline" autocomplete="off">
              <!-- <a-form-item
                                label="设备区域"
                                name="groupId"
                            >
                                <a-select
                                    v-model:value="searchModel.groupId"
                                    style="width: 220px"
                                >
                                    <a-select-option value=""> {{ '全设备测点' }} </a-select-option>
                                </a-select>
                            </a-form-item>

                            <a-form-item
                                label="设备"
                                name="status"
                                >
                                <a-select
                                    v-model:value="searchModel.groupId"
                                    style="width: 220px"
                                >
                                    <a-select-option value=""> {{ '全设备测点' }} </a-select-option>
                                </a-select>
                            </a-form-item> -->

              <a-form-item :label="langObj['报警时间']" name="status">
                <a-range-picker v-model:value="warnSearchModel.date" :disabled-date="disabledDate"
                  @calendarChange="onCalendarChange" />
              </a-form-item>

              <a-form-item :label="langObj['报警类型']" name="status">
                <a-select v-model:value="warnSearchModel.type" style="width: 220px" allowClear
                  :placeholder="langObj['请选择报警类型']">
                  <a-select-option v-for="(item, index) in warnTypeList" :key="index" :value="item.value">
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['严重程度']" name="status">
                <a-select v-model:value="warnSearchModel.condition" style="width: 220px" allowClear
                  :placeholder="langObj['请选择严重程度']">
                  <a-select-option v-for="(item, index) in conditionLists" :key="index" :value="item.value">
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item>
                <div class="flex items-center">
                  <div @click="resetWarn" class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">
                    {{ langObj['重置'] }}
                  </div>

                  <div @click="(warnSearchModel.skip = 1), getWarningList()"
                    class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit" style="background-color: #072499">
                    {{ langObj['查询'] }}
                  </div>
                </div>
              </a-form-item>
            </a-form>
          </div>

          <div class="box mt-4" style="width: 100%">
            <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="warningColumns"
              :data-source="warningList" :pagination="warmPagination" @change="changeWarnTable">
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'type'">
                  <div class="flex items-center">
                    <div v-if="text === 1">
                      <img style="width: 20px" src="@/assets/zhenduan.png" alt="" />
                    </div>
                    <div v-if="text === 2">
                      <img style="width: 20px" src="@/assets/weixiu.png" alt="" />
                    </div>
                    <div v-if="text === 3">
                      <img style="width: 20px" src="@/assets/jiancha.png" alt="" />
                    </div>
                    <div v-if="text === 4">
                      <img style="width: 20px" src="@/assets/menxian.png" alt="" />
                    </div>
                    <div class="ml-2">
                      {{ langObj[warnTypeList.find((p) => p.value === text)?.label] }}
                    </div>
                  </div>
                </template>

                <template v-if="column.dataIndex === 'condition'">
                  <div class="flex items-center">
                    <div style="width: 12px; height: 12px; border-radius: 50%" :style="{
                      background: conditionList.find((p) => p.value === text)
                        ?.color,
                    }"></div>
                    <div class="ml-2">
                      {{ langObj[conditionList.find((p) => p.value === text)?.label] }}
                    </div>
                  </div>
                </template>

                <template v-if="column.dataIndex === 'status'">
                  <div></div>
                </template>

                <template v-if="column.dataIndex === 'act'">
                  <a @click="viewReport(record)" v-if="record.type === 1 && record.reportIds?.length"
                    class="theme-table-link">{{ langObj['诊断报告'] }}</a>
                  <a @click="viewReport(record)" v-if="record.type === 2 && record.reportIds?.length"
                    class="theme-table-link">{{ langObj['检修报告'] }}</a>
                </template>
              </template>
            </a-table>
          </div>
        </div>

        <div class="w-full flex justify-center mt-5">
          <div @click="visibleWarning = false" class="btn-default py-1 px-6">
            {{ langObj['关闭'] }}
          </div>
        </div>
      </div>
    </a-modal>

    <!-- 检修记录 -->
    <a-modal v-model:visible="visibleRepair" :closable="false" :footer="null" :bodyStyle="{ background: '#020F46' }"
      style="top: 30%" width="800px" :maskClosable="false">
      <div style="color: #fff">
        <div class="w-full flex">
          <div class="flex-1"></div>
          <div class="cursor-pointer" @click="visibleRepair = false">
            <close-outlined style="font-size: 20px" />
          </div>
        </div>
        <div class="text-center w-full" style="font-size: 24px">{{ langObj['检修记录'] }}</div>

        <div class="mt-5" style="font-size: 16px">{{ langObj['故障信息'] }}</div>

        <div class="box mt-4" style="width: 100%">
          <a-table style="width: 100%" size="small" :row-class-name="getRowClassName" :columns="repairColumns"
            :data-source="repairForm.list">
            <template #bodyCell="{ column, text }">
              <template v-if="column.dataIndex === 'act'">
                <a class="theme-table-link">{{ langObj['编辑'] }}</a>
              </template>
            </template>
          </a-table>
        </div>

        <div class="mt-10 mb-4" style="font-size: 16px">{{ langObj['故障详情'] }}</div>
        <a-descriptions :column="{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }" :labelStyle="{ color: '#fff' }">
          <a-descriptions-item :labelStyle="{ color: '#fff' }" label="开始时间">{{ repairForm.begin }}
          </a-descriptions-item>
          <a-descriptions-item label="结束时间">{{
            repairForm.end
          }}
          </a-descriptions-item>
          <a-descriptions-item label="设备名称">{{
            repairForm.machineName
          }}
          </a-descriptions-item>
          <a-descriptions-item label="设备区域">{{
            repairForm.groupName
          }}
          </a-descriptions-item>
          <a-descriptions-item label="检修人员">{{
            repairForm.maintainPerson
          }}
          </a-descriptions-item>
          <a-descriptions-item label="现场设备状态">{{
            repairForm.accuracy
          }}
          </a-descriptions-item>
          <!-- <a-descriptions-item label="故障原因">xxxxx</a-descriptions-item> -->
        </a-descriptions>
        <div>{{ langObj['检修内容'] }}:</div>
        <div>{{ repairForm.maintainMessage }}</div>

        <div class="flex mt-4">
          <div>{{ langObj['检修照片'] }}:</div>
        </div>

        <div class="w-full flex justify-center mt-5">
          <div @click="visibleRepair = false" class="btn-default py-1 px-6">
            {{ langObj['关闭'] }}
          </div>
        </div>
      </div>
    </a-modal>

    <a-drawer v-model:visible="visibleReport" class="custom-class" size="large" :closable="false" placement="right">
      <template #title></template>
      <DiagnosticReportDetails ref="mrDref" :reportId="reportId" @cancel="cancel"></DiagnosticReportDetails>
    </a-drawer>

    <a-modal :visible="previewVisible" title="图片" :footer="null" @cancel="handleCancel" :zIndex="1001">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>

    <a-modal v-model:visible="visibleImportMachine" :title="langObj['导入设备'] || '导入设备'" :footer="null" style="top: 30%"
      width="1500px" :maskClosable="false">
      <import-machine :machine-types="machineTypeListAll" @success="onImportMachineSuccess"></import-machine>
    </a-modal>

    <SelectMachineComponent @selectMachineSen="selectMachineSen" :visible="selectMachineVisible"
      v-if="selectMachineVisible" :editRindex2="editRindex2"></SelectMachineComponent>
    <SelectSensorComponent @selectSensorSen="selectSensorSen" :machineId="sensorForm.machineId"
      :visible="selectSensorVisible" v-if="selectSensorVisible" :editRindex2="editRindex2"></SelectSensorComponent>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import {
  getFactoryName,
  getGroupName,
  transformDate2,
  getGroupListByFactory,
  getRealFactoryIdList
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import SelectMachineComponent from "./select-machine.vue";
import SelectSensorComponent from "./select-sensor.vue";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";
import ImportMachine from "@/components/equipment/import-machine.vue";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep,getPopover } from "../../common/registdriver";

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];

let whetherList = [
  { label: "是", value: true },
  { label: "否", value: false },
];


const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let menuSelect = ref(1); //1: 设备列表  2：测点列表
let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
});
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];


let statusList = [
  { label: "运行", value: 1, color: "#0AF9F6" },
  { label: "停机", value: 0, color: "rgb(162, 166, 171)" },
];


let data = ref([]);

const machineColumns = [
  {
    title: langObj.value["设备编号"],
    dataIndex: "machineCode",
  },
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["所属工厂"],
    dataIndex: "factoryId",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["设备位置"],
    dataIndex: "position",
  },
  {
    title: langObj.value["设备厂家"],
    dataIndex: "supplier",
  },
  {
    title: langObj.value["设备型号"],
    dataIndex: "model",
  },
  {
    title: langObj.value["设备类型"],
    dataIndex: "machineTypeAll",
  },
  {
    title: getLanguage('是否配置提资信息'),
    dataIndex: "isUpgradeMachineType",
  },
  {
    title: langObj.value["设备类型缩写"],
    dataIndex: "machineType",
  },
  {
    title: langObj.value["设备SN号"],
    dataIndex: "sn",
  },
  {
    title: langObj.value["巡检序号"],
    dataIndex: "sort",
  },
  // {
  //   title: langObj.value["是否实验台"],
  //   dataIndex: "experiment",
  // },
  {
    title: langObj.value["设备描述"],
    dataIndex: "remark",
  },
  {
    title: getLanguage('监测类别', language.value),
    dataIndex: "mode",
  },
  {
    title: langObj.value["健康状态"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["运行状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const sensorColumns = [
  {
    title: langObj.value["传感器SN"],
    dataIndex: "sensorSn",
  },
  {
    title: langObj.value["测点位置"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["设备区域"],
    dataIndex: "groupId",
  },
  {
    title: langObj.value["所属设备"],
    dataIndex: "machineName",
  },
  // {
  //   title: langObj.value["测点ID"],
  //   dataIndex: "id",
  // },
  {
    title: langObj.value["转频提取测点"],
    dataIndex: "fcSensorPlace",
  },
  {
    title: langObj.value["轴参数"],
    dataIndex: "parameter",
  },
  // {
  //   title: langObj.value["开机指标"],
  //   dataIndex: "threshold",
  // },
  {
    title: langObj.value["传感器电量"],
    dataIndex: "hardware",
  },
  {
    title: getLanguage('智能采集有效期', language.value),
    dataIndex: "validity",
  },
  {
    title: getLanguage('智能诊断巡检配置', language.value),
    dataIndex: "inspectionSubs",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

let groupList: any = ref([]);
let machineList: any = ref([]);
let machineTypeList: any = ref([]);
let thresholdList: any = ref([]);
let machineTypeListAll: any = ref([]);
let dataTypeList = ref<any>();
let isEditable: any = ref(false);

let options: any = ref([])
let categoryAll: any = ref([])

onMounted(() => {
  isEditable.value = true
  loadDatatype();
  getMachineType();
  getThresholdList()
  getGroupList();
  search();
  getSearch3()
  getBearingParameter()

  setTimeout(()=>{
    //新注册用户，未经过引导的需弹窗进行引导
    if(!getMemberOnboarding())
    {
      highlightStep(getPopover(".addMachineDriver","5/9 新建设备","点击新建设备，可创建需监测的设备信息，通过绑定设备类型，可自动生成测点及3D图等配置。","新建设备","right","start",nextRoute,addMachineDriver));
    }
  },200)
  
});

// 获取轴承型号
let bearingParameters:any = ref([])
const getBearingParameter = async() => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/bearing-parameters/all', config)
  if (result?.data?.length) {
    bearingParameters.value = result.data.map((d:any) => ({
      label: d.bearingModel,
      value: d.bearingModel,
    }))
  }
}

const getSearch3 = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    categoryAll.value = JSON.parse(JSON.stringify(result.data))
  }

  options.value = []
  let first = categoryAll.value.map((p: any) => p.firstCategory)
  first = [...new Set(first)]
  let list: any = []
  first.map((f: any) => {
    let firstList: any = {
      label: f,
      value: f,
      children: []
    }
    let second = categoryAll.value.filter((p: any) => p.firstCategory === f).map((p: any) => p.secondCategory)
    second = [...new Set(second)]
    second.map((s: any) => {
      let secondList: any = {
        label: s,
        value: s,
        children: []
      }
      let third = categoryAll.value.filter((p: any) => p.firstCategory === f && p.secondCategory === s).map((p: any) => p.thirdCategory)
      third = [...new Set(third)]
      third.map((t: any) => {
        let thirdList: any = {
          label: t,
          value: t,
        }
        secondList.children.push(thirdList)
      })
      firstList.children.push(secondList)
    })

    list.push(firstList)
  })

  options.value = list
}


const loadDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      dataTypeList.value = res.data.map((t: any) => ({
        value: t.name,
        label: langObj.value[t.name] || t.name,
      }));
    }
  });
};

// 获取设备类型
const getMachineType = async () => {
  let result = await request("/api/sensors/machine-types");
  if (result && result.data) {
    machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.FirstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.FirstCategory === d1)
            .map((p: any) => p.SecondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.SecondCategory === d2 && p.FirstCategory === d1)
          .map((p: any) => p.ThirdCategory);

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    machineTypeList.value = list;
  }
};

// 获取设备细分
const getThresholdList = async () => {
  let result = await request("/api/external/inspection/threshold");
  if (result && result.data) {
    // machineTypeListAll.value = result.data;
    let firstCategoryList = result.data.map((d: any) => d.firstCategory);
    firstCategoryList = [...new Set(firstCategoryList)];
    let list: any = [];
    firstCategoryList.map((d1: any) => {
      let first: any = {
        value: d1,
        label: d1,
        children: [],
      };
      let list1 = [
        ...new Set(
          result.data
            .filter((p: any) => p.firstCategory === d1)
            .map((p: any) => p.secondCategory)
        ),
      ];

      list1.map((d2: any) => {
        let second: any = {
          value: d2,
          label: d2,
          children: [],
        };

        let list2 = result.data
          .filter((p: any) => p.secondCategory === d2 && p.firstCategory === d1)
          .map((p: any) => p.thirdCategory);

        list2.map((d3: any) => {
          let third = {
            value: d3,
            label: d3,
          };

          second.children.push(third);
        });

        first.children.push(second);
      });

      list.push(first);
    });
    thresholdList.value = list;

  }
};
//选择设备细分
const selectThreshold = (ev) => {
  macForm.value.thresholdArr = JSON.parse(JSON.stringify(ev))

}// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

const search = () => {
  searchModel.value.skip = 1;
  getData();
};

const getData = () => {
  if (menuSelect.value === 1) {
    getMachineList();
  }

  if (menuSelect.value === 2) {
    searchSensor();
  }
};

//获取设备列表
const getMachineList = async () => {
  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      status: searchModel.value.status,
      factoryIds: getRealFactoryIdList(),
      keyWord: searchModel.value.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if(searchModel.value.isUpgradeMachineType === true || searchModel.value.isUpgradeMachineType === false) {
    config.params.isUpgradeMachineType = searchModel.value.isUpgradeMachineType
  }

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.realValue;
  }

  let result = await request("/api/machines", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.machineTypeId) {
        let info = machineTypeListAll.value.find(
          (p: any) => p.id === d.machineTypeId
        );
        if (info) {
          d.machineTypeAll = `${info.FirstCategory}/${info.SecondCategory}/${info.ThirdCategory}`;
        } else {
          d.machineTypeAll = ''
        }
      }
    });
    data.value = result.data;
  }
};

// 获取测点列表
const searchSensor = () => {
  searchModel.value.groupId = groupList.value[0].id;
  selectGroup(searchModel.value.groupId);
  searchModel.value.machineId = machineList.value[0].id;
  getSensorList();
};

const getSensorList = async () => {
  if (!searchModel.value.machineId) {
    return message.warning('请选择设备后查询')
  }

  data.value = [];
  let config: any = {
    params: {
      machineId: searchModel.value.machineId,
      keyWord: searchModel.value.keyWord,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors/search", config);

  if (result && result.status === 200) {
    data.value = result.data;
  }
};

// 重置
const reset = () => {
  if (menuSelect.value === 1) {
    searchModel.value = {
      ...searchModel.value,
      keyWord: "",
      groupId: null,
      machineId: "",
      condition: null,
      status: null,
      sensorPlace: "",
    };
  } else {
    searchModel.value = {
      ...searchModel.value,
      keyWord: "",
      condition: null,
      status: null,
      sensorPlace: "",
    };
  }
};

const selectMenu = (ev: any) => {
  menuSelect.value = ev;
  data.value = [];
  reset();
  getData();
};

// 获取设备健康状态
const getMachienCondition = (ev: any) => {
  if (ev && ev.workStatus && ev.workStatus.stableCondition) {
    return conditionList.find(
      (p: any) => p.value === ev.workStatus.stableCondition
    );
  } else {
    return conditionList[0];
  }
};

// 获取设备运行状态
const getMachienStatus = (ev: any) => {
  if(ev.workStatus?.quotaWkStatus) {
    return statusList[0]
  } else if(ev.workStatus?.scoreWkStatus) {
    return statusList.find((p: any) => p.value === ev.workStatus?.scoreWkStatus);
  } else {
    return statusList[1];
  }
}

// 获取监测类别
const getMode = (ev: any) => {
  if (ev?.length) {
    let list: any = []
    ev.map((d: any) => {
      if (d == 1) {
        list.push(getLanguage('在线监测', language.value))
      }
      if (d == 2) {
        list.push(getLanguage('巡检', language.value))
      }
    })
    return list.join('/')
  } else {
    return ''
  }
}

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

// 新增测点
let visibleAddSensor = ref(false);
let sensorForm: any = ref({
  sensorSn: null,
  sensorPlace: "",
  machineName: "",
  machineId: "",
  fcSensorId: "",
  fcSensorPlace: "",
  machineTypeId: "",
  national: "",
  remark: "",
  type: null,
  sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
  disable: false,
  onOff: null,
  quotaInterval: null,
  inspectionSubId: null,
  inspectionSubs: null,
  isDas: true,
  dasCode: ""
});
let powerEtcList: any = ref([]);
let selectMachineVisible = ref(false);
let selectSensorVisible = ref(false);
const sensorAddColumns = [
  {
    title: langObj.value["编号"],
    dataIndex: "sonId",
  },
  {
    title: langObj.value["轴向"],
    dataIndex: "coordinate",
  },
  {
    title: langObj.value["名称"],
    dataIndex: "sensorGroupName",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "value",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
const addSensor = () => {
  sensorForm.value = {
    sensorSn: null,
    sensorPlace: "",
    machineName: "",
    machineId: "",
    fcSensorId: "",
    fcSensorPlace: "",
    machineTypeId: "",
    national: null,
    remark: "",
    dataType: ['高频加速度', '低频加速度Z', '速度Z'],
    type: null,
    sensorGroup: [{ coordinate: "", sonId: "", sensorGroupName: "" }],
    disable: false,
    onOff: null,
    quotaInterval: null,
    inspectionSubId: null,
    inspectionSubs: null,
    isDas: true,
    dasCode: ""
  };
  machineFileList.value = [];
  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm1.value.clearValidate();
  }, 200);
};
let speedList: any = ref([])

// 选择设备
const selectMachineS = () => {
  selectMachineVisible.value = true;
};

const selectMachineSen = (ev: any) => {
  selectMachineVisible.value = false;
  if (ev) {
    sensorForm.value.machineId = ev.id;
    sensorForm.value.machineName = ev.machineName;
  } else {
    sensorForm.value.machineId = "";
    sensorForm.value.machineName = "";
  }
};

//选择转频提取测点
const selectSensorS = () => {
  if (!sensorForm.value.machineId) {
    message.warning('请选择所属设备后选择转频提取测点')
    return
  }

  selectSensorVisible.value = true;
};

const selectSensorSen = (ev: any) => {
  selectSensorVisible.value = false;
  if (ev) {
    sensorForm.value.fcSensorId = ev.id;
    sensorForm.value.fcSensorPlace = ev.sensorPlace;
  } else {
    sensorForm.value.fcSensorId = "";
    sensorForm.value.fcSensorPlace = "";
  }
};

// 选择类型
const changeThirdTypeId = async (ev: any) => {
  if (ev && ev.length === 3) {
    let info = machineTypeListAll.value.find(
      (p: any) =>
        p.FirstCategory === ev[0] &&
        p.SecondCategory === ev[1] &&
        p.ThirdCategory === ev[2]
    );
    sensorForm.value.machineTypeId = info.id;

    let config = {
      params: { id: info.id },
      headers: {
        requestId: uuidv4(),
      },
    };

    let result = await request.get(
      "/api/sensors/machine-types/power-etc",
      config
    );
    if (!result?.data) {
      powerEtcList.value = [];
      sensorForm.value.national = null;
      return;
    }

    powerEtcList.value = result.data;
    if (result.data.length > 0) {
      sensorForm.value.national = result.data[0].powerEtc;
    } else {
      sensorForm.value.national = null;
    }
  }
};

const addSensorParmar = () => {
  sensorForm.value.sensorGroup.push({ coordinate: "Y" });
};

const deleteSensorParmar = (ev: any) => {
  sensorForm.value.sensorGroup.splice(ev, 1);
};

// 选择巡检设备细分
const changeISub = (ev: any) => {
  if (ev?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === ev[0] && p.secondCategory === ev[1] && p.thirdCategory === ev[2])
  } else {
    speedList.value = []
  }
}

// 编辑
const editSensor = async (ev: any) => {
  machineFileList.value = [];
  let evValue = JSON.parse(JSON.stringify(ev));
  sensorForm.value = evValue;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
      (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      sensorForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      sensorForm.value.type = []
    }

  }
  if (ev.machine) {
    sensorForm.value.machineName = ev.machine.machineName;
  }

  if (sensorForm.value.pictures) {
    sensorForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = sensorForm.value.pictures;
  }

  if (sensorForm.value?.inspectionSubs?.length === 3) {
    speedList.value = categoryAll.value.filter((p: any) => p.firstCategory === sensorForm.value.inspectionSubs[0] && p.secondCategory === sensorForm.value.inspectionSubs[1] && p.thirdCategory === sensorForm.value.inspectionSubs[2])
  } else {
    speedList.value = []
  }

  visibleAddSensor.value = true;
  setTimeout(() => {
    sensorForm1.value.clearValidate();
  }, 200);
};

let sensorForm1 = ref();
const submitSensor = () => {
  sensorForm1.value?.clearValidate();
  sensorForm1.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(sensorForm.value));
      form.sensorGroup && form.sensorGroup.map((p: any) => {
        if (p.sonId) {
          p.sonId = p.sonId.trim()
        }
      })

      if (form.inspectionSubs?.length !== 3) {
        form.inspectionSubs = []
      }
      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 9,
        }));
      }

      let result: any = form.id
        ? await request.put("/api/sensors/info", form)
        : await request.post("/api/sensors", form);


      if (result && result.status) {
        message.success("操作成功");
        visibleAddSensor.value = false;
        getSensorList();
      } else {
        message.warning("操作失败");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 获取有效期
const getValidity = (ev: any) => {
  if (!ev) {
    return ''
  }

  let now = new Date().getTime()
  let end = new Date(ev).getTime()

  if (now >= end) {
    return '已过期'
  } else {
    let date = Math.round((end - now) / (1000 * 60 * 60 * 24))
    return date + '天'
  }
}

// 获取巡检设备细分
const getInspectionSubs = (ev: any) => {
  if (ev?.length !== 3) {
    return ''
  } else {
    return ev.join('/')
  }
}

// 新增设备
let visibleAddMachine = ref(false);
let machineForm: any = ref({
  machineCode: null,
  groupId: null,
  type: null,
  machineName: "",
  position: null,
  supplier: null,
  model: null,
  machineType: null,
  machineTypeId: null,
  thresholdIns: null,
  thresholdArr: [],
  sn: null,
  sort: null,
  experiment: false,
  remark: null,
  pictures: [],
  factoryId: "",
  parentFactoryId: "",
  params: {},
  bitNumber: null,
  machinePower: null,
  mode: [],
});
let macForm = ref();
const addMachine = () => {
  machine_select_id.value = ''
  machineForm.value = {
    machineCode: null,
    groupId: null,
    type: null,
    machineName: "",
    position: null,
    supplier: null,
    model: null,
    machineType: null,
    machineTypeID: null,
    // thresholdIns:null,
    // thresholdArr:[],
    sn: null,
    sort: null,
    experiment: false,
    remark: null,
    pictures: [],
    factoryId: "",
    parentFactoryId: "",
    params: {},
    bitNumber: null,
    machinePower: null,
    mode: [],
  };
  machineFileList.value = [];
  visibleAddMachine.value = true;
  setTimeout(() => {
    macForm.value.clearValidate();
  }, 200);
};

// 新建设备（新）
let machine_select_id:any = ref('')
let visibleAddMachineNew = ref(false);
let addMachineForm:any = ref({})
let pic3Ds:any = ref([])
let sensorTreeData:any = ref([]);
let selectSensorList:any = ref([]);
let structureList:any = ref([]) //结构列表
let isShowNext = computed(() => {
  return machineForm.value.mode.includes(1) && !machineForm.value.id
  // !machineForm.id
})
let addLoading:any = ref(false);

const getStructureList = async() => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-structures/all", config);
  if (result?.data) {
    structureList.value = result.data;
  }
}
getStructureList()

// 新增的下一步
const addMachineNew = async() => {
  if(!machine_select_id.value) {
    macForm.value?.clearValidate();
    macForm.value
    .validate()
    .then(async (res: any) => {
      addLoading.value = true;
      let form = JSON.parse(JSON.stringify(machineForm.value));
      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 1,
        }));
      }

      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      let type_save = form.type
      delete form.type;

      let result = await request.post("/api/machines", form);
      if (result && result.status === 200) {
        message.success("新增设备成功");
        machine_select_id.value = result.data
      } else {
        message.warning("操作失败,请联系管理员!");
      }

      addMachineForm.value = {
        type: null,
        machineType: null,
        machineTypeId: null,
        machineTypeDetails: {
          isFrequentStart: false,
          pictureUrl: null,
          sensorList: [],
          parts:[]
        }
      }
      parts_old.value = null
      visibleAddMachineNew.value = true
      addLoading.value = false;

      // 已选择设备类型
      if( form.machineTypeId && type_save?.length === 3) {
        addMachineForm.value.type = type_save
        selectMT(type_save)
      }

    })
    .catch((err: any) => {
      console.log(err); //失败后执行
      message.warning('请填写必填项后进行下一步')
    });
  }  else {
    addMachineForm.value = {
      type: null,
      machineType: null,
      machineTypeId: null,
      machineTypeDetails: {
        isFrequentStart: false,
        pictureUrl: null,
        sensorList: [],
        parts:[]
      }
    }


    let form = JSON.parse(JSON.stringify(machineForm.value));
    let type_save = form.type
    if(type_save?.length === 3) {
      addMachineForm.value.type = type_save
      selectMT(type_save)
    }

    visibleAddMachineNew.value = true
  }
}

// 编辑的下一步
let parts_old:any = ref(null)
const addMachineNewNewtEdit = () => {
  parts_old.value = null
  let {id, type} = machineForm.value
  machine_select_id.value = id
  let machineTypeDetails = JSON.parse(JSON.stringify(machineForm.value.machineTypeDetails))
  if(machineTypeDetails) {
    console.log('machineTypeDetails', machineTypeDetails)
   
    addMachineForm.value = {
      type: null,
      machineType: null,
      machineTypeId: null,
      machineTypeDetails: {
        isFrequentStart: machineTypeDetails.isFrequentStart,
        pictureUrl: machineTypeDetails.pictureUrl,
        sensorList: [],
        parts:[]
      }
    }

    if(type?.length === 3) {
      addMachineForm.value.type = type
      selectMT(type)
    }
    if(machineTypeDetails.sensorList?.length) {
      selectSensorList.value = machineTypeDetails.sensorList.map((p:any) => p.partUniqueId+'-'+p.sensorPlace)
      parts_old.value =  machineTypeDetails.parts
      selectSensor()
    }

  } else {
    addMachineForm.value = {
      type: null,
      machineType: null,
      machineTypeId: null,
      machineTypeDetails: {
        isFrequentStart: false,
        pictureUrl: null,
        sensorList: [],
        parts:[]
      }
    }
  }

  visibleAddMachineNew.value = true
}

// 模拟下一步
const nmNext = () => {
   machineForm.value =  {
      "groupId": "1732516016562",
      "machineName": "自动化上线测试设备4",
      "supplier": null,
      "model": null,
      "remark": null,
      "createdAt": "2024/11/27 09:27:06",
      "position": null,
      "factoryId": "649a3ee1154bc03e85f25ff7",
      "parentFactoryId": null,
      "sn": null,
      "sort": 4,
      "experiment": false,
      "machineCode": null,
      "bitNumber": null,
      "machinePower": null,
      "mode": [
          1
      ],
      "version": 0,
      "machineType": "JZJ",
      "machineTypeDetails": {
          "isFrequentStart": false,
          "pictureUrl": "http://192.168.0.150:9000/public/20241118/6d0e123e-34fb-45bd-a586-fc00497ea3ab.jpg",
          "sensorList": [
              {
                  "partUniqueId": "1731029010647",
                  "sensorPlace": "测点一"
              },
              {
                  "partUniqueId": "1731029010647",
                  "sensorPlace": "测点3"
              }
          ],
          "parts": [
              {
                  "partUniqueId": "1731029010647",
                  "partName": "预精轧机",
                  "machineTypeSegmentId": "67318c0205ab59869b87d5d0",
                  "params": {
                      "Rated_speed": 1,
                      "startFre": 3,
                      "stopFre": 4
                  },
                  "structures": [
                      {
                          "sensorPlace": "测点一",
                          "machineStructureId": "672c55e3e19a0d0e17e653b7",
                          "machineStructureName": "轴承",
                          "params": {
                              "type": 1,
                              "bearingModel": "E6"
                          }
                      },
                      {
                          "sensorPlace": "测点3",
                          "machineStructureId": "672c55e3e19a0d0e17e653ba",
                          "machineStructureName": "定轴齿轮",
                          "params": {
                              "GeaTeethNum": 1,
                              "GeaOutTeethNum": 2,
                              "mesh_n": 3
                          }
                      }
                  ]
              }
          ]
      },
      "machineTypeId": "62aae5693e78a92326016dbb",
      "id": "6746756a5c31ac784a6aaff2",
      "sensorList": [],
      "pictures": [],
      "reportNumber": 0,
      "params": {},
      "type": [
          "轧制设备",
          "轧机",
          "精轧机"
      ]
  }
  setTimeout(() => {
    addMachineNewNewtEdit()
  }, 1000);
}
// nmNext()


// 选择设备类型
const selectMT = async(ev:any) => {
  if(ev?.length === 3) {
    sensorTreeData.value = []
    let machineTypeInfo = machineTypeListAll.value.find((p:any) => p.FirstCategory === ev[0] && p.SecondCategory === ev[1] && p.ThirdCategory === ev[2] )
    if(machineTypeInfo) {
      addMachineForm.value.machineType = machineTypeInfo.ThirdCategoryAbbreviation
      addMachineForm.value.machineTypeId = machineTypeInfo.id
      pic3Ds.value = machineTypeInfo.pictures || []
      // 获取测点列表
      let sensorList1 = machineTypeInfo?.sensorTemplate?.sensorList || []
      sensorList1.map((d:any) => {
        let first:any = {
          label: d.name || machineTypeInfo.ThirdCategory,
          value: d.uniqueId,
          selectable: false,
          children: []
        } 
        if(d.sensors?.length > 0) {
          d.sensors.map((s:any) => {
            let second = {
              label: s.sensorPlace,
              value: d.uniqueId+'-'+s.sensorPlace,
              selectable: true
            }
            first.children.push(second)
          })
        }
        sensorTreeData.value.push(first)
      })
    }
  }
}

// 选择测点
const selectSensor = async() => {
  if(!addMachineForm.value.machineTypeId) return message.warning('请先选择设备类型！')
  if(!selectSensorList.value?.length) return message.warning('请先选择测点！')

  let sensorList_select = selectSensorList.value.map((d:any) => {
    return {
      partUniqueId: d.split('-')[0],
      sensorPlace: d.split('-')[1]
    }
  })
  addMachineForm.value.machineTypeDetails.sensorList = sensorList_select

  let uniqueIds = [... new Set(sensorList_select.map((d:any) => d.partUniqueId))]


  let machineTypeInfo = machineTypeListAll.value.find((p:any) => p.id === addMachineForm.value.machineTypeId)
  let sensorList_template = machineTypeInfo?.sensorTemplate?.sensorList || []

  let parts:any = []
  

  for (let i in uniqueIds) {
    let d = uniqueIds[i]
    let structures:any = []
    let partInfo = sensorList_template.find((p:any) => p.uniqueId === d)
    if(partInfo?.sensors?.length > 0) {
      // 获取细分列表
      let config: any = {
        params: {
            machineTypeId: machineTypeInfo.id,
            partUniqueId: machineTypeInfo.isPart ? '' : d,
            take: 999
        },
        headers: {
            requestId: uuidv4(),
        },
      };
      let result = await request.get('/api/machine-type/segments/list', config)
      let resultList = result?.data?.list || []
      let tableTitles:any = []
      resultList.map((r:any) => {
        tableTitles = tableTitles.concat(Object.keys(r?.columns || {})) 
      })
      let firstColumnsData:any = []
      if(tableTitles?.length > 0) {
        resultList.map((t:any) => {
          firstColumnsData.push(t?.columns[tableTitles[0]]) 
        })
      }
      let segments = {
        columns: [... new Set(tableTitles)],
        dataList: resultList,
        columnsData0: [... new Set(firstColumnsData)],
      }

      let sensors_part = partInfo.sensors.filter((p:any) => sensorList_select.find((s:any) => s.sensorPlace === p.sensorPlace))

      sensors_part.map((s:any) => {
        // structureList.value
        s.machineStructure.map((m:any) => {
          let structureInfo = structureList.value.find((p:any) => p.id === m)
          // 轴承齿轮需要绑定测点
          if(m === "672c55e3e19a0d0e17e653b7" || m === "672c55e3e19a0d0e17e653b9" || m === "672c55e3e19a0d0e17e653ba") {
            structures.push({
              sensorPlace:  s.sensorPlace,
              machineStructureId: m,
              machineStructureName: structureInfo?.name,
              parmarList: getStructureParmar(m)
            })
          } else {
            if(!structures.find((p:any) => p.machineStructureId === m)) {
              structures.push({
                sensorPlace: '',
                machineStructureId: m,
                machineStructureName: structureInfo?.name,
                parmarList: getStructureParmar(m)
              })
            }
          }


        })
      })

      parts.push({
        partUniqueId: d,
        partName: partInfo.name,
        machineTypeSegmentId: null,
        params: {
          Rated_speed: null,
          PowerFre: null,
          startFre: null,
          stopFre: null,
        },
        structures,
        segments,
      })

      console.log('parts_old', parts_old.value)
      console.log('parts', parts)
      parts.map((part:any) => {
        let part_old = parts_old.value.find((p:any) => p.partUniqueId === part.partUniqueId)
        if(part_old) {
          part.params = part_old.params
                                                           
          part.structures.map((structure:any) => {

          })

        }
      })


    }
  }

  addMachineForm.value.machineTypeDetails.parts = parts

}

// 获取不同结构的参数
const getStructureParmar = (id:any) => {
  let list = [
    {
      id: "672c55e3e19a0d0e17e653b6",
      name: '叶轮',
      parmar: [
        {label: '叶轮级数', value: 1, key: 'Impeller_series'},
        {label: '叶轮数目', value: null, key: 'BladeNum'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bc",
      name: '电机',
      parmar: [
        {label: '额定转速', value: null, key: 'Rated_speed'},
        {label: '额定电源频率', value: 50, key: 'PowerFre'},
        {label: '电机极对数', value: null, key: 'p'},
        {label: '转子条数', value: null, key: 'rot_n'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b7",
      name: '轴承',
      parmar: [
        {label: '轴承类型', value: null, key: 'type', type: 'select', option:[{label: '滚动轴承', value:1}, {label: '滑动轴承', value:2}]},
        // {label: '轴承参数id', value: null, key: 'bearingParameterId', type: 'string'},
        {label: '轴承型号', value: null, key: 'bearingModel', type: 'select', option:bearingParameters.value},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b9",
      name: '行星齿轮',
      parmar: [
        {label: '太阳轮齿数', value: null, key: 'Zs', },
        {label: '一倍啮合频率', value: null, key: 'MeshFre'},
        {label: '行星轮个数', value: null, key: 'PLanetary'},
        {label: '齿圈齿数', value: null, key: 'RingTeeth'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653ba",
      name: '定轴齿轮',
      parmar: [
        {label: '主动齿轮齿数', value: null, key: 'GeaTeethNum', },
        {label: '从动齿轮齿数', value: null, key: 'GeaOutTeethNum'},
        {label: '提取啮合频率谐波阶数', value: 5, key: 'mesh_n'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653b8",
      name: '柱塞',
      parmar: [
        {label: '柱塞数目', value: null, key: 'z', },
        {label: '柱塞泵滤波参数', value: 2000, key: 'wps'},
        {label: '柱塞泵滤波参数', value: 3000, key: 'wss'},
        {label: '柱塞泵转频上限', value: null, key: 'fr_Min'},
        {label: '柱塞泵转频下限', value: null, key: 'fr_Max'},
        {label: '轴柱塞泵个数', value: null, key: 'PlungerNum'},
      ]
    },
    {
      id: "672c55e3e19a0d0e17e653bb",
      name: '螺杆',
      parmar: [
        {label: '螺杆阳转子齿数', value: null, key: 'ScrTeethNum', },
        {label: '螺杆阴转子齿数', value: null, key: 'ScrOutTeethNum'}
      ]
    },

  ]
  return list.find((p:any) => p.id === id)?.parmar || []
}

// 选择细分内容
const selectPartParmar = (segments:any, index:any) => {
  if(index+1 >= segments.columns.length) {
    let dataListC = JSON.parse(JSON.stringify(segments.dataList))
    for(let i in segments.columns) {
      let c = segments.columns[i]
      dataListC = dataListC.filter((p:any) => p.columns[c] === segments['data'+i])
    }
    if(dataListC?.length) {
      segments.id = dataListC[0].id
    } else {
      message.warning(getLanguage('暂无选择的细分， 请重新选择'))
    }
    return
  }
  let column = segments.columns[index+1]
  let columnsData:any = []
  let dataListS = segments.dataList.filter((p:any) => p.columns[segments.columns[index]])
  dataListS.map((t:any) => {
    columnsData.push(t?.columns[column]) 
  })
  segments['columnsData'+(index+1)] = columnsData
}


// 提交提资信息
const submitMachineNew = async() => {
  if(!selectSensorList.value?.length) return message.warning('请先选择测点！')

  let submit = JSON.parse(JSON.stringify(addMachineForm.value))
  submit.machineTypeDetails.parts.map((d:any) => {
    d.machineTypeSegmentId = d.segments.id
    delete d.segments
    d.structures.map((s:any) => {
      let parmarList = s.parmarList
      delete s.parmarList
      let parmar:any = {}
      parmarList.map((p:any) => {
        parmar[p.key] = p.value
      })
      s.params = parmar
    })
  }) 

  submit.id = machine_select_id.value
    
  let result = await request.post('/api/machines/machine-type-details', submit)
  if(result?.status == 200) {
    message.success('操作成功!')
    visibleAddMachineNew.value = false 
    visibleAddMachine.value = false
  } else {
    message.warning('操作失败,请联系管理员!')
  }

}


const editMachine = async (ev: any) => {
  machine_select_id.value = ''
  machineFileList.value = [];
  let dataValue = JSON.parse(JSON.stringify(ev));
  if (!dataValue.params) {
    dataValue.params = {}
  }
  if (!dataValue.mode) {
    dataValue.mode = []
  }
  delete dataValue.machineTypeAll;
  machineForm.value = dataValue;
  if (ev.machineTypeId) {
    let info = machineTypeListAll.value.find(
      (p: any) => p.id === ev.machineTypeId
    );
    if (info) {
      machineForm.value.type = [
        info.FirstCategory,
        info.SecondCategory,
        info.ThirdCategory,
      ];
    } else {
      machineForm.value.type = null
    }
  }

  if (machineForm.value.pictures) {
    machineForm.value.pictures.map((p: any) => {
      p.type = "";
    });
    machineFileList.value = machineForm.value.pictures;
  }

  visibleAddMachine.value = true;
  setTimeout(() => {
    macForm.value.clearValidate();
  }, 200);
};

let visibleImportMachine = ref(false);
const onImportMachineSuccess = () => {
  visibleImportMachine.value = false;
  getData();
};

const selectGroupM = (ev: any) => {
  machineForm.value.factoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.factoryId;
  machineForm.value.parentFactoryId = groupList.value.find(
    (p: any) => p.id === ev
  )?.parentId;
};

//设备图片
let machineFileList = ref<any>([]);

let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

//**图片上传* */
const previewVisible = ref(false);
const previewImage = ref("");
const previewTitle = ref("");
const handleCancel = () => {
  previewVisible.value = false;
  previewTitle.value = "";
};

const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};

const submitMachine = () => {
  macForm.value?.clearValidate();
  macForm.value
    .validate()
    .then(async (res: any) => {
      let form = JSON.parse(JSON.stringify(machineForm.value));
      if (machineFileList.value && machineFileList.value.length) {
        form.pictures = machineFileList.value.map((p: any) => ({
          url: p.url,
          type: 1,
        }));
      }

      if (form.type && form.type.length === 3) {
        let info = machineTypeListAll.value.find(
          (p: any) =>
            p.FirstCategory === form.type[0] &&
            p.SecondCategory === form.type[1] &&
            p.ThirdCategory === form.type[2]
        );
        form.machineTypeId = info.id;
      } else {
        form.machineTypeId = null;
      }
      delete form.type;

      let result = form.id
        ? await request.put("/api/machines/info", form)
        : await request.post("/api/machines", form);
      if (result && result.status === 200) {
        message.success("操作成功");
        visibleAddMachine.value = false;
        await getMember();
        getData();
      } else {
        message.warning("操作失败,请联系管理员!");
      }
    })
    .catch((err: any) => {
      console.log(err); //失败后执行
    });
};

// 报警记录
let visibleWarning = ref(false);
let warningList: any = ref([]);
let warnSearchModel: any = ref({
  machineId: null,
  date: null,
  type: null,
  condition: null,
  skip: 1,
  take: 20,
  total: 0,
  keyWord: "",
});
const warmPagination = computed(() => ({
  total: warnSearchModel.value.total,
  current: warnSearchModel.value.skip,
  pageSize: warnSearchModel.value.take,
}));
const changeWarnTable = (pagination: any) => {
  searchModel.value.skip = pagination.current;
  searchModel.value.take = pagination.pageSize;
  getWarningList();
};
let warnTypeList = [
  { label: '智能诊断', value: 1 },
  { label: '门限报警', value: 4 },
]
let warningColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["测点"],
    dataIndex: "sensorPlace",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "contactName",
  },
  {
    title: langObj.value["处理状态"],
    dataIndex: "status",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

const resetWarn = () => {
  warnSearchModel.value = {
    ...warnSearchModel.value,
    date: null,
    type: null,
    condition: null,
  };
};

const openMachineWarining = (ev: any) => {
  warnSearchModel.value.machineId = ev.id;
  visibleWarning.value = true;
  warnSearchModel.value.skip = 1;
  resetWarn();
  getWarningList();
};

const getWarningList = async () => {
  warningList.value = [];
  let config: any = {
    params: {
      skip: warnSearchModel.value.skip,
      take: warnSearchModel.value.take,
      machineId: warnSearchModel.value.machineId,
      typeList: [1, 4],
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (warnSearchModel.value.type) {
    delete config.params.typeList;
    config.params.type = warnSearchModel.value.type;
  }

  if (warnSearchModel.value.date && warnSearchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(warnSearchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(
      new Date(warnSearchModel.value.date[1]),
      2
    );
  }

  if (warnSearchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === warnSearchModel.value.condition
    )?.realValue;
  }

  let result = await request.get("/api/workflows", config);
  if (result) {
    warningList.value = result.data.list;
    warnSearchModel.value.total = result.data.total;
  }
};

//检修记录
let visibleRepair = ref(false);
let repairForm: any = ref({
  list: [],
});

let repairColumns = [
  {
    title: "报警时间",
    dataIndex: "id",
  },
  {
    title: "严重程度",
    dataIndex: "id",
  },
  {
    title: "故障描述",
    dataIndex: "id",
  },
];

let accuracyList = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 2 },
  { label: "故障", value: 3 },
  { label: "不确定", value: 4 },
];

const getRepairInfo = async (id: any) => {
  repairForm.value = { list: [] };

  let config: any = {
    params: {
      id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/reports/info", config);

  if (result && result.data) {
    repairForm.value = {
      begin: result.data.repairReport.begin,
      end: result.data.repairReport.end,
      maintainMessage: result.data.repairReport.maintainMessage,
      maintainPerson: result.data.repairReport.maintainPerson,
      machineName: result.data.machines[0].machineName,
      accuracy: accuracyList.find(
        (p: any) => p.value === result.data.repairReport.accuracy
      )?.label,
    };
    if (result.data.machines.length) {
      repairForm.value.groupName = getGroupName(
        result.data.machines[0].groupId
      );
    }
  }
};

// 健康总览
const viewMachine = (ev: any) => {
  if (ev) {
    localStorage.setItem("machine_id", ev.id);
    router.push("/diagnosisN/overview");
  }
};

// 查看专家报告
let visibleReport = ref(false);
let reportId = ref("");
let mrDref = ref();
const viewReport = (ev: any) => {
  visibleReport.value = true;
  reportId.value = ev.reportIds[0];
  setTimeout(() => {
    mrDref.value.show(ev.reportIds[0]);
  }, 500);
};
const cancel = (ev: any) => {
  if (ev) {
    visibleReport.value = false;
    reportId.value = "";
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const initNodeInfo = () => {
  sensorForm1.value?.clearValidate();
  sensorForm1.value
    .validate()
    .then(async (res: any) => {
      let result = await request.post("/api/sensors/initNode", JSON.parse(JSON.stringify(sensorForm.value)));
      if (result && !!result.data && !!result.data.data) {//自研das
        var allnode = result.data.data;
        sensorForm.value.sensorGroup.length = 0;
        var is3 = [];
        if (!!sensorForm.value.dataType) {
          is3 = sensorForm.value.dataType.filter((p: any) => !!p && (p.indexOf("X") >= 0 || p.indexOf("Y") >= 0));//包含XY指标/波形时，判断为三轴，否则为单轴
        }
        var pnode = allnode.find(p => p.node_type == 10);
        if (!!is3 && is3.length > 0) {
          allnode.map(function (node) {
            if (node.node_type != 10) {
              var sgname = node.node_type == 21 ? "竖直径向" : node.node_type == 22 ? "轴向" : node.node_type == 23 ? "水平径向" : "";
              var cd = node.node_type == 21 ? "Z" : node.node_type == 22 ? "X" : node.node_type == 23 ? "Y" : "";
              sensorForm.value.sensorGroup.push({ coordinate: cd, sonId: node.id, sensorGroupName: sgname, node_name: pnode?.node_name + "/" + node.node_name });
            }
          });
        } else {
          var node = allnode.find(p => p.node_type == 21);
          if (node) {
            sensorForm.value.sensorGroup.push({ coordinate: "Z", sonId: node.id, sensorGroupName: "竖直径向", node_name: pnode?.node_name + "/" + node.node_name });
          }
        }
        message.success("三轴参数生成成功!");
      } else {
        message.warning("三轴参数生成失败，请联系管理员!");
      }
    })
    .catch((err: any) => {
      message.warning("请先填写必填项!");
    });
};
//新用户引导新建设备按钮回调
let editRindex:any = ref(1000);
let editRindex2:any = ref(1001);
let dropdownClassName:any = ref("");
const addMachineDriver = ()=>{
    editRindex = 1000000001;
    dropdownClassName = "dropdownStyleDriver";
    addMachine();
};
//新用户引导新建设备下一步按钮回调
const nextRoute = ()=>{
    if(data.value.length==0)
    {
        message.warning("请先新建设备!");
        return;
    }
    selectMenu(2);
    setTimeout(()=>{
       highlightStep(getPopover(".editSensorDriver","6/9 编辑测点","设备新建后，需在测点列表操作编辑安装的传感器SN，绑定成功后才可完成数据回传。","编辑测点","left","center",nextRouteAlarm,editSensorDriver));
    },400)
};
//新用户引导编辑测点按钮回调
const editSensorDriver = ()=>{
    editRindex = 1000000001;
    editRindex2 = 1000000002;
    dropdownClassName = "dropdownStyleDriver";
    if(data.value.length>0){
       editSensor(data.value[0]);
    }
};
//新用户引导编辑测点下一步按钮回调
const nextRouteAlarm = ()=>{
    router.push("/warning-manage/warning-list");
    return;
};

const getMember = async () => {
  let result2 = await request.get("/api/members/info");
  localStorage.setItem("memberInfo", JSON.stringify(result2.data));
};
</script>

<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 0px solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}

.part {
  .table1 {
    border: 1px solid #0d53b7cc;
    width: 97%;
    padding: 5px 5px;
    .header {

    }
  }
}


</style>
