import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { style: {"color":"#fff"} }
const _hoisted_2 = { class: "w-full flex" }
const _hoisted_3 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_4 = { class: "p-3 w-full" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["onClick"]

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { getFactoryName, getGroupName, transformDate2, getGroupListByFactory, getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";


export default /*@__PURE__*/_defineComponent({
  __name: 'select-machine',
  props: {
    visible: null,
    editRindex2: null
},
  emits: ["selectMachineSen"],
  setup(__props, { emit: __emit }) {

let visible = ref(true)
let editRindex2 = ref("auto");
let groupList = ref([])
const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()
const machineColumns = [
    {
        title: langObj.value['设备编号'],
        dataIndex: 'machineCode',
    },  
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },  
    {
        title: langObj.value['所属工厂'],
        dataIndex: 'factoryId',
    },  
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupId',
    }, 
    {
        title: langObj.value['设备位置'],
        dataIndex: 'position',
    },  
    {
        title: langObj.value['设备厂家'],
        dataIndex: 'supplier',
    },  
    {
        title: langObj.value['设备型号'],
        dataIndex: 'model',
    },  
    {
        title: langObj.value['设备类型'],
        dataIndex: 'machineType',
    },  
    {
        title: langObj.value['设备类型缩写'],
        dataIndex: 'machineType',
    },  
    {
        title: langObj.value['设备SN号'],
        dataIndex: 'sn',
    },  
    {
        title: langObj.value['巡检序号'],
        dataIndex: 'sort',
    },  
    // {
    //     title: langObj.value['是否实验台'],
    //     dataIndex: 'experiment',
    // },  
    {
        title:langObj.value['设备描述'],
        dataIndex: 'remark',
    },  
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },  
]
const props: any = __props;
let searchModel:any = ref({
    groupId: '',
})
let data:any = ref([])

const emits = __emit;

watch(
    () => props.visible,
    (newValue, oldValue) => {
        visible.value = newValue
        init()
    }
);

onMounted(() => {
    editRindex2.value = props.editRindex2
    init()
})

const cancel = () => {
    visible.value = false
    emits("selectMachineSen", false);
}

const select = (ev:any) => {
    visible.value = false
    emits("selectMachineSen", ev);
}

const init = () => {
    getGroupList()
    getData()
}

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory()
}

const getData = async() => {
    let config: any = {
        params: {
            groupId: searchModel.value.groupId,
            factoryIds: getRealFactoryIdList()
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request('/api/machinesall', config)
    if(result && result.status === 200) {
        data.value = result.data
        console.log(' data.value',  data.value)
    }
}

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

return (_ctx: any,_cache: any) => {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      visible: _unref(visible),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      closable: false,
      footer: null,
      bodyStyle: {'background': '#020F46'},
      style: {"top":"30%"},
      width: "1500px",
      maskClosable: false,
      "z-index": _unref(editRindex2),
      "onUpdate:zIndex": _cache[2] || (_cache[2] = ($event: any) => (_isRef(editRindex2) ? (editRindex2).value = $event : editRindex2 = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: cancel
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(langObj)['选择设备']), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_form, {
              model: _unref(searchModel),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['设备区域'],
                  name: "groupId"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_select, {
                      value: _unref(searchModel).groupId,
                      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).groupId) = $event)),
                      style: {"width":"220px"},
                      allowClear: ""
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(groupList), (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_select_option, {
                            key: index,
                            value: item.id
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", {
                        onClick: getData,
                        class: "box px-5 py-1 fff cursor-pointer ml-4",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_unref(langObj)['查询']), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_table, {
                style: {"width":"100%"},
                size: "small",
                "row-class-name": getRowClassName,
                columns: machineColumns,
                "data-source": _unref(data),
                pagination: false
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'factoryId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(getFactoryName)(text)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'groupId')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_unref(getGroupName)(text)), 1))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'act')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 2,
                        onClick: ($event: any) => (select(record)),
                        class: "theme-table-link"
                      }, _toDisplayString(_unref(langObj)['选择']), 9, _hoisted_9))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "z-index"])
  ]))
}
}

})