import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/cliqueD4.png'
import _imports_1 from '@/assets/machineD1.png'
import _imports_2 from '@/assets/machineD2.png'
import _imports_3 from '@/assets/machineD3.png'
import _imports_4 from '@/assets/zhenduan.png'
import _imports_5 from '@/assets/weixiu.png'
import _imports_6 from '@/assets/menxian.png'


const _hoisted_1 = {
  class: "w-full flex px-7 py-2 theme-bg theme-machine theme-common",
  style: {"background-color":"#000a31","color":"#fff","overflow-y":"scroll","height":"calc(100vh - 100px)"}
}
const _hoisted_2 = { style: {"width":"250px"} }
const _hoisted_3 = {
  key: 0,
  class: "flex-1 ml-7"
}
const _hoisted_4 = { class: "w-full flex items-center" }
const _hoisted_5 = { style: {"font-size":"24px"} }
const _hoisted_6 = { class: "w-full flex mt-7" }
const _hoisted_7 = { style: {"min-width":"476px"} }
const _hoisted_8 = { class: "w-full flex items-center" }
const _hoisted_9 = { class: "box2" }
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { style: {"text-align":"center"} }
const _hoisted_12 = { class: "box2 ml-5" }
const _hoisted_13 = { class: "content" }
const _hoisted_14 = { style: {"text-align":"center"} }
const _hoisted_15 = { class: "w-full mt-7 box3" }
const _hoisted_16 = { class: "w-full flex items-center" }
const _hoisted_17 = { class: "size18 ml-2" }
const _hoisted_18 = { class: "w-full flex flex-wrap mt-4" }
const _hoisted_19 = { class: "w-1/2" }
const _hoisted_20 = { style: {"opacity":"0.8"} }
const _hoisted_21 = { class: "w-1/2" }
const _hoisted_22 = { style: {"opacity":"0.8"} }
const _hoisted_23 = { class: "w-1/2 mt-2" }
const _hoisted_24 = { style: {"opacity":"0.8"} }
const _hoisted_25 = { class: "w-1/2 mt-2" }
const _hoisted_26 = { style: {"opacity":"0.8"} }
const _hoisted_27 = { class: "w-full mt-7 box3" }
const _hoisted_28 = { class: "w-full flex items-center" }
const _hoisted_29 = { class: "size18 ml-2" }
const _hoisted_30 = { class: "w-full flex items-center" }
const _hoisted_31 = { class: "ml-2" }
const _hoisted_32 = { class: "w-full flex items-center" }
const _hoisted_33 = { class: "ml-2" }
const _hoisted_34 = { class: "w-full flex mt-4" }
const _hoisted_35 = {
  class: "flex-1 flex flex-col",
  style: {"height":"290px"}
}
const _hoisted_36 = { class: "flex-1 mt-4" }
const _hoisted_37 = {
  key: 0,
  id: "chartAM",
  style: {"height":"200px","width":"200px"}
}
const _hoisted_38 = {
  class: "w-full",
  style: {"text-align":"center"}
}
const _hoisted_39 = { style: {"color":"#4efaee"} }
const _hoisted_40 = { class: "ml-4" }
const _hoisted_41 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_42 = { class: "ml-2" }
const _hoisted_43 = { class: "ml-2" }
const _hoisted_44 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_45 = { class: "ml-2 opt8" }
const _hoisted_46 = { class: "ml-2 opt8" }
const _hoisted_47 = { class: "mt-4" }
const _hoisted_48 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_49 = { class: "ml-2" }
const _hoisted_50 = { class: "ml-2" }
const _hoisted_51 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_52 = { class: "ml-2 opt8" }
const _hoisted_53 = { class: "ml-2 opt8" }
const _hoisted_54 = { class: "mt-4" }
const _hoisted_55 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_56 = { class: "ml-2" }
const _hoisted_57 = { class: "ml-2" }
const _hoisted_58 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_59 = { class: "ml-2 opt8" }
const _hoisted_60 = { class: "ml-2 opt8" }
const _hoisted_61 = { class: "mt-4" }
const _hoisted_62 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_63 = { class: "ml-2" }
const _hoisted_64 = { class: "ml-2" }
const _hoisted_65 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_66 = { class: "ml-2 opt8" }
const _hoisted_67 = { class: "ml-2 opt8" }
const _hoisted_68 = { class: "mt-4" }
const _hoisted_69 = {
  class: "flex items-center",
  style: {"font-size":"16px"}
}
const _hoisted_70 = { class: "ml-2" }
const _hoisted_71 = { class: "ml-2" }
const _hoisted_72 = {
  class: "flex items-center",
  style: {"font-size":"13px"}
}
const _hoisted_73 = { class: "ml-2 opt8" }
const _hoisted_74 = { class: "ml-2 opt8" }
const _hoisted_75 = { class: "flex-1" }
const _hoisted_76 = { class: "w-full flex" }
const _hoisted_77 = { class: "flex items-center mr-8" }
const _hoisted_78 = { class: "ml-2" }
const _hoisted_79 = { class: "ml-2" }
const _hoisted_80 = { class: "ml-2" }
const _hoisted_81 = { class: "ml-2" }
const _hoisted_82 = {
  key: 0,
  id: "yulan",
  style: {"margin":"auto","padding":"20px","width":"800px","height":"600px","position":"relative"}
}
const _hoisted_83 = ["src"]
const _hoisted_84 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_85 = { class: "w-full flex" }
const _hoisted_86 = { class: "w-full flex items-center" }
const _hoisted_87 = { style: {"width":"35%"} }
const _hoisted_88 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_89 = { style: {"width":"35%"} }
const _hoisted_90 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_91 = { style: {"width":"30%","word-wrap":"break-word"} }
const _hoisted_92 = { style: {"font-size":"12px","opacity":"0.6"} }
const _hoisted_93 = { class: "w-full flex" }
const _hoisted_94 = {
  class: "mr-2",
  style: {"font-size":"12px","opacity":"0.6"}
}
const _hoisted_95 = {
  class: "box3",
  style: {"width":"360px"}
}
const _hoisted_96 = { class: "w-full flex items-center" }
const _hoisted_97 = { class: "size18 ml-2" }
const _hoisted_98 = { class: "w-full flex items-center justify-between mt-2 whiteBg" }
const _hoisted_99 = { class: "w-full flex" }
const _hoisted_100 = {
  key: 0,
  id: "chartMachienB",
  style: {"width":"290px","height":"290px"}
}
const _hoisted_101 = {
  class: "box3 mt-7",
  style: {"width":"360px"}
}
const _hoisted_102 = { class: "w-full flex items-center" }
const _hoisted_103 = { class: "size18 ml-2" }
const _hoisted_104 = { class: "mt-2 w-full flex whiteBg" }
const _hoisted_105 = { class: "mt-2 w-full" }
const _hoisted_106 = { class: "w-full flex items-center" }
const _hoisted_107 = {
  key: 0,
  style: {"width":"18px","height":"18px"},
  src: _imports_4,
  alt: ""
}
const _hoisted_108 = {
  key: 1,
  style: {"width":"18px","height":"18px"},
  src: _imports_5,
  alt: ""
}
const _hoisted_109 = {
  key: 2,
  style: {"width":"18px","height":"18px"},
  src: _imports_6,
  alt: ""
}
const _hoisted_110 = {
  key: 4,
  class: "ml-2"
}
const _hoisted_111 = {
  key: 5,
  class: "ml-2"
}
const _hoisted_112 = {
  key: 6,
  class: "box6 opt8"
}
const _hoisted_113 = {
  key: 7,
  class: "box6 opt8"
}
const _hoisted_114 = { class: "mt-2" }
const _hoisted_115 = {
  class: "mt-2 opt6",
  style: {"font-size":"12px"}
}
const _hoisted_116 = {
  key: 1,
  class: "flex-1 ml-7"
}
const _hoisted_117 = { class: "w-full flex items-center" }
const _hoisted_118 = { style: {"font-size":"24px"} }
const _hoisted_119 = { class: "w-full flex" }
const _hoisted_120 = {
  class: "w-full flex items-center",
  style: {"border-bottom":"1px solid rgba(62, 73, 114, 1)"}
}
const _hoisted_121 = { class: "ml-3" }
const _hoisted_122 = { class: "mt-2 w-full flex items-center" }
const _hoisted_123 = { class: "pic" }
const _hoisted_124 = ["src"]
const _hoisted_125 = { class: "flex-1 ml-4" }
const _hoisted_126 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_127 = { class: "text-gray-50 pl-2" }
const _hoisted_128 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_129 = { class: "text-gray-50 pl-2" }
const _hoisted_130 = { class: "flex items-center mt-2" }
const _hoisted_131 = { class: "text-gray-50 pl-2" }
const _hoisted_132 = { class: "flex-1 flex" }
const _hoisted_133 = { style: {"width":"80%","display":"flex","justify-content":"center","position":"relative"} }
const _hoisted_134 = {
  key: 0,
  id: "yulan",
  style: {"margin":"auto","padding":"20px","width":"1000px","height":"800px","position":"relative"}
}
const _hoisted_135 = ["src"]
const _hoisted_136 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_137 = { class: "w-full flex" }
const _hoisted_138 = { style: {"font-size":"18px"} }

import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import pictureListComponent from "../components/picture-list.vue";
import { langList } from "../../common/lang";
import {
  transformDate,
  transformDate2,
  getGroupName,
  getFactoryName,
} from "../../common/tools";
import * as echarts from "echarts";
import { getLanguage } from "../../common/translate"


export default /*@__PURE__*/_defineComponent({
  __name: 'machine',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
  theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
  console.log(theme.value)
}
getLang()

const router = useRouter();
let value = ref("");
let machine_id = "";
let machineInfo: any = ref({});

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f", color2: "#0D53B7" },
  { label: "可用", value: [1, 2], color: "#d2de49", color2: "#d2de49" },
  { label: "警戒", value: [3, 4, 5], color: "#cd5f3b", color2: "#cd5f3b" },
  { label: "故障", value: [6, 7], color: "#921e37", color2: "#921e37" },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};

const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};

// let conditionLists = [
//     {label: '健康', value: 0, color: '#61c08f'},
//     {label: '可用', value: 1, color: '#d2de49'},
//     {label: '可用', value: 2, color: '#d2de49'},
//     {label: '警戒', value: 3, color: '#cd5f3b'},
//     {label: '警戒', value: 4, color: '#cd5f3b'},
//     {label: '警戒', value: 5, color: '#cd5f3b'},
//     {label: '故障', value: 6, color: '#921e37'},
//     {label: '故障', value: 7, color: '#921e37'},
// ]

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "停机", value: false, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
  { label: "运行", value: true, color: "#4EFAEE" },
];

let selectType: any = ref(1)

const selectMachine = (ev: any) => {
  selectType.value = 1
  machine_id = ev;
  selecrRandar.value = 2;
  picListAll.value = [];
  picList.value = [];
  init();
};

const init = () => {
  getMachineInfo();
  getMachineStatusTime();
  getMachineWarning();
};

// 获取设备详情
const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (
      machineInfo.value &&
      machineInfo.value.workStatus &&
      machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
        p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    machineInfo.value.status = statusList[0];
    if (machineInfo.value &&
      machineInfo.value.workStatus) {
      let status = statusList.find(
        (p: any) => p.value === (machineInfo.value.workStatus.quotaWkStatus ?? machineInfo.value.workStatus.scoreWkStatus ?? 0)
      );
      status && (machineInfo.value.status = status)
    }
    load3D();
  }

  if (machineInfo.value.pictures?.length > 0) {
    // picList.value.push({
    //   name: "设备",
    //   url: machineInfo.value.pictures[0].url,
    // });
    machineInfo.value.pictures.map((d: any) => {
      picList.value.push({
        name: "设备",
        url: d.url,
      });
    })
  }
  getSensorList();
  getRandarChartData();
};

let picList: any = ref([]);
let picListAll: any = ref([]);
const getSensorList = async () => {
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/sensors", config);

  if (result && result.status === 200) {
    result.data.map((d: any) => {
      if (d.pictures) {
        d.pictures.map((p: any) => {
          picList.value.push({
            name: d.sensorPlace,
            url: p.url
          })
        })
      }
    });
  }

  picListAll.value = picList.value;
};

// 获取设备状态占比
let satusTimeDate: any = ref(1);
let visibleChart = ref(true);
let conditionTotal: any = ref({
  condition1Date: "--",
  condition2Date: "--",
  condition3Date: "--",
  condition4Date: "--",
  condition1Rate: 0,
  condition2Rate: 0,
  condition3Rate: 0,
  condition4Rate: 0,
  wkStatus1Date: "--",
  wkStatus2Date: "--",
  wkStatus1Rate: 0,
  wkStatus2Rate: 0,
});
const getMachineStatusTime = async () => {
  conditionTotal.value = {
    condition1Date: "--",
    condition2Date: "--",
    condition3Date: "--",
    condition4Date: "--",
    condition1Rate: 0,
    condition2Rate: 0,
    condition3Rate: 0,
    condition4Rate: 0,
    wkStatus1Date: "--",
    wkStatus2Date: "--",
    wkStatus1Rate: 0,
    wkStatus2Rate: 0,
  };
  let time = new Date();
  let endDate = transformDate2(time, 1);


  // satusTimeDate.value = 3;
  // //本年
  // if (satusTimeDate.value === 3) {
  //   startDate = `${year}/01/01 00:00:00`;
  // }
  //本月
  let num = satusTimeDate.value === 2 ? 30 : 7

  let startTime = time.getTime() - (1000 * 60 * 60 * 24 * num)
  let startDate = transformDate2(startTime, 1)


  let stableSearch = {
    params: {
      begin: startDate,
      end: endDate,
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result2 = await request.get(
    "/api/machines/workstatus/stables",
    stableSearch
  );
  let stableList: any = [];
  if (result2 && result2.data && result2.data.length) {
    stableList = result2.data;
  }

  let beginT = new Date(startDate).getTime();
  let endT = new Date(endDate).getTime();

  let condition0 = 0; //无
  let condition1 = 0; //健康
  let condition2 = 0; //可用
  let condition3 = 0; //警戒
  let condition4 = 0; //故障
  let conditionT = endT - beginT;
  if (stableList?.length > 0) {
    stableList.map((d: any, index: any) => {
      if (index == 0) {
        condition0 = new Date(d.date).getTime() - beginT;
      } else {
        let time =
          new Date(d.date).getTime() -
          new Date(stableList[index - 1].date).getTime();

        if (d.condition === 0) {
          condition1 = condition1 + time;
        }
        if (d.condition >= 1 && d.condition <= 2) {
          condition2 = condition2 + time;
        }
        if (d.condition >= 3 && d.condition <= 5) {
          condition3 = condition3 + time;
        }
        if (d.condition >= 6 && d.condition <= 7) {
          condition4 = condition4 + time;
        }
      }

      if (index === stableList.length - 1) {
        condition0 = condition0 + (endT - new Date(d.date).getTime());
      }
    });
  } else {
    condition1 = 0
    condition2 = 0
    condition3 = 0
    condition4 = 0
    condition0 = conditionT
  }


  let result4 = await request.get('/api/external/machines/onlines', stableSearch)
  let wkStatus1 = 0; //运行
  let wkStatus2 = 0; //停机
  let dateWK1 = 0
  if (result4 && result4.data) {
    result4.data.map((d: any) => {
      dateWK1 = dateWK1 + Math.round(d.online / 3600)
    })
  }
  wkStatus1 = dateWK1 * 60 * 60 * 1000
  wkStatus2 = conditionT - wkStatus1

  function convertTimestamp(timestamp: any) {
    var milliseconds = timestamp;
    var date: any = new Date(milliseconds);

    var days = Math.floor(date / (24 * 60 * 60 * 1000));
    var hours = Math.floor((date % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    var minutes = Math.floor((date % (60 * 60 * 1000)) / (60 * 1000));
    return days + getLanguage("天", language.value) + ' ' + hours + getLanguage("时", language.value) + ' ' + minutes + getLanguage("分2", language.value);
  }

  conditionTotal.value = {
    condition1Date: convertTimestamp(condition1),
    condition2Date: convertTimestamp(condition2),
    condition3Date: convertTimestamp(condition3),
    condition4Date: convertTimestamp(condition4),
    condition0Rate: Number(((condition0 / conditionT) * 100).toFixed(0)),
    condition1Rate: Number(((condition1 / conditionT) * 100).toFixed(0)),
    condition2Rate: Number(((condition2 / conditionT) * 100).toFixed(0)),
    condition3Rate: Number(((condition3 / conditionT) * 100).toFixed(0)),
    condition4Rate: Number(((condition4 / conditionT) * 100).toFixed(0)),
    wkStatus1Rate: Number(((wkStatus1 / conditionT) * 100).toFixed(0)),
    wkStatus2Rate: Number(
      (((wkStatus2) / conditionT) * 100).toFixed(0)
    ),
    wkStatus1Date: convertTimestamp(wkStatus1),
    wkStatus2Date: convertTimestamp(wkStatus2),
    wkStatsu: convertTimestamp(wkStatus1)
  };

  visibleChart.value = false;
  visibleChart.value = true;

  setTimeout(() => {
    initMacineStatusRateChart();
  }, 500);
};

//绘制设备状态占比图
const initMacineStatusRateChart = () => {
  const ec = echarts as any;
  if (!document.getElementById("chartAM")) return;
  let myChart = ec.init(document.getElementById("chartAM"));

  const data = [
    {
      name: "外层饼图",
      children: [
        { value: conditionTotal.value.condition1Rate, name: "健康" },
        { value: conditionTotal.value.condition2Rate, name: "可用" },
        { value: conditionTotal.value.condition3Rate, name: "警戒" },
        { value: conditionTotal.value.condition4Rate, name: "故障" },
        { value: conditionTotal.value.condition0Rate, name: "停机" },
      ],
    },
    {
      name: "内层饼图",
      children: [
        { value: conditionTotal.value.wkStatus1Rate, name: "运行" },
        { value: conditionTotal.value.wkStatus2Rate, name: "停机" },
      ],
    },
  ];

  // 配置选项
  const option = {
    color: ["#1CC48B", "#DDD246", "#CB6A34", "#B81212", "#ADADB0"],
    grid: {
      right: "0%",
      left: "0%",
      top: 0,
      bottom: 0,
    },
    series: [
      {
        name: "外层饼图",
        type: "pie",
        radius: ["70%", "100%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[0].children,
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
      {
        name: "内层饼图",
        type: "pie",
        radius: ["40%", "70%"], // 控制内外半径
        label: {
          show: false, // 不显示标签
        },
        data: data[1].children,
        color: ["#4DFAED", "#ADADB0"],
        emphasis: {
          scale: false, // 取消鼠标移入放大效果
        },
      },
    ],
  };

  // 使用配置项显示图表
  myChart.setOption(option);
};

// 获取雷达图数据
let selecrRandar = ref(2); //1健康  2振动
let visibleChart2 = ref(true);
const getRandarChartData = async () => {
  let faultnamesSearch = {
    params: { machineId: machine_id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/faultnames", faultnamesSearch);
  let faultName = result.data.faultNames;
  let faultNameII = result.data.faultNameExpects;

  let Slist: any = [];
  let Tlist: any = [];

  if (
    machineInfo.value.workStatus &&
    machineInfo.value.workStatus.stableScores
  ) {
    for (let index in machineInfo.value.workStatus.stableScores) {
      const item = machineInfo.value.workStatus.stableScores[index];
      if (index.includes("S")) {
        Slist.push({
          quota: index,
          score: item,
          text: langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title,
        });
      }

      if (index.includes("T")) {
        Tlist.push({
          quota: index,
          score: item,
          text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
        });
      }
    }
  }

  machineInfo.value.Tlist = Tlist;
  machineInfo.value.Slist = Slist;

  // 获取分数趋势数据
  let end = transformDate(new Date());
  let begin = transformDate(new Date().getTime() - 1000 * 60 * 60 * 24);

  let search = {
    params: {
      begin,
      end,
      sensorId: "",
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let score = await request.get("/api/external/machines/score-full", search);
  let scoreAll = score.data;

  let scoreDataList: any = [];

  scoreAll.map((item: any) => {
    let d = item.date;
    let info1 = scoreAll.find((p: any) => p.date === d);
    delete info1.date;
    delete info1.wkStatus;
    delete info1.condition;
    let list = Object.values(info1).sort();
    scoreDataList.push({
      date: d,
      value: list[0],
    });
  });

  machineInfo.value.scoreDataList = scoreDataList;

  visibleChart2.value = false;
  visibleChart2.value = true;

  setTimeout(() => {
    if (selecrRandar.value === 1) {
      initRandarChart(Slist);
    }
    if (selecrRandar.value === 2) {
      initRandarChart(Tlist);
    }
  }, 500);
};

// 绘制雷达图
const initRandarChart = (dataList: any) => {
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB")) return;
  let myChart = ec.init(document.getElementById("chartMachienB"));
  myChart.clear();
  if (!dataList.length) return;
  let data: any = [];
  let error: any = [];
  let indicator: any = [];
  dataList.map((d: any) => {
    data.push(d.score);
    error.push(60);
    let color = "";
    if (d.score >= 0 && d.score < 60) {
      color = "#CD5F3B";
    }
    if (d.score >= 60 && d.score < 80) {
      color = "#D2DE49";
    }
    if (d.score >= 80 && d.score <= 100) {
      color = theme.value == 'white' ? '#333' : "#fff";
    }

    let name = ''
    if (language.value == "Chinese" && d.text) {
      if (d.text.length > 8) {
        name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
      } else if (d.text.length > 4 && d.text.length <= 8) {
        name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
      } else {
        name = d.text
      }
    }

    if (language.value == "English" && d.text) {
      let list1 = d.text.split(' ')

      let str: any = ''
      let num = 0
      let sy = ''
      list1.map((n: any, i: any) => {
        if (i == 0) {
          if (n.length <= 10) {
            num = n.length
            str = n
          } else {
            num = 10
            let a1 = n.substring(0, 10)
            sy = n.slice(10)
            str = a1
          }
        } else {

          if (sy) {
            str = str + '\n';
            let n2 = sy.length + n.length
            if (n2 <= 10) {
              num = n2
              str = str + sy + ' ' + n
              sy = ''
            } else {
              num = 10
              str = str + sy
              let n2 = 10 - sy.length >= 0 ? 10 - sy.length : 0

              let a1 = n.substring(0, n2)
              sy = n.slice(10)
              str = str + a1
            }
          } else {
            if (num + n.length <= 10) {
              str = str + ' ' + n
              num = num + n.length
            } else {
              str = str + '\n' + n
              num = n.length
            }
          }
        }
      })
      name = str
    }

    indicator.push({
      color,
      max: 100,
      text: name
    });
  });

  const option = {
    title: {
      text: "",
    },
    tooltip: { position: { left: '0px' } },
    radar: [
      {
        indicator: indicator,
        center: ["50%", "50%"],
        radius: 60,
        startAngle: 90,
        splitNumber: 5,
        name: {
          formatter: "{value}",
          textStyle: {
            color: "rgba(46, 214, 157, 1)",
            fontSize: 12,
          },
        },
        splitArea: {
          areaStyle: {
            color: ["#605969", "#453A47"],
          },
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgb(241,243,250)"],
            width: 0,
          },
        },
      },
    ],
    series: [
      {
        name: "当前值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: data,
            lineStyle: {
              width: 1,
              color: "rgba(247,220,225,0.1)",
              normal: {
                type: "dotted",
              },
            },
            itemStyle: {
              color: "#FF4E13",
              borderWidth: 5,
            },
            areaStyle: {
              color: "#F7DCE1",
            },
          },
        ],
      },
      {
        name: "预警值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 1,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: error,
            name: "",
            lineStyle: {
              width: 2,
              color: "rgba(255, 25, 59, 0.2)",
            },
            itemStyle: {
              color: "rgba(255, 25, 59, 0.2)",
              borderWidth: 0,
            },
          },
        ],
      },
    ],
  };

  option && myChart.setOption(option);
};

//绘制分数趋势图
const initScoreChart = () => {
  let data = machineInfo.value.scoreDataList;
  const ec = echarts as any;
  if (!document.getElementById("chartMachienB")) return;
  let myChart = ec.init(document.getElementById("chartMachienB"));
  myChart.clear();
  let xData = data.map((p: any) => p.date);
  let yData = data.map((p: any) => p.value);

  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [{ type: "inside" }],
    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: xData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: yData,
        type: "line",
      },
    ],
  };

  myChart.setOption(option);
};

// 选择雷达图、分数趋势图
const selectTag = (ev: any) => {
  selecrRandar.value = ev;
  if (ev === 1) {
    initRandarChart(machineInfo.value.Slist);
  }

  if (ev === 2) {
    initRandarChart(machineInfo.value.Tlist);
  }

  if (ev === 3) {
    initScoreChart();
  }
};

// 获取设备事件
let warningType = ref(1);
let warningTotal = ref(0);
let machineWarningData: any = ref([]);
const getMachineWarning = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 3,
      machineId: machine_id,
      typeList: warningType.value === 1 ? [1, 4] : [2],
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows", config);
  if (result && result.data) {
    machineWarningData.value = result.data.list;
    warningTotal.value = result.data.total;
  }
};

// 获取3D图测点数据
const get3DData = async () => {
  let config: any = {
    params: {
      id: machine_id,
    },

    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  if (result && result.data) {
    config_3d.value.marks?.map((d: any) => {
      if (d.bind.includes("b-")) {
        let list = d.bind.split("-");
        if (list && list.length === 2) {
          let sensorId = list[1];
          let sensorInfo = result.data.sensors.find(
            (p: any) => p.id === sensorId
          );
          if (sensorInfo) {
            d.sensorPlace = sensorInfo.sensorPlace;
          } else {
            d.sensorPlace = "";
          }

          let info = result.data.quotas.find(
            (p: any) => p.sensorId === sensorId
          );
          if (info) {
            if (info.values.length) {
              let sz = info.values.find((p: any) => p.type === "速度Z");
              d.sz = sz ? sz.value.toFixed(2) : "0";
              let hz = info.values.find((p: any) => p.type === "高频加速度");
              d.hz = hz ? hz.value.toFixed(2) : "0";
              let temp = info.values.find((p: any) => p.type === "温度");
              d.temp = temp ? temp.value.toFixed(2) : "0";
            }
            d.date = info.date
          }
          let stableInfo = result.data.stableSensors && result.data.stableSensors.find(
            (p: any) => p.id === sensorId
          );
          if (stableInfo) {
            delete stableInfo.id;
            let list = Object.values(stableInfo).sort();
            let score: any = 100;
            if (list && list.length) {
              score = list[0];
            }

            if (score >= 80 && score <= 100) {
              d.condition = 0;
            }
            if (score >= 60 && score < 80) {
              d.condition = 1;
            }
            if (score >= 30 && score < 60) {
              d.condition = 3;
            }
            if (score >= 0 && score < 30) {
              d.condition = 6;
            }
          }
        }
      }
    });
  }
};

let markList = ref([]);

let config_3d = ref({
  marks: [],
});

let line = ref([]);

const load3D = async () => {
  config_3d.value = {};
  let config: any = {
    params: {
      machineId: machine_id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-3D", config);
  if (result) {
    if (
      (!result.data || !result.data.picture) &&
      machineInfo.value.machineTypeId
    ) {
      config = {
        params: {
          _id: machineInfo.value.machineTypeId,
        },
        headers: {
          requestId: uuidv4(),
        },
      };
      // 查询3d模型库
      let res = await request.get("/api/machine-type", config);

      if (res.data) {
        result.data = {
          picture: res.data.picture
        };
      }
    }

    config_3d.value = result.data || {
      marks: [],
    };
    markList.value = config_3d.value.marks;
  }
  line.value = [];
  if (config_3d.value.marks?.length > 0) {
    for (var i = 0; i < config_3d.value.marks.length; i++) {
      var item = config_3d.value.marks[i];
      if (item.bind.indexOf("a-") > -1) {
        var find = config_3d.value.marks.find(
          (t) =>
            t.bind.substring(2, t.bind.length) ==
            item.bind.substring(2, item.bind.length) &&
            t.bind.indexOf("b-") > -1
        );
        if (find) {
          var distance = Math.sqrt(
            Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
          );
          var angle_d =
            Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
          line.value.push({
            ...item,
            distance: distance,
            angle: angle_d,
          });
        }
      }
    }
  }

  get3DData();
};

// 查看更多
const viewMore = () => {
  router.push("/warning-manage/warning-list");
};


// 选择区域
let group_info: any = ref({})
let group_machineList: any = ref([])
let config_3d_group: any = ref({})
let lineGroup: any = ref([])

const selectGroup = (ev: any) => {
  selectType.value = 2
  if (ev) {
    group_info.value = ev
    group_machineList = ev.machineList || []
  }

  loadGroup3D()
}

const loadGroup3D = async () => {
  config_3d_group.value = { marks: [] };

  let config: any = {
    params: {
      groupId: group_info.value.id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/group-3D", config);
  console.log('result.data2222222222', result.data)
  if (result?.data) {
    config_3d_group.value = result.data

    markList.value = config_3d_group.value.marks;
  }


  lineGroup.value = [];
  for (var i = 0; i < config_3d_group.value.marks.length; i++) {
    var item = config_3d_group.value.marks[i];
    if (item.bind.indexOf("a-") > -1) {
      var find = config_3d_group.value.marks.find(
        (t: any) =>
          t.bind.substring(2, t.bind.length) ==
          item.bind.substring(2, item.bind.length) &&
          t.bind.indexOf("b-") > -1
      );
      if (find) {
        var distance = Math.sqrt(
          Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
        );
        var angle_d =
          Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
        lineGroup.value.push({
          ...item,
          distance: distance,
          angle: angle_d,
        });
      }
    }

  }
}


return (_ctx: any,_cache: any) => {
  const _component_calendar_outlined = _resolveComponent("calendar-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(machineListLeftComponent, {
        onSelectMachine: selectMachine,
        onSelectGroup: selectGroup,
        type: 3,
        mode: 1
      })
    ]),
    (_unref(selectType) == 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(machineInfo).machineName), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _cache[6] || (_cache[6] = _createElementVNode("img", {
                    class: "w-full h-full theme-white-hide",
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", {
                      class: "w-full",
                      style: _normalizeStyle([{ color: _unref(machineInfo)?.condition?.color }, {"font-size":"24px","text-align":"center"}])
                    }, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.condition?.label]), 5),
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(langObj)['综合健康状态']), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[7] || (_cache[7] = _createElementVNode("img", {
                    class: "w-full h-full theme-white-hide",
                    src: _imports_0,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", {
                      class: "w-full",
                      style: _normalizeStyle([{ color: _unref(machineInfo)?.status?.color }, {"font-size":"24px","text-align":"center"}])
                    }, _toDisplayString(_unref(langObj)[_unref(machineInfo)?.status?.label]), 5),
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)['当前运行状态']), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _cache[8] || (_cache[8] = _createElementVNode("img", {
                    style: {"width":"18px","height":"18px"},
                    src: _imports_1,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(langObj)['设备信息']), 1)
                ]),
                _createVNode(pictureListComponent, {
                  type: 3,
                  picList: _unref(picListAll)
                }, null, 8, ["picList"]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, _toDisplayString(_unref(langObj)['设备名称']), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).machineName), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(langObj)['设备区域']), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(getGroupName)(_unref(machineInfo).groupId)), 1)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(langObj)['设备厂家']), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).supplier), 1)
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(langObj)['设备编号']), 1),
                    _createElementVNode("div", null, _toDisplayString(_unref(machineInfo).machineCode), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _cache[9] || (_cache[9] = _createElementVNode("img", {
                    style: {"width":"18px","height":"18px"},
                    src: _imports_2,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_29, _toDisplayString(_unref(langObj)['设备状态占比']), 1),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _createVNode(_component_a_select, {
                    ref: "select",
                    value: _unref(satusTimeDate),
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(satusTimeDate) ? (satusTimeDate).value = $event : satusTimeDate = $event)),
                    style: {"min-width":"120px"},
                    onChange: getMachineStatusTime
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select_option, { value: 1 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_component_calendar_outlined),
                            _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(langObj)['近一周']), 1)
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_select_option, { value: 2 }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_component_calendar_outlined),
                            _createElementVNode("div", _hoisted_33, _toDisplayString(_unref(langObj)['近一月']), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      (_unref(visibleChart))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_37))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_38, [
                      _createTextVNode(_toDisplayString(_unref(langObj)['累计运行时间']) + "：", 1),
                      _createElementVNode("span", _hoisted_39, _toDisplayString(_unref(conditionTotal).wkStatsu), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_40, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_41, [
                        _cache[11] || (_cache[11] = _createElementVNode("div", {
                          class: "box4",
                          style: {"background-color":"#4efaee"}
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_42, _toDisplayString(_unref(langObj)['运行占比']) + " :", 1),
                        _createElementVNode("div", _hoisted_43, _toDisplayString(_unref(conditionTotal).wkStatus1Rate) + "%", 1)
                      ]),
                      _createElementVNode("div", _hoisted_44, [
                        _cache[12] || (_cache[12] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                        _createElementVNode("div", _hoisted_45, _toDisplayString(_unref(langObj)['运行时间']) + ":", 1),
                        _createElementVNode("div", _hoisted_46, _toDisplayString(_unref(conditionTotal).wkStatus1Date), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_47, [
                      _createElementVNode("div", _hoisted_48, [
                        _cache[13] || (_cache[13] = _createElementVNode("div", {
                          class: "box4",
                          style: {"background-color":"#1cc48b"}
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_49, _toDisplayString(_unref(langObj)['健康占比']) + ":", 1),
                        _createElementVNode("div", _hoisted_50, _toDisplayString(_unref(conditionTotal).condition1Rate) + "%", 1)
                      ]),
                      _createElementVNode("div", _hoisted_51, [
                        _cache[14] || (_cache[14] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                        _createElementVNode("div", _hoisted_52, _toDisplayString(_unref(langObj)['健康时间']) + ":", 1),
                        _createElementVNode("div", _hoisted_53, _toDisplayString(_unref(conditionTotal).condition1Date), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_54, [
                      _createElementVNode("div", _hoisted_55, [
                        _cache[15] || (_cache[15] = _createElementVNode("div", {
                          class: "box4",
                          style: {"background-color":"#ddd246"}
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_56, _toDisplayString(_unref(langObj)['可用占比']) + ":", 1),
                        _createElementVNode("div", _hoisted_57, _toDisplayString(_unref(conditionTotal).condition2Rate) + "%", 1)
                      ]),
                      _createElementVNode("div", _hoisted_58, [
                        _cache[16] || (_cache[16] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                        _createElementVNode("div", _hoisted_59, _toDisplayString(_unref(langObj)['可用时间']) + ":", 1),
                        _createElementVNode("div", _hoisted_60, _toDisplayString(_unref(conditionTotal).condition2Date), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _createElementVNode("div", _hoisted_62, [
                        _cache[17] || (_cache[17] = _createElementVNode("div", {
                          class: "box4",
                          style: {"background-color":"#cb6a34"}
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_63, _toDisplayString(_unref(langObj)['警戒占比']) + ":", 1),
                        _createElementVNode("div", _hoisted_64, _toDisplayString(_unref(conditionTotal).condition3Rate) + "%", 1)
                      ]),
                      _createElementVNode("div", _hoisted_65, [
                        _cache[18] || (_cache[18] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                        _createElementVNode("div", _hoisted_66, _toDisplayString(_unref(langObj)['警戒时间']) + ":", 1),
                        _createElementVNode("div", _hoisted_67, _toDisplayString(_unref(conditionTotal).condition3Date), 1)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_68, [
                      _createElementVNode("div", _hoisted_69, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", {
                          class: "box4",
                          style: {"background-color":"#b81212"}
                        }, null, -1)),
                        _createElementVNode("div", _hoisted_70, _toDisplayString(_unref(langObj)['故障占比']) + ":", 1),
                        _createElementVNode("div", _hoisted_71, _toDisplayString(_unref(conditionTotal).condition4Rate) + "%", 1)
                      ]),
                      _createElementVNode("div", _hoisted_72, [
                        _cache[20] || (_cache[20] = _createElementVNode("div", { style: {"width":"12px"} }, null, -1)),
                        _createElementVNode("div", _hoisted_73, _toDisplayString(_unref(langObj)['故障时间']) + ":", 1),
                        _createElementVNode("div", _hoisted_74, _toDisplayString(_unref(conditionTotal).condition4Date), 1)
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_75, [
              _createElementVNode("div", _hoisted_76, [
                _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                _createElementVNode("div", _hoisted_77, [
                  _cache[21] || (_cache[21] = _createElementVNode("div", {
                    class: "circle",
                    style: {"background":"#1cc48b"}
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_78, _toDisplayString(_unref(langObj)['健康']), 1),
                  _cache[22] || (_cache[22] = _createElementVNode("div", {
                    class: "circle ml-8",
                    style: {"background":"#ddd246"}
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_79, _toDisplayString(_unref(langObj)['可用']), 1),
                  _cache[23] || (_cache[23] = _createElementVNode("div", {
                    class: "circle ml-8",
                    style: {"background":"#cb6a34"}
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_80, _toDisplayString(_unref(langObj)['警戒']), 1),
                  _cache[24] || (_cache[24] = _createElementVNode("div", {
                    class: "circle ml-8",
                    style: {"background":"#b81212"}
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_81, _toDisplayString(_unref(langObj)['故障']), 1)
                ])
              ]),
              (_unref(config_3d) && _unref(config_3d).img)
                ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                    _createElementVNode("img", {
                      src: _unref(config_3d).picture,
                      style: _normalizeStyle([{"position":"absolute"}, {
              width: _unref(config_3d).img.width + 'px',
              height: _unref(config_3d).img.height + 'px',
              top: _unref(config_3d).img.y + 'px',
              left: _unref(config_3d).img.x + 'px',
              transform: 'rotate(' + _unref(config_3d).img.rotate + 'deg)',
            }])
                    }, null, 12, _hoisted_83),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d).marks, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        (item.bind.indexOf('a-') > -1)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "d3_point",
                              style: _normalizeStyle({ top: item.y + 'px', left: item.x + 'px' })
                            }, null, 4))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d).marks, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        (item.bind.indexOf('b-') > -1)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "d3_bq",
                              style: _normalizeStyle([{
                top: item.y - 50 + 'px',
                left: item.x - 100 + 'px',
                'border-color': getColor(item.condition),
                'box-shadow': getShadow(item.condition),
              }, {"width":"225px","height":"100px"}])
                            }, [
                              _createElementVNode("div", _hoisted_84, [
                                _createElementVNode("div", _hoisted_85, [
                                  _createElementVNode("div", null, _toDisplayString(item.sensorPlace), 1),
                                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                  _createElementVNode("div", {
                                    style: _normalizeStyle([{ 'background-color': getColor2(item.condition) }, {"width":"15px","height":"15px","border-radius":"50%"}])
                                  }, null, 4)
                                ]),
                                _createElementVNode("div", _hoisted_86, [
                                  _createElementVNode("div", _hoisted_87, [
                                    _createElementVNode("div", null, _toDisplayString(item.hz) + "m/s²", 1),
                                    _createElementVNode("div", _hoisted_88, _toDisplayString(_unref(langObj)['振动加速度']), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_89, [
                                    _createElementVNode("div", null, _toDisplayString(item.sz) + "mm/s", 1),
                                    _createElementVNode("div", _hoisted_90, _toDisplayString(_unref(langObj)['振动速度']), 1)
                                  ]),
                                  _createElementVNode("div", _hoisted_91, [
                                    _createElementVNode("div", null, _toDisplayString(item.temp) + "℃", 1),
                                    _createElementVNode("div", _hoisted_92, _toDisplayString(_unref(langObj)['温度']), 1)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_93, [
                                  _cache[27] || (_cache[27] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                  _createElementVNode("div", _hoisted_94, _toDisplayString(item.date), 1)
                                ])
                              ])
                            ], 4))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(line), (item, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createElementVNode("div", {
                          class: "d3_line",
                          style: _normalizeStyle({
                top: item.y + 5 + 'px',
                left: item.x + 5 + 'px',
                width: item.distance + 'px',
                transform: 'rotate(' + item.angle + 'deg)',
              })
                        }, null, 4)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_95, [
                _createElementVNode("div", _hoisted_96, [
                  _cache[28] || (_cache[28] = _createElementVNode("img", {
                    style: {"width":"18px","height":"18px"},
                    src: _imports_2,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_97, _toDisplayString(_unref(langObj)['设备诊断']), 1)
                ]),
                _createElementVNode("div", _hoisted_98, [
                  _cache[29] || (_cache[29] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _createElementVNode("div", {
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (selectTag(1))),
                    class: _normalizeClass(["btn1 cursor-pointer", _unref(selecrRandar) === 1 ? 'btn1BgSelected' : '']),
                    style: _normalizeStyle({ color: _unref(selecrRandar) === 1 ? '#fff' : '' })
                  }, _toDisplayString(_unref(langObj)['振动指标']), 7),
                  _createElementVNode("div", {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (selectTag(2))),
                    class: _normalizeClass(["btn1 cursor-pointer", _unref(selecrRandar) === 2 ? 'btn1BgSelected' : '']),
                    style: _normalizeStyle([{ color: _unref(selecrRandar) === 2 ? '#fff' : '' }, {"margin-left":"10px"}])
                  }, _toDisplayString(_unref(langObj)['健康指标']), 7),
                  _createElementVNode("div", {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (selectTag(3))),
                    class: _normalizeClass(["btn1 cursor-pointer", _unref(selecrRandar) === 3 ? 'btn1BgSelected' : '']),
                    style: _normalizeStyle([{"margin-left":"10px"}, { color: _unref(selecrRandar) === 3 ? '#fff' : '' }])
                  }, _toDisplayString(_unref(langObj)['分数趋势']), 7),
                  _cache[30] || (_cache[30] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_99, [
                  _cache[31] || (_cache[31] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  (_unref(visibleChart2))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_100))
                    : _createCommentVNode("", true),
                  _cache[32] || (_cache[32] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_101, [
                _createElementVNode("div", _hoisted_102, [
                  _cache[33] || (_cache[33] = _createElementVNode("img", {
                    style: {"width":"18px","height":"18px"},
                    src: _imports_3,
                    alt: ""
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_103, _toDisplayString(_unref(langObj)['设备事件']) + "（" + _toDisplayString(_unref(warningTotal)) + "条）", 1),
                  _cache[34] || (_cache[34] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _createElementVNode("div", {
                    onClick: viewMore,
                    class: "opt8",
                    style: {"font-size":"12px","cursor":"pointer"}
                  }, _toDisplayString(_unref(langObj)['查看更多']) + " > ", 1)
                ]),
                _createElementVNode("div", _hoisted_104, [
                  _cache[35] || (_cache[35] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                  _createElementVNode("div", {
                    onClick: _cache[4] || (_cache[4] = ($event: any) => ((_isRef(warningType) //@ts-ignore
 ? warningType.value = 1 : warningType = 1), getMachineWarning())),
                    class: _normalizeClass(["btn1 cursor-pointer", _unref(warningType) === 1 ? 'btn1BgSelected' : '']),
                    style: _normalizeStyle({ color: _unref(warningType) === 1 ? '#fff' : '' })
                  }, _toDisplayString(_unref(langObj)['报警事件']), 7),
                  _createElementVNode("div", {
                    onClick: _cache[5] || (_cache[5] = ($event: any) => ((_isRef(warningType) //@ts-ignore
 ? warningType.value = 2 : warningType = 2), getMachineWarning())),
                    class: _normalizeClass(["btn99 cursor-pointer", _unref(warningType) === 2 ? 'btn1BgSelected' : '']),
                    style: _normalizeStyle([{"margin-left":"10px"}, { color: _unref(warningType) === 2 ? '#fff' : '' }])
                  }, _toDisplayString(_unref(langObj)['检修记录']), 7),
                  _cache[36] || (_cache[36] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_105, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineWarningData), (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "box5 mt-2"
                    }, [
                      _createElementVNode("div", _hoisted_106, [
                        (item.type === 1)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_107))
                          : _createCommentVNode("", true),
                        (item.type === 2)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_108))
                          : _createCommentVNode("", true),
                        (item.type === 4)
                          ? (_openBlock(), _createElementBlock("img", _hoisted_109))
                          : _createCommentVNode("", true),
                        (item.type === 1)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 3,
                              style: _normalizeStyle([{
                    color: _unref(conditionList).find((p) =>
                      p.value.includes(item.condition)
                    )?.color,
                  }, {"font-size":"16px"}]),
                              class: "ml-2"
                            }, _toDisplayString(_unref(langObj)['诊断报醒']) + "：" + _toDisplayString(_unref(langObj)[_unref(conditionList).find((p) =>
                    p.value.includes(item.condition)
                    )?.label]), 5))
                          : _createCommentVNode("", true),
                        (item.type === 2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_110, _toDisplayString(_unref(langObj)['现场维修']), 1))
                          : _createCommentVNode("", true),
                        (item.type === 4)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_111, _toDisplayString(_unref(langObj)['门限报警']), 1))
                          : _createCommentVNode("", true),
                        _cache[37] || (_cache[37] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                        (
                    item.reportIds && item.reportIds.length && item.type === 1
                  )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_112, _toDisplayString(_unref(langObj)['诊断报告']), 1))
                          : _createCommentVNode("", true),
                        (
                    item.reportIds && item.reportIds.length && item.type === 2
                  )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_113, _toDisplayString(_unref(langObj)['检修报告']), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_114, _toDisplayString(item.diagnosticNotes), 1),
                      _createElementVNode("div", _hoisted_115, _toDisplayString(item.date), 1)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(selectType) == 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_116, [
          _createElementVNode("div", _hoisted_117, [
            _createElementVNode("span", _hoisted_118, _toDisplayString(_unref(group_info).name), 1)
          ]),
          _createElementVNode("div", _hoisted_119, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(group_machineList), (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "group-box mt-3 p-2",
                  key: index
                }, [
                  _createElementVNode("div", _hoisted_120, [
                    _cache[38] || (_cache[38] = _createElementVNode("div", { class: "shu" }, null, -1)),
                    _createElementVNode("div", _hoisted_121, _toDisplayString(item.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_122, [
                    _createElementVNode("div", _hoisted_123, [
                      _createElementVNode("img", {
                        style: {"width":"100%","max-height":"100%"},
                        src: item.picture,
                        alt: ""
                      }, null, 8, _hoisted_124)
                    ]),
                    _createElementVNode("div", _hoisted_125, [
                      (item.scoreWkStatus)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_126, [
                            _cache[39] || (_cache[39] = _createElementVNode("div", { class: "line" }, [
                              _createElementVNode("div", { class: "line animation" })
                            ], -1)),
                            _createElementVNode("span", _hoisted_127, _toDisplayString(_unref(langObj)['运行']), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_128, [
                            _cache[40] || (_cache[40] = _createElementVNode("div", { class: "down" }, [
                              _createElementVNode("div", { class: "down animation" })
                            ], -1)),
                            _createElementVNode("span", _hoisted_129, _toDisplayString(_unref(langObj)['停机']), 1)
                          ])),
                      _createElementVNode("div", _hoisted_130, [
                        _createElementVNode("div", {
                          class: "line",
                          style: _normalizeStyle({ 'background-color': item?.condition?.color })
                        }, [
                          _createElementVNode("div", {
                            class: "line animation",
                            style: _normalizeStyle({ 'background-color': item?.condition?.color })
                          }, null, 4)
                        ], 4),
                        _createElementVNode("span", _hoisted_131, _toDisplayString(item?.condition?.label), 1)
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_132, [
              _cache[42] || (_cache[42] = _createElementVNode("div", { style: {"width":"10%"} }, null, -1)),
              _createElementVNode("div", _hoisted_133, [
                (_unref(config_3d_group) && _unref(config_3d_group).img)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_134, [
                      _createElementVNode("img", {
                        src: _unref(config_3d_group).picture,
                        style: _normalizeStyle([{"position":"absolute"}, {
                width: _unref(config_3d_group).img.width + 'px',
                height: _unref(config_3d_group).img.height + 'px',
                top: _unref(config_3d_group).img.y + 'px',
                left: _unref(config_3d_group).img.x + 'px',
                transform: 'rotate(' + _unref(config_3d_group).img.rotate + 'deg)',
              }])
                      }, null, 12, _hoisted_135),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d_group).marks, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (item.bind.indexOf('a-') > -1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "d3_point",
                                style: _normalizeStyle({ top: item.y + 'px', left: item.x + 'px' })
                              }, null, 4))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d_group).marks, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          (item.bind.indexOf('b-') > -1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "d3_bq",
                                style: _normalizeStyle([{
                  top: item.y - 50 + 'px',
                  left: item.x - 100 + 'px',
                  'border-color': getColor(0),
                }, {"width":"220px","height":"auto"}])
                              }, [
                                _createElementVNode("div", _hoisted_136, [
                                  _createElementVNode("div", _hoisted_137, [
                                    _createElementVNode("div", _hoisted_138, _toDisplayString(_unref(group_info)?.name), 1)
                                  ]),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(group_machineList), (item, index) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: index,
                                      class: "w-full flex items-center"
                                    }, [
                                      _createElementVNode("div", null, _toDisplayString(item.name), 1),
                                      _cache[41] || (_cache[41] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                      _createElementVNode("div", {
                                        style: _normalizeStyle([{"width":"16px","height":"16px","border-radius":"50%","min-width":"16px"}, { 'background': item.condition.color }])
                                      }, null, 4)
                                    ]))
                                  }), 128))
                                ])
                              ], 4))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lineGroup), (item, index) => {
                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                          _createElementVNode("div", {
                            class: "d3_line",
                            style: _normalizeStyle({
                  top: item.y + 5 + 'px',
                  left: item.x + 5 + 'px',
                  width: item.distance + 'px',
                  transform: 'rotate(' + item.angle + 'deg)',
                })
                          }, null, 4)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})