import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, isRef as _isRef, createBlock as _createBlock, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "w-full flex px-4 theme-common" }
const _hoisted_2 = { style: {"width":"33%"} }
const _hoisted_3 = { class: "w-full flex items-center mb-4" }
const _hoisted_4 = { class: "ml-4" }
const _hoisted_5 = { class: "ml-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "flex-1 ml-4" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex items-center"
}
const _hoisted_10 = {
  key: 1,
  class: "w-full flex items-center"
}
const _hoisted_11 = { class: "box mt-4 p-4" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  class: "flex items-center",
  style: {"color":"#000"}
}
const _hoisted_18 = {
  class: "flex items-center",
  style: {"color":"#000"}
}
const _hoisted_19 = {
  class: "flex items-center",
  style: {"color":"#000"}
}

import {
    computed,
    defineComponent,
    onMounted,
    onUnmounted,
    reactive,
    ref,
    watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import {
    getFactoryName,
    getGroupName,
    transformDate2,
    getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import SelectMachineComponent from "./select-machine.vue";
import SelectSensorComponent from "./select-sensor.vue";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";
import { Dayjs } from "dayjs";
import ModelItem from './inspectionThresholdItem/ModelItem.vue';

import { getLanguage } from "../../common/translate";

export default /*@__PURE__*/_defineComponent({
  __name: 'inspectionThreshold',
  setup(__props) {

let language = localStorage.getItem("language") || "Chinese";
const VNodes = defineComponent({
  props: {
    vnodes: {
      type: Object,
      required: true,
    },
  },
  render() {
    return this.vnodes;
  },
});


let tableData:any = ref([])
const columns = [
    {
        title: getLanguage('一级分类', language),
        dataIndex: "firstCategory",
        align: "center",
    },
    {
        title: getLanguage('二级分类', language),
        dataIndex: "secondCategory",
        align: "center",
    },
    {
        title: getLanguage('三级分类', language),
        dataIndex: "thirdCategory",
        align: "center",
    },
    {
        title: getLanguage('转速范围', language),
        dataIndex: "speedRange",
        align: "center",
    },
    {
        title: getLanguage('操作', language),
        dataIndex: "act",
        align: "center",
    },
]

let visible:any = ref(false)
let form:any = ref({
    firstCategory: null,
    secondCategory: null,
    thirdCategory: null,
    speedRange: null,
})
let firstCategoryList:any = ref([])
let secondCategoryList:any = ref([])
let thirdCategoryList:any = ref([])

let visibleAddValue = ref(false)
let addValue:any = ref('')
let addValueType:any = ref(1)   //1,2,3

let searchModel:any = ref({
    thirdCategory: null
})
let options:any = ref([])
let tableDataAll:any = ref([])

onMounted(() => {
    init();
});

const init = () => {
    search();
};

// 搜索
const search = async () => {
    console.log('sss', searchModel.value.thirdCategory)
    select_activeKey.value = ''
    let config:any = {
        params: { 
            
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    // if(searchModel.value.thirdCategory?.length === 3) {
    //     config.params.firstCategory = searchModel.value.thirdCategory[0]
    //     config.params.secondCategory = searchModel.value.thirdCategory[1]
    //     config.params.thirdCategory = searchModel.value.thirdCategory[2]
    // }
    
    let result = await request.get('/api/external/inspection/threshold', config)
    if(result?.data) {
        tableDataAll.value = JSON.parse(JSON.stringify( result.data))
        if(searchModel.value.thirdCategory?.length === 3) {
            tableData.value = result.data.filter((p:any) => p.firstCategory===searchModel.value.thirdCategory[0] && p.secondCategory===searchModel.value.thirdCategory[1] && p.thirdCategory===searchModel.value.thirdCategory[2])
        } else {
            tableData.value = result.data
        }
         
        getCategoryList()
        getSearch3()
    }
};

// 获取查询三级结构
const getSearch3 = () => {
    options.value = []
    let first = tableDataAll.value.map((p:any) => p.firstCategory)
    first = [... new Set(first)]
    let list:any = []
    first.map((f:any) => {
        let firstList:any = {
            label: f,
            value: f,
            children: []
        }
        let second = tableDataAll.value.filter((p:any) => p.firstCategory === f).map((p:any) => p.secondCategory)
        second = [... new Set(second)]
        second.map((s:any) => {
            let secondList:any = {
                label: s,
                value: s,
                children: []
            }
            let third = tableDataAll.value.filter((p:any) => p.firstCategory === f && p.secondCategory === s).map((p:any) => p.thirdCategory)
            third = [... new Set(third)]
            third.map((t:any) => {
                let thirdList:any = {
                    label: t,
                    value: t,
                }
                secondList.children.push(thirdList)
            })
            firstList.children.push(secondList)
        })

        list.push(firstList)
    })

    options.value = list
}

// 获取三个分类数组
const getCategoryList = () => {
    let first = tableData.value.map((p:any) => p.firstCategory)
    firstCategoryList.value = [... new Set(first)]
}
// 获取二级分类数组
const getSecondList = () => {
    let list = tableData.value.filter((p:any) => p.firstCategory === form.value.firstCategory).map((p:any) => p.secondCategory)
    secondCategoryList.value = [... new Set(list)]
}
// 获取三级分类数组
const getThirdList = () => {
    let list = tableData.value.filter((p:any) => p.firstCategory === form.value.firstCategory && p.secondCategory === form.value.secondCategory).map((p:any) => p.thirdCategory)
    thirdCategoryList.value = [... new Set(list)]
}

// 新增
const add = async () => {
    form.value = {
        firstCategory: null,
        secondCategory: null,
        thirdCategory: null,
        speedRange: null,
    }
    visible.value = true;
};

// 编辑
const editThis = async (ev:any) => {
    form.value = JSON.parse(JSON.stringify(ev))
    getSecondList()
    getThirdList()
    visible.value = true;
};

// 新增分类选择项
const addItemCategory = (ev:any) => {
    addValue.value = ''
    visibleAddValue.value = true
    addValueType.value = ev
}

// 提交分类名称
const submitAddValue = () => {
    if(addValueType.value === 1) {
        firstCategoryList.value.push(addValue.value)
        form.value.firstCategory = addValue.value
    }
    if(addValueType.value === 2) {
        secondCategoryList.value.push(addValue.value)
        form.value.secondCategory = addValue.value
    }
    if(addValueType.value === 3) {
        thirdCategoryList.value.push(addValue.value)
        form.value.thirdCategory = addValue.value
    }
    visibleAddValue.value = false
}

// 提交主表数据
const submitMain = async() => {
    let result = form.value._id ? await request.put('/api/external/inspection/threshold', form.value)
                : await request.post('/api/external/inspection/threshold', form.value)
    if(result?.data) {
        message.success('操作成功')
        visible.value = false
        search()
    } else {
        message.warning('操作失败请重试或联系管理员！')
    }
}

// 删除
const deleteThis = async (ev:any) => {
    const config = {
        params: { 
            id: ev._id
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    
    let result = await request.delete('/api/external/inspection/threshold', config)
    if(result?.data) {
        message.success('操作成功')
        search()
    } else {
        message.warning('操作失败请重试或联系管理员！')
    }
};

// 阈值配置
const columnsExpert = [
    {
        title: getLanguage('细分故障', language) ,
        dataIndex: "segmentFault",
    },
    {
        title: getLanguage('计算方法', language) ,
        dataIndex: "faultFunction",
    },
    {
        title: getLanguage('指标', language) ,
        dataIndex: "scores",
    },
];
const columnsZB = [
    {
        title: getLanguage('细分故障', language),
        dataIndex: "segmentFault",
    },
    {
        title: getLanguage('计算方法', language),
        dataIndex: "faultFunction",
    },
];
const columnsDI = [
    {
        title: getLanguage('部件类型', language),
        dataIndex: "typeName",
    },
    {
        title: getLanguage('参数释义', language),
        dataIndex: "paramExplan",
    },
    {
        title: getLanguage('配置参数', language),
        dataIndex: "code",
    },
];


let select_id = ref('')
let select_title = ref('')
let select_activeKey = ref('')  //1: 健康指标 2: 转频参数
let isEdit = ref(false)
let select_dataList:any = ref([])

let select_threshold:any = ref(null)
const baseModelSource = ref([]);

let mainSelectedRowKeys:any = ref([]);
let mainSelectedRows:any = ref([]);
let visible2 = ref(false)
const selectedRowKeys = ref<any>();
const selectedRows = ref<any>();


// 选中
const selectThis = async (ev:any) => {
    select_activeKey.value = '1'
    isEdit.value = false
    select_id.value = ev._id
    select_title.value = `${ev.firstCategory}/${ev.secondCategory}/${ev.thirdCategory}/${ev.speedRange}`
    loadBaseModelSource()
    getParmarExpert()
};

const tabsChange = () => {
    isEdit.value = false
    if(select_activeKey.value === '1') {
        loadBaseModelSource()
        getParmarExpert()
    }
    if(select_activeKey.value === '2') {
        getDeviceInfo()
    }
    
}

// 获取所有指标
const loadBaseModelSource = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request
    .post("/api/faultinfos", { FeatureType: "" }, config)
    .then((res: any) => {
      if (res) {
        for (var i = 0; i < res.data.length; i++) {
          if(res.data[i]?.scores?.length) {
            res.data[i].scores = res.data[i].scores.map((t: any) => ({
              ...t,
              scoreValue: t.default,
            }));
          } else {
            res.data[i].scores = []
          }
          
        }

        res.data = res.data.map((t: any) => ({ ...t, key: t.id }));
        baseModelSource.value = res.data.filter((p:any) => p.featureType === "Expect");
      }
    });
};

// 获取专业阈值
const getParmarExpert = async() => {
    const config = {
        params: { 
            id: select_id.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    
    let result = await request.get('/api/external/inspection/threshold/expert', config)
    if(result?.data) {
        select_dataList.value = result.data?.thresholds || []
    }
}

const onMainSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
    mainSelectedRowKeys.value = selectedRowKeys2;
    mainSelectedRows.value = selectedRows2;
};

const selectAdd = () => {
    selectedRowKeys.value = select_dataList.value.map((t: any) => t.faultId);
    visible2.value = true;
}

const onSelectChange = (selectedRowKeys2: any, selectedRows2: any) => {
    selectedRowKeys.value = selectedRowKeys2;
    selectedRows.value = selectedRows2;
};

// 添加确定
const handleOk = () => {
    var selected = JSON.parse(JSON.stringify(selectedRows.value))
    select_dataList.value = selected.concat(select_dataList.value);
    select_dataList.value = Array.from(
        select_dataList.value
        .reduce((m: any, t: any) => m.set(t.faultFunction, t), new Map())
        .values()
    );
    
    visible2.value = false;
    isEdit.value = true;
};

// 保存专业阈值
const saveConfig1 = async () => {
    var param:any = {
        inspectionSubId: select_id.value,
        faultFunctions: [],
        scoreNames: [],
        featureType: "Expect"
    };
    var flag = true;
    var msg = "";
    for (var i = 0; i < select_dataList.value.length; i++) {
    var item: any = select_dataList.value[i];
    param.scoreNames.push(item.scoreName);
    param.faultFunctions.push(item.faultFunction);
    param[item.scoreName + "_name"] = item.faultName||item.segmentFault;
    for (var j = 0; j < item.scores.length; j++) {
        var t = item.scores[j];
        if((t.name && t.name.includes('Vexp')) || (t.scoreName && t.scoreName.includes('Vexp'))) {
        t.scoreValue = t.default
        }
        param[t.name || t.scoreName] = t.scoreValue || t.default||t.scoreValue ;
        if (!t.scoreValue && t.scoreValue != 0) {
        msg = "参数：" + (item.featureName || item.mainFault) + '的' + t.name + "未输入";
        flag = false;
        break;
        }
    }
    }
    if (!flag) {
        message.warning(msg);
        return;
    }
    request.put("/api/external/inspection/threshold/expert", param).then((res) => {
        if (res) {
            message.success("操作成功");
            isEdit.value = false;
            getParmarExpert()
        }
    });
};

// 取消
const qX1 = () => {
    isEdit.value = false;
    getParmarExpert()
}

// 删除
const deleteThis1 = () => {
    if (!mainSelectedRows.value||!mainSelectedRows.value.length) {
        message.warning(getLanguage('请选择内容', language));
        return;
    }

    var param: any = {
        inspectionSubId: select_id.value,
        featureType: "Expect",
        faultFunctions: [],
        scoreNames: [],
        scores: [],
    };
    for (var i = 0; i < mainSelectedRows.value.length; i++) {
        var item: any = mainSelectedRows.value[i];
        param.scoreNames.push(item.scoreName);
        param.faultFunctions.push(item.faultFunction);
        for (var j = 0; j < item.scores.length; j++) {
            var t = item.scores[j];
            param.scores.push(t.name || t.scoreName);
        }
        }
        const config = {
            params: param,
            data: param,
        headers: {
            requestId: uuidv4(),
        },
    };

    request.delete("/api/external/inspection/threshold/expert", config).then((res) => {
        if (res) {
            message.success('操作成功');
            isEdit.value = false;
            getParmarExpert()
        }
    });
};


// 复制expect
const copy1 = () => {
    if(! mainSelectedRows.value.length) {
        message.warning('请选择后复制')
        return
    }

    localStorage.setItem('threshold-expect', JSON.stringify(mainSelectedRows.value))
    message.success('复制成功')
}

// 粘贴
const paste1 = () => {
    let list = JSON.parse(localStorage.getItem('threshold-expect') || '[]')
    if(!list?.length) {
        message.warning('请复制后粘贴')
        return
    }
    select_dataList.value = list
    mainSelectedRows.value = []
    mainSelectedRowKeys.value = []
    isEdit.value = true
}


// 获取设备参数列表
const getDeviceInfo = async() => {
    const config = {
        params: { 
            id: select_id.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    
    let result = await request.get('/api/external/inspection/threshold/deviceinfo', config)
    if(result?.data) {
        select_dataList.value = result.data?.deviceParams?.Common || []
    }
}

const saveConfig2 = async() => {
    var param: any = {
        inspectionSubId: select_id.value,
        codes: [],
        type: "Common",
    };
    var flag = true;
    var msg = "";
    for (var i = 0; i < select_dataList.value.length; i++) {
        var item: any = select_dataList.value[i];
        param.codes.push(item.code);
        if (item.required && !item.paramValue) {
          msg = "参数：" + (item.paramName || item.parentCode) + "未输入";
          flag = false;
          break;
        }
        param[item.code ||item.paramName || item.parentCode] = item.paramValue;
    }
    if (!flag) {
        message.warning(msg);
        return;
    }
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };
    request.put("/api/external/inspection/threshold/deviceinfo", param, config).then((res) => {
        if (res) {
          message.success('操作成功');
          isEdit.value = false;
          getDeviceInfo
        }
    });
}

const qX2 = () => {
    isEdit.value = false;
    getDeviceInfo()
}

// 复制expect
const copy2 = () => {
    localStorage.setItem('threshold-deviceinfo', JSON.stringify(select_dataList.value))
    message.success('复制成功')
}

// 粘贴
const paste2 = () => {
    let list = JSON.parse(localStorage.getItem('threshold-deviceinfo') || '[]')
    if(!list?.length) {
        message.warning('请复制后粘贴')
        return
    }
    select_dataList.value = list
    isEdit.value = true
}



return (_ctx: any,_cache: any) => {
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, _toDisplayString(_unref(getLanguage)('智能诊断巡检配置', _unref(language))) + ":", 1),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_a_cascader, {
            value: _unref(searchModel).thirdCategory,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).thirdCategory) = $event)),
            options: _unref(options),
            placeholder: ""
          }, null, 8, ["value", "options"])
        ]),
        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
        _createElementVNode("div", null, [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: search
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(getLanguage)("搜索", _unref(language))), 1)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_button, {
            type: "primary",
            onClick: add
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_plus_outlined)
            ]),
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(getLanguage)("新增", _unref(language))), 1)
            ]),
            _: 1
          })
        ])
      ]),
      _createVNode(_component_a_table, {
        scroll: { y: 820 },
        bordered: "",
        columns: columns,
        "data-source": _unref(tableData),
        pagination: false,
        size: "small"
      }, {
        bodyCell: _withCtx(({ column, record }) => [
          (column.dataIndex === 'act')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("a", {
                  style: _normalizeStyle({color: record._id===_unref(select_id) ? 'gray !important' : '' }),
                  onClick: ($event: any) => (selectThis(record))
                }, _toDisplayString(_unref(getLanguage)('选中', _unref(language))), 13, _hoisted_6),
                _createVNode(_component_a_divider, { type: "vertical" }),
                _createVNode(_component_a_popconfirm, {
                  title: _unref(getLanguage)('确定删除此条数据么', _unref(language)),
                  onConfirm: ($event: any) => (deleteThis(record))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", null, _toDisplayString(_unref(getLanguage)('删除', _unref(language))), 1)
                  ]),
                  _: 2
                }, 1032, ["title", "onConfirm"]),
                _createVNode(_component_a_divider, { type: "vertical" }),
                _createElementVNode("a", {
                  onClick: ($event: any) => (editThis(record))
                }, _toDisplayString(_unref(getLanguage)('编辑', _unref(language))), 9, _hoisted_7)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["data-source"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_unref(select_activeKey) === '1')
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", null, _toDisplayString(_unref(select_title)), 1),
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: selectAdd
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(getLanguage)("新增", _unref(language))), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              class: "ml-4",
              type: "primary",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_isRef(isEdit) //@ts-ignore
 ? isEdit.value = true : isEdit=true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(getLanguage)("编辑", _unref(language))), 1)
              ]),
              _: 1
            }),
            (_unref(isEdit))
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  class: "ml-4",
                  type: "primary",
                  onClick: saveConfig1
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)("保存", _unref(language))), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isEdit))
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  class: "ml-4",
                  type: "primary",
                  onClick: qX1
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)("取消", _unref(language))), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              class: "ml-4",
              type: "primary",
              onClick: copy1
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(getLanguage)("复制", _unref(language))), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              class: "ml-4",
              type: "primary",
              onClick: paste1
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(getLanguage)("粘贴覆盖", _unref(language))), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_popconfirm, {
              title: _unref(getLanguage)('确定删除所选数据么', _unref(language)) +'?',
              "ok-text": _unref(getLanguage)('确定', _unref(language)),
              "cancel-text": _unref(getLanguage)('取消', _unref(language)),
              onConfirm: _cache[2] || (_cache[2] = ($event: any) => (deleteThis1()))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  class: "ml-4",
                  type: "primary"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)("删除", _unref(language))), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title", "ok-text", "cancel-text"])
          ]))
        : _createCommentVNode("", true),
      (_unref(select_activeKey) === '2')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("div", null, _toDisplayString(_unref(select_title)), 1),
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createVNode(_component_a_button, {
              class: "ml-4",
              type: "primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_isRef(isEdit) //@ts-ignore
 ? isEdit.value = true : isEdit=true))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(getLanguage)("编辑", _unref(language))), 1)
              ]),
              _: 1
            }),
            (_unref(isEdit))
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 0,
                  class: "ml-4",
                  type: "primary",
                  onClick: saveConfig2
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)("保存", _unref(language))), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isEdit))
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  class: "ml-4",
                  type: "primary",
                  onClick: qX2
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(getLanguage)("取消", _unref(language))), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_a_button, {
              class: "ml-4",
              type: "primary",
              onClick: copy2
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(getLanguage)("复制", _unref(language))), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_a_button, {
              class: "ml-4",
              type: "primary",
              onClick: paste2
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(getLanguage)("粘贴", _unref(language))), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_a_tabs, {
          activeKey: _unref(select_activeKey),
          "onUpdate:activeKey": _cache[4] || (_cache[4] = ($event: any) => (_isRef(select_activeKey) ? (select_activeKey).value = $event : select_activeKey = $event)),
          onChange: tabsChange
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tab_pane, {
              key: "1",
              tab: _unref(getLanguage)('健康指标', _unref(language)),
              disabled: _unref(isEdit)
            }, null, 8, ["tab", "disabled"]),
            _createVNode(_component_a_tab_pane, {
              key: "2",
              tab: _unref(getLanguage)('转频参数', _unref(language)),
              disabled: _unref(isEdit)
            }, null, 8, ["tab", "disabled"])
          ]),
          _: 1
        }, 8, ["activeKey"]),
        (_unref(select_activeKey) === '1')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_a_table, {
                bordered: "",
                emptyText: '暂无数据',
                "row-selection": {
                            selectedRowKeys: _unref(mainSelectedRowKeys),
                            onChange: onMainSelectChange,
                        },
                rowKey: "faultId",
                columns: columnsExpert,
                "data-source": _unref(select_dataList),
                pagination: false,
                onChange: onMainSelectChange
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'scores')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (!_unref(isEdit))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.scores, (item) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  key: item.faultId+(item.name || item.scoreName)
                                }, [
                                  (!((item.name || item.scoreName).indexOf('Vexp')>-1))
                                    ? (_openBlock(), _createBlock(_component_a_tag, {
                                        color: "green",
                                        key: item.faultId+(item.name || item.scoreName)
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.name || item.scoreName) + ":" + _toDisplayString(item.scoreValue), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              _createVNode(_component_a_form, { layout: "inline" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.scores, (item) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      key: item.faultId+(item.name || item.scoreName)
                                    }, [
                                      (!((item.name || item.scoreName).indexOf('Vexp')>-1))
                                        ? (_openBlock(), _createBlock(_component_a_form_item, {
                                            key: item.faultId+(item.name || item.scoreName),
                                            label: item.name || item.scoreName,
                                            name: item.name || item.scoreName,
                                            required: item.required
                                          }, {
                                            default: _withCtx(() => [
                                              (_openBlock(), _createBlock(_component_a_input_number, {
                                                key: item.faultId+(item.name || item.scoreName),
                                                step: 0.01,
                                                value: item.scoreValue,
                                                "onUpdate:value": ($event: any) => ((item.scoreValue) = $event),
                                                placeholder: _unref(getLanguage)('请输入', _unref(language))
                                              }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                            ]),
                                            _: 2
                                          }, 1032, ["label", "name", "required"]))
                                        : _createCommentVNode("", true)
                                    ]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ]))
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["row-selection", "data-source"])
            ]))
          : _createCommentVNode("", true),
        (_unref(select_activeKey) === '2')
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_a_table, {
                bordered: "",
                emptyText: '暂无数据',
                columns: columnsDI,
                "data-source": _unref(select_dataList),
                pagination: false
              }, {
                bodyCell: _withCtx(({ column, text, record }) => [
                  (column.dataIndex === 'code')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        (!_unref(isEdit))
                          ? (_openBlock(), _createBlock(_component_a_tag, {
                              key: 0,
                              color: "green"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(record.code) + ":" + _toDisplayString(record.paramValue), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                              _createVNode(_component_a_form_item, {
                                label: record.code,
                                name: record.code,
                                required: record.required
                              }, {
                                default: _withCtx(() => [
                                  (record.dataType == 'radio')
                                    ? (_openBlock(), _createBlock(_component_a_radio_group, {
                                        key: 0,
                                        value: record.paramValue,
                                        "onUpdate:value": ($event: any) => ((record.paramValue) = $event)
                                      }, {
                                        default: _withCtx(() => [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(record.examples, (item) => {
                                            return (_openBlock(), _createBlock(_component_a_radio, {
                                              value: item,
                                              key: item
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["value"]))
                                          }), 128))
                                        ]),
                                        _: 2
                                      }, 1032, ["value", "onUpdate:value"]))
                                    : (record.dataType == 'int')
                                      ? (_openBlock(), _createBlock(_component_a_input_number, {
                                          key: 1,
                                          value: record.paramValue,
                                          "onUpdate:value": ($event: any) => ((record.paramValue) = $event),
                                          placeholder: _unref(getLanguage)('请输入', _unref(language)),
                                          step: 1
                                        }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                      : (record.dataType == 'float')
                                        ? (_openBlock(), _createBlock(_component_a_input_number, {
                                            key: 2,
                                            value: record.paramValue,
                                            "onUpdate:value": ($event: any) => ((record.paramValue) = $event),
                                            placeholder: _unref(getLanguage)('请输入', _unref(language)),
                                            step: 0.01
                                          }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                        : (_openBlock(), _createBlock(_component_a_input, {
                                            key: 3,
                                            type: "text",
                                            value: record.paramValue,
                                            "onUpdate:value": ($event: any) => ((record.paramValue) = $event),
                                            placeholder: _unref(getLanguage)('请输入', _unref(language))
                                          }, null, 8, ["value", "onUpdate:value", "placeholder"]))
                                ]),
                                _: 2
                              }, 1032, ["label", "name", "required"])
                            ]))
                      ], 64))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["data-source"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_a_modal, {
      width: 850,
      maskClosable: false,
      visible: _unref(visible),
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      title: _unref(form)._id ? '编辑' : '新增',
      onOk: submitMain
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _unref(form),
          ref: "formStateRef",
          name: "basic",
          autocomplete: "off",
          "label-col": { style: { width: '150px' } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('一级分类', _unref(language)),
              name: "firstCategory",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(form).firstCategory,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(form).firstCategory) = $event)),
                  style: {"width":"100%"},
                  options: _unref(firstCategoryList).map(item => ({ value: item })),
                  onChange: getSecondList
                }, {
                  dropdownRender: _withCtx(({ menuNode: menu }) => [
                    _createVNode(_unref(VNodes), { vnodes: menu }, null, 8, ["vnodes"]),
                    _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                    _createElementVNode("div", {
                      style: {"padding":"4px 8px","cursor":"pointer"},
                      onMousedown: _cache[5] || (_cache[5] = e => e.preventDefault()),
                      onClick: _cache[6] || (_cache[6] = ($event: any) => (addItemCategory(1)))
                    }, [
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_plus_outlined),
                        _cache[22] || (_cache[22] = _createTextVNode(" 添加 "))
                      ])
                    ], 32)
                  ]),
                  _: 1
                }, 8, ["value", "options"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('二级分类', _unref(language)),
              name: "secondCategory",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(form).secondCategory,
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(form).secondCategory) = $event)),
                  style: {"width":"100%"},
                  options: _unref(secondCategoryList).map(item => ({ value: item })),
                  onChange: getThirdList
                }, {
                  dropdownRender: _withCtx(({ menuNode: menu }) => [
                    _createVNode(_unref(VNodes), { vnodes: menu }, null, 8, ["vnodes"]),
                    _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                    _createElementVNode("div", {
                      style: {"padding":"4px 8px","cursor":"pointer"},
                      onMousedown: _cache[8] || (_cache[8] = e => e.preventDefault()),
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (addItemCategory(2)))
                    }, [
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_plus_outlined),
                        _cache[23] || (_cache[23] = _createTextVNode(" 添加 "))
                      ])
                    ], 32)
                  ]),
                  _: 1
                }, 8, ["value", "options"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('三级分类', _unref(language)),
              name: "thirdCategory",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  value: _unref(form).thirdCategory,
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(form).thirdCategory) = $event)),
                  style: {"width":"100%"},
                  options: _unref(thirdCategoryList).map(item => ({ value: item }))
                }, {
                  dropdownRender: _withCtx(({ menuNode: menu }) => [
                    _createVNode(_unref(VNodes), { vnodes: menu }, null, 8, ["vnodes"]),
                    _createVNode(_component_a_divider, { style: {"margin":"4px 0"} }),
                    _createElementVNode("div", {
                      style: {"padding":"4px 8px","cursor":"pointer"},
                      onMousedown: _cache[11] || (_cache[11] = e => e.preventDefault()),
                      onClick: _cache[12] || (_cache[12] = ($event: any) => (addItemCategory(3)))
                    }, [
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_plus_outlined),
                        _cache[24] || (_cache[24] = _createTextVNode(" 添加 "))
                      ])
                    ], 32)
                  ]),
                  _: 1
                }, 8, ["value", "options"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _unref(getLanguage)('转速范围', _unref(language)),
              name: "thirdCategory",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _unref(form).speedRange,
                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_unref(form).speedRange) = $event)),
                  placeholder: "",
                  rows: 3
                }, null, 8, ["value"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "title"]),
    _createVNode(_component_a_modal, {
      width: 400,
      maskClosable: false,
      visible: _unref(visibleAddValue),
      "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => (_isRef(visibleAddValue) ? (visibleAddValue).value = $event : visibleAddValue = $event)),
      title: "新增分类名称",
      onOk: submitAddValue
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_input, {
          style: {"width":"350px"},
          value: _unref(addValue),
          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => (_isRef(addValue) ? (addValue).value = $event : addValue = $event))
        }, null, 8, ["value"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_a_modal, {
      width: 850,
      maskClosable: false,
      visible: _unref(visible2),
      "onUpdate:visible": _cache[18] || (_cache[18] = ($event: any) => (_isRef(visible2) ? (visible2).value = $event : visible2 = $event)),
      title: "新增",
      onOk: handleOk
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_a_table, {
            bordered: "",
            "row-selection": {
                    selectedRowKeys: selectedRowKeys.value,
                    onChange: onSelectChange,
                },
            columns: columnsZB,
            "data-source": baseModelSource.value
          }, null, 8, ["row-selection", "data-source"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}
}

})