import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, isRef as _isRef, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = {
  class: "w-64 px-2 overflow-y-auto scroll-box h-screen code-box",
  style: {"height":"100%"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { style: {"color":"#f50"} }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }

import request from "../../common/request";
import { onMounted } from "vue";
import { ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import DcpItem from './common/DcpItem.vue';
import { getRealFactoryIdListById } from "../../common/tools";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { langList } from "../../common/lang";
import { operateLogs } from "@/common/logs";

export default /*@__PURE__*/_defineComponent({
  __name: 'Dpc',
  setup(__props) {

let langObj: any = ref({})
    let language: any = ref('Chinese')
    const getLang = () => {
        language.value = localStorage.getItem('language') || 'Chinese'
        langObj.value = langList[language.value]
    }
    getLang()
/**操作日志记录 */
operateLogs('访问参数配置'); 
/**操作日志记录 */


const sensorsSource = ref<any>([]);

// 查询
const search = async() => {
  const config = {
    params: { id: currentMachine.value.key },
    headers: {
      requestId: uuidv4(),
    },
  };
  var result = await request.get("/api/machines/parameter", config);
  if (result) {
   
    const sConfig = {
      params: { machineId: currentMachine.value.key },
      headers: {
        requestId: uuidv4(),
      },
    };
    var sResult = await request.get("/api/sensors", sConfig);

    if(sResult){
      var sensorsSources:any = []

      sResult.data.map((d:any) => {
        let resultInfo = result?.data.find((p:any) => p.sensorId === d.id)
        resultInfo.machineIdList = [d.machineId]
        sensorsSources.push(resultInfo)
      })

      // result.data.forEach((item: any) => {
      //   item.machineIdList = [item.machineId];
      //   if(sResult.data.filter((t:any)=>t.id ==item.sensorId).length){
      //     sensorsSources.push(item)
      //   }
      // });
      sensorsSource.value = sensorsSources
    }
  } else {
    sensorsSource.value = [];
  }
};

/* 左侧区域-设备 数据加载 */
// 在组件加载完成后执行一些操作
let currentMachine = ref<any>();
const selectTree = (key: any, val: any) => {
  if (val.node.type == "machine") {
    currentMachine.value = val.node;
    search();
  }
};



let filterKeyWord = ref<any>();
let treeData = ref<any>();

const onExpand = (keys: string[]) => {
  expandedKeys.value = keys;
  autoExpandParent.value = false;
};
const expandedKeys = ref<(string | number)[]>([]);
const autoExpandParent = ref<boolean>(true);

watch(filterKeyWord, () => {
  expandedKeys.value = [];
  sonKey(treeData.value, "");
  autoExpandParent.value = true;
});
const sonKey = (item: any) => {
  item.forEach((element: any) => {
    if (element.title.indexOf(filterKeyWord.value) > -1) {
      // expandedKeys.value.push(key);
      expandedKeys.value.push(element.key);
    }
    if (element.children && element.children.length) {
      sonKey(element.children, element.key);
    }
  });
};
const getParentKey = (
  key: string | number,
  tree: any
): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (
        node.children.some((item: { key: string | number }) => item.key === key)
      ) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

// 加载model基础数据
const baseModelSource = ref([]);
const loadBaseModelSource = () => {
  const config = {
    params: { type: 'Component' },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/machines/parameter/configs", config).then((res: any) => {
    if (res) {
      res.data=res.data.map((t:any)=>({...t,key:t.id}))
      baseModelSource.value = res.data
    }
  });
};
loadBaseModelSource()


//***角色车间***//
onMounted(() => {
  var requestId = uuidv4();
  loadFactory(requestId);
});

let factoryList = ref<any>();
let allFactory:any = ref([])
const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory:any = [];
      let parentFs = list.filter((p:any) => !p.parentId)
      parentFs.map((d:any) => {
        let sonFactory = list.filter((p:any) => p.parentId === d.id)
        if(sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })
      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
const loadMachine = async(requestId: any) => {
  let factoryIds = allFactory.value.map((p:any) => p._id)
  let res = await request.post("/api/machines/all", {factoryIds})
  factoryList.value.forEach((element: any) => {
    if (res) {
        element.children.forEach((sonFact: any) => {
          if (sonFact.type == "factory" && sonFact.children) {
            sonFact.children.forEach((groupFact: any) => {
              if (groupFact.type == "group") {
                groupFact.children = res.data
                  .filter((t: any) => t.groupId == groupFact.value)
                  .map((t: any) => ({
                    type: "machine",
                    ...t,
                    value: t.id,
                    label: t.machineName,
                    title: t.machineName,
                    key: t.id,
                  }));
              }
            });
          } else if (sonFact.type == "group") {
            sonFact.children = res.data
              .filter((t: any) => t.groupId == sonFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
          }
        });
      }
  });
  setTimeout(() => {
    treeData.value = factoryList.value
  }, 100);
};

return (_ctx: any,_cache: any) => {
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_layout_content, { style: {"padding":"0 20px","height":"100%"} }, {
          default: _withCtx(() => [
            _createVNode(_component_a_row, {
              type: "flex",
              style: {"height":"100%"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, {
                  span: 5,
                  order: 1,
                  style: {"height":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_a_input_search, {
                        value: _unref(filterKeyWord),
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_isRef(filterKeyWord) ? (filterKeyWord).value = $event : filterKeyWord = $event)),
                        style: {"margin-bottom":"8px"},
                        placeholder: _unref(langObj)['输入区域或设备名称筛选']
                      }, null, 8, ["value", "placeholder"]),
                      _createVNode(_component_a_tree, {
                        class: "draggable-tree",
                        draggable: "",
                        "block-node": "",
                        autoExpandParent: autoExpandParent.value,
                        "expanded-keys": expandedKeys.value,
                        "tree-data": _unref(treeData),
                        onExpand: onExpand,
                        onSelect: selectTree
                      }, {
                        title: _withCtx(({ title }) => [
                          (title.indexOf(_unref(filterKeyWord)) > -1)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                                _createTextVNode(_toDisplayString(title.substr(0, title.indexOf(_unref(filterKeyWord)))) + " ", 1),
                                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(filterKeyWord)), 1),
                                _createTextVNode(" " + _toDisplayString(title.substr(
                      title.indexOf(_unref(filterKeyWord)) + _unref(filterKeyWord).length
                    )), 1)
                              ]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(title), 1))
                        ]),
                        _: 1
                      }, 8, ["autoExpandParent", "expanded-keys", "tree-data"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, {
                  span: 19,
                  order: 2
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sensorsSource.value, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.sensorId
                      }, [
                        (_openBlock(), _createBlock(DcpItem, {
                          threshold: item,
                          baseModel: baseModelSource.value,
                          key: item.sensorId,
                          deviceId: _unref(currentMachine).key,
                          onSearch: search
                        }, null, 8, ["threshold", "baseModel", "deviceId"]))
                      ]))
                    }), 128)),
                    (!sensorsSource.value || !sensorsSource.value.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_a_alert, {
                            message: _unref(langObj)['未选择设备或没有配置测点'],
                            type: "warning",
                            "show-icon": ""
                          }, null, 8, ["message"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})