import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/people.png'
import _imports_2 from '@/assets/Vector1.png'
import _imports_3 from '@/assets/Vector2.png'
import _imports_4 from '@/assets/Vector3.png'


const _hoisted_1 = { class: "logoText" }
const _hoisted_2 = {
  class: "logo ml-4",
  style: {"background-color":"#fff"}
}
const _hoisted_3 = { class: "plantName" }
const _hoisted_4 = { class: "flex item-center" }
const _hoisted_5 = { class: "text-gray-50 ml-2" }
const _hoisted_6 = {
  key: 1,
  id: "ant-layout-content"
}
const _hoisted_7 = {
  class: "w-full flex theme-head-background",
  style: {"height":"100px"}
}
const _hoisted_8 = ["src"]
const _hoisted_9 = {
  key: 1,
  style: {"height":"50px"},
  class: "ml-4",
  src: _imports_0
}
const _hoisted_10 = { class: "header-logo-txt ml-2 flex-1" }
const _hoisted_11 = {
  class: "flex-1",
  style: {"position":"relative"}
}
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { style: {"transform":"skew(30deg)","text-align":"center","line-height":"43px"} }
const _hoisted_14 = {
  class: "w-full flex",
  style: {"justify-content":"center","transform":"skew(30deg)"}
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { style: {"transform":"skew(30deg)","text-align":"center","line-height":"32px"} }
const _hoisted_17 = { style: {"position":"relative","min-width":"230px"} }
const _hoisted_18 = {
  class: "flex items-center",
  style: {"height":"70px"}
}
const _hoisted_19 = {
  class: "box fav-box",
  style: {"display":"none"}
}
const _hoisted_20 = { style: {"padding":"15px 15px 0 15px","cursor":"pointer"} }
const _hoisted_21 = { style: {"padding":"15px 15px 0 15px","cursor":"pointer"} }
const _hoisted_22 = {
  class: "text-gray-50 ml-2 flex-1",
  style: {"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","cursor":"pointer"}
}
const _hoisted_23 = { class: "flex items-center" }
const _hoisted_24 = { class: "flex items-center" }
const _hoisted_25 = { class: "parallelogram-1" }
const _hoisted_26 = {
  class: "flex-1",
  style: {"text-align":"center"}
}
const _hoisted_27 = {
  key: 0,
  class: "list-box",
  style: {"z-index":"99999 !important"}
}
const _hoisted_28 = { class: "mb-2" }
const _hoisted_29 = ["onClick"]
const _hoisted_30 = { key: 0 }
const _hoisted_31 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_CaretLeftOutlined = _resolveComponent("CaretLeftOutlined")!
  const _component_CaretRightOutlined = _resolveComponent("CaretRightOutlined")!
  const _component_UserOutlined = _resolveComponent("UserOutlined")!
  const _component_clear_outlined = _resolveComponent("clear-outlined")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_CaretDownOutlined = _resolveComponent("CaretDownOutlined")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_warning_popup = _resolveComponent("warning-popup")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.locale === 'en' ? _ctx.enUS : _ctx.zhCN
  }, {
    default: _withCtx(() => [
      (_ctx.config.menuVersion === 'V1')
        ? (_openBlock(), _createBlock(_component_a_layout, { key: 0 }, {
            default: _withCtx(() => [
              (!_ctx.iframe)
                ? (_openBlock(), _createBlock(_component_a_layout_sider, {
                    key: 0,
                    collapsed: _ctx.collapsed,
                    "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.collapsed) = $event)),
                    trigger: null,
                    theme: "dark",
                    collapsible: "",
                    style: _normalizeStyle({
        height: _ctx.state.windowHeight + 'px',
      })
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("div", _hoisted_1, "PHM", 512), [
                        [_vShow, _ctx.collapsed]
                      ]),
                      _withDirectives(_createElementVNode("div", _hoisted_2, _cache[27] || (_cache[27] = [
                        _createElementVNode("img", {
                          style: {"height":"100%"},
                          src: _imports_0
                        }, null, -1)
                      ]), 512), [
                        [_vShow, !_ctx.collapsed]
                      ]),
                      _createVNode(_component_a_menu, {
                        selectedKeys: _ctx.selectedKeys2,
                        "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys2) = $event)),
                        openKeys: _ctx.openKeys,
                        "onUpdate:openKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openKeys) = $event)),
                        mode: "inline",
                        style: _normalizeStyle({
          height: _ctx.state.windowHeight - 100 + 'px',
        }),
                        class: "menuOv",
                        theme: "dark"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (item, index) => {
                            return (_openBlock(), _createElementBlock("span", { key: index }, [
                              (item.type == 2)
                                ? (_openBlock(), _createBlock(_component_a_menu_item, {
                                    key: item.id,
                                    onClick: ($event: any) => (_ctx.linkTo(item.route))
                                  }, {
                                    icon: _withCtx(() => [
                                      (item.icon)
                                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon + '-outlined'), { key: 0 }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    default: _withCtx(() => [
                                      _createTextVNode(" " + _toDisplayString(item.title), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : (_openBlock(), _createBlock(_component_a_sub_menu, {
                                    key: item.id + '_'
                                  }, {
                                    icon: _withCtx(() => [
                                      (item.icon)
                                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon + '-outlined'), { key: 0 }))
                                        : _createCommentVNode("", true)
                                    ]),
                                    title: _withCtx(() => [
                                      _createElementVNode("span", null, _toDisplayString(item.title), 1)
                                    ]),
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (children) => {
                                        return (_openBlock(), _createBlock(_component_a_menu_item, {
                                          key: children.id,
                                          onClick: ($event: any) => (_ctx.linkTo(children.route))
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(children.title), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"]))
                                      }), 128))
                                    ]),
                                    _: 2
                                  }, 1024))
                            ]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["selectedKeys", "openKeys", "style"])
                    ]),
                    _: 1
                  }, 8, ["collapsed", "style"]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_layout, null, {
                default: _withCtx(() => [
                  (!_ctx.iframe)
                    ? (_openBlock(), _createBlock(_component_a_layout_header, {
                        key: 0,
                        style: {"padding":"0"},
                        class: "flex",
                        theme: "dark"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, [
                            (_ctx.collapsed)
                              ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, {
                                  key: 0,
                                  class: "trigger",
                                  onClick: _cache[3] || (_cache[3] = () => (_ctx.collapsed = !_ctx.collapsed))
                                }))
                              : (_openBlock(), _createBlock(_component_menu_fold_outlined, {
                                  key: 1,
                                  class: "trigger",
                                  onClick: _cache[4] || (_cache[4] = () => (_ctx.collapsed = !_ctx.collapsed))
                                }))
                          ]),
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.config?.projectName), 1),
                          _createElementVNode("div", _hoisted_4, [
                            (_ctx.visibleFactory)
                              ? (_openBlock(), _createBlock(_component_a_cascader, {
                                  key: 0,
                                  class: "mycasc",
                                  value: _ctx.factoryIds,
                                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.factoryIds) = $event)),
                                  options: _ctx.factoryList,
                                  placeholder: "切换工厂(全部)",
                                  style: {"width":"250px","margin-right":"20px"},
                                  onChange: _ctx.factoryChange
                                }, null, 8, ["value", "options", "onChange"]))
                              : _createCommentVNode("", true),
                            _cache[29] || (_cache[29] = _createElementVNode("img", {
                              class: "hidden xl:inline-block",
                              src: _imports_1,
                              width: "20",
                              height: "20"
                            }, null, -1)),
                            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.member.nickName || _ctx.member.userName), 1),
                            _cache[30] || (_cache[30] = _createElementVNode("i", {
                              class: "inline xl:hidden cursor-pointer text-base mx-2",
                              "nz-icon": "",
                              nzType: "logout",
                              nzTheme: "outline"
                            }, null, -1)),
                            _createElementVNode("a", {
                              class: "mx-5 hidden xl:inline-block",
                              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args)))
                            }, _cache[28] || (_cache[28] = [
                              _createElementVNode("span", { class: "text-gray-50" }, "[安全退出]", -1)
                            ]))
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_layout_content, {
                    id: "ant-layout-content",
                    style: _normalizeStyle({
          margin: '3px 3px',
          padding: '0px 0',
          background: '#fff',
          minHeight: '680px',
          height:
            (_ctx.iframe ? _ctx.state.windowHeight - 20 : _ctx.state.windowHeight - 200) +
            'px',
        }),
                    class: "menuOv"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.roterAlive)
                        ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["style"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_drawer, {
        width: 500,
        title: "切换工厂",
        placement: "right",
        visible: _ctx.visible,
        onClose: _ctx.onClose
      }, null, 8, ["visible", "onClose"]),
      (_ctx.config.menuVersion === 'V2')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", {
                class: _normalizeClass(["header-logo flex items-center", _ctx.theme == 'white' ? 'header-logo-white' : ''])
              }, [
                (_ctx.picUrl && _ctx.picUrl !== '1')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      style: {"height":"50px"},
                      class: "ml-4",
                      src: _ctx.picUrl
                    }, null, 8, _hoisted_8))
                  : _createCommentVNode("", true),
                (!_ctx.picUrl)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.langObj[_ctx.title] || _ctx.title), 1),
                _cache[31] || (_cache[31] = _createElementVNode("div", { class: "header-v" }, "v4.1", -1))
              ], 2),
              _createElementVNode("div", _hoisted_11, [
                (Number(_ctx.routerContentWidth) > Number(_ctx.routerWidth))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "leftbtn",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.scrollBar('right')))
                    }, [
                      _createVNode(_component_CaretLeftOutlined)
                    ]))
                  : _createCommentVNode("", true),
                (Number(_ctx.routerContentWidth) > Number(_ctx.routerWidth))
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "rightbtn",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.scrollBar('left')))
                    }, [
                      _createVNode(_component_CaretRightOutlined)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  style: _normalizeStyle([{"height":"70px","position":"relative"}, { width: _ctx.routerWidth + 'px' }]),
                  class: "overflow-hidden",
                  ref: "wrapper",
                  id: "wrapper"
                }, [
                  _createElementVNode("div", {
                    class: "flex items-center h-full content",
                    style: _normalizeStyle({ width: _ctx.routerContentWidth + 'px' })
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuList, (menu, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createElementVNode("div", {
                          onClick: ($event: any) => (_ctx.linkTo(menu.code)),
                          style: {"width":"250px","height":"50px","font-size":"18px"},
                          class: _normalizeClass(["ml-4", [
                  _ctx.selectParentCode === menu.code
                    ? 'parallelogram-select'
                    : 'parallelogram-2',
                ]])
                        }, [
                          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.langObj[menu.title] || menu.title), 1),
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("div", {
                              class: "text-line",
                              style: _normalizeStyle({
                      'background-color':
                        _ctx.selectParentCode === menu.code
                          ? '#00FAFC'
                          : 'transparent',
                    })
                            }, null, 4)
                          ])
                        ], 10, _hoisted_12)
                      ]))
                    }), 128))
                  ], 4)
                ], 4),
                _createElementVNode("div", {
                  style: _normalizeStyle([{"height":"30px"}, { width: _ctx.routerChildWidth + 'px' }]),
                  class: "overflow-hidden",
                  id: "wrapper2"
                }, [
                  _createElementVNode("div", {
                    class: "flex items-center h-full content",
                    style: _normalizeStyle({ width: _ctx.childrenMenuList.length * 191 + 'px' })
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.childrenMenuList, (menu, index) => {
                      return (_openBlock(), _createElementBlock("div", { key: index }, [
                        _createElementVNode("div", {
                          onClick: ($event: any) => (_ctx.linkToChild(menu.route)),
                          style: {"width":"175px","height":"30px","font-size":"14px"},
                          class: _normalizeClass(["ml-4", [
                    _ctx.selectChilMenu === menu.route
                      ? 'parallelogram-select'
                      : 'parallelogram-2',
                  ]])
                        }, [
                          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.langObj[menu.title] || menu.title), 1)
                        ], 10, _hoisted_15)
                      ]))
                    }), 128))
                  ], 4)
                ], 4)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _cache[39] || (_cache[39] = _createElementVNode("div", { style: {"width":"20px"} }, null, -1)),
                  _createVNode(_component_UserOutlined, { style: {"font-size":"20px"} }),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createTextVNode(_toDisplayString(_ctx.langObj['语言']) + "： ", 1),
                      _createVNode(_component_a_select, {
                        style: {"width":"120px"},
                        value: _ctx.language,
                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.language) = $event)),
                        onChange: _ctx.selectLang
                      }, {
                        suffixIcon: _withCtx(() => [
                          _createVNode(_component_clear_outlined, { class: "ant-select-suffix" })
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select_option, { value: "Chinese" }, {
                            default: _withCtx(() => _cache[32] || (_cache[32] = [
                              _createTextVNode("简体中文")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: "English" }, {
                            default: _withCtx(() => _cache[33] || (_cache[33] = [
                              _createTextVNode("English")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "onChange"])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createTextVNode(_toDisplayString(_ctx.langObj['主题']) + "： ", 1),
                      _createVNode(_component_a_select, {
                        style: {"width":"120px"},
                        value: _ctx.theme,
                        "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.theme) = $event)),
                        onChange: _ctx.changeTheme
                      }, {
                        suffixIcon: _withCtx(() => [
                          _createVNode(_component_clear_outlined, { class: "ant-select-suffix" })
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_a_select_option, { value: "blue" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.langObj['蓝色']), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: "white" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.langObj['白色']), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "onChange"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_a_dropdown, null, {
                      overlay: _withCtx(() => [
                        _createVNode(_component_a_menu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_sub_menu, { key: "sub1" }, {
                              title: _withCtx(() => [
                                _createElementVNode("div", _hoisted_23, [
                                  _cache[34] || (_cache[34] = _createElementVNode("img", {
                                    style: {"height":"16px","width":"18px","margin-right":"10px"},
                                    src: _imports_2
                                  }, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.language == 'English' ? 'English' : '简体中文'), 1)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_a_menu_item, {
                                  key: "Chinese",
                                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.selectLang('Chinese')))
                                }, {
                                  default: _withCtx(() => _cache[35] || (_cache[35] = [
                                    _createTextVNode("简体中文")
                                  ])),
                                  _: 1
                                }),
                                _createVNode(_component_a_menu_item, {
                                  key: "English",
                                  onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.selectLang('English')))
                                }, {
                                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                                    _createTextVNode("EngLish")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_sub_menu, { key: "sub2" }, {
                              title: _withCtx(() => [
                                _createElementVNode("div", _hoisted_24, [
                                  _cache[37] || (_cache[37] = _createElementVNode("img", {
                                    style: {"height":"16px","width":"18px","margin-right":"10px"},
                                    src: _imports_3
                                  }, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.theme == 'white' ? _ctx.langObj['白色'] : _ctx.langObj['蓝色']), 1)
                                ])
                              ]),
                              default: _withCtx(() => [
                                _createVNode(_component_a_menu_item, {
                                  key: "blue",
                                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.changeTheme('blue')))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.langObj['蓝色']), 1)
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_a_menu_item, {
                                  key: "white",
                                  onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.changeTheme('white')))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.langObj['白色']), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_menu_item, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", {
                                  onClick: _cache[16] || (_cache[16] = 
//@ts-ignore
(...args) => (_ctx.logOut && _ctx.logOut(...args))),
                                  class: "flex items-center"
                                }, [
                                  _cache[38] || (_cache[38] = _createElementVNode("img", {
                                    style: {"height":"16px","width":"18px","margin-right":"10px"},
                                    src: _imports_4
                                  }, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.langObj["[安全退出]"]), 1)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "text-gray-50 flex items-center",
                          onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["prevent"]))
                        }, [
                          _createTextVNode(_toDisplayString(_ctx.member.nickName || _ctx.member.userName) + " ", 1),
                          _createVNode(_component_CaretDownOutlined, { style: {"font-size":"16px","padding-left":"5px"} })
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", {
                  tabindex: "-1",
                  onBlur: _cache[19] || (_cache[19] = 
//@ts-ignore
(...args) => (_ctx.cancalVis && _ctx.cancalVis(...args)))
                }, [
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", {
                      onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.visibleFac = !_ctx.visibleFac)),
                      class: "flex items-center w-full h-full cursor-pointer",
                      style: {"transform":"skew(35deg)"}
                    }, [
                      _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.selectFactoryName), 1),
                      _createVNode(_component_CaretDownOutlined)
                    ])
                  ]),
                  (_ctx.visibleFac)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          _createVNode(_component_a_input, {
                            value: _ctx.facName,
                            "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.facName) = $event)),
                            placeholder: "请输入工厂名称",
                            onChange: _ctx.changeFacName
                          }, null, 8, ["value", "onChange"])
                        ]),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.factoryList, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", { key: index }, [
                            _createElementVNode("div", {
                              onClick: ($event: any) => (_ctx.selectFac(item)),
                              class: _normalizeClass(["list-info theme-factory", { 'list-info-select': _ctx.selectFactory === item.id }])
                            }, [
                              _createElementVNode("span", null, _toDisplayString(item.label), 1)
                            ], 10, _hoisted_29),
                            (item.children && item.children.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child, index2) => {
                                    return (_openBlock(), _createElementBlock("div", { key: index2 }, [
                                      _createElementVNode("div", {
                                        onClick: ($event: any) => (_ctx.selectFac(child)),
                                        class: _normalizeClass(["list-info theme-factory", {
                      'list-info-select': _ctx.selectFactory === child.id,
                    }]),
                                        style: {"padding":"10px 40px"}
                                      }, [
                                        _createElementVNode("span", null, _toDisplayString(child.label), 1)
                                      ], 10, _hoisted_31)
                                    ]))
                                  }), 128))
                                ]))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 32)
              ])
            ]),
            _createVNode(_component_a_modal, {
              width: 550,
              maskClosable: false,
              visible: _ctx.modalState.visible,
              "onUpdate:visible": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.modalState.visible) = $event)),
              title: "修改密码",
              "confirm-loading": _ctx.modalState.confirmLoading,
              onOk: _ctx.changePassword
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form, {
                  model: _ctx.modalFormPwd,
                  ref: "formPasswordRef",
                  name: "basic",
                  rules: _ctx.rules,
                  autocomplete: "off",
                  "label-col": { style: { width: '150px' } }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_form_item, {
                      label: "原密码",
                      name: "oldPassword",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          type: "password",
                          value: _ctx.modalFormPwd.oldPassword,
                          "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.modalFormPwd.oldPassword) = $event)),
                          placeholder: "请输入原密码"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, {
                      label: "新密码",
                      name: "password",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          type: "password",
                          value: _ctx.modalFormPwd.password,
                          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.modalFormPwd.password) = $event)),
                          placeholder: "请输入新密码"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_form_item, {
                      label: "再次输入新密码",
                      name: "checkPassword",
                      required: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_input, {
                          type: "password",
                          value: _ctx.modalFormPwd.checkPassword,
                          "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.modalFormPwd.checkPassword) = $event)),
                          placeholder: "再次输入新密码"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["model", "rules"])
              ]),
              _: 1
            }, 8, ["visible", "confirm-loading", "onOk"]),
            _createVNode(_component_a_modal, {
              width: 1500,
              maskClosable: false,
              title: "报警",
              footer: null,
              visible: _ctx.isWarningModalVisible,
              "onUpdate:visible": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.isWarningModalVisible) = $event)),
              forceRender: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_warning_popup, {
                  onClose: _cache[24] || (_cache[24] = ($event: any) => (_ctx.isWarningModalVisible=false)),
                  onAlarm: _cache[25] || (_cache[25] = ($event: any) => (_ctx.isWarningModalVisible=true))
                })
              ]),
              _: 1
            }, 8, ["visible"]),
            (_ctx.roterAlive)
              ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["locale"]))
}