<template>
    <div>
        <a-modal 
            v-model:visible="visible"
            :closable="false"
            :footer="null"
            :bodyStyle= "{'background': '#020F46'}"
            style="top: 30%"
            width="1300px"
            :maskClosable="false"
            v-model:z-index="editRindex2"
        > 
            <div style="color: #fff" >
                <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="cursor-pointer" @click="cancel">
                        <close-outlined style="font-size: 20px;" />
                    </div>
                    
                </div>
                <div class="text-center w-full" style="font-size: 24px;">{{langObj['选择设备']}}</div>

                <div class="p-3 w-full">
                    <a-form
                        :model="searchModel"
                        name="horizontal_login"
                        layout="inline"
                        autocomplete="off"
                    >

                        <a-form-item
                            :label="langObj['测点位置']"
                            name="status"
                            >
                            <a-input v-model:value="searchModel.sensorPlace" />
                        </a-form-item>


                        <a-form-item>
                            <div class="flex items-center">
                                <div @click="getData" class="box px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499;">{{langObj['查询']}}</div>
                            </div>
                        </a-form-item>
                        

                    </a-form>

                    <div class="box mt-4" style="width: 100%;">
                        <a-table style="width: 100%;" size="small" :row-class-name="getRowClassName" :columns="sensorColumns" :data-source="data">
                            <template #bodyCell="{ column, text, record }">
                                <template v-if="column.dataIndex === 'groupId'">
                                    <span>{{ getGroupName(record.machine.groupId) }}</span>
                                </template>
                                <template v-if="column.dataIndex === 'machineName'">
                                    <span>{{ record.machine.machineName }}</span>
                                </template>
                                <template v-if="column.dataIndex === 'act'">
                                    <a @click="select(record)" class="theme-table-link">{{langObj['选择']}}</a>
                                </template>
                            </template>
                        </a-table>


                    </div>



                </div>
            </div>
        </a-modal>
    </div>
</template>

<script lang="ts" setup>
import { defineProps, defineExpose, defineEmits, computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { getFactoryName, getGroupName, transformDate2 } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";

let visible = ref(true)
let editRindex2 = ref("auto");
let groupList = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()
const sensorColumns = [
    {
        title: langObj.value['传感器SN'],
        dataIndex: 'sensorSn',
    },  
    {
        title: langObj.value['测点位置'],
        dataIndex: 'sensorPlace',
    },  
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupId',
    },  
    {
        title: langObj.value['所属设备'],
        dataIndex: 'machineName',
    },  
    {
        title: langObj.value['测点ID'],
        dataIndex: 'id',
    },  
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },  
]
const props: any = defineProps({
    visible: null,
    machineId: null,
    editRindex2: null
});
let searchModel:any = ref({
    groupId: '',
})
let data:any = ref([])
let machineList = ref([])
let machine_id = ref('')

const emits = defineEmits(["selectSensorSen"]);

watch(
    () => props.visible,
    (newValue, oldValue) => {
        visible.value = newValue
        machine_id.value = props.machineId
        init()
    }
);

onMounted(() => {
    machine_id.value = props.machineId
    editRindex2.value = props.editRindex2
    init()
})

const cancel = () => {
    visible.value = false
    emits("selectSensorSen", false);
}

const select = (ev:any) => {
    visible.value = false
    emits("selectSensorSen", ev);
}

const init = () => {
    searchSensor()
}

// 获取测点列表
const searchSensor = () => {
    if(!machine_id.value) {
        return
    }
    searchModel.value.machineId = machine_id.value
    getData()
}

const getData = async() => {
    let config: any = {
        params: {
            machineId: searchModel.value.machineId,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get('/api/sensors', config)
        
    if(result && result.status === 200) {
        data.value = result.data
    }
}

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}
</script>

<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff;
}

/deep/ .ant-table {
    background: #020F46;
    color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: transparent;
}

/deep/ .ant-empty-normal {
    color: #fff;
}

/deep/ .ant-descriptions-item-content {
    color: #fff
}
/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0px solid #fff
}
/deep/ .ant-select-clear span {
  background-color: #000 ;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
    background: #021766;
}
</style>