import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "w-full theme-common",
  style: {"padding":"16px 16px","height":"calc(100vh - 100px)","overflow-y":"scroll","padding-bottom":"30px","background-color":"#000a32"}
}
const _hoisted_2 = { class: "w-full h-full flex" }
const _hoisted_3 = {
  class: "flex-1 flex ml-4",
  style: {"color":"#fff"}
}
const _hoisted_4 = { class: "flex-1 whiteBg" }
const _hoisted_5 = {
  class: "w-full flex px-4 mt-2",
  style: {"border":"1px solid #4a5983","background":"#07249933"}
}
const _hoisted_6 = { class: "w-full flex mt-2" }
const _hoisted_7 = { style: {"min-width":"250px"} }
const _hoisted_8 = { class: "flex-1" }
const _hoisted_9 = { class: "flex-1 content ml-2" }
const _hoisted_10 = { class: "w-full flex" }
const _hoisted_11 = { class: "w-full flex mt-2" }
const _hoisted_12 = { class: "flex items-center mt-4" }
const _hoisted_13 = { class: "flex ml-2" }
const _hoisted_14 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_15 = { class: "mt-4 content ml-2" }
const _hoisted_16 = { class: "w-full flex" }
const _hoisted_17 = { class: "flex-1" }
const _hoisted_18 = { class: "flex-1" }
const _hoisted_19 = { class: "mt-4" }
const _hoisted_20 = {
  class: "box w-full",
  style: {"width":"100%"}
}
const _hoisted_21 = { class: "flex items-center" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "theme-table-link" }
const _hoisted_24 = { class: "w-full items-center box" }
const _hoisted_25 = {
  style: {"height":"calc(100% - 10px)"},
  class: "mt-2 overflow-y-auto"
}
const _hoisted_26 = { class: "flex-1 text-center mt-2" }
const _hoisted_27 = { class: "w-full items-center box" }
const _hoisted_28 = {
  style: {"height":"calc(100% - 10px)"},
  class: "mt-2 overflow-y-auto"
}
const _hoisted_29 = { class: "flex-1 text-center mt-2" }

import { onMounted, onUnmounted, ref, watch } from "vue";
import request from "../../common/request";
import { transformDate2, } from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { Dayjs } from "dayjs";
import { langList } from "@/common/lang";
import { message } from "ant-design-vue";

interface SingleOffsetItem {
  date: string;
  offsetScore: number;
  score: number;
}

interface OffsetState {
  wholeOffsetScore: number,
  singleOffsetScore: SingleOffsetItem | null,
}

interface ParamGroup {
  id: number;
  faultName: string;
  isSingle: boolean,
  dateArr: string[],
  scores: number[],
  params: any,
  paramOptions: { label: string, value: number }[],
  paramLabels: string[],
  scoreArr: { date: string, score: number }[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'vibration',
  setup(__props) {

let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

const SCORE_OPERATOR = { plus: "plus", minus: "minus" };

let sensorInfo: any = ref({});

let form: any = ref({
  wholeOffsetScoreOperator: SCORE_OPERATOR.plus,
  wholeOffsetScore: null,
  singleOffsetScoreOperator: SCORE_OPERATOR.plus,
  singleOffsetScore: null,

  machineType: "",
  sensorIds: [],
});

let unUseSensorIds: any = ref([]);
let useSensorIds: any = ref([]);

//let unUseSensorIdsOld: any = ref([]);
let useSensorIdsOld: any = ref([]);

let unUseSensors: any = ref([]);
let useSensors: any = ref([]);

let simpleSearchModel = ref({
  type: "1",
  date: "",
});

let menuSelect = ref(1);

let scrollTop = ref("120px");

let selectSimpleModel: any = ref({});

const selectSensor = async (ev: any) => {
  let result = await request.get("/api/sensors/info?id=" + ev);
  if (result.data) {
    sensorInfo.value = result.data;
    await loadFaultNames(ev);
  }
};

onMounted(() => {
  loadMachineTypes();
  myChart = (echarts as any).init(document.getElementById("chart1"));
});

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

let selectedChartDataIndex: number | null = null;
let selectedChartDataDate: string = "";
let myChart: any = null;
let xData: string[] = [];
let yData: number[] = [];
let modifiedYData: (number | null)[] = [];
let trainedYData: number[] = [];
const updateChart = (reDraw: boolean = true) => {
  myChart.on('selectchanged', function (params: any) {
    if (params.selected && params.selected.length > 0) {
      //console.log("selectchanged", params.selected[0].dataIndex)
      selectedChartDataIndex = params.selected[0].dataIndex[0] as number;
      selectedChartDataDate = scoreData[selectedChartDataIndex].date;
    } else {
      selectedChartDataIndex = null;
      selectedChartDataDate = "";
    }
    //console.log('chart select changed', params);
  });

  let unselectedIndex = selectedChartDataIndex;
  if (reDraw) {
    selectedChartDataIndex = null;
    selectedChartDataDate = "";
    xData = [];
    yData = [];
    modifiedYData = [];
    trainedYData = [];
    myChart.clear();

    for (let item of scoreData) {
      let score = item[selectedFaultNameValue.value];
      if (score == null || score < 0) continue

      xData.push(item.date);

      if (offsetState.wholeOffsetScore) {
        let modifiedScore = Number(offsetState.wholeOffsetScore) + Number(score);
        modifiedYData.push(modifiedScore);
      } else if (offsetState.singleOffsetScore && offsetState.singleOffsetScore.date === item.date) {
        let modifiedScore = Number(offsetState.singleOffsetScore.offsetScore) + Number(score);
        modifiedYData.push(modifiedScore);
        // select on chart
        selectedChartDataIndex = xData.length - 1;
        selectedChartDataDate = item.date;
      } else {
        modifiedYData.push(null);
      }

      yData.push(score);
    }
  } else {
    myChart.setOption({
      series: [
        {
          name: "分数",
          type: "line",
          data: yData,
          selectedMode: "single",
          select: {
            itemStyle: {
              borderWidth: 1.5,
              borderColor: "cyan",
              borderType: "solid"
            }
          }
        },
        {
          name: "训练分数",
          type: "line",
          data: trainedYData
        }
      ]
    });
  }

  if (selectedParamGroupIndex.value >= 0) {
    let paramGroup = trainedParamGroups.value[selectedParamGroupIndex.value];
    for (let item of paramGroup.scoreArr) {
      if (xData.includes(item.date)) {
        trainedYData.push(item.score);
      }
    }
  }

  let option = {
    tooltip: {
      hideDelay: 100,
      transitionDuration: 0,
      animation: false,
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    legend: {
      data: ['分数', '调整分数', '训练分数'],
      textStyle: {
        color: "#fff"
      }
    },
    grid: {
      right: "5%",
      left: "7%",
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [{ type: "inside" }],
    xAxis: [
      {
        show: true,
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        data: xData,
        splitLine: {
          show: true,
          lineStyle: {
            opacity: 0.3,
          },
        },
        axisLabel: {
          color: "#fff",
          fontSize: 16,
          showMaxLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        scale: true,
        min: 0,
        max: 100,
        splitNumber: 5,
        splitLine: {
          lineStyle: {
            color: [
              "rgb(117, 116, 116)",
              "rgb(117, 116, 116)",
              "rgb(117, 116, 116)",
              "rgba(250, 60, 88, 1)",
              "rgba(254, 178, 7, 1)",
              "rgb(117, 116, 116)",
            ],
          },
        },
        position: "left",
        offset: "-10",
        axisLine: {
          lineStyle: {
            color: "#fff",
          },
        },
      },
    ],
    series: [{
      name: "分数",
      type: "line",
      data: yData,
      selectedMode: "single",
      select: {
        itemStyle: {
          borderWidth: 1.5,
          borderColor: "cyan",
          borderType: "solid"
        }
      }
    } as { name: string, type: string, data: (number | null)[] },
      {
        name: "训练分数",
        type: "line",
        data: trainedYData,
      }],
  };
  option.series.push({
    name: "训练分数",
    type: "line",
    data: trainedYData,
  })

  option.series.push({
    name: "调整分数",
    type: "line",
    data: modifiedYData,
  })

  option && myChart.setOption(option);

  if (selectedChartDataIndex != null) {
    myChart.dispatchAction({
      type: 'select',
      seriesIndex: 0,
      dataIndex: [selectedChartDataIndex]
    });
  } else if (unselectedIndex != null) {
    myChart.dispatchAction({
      type: 'unselect',
      seriesIndex: 0,
      dataIndex: unselectedIndex
    });
  }
};

let changeSimpleDateType = (type: string) => {
  simpleSearchModel.value.type = type;
};

function getQueryDate(): { begin: string, end: string } {
  let beginDate: any = "";
  let endDate: any = "";

  if (simpleSearchModel.value.type == "0") {
    if (
        simpleSearchModel.value.date &&
        simpleSearchModel.value.date.length === 2
    ) {
      let start = simpleSearchModel.value.date[0];
      let end = simpleSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return { begin: "", end: "" };
    }
  } else {
    let date = new Date().getTime();
    if (simpleSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24;
    }
    if (simpleSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (simpleSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    endDate = date;
  }
  let begin = (transformDate2(beginDate, 1)) || "";
  let end = (transformDate2(endDate, 2)) || "";
  return { begin: begin, end: end };
}

let scoreData: any[] = [];
let quotaSearch = async () => {
  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    return;
  }

  clearTrainedData();
  let { begin, end } = getQueryDate();

  // todo: remove this
  //begin = '2024/08/01 00:00:00';
  //end = '2024/08/30 00:00:00';

  let result = await request.get("/api/external/machines/score-full", {
    params: {
      begin, end,
      sensorId: sensorInfo.value.id
    }
  });
  /*
  offsetState = {
    wholeOffsetScore: 0,
    singleOffsetScore: null
  }
   */
  scoreData.length = 0;

  if (result && result.data) {
    scoreData = result.data;
  }
  updateChart();
};

let offsetState: OffsetState = {
  wholeOffsetScore: 0,
  singleOffsetScore: null
}

let allowAddSingle = ref(true);
let allowAddWhole = ref(true);
const onClickAddSingleOffsetScore = () => {
  if (!selectedChartDataDate || !selectedChartDataIndex) {
    message.warn("请选择单点");
    return;
  }
  if (form.value.singleOffsetScore == null) {
    message.warn("请输入分数");
    return;
  }
  let offsetScore = Number(form.value.singleOffsetScore);
  if (form.value.singleOffsetScoreOperator == SCORE_OPERATOR.minus && offsetScore > 0) {
    offsetScore = -offsetScore;
  } else if (form.value.singleOffsetScoreOperator == SCORE_OPERATOR.plus && offsetScore < 0) {
    offsetScore = -offsetScore;
  }

  offsetState.wholeOffsetScore = 0;
  form.value.wholeOffsetScore = 0;
  offsetState.singleOffsetScore = {
    date: selectedChartDataDate,
    offsetScore: offsetScore,
    score: scoreData[selectedChartDataIndex][selectedFaultNameValue.value]
  };
  offsetState.singleOffsetScore.score = scoreData[selectedChartDataIndex][selectedFaultNameValue.value];
  //console.log("selected x index", selectedChartDataIndex)
  //console.log("origin score", scoreData[selectedChartDataIndex][selectedFaultNameValue.value])
  //console.log('operate', form.value.singleOffsetScoreOperator)
  //console.log("offset score", offsetScore)
  //console.log("updated score", offsetState.singleOffsetScore)
  //offsetState.singleOffsetScore.finalScore = scoreData[selectedChartDataIndex][selectedFaultNameValue.value] + offsetScore;

  updateChart();
}

const onClickAddWholeOffsetScore = () => {
  if (form.value.wholeOffsetScore == null) {
    message.warn("请输入分数");
    return;
  }

  offsetState.wholeOffsetScore = Number(form.value.wholeOffsetScore);
  if (form.value.wholeOffsetScoreOperator == SCORE_OPERATOR.minus) {
    offsetState.wholeOffsetScore = -offsetState.wholeOffsetScore;
  }

  offsetState.singleOffsetScore = null;
  form.value.singleOffsetScore = 0;

  updateChart()
};

let trainedParamGroups = ref<ParamGroup[]>([]);
let selectedParamGroupIndex = ref<number>(-1);
watch(selectedParamGroupIndex, (val) => {
  if (val == -1) {
    trainedYData = [];
    return;
  }
  //console.log("selected param group", val)
  let paramGroup = trainedParamGroups.value[val];
  trainedYData.length = 0;
  if (!paramGroup) {
    return;
  }

  for (let i = 0; i < xData.length; i++) {
    let trainedScoreItem = paramGroup.scoreArr.find(t => t.date === xData[i]);
    if (trainedScoreItem) {
      trainedYData.push(trainedScoreItem.score);
    } else {
      trainedYData.push(yData[i]);
    }
  }
  updateChart(false);
  //console.log("trainedYData", trainedYData)
})
const updateTrainedData = (faultName: string, isSingle: boolean, dateArr: string[], resultArr: object[]) => {
  trainedParamGroups.value = [];
  if (resultArr.length == 0) {
    selectedParamGroupIndex.value = -1;
    return;
  }

  for (let i = 0; i < resultArr.length; i++) {
    let item = resultArr[i] as any;
    let scoreRes = item[faultName] as any;

    let paramGroup = {
      id: i,
      isSingle: isSingle,
      dateArr: dateArr,
      faultName: faultName,
      scores: [] as number[],
      paramOptions: [] as { label: string, value: number }[],
      paramLabels: [] as string[],
      scoreArr: [] as { date: string, score: number }[],
      params: null
    }
    if (item.params) {
      for (let paramKey in item.params) {
        let paramValue = Number(item.params[paramKey].toFixed(4));
        item.params[paramKey] = paramValue;
        paramGroup.paramOptions.push({ label: paramKey, value: paramValue })
        paramGroup.paramLabels.push(`${paramKey}:${paramValue}`)
      }
    }
    paramGroup.params = item.params

    paramGroup.scores = scoreRes;
    for (let i = 0; i < dateArr.length; i++) {
      let score = scoreRes[i];
      paramGroup.scoreArr.push({
        date: dateArr[i],
        score: score
      })
    }

    trainedParamGroups.value.push(paramGroup)
  }
  if (trainedParamGroups.value.length > 0) {
    selectedParamGroupIndex.value = 0;
  }
}

const clearTrainedData = () => {
  selectedParamGroupIndex.value = -1;
  trainedParamGroups.value = []
}

let isTraining = ref(false);
const onClickTrainModel = async () => {

  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    return;
  }

  if (offsetState.wholeOffsetScore == 0 && !offsetState.singleOffsetScore) {
    message.warn("请设置分数变化");
    return;
  }

  isTraining.value = true;
  let url: string;
  let faultInfo = allFaultNames.value.find((t: any) => t.column === selectedFaultNameValue.value);
  let params = {
    machineId: sensorInfo.value.machineId,
    sensorId: sensorInfo.value.id,
    srcScore: scoreData.map(t => t[selectedFaultNameValue.value]), //原始分数，[60,80]
    dateArray: scoreData.map(t => t.date),
    variance: 0, //分数变化
    tarFunc: faultInfo.function,  //目标函数，brg_D_05,S40_Score
    funcTag: faultInfo.column, //函数标签S39,T53
    trainIndex: 0,
    isSingle: false
  }
  if (offsetState.wholeOffsetScore) {
    // 多阈值指标
    url = "/scoreModel/score/multi-threshold"
    params.variance = offsetState.wholeOffsetScore;
    params.isSingle = false;
  } else {
    // 单阈值指标
    url = "/scoreModel/score/single-threshold"
    params.variance = offsetState.singleOffsetScore!.offsetScore;
    params.trainIndex = selectedChartDataIndex!;
    params.isSingle = true;
  }

  try {
    let result = await request.post(url, params);
    message.success("训练成功!");
    if (result?.data) {
      updateTrainedData(faultInfo.column, params.isSingle, result.data.dateArray, result.data.result || [])
    }
  } catch (err) {
    throw err;
  } finally {
    isTraining.value = false;
  }

}

let isApplyModelLoading = ref(false);
const onClickApplyModel = async () => {

  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    return;
  }

  if (!trainedParamGroups.value || trainedParamGroups.value.length == 0) {
    message.warn("请先训练模型参数");
    return
  }

  if (selectedParamGroupIndex.value == null || selectedParamGroupIndex.value < 0) {
    message.warn("请选择训练模型参数");
    return;
  }

  let paramGroup = trainedParamGroups.value[selectedParamGroupIndex.value];
  if (!paramGroup) {
    message.warn("请先训练模型参数");
    return;
  }

  isApplyModelLoading.value = true;

  let body = {
    sensorId: sensorInfo.value.id,
    isExpert: paramGroup.faultName[0] === "T",
    params: paramGroup.params,
  }

  try {
    let result = await request.patch("/api/machines/threshold", body);
    if (result && result.status === 200) {
      message.success("保存模型参数成功!");
    } else {
      message.error((result as any)?.message || "系统错误");
    }
  } catch (err) {
    throw err;
  } finally {
    isApplyModelLoading.value = false;
  }
};

let isSaveModelLoading = ref(false);
const onClickSaveModel = async () => {
  let dateQuery = getQueryDate();

  isSaveModelLoading.value = true;

  if (!selectedFaultNameValue.value) {
    message.warn("请选择指标");
    isSaveModelLoading.value = false;
    return;
  }

  if (offsetState.wholeOffsetScore == 0 && !offsetState.singleOffsetScore) {
    message.warn("请设置加分");
    isSaveModelLoading.value = false;
    return;
  }

  let modelParams = null;
  if (selectedParamGroupIndex.value >= 0) {
    let paramGroup = trainedParamGroups.value[selectedParamGroupIndex.value];
    if (paramGroup) modelParams = paramGroup.params;
  }

  let faultInfo = allFaultNames.value.find((t: any) => t.column === selectedFaultNameValue.value);
  let body = {
    factoryId: sensorInfo.value.factoryId,
    machineId: sensorInfo.value.machineId,
    machineType: sensorInfo.value.machineTypeId,
    sensorIds: [sensorInfo.value.id],
    faultName: selectedFaultNameValue.value,
    faultTitle: faultInfo?.title,
    wholeOffsetScore: offsetState.wholeOffsetScore, // 整体加分
    singleOffsetScore: offsetState.singleOffsetScore, // 单点加分
    modelParams: modelParams,
    begin: dateQuery.begin,
    end: dateQuery.end,
  };
  try {
    let result = await request.post("/api/quota/vibration-model", body);
    if (result) {
      message.success("保存模板成功!");
    }
  } catch (err) {
    throw err;
  } finally {
    isSaveModelLoading.value = false;
  }
}

let selectedFaultNameValue: any = ref('');
const onSelectFaultName = async (e: Event) => {
  //selectedFaultNameValue.value = (e?.target as any)?.value;
  //console.log('select fault name', e);
  let value = (e?.target as any)?.value;
  onChangeFaultName(value)
}

// 专业指标中的单指标集合
let singleExpertFaultNames = ["T8",
  "T14",
  "T15",
  "T16",
  "T17",
  "T18",
  "T19",
  "T20",
  "T21",
  "T22",
  "T23",
  "T30",
  "T35",
  "T39",
  "T40",
  "T48",
  "T49",
  "T51",
  "T54",
  "T62",
  "T63"];
const onChangeFaultName = async (value: string) => {
  if (!value) {
    allowAddSingle.value = false;
    allowAddWhole.value = false;
  }
  if (value && value[0] === "S" || singleExpertFaultNames.includes(value)) {
    allowAddSingle.value = true;
    allowAddWhole.value = false;
  } else {
    allowAddSingle.value = false;
    allowAddWhole.value = true;
  }
  await loadSensorVibrationModel();
  await quotaSearch();
}

const loadSensorVibrationModel = async () => {
  let config: any = {
    params: {
      skip: 1, take: 1,
      sensorId: sensorInfo.value.id,
      faultName: selectedFaultNameValue.value,
      factoryId: localStorage.getItem("factory_id"),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/vibration-model", config);
  if (result && result.data?.list?.length > 0) {
    let model = result.data.list[0];
    if (model.singleOffsetScore) {
      form.value.singleOffsetScore = Math.abs(model.singleOffsetScore.offsetScore || 0);
      form.value.singleOffsetScoreOperator = model.singleOffsetScore?.offsetScore < 0 ? SCORE_OPERATOR.minus : SCORE_OPERATOR.plus;
    } else {
      selectedChartDataIndex = 0;
      selectedChartDataDate = "";
    }
    form.value.wholeOffsetScore = Math.abs(model.wholeOffsetScore || 0);
    form.value.wholeOffsetScoreOperator = model.wholeOffsetScore < 0 ? SCORE_OPERATOR.minus : SCORE_OPERATOR.plus;
    offsetState = {
      wholeOffsetScore: model.wholeOffsetScore,
      singleOffsetScore: model.singleOffsetScore
    }
  } else {
    form.value.wholeOffsetScore = 0;
    form.value.singleOffsetScore = 0;
    offsetState = {
      wholeOffsetScore: 0,
      singleOffsetScore: null
    }
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

const selectMenu = (type: any) => {
  menuSelect.value = type;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

let data = ref([]);

let selectUnUseSensor = () => {
};

let selectUseSensor = () => {
};

let machineTypes: any = ref([]);

class FaultNameItem {
  column: string
  title: string
}

const faultNames: any = ref(([] as FaultNameItem[]));
const faultNameExpects: any = ref(([] as FaultNameItem[]));
const allFaultNames: any = ref(([] as FaultNameItem[]));
let isLoadingFaultNames: boolean = false;
const loadFaultNames = async (sensorId: string) => {
  isLoadingFaultNames = true;
  let config: any = {
    params: { sensorId: sensorId },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("api/sensors/faultnames", config);
  isLoadingFaultNames = false;
  if (result && result.data) {
    faultNames.value = result.data.faultNames || [];
    faultNameExpects.value = result.data.faultNameExpects || [];
    allFaultNames.value = [...faultNames.value, ...faultNameExpects.value];

    if (allFaultNames.value.findIndex((t: any) => t.column === selectedFaultNameValue.value) > -1) {
    } else {
      selectedFaultNameValue.value = allFaultNames.value[0].column;
    }
  } else {
    selectedFaultNameValue.value = null;
  }
  await onChangeFaultName(selectedFaultNameValue.value);
};

const tempColumns = [
  {
    title: "模板号",
    dataIndex: "version",
    width: "60px"
  },
  {
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["设备类型"],
    dataIndex: "machineTypeName",
  },
  {
    title: langObj.value["指标"],
    dataIndex: "faultTitle",
    minWidth: "130px"
  },
  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];

let loadMachineTypes = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machine-type/all", config);
  if (result) {
    machineTypes.value = result.data;
  }
};


let tempModelQuery = ref({
  date: null,
  begin: null,
  end: null,
  machineTypeId: null,
  faultName: null,
});

let loadVibrationModelList = async () => {
  if (tempModelQuery.value.date) {
    tempModelQuery.value.begin = tempModelQuery.value.date[0].$d;
    tempModelQuery.value.end = tempModelQuery.value.date[1].$d;
  }

  let config: any = {
    params: {
      machineType: tempModelQuery.value.machineTypeId,
      faultName: tempModelQuery.value.faultName,
      factoryId: localStorage.getItem("factory_id"),
      begin: tempModelQuery.value.begin,
      end: tempModelQuery.value.end,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get<any>("/api/quota/vibration-model", config);
  if (result) {
    data.value = result.data || [];
  }
};

let deleteVibrationModel = async (record: any) => {
  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.delete("/api/quota/vibration-model", config).then((res) => {
    if (res) {
      message.success("操作成功");
      loadVibrationModelList();
    } else {
    }
  });
};

let loadBingInfos = async (record: any) => {
  selectSimpleModel.value = record;

  const config = {
    params: { id: record.id },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/quota/vibration-model/bind-infos", config);
  if (result) {
    const groupedData: any = {};
    const groupedDataUn: any = {};

    result.data.binds.map((item: any) => {
      if (!groupedData[item.groupId]) {
        groupedData[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedData[item.groupId].machines[item.machineId]) {
        groupedData[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedData[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    useSensorIds.value = result.data.binds.map((p: any) => p.sensorId);
    useSensorIdsOld.value = useSensorIds.value;

    const treeData = Object.keys(groupedData).map((groupId) => ({
      key: groupId,
      title: groupedData[groupId].groupName,
      checkable: false,

      children: Object.keys(groupedData[groupId].machines).map((machineId) => ({
        key: machineId,
        title: groupedData[groupId].machines[machineId].machineName,
        checkable: false,
        children: groupedData[groupId].machines[machineId].sensors,
      })),
    }));

    useSensors.value = treeData;

    result.data.unBinds?.map((item: any) => {
      if (!groupedDataUn[item.groupId]) {
        groupedDataUn[item.groupId] = {
          groupName: item.groupName,
          machines: {},
        };
      }

      if (!groupedDataUn[item.groupId].machines[item.machineId]) {
        groupedDataUn[item.groupId].machines[item.machineId] = {
          machineName: item.machineName,
          sensors: [],
        };
      }

      groupedDataUn[item.groupId].machines[item.machineId].sensors.push({
        key: item.sensorId,
        title: item.sensorPlace,
      });
    });

    const treeDataUn = Object.keys(groupedDataUn).map((groupId) => ({
      key: groupId,
      title: groupedDataUn[groupId].groupName,
      checkable: false,
      children: Object.keys(groupedDataUn[groupId].machines).map(
          (machineId) => ({
            key: machineId,
            checkable: false,
            title: groupedDataUn[groupId].machines[machineId].machineName,
            children: groupedDataUn[groupId].machines[machineId].sensors,
          })
      ),
    }));

    unUseSensorIds.value = [];
    unUseSensors.value = treeDataUn;
  }
};

let submitUnUseModel = async () => {
  if (unUseSensorIds.value.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/vibration-model",
        { id: selectSimpleModel.value.id, sensorIds: unUseSensorIds.value },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      await loadBingInfos(selectSimpleModel.value);
    }
  }
};

let submitReplaceUseModel = async () => {
  let diff = useSensorIds.value
      .concat(useSensorIdsOld.value)
      .filter(
          (item: any) =>
              !useSensorIds.value.includes(item) ||
              !useSensorIdsOld.value.includes(item)
      );
  if (diff.length > 0) {
    const config = {
      headers: {
        requestId: uuidv4(),
      },
    };
    let result = await request.put(
        "/api/quota/vibration-model/un-bind",
        { id: selectSimpleModel.value.id, sensorIds: diff },
        config
    );

    if (result && result.data) {
      message.success("操作成功");
      loadBingInfos(selectSimpleModel.value);
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_tree = _resolveComponent("a-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 1 ? '2px solid #00FFF4' : ''}]),
              class: "py-3 cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (selectMenu(1)))
            }, _toDisplayString(_unref(langObj)["配置模型"]) + "配置模型 ", 5),
            _createElementVNode("div", {
              style: _normalizeStyle([{"font-size":"18px"}, { borderBottom: _unref(menuSelect) === 2 ? '2px solid #00FFF4' : ''}]),
              class: "py-3 ml-12 cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMenu(2)))
            }, _toDisplayString(_unref(langObj)["模型管理"]) + "模型管理 ", 5)
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  style: _normalizeStyle([{"width":"250px"}, { top: _unref(scrollTop) }])
                }, [
                  _createVNode(machineListLeftComponent, {
                    onSelectSensor: selectSensor,
                    type: 2
                  })
                ], 4)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _cache[22] || (_cache[22] = _createElementVNode("span", null, "健康指标：", -1)),
                    _createVNode(_component_a_radio_group, {
                      name: "faultRadioGroup",
                      value: _unref(selectedFaultNameValue),
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_isRef(selectedFaultNameValue) ? (selectedFaultNameValue).value = $event : selectedFaultNameValue = $event)),
                      onChange: onSelectFaultName
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(faultNameExpects.value, (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_radio, {
                            value: item.column,
                            key: index
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)[item.title]), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"]),
                    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _cache[24] || (_cache[24] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _cache[25] || (_cache[25] = _createElementVNode("span", null, "振动指标：", -1)),
                    _createVNode(_component_a_radio_group, {
                      name: "faultExpectRadioGroup",
                      value: _unref(selectedFaultNameValue),
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => (_isRef(selectedFaultNameValue) ? (selectedFaultNameValue).value = $event : selectedFaultNameValue = $event)),
                      onChange: onSelectFaultName
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(faultNames.value, (item, index) => {
                          return (_openBlock(), _createBlock(_component_a_radio, {
                            value: item.column,
                            key: index
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)[item.title]), 1)
                            ]),
                            _: 2
                          }, 1032, ["value"]))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["value"]),
                    _cache[26] || (_cache[26] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _cache[27] || (_cache[27] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '1' }]),
                        style: _normalizeStyle([{
                          background:
                            _unref(simpleSearchModel).type == '1' ? '#072498' : '',
                        }, {"border-radius":"5px 0 0 5px"}]),
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(changeSimpleDateType)('1')))
                      }, _toDisplayString(_unref(langObj)["近一天"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '2' }]),
                        style: _normalizeStyle({
                          background:
                            _unref(simpleSearchModel).type == '2' ? '#072498' : '',
                        }),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(changeSimpleDateType)('2')))
                      }, _toDisplayString(_unref(langObj)["近一周"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '3' }]),
                        style: _normalizeStyle({
                          background:
                            _unref(simpleSearchModel).type == '3' ? '#072498' : '',
                        }),
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(changeSimpleDateType)('3')))
                      }, _toDisplayString(_unref(langObj)["近一月"]), 7),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn2 px-3 py-1", { 'text-white theme-datesetected': _unref(simpleSearchModel).type == '0' }]),
                        style: _normalizeStyle([{
                          background:
                            _unref(simpleSearchModel).type == '0' ? '#072498' : '',
                        }, {"border-radius":"0 5px 5px 0"}]),
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(simpleSearchModel).type = '0'))
                      }, _toDisplayString(_unref(langObj)["自定义"]), 7)
                    ]),
                    (_unref(simpleSearchModel).type == '0')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createVNode(_component_a_range_picker, {
                            value: _unref(simpleSearchModel).date,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_unref(simpleSearchModel).date) = $event)),
                            "disabled-date": disabledDate,
                            onCalendarChange: onCalendarChange
                          }, null, 8, ["value"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_unref(quotaSearch) && _unref(quotaSearch)(...args))),
                      class: "btn3 ml-2"
                    }, _toDisplayString(_unref(langObj)["查询"]), 1),
                    _cache[28] || (_cache[28] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ]),
                  _cache[29] || (_cache[29] = _createElementVNode("div", { class: "w-full flex items-center mt-4" }, [
                    _createElementVNode("div", {
                      id: "chart1",
                      style: {"width":"100%","height":"322px"}
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createVNode(_component_a_form, {
                        name: "basic",
                        autocomplete: "off"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, {
                            gutter: 24,
                            class: "text-white"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 4 }),
                              _createVNode(_component_a_col, { span: 4 }, {
                                default: _withCtx(() => _cache[30] || (_cache[30] = [
                                  _createElementVNode("span", null, "整体修改：", -1)
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_a_col, { span: 6 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    style: {"width":"90px"},
                                    value: _unref(form).wholeOffsetScoreOperator,
                                    "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(form).wholeOffsetScoreOperator) = $event)),
                                    placeholder: _unref(langObj)['请选择']
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select_option, { value: "plus" }, {
                                        default: _withCtx(() => _cache[31] || (_cache[31] = [
                                          _createTextVNode("整体加")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_select_option, { value: "minus" }, {
                                        default: _withCtx(() => _cache[32] || (_cache[32] = [
                                          _createTextVNode("整体减")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_col, { span: 5 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "wholeOffsetScore" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _unref(form).wholeOffsetScore,
                                        "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_unref(form).wholeOffsetScore) = $event)),
                                        type: "number",
                                        min: "0"
                                      }, null, 8, ["value"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_col, { span: 5 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    disabled: !_unref(allowAddWhole),
                                    onClick: onClickAddWholeOffsetScore,
                                    class: "btn3 ml-2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(langObj)["计算"]) + "计算 ", 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, {
                            gutter: 24,
                            class: "text-white"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 4 }),
                              _createVNode(_component_a_col, { span: 4 }, {
                                default: _withCtx(() => _cache[33] || (_cache[33] = [
                                  _createElementVNode("span", null, "单点修改：", -1)
                                ])),
                                _: 1
                              }),
                              _createVNode(_component_a_col, { span: 6 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_select, {
                                    style: {"width":"90px"},
                                    value: _unref(form).singleOffsetScoreOperator,
                                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_unref(form).singleOffsetScoreOperator) = $event)),
                                    placeholder: _unref(langObj)['请选择']
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_select_option, { value: "plus" }, {
                                        default: _withCtx(() => _cache[34] || (_cache[34] = [
                                          _createTextVNode("单点加")
                                        ])),
                                        _: 1
                                      }),
                                      _createVNode(_component_a_select_option, { value: "minus" }, {
                                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                                          _createTextVNode("单点减")
                                        ])),
                                        _: 1
                                      })
                                    ]),
                                    _: 1
                                  }, 8, ["value", "placeholder"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_col, { span: 5 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_form_item, { name: "singleOffsetScore" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_a_input, {
                                        value: _unref(form).singleOffsetScore,
                                        "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_unref(form).singleOffsetScore) = $event)),
                                        type: "number",
                                        min: "0"
                                      }, null, 8, ["value"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_a_col, { span: 5 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    disabled: !_unref(allowAddSingle),
                                    onClick: onClickAddSingleOffsetScore,
                                    class: "btn3 ml-2"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(langObj)["计算"]) + "计算 ", 1)
                                    ]),
                                    _: 1
                                  }, 8, ["disabled"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_row, {
                            gutter: 24,
                            class: "mb-4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 8 }),
                              _createVNode(_component_a_col, { span: 16 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    class: "btn3",
                                    loading: _unref(isTraining),
                                    onClick: onClickTrainModel
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(langObj)["生成分数"]) + "生成分数 ", 1)
                                    ]),
                                    _: 1
                                  }, 8, ["loading"]),
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    loading: _unref(isApplyModelLoading),
                                    class: "btn3 ms-2",
                                    onClick: onClickApplyModel
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(langObj)["确认修改"]) + "确认修改 ", 1)
                                    ]),
                                    _: 1
                                  }, 8, ["loading"]),
                                  _createVNode(_component_a_button, {
                                    type: "primary",
                                    class: "btn3 ms-2",
                                    loading: _unref(isSaveModelLoading),
                                    onClick: onClickSaveModel
                                  }, {
                                    default: _withCtx(() => _cache[36] || (_cache[36] = [
                                      _createTextVNode(" 保存为模板 ")
                                    ])),
                                    _: 1
                                  }, 8, ["loading"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_a_radio_group, {
                        name: "trainedParamGroupRadio",
                        value: _unref(selectedParamGroupIndex),
                        "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => (_isRef(selectedParamGroupIndex) ? (selectedParamGroupIndex).value = $event : selectedParamGroupIndex = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, {
                            gutter: 24,
                            class: "mb-4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_col, { span: 24 }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(trainedParamGroups), (paramGroup, idxGroup) => {
                                    return (_openBlock(), _createBlock(_component_a_radio, {
                                      style: {"margin-bottom":"0.5rem"},
                                      value: paramGroup.id,
                                      key: idxGroup
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(paramGroup.paramLabels, (label) => {
                                          return (_openBlock(), _createBlock(_component_a_tag, { color: "#2db7f5" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(label), 1)
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 256))
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ])
                  ])
                ])
              ])
            ])
          ], 512), [
            [_vShow, _unref(menuSelect) == 1]
          ]),
          _withDirectives(_createElementVNode("div", _hoisted_19, [
            _createVNode(_component_a_row, { gutter: 24 }, {
              default: _withCtx(() => [
                _createVNode(_component_a_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", null, [
                        _createVNode(_component_a_form, {
                          model: _unref(quotaSearch),
                          name: "horizontal_login",
                          layout: "inline",
                          autocomplete: "off"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: _unref(langObj)['时间'],
                              name: "date"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_range_picker, {
                                  style: {"width":"230px"},
                                  value: _unref(tempModelQuery).date,
                                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_unref(tempModelQuery).date) = $event)),
                                  "disabled-date": disabledDate,
                                  onCalendarChange: onCalendarChange
                                }, null, 8, ["value"])
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_a_form_item, {
                              label: _unref(langObj)['设备类型'],
                              name: "machineType"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  style: {"width":"250px"},
                                  value: _unref(tempModelQuery).machineTypeId,
                                  "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_unref(tempModelQuery).machineTypeId) = $event)),
                                  allowClear: "",
                                  placeholder: _unref(langObj)['请选择']
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineTypes), (item, index) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: index,
                                        value: item.id
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.FirstCategory +
                              "/" +
                              item.SecondCategory +
                              "/" +
                              item.ThirdCategory), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["value", "placeholder"])
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_a_form_item, {
                              label: _unref(langObj)['指标'],
                              name: "faultName"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  style: {"width":"200px"},
                                  value: _unref(tempModelQuery).faultName,
                                  "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_unref(tempModelQuery).faultName) = $event)),
                                  allowClear: "",
                                  placeholder: _unref(langObj)['请选择']
                                }, {
                                  default: _withCtx(() => [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allFaultNames.value, (item, index) => {
                                      return (_openBlock(), _createBlock(_component_a_select_option, {
                                        key: index,
                                        value: item.column
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_unref(langObj)[item.title]), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["value"]))
                                    }), 128))
                                  ]),
                                  _: 1
                                }, 8, ["value", "placeholder"])
                              ]),
                              _: 1
                            }, 8, ["label"]),
                            _createVNode(_component_a_form_item, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("div", {
                                    onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_unref(loadVibrationModelList) && _unref(loadVibrationModelList)(...args))),
                                    class: "btn3 px-5 py-1 fff cursor-pointer ml-4",
                                    style: {"background-color":"#072499","color":"white"}
                                  }, _toDisplayString(_unref(langObj)["查询"]), 1)
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["model"])
                      ]),
                      _createVNode(_component_a_table, {
                        style: {"width":"100%","height":"calc(100% - 70px)","overflow-y":"auto"},
                        size: "small",
                        bordered: "",
                        "row-class-name": getRowClassName,
                        columns: tempColumns,
                        "data-source": _unref(data),
                        pagination: false
                      }, {
                        bodyCell: _withCtx(({ column, text, record }) => [
                          (column.dataIndex === 'act')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("a", {
                                  style: {"color":"#00fff4 !important","margin-right":"5px"},
                                  onClick: ($event: any) => (_unref(loadBingInfos)(record))
                                }, _toDisplayString(_unref(langObj)["查看"]), 9, _hoisted_22),
                                _createVNode(_component_a_popconfirm, {
                                  title: _unref(langObj)['确定删除此条数据么'] + '?',
                                  "ok-text": _unref(langObj)['确定'],
                                  "cancel-text": _unref(langObj)['取消'],
                                  onConfirm: ($event: any) => (_unref(deleteVibrationModel)(record))
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("a", _hoisted_23, _toDisplayString(_unref(langObj)["删除"]), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["title", "ok-text", "cancel-text", "onConfirm"])
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["data-source"])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 6 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_24, [
                      _cache[38] || (_cache[38] = _createElementVNode("div", { class: "flex-1 text-center" }, "未使用模板的设备", -1)),
                      _createElementVNode("div", _hoisted_25, [
                        (_unref(unUseSensors).length)
                          ? (_openBlock(), _createBlock(_component_a_tree, {
                              key: 0,
                              checkedKeys: _unref(unUseSensorIds),
                              "onUpdate:checkedKeys": _cache[19] || (_cache[19] = ($event: any) => (_isRef(unUseSensorIds) ? (unUseSensorIds).value = $event : unUseSensorIds = $event)),
                              checkable: "",
                              "tree-data": _unref(unUseSensors),
                              onCheck: _unref(selectUnUseSensor),
                              defaultExpandAll: ""
                            }, {
                              title: _withCtx(({ title, key }) => [
                                _createTextVNode(_toDisplayString(title), 1)
                              ]),
                              _: 1
                            }, 8, ["checkedKeys", "tree-data", "onCheck"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          class: "btn3",
                          onClick: _unref(submitUnUseModel)
                        }, {
                          default: _withCtx(() => _cache[37] || (_cache[37] = [
                            _createTextVNode("使用模板 ")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_col, { span: 6 }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_27, [
                      _cache[40] || (_cache[40] = _createElementVNode("div", { class: "flex-1 text-center" }, "已使用模板的设备", -1)),
                      _createElementVNode("div", _hoisted_28, [
                        (_unref(useSensors).length)
                          ? (_openBlock(), _createBlock(_component_a_tree, {
                              key: 0,
                              checkedKeys: _unref(useSensorIds),
                              "onUpdate:checkedKeys": _cache[20] || (_cache[20] = ($event: any) => (_isRef(useSensorIds) ? (useSensorIds).value = $event : useSensorIds = $event)),
                              checkable: "",
                              "tree-data": _unref(useSensors),
                              onCheck: _unref(selectUseSensor),
                              defaultExpandAll: ""
                            }, {
                              title: _withCtx(({ title, key }) => [
                                _createTextVNode(_toDisplayString(title), 1)
                              ]),
                              _: 1
                            }, 8, ["checkedKeys", "tree-data", "onCheck"]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _createVNode(_component_a_button, {
                          type: "primary",
                          class: "btn3",
                          onClick: _unref(submitReplaceUseModel)
                        }, {
                          default: _withCtx(() => _cache[39] || (_cache[39] = [
                            _createTextVNode("替换模板 ")
                          ])),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _unref(menuSelect) == 2]
          ])
        ])
      ])
    ])
  ]))
}
}

})