<template>
  <div class="theme-common">
    <a-layout-content style="padding: 0 20px; height: 100%">
      <a-row type="flex" style="height: 100%">
        <a-col :span="5" :order="1" style="height: 100%">
          <div class="w-64 px-2 overflow-y-auto scroll-box h-screen code-box" style="height: 100%">
            <a-input-search v-model:value="filterKeyWord" style="margin-bottom: 8px"
                            placeholder="输入区域或设备名称筛选"/>
            <a-tree class="draggable-tree" draggable block-node :autoExpandParent="autoExpandParent"
                    :expanded-keys="expandedKeys" :tree-data="treeData" @expand="onExpand" @select="selectTree">
              <template #title="{ title }">
                        <span v-if="title.indexOf(filterKeyWord) > -1">
                        {{ title.substr(0, title.indexOf(filterKeyWord)) }}
                        <span style="color: #f50">{{ filterKeyWord }}</span>
                        {{
                            title.substr(
                                title.indexOf(filterKeyWord) + filterKeyWord.length
                            )
                          }}
                        </span>
                <span v-else>{{ title }}</span>
              </template>
            </a-tree>
          </div>
        </a-col>
        <a-col v-if="currentMachine" :span="19" :order="2" style="padding-bottom: 20px;">
          <div class="flex mb-3 items-center">
            <div v-if="currentMachine" class="freqx_formTitle">{{ currentMachine?.machineName }}</div>
            <div class="flex-1"></div>
            <div class="flex" style="text-align: right;justify-content: flex-end">

              <a-button type="primary" @click="submit">保存</a-button>
            </div>
          </div>

          <a-row class="mb-2">
            <a-checkbox :checked="isSingle" @change="selectSingle">单侧点</a-checkbox>
          </a-row>

          <a-row class="mb-2">
            <a-form :model="onlineConfig" name="horizontal_single" layout="inline" autocomplete="off">
              <a-form-item label="开关机测点" name="sensorId">
                <a-select v-model:value="onlineConfig.sensorId"
                          style="min-width: 200px" placeholder="请选择测点" allowClear>
                  <a-select-option v-for="(item, index) in sensorList" :key="index" :value="item.id">
                    {{ item.sensorPlace }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="数据类型" name="dataType">
                <a-select v-model:value="onlineConfig.dataType"
                          style="min-width: 200px" placeholder="请选择数据类型" allowClear>
                  <a-select-option v-for="(item, index) in dataTypeList" :key="index" :value="item.value">
                    {{ item.label }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item label="间隔（秒）" name="interval">
                <a-input-number style="min-width: 100px;" v-model:value="onlineConfig.interval" :controls="false"/>
              </a-form-item>

              <a-form-item label="阈值" name="threshold">
                <a-input-number style="min-width: 100px;" v-model:value="onlineConfig.threshold" :controls="false"/>
              </a-form-item>
            </a-form>

          </a-row>

          <a-row class="mb-2">
            <a-checkbox :checked="isGroup" @change="selectGroup">半数及以上</a-checkbox>
          </a-row>

          <a-row class="mb-2">
            <a-form :model="onlineConfig" name="horizontal_multi" layout="inline" autocomplete="off">
              <a-form-item label="距首次获取数据延迟（秒）" name="groupDelay">
                <a-input-number style="min-width: 100px;" v-model:value="onlineConfig.groupDelay" :controls="false"/>
                <span class="text-white">秒统计</span>
              </a-form-item>

              <a-form-item label="间隔（秒）" name="groupInterval">
                <a-input-number style="min-width: 100px;" v-model:value="onlineConfig.groupInterval" :controls="false"/>
              </a-form-item>
            </a-form>
          </a-row>

          <a-row class="mb-2">
            <span>开关机测点阈值</span>
          </a-row>

          <a-row class="mb-1" v-for="(item, index) in onlineConfig.groupSensorThresholds">
            <a-col :span="4">
              <label>
                <node-index-outlined/>
                {{ item.sensorPlace }}：</label>
            </a-col>
            <a-col>
              <a-input-number style="min-width: 100px;" v-model:value="item.threshold" :controls="false"/>
            </a-col>

          </a-row>
        </a-col>
      </a-row>
    </a-layout-content>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { NodeIndexOutlined } from "@ant-design/icons-vue"

class SensorThreshold {
  sensorId: string = "";
  sensorPlace: string = "";
  threshold: number = 0;
}

class MachineOnlineConfig {
  _id: string = "";
  type: "single" | "group" = "group";
  machineId: string = "";
  sensorId: string = "";
  dataType: string = "";
  interval: number = 300;
  threshold: number = 0;
  groupDelay: number = 120;
  groupInterval: number = 300;
  groupSensorThresholds: SensorThreshold[] = [];
}

let currentMachine = ref<any>();
const selectTree = async (key: any, val: any) => {
  if (val.node.type == "machine") {
    currentMachine.value = val.node;
    await getMachineOnlineConfig();
  }
};

let isSingle = computed(() => {
  return !onlineConfig.value.type || onlineConfig.value.type == "single";
});
let isGroup = computed(() => {
  return onlineConfig.value.type == "group";
});

function selectSingle(e: any) {
  if (e.target.checked) {
    onlineConfig.value.type = "single";
  } else {
    onlineConfig.value.type = "group";
  }
}

function selectGroup(e: any) {
  if (e.target.checked) {
    onlineConfig.value.type = "group";
  } else {
    onlineConfig.value.type = "single";
  }
}

let filterKeyWord = ref<any>();
let treeData = ref<any>();

const onExpand = (keys: string[]) => {
  expandedKeys.value = keys;
  autoExpandParent.value = false;
};
const expandedKeys = ref<(string | number)[]>([]);
const autoExpandParent = ref<boolean>(true);

watch(filterKeyWord, () => {
  expandedKeys.value = [];
  sonKey(treeData.value);
  autoExpandParent.value = true;
});
const sonKey = (item: any) => {
  item.forEach((element: any) => {
    if (element.title.indexOf(filterKeyWord.value) > -1) {
      // expandedKeys.value.push(key);
      expandedKeys.value.push(element.key);
    }
    if (element.children && element.children.length) {
      sonKey(element.children);
    }
  });
};
const getParentKey = (
    key: string | number,
    tree: any
): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (
          node.children.some((item: { key: string | number }) => item.key === key)
      ) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};


let factoryList = ref<any>();
let allFactory: any = ref([])
const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId)
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id)
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })

      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let res = await request.post("/api/machines/all", { factoryIds })
  factoryList.value.forEach((element: any) => {
    if (res) {
      element.children.forEach((sonFact: any) => {
        if (sonFact.type == "factory" && sonFact.children) {
          sonFact.children.forEach((groupFact: any) => {
            if (groupFact.type == "group") {
              groupFact.children = res.data
                  .filter((t: any) => t.groupId == groupFact.value)
                  .map((t: any) => ({
                    type: "machine",
                    ...t,
                    value: t.id,
                    label: t.machineName,
                    title: t.machineName,
                    key: t.id,
                  }));
            }
          });
        } else if (sonFact.type == "group") {
          sonFact.children = res.data
              .filter((t: any) => t.groupId == sonFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
        }
      });
    }
  });
  setTimeout(() => {
    treeData.value = factoryList.value;
  }, 100);
};

let onlineConfig = ref<MachineOnlineConfig>(new MachineOnlineConfig())
let sensorList: any = ref([])
let dataTypeList: any = ref([])

onMounted(() => {
  init()
})

const init = () => {
  getDatatype()
  let requestId = uuidv4();
  loadFactory(requestId);
}

const getDatatype = () => {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/sensors/datatype", config).then((res: any) => {
    if (res) {
      let list: any = res.data.map((t: any) => ({
        value: t.name,
        label: t.name,
      }));

      dataTypeList.value = list.concat([{ label: '原始波形', value: '原始波形' }])
    }
  });
};

const getMachineOnlineConfig = async () => {
  if (!currentMachine.value) return
  sensorList.value = []

  const config = {
    params: {
      machineId: currentMachine.value.id
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let resultSensor = await request.get('/api/sensors', config)
  if (resultSensor?.data?.length > 0) {
    sensorList.value = resultSensor.data
  }

  let result = await request.get('/api/machine-online-config/info', config)
  if (result && result.data) {
    onlineConfig.value = result.data
  } else {
    onlineConfig.value = new MachineOnlineConfig()
    onlineConfig.value.machineId = currentMachine.value.id;
  }

  if (!onlineConfig.value.groupSensorThresholds) {
    onlineConfig.value.groupSensorThresholds = [];
  }

  let ss: SensorThreshold[] = onlineConfig.value.groupSensorThresholds || [];
  for (const sensor of sensorList.value) {
    let sensorThreshold = onlineConfig.value.groupSensorThresholds?.find(t => t.sensorId == sensor.id)
    if (sensorThreshold) {
      sensorThreshold.sensorPlace = sensor.sensorPlace
      if (!sensorThreshold.threshold) {
        sensorThreshold.threshold = sensor.defaultPowerOnThreshold || 0;
      }
    } else {
      ss.push({
        sensorId: sensor.id,
        sensorPlace: sensor.sensorPlace,
        threshold: sensor.defaultPowerOnThreshold || 0
      });
    }
  }

  onlineConfig.value.groupSensorThresholds = ss.filter(t => sensorList.value.find((s: any) => s.id == t.sensorId))

}

const submit = async () => {
  let result = onlineConfig.value._id
      ? await request.put('/api/machine-online-config', onlineConfig.value)
      : await request.post('/api/machine-online-config', onlineConfig.value)
  if (result && result.data) {
    message.success('操作成功!')
    await getMachineOnlineConfig()
  } else {
    message.warning('操作失败，请联系管理员')
  }
}


</script>

<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.btn {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0px 16px;
  border: 1px solid #0d53b7cc;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
  max-height: 100%;
}


</style>
