import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "mt-4 fff" }
const _hoisted_3 = { class: "mt-4 fff" }
const _hoisted_4 = {
  class: "box",
  style: {"color":"#fff"}
}
const _hoisted_5 = {
  key: 0,
  class: "span-1"
}
const _hoisted_6 = {
  key: 1,
  class: "span-1"
}

import { ref, watch, onMounted } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from 'ant-design-vue';
import * as echarts from 'echarts';
import request from "../../../common/request";
import { Dayjs } from 'dayjs';


export default /*@__PURE__*/_defineComponent({
  __name: 'select-score',
  props: {
    machineInfo:null,
},
  setup(__props) {

const props:any = __props

watch(() => props.machineInfo, (newValue, oldValue) => {
    init()
})

let typeList = ref([
    {label: '高频', value: 'hz'},
    {label: '低频X', value: 'lx'},
    {label: '低频Y', value: 'ly'},
    {label: '低频Z', value: 'lz'},
])
let quotaList1 = [
    { label: '0~0.3KHz均方根', value: 'Trms_300',   },
    { label: '0~1KHz均方根', value: 'Trms_1000',  },
    { label: '1K~10KHz均方根', value: 'Trms_1000_10000',  },
    { label: '10K~25.6KHz均方根', value: 'Trms_10000_25600',  },
    { label: '1K~25.6KHz均方根', value: 'Trms_1000_25600',  },
    { label: '0~25.6KHz均方根', value: 'Trms',  },
    { label: '优化峭度01', value: 'TLinKurt' , },
    { label: '平稳冲击烈度', value: 'TImpactIntensity',  },
    { label: '线性峭度02', value: 'Tlin_kurt',  },
    { label: '峭度因子', value: 'Tkurt',  },
    { label: '峰峰值', value: 'Tff', },
    { label: '优化峰值', value: 'TAccelerationPeak',  },
    { label: '优化偏度', value: 'Tlin_bias',  },
    { label: '速度均方根', value: 'TVelocityRMS',  },
    { label: '频率集中度', value: 'Tcon',  },
    { label: '0.3~1KHz均方根', value: 'Trms_300_1000',  },
    { label: '1K~3KHz均方根', value: 'Trms_1000_3000',  },
    { label: '3K~6KHz均方根', value: 'Trms_3000_6000' , },
    { label: '6K~12KHz均方根', value: 'Trms_6000_12000' , },
    { label: '12000~25600Hz均方根', value: 'Trms_12000_25600' , },
    { label: '6K~10KHz均方根', value: 'Trms_6000_10000' , },
    { label: '优化均方根', value: 'TAccelerationRMS',  },
    { label: '偏度因子', value: 'Tbias' , },
    { label: '裕度因子', value: 'Tmargin' , },
    { label: '峰值指标', value: 'Tpp' , },
    { label: '包络信息熵', value: 'Tent_e' , },
    { label: '包络幅值峭度', value: 'Tenv_kurt' , },
    { label: '脉冲因子', value: 'Tpulse',  },
    { label: '波形因子', value: 'Twave',  },
    { label: '均值频率', value: 'TF9', },
    { label: '主频带位置', value: 'Tmainf',  },
    { label: '时域信息熵', value: 'Tent_t',  },
    { label: '频域信息熵', value: 'Tent_f',  },
    { label: '电流指标', value: 'Tcurrent',  },
]

let quotaList2:any = ref([])


let formState:any = ref({
    machineName: '',
    sensorId: null,
    date: null,
    content: ''
})

let sensorList:any = ref([])

onMounted(() => {
    init()
})


const init = () => {
    if(!props.machineInfo || !props.machineInfo.id) {
        return
    }
    formState.value.machineName = props.machineInfo.machineName
    formState.value.quota = 'Trms_300'
    formState.value.type = 'hz'
    formState.value.typeIndex = 1
    getMachine()
    getQuotaList()
}

const getQuotaList = async() => {
    quotaList2.value = []
    const config = {
        params: { machineId: props.machineInfo.id},
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/machines/quota/names', config);
    console.log('result333333', result)
    if(result?.data?.length) {
        quotaList2.value = result.data.map((d:any) => ({label: d.value, value: d.value, name: d.name}))
    }
}


const getMachine = async() => {
    // const config = {
    //     params: { id: props.machineInfo.id},
    //     headers: {
    //         requestId: uuidv4(),
    //     },
    // };

    // let result = await request.get('/api/external/machines/workstatus/info', config);
    // statusInfo = result.data
    getSensorList()
}

const getSensorList = async() => {
    const config = {
        params: {machineId: props.machineInfo.id},
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/sensors', config)
    
    sensorList.value = result.data
    getData()
}

// 选择时间
const selectDate = (ev:any) => {
    getData()
}

// 选择通用指标
const selectQuota1 = (ev:any) => {
    console.log('ev', ev)
    typeList.value = [
        {label: '高频', value: 'hz'},
        {label: '低频X', value: 'lx'},
        {label: '低频Y', value: 'ly'},
        {label: '低频Z', value: 'lz'},
    ]
    let typeInfo = typeList.value.find((p:any) => p.value === formState.value.type)
    if(!typeInfo) {
        formState.value.type = 'hz'
    }
    formState.value.typeIndex = 1
    getData()
}

// 选择专业指标
const selectQuota2 = (ev:any) => {
    console.log('ev', ev)
    let quotaInfo = quotaList2.value.find((p:any) => p.value === formState.value.quota)
    console.log('quotaInfo', quotaInfo)
    typeList.value = [
        {label: quotaInfo.name, value: quotaInfo.name}
    ]
    formState.value.type = quotaInfo.name
    formState.value.typeIndex = 2
    getData()
}


// 获取图数据
const getData = () => {
    console.log('11111')
}






return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_form, {
      layout: 'inline',
      model: _unref(formState)
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, { label: "设备名称" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _unref(formState).machineName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(formState).machineName) = $event)),
              placeholder: "",
              readonly: true
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "传感器选择" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              ref: "sensorId",
              value: _unref(formState).sensorId,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(formState).sensorId) = $event)),
              style: {"width":"200px"},
              onChange: getData
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(sensorList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item.id
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.sensorPlace), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "日期范围" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_range_picker, {
              onChange: selectDate,
              value: _unref(formState).date,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_unref(formState).date) = $event)),
              "show-time": "",
              format: "YYYY-MM-DD HH:mm:ss",
              "disabled-date": _ctx.disabledDate,
              onCalendarChange: _ctx.onCalendarChange
            }, null, 8, ["value", "disabled-date", "onCalendarChange"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "类型" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select, {
              value: _unref(formState).type,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_unref(formState).type) = $event)),
              style: {"width":"200px"}
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(typeList), (item, index) => {
                  return (_openBlock(), _createBlock(_component_a_select_option, {
                    key: index,
                    value: item.value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { label: "内容" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              value: _unref(formState).content,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_unref(formState).content) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, { "wrapper-col": { } }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: _ctx.submit,
              class: "btn-submit"
            }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode("提交选择")
              ])),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"]),
    _createElementVNode("div", _hoisted_2, [
      _cache[8] || (_cache[8] = _createElementVNode("div", { class: "span-1" }, "通用", -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_a_radio_group, {
          value: _unref(formState).quota,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_unref(formState).quota) = $event)),
          "button-style": "solid",
          onChange: selectQuota1
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(quotaList1), (item, index) => {
              return (_openBlock(), _createBlock(_component_a_radio_button, {
                key: index,
                value: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "span-1" }, "专业", -1)),
      _createElementVNode("div", null, [
        _createVNode(_component_a_radio_group, {
          value: _unref(formState).quota,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(formState).quota) = $event)),
          "button-style": "solid",
          onChange: selectQuota2
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(quotaList2), (item, index) => {
              return (_openBlock(), _createBlock(_component_a_radio_button, {
                key: index,
                value: item.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      ( _unref(formState).model == '健康' )
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "健康诊断分数趋势图"))
        : _createCommentVNode("", true),
      ( _unref(formState).model == '振动' )
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, "振动指标诊断健康分数"))
        : _createCommentVNode("", true),
      _cache[10] || (_cache[10] = _createElementVNode("div", {
        id: "divInfoChart",
        style: {"width":"100%","height":"470px"}
      }, null, -1))
    ])
  ]))
}
}

})