<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full flex whiteBg" style="color: #fff;">
        <!-- 左侧 -->
        <div style="width: 270px;">
            <div class="w-full box p-2" style="width: 270px;">
                <a-radio-group v-model:value="searchModel.type" style="width: 100%;" @change="init">
                    <a-radio v-for="(item, index) in typeList" :key="index" style="display: block;" :value="item.value">{{ item.label }}</a-radio>
                </a-radio-group>
            </div>

            <div class="box mt-4" style="width: 270px;">
                <a-spin :spinning="loading">
                    <a-table 
                        style="width: 100%;" 
                        size="small" 
                        :row-class-name="getRowClassName" 
                        :columns="columns" 
                        :data-source="dataList"
                        :pagination="pagination"
                        @change="handleTableChange"
                        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                        rowKey="_id"
                    >
                        <template #bodyCell="{ column, text }">
                            <template v-if="column.dataIndex === 'opt'">
                                <a class="theme-table-link">{{langObj['下载']}}</a>
                            </template>
                            <template v-if="column.dataIndex === 'value'">
                                <span v-if="text || text==0"> {{ text.toFixed(2) }}</span>
                            </template>
                        </template>
                    </a-table> 
                </a-spin>
            </div>

        </div>

        <!-- 右侧 -->
        <div class="flex-1 box ml-4 p-3">
            <div class="w-full" v-for="(item, index) in chartList" :key="index">
                <div class="w-full flex items-center">
                    <!-- <span>设备1/测点1</span> -->
                    <div class="ml-4">{{ item.type }}</div>
                    <div class="ml-4">{{langObj['采样时间']}}：{{ item.date }}</div>
                </div>

                <div :id="'chart3A'+index" style="width: 100%; height: 350px;"></div>
            </div>

            <div class="w-full" v-for="(item, index) in chartList" :key="index">
                <div class="w-full flex items-center">
                    <!-- <span>设备1/测点1</span> -->
                    <div class="ml-4">{{ item.type }}</div>
                    <div class="ml-4">{{langObj['采样时间']}}：{{ item.date }}</div>
                </div>

                <div :id="'chart3B'+index" style="width: 100%; height: 350px;"></div>
            </div>


            
           
        </div>

    </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { transformDate2, transformDate, getChartDataTool } from "../../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()
 let typeList = [
  { label: langObj.value["高频加速度(长)"], value: 2, type: "TW", unit: "m/s²" },
  { label: langObj.value["高频加速度"], value: 1, type: "HZ", unit: "m/s²" },
  // {label : '温度', value: 2},
  { label: langObj.value["低频加速度X"], value: 3, type: "LX", unit: "m/s²" },
  { label: langObj.value["低频加速度Y"], value: 4, type: "LY", unit: "m/s²" },
  { label: langObj.value["低频加速度Z"], value: 5, type: "LZ", unit: "m/s²" },
  { label: langObj.value["速度X"], value: 6, type: "SX", unit: "mm/s" },
  { label: langObj.value["速度Y"], value: 7, type: "SY", unit: "mm/s" },
  { label: langObj.value["速度Z"], value: 8, type: "SZ", unit: "mm/s" },
];


const columns = [
{
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["值"],
    dataIndex: "value",
  },
];

let dataList:any = ref([])

const props:any = defineProps({
    sensorInfo: null,
});
let sensorInfo:any = ref()
sensorInfo.value = props.sensorInfo
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
    type: 1,
})
watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);

let chartList:any = ref([])

let selectedRowKeys = ref([])
const onSelectChange = (ev:any) => {
    selectedRowKeys.value = ev
    getChartData()
}

onMounted(() => {
    init()
})

const init = () => {
    if(!sensorInfo.value) return
    getTrendListAll()
}

//获取所有时间点数据
const getTrendListAll = async() => {
    dataList.value = []
    selectedRowKeys.value = []
    chartList.value = []
    let config: any = {
        params: {
            machineId: sensorInfo.value.machineId ,
            sensorId: sensorInfo.value.id ,
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            begin: transformDate(new Date('2021/01/01 00:00:00')),
            end: transformDate(new Date()),
            type: typeList.find((p:any) => p.value === searchModel.value.type)?.type
        },
        headers: {
            requestId: uuidv4(),
        },
    }; 

    let result = await request.get('/api/external/singles', config)

    if(result && result.data) {
        dataList.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

// 获取chart数据
let loading:any = ref(false)
const getChartData = async() => {
    loading.value = true
    chartList.value = []
    for(let index in selectedRowKeys.value) {
        let item = selectedRowKeys.value[index]
        let info = dataList.value.find((p:any) => p._id === item)

        let t = typeList.find((p:any) => p.value === searchModel.value.type)?.type

        let config: any = {
            params: {
                sensorId: info.sensorId ,
                date: info.date,
                type: t,
                latest: true
            },
            headers: {
                requestId: uuidv4(),
            },
        }; 

        let result = await request.get('/api/external/singles/info', config)
        if(!result || !result.data || !result.data.url) {
            return
        }

        let url = result.data.url
        // url = 'http://source.freqx.com/singles/632d1ec9735daf1ba76d4f9f/20231123/null_633434ab735daf1ba76d55e1_HZ_20231123_080000.Single'
        //幅值
        let resultTIME:any = await request.post('/draw/api/time-sort', {url:  [url]})
        let resultFFT:any = await request.post('/draw/api/fft-sort', {url:  [url]}) 
        let resultTIME2 = getChartDataTool(resultTIME?.data)
        let resultFFT2 = getChartDataTool(resultFFT?.data)

        let xDataTime = resultTIME2.axisX
        let yDataTime = resultTIME2.axisY

        let xDataFft = resultFFT2.axisX
        let yDataFft = resultFFT2.axisY

        chartList.value.push({
            type: typeList.find((p:any) => p.value === searchModel.value.type)?.label, 
            date: info.date, 
            timeData: {
                xData: xDataTime,
                yData: yDataTime,
            },
            fftData: {
                xData: xDataFft,
                yData: yDataFft,
            }
        })

        setTimeout(() => {
            initChart({xData: xDataTime,yData: yDataTime}, 'chart3A'+index, '时域图', 's', typeList.find((p:any) => p.value === searchModel.value.type)?.unit)
            initChart({xData: xDataFft,yData: yDataFft}, 'chart3B'+index, '幅值谱图', 'hz', typeList.find((p:any) => p.value === searchModel.value.type)?.unit) 
        }, 200);

        
    }
    loading.value = false
}




// 测试绘图
const initChart = (data:any, id:any, title:any, xUnit:any, yUnit:any) => {
    let {xData, yData} = data
    const ec = echarts as any;
    if(!document.getElementById(id)) return
    let myChartA = ec.init(document.getElementById(id));

    let option = {
        color: theme.value=='white'? ["#274CE0"]:["#00FFF4"],
        title: {
            text: title,
            textStyle: {
                color: '#fff'
            },
            left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
            top: '8%',
        },
        toolbox: {
          trigger: 'axis'
        },
        dataZoom: [{ type: 'inside' }],
        xAxis: {
            name: xUnit,
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              showMaxLabel: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.3
              }
            },
            data: xData
        },
        yAxis: {
            name: yUnit,
            type: 'value'
        },
        series: [
            {
                data: yData,
                type: 'line'
            }
        ]
    };

    myChartA && myChartA.setOption(option)
}

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const handleTableChange = (pagination: any) => {
    // 处理分页数据
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getTrendListAll();
};


</script>

<style lang="less" scoped>
/deep/ .ant-picker {
  background-color: #07249933 !important ;
}

.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
    width: 250px;
}

.btn1 {
    padding: 3px 10px;
}

/deep/ .ant-table {
    color: #fff
}
/deep/ .ant-table-thead .ant-table-cell {
    background-color: #020F46;
    color: #fff;
}

/deep/ table tbody tr:hover>td {
    background-color: #020F46 !important;
}

/deep/ .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: #020F46 !important;
}

/deep/ .ant-table-tbody > tr > td {
    transition: 0s;
}

/deep/ .ant-table-tbody > tr.ant-table-row-selected > td {
    background: transparent;
    border-color: #fff;
}

/deep/ .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
    background: #020F46;
}

/deep/ .ant-empty-description {
    color: #fff;
}

</style>
