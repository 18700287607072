import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, withCtx as _withCtx, renderList as _renderList, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "pdfCompany",
  class: "p-4",
  style: {"color":"#fff","background-color":"#020f46","border":"1px solid #0d53b7cc"}
}
const _hoisted_2 = { class: "w-full flex" }
const _hoisted_3 = { class: "w-full flex items-center" }
const _hoisted_4 = {
  class: "text-center flex-1",
  style: {"font-size":"24px"}
}
const _hoisted_5 = {
  key: 0,
  class: "mt-5",
  style: {"font-size":"16px"}
}
const _hoisted_6 = {
  key: 1,
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_7 = {
  class: "mt-10 mb-4",
  style: {"font-size":"16px"}
}
const _hoisted_8 = { class: "flex mt-4" }

import request from "../../../common/request";
import { ref, reactive, toRaw } from "vue";
import { v4 as uuidv4 } from "uuid";
import { getGroupName } from "../../../common/tools";
import { langList } from "../../../common/lang";
import htmlToPdf from '../../../common/htmlToPdf';
import { message } from 'ant-design-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'maintenanceReportDetails',
  emits: ["cancel"],
  setup(__props, { expose: __expose, emit: __emit }) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
let repairForm: any = ref({
  list: [],
});
let accuracyList = [
  { label: "健康", value: 0 },
  { label: "可用", value: 1 },
  { label: "警戒", value: 2 },
  { label: "故障", value: 3 },
  { label: "不确定", value: 4 },
];
let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];
let repairColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["故障描述"],
    dataIndex: "diagnosticNotes",
  },
];

const show = async (id: any) => {
  let config: any = {
    params: {
      id,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/reports/info", config);

  if (result && result.data) {
    repairForm.value = {
      begin: result.data.repairReport.begin,
      end: result.data.repairReport.end,
      maintainMessage: result.data.repairReport.maintainMessage,
      maintainPerson: result.data.repairReport.maintainPerson,
      machineName: result.data.machines[0].machineName,
      accuracy: accuracyList.find(
        (p: any) => p.value === result.data.repairReport.accuracy
      )?.label,
      list: result.data.repairReport.list,
      condition: result.data.repairReport.condition,
      faultCause: result.data.repairReport.faultCause,
      pictures: result.data.pictures,
    };
    if (result.data.machines.length) {
      repairForm.value.groupName = getGroupName(
        result.data.machines[0].groupId
      );
    }
  }
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

const cancel = () => {
  emits("cancel", true);
};

const emits = __emit;

__expose({
  show,
});
const resultReport = ref<any>({});

// 下载pdf
let loadingDown = ref(false)
const handleDown = async() => {
    loadingDown.value = true;	// 动画加载事件
    // 调用htmlToPdf工具函数
    let result = await htmlToPdf.getPdf('检修报告', 'a4' );
    console.log('result', result)
    loadingDown.value = false
    if(result) {
        message.success('下载成功')
    } else {
        message.warning('下载失败，请重试')
    }

}


return (_ctx: any,_cache: any) => {
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_download_outlined = _resolveComponent("download-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_image = _resolveComponent("a-image")!
  const _component_a_card_grid = _resolveComponent("a-card-grid")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
      _createElementVNode("div", {
        class: "cursor-pointer",
        onClick: cancel
      }, [
        _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(langObj)['检修记录']), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleDown())),
        "nz-button": "",
        nzType: "text",
        class: "ml-2"
      }, [
        _createVNode(_component_download_outlined, { style: {"font-size":"18px"} })
      ])
    ]),
    (_unref(repairForm).list?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(langObj)['故障信息']), 1))
      : _createCommentVNode("", true),
    (_unref(repairForm).list?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_a_table, {
            style: {"width":"100%"},
            pagination: false,
            size: "small",
            "row-class-name": getRowClassName,
            columns: _unref(repairColumns),
            "data-source": _unref(repairForm).list
          }, {
            bodyCell: _withCtx(({ column, text }) => [
              (column.dataIndex === 'condition')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === text)?.label]), 1)
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["columns", "data-source"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(langObj)['检修详情']), 1),
    _createVNode(_component_a_descriptions, {
      column: { xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 },
      labelStyle: { color: '#fff' }
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_descriptions_item, {
          labelStyle: { color: '#fff' },
          label: _unref(langObj)['开始时间']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(repairForm).begin), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_a_descriptions_item, {
          label: _unref(langObj)['结束时间']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(repairForm).end), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_a_descriptions_item, {
          label: _unref(langObj)['设备名称']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(repairForm).machineName), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_a_descriptions_item, {
          label: _unref(langObj)['设备区域']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(repairForm).groupName), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_a_descriptions_item, {
          label: _unref(langObj)['检修人员']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(repairForm).maintainPerson), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_a_descriptions_item, {
          label: _unref(langObj)['现场设备状态']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(langObj)[_unref(conditionList).find((p) => p.value === _unref(repairForm).condition)?.label]), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_a_descriptions_item, {
          label: _unref(langObj)['故障原因']
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_unref(repairForm).faultCause), 1)
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }),
    _createElementVNode("div", null, _toDisplayString(_unref(langObj)['检修内容']) + ":", 1),
    _createElementVNode("div", null, _toDisplayString(_unref(repairForm).maintainMessage), 1),
    _createElementVNode("div", null, _toDisplayString(_unref(langObj)['检修照片']) + ":", 1),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_a_card, {
        bordered: false,
        style: {"background-color":"rgb(2, 15, 70)"}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(repairForm).pictures, (item, index) => {
            return (_openBlock(), _createBlock(_component_a_card_grid, {
              bordered: false,
              style: {"width":"25%","text-align":"center","padding":"5px"},
              key: index
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_image, {
                  src: item.url
                }, null, 8, ["src"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }),
      _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1))
    ])
  ]))
}
}

})