import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-full flex whiteBg",
  style: {"color":"#fff"}
}
const _hoisted_2 = { style: {"width":"220px"} }
const _hoisted_3 = {
  class: "box",
  style: {"width":"220px"}
}
const _hoisted_4 = {
  key: 0,
  class: "theme-table-link"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "flex-1 box ml-4 p-3" }
const _hoisted_7 = {
  key: 0,
  id: "chart9",
  style: {"width":"100%","height":"700px"}
}

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { transformDate2, transformDate, getChartDataTool } from "../../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from 'echarts';
import 'echarts-gl';


import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { langList } from "../../../common/lang";

export default /*@__PURE__*/_defineComponent({
  __name: 'chart9',
  props: {
    sensorInfo: null,
},
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()


 let typeList = [
  { label: langObj.value["高频加速度(长)"], value: 2, type: "TW", unit: "m/s²" },
  { label: langObj.value["高频加速度"], value: 1, type: "HZ", unit: "m/s²" },
  // {label : '温度', value: 2},
  { label: langObj.value["低频加速度X"], value: 3, type: "LX", unit: "m/s²" },
  { label: langObj.value["低频加速度Y"], value: 4, type: "LY", unit: "m/s²" },
  { label: langObj.value["低频加速度Z"], value: 5, type: "LZ", unit: "m/s²" },
  { label: langObj.value["速度X"], value: 6, type: "SX", unit: "mm/s" },
  { label: langObj.value["速度Y"], value: 7, type: "SY", unit: "mm/s" },
  { label: langObj.value["速度Z"], value: 8, type: "SZ", unit: "mm/s" },
];



const columns = [
{
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["值"],
    dataIndex: "value",
  },
];


let dataList:any = ref([])
let chartA:any 
let visible = ref(true)

const props:any = __props;
let sensorInfo:any = ref()
sensorInfo.value = props.sensorInfo
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel:any = ref({
    skip: 1,
    take: 20,
    total: 0,
    type: 1,
})
watch(
    () => props.sensorInfo,
    (newValue, oldValue) => {
        sensorInfo.value = props.sensorInfo
        init();
    }
);

onMounted(() => {
    init()
})

const init = () => {
    if(!sensorInfo.value || !sensorInfo.value.id) return
    getTrendListAll()
}

let selectedRowKeys:any = ref([])
const onSelectChange = (ev:any) => {
    selectedRowKeys.value = ev
    getChartData()
}


const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const handleTableChange = (pagination: any) => {
    // 处理分页数据
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getTrendListAll();
};

//获取所有时间点数据
const getTrendListAll = async() => {
    dataList.value = []
    selectedRowKeys.value = []
    let config: any = {
        params: {
            machineId: sensorInfo.value.machineId ,
            sensorId: sensorInfo.value.id ,
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            begin: transformDate(new Date('2021/01/01 00:00:00')),
            end: transformDate(new Date()),
            type: 'HZ'
        },
        headers: {
            requestId: uuidv4(),
        },
    }; 

    let result = await request.get('/api/external/singles', config)

    if(result && result.data) {
        dataList.value = result.data.list
        searchModel.value.total = result.data.count 
    }
}

let chartDataList:any = []
const getChartData = async() => {
    chartDataList = []
    if(selectedRowKeys.value.length) {
        for(let index in selectedRowKeys.value) {
            let item = selectedRowKeys.value[index]
            let info = dataList.value.find((p:any) => p._id === item)
            let result = await request.post('/draw/api/fft-sort', {url: [info.url]})
            let fftData = getChartDataTool(result?.data)
            chartDataList.push({
                data: {
                    f: fftData.axisX,
                    fft: fftData.axisY
                },
                date: info.date
            })
        }


        console.log('chartDataList', chartDataList)
        initChart();
        

    } else {
        chartA && chartA.clear()
    }
}

let myChart:any
const initChart = () => {
    if(!document.getElementById('chart9')) return
    const ec = echarts as any;
    myChart = ec.init(document.getElementById('chart9'));
    myChart.clear()

    let series:any[] = []
    console.log('chartDataList', chartDataList)
    chartDataList.forEach( (d:any) => {
      let data:any = []
      d.data && d.data.f && d.data.f.forEach( (p:any, i:any) => {
        data.push([p, d.date, d.data.fft?.[i]])
      })

      let serie = {
        name: d.date,
        type: 'line3D',
        data: data,
        lineStyle: {
          width: 4
        }
      }
      series.push(serie)
    })

    myChart.setOption({
        tooltip: {
            textStyle: {
            }
        },
        visualMap: {
            show: false,
            dimension: 2,
            inRange: {
                color: theme.value=='white'? ["#274CE0"]:["#00FFF4"],
            }
        },
        xAxis3D: {
            name: langObj.value['频率-Hz'],
            nameGap: 25, 
            nameTextStyle: {
                fontSize: 16,
                color: theme.value=='white'?'#333':'#fff',
            },
            type: 'value',
            axisLabel: {
                color: theme.value=='white'?'#333':'#fff',
                fontSize: 16,
            },
            lineStyle: {
                opacity: 0.3,
                color: theme.value=='white'?'#333':'#fff',
            },
        },
        yAxis3D: {
            name: langObj.value['时间'] ,
            nameGap: 50, 
            type: 'category',
            nameTextStyle: {
                fontSize: 16,
                color: theme.value=='white'?'#333':'#fff',
            },
            axisLabel: {
                color: theme.value=='white'?'#333':'#fff',
                fontSize: 16,
            },
        },
        zAxis3D: {
            name: langObj.value['幅值-m/s²'],
            nameGap: 35, 
            nameTextStyle: {
            fontSize: 16,
            color: theme.value=='white'?'#333':'#fff',
            },
            type: 'value',
            axisLabel: {
                color: theme.value=='white'?'#333':'#fff',
                fontSize: 16,
            },
        },
        grid3D: {
            boxWidth: 70,
            boxDepth: 70,
		    boxHeight: 70,
            viewControl: {
                projection: 'orthographic'
            },
            axisPointer:{
                lineStyle:{
                    color: theme.value=='white'?'#333':'#fff',				//鼠标滑过分割线颜色
                }
            },
        },
        series: series
    });

}



return (_ctx: any,_cache: any) => {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: columns,
              "data-source": _unref(dataList),
              pagination: pagination.value,
              onChange: handleTableChange,
              "row-selection": { selectedRowKeys: _unref(selectedRowKeys), onChange: onSelectChange, hideSelectAll: true },
              rowKey: "_id"
            }, {
              bodyCell: _withCtx(({ column, text }) => [
                (column.dataIndex === 'opt')
                  ? (_openBlock(), _createElementBlock("a", _hoisted_4, _toDisplayString(_unref(langObj)['下载']), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'value')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (text || text==0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(text.toFixed(2)), 1))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source", "pagination", "row-selection"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (_unref(visible))
            ? (_openBlock(), _createElementBlock("div", _hoisted_7))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})