import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  style: {"padding":"16px 16px","min-height":"100vh","background-color":"#000A32"},
  class: "theme-common"
}
const _hoisted_2 = { class: "w-full h-full flex" }
const _hoisted_3 = { style: {"min-width":"250px","margin-top":"31px"} }
const _hoisted_4 = {
  class: "flex-1 ml-4 whiteBg",
  style: {"color":"#fff"}
}
const _hoisted_5 = {
  style: {"width":"100%"},
  class: "flex items-center box"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "w-full mt-4" }

import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import machineListLeftComponent from "../components/machine-list-left.vue";
import { v4 as uuidv4 } from "uuid";
import chart1Component from "./chart/chart1.vue";
import chart2Component from "./chart/chart2.vue";
import chart3Component from "./chart/chart3.vue";
import chart4Component from "./chart/chart4.vue";
import chart5Component from "./chart/chart5.vue";
import chart6Component from "./chart/chart6.vue";
import chart7Component from "./chart/chart7.vue";
import chart8Component from "./chart/chart8.vue";
import chart9Component from "./chart/chart9.vue";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";


export default /*@__PURE__*/_defineComponent({
  __name: 'sensor',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
    theme.value = localStorage.getItem('theme') || 'blue'
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()

let menuList = [
    {label: langObj.value['趋势波形图'], value: 1},
    {label: langObj.value['包络解调'], value: 2},
    {label: langObj.value['多时域频谱'], value: 3},
    {label: langObj.value['指标监测'], value: 4},
    {label: langObj.value['指标值对比分析'], value: 5},
    {label: langObj.value['诊断分数趋势'], value: 6},
    {label: langObj.value['倒频谱'], value: 7},
    // {label: '轴心轨迹', value: 8},
    {label: langObj.value['瀑布图'], value: 9},
]

let selectMenu = ref(1)
let sensor_id = ref('')
let sensorInfo:any = ref(null)
let scrollTop = ref('120px')

const selectSensor = (ev:any) => {
    sensor_id.value = ev
    init()
}

const init = () => {
    getSensorInfo()
}

const handleEvent = (e: any) => {
    if(e.target.localName == 'body') {
        if( e.srcElement.scrollTop >= 120) {
            scrollTop.value = '5px'
        } else {
            scrollTop.value = '120px'
        }
    }
    
};

window.addEventListener('scroll', handleEvent, true)

onUnmounted(() => {
    window.removeEventListener("scroll", handleEvent, true)

})

const getSensorInfo = async() => {
    sensorInfo.value = {}
    let config: any = {
        params: {
            id: sensor_id.value ,
        },
        headers: {
            requestId: uuidv4(),
        },
    }; 
    let result = await request.get('/api/sensors/info', config)

    if(result && result.data) {
        sensorInfo.value = result.data
    } 

    console.log('sensorInfo.value', sensorInfo.value)
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          style: _normalizeStyle([{"width":"250px","position":"fixed"}, { 'top': _unref(scrollTop) }])
        }, [
          _createVNode(machineListLeftComponent, {
            onSelectSensor: selectSensor,
            type: 2,
            mode: 1
          })
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menuList), (item) => {
            return (_openBlock(), _createElementBlock("div", {
              onClick: ($event: any) => (_isRef(selectMenu) //@ts-ignore
 ? selectMenu.value = item.value : selectMenu = item.value),
              class: _normalizeClass([[item.value === _unref(selectMenu) ? 'menu-select' : ''], "menu ml-8"]),
              key: item.value
            }, _toDisplayString(item.label), 11, _hoisted_6))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_unref(selectMenu) === 1)
            ? (_openBlock(), _createBlock(chart1Component, {
                key: 0,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 2)
            ? (_openBlock(), _createBlock(chart2Component, {
                key: 1,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 3)
            ? (_openBlock(), _createBlock(chart3Component, {
                key: 2,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 4)
            ? (_openBlock(), _createBlock(chart4Component, {
                key: 3,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 5)
            ? (_openBlock(), _createBlock(chart5Component, {
                key: 4,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 6)
            ? (_openBlock(), _createBlock(chart6Component, {
                key: 5,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 7)
            ? (_openBlock(), _createBlock(chart7Component, {
                key: 6,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 8)
            ? (_openBlock(), _createBlock(chart8Component, {
                key: 7,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true),
          (_unref(selectMenu) === 9)
            ? (_openBlock(), _createBlock(chart9Component, {
                key: 8,
                sensorInfo: _unref(sensorInfo)
              }, null, 8, ["sensorInfo"]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}
}

})