import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "bule_list_page theme-common" }
const _hoisted_2 = {
  key: 0,
  class: "w-full fac-box p-3 ml-4"
}
const _hoisted_3 = {
  style: {"text-align":"right","margin-bottom":"15px","justify-content":"flex-end"},
  class: "flex"
}
const _hoisted_4 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_5 = { class: "ml-2" }
const _hoisted_6 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_7 = { class: "ml-2" }
const _hoisted_8 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_9 = { class: "ml-2" }
const _hoisted_10 = {
  key: 1,
  class: "w-full fac-box p-3 ml-4"
}

import {
  onMounted,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { getLanguage } from "../../common/translate";
import { getMemberOnboarding } from "../../common/tools";
import { highlightStep,getPopover } from "../../common/registdriver";
import pushRuleDriverComponent from "./push-rule-driver.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'push-rule',
  setup(__props) {

const router = useRouter();

let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
};
getLang();

const getMessageType = (t) => {
  switch (t) {
    case 4:
      return getLanguage("智能诊断报警", language.value);
    case 5:
      return getLanguage("设备门限报警", language.value);
  }
};

const getMembers = async (factoryId: any) => {
  const config = {
    params: { factoryId: factoryId, take: 10000 },
    headers: {
      requestId: uuidv4(),
    },
  };
  let res = await request.get("/api/members", config);
  oMembers.value = res?.data?.list
  return res?.data?.list || [];
};

let pushTypes = ref([
  {
    code: 0,
    name: "短信",
  },
  {
    code: 1,
    name: "微信公众号",
  },
]);

let oMembers = ref([]);

let columns1 = [
  {
    title: getLanguage("报警类型", language.value),
    dataIndex: "messageType",
  },
  {
    title: getLanguage("接收人", language.value),
    dataIndex: "outerMembers",
  },
  {
    title: getLanguage("推送平台", language.value),
    dataIndex: "outerTargetPlatforms",
  },
];

class AlarmSettings {
  combineRangeSeconds: number = 30;
  collectExpireHours: number = 4;
}

let form = ref<AlarmSettings>(new AlarmSettings());

const getFactoryAlarmSettings = (factoryId: string) => {
  const config = {
    params: { id: factoryId },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/factories/setting", config).then((res: any) => {
    if (res?.data?.alarmSettings) {
      form.value = res.data.alarmSettings;
      if (!form.value) {
        form.value = new AlarmSettings()
      }
    } else {
      form.value = new AlarmSettings();
    }
  });
};

async function saveFactoryAlarmSettings() {
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };
  let settings = Object.assign({}, form.value);
  let body = {
    factoryId: _selectedFactoryId,
    alarmSettings: settings
  }
  if (!body.alarmSettings.collectExpireHours || !body.alarmSettings.combineRangeSeconds) {
    message.error(getLanguage('请填写报警间隔', language.value));
    return;
  }
  await request.post("/api/factories/setting", body, config);
}


const handleOk = async () => {
  await saveFactoryAlarmSettings();
  let res = await request.put("/api/push-rule", { list: dataList.value });
  if (res && res.status == 200) {
    message.success(getLanguage("保存推送配置成功", language.value));
  }
};

const filterOption = (input: any, option: any) => {
  return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

let dataList = ref<any>([]);

let _selectedFactoryId: string = "";
let factoryList = ref<any>();
let treeData: any = ref([]);
let allFactory = ref([]);

const loadFactory = async () => {
  const config = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  await request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId);
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id);
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory;
        }
        factory.push(d);
      });

      factoryList.value = factory;
      treeData.value = factoryList.value;
    }
  });
};

// 查询
const search = (factoryId: any) => {
  const config = {
    params: { factoryId: factoryId },
    headers: {
      requestId: uuidv4(),
    },
  };
  request.get("/api/push-rule/info", config).then((res: any) => {
    if (res.data && res.data.length) {
      dataList.value = res.data;
    } else {
      dataList.value = [
        {
          messageType: 4,
          factoryId: factoryId,
          outerMembers: [],
          outerTargetPlatforms: [],
        },
        {
          messageType: 5,
          factoryId: factoryId,
          outerMembers: [],
          outerTargetPlatforms: [],
        },
      ];
    }
  });
};

const selectTree = async (ev: any, info: any) => {
  if (ev && ev.length && info.node.type == "factory") {
    var cutFactory = allFactory.value.find((t) => t._id == info.node.id);
    if (cutFactory) {
      _selectedFactoryId = cutFactory._id;
      await getMembers(cutFactory._id);
      await getFactoryAlarmSettings(cutFactory._id)
      search(cutFactory._id);
    } else {
      _selectedFactoryId = "";
    }
  }
};
onMounted(async () => {
  await loadFactory();

  //新注册用户，未经过引导的需弹窗进行引导
  if(!getMemberOnboarding())
  {
    await selectTree([treeData.value[0].id],{ node: treeData.value[0]});
    setTimeout(()=>{
          highlightStep(getPopover(".pushruleDriver","8/9 报警推送","针对智能诊断报警和门限报警，可配置接收人和推送平台。（系统提供微信公众号和短信推送平台）","推送配置","bottom","center",nextRoute,pushruleDriver));
    },400)
  }
});

let pushRuleDriverVisible = ref(false);
let editRindex = ref("auto");
//新用户引导报警推送配置按钮回调
const pushruleDriver = ()=>{
    editRindex = 1000000001;
    pushRuleDriverVisible.value = true;
};
//新用户引导报警推送下一步按钮回调
const nextRoute = ()=>{
    router.push("/report/list");
    return;
};
const savePushRuleDriver=async (ev:any)=>{
    pushRuleDriverVisible.value = false;
    await selectTree([treeData.value[0].id],{ node: treeData.value[0]});
}

return (_ctx: any,_cache: any) => {
  const _component_a_tree = _resolveComponent("a-tree")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_layout_content, {
      style: {"padding":"0 20px"},
      class: "flex"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_tree, {
          style: {"height":"80vh","overflow":"auto","padding":"10px 0","width":"300px"},
          class: "draggable-tree",
          "block-node": "",
          "tree-data": _unref(treeData),
          onSelect: selectTree
        }, null, 8, ["tree-data"]),
        (_unref(dataList).length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: "btn2-o flex items-center cursor-pointer",
                  onClick: handleOk,
                  style: {"padding":"4px 8px","max-width":"145px","margin-left":"10px"}
                }, _toDisplayString(_unref(getLanguage)("保存", _unref(language))), 1)
              ]),
              _createVNode(_component_a_row, { class: "mb-4" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, { span: "8" }, {
                    default: _withCtx(() => [
                      _cache[2] || (_cache[2] = _createElementVNode("span", null, "合并报警间隔时间：", -1)),
                      _createVNode(_component_a_input_number, {
                        value: _unref(form).combineRangeSeconds,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(form).combineRangeSeconds) = $event)),
                        style: {"width":"60px"},
                        size: "small",
                        min: 0
                      }, null, 8, ["value"]),
                      _cache[3] || (_cache[3] = _createElementVNode("span", null, "秒内报警，合并成一条", -1))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_col, { span: "12" }, {
                    default: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createElementVNode("span", null, "不触发报警条件：采集时间与实际报警时间延迟超过", -1)),
                      _createVNode(_component_a_input_number, {
                        value: _unref(form).collectExpireHours,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_unref(form).collectExpireHours) = $event)),
                        style: {"width":"60px"},
                        size: "small",
                        min: 0
                      }, null, 8, ["value"]),
                      _cache[5] || (_cache[5] = _createElementVNode("span", null, "小时不对外发送报警消息", -1))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_table, {
                bordered: "",
                columns: _unref(columns1),
                dataSource: _unref(dataList),
                class: "pushruleDriver",
                pagination: false
              }, {
                bodyCell: _withCtx(({ column, record, text, index }) => [
                  (column.dataIndex === 'messageType')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, _toDisplayString(getMessageType(Number(text))), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'outerMembers')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_a_select, {
                            mode: "multiple",
                            value: record.outerMembers,
                            "onUpdate:value": ($event: any) => ((record.outerMembers) = $event),
                            style: {"width":"300px"},
                            placeholder: "请选择接收人",
                            filterOption: filterOption
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(oMembers), (item, i) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: item._id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.nickName), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value"])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (column.dataIndex === 'outerTargetPlatforms')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_a_select, {
                            mode: "multiple",
                            value: record.outerTargetPlatforms,
                            "onUpdate:value": ($event: any) => ((record.outerTargetPlatforms) = $event),
                            style: {"width":"200px"},
                            placeholder: "请选择推送平台"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pushTypes), (item, i) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: item.code,
                                  value: item.code
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 2
                          }, 1032, ["value", "onUpdate:value"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["columns", "dataSource"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_a_alert, {
                message: "请选择工厂",
                type: "warning"
              })
            ]))
      ]),
      _: 1
    }),
    (_unref(pushRuleDriverVisible))
      ? (_openBlock(), _createBlock(pushRuleDriverComponent, {
          key: 0,
          onSavePushRuleDriver: savePushRuleDriver,
          visible: _unref(pushRuleDriverVisible),
          editRindex: _unref(editRindex)
        }, null, 8, ["visible", "editRindex"]))
      : _createCommentVNode("", true)
  ]))
}
}

})