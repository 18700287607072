<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div style="">
      <div class="box theme-common">
        <div class="w-full p-5">
          <!--
          <div class="w-full">
            <a-form :model="searchModel" name="horizontal_login" layout="inline"
              autocomplete="off">
              <a-form-item :label="langObj['设备区域']" name="groupId">
                <a-select v-model:value="searchModel.groupId"
                  style="width: 220px" :placeholder="langObj['请选择设备区域']"
                  @change="selectGroup">
                  <a-select-option v-for="(item, index) in groupList"
                    :key="index" :value="item.id"> {{
                      item.name
                    }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['设备']" name="status">
                <a-select v-model:value="searchModel.machineId"
                  style="width: 220px" :placeholder="langObj['请选择设备']"
                  allowClear>
                  <a-select-option v-for="(item, index) in machineList"
                    :key="index" :value="item.id"> {{
                      item.name
                    }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['报警时间']" name="status">
                <a-range-picker v-model:value="searchModel.date"
                  :disabled-date="disabledDate"
                  @calendarChange="onCalendarChange" />
              </a-form-item>

              <a-form-item :label="langObj['严重程度']" name="status">
                <a-select v-model:value="searchModel.condition"
                  style="width: 220px" allowClear
                  :placeholder="langObj['请选择严重程度']">
                  <a-select-option v-for="(item, index) in conditionLists"
                    :key="index" :value="item.value">
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item>
                <div class="flex items-center">
                  <div @click="reset"
                    class="btn px-5 py-1 fff cursor-pointer ml-4 btn-reset">{{
                      langObj['重置'] }}</div>
                  <div @click="search"
                    class="btn px-5 py-1 fff cursor-pointer ml-4 btn-submit"
                    style="background-color: #072499;">
                    {{ langObj['查询'] }}
                  </div>
                </div>
              </a-form-item>


            </a-form>
          </div>
          -->

          <div class="box mt-4" style="width: 100%;">
            <a-table style="width: 100%;" size="small"
              :row-class-name="getRowClassName" :columns="warningColumns"
              :data-source="data"
              :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
              :pagination="pagination" @change="handleTableChange" rowKey="id">
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'scoreCondition'">
                  <div class="flex items-center">
                    <div style="width: 12px; height: 12px; border-radius: 50%;"
                      :style="{ 'background': conditionList.find((p) => p.value === text)?.color }">
                    </div>
                    <div class="ml-2">{{ langObj[conditionList.find((p) =>
                      p.value === text)?.label ?? ""] }}</div>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'condition'">
                  <div class="flex items-center">
                    <div style="width: 12px; height: 12px; border-radius: 50%;"
                      :style="{ 'background': conditionList.find((p) => p.value === text)?.color }">
                    </div>
                    <div class="ml-2">{{ langObj[conditionList.find((p) =>
                      p.value === text)?.label ?? ""] }}</div>
                  </div>
                </template>

                <template v-if="column.dataIndex === 'handleStatus'">
                  <div @click="viewClose(record)" v-if="text === 3"><a>{{
                      langObj['已关闭'] }}</a></div>
                  <div v-if="text !== 3">{{
                    statusList.find(p => p.value === text) ?
                    langObj[statusList.find(p => p.value === text)?.label ?? ""]
                    :
                    ""
                    }}
                  </div>
                </template>

                <template v-if="column.dataIndex === 'act'">

                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>

      <a-modal v-model:visible="visibledel2" :closable="false" :footer="null"
        :bodyStyle="{ 'background': '#020F46' }" style="top: 30%" width="1200px"
        :maskClosable="false">
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="visibledel2 = false">
              <close-outlined style="font-size: 20px; color: #fff !important" />
            </div>

          </div>
          <div class="text-center w-full" style="font-size: 24px;">{{
            langObj['关闭报警']
            }}</div>

          <a-form ref="formRef" name="advanced_search"
            class="ant-advanced-search-form" style="margin-top: 20px"
            :model="formClose">
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item name="closeCause" label="关闭原因" required>
                  <a-textarea v-model:value="formClose.closeCause"
                    placeholder="" :rows="4" readonly />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>

        </div>
      </a-modal>


      <a-drawer v-model:visible="visibleDR" class="custom-class" size="large"
        :closable="false" placement="right">
        <template #title>
        </template>
        <DiagnosticReportDetails ref="mrDref" :reportId="reportDRId"
          @cancel="cancelDR"></DiagnosticReportDetails>
      </a-drawer>

    </div>
  </a-config-provider>
</template>


<script lang="ts" setup>
import { computed, onMounted, ref, defineProps, defineExpose } from "vue";
import request from "../../common/request";
import { transformDate2, getGroupListByFactory } from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import { langList } from "@/common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";

const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}

let langObj: any = ref({})
let language: any = ref('Chinese')
getLang()

let props = defineProps<{
  query: any
}>()

let conditionList = [
  { label: '健康', value: 0, color: '#61c08f' },
  { label: '可用', value: 1, color: '#d2de49' },
  { label: '可用', value: 2, color: '#d2de49' },
  { label: '警戒', value: 3, color: '#cd5f3b' },
  { label: '警戒', value: 4, color: '#cd5f3b' },
  { label: '警戒', value: 5, color: '#cd5f3b' },
  { label: '故障', value: 6, color: '#921e37' },
  { label: '故障', value: 7, color: '#921e37' },
]

let currentConditionList = [
  { label: "可用", value: 1, realValue: [1, 2] },
  { label: "警戒及以下", value: 2, realValue: [3, 4, 5, 6, 7] },
  { label: "不限", value: null, realValue: null },
]


let statusList = [
  { label: '未处理', value: 1 },
  { label: '已处理', value: 2 },
  { label: '已关闭', value: 3 },
]

let warningColumns = [
  {
    title: "项目",
    dataIndex: "factoryName",
    align: "center"
  },
  {
    title: "区域",
    dataIndex: "groupName",
    align: "center"
  },
  {
    title: langObj.value['报警时间'],
    dataIndex: 'date',
  },
  {
    title: langObj.value['当前状态'],
    dataIndex: 'scoreCondition',
  },
  {
    title: langObj.value['严重程度'],
    dataIndex: 'condition',
  },
  {
    title: langObj.value['设备'],
    dataIndex: 'machineName',
  },
  {
    title: langObj.value['报警内容'],
    dataIndex: 'diagnosticNotes',
  },
  {
    title: langObj.value['联系人'],
    dataIndex: 'contactName',
  },
  {
    title: langObj.value['处理状态'],
    dataIndex: 'handleStatus',
  },
  /*
  {
    title: langObj.value['操作'],
    dataIndex: 'act',
  },
  */
]
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let data: any = ref([])
let searchModel: any = ref({
  type: 4,
  expertCertification: false,
  groupId: null,
  machineId: null,
  date: null,
  skip: 1,
  take: 20,
  condition: [],
  handleStatus: null,
})
let selectedRowKeys: any = ref()
let groupList: any = ref([])
let machineList: any = ref([])
let formClose: any = ref({
  closeCause: ''
})
let isEditable: any = ref(false)
let isAdmin: any = ref(false)

onMounted(() => {
  isEditable.value = true
  getAdmin()
  getGroupList()
  search()
  getLang()
})

const getAdmin = () => {
  let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '{}')
  isAdmin.value = memberInfo?.admin
}


// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory()
}

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem('memberInfo') || '')
  searchModel.value.machineId = ''
  if (ev) {
    machineList.value = memberInfo.machines.filter((p: any) => p.groupId === ev)
  } else {
    machineList.value = []
  }
}

const search = () => {
  searchModel.value.skip = 1
  Object.assign(searchModel.value, props.query, {
    type: 4,
    expertCertification: false,
  })
  getData()
}

const getData = async () => {
  let config: any = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      machineId: searchModel.value.machineId,
      type: searchModel.value.type,
      handleStatus: searchModel.value.handleStatus,
      expertCertification: false,
      factoryId: searchModel.value.factoryId,
      begin: searchModel.value.begin,
      end: searchModel.value.end,
      //factoryIds: getRealFactoryIdList()
    },
    headers: {
      requestId: uuidv4(),
    },
  };


  if (!searchModel.value.type) {
    config.params.typeList = [1, 4]
  }

  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  if (searchModel.value.condition) {
    config.params.conditionList = currentConditionList.find((p: any) => p.value === searchModel.value.condition)?.realValue
  }

  let result = await request.get('/api/workflows', config)

  if (result) {
    data.value = result.data.list
    searchModel.value.total = result.data.total
  }
}

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    let order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};


//复选框选择
const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev
}

// 查看已关闭
let visibledel2 = ref(false)
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause
  visibledel2.value = true
}

//查看报告
let visibleDR: any = ref(false)
let reportDRId: any = ref('')
let mrDref: any = ref()

const cancelDR = () => {
  visibleDR.value = false
}

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? 'even-row' : 'odd-row';
}


const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};

defineExpose({
  search,
});

</script>


<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.txt-select {
  border-bottom: 2px solid #00FFF4;
}

.btn {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff
}

/deep/ .ant-table {
  background: #020F46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 0 solid #fff
}

/deep/ .ant-picker-input>input {
  color: #fff;
}

/deep/ .ant-picker-clear span {
  background: #000 !important;
}

/deep/ .ant-drawer-close span svg {
  color: #000 !important;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}
</style>
