import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "flex theme-common",
  style: {"background-color":"rgb(0, 10, 50)","color":"#fff","height":"calc(100vh - 100px)"}
}
const _hoisted_2 = {
  class: "p-3",
  style: {"width":"750px"}
}
const _hoisted_3 = { class: "box w-full mb-4 p-3" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { style: {"font-size":"20px"} }
const _hoisted_6 = {
  class: "w-full flex items-center",
  style: {"justify-content":"space-between"}
}
const _hoisted_7 = { class: "ml-12 mr-5" }
const _hoisted_8 = { class: "ml-2" }
const _hoisted_9 = { class: "ml-2" }
const _hoisted_10 = { class: "ml-2" }
const _hoisted_11 = { class: "ml-2" }
const _hoisted_12 = { class: "box w-full p-3" }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = { style: {"font-size":"20px"} }
const _hoisted_15 = { class: "w-full flex items-center justify-center" }
const _hoisted_16 = { class: "flex items-center cursor-pointer" }
const _hoisted_17 = { class: "ml-2" }
const _hoisted_18 = { class: "flex items-center ml-4 cursor-pointer" }
const _hoisted_19 = { class: "ml-2" }
const _hoisted_20 = { class: "flex items-center ml-4 cursor-pointer" }
const _hoisted_21 = { class: "ml-2" }
const _hoisted_22 = { class: "flex items-center ml-4 cursor-pointer" }
const _hoisted_23 = { class: "ml-2" }
const _hoisted_24 = { class: "p-3 box w-full flex-1 mt-3 whiteBg" }
const _hoisted_25 = { class: "flex" }
const _hoisted_26 = { style: {"font-size":"20px"} }
const _hoisted_27 = { class: "flex items-center" }
const _hoisted_28 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = {
  key: 2,
  class: "flex items-center"
}
const _hoisted_32 = ["onClick"]

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import * as echarts from "echarts";
import {
  getFactoryName,
  getGroupName,
  transformDate2,
  getGroupListByFactory,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";

import { Dayjs } from 'dayjs';
import { getLanguage } from "../../common/translate";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import Log from "../system/Log.vue";
import { Value } from "sass";


export default /*@__PURE__*/_defineComponent({
  __name: 'statistics',
  setup(__props) {

let conditionLists = [
  { label: "健康", value: 1, realValue: [0] },
  { label: "可用", value: 2, realValue: [1, 2] },
  { label: "警戒", value: 3, realValue: [3, 4, 5] },
  { label: "故障", value: 4, realValue: [6, 7] },
];
let conditionChartData: any = ref([      //设备健康状态统计 数据
  { label: '健康', num: 0, percentage: "0%", color: '#61c08f' },
  { label: '可用', num: 0, percentage: "0%", color: '#d2de49' },
  { label: '警戒', num: 0, percentage: "0%", color: '#cd5f3b' },
  { label: '故障', num: 0, percentage: "0%", color: '#921e37' },
])




const router = useRouter();
let langObj: any = ref({})
let language: any = ref('Chinese')
let theme: any = ref('blue')
const getLang = () => {
   theme.value = localStorage.getItem('theme') || 'blue'
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
  console.log(theme.value,"theme.value")

  conditionLists = [
    { label: langObj.value["健康"], value: 1, realValue: [0] },
    { label: langObj.value["可用"], value: 2, realValue: [1, 2] },
    { label: langObj.value["警戒"], value: 3, realValue: [3, 4, 5] },
    { label: langObj.value["故障"], value: 4, realValue: [6, 7] },
  ];
}
getLang()
const chartBtime: any = ref(2)




const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  search();

};



let searchModel: any = ref({
  skip: 1,
  take: 10,
  keyWord: "",
  groupId: null,
  machineId: "",
  condition: null,
  status: null,
  sensorPlace: "",
  date: null,
  dateLeft1: null,
  dateLeft2: null,
  total: 0,
  type: null
});
let machineInfo: any = ref({});


let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];






let data = ref({});

const machineColumns = [
  {
    title: langObj.value["设备名称"],
    dataIndex: "machineName",
  },

  {
    title: langObj.value["区域"],
    dataIndex: "groupId",
  },

  {
    title: langObj.value["设备位号"],
    dataIndex: "bitNumber",
  },
  {
    title: langObj.value["巡检报告数量"],
    dataIndex: "reportNumber",
  },
  {
    title: langObj.value["设备编号"],
    dataIndex: "machineCode",
  },
  {
    title: langObj.value["最新巡检日期"],
    dataIndex: "date",
  },



  {
    title: langObj.value["最新巡检状态"],
    dataIndex: "condition",
  },

  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];



let groupList: any = ref([]);

let dataTypeList = ref<any>();

let dataChart: any = ref({})

onMounted(() => {
  //  isEditable.value = true
  //  loadDatatype();
  if (!localStorage.getItem("factory_id")) {
    return
  }
  getMachineList2()
  getGroupList();
  getMachineNum()
  search();
});


const getMachineInfo = (info: any) => {
  if (info) {
    localStorage.setItem("machine_id", info.id);
    router.push("/inspection/diagnosis");
  }
};
const getMachineNum = () => {
  let end = null
  let start = null

  if (chartBtime.value == 1) {
    searchModel.value.dateLeft2 = null
    end = new Date()
    start = new Date(end).getTime() - (1000 * 60 * 60 * 24 * 1)
  } else if (chartBtime.value == 2) {
    searchModel.value.dateLeft2 = null
    end = new Date()
    start = new Date(end).getTime() - (1000 * 60 * 60 * 24 * 7)
  } else if (chartBtime.value == 3) {
    searchModel.value.dateLeft2 = null
    end = new Date()
    start = new Date(end).getTime() - (1000 * 60 * 60 * 24 * 30)
  }


  let endTime = transformTime(end)
  let strtTime = transformTime2(start)
  let dateList: any = ref([])
  let chartAData: any = ref([])


  const config: any = {
    params: {
      factoryId: localStorage.getItem("factory_id"),
      skip: 1,
      take: 100000,
      begin: strtTime,
      end: endTime,

    },
    headers: {
      requestId: uuidv4(),
    },

  };
  if (searchModel.value.dateLeft2 && searchModel.value.dateLeft2.length === 2) {

    config.params.begin = transformDate2(new Date(searchModel.value.dateLeft2[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.dateLeft2[1]), 2);
  }
  request.get("/api/external/inspection", config).then((res: any) => {
    if (res) {
      // machineInfo.value = res.data;
      if (!document.getElementById("chartB")) return
      let myChart = echarts.init(document.getElementById("chartB") as HTMLDivElement);
      myChart && myChart.clear()

      dateList.value = res.data.list.map((p: any) => p.date);
      let list = dateList.value.map((p: any) => p.substring(0, 10))
      list = [...new Set(list)].reverse()


      list.map((date: any) => {
        let dataList = res.data.list.filter((p: any) => p.date.includes(date))
        let condition1Num = 0;
        let condition2Num = 0
        let condition3Num = 0
        let condition4Num = 0
        let machineIds = dataList.map((tt: any) => tt.machineId);
        machineIds = [...new Set(machineIds)];
        machineIds.map((tt: any) => {
          let machines = dataList.filter((item: any) => item.machineId == tt);
          let conditions = machines.map((p: any) => p.condition).sort();
          let condition = conditions[conditions.length - 1];
          if (condition == 0) {
            condition1Num++
          }
          if (condition >= 1 && condition <= 2) {
            condition2Num++
          }
          if (condition >= 3 && condition <= 5) {
            condition3Num++
          }
          if (condition >= 6 && condition <= 7) {
            condition4Num++
          }


        })


        // conditionList
        chartAData.value.push({
          date: date,
          total: dataList.length,
          totalMachine: machineIds.length,
          condition1Num,
          condition2Num,
          condition3Num,
          condition4Num
        })
        initChartB(chartAData.value)
      })
    }
  });
};


// 转换时间
const transformTime = (date: any) => {
  const time = new Date(date)
  const year = time.getFullYear()
  const month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
  const day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
  const hour = time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
  const min = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
  const second = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
  return `${year}/${month}/${day} ${hour}:${min}:${second}`
};

const transformTime2 = (date: any) => {
  const time = new Date(date)
  const year = time.getFullYear()
  const month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : '0' + (time.getMonth() + 1)
  const day = time.getDate() > 9 ? time.getDate() : '0' + time.getDate()
  const hour = time.getHours() > 9 ? time.getHours() : '0' + time.getHours()
  const min = time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes()
  const second = time.getSeconds() > 9 ? time.getSeconds() : '0' + time.getSeconds()
  return `${year}/${month}/${day} 00:00:00`
};



// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

const selectMachineCondition = (condition: any) => {
  // resetMachine()
  // machineModelSearch.value.condition = condition
  // machineVisible.value = true
  // getDataMachine()
}

const search = () => {
  // searchModel.value.skip = 1;
  getData();
};

const getData = () => {
  getMachineList()
};

//获取左侧设备数量
const getMachineList2 = async () => {

  let config: any = {
    params: {
      factoryId: localStorage.getItem("factory_id"),

      // sort: "date",
      // order: -1,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (searchModel.value.dateLeft1 && searchModel.value.dateLeft1.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.dateLeft1[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.dateLeft1[1]), 2);
  }
  let result = await request.get("/api/external/inspection/machines/status", config);

  if (result && result.status === 200) {
    dataChart.value = result.data;
    

    getConditionChartData()
  }
};

//获取列表
const getMachineList = async () => {
  data.value = {};
  let config: any = {
    params: {
      groupId: searchModel.value.groupId,
      factoryId: localStorage.getItem("factory_id"),
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      sort: "date",
      order: -1,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }
  let result = await request.get("/api/external/inspection/machines", config);

  if (result && result.status === 200) {
    data.value = result.data;
    searchModel.value.total = result.data.count;

  }
};

// 重置
const reset = () => {
  searchModel.value = {
    ...searchModel.value,
    keyWord: "",
    date: null,
    groupId: null,
    machineId: "",
    type: null,
    condition: null,
    status: null,
    sensorPlace: "",
  };
};



// 获取报告健康状态
const getReportCondition = (ev: any) => {
  if (ev && ev.workStatus) {
    return conditionList.find(
      (p: any) => p.value === ev.workStatus.scoreCondition
    );
  } else {
    return conditionList[0];
  }
};





const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};










const handlePreview = async (file: any) => {
  previewImage.value = file.url;
  previewVisible.value = true;
  previewTitle.value = "";
};






const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  searchModel.value.date = val;
  getMachineList()
};
const onCalendarChange1 = (val: any) => {
  searchModel.value.dateLeft1 = val;
  getMachineList2()
};
const onCalendarChange2 = (val: any) => {
  searchModel.value.dateLeft2 = val;
  if (searchModel.value.dateLeft2 && searchModel.value.dateLeft2.length === 2) {
    chartBtime.value = 0;
  } else {
    chartBtime.value = 1;
  }
  getMachineNum()
};

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
  let n1 = 0, n2 = 0, n3 = 0, n4 = 0;
  let p1 = "", p2 = "", p3 = "", p4 = "";
  let length = dataChart.value.list.length


  dataChart.value.list && dataChart.value.list.map((m: any) => {
    if (m.workStatus?.scoreCondition === 0 || !m.workStatus) {
      n1++
    }
    if (m.workStatus?.scoreCondition >= 1 && m.workStatus?.scoreCondition <= 2) {
      n2++
      
    }
    if (m.workStatus?.scoreCondition >= 3 && m.workStatus?.scoreCondition <= 5) {
      n3++
    }
    if (m.workStatus?.scoreCondition >= 6 && m.workStatus?.scoreCondition <= 7) {
      n4++
    }
  })
  


  p1 = length > 0 ? ((n1 / length) * 100).toFixed(2) + "%" : '0%';
  p2 = length > 0 ? ((n2 / length) * 100).toFixed(2) + "%" : '0%';
  p3 = length > 0 ? ((n3 / length) * 100).toFixed(2) + "%" : '0%';
  p4 = length > 0 ? ((n4 / length) * 100).toFixed(2) + "%" : '0%';
 
  conditionChartData.value = [
      { label: '健康', num: n1, percentage: p1, color: '#61c08f' },
      { label: '可用', num: n2, percentage: p2, color: '#d2de49' },
      { label: '警戒', num: n3, percentage: p3, color: '#cd5f3b' },
      { label: '故障', num: n4, percentage: p4, color: '#921e37' },
    ]


  initConditionChart()
}


// 绘制设备健康状态统计图
const initConditionChart = () => {
  if (!document.getElementById("chartA")) return
  let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

  myChart && myChart.clear()
  let legendData = conditionChartData.value.map((t: any) => t.label)
  let DData = conditionChartData.value.map((t: any) => ({
    value: t.num,
    name: t.label + "设备数：" + t.num
  }))

  let options = {
    title: {
      text: "",
      left: "center",
    },
    tooltip: {
      show: false,
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
    },
    legend: {
      show: false,
      orient: "vertical",
      right: 10,
      top: 30,
      textStyle: {
        color: theme.value=='white'? "#333" :"#ffffff",
        fontSize: 12,
      },
      data: legendData,
      selected: {
        健康: true,
      },
    },
    graphic: {
      type: "text",
      left: "center",
      top: "center",
      style: {
        text: langObj.value["总设备数"] + ":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num),
        textAlign: "center",
        fontSize: 16,
        fill:  theme.value=='white'? "#333" :"#ffffff",
      },
      tooltip: {
        show: false,
      },
    },
    series: [
      {
        name: "",
        type: "pie",
        radius: ["65%", "85%"],
        center: ["50%", "50%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 12,
            fontWeight: "bold",
            formatter: "{b}\n{c} ({d}%)",
          },
        },
        labelLine: {
          show: false,
        },
        data: DData,
      },
    ],
    color: ['rgb(28, 196, 139)', 'rgb(206, 224, 8)', 'rgb(223, 87, 45)', 'rgb(162, 3, 53)']
  };

  myChart.setOption(options);
  myChart.on("mouseover", (params: any) => {
    const { name } = params;

    if (name) {
      let nameArr = name.split("：");
      options.graphic.style.text = langObj.value[nameArr[0]] + "：" + nameArr[1];
    } else {
      options.graphic.style.text = langObj.value["总设备数"] + ":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num);
    }

    myChart.setOption(options);
  });
  myChart.on("mouseout", () => {
    options.graphic.style.text = langObj.value["总设备数"] + ":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num)
    myChart.setOption(options);

  });

}


const initChartB = (dataList: any) => {

  if (!document.getElementById("chartB")) return
  let myChart = echarts.init(document.getElementById("chartB") as HTMLDivElement);



  let xData = dataList.map((p: any) => p.date)
  xData = xData.map((p: any) => p.substring(5, 10))
  let condition1 = dataList.map((p: any) => p.condition1Num)
  let condition2 = dataList.map((p: any) => p.condition2Num)
  let condition3 = dataList.map((p: any) => p.condition3Num)
  let condition4 = dataList.map((p: any) => p.condition4Num)

  const labelOption = {
    show: true,
    // formatter: '{c}',
    formatter: (object) => {
      return object.value > 0 ? object.value : ""
    },
    fontSize: 12
  };
  let option = {
    grid: {
      top: '5%',
      bottom: '15%'
    },

    tooltip: {
      trigger: 'axis',
      position: ['40%', '0%'],
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      show: false,
    },
    xAxis: [{
      type: 'category',
      axisTick: {
        show: false
      },
      data: xData
    }],
    yAxis: [{
      type: 'value'
    }],
    series: [{
      name: '健康',
      type: 'line',
      barGap: 0,
      emphasis: {
        focus: 'series'
      },
      label: labelOption,
      data: condition1,
      itemStyle: {
        color: '#1CC48B'
      }
    },
    {
      name: '可用',
      type: 'line',
      emphasis: {
        focus: 'series'
      },
      label: labelOption,
      data: condition2,
      itemStyle: {
        color: '#d2de49'
      }
    },
    {
      name: '警戒',
      type: 'line',
      emphasis: {
        focus: 'series'
      },
      label: labelOption,
      data: condition3,
      itemStyle: {
        color: '#CB6A34'
      }
    },
    {
      name: '故障',
      type: 'line',
      emphasis: {
        focus: 'series'
      },
      label: labelOption,
      data: condition4,
      itemStyle: {
        color: '#B81212'
      }
    }
    ]
  };

  myChart.setOption(option);
  // this.$refs.chartB1.init(echarts, (chart) => {
  // 	this.$refs.chartB1.setOption(option);
  // });




};










return (_ctx: any,_cache: any) => {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _unref(language) === 'English' ? _unref(enUS) : _unref(zhCN)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(langObj)['巡检状态统计']), 1),
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
              _createVNode(_component_a_form, {
                model: _unref(searchModel),
                name: "horizontal_login",
                layout: "inline",
                autocomplete: "off"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['巡检日期'],
                    name: "date"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_range_picker, {
                        size: "small",
                        value: _unref(searchModel).dateLeft1,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_unref(searchModel).dateLeft1) = $event)),
                        "disabled-date": disabledDate,
                        onCalendarChange: onCalendarChange1
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[13] || (_cache[13] = _createElementVNode("div", {
                style: {"width":"300px","height":"300px"},
                id: "chartA"
              }, null, -1)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: "flex items-center cursor-pointer",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (selectMachineCondition(1)))
                }, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#61c08f"} }, null, -1)),
                  _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(langObj)['健康']) + "：", 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[0].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))) + "," + _toDisplayString(_unref(conditionChartData)[0].percentage), 1)
                ]),
                _createElementVNode("div", {
                  class: "flex items-center cursor-pointer mt-3",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (selectMachineCondition(2)))
                }, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(langObj)['可用']) + "：", 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[1].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))) + "," + _toDisplayString(_unref(conditionChartData)[1].percentage), 1)
                ]),
                _createElementVNode("div", {
                  class: "flex items-center cursor-pointer mt-3",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (selectMachineCondition(3)))
                }, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(langObj)['警戒']) + "：", 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[2].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))) + "," + _toDisplayString(_unref(conditionChartData)[2].percentage), 1)
                ]),
                _createElementVNode("div", {
                  class: "flex items-center cursor-pointer mt-3",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (selectMachineCondition(4)))
                }, [
                  _cache[12] || (_cache[12] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
                  _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(langObj)['故障']) + "：", 1),
                  _createElementVNode("div", null, _toDisplayString(_unref(conditionChartData)[3].num) + _toDisplayString(_unref(getLanguage)("台", _unref(language))) + "," + _toDisplayString(_unref(conditionChartData)[3].percentage), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(langObj)['巡检设备趋势']), 1),
              _createVNode(_component_a_form, {
                style: {"display":"flex","margin-left":"10px"},
                model: _unref(searchModel),
                name: "horizontal_login",
                layout: "inline",
                autocomplete: "off"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        ref: "select",
                        value: chartBtime.value,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((chartBtime).value = $event)),
                        style: {"display":"block","width":"100px"},
                        size: "small",
                        onChange: getMachineNum
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select_option, { value: 0 }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(_toDisplayString(''))
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)['近一天']), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)['近一周']), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_a_select_option, { value: 3 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_unref(langObj)['近一月']), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_form_item, { class: "flex-1" }),
                  _createVNode(_component_a_form_item, {
                    label: _unref(langObj)['巡检日期'],
                    name: "date"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_range_picker, {
                        size: "small",
                        value: _unref(searchModel).dateLeft2,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_unref(searchModel).dateLeft2) = $event)),
                        "disabled-date": disabledDate,
                        onCalendarChange: onCalendarChange2
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("div", {
              id: "chartB",
              style: {"width":"100%","height":"200px"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _cache[15] || (_cache[15] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#61c08f"} }, null, -1)),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(langObj)['健康']), 1)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[16] || (_cache[16] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#d2de49"} }, null, -1)),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_unref(langObj)['可用']), 1)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[17] || (_cache[17] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#cd5f3b"} }, null, -1)),
                _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(langObj)['警戒']), 1)
              ]),
              _createElementVNode("div", _hoisted_22, [
                _cache[18] || (_cache[18] = _createElementVNode("div", { style: {"width":"14px","height":"14px","background-color":"#921e37"} }, null, -1)),
                _createElementVNode("div", _hoisted_23, _toDisplayString(_unref(langObj)['故障']), 1)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(langObj)['巡检设备统计']), 1),
            _cache[20] || (_cache[20] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createVNode(_component_a_form, {
              model: _unref(searchModel),
              name: "horizontal_login",
              layout: "inline",
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_form_item, {
                  label: _unref(langObj)['巡检日期'],
                  name: "date"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_range_picker, {
                      value: _unref(searchModel).date,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_unref(searchModel).date) = $event)),
                      "disabled-date": disabledDate,
                      onCalendarChange: onCalendarChange
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_a_form_item, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", {
                        onClick: reset,
                        class: "box px-5 py-1 fff cursor-pointer ml-4 btn-reset"
                      }, _toDisplayString(_unref(langObj)['重置']), 1),
                      _createElementVNode("div", {
                        onClick: getData,
                        class: "box px-5 py-1 fff cursor-pointer ml-4 btn-submit",
                        style: {"background-color":"#072499"}
                      }, _toDisplayString(_unref(langObj)['查询']), 1)
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model"])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_a_table, {
              style: {"width":"100%"},
              size: "small",
              "row-class-name": getRowClassName,
              columns: machineColumns,
              "data-source": _unref(data).list,
              pagination: pagination.value,
              onChange: handleTableChange
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'groupId')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_unref(getGroupName)(text)), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'date')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(record.workStatus?.scoreDate), 1))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'condition')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                      _createElementVNode("div", {
                        style: _normalizeStyle([{"width":"14px","height":"14px","border-radius":"50%"}, { background: getReportCondition(record)?.color }])
                      }, null, 4),
                      _createElementVNode("div", {
                        class: "ml-1",
                        style: _normalizeStyle({ color: getReportCondition(record)?.color })
                      }, _toDisplayString(_unref(langObj)[getReportCondition(record)?.label]), 5)
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 3,
                      onClick: ($event: any) => (getMachineInfo(record)),
                      class: "theme-table-link"
                    }, _toDisplayString(_unref(langObj)['查看详情']), 9, _hoisted_32))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["data-source", "pagination"])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["locale"]))
}
}

})