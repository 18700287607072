import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, isRef as _isRef } from "vue"
import _imports_0 from '../../assets/facIcon1.png'
import _imports_1 from '@/assets/facMark.png'
import _imports_2 from '@/assets/map.png'


const _hoisted_1 = { class: "theme-common" }
const _hoisted_2 = { class: "w-full flex items-center justify-center" }
const _hoisted_3 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_4 = {
  class: "box mt-4",
  style: {"width":"100%"}
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 1,
  class: "flex w-full p-3 mt-3"
}
const _hoisted_7 = {
  class: "w-64 p-2 overflow-y-auto scroll-box h-screen code-box",
  style: {"height":"75vh"}
}
const _hoisted_8 = { class: "w-full mt-2" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "ml-2" }
const _hoisted_11 = { class: "flex-1 ml-3" }
const _hoisted_12 = { class: "w-full code-box flex" }
const _hoisted_13 = { class: "flex-1 ml-4" }
const _hoisted_14 = {
  class: "code-box mt-3",
  style: {"padding":"0"}
}
const _hoisted_15 = {
  class: "w-full",
  style: {"border-bottom":"1px solid #495985"}
}
const _hoisted_16 = {
  class: "flex items-center px-5 flex-wrap",
  style: {"font-size":"18px"}
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "w-full mt-3 px-5" }
const _hoisted_19 = {
  key: 0,
  class: "flex"
}
const _hoisted_20 = { style: {"width":"80%","display":"flex","justify-content":"center","position":"relative"} }
const _hoisted_21 = ["src"]
const _hoisted_22 = {
  style: {"width":"10%"},
  class: "flex"
}
const _hoisted_23 = {
  key: 1,
  class: "flex"
}
const _hoisted_24 = { style: {"width":"80%","display":"flex","justify-content":"center","position":"relative"} }
const _hoisted_25 = {
  key: 0,
  id: "yulan",
  style: {"margin":"auto","padding":"20px","width":"1000px","height":"800px","position":"relative"}
}
const _hoisted_26 = ["src"]
const _hoisted_27 = {
  class: "w-full",
  style: {"padding":"5px 10px"}
}
const _hoisted_28 = { class: "w-full flex" }
const _hoisted_29 = { style: {"font-size":"18px"} }
const _hoisted_30 = {
  style: {"width":"10%"},
  class: "flex"
}
const _hoisted_31 = { class: "w-full mb-2" }
const _hoisted_32 = ["onClick"]
const _hoisted_33 = { class: "flex" }
const _hoisted_34 = { class: "gh3d" }
const _hoisted_35 = {
  id: "repart",
  class: "lipButton"
}
const _hoisted_36 = {
  class: "ml-4",
  style: {"color":"#fff"}
}
const _hoisted_37 = { class: "w-full flex" }
const _hoisted_38 = { style: {"position":"relative","width":"1000px"} }
const _hoisted_39 = ["src"]
const _hoisted_40 = {
  class: "flex-1 ml-20",
  style: {"color":"#fff"}
}
const _hoisted_41 = { class: "ml-2" }
const _hoisted_42 = { class: "ml-2" }
const _hoisted_43 = { style: {"color":"#fff"} }
const _hoisted_44 = { class: "w-full flex" }
const _hoisted_45 = {
  class: "text-center w-full",
  style: {"font-size":"24px"}
}
const _hoisted_46 = { class: "tips" }
const _hoisted_47 = { class: "main" }
const _hoisted_48 = {
  key: 0,
  id: "container",
  style: {"border":"1px solid #aaa","padding":"20px","background-color":"rgb(2, 15, 70)","width":"1000px","height":"800px"},
  class: "theme-factory-3d"
}
const _hoisted_49 = ["src"]
const _hoisted_50 = { class: "gh3d" }
const _hoisted_51 = {
  id: "repart",
  class: "lipButton"
}
const _hoisted_52 = { class: "m_r" }
const _hoisted_53 = { class: "list" }
const _hoisted_54 = { class: "lleft" }
const _hoisted_55 = { class: "lright" }
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "w-full flex justify-center mt-5" }

import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList,getMemberOnboarding } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { langList } from "../../common/lang";
import MapImage from "@/assets/map.png";
import { highlightStep,getPopover } from "../../common/registdriver";

export default /*@__PURE__*/_defineComponent({
  __name: 'factory',
  setup(__props) {

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()
const columns:any = [
    {
        title: langObj.value["上级工厂"],
        dataIndex: "parentName",
        align: "center"
    },
    {
        title: langObj.value["工厂名称"],
        dataIndex: "factoryName",
        align: "center"
    },
    {
        title: langObj.value["区域"],
        dataIndex: "groups",
        align: "center"
    },
    {
        title: langObj.value["操作"],
        dataIndex: "act",
        align: "center"
    },
]

let dataList:any = ref([])
let visible = ref(false)
let form:any = ref({
    groups: [],
    parentId: null,
    factoryName: '',
    position: [null, null],
})

let menuSelect = ref(1)

onMounted(async () => {
    await init();
    //新注册用户，未经过引导的需弹窗进行引导
    if(!getMemberOnboarding()){
        selelctMenu(2);
        if(dataList.value.length>0)
        {
            await selectFactory(dataList.value[0]._id);
            highlightStep(getPopover(".editfactory","1/9 编辑工厂","点击编辑工厂，可编辑工厂信息，可新增修改车间区域。","编辑工厂","left","center",nextRoute,editfactory));
        }
    }
})

const init = async () => {
    await getListData();
}

const selelctMenu = (ev:any) => {
    menuSelect.value = ev
    init()
}

const getListData = async() => {
    let config: any = {
        params: {

        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/factories', config)

    if(result && result.data) {
        dataList.value = result.data
        dataList.value.map((d:any) => {
            if(d.parentId) {
                d.parentName = dataList.value.find((p:any) => p._id === d.parentId)?.factoryName
            }
        })
    }
}

// 新增
const add = () => {
    form.value = {
        groups: [],
        parentId: null,
        factoryName: '',
        position: [],
    }
    facFileList.value = []
    visible.value = true
}

// 编辑
const edit = async(ev:any) => {
    let data = JSON.parse(JSON.stringify(ev))
    facFileList.value = []

    let config: any = {
        params: {
            factoryId: data._id
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let reslut = await request.get('/api/factories/info', config)
 
    if(reslut && reslut.data && reslut.data.picture) {
        reslut.data.picture.type = ''
        facFileList.value = [reslut.data.picture]
        data.position = reslut.data.position
    } 
    
    if(!data.position) {
        data.position = []
    } 
    form.value = data
    visible.value = true
}

const addGroup = () => {
    form.value.groups.push({
        name: '',
        sonID: new Date().getTime().toString()
    })
}

const deleteGroup = (index:any) => {
    form.value.groups.splice(index, 1)
}

const submit = async() => {
    let submit = JSON.parse(JSON.stringify(form.value))
    if(!submit.position) {
        submit.position = null
    } 

    for(let group of submit.groups) {
        if(!group.name) {
            message.warning(langObj.value['请填写区域名称'])
            return
        }
    }

    if(submit._id && submit.parentId && submit._id === submit.parentId) {
        message.warning(langObj.value['集团不能为自身工厂'])
        return
    }

    if(!submit.factoryName) {
        message.warning(langObj.value['请填写工厂名称'])
        return
    }

    if(facFileList.value?.length > 0) {
        submit.picture = facFileList.value[0].url
    }

    if(!submit._id) {
        submit.platform = 2
    }

    let result = await request.post('/api/factories', submit)
    if(result && result.status == 200) {
        message.success(langObj.value['操作成功']+'!')
        visible.value = false
        getListData()
    } else {
        message.warning(langObj.value['操作失败']+'!')
    }

    //如果是新用户引导时，编辑工厂信息，需自动给工厂管理员赋区域权限
    if(editf){
        editf = false;
        editfindex = "auto";
        let roleentity = {
            groupIds:[],
        }
        submit.groups.map(p=> roleentity.groupIds = roleentity.groupIds.concat(p.sonID));
        await request.post('/api/roles/group', roleentity);
        if(dataList.value.length>0)
        {
            await selectFactory(dataList.value[0]._id);
        }
    }
}

// 台账显示
const filterKeyWord = ref('')
let factory_id = ref('')
let factory_info:any = ref({})
let tagSelect:any = ref('1')
let taglist:any = ref([])
let localeList:any = ref([])    //坐标列表

const selectFactory = async(ev:any) => {
    localeList.value = []
    facPic.value = ''
    taglist.value = []
    factory_id.value = ev
    let config: any = {
        params: {
            factoryId: ev
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let reslut = await request.get('/api/factories/info', config)
    if(reslut?.data) {
        factory_info.value = reslut.data
        factory_info.value._id = factory_info.value.id
        delete factory_info.value.id
        factory_info.value.parentName = dataList.value.find((p:any) => p._id === factory_info.value.parentId)?.factoryName
        if(factory_info.value?.groups?.length > 0) {
            taglist.value =  taglist.value.concat(factory_info.value.groups.map((d:any) => ({label: d.name, value: d.sonID})))
        }
        if(reslut.data.picture) {
            reslut.data.picture.type = ''
            facFileList.value = [reslut.data.picture]
        }
    }
    tagSelect.value = '1'
    getFactory3D()
}

// 选择工厂区域
const selectTag = (ev:any) => {
    tagSelect.value = ev
    if(ev !== '1') {
        selectGroup()
    }
}

// 工厂图片
let facFileList:any = ref([])
let facPic:any = ref('')

// 处理工厂3d图
const getFactory3D = () => {
    if(factory_info.value?.picture) {
        facPic.value = factory_info.value.picture?.url

        if(factory_info.value?.position?.length > 0) {
            let list:any = []
            setTimeout(() => {
                factory_info.value.position.map((d:any) => {
                    let left = 0
                    let right = 0

                    let div:any = document.getElementById('facImg');
                    let width = div.offsetWidth || 0;
                    let height = div.offsetHeight || 0;
                    left = width * (d[0] / 100) - 25
                    right = height * (d[1] / 100) - 25
                    list.push([left, right])
                }) 

                localeList.value = list
            }, 500);
        }
    } else {
        facPic.value = MapImage
        if(factory_info.value?.position?.length > 0) {
            let list:any = []
            setTimeout(() => {
                factory_info.value.position.map((d:any) => {
                    let left = 0
                    let right = 0

                    let div:any = document.getElementById('facImg');
                    let width = div.offsetWidth || 0;
                    let height = div.offsetHeight || 0;
                    left = width * (d[0] / 100) - 25
                    right = height * (d[1] / 100) - 25
                    list.push([left, right])
                }) 

                localeList.value = list
            }, 500);
        }
    }
}


let customRequest = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
  }
};

let customRequest2 = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    facPic2.value = res.data
    onSuccess({ url: file.url, status: "done" });
  }
};


// 编辑地点
let ELvisible = ref(false)
let facPic2 = ref('')
const editLocation = () => {
    facPic2.value = ''
    positionELList.value = []
    if(facFileList.value && facFileList.value.length) {
        facPic2.value = facFileList.value[0].url
        ELvisible.value = true
        getEL()
    } else {
        ELvisible.value = true
        getEL()
    }
}

const getEL = () => {
    if(factory_info.value.position?.length > 0) {
        setTimeout(() => {
            let div:any = document.getElementById('myDiv');

            positionELList.value = factory_info.value.position.map((d:any) => {
                let width = div.offsetWidth || 0;
                let height = div.offsetHeight || 0;
                let ELX = width * (d[0] / 100) - 25
                let ELY = height * (d[1] / 100) - 25
                return {
                    ELY,
                    ELX,
                    x: d[0],
                    y: d[1],
                }
            })

        }, 500);
    }
}

let ELX:any = ref(0)
let ELY:any = ref(0)
let positionELList:any = ref([])
const clickEL = () => {
    let event:any = window.event
    ELX.value = event.layerX - 25
    ELY.value = event.layerY - 25
    let div:any = document.getElementById('myDiv');
    let width = div.offsetWidth || 0;
    let height = div.offsetHeight || 0;
    let x = (event.layerX / width) * 100
    let y = (event.layerY / height) * 100
    positionELList.value.push({
        ELX: event.layerX - 25,
        ELY: event.layerY - 25,
        x: Number(x.toFixed(0)),
        y: Number(y.toFixed(0)),
    })
}

const deleteEL = (index:any) => {
    positionELList.value.splice(index, 1)
}

const subEL = async() => {
    form.value = factory_info.value
    form.value.position = positionELList.value.map((d:any) => ([d.x, d.y]))

    
    let submit = JSON.parse(JSON.stringify(form.value))
    delete submit.parentName
    if(facFileList.value?.length > 0) {
        submit.picture = facFileList.value[0].url
    }

    let subForm = {
        position: submit.position,
        picture: submit.picture,
        _id: submit._id,
    }

    let result = await request.post('/api/factories', subForm)
    if(result && result.data) {
        ELvisible.value = false
        selectFactory(submit._id)
    } else {
        message.success(langObj.value['操作失败']+'!')
    }
}





// 区域3d图
let groupInfo:any = ref({})
let groupPic = ref('')
let groupVisible = ref(false)
let config_3d:any = ref({})
let markList:any = ref([])
let machineOptions:any = ref([]);
let machineList:any = ref([])

const selectGroup = () => {
    let groupList = factory_info.value?.groups || []
    groupInfo.value = groupList.find((p:any) => p.sonID === tagSelect.value) || {}
    getMachineList()
}

const getMachineList = async () => {
    machineOptions.value = []
    machineList.value = []
    let config: any = {
        params: {
            factoryId: factory_id.value,
            groupId:tagSelect.value
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/machines", config);
    if(result?.data) { 
        machineList.value = result.data
    } 
    machineOptions.value = [
        {
            label: "区域-标点",
            value: "a-区域",
        },
        {
            label: "区域-标签",
            value: "b-区域",
        }
    ]

    load3D(1)
};



const editGroup3D = () => {
    markList.value = []
    groupVisible.value = true
    load3D(2)
}

let line:any = ref([]);
const load3D = async(type:any) => {
    config_3d.value = { marks: [] };
    
    let config: any = {
        params: {
            groupId: groupInfo.value.sonID,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/group-3D", config);
    if (result?.data) {
        config_3d.value = result.data

        markList.value = config_3d.value.marks;
    }


    if (type == 2) {
        setTimeout(() => {
        $("#container").ZoomMark({
            markColor: "red",
            afterMark: addRowToTabel,
            showMarkNumber: false,
            markList: [],
        });
        setTimeout(() => {
            if (config_3d.value.img) {
            $("#container").ZoomMark("config", config_3d.value.img);
            }

            setTimeout(() => {
            if (config_3d.value.marks.length) {
                $("#container").ZoomMark("addMarks", config_3d.value.marks);
            }
            }, 100);
        }, 100);

        $("#reset").click(function () {
            $("#container").ZoomMark("reset");
        });
        }, 100);
    } else {
        line.value = [];
        for (var i = 0; i < config_3d.value.marks.length; i++) {
            var item = config_3d.value.marks[i];
            if (item.bind.indexOf("a-") > -1) {
                var find = config_3d.value.marks.find(
                (t:any) =>
                    t.bind.substring(2, t.bind.length) ==
                    item.bind.substring(2, item.bind.length) &&
                    t.bind.indexOf("b-") > -1
                );
                if (find) {
                    var distance = Math.sqrt(
                        Math.pow(find.x - item.x, 2) + Math.pow(find.y - item.y, 2)
                    );
                    var angle_d =
                        Math.atan2(find.y - item.y, find.x - item.x) * (180 / Math.PI);
                    line.value.push({
                        ...item,
                        distance: distance,
                        angle: angle_d,
                    });
                }
            }
        }
        load3DData()
    }
}

const load3DData = () => {
    machineList.value.map((d:any) => {
        if(d.workStatus && d.workStatus.stableCondition) {
            d.condition = d.workStatus.stableCondition
        } else {
            d.condition = 0
        }
    })
    
}


let customRequest3D = async (options: any) => {
  const { onSuccess, onError, file } = options;
  let formData = new FormData();
  formData.append("file", file); // file为要上传的文件
  const config = {
    headers: {
      requestId: uuidv4(),
      "Content-Type": "multipart/form-data",
    },
  };
  let res = await request.post("/api/upload", formData, config);
  if (res) {
    file.url = res.data;
    onSuccess({ url: file.url, status: "done" });
    groupVisible.value = false;
    // config_3d.value.picture = res.data.url;
    config_3d.value = {
      id: config_3d.value?.id,
      picture: res.data,
      marks: [],
    };
    markList.value = [];
    setTimeout(() => {
        groupVisible.value = true;
        setTimeout(() => {
            $("#container").ZoomMark({
            markColor: "red",
            afterMark: addRowToTabel,
            showMarkNumber: false,
            markList: [],
            });
        }, 800);
    }, 200);
  }
};

const deleteTag = (item: any, index: any) => {
  markList.value.splice(index);
  $("#container").ZoomMark("deleteMark", index);
};

function addRowToTabel(marks:any) {
  for (var i = 0; i < marks.length; i++) {
    if (!markList.value.filter((t:any) => t.id == marks[i].id).length) {
      markList.value.push({ ...marks[i], bind: "" });
    }
  }
  // $('#marksTable').find('tbody').append('<tr id="row_'+marks[marks.length-1].id+'"><td><span class="colorSpan"style="background-color:'+marks[marks.length-1].color+'">'+($('#marksTable').find('tbody').find('tr').length+1)+'</span></td><td >'+marks[marks.length-1].color+'</td><td><textarea name="a" style="width:200px;height:60px;"></textarea></td><td><img src="example/assets/delete.png" onclick="deleteMark('+marks[marks.length-1].id+')"></td></tr>');
}

const save3D = async() => {
    var setting = $("#container").ZoomMark("getSetting");
    if (markList.value.filter((t:any) => !t.bind).length > 1) {
        message.warning("请设置每个标点内容");
        return;
    }

    markList.value.forEach((t:any) => {
        var find = setting.marks.find((t2:any) => t2.id == t.id);
        t.x = find.x;
        t.y = find.y;
    });
    var postData = {
        id: config_3d.value?.id,
        groupId: groupInfo.value.sonID,
        picture: config_3d.value?.picture,
        marks: markList.value,
        img: setting.img,
    };

    if (postData.id) {
        let result = await request.put("/api/group-3D", postData);
        if (result && result.status == 200) {
            message.success("保存成功");
            groupVisible.value = false;
            load3D(1);
        }
    } else {
        let result = await request.post("/api/group-3D", postData);
        if (result && result.status == 200) {
            message.success("保存成功");
            groupVisible.value = false;
            config_3d.value._id = result.data;
            load3D(1);
        }
    }
}

let conditionList = [
  { label: langObj.value["健康"], value: [0], color: "#61c08f", color2: "#0D53B7", valueR: 1 },
  {
    label: langObj.value["可用"],
    value: [1, 2],
    color: "#d2de49",
    color2: "#d2de49",
    valueR: 2,
  },
  {
    label: langObj.value["警戒"],
    value: [3, 4, 5],
    color: "#cd5f3b",
    color2: "#cd5f3b",
    valueR: 3,
  },
  {
    label: langObj.value["故障"],
    value: [6, 7],
    color: "#921e37",
    color2: "#921e37",
    valueR: 4,
  },
];

const getColor = (ev: any) => {
  let a = conditionList.find((p: any) => p.value.includes(ev));
  return a ? a.color2 : "#072499";
};

const getColor2 = (ev: any) => {
  let a: any = conditionList.find((p: any) => p.value.includes(ev));
  return a?.color;
};
const getShadow = (ev: any) => {
  // box-shadow: inset 0 0 34px 0 #00249b;
  let a = conditionList.find((p: any) => p.value.includes(ev));
  let c = a ? a.color2 : "#072499";

  return "inset 0 0 34px 0 " + c;
};
//新用户引导编辑工厂按钮回调
let editf = ref(false);
let editfindex = ref("auto");
let dropdownClassName = ref("");
const editfactory = async()=>{
    editf = true;
    editfindex = 1000000001;
    dropdownClassName = "dropdownStyleDriver";
    await edit(factory_info.value);
};
//新用户引导编辑工厂下一步按钮回调
const nextRoute = ()=>{
    highlightStep(getPopover(".editfactory3D","2/9 地图标记","选择工厂后点击编辑按钮，可在图上标记工厂位置。选择区域后点击编辑按钮，可上传车间图并标记车间内设备状态。","标记地图","left","center",nextRouteRole,editfactory3D));
};
//新用户引导编辑地图标点按钮回调
const editfactory3D = ()=>{
    editf = true;
    editfindex = 1000000001;
    editLocation();
};
const router = useRouter();
//新用户引导编辑地图标点下一步按钮回调
const nextRouteRole = ()=>{
    router.push("/system/role");
    return;
}

return (_ctx: any,_cache: any) => {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_edit_outlined = _resolveComponent("edit-outlined")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_input_search = _resolveComponent("a-input-search")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_close_square_outlined = _resolveComponent("close-square-outlined")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_SwapOutlined = _resolveComponent("SwapOutlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_close_outlined = _resolveComponent("close-outlined")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["btn1 theme_equipment_btn", _unref(menuSelect)===1?'theme_equipment_btn_select':'']),
        style: _normalizeStyle({'background': _unref(menuSelect) === 1 ? '#072499' : ''}),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (selelctMenu(1)))
      }, _toDisplayString(_unref(langObj)['列表显示']), 7),
      _createElementVNode("div", {
        class: _normalizeClass(["btn1 theme_equipment_btn", _unref(menuSelect)===2?'theme_equipment_btn_select':'']),
        style: _normalizeStyle({'background': _unref(menuSelect) === 2 ? '#072499' : ''}),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (selelctMenu(2)))
      }, _toDisplayString(_unref(langObj)['台账显示']), 7)
    ]),
    (_unref(menuSelect) === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_a_button, {
            type: "primary",
            class: "plft",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (add()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_plus_outlined),
              _createTextVNode(_toDisplayString(_unref(langObj)['新增']), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_table, {
              bordered: "",
              columns: columns,
              dataSource: _unref(dataList),
              pagination: false,
              size: "small"
            }, {
              bodyCell: _withCtx(({ column, text, record }) => [
                (column.dataIndex === 'groups')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(text, (tag) => {
                        return (_openBlock(), _createBlock(_component_a_tag, {
                          key: tag,
                          color: 'volcano'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tag.name), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (column.dataIndex === 'act')
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 1,
                      type: "primary",
                      class: "plft",
                      onClick: ($event: any) => (edit(record))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_edit_outlined),
                        _createTextVNode(_toDisplayString(_unref(langObj)['编辑']), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["dataSource"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_unref(menuSelect) === 2)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_a_input_search, {
              value: filterKeyWord.value,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((filterKeyWord).value = $event)),
              style: {"margin-bottom":"8px"},
              placeholder: _unref(langObj)['搜索']
            }, null, 8, ["value", "placeholder"]),
            _createElementVNode("div", {
              onClick: _cache[4] || (_cache[4] = ($event: any) => (add())),
              class: "w-full text-center py-1 cursor-pointer theme-font-color theme-btn-bg theme-btn-bg",
              style: {"background-color":"#072499"}
            }, _toDisplayString(_unref(langObj)['新建工厂']), 1),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["w-full flex items-center cursor-pointer facbox", item._id == _unref(factory_id) ?'facbox-select':'']),
                  key: index,
                  onClick: ($event: any) => (selectFactory(item._id)),
                  style: _normalizeStyle({'background': item._id == _unref(factory_id) ? '#3137AF' : ''})
                }, [
                  _cache[20] || (_cache[20] = _createElementVNode("img", {
                    style: {"width":"16px"},
                    src: _imports_0,
                    alt: "",
                    class: "theme-white-black-img"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_10, _toDisplayString(item.factoryName), 1)
                ], 14, _hoisted_9))
              }), 128))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_a_descriptions, {
                  layout: "vertical",
                  column: 4,
                  contentStyle: { color: '#fff' },
                  labelStyle: { color: '#7E8BA9' }
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_descriptions_item, {
                      label: _unref(langObj)['工厂名称']
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(factory_info).factoryName), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_descriptions_item, {
                      label: _unref(langObj)['集团名称']
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(factory_info).parentName), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_a_descriptions_item, {
                      label: _unref(langObj)['区域']
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(factory_info).groups, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            style: {"border":"1px solid #00D2D6","padding":"1px 3px"},
                            class: _normalizeClass(["theme-factory-tag", {'ml-2': index != 0}])
                          }, _toDisplayString(item.name), 3))
                        }), 128))
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              (_unref(factory_info)?._id)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (edit(_unref(factory_info)))),
                    class: "mt-4 px-5 py-1 fff cursor-pointer theme-btn-bg editfactory",
                    style: {"border":"1px solid #0d53b7cc","background":"#072499","height":"33px","color":"#fff","display":"inline-block"}
                  }, _toDisplayString(_unref(langObj)['编辑工厂']), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["py-3 cursor-pointer", { 'txt-select theme-factory-txt-select': _unref(tagSelect) === '1' }]),
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (selectTag('1')))
                  }, _toDisplayString(_unref(langObj)['工厂图']), 3),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(taglist), (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(["py-3 cursor-pointer ml-24", { 'txt-select theme-factory-txt-select': _unref(tagSelect) === item.value }]),
                      onClick: ($event: any) => (selectTag(item.value))
                    }, _toDisplayString(item.label), 11, _hoisted_17))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                (_unref(tagSelect) === '1')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _cache[23] || (_cache[23] = _createElementVNode("div", { style: {"width":"10%"} }, null, -1)),
                      _createElementVNode("div", _hoisted_20, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(localeList), (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            style: _normalizeStyle([{"position":"absolute"}, {'left': item[0] + 'px', 'top': item[1]+'px'}])
                          }, _cache[21] || (_cache[21] = [
                            _createElementVNode("img", {
                              style: {"width":"50px"},
                              src: _imports_1,
                              alt: ""
                            }, null, -1)
                          ]), 4))
                        }), 128)),
                        _createElementVNode("img", {
                          id: "facImg",
                          style: {"width":"100%"},
                          src: _unref(facPic),
                          alt: ""
                        }, null, 8, _hoisted_21)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                        (_unref(factory_info)?._id)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (editLocation())),
                              class: "mt-4 px-5 py-1 fff cursor-pointer theme-btn-bg editfactory3D",
                              style: {"border":"1px solid #0d53b7cc","background":"#072499","height":"33px","color":"#fff","display":"inline-block"}
                            }, _toDisplayString(_unref(langObj)['编辑']), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_unref(tagSelect) !== '1')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                      _cache[26] || (_cache[26] = _createElementVNode("div", { style: {"width":"10%"} }, null, -1)),
                      _createElementVNode("div", _hoisted_24, [
                        (_unref(config_3d) && _unref(config_3d).img)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                              _createElementVNode("img", {
                                src: _unref(config_3d).picture,
                                style: _normalizeStyle([{"position":"absolute"}, {
                                    width: _unref(config_3d).img.width + 'px',
                                    height: _unref(config_3d).img.height + 'px',
                                    top: _unref(config_3d).img.y + 'px',
                                    left: _unref(config_3d).img.x + 'px',
                                    transform: 'rotate(' + _unref(config_3d).img.rotate + 'deg)',
                                    }])
                              }, null, 12, _hoisted_26),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d).marks, (item, index) => {
                                return (_openBlock(), _createElementBlock("div", { key: index }, [
                                  (item.bind.indexOf('a-') > -1)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "d3_point",
                                        style: _normalizeStyle({ top: item.y + 'px', left: item.x + 'px' })
                                      }, null, 4))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config_3d).marks, (item, index) => {
                                return (_openBlock(), _createElementBlock("div", { key: index }, [
                                  (item.bind.indexOf('b-') > -1)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "d3_bq",
                                        style: _normalizeStyle([{
                                        top: item.y - 50 + 'px',
                                        left: item.x - 100 + 'px',
                                        'border-color': getColor(0),
                                    }, {"width":"220px","height":"auto"}])
                                      }, [
                                        _createElementVNode("div", _hoisted_27, [
                                          _createElementVNode("div", _hoisted_28, [
                                            _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(groupInfo)?.name), 1)
                                          ]),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(machineList), (item, index) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              key: index,
                                              class: "w-full flex items-center"
                                            }, [
                                              _createElementVNode("div", null, _toDisplayString(item?.machineName), 1),
                                              _cache[24] || (_cache[24] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                                              _createElementVNode("div", {
                                                style: _normalizeStyle([{"width":"16px","height":"16px","border-radius":"50%","min-width":"16px"}, {'background': getColor2(item.condition)}])
                                              }, null, 4)
                                            ]))
                                          }), 128))
                                        ])
                                      ], 4))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(line), (item, index) => {
                                return (_openBlock(), _createElementBlock("div", { key: index }, [
                                  _createElementVNode("div", {
                                    class: "d3_line",
                                    style: _normalizeStyle({
                                        top: item.y + 5 + 'px',
                                        left: item.x + 5 + 'px',
                                        width: item.distance + 'px',
                                        transform: 'rotate(' + item.angle + 'deg)',
                                    })
                                  }, null, 4)
                                ]))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_30, [
                        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
                        _createElementVNode("div", {
                          onClick: _cache[8] || (_cache[8] = ($event: any) => (editGroup3D())),
                          class: "mt-4 px-5 py-1 fff cursor-pointer theme-btn-bg",
                          style: {"border":"1px solid #0d53b7cc","background":"#072499","height":"33px","color":"#fff","display":"inline-block"}
                        }, _toDisplayString(_unref(langObj)['编辑']), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_modal, {
      okText: _unref(langObj)['确认'],
      cancelText: _unref(langObj)['取消'],
      width: 850,
      maskClosable: false,
      visible: _unref(visible),
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => (_isRef(visible) ? (visible).value = $event : visible = $event)),
      title: _unref(form)._id? _unref(langObj)['编辑'] : _unref(langObj)['新增'],
      onOk: submit,
      "z-index": _unref(editfindex),
      "onUpdate:zIndex": _cache[13] || (_cache[13] = ($event: any) => (_isRef(editfindex) ? (editfindex).value = $event : editfindex = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _unref(form),
          ref: "formStateRef",
          name: "basic",
          autocomplete: "off",
          "label-col": { style: { width: '150px' } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: _unref(langObj)['集团'],
              name: "parentId"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_select, {
                  allowClear: "",
                  ref: "select",
                  value: _unref(form).parentId,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_unref(form).parentId) = $event)),
                  placeholder: _unref(langObj)['请选择集团'],
                  dropdownClassName: _unref(dropdownClassName)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(dataList), (item, index) => {
                      return (_openBlock(), _createBlock(_component_a_select_option, {
                        key: index,
                        value: item._id
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.factoryName), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value", "placeholder", "dropdownClassName"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _unref(langObj)['工厂名称'],
              name: "factoryName",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_input, {
                  value: _unref(form).factoryName,
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_unref(form).factoryName) = $event)),
                  placeholder: _unref(langObj)['请输入工厂名称']
                }, null, 8, ["value", "placeholder"])
              ]),
              _: 1
            }, 8, ["label"]),
            _createVNode(_component_a_form_item, {
              label: _unref(langObj)['区域'],
              name: "groups"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_31, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(form).groups, (group, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "flex items-center mt-2"
                    }, [
                      _createVNode(_component_a_input, {
                        value: group.name,
                        "onUpdate:value": ($event: any) => ((group.name) = $event),
                        placeholder: _unref(langObj)['请输入车间名称']
                      }, null, 8, ["value", "onUpdate:value", "placeholder"]),
                      _cache[27] || (_cache[27] = _createElementVNode("div", {
                        class: "mx-4",
                        style: {"color":"#fff"}
                      }, "-", -1)),
                      _createVNode(_component_a_input, {
                        value: group.sonID,
                        "onUpdate:value": ($event: any) => ((group.sonID) = $event),
                        placeholder: "",
                        readonly: ""
                      }, null, 8, ["value", "onUpdate:value"]),
                      _createElementVNode("div", {
                        class: "cursor-pointer ml-4",
                        onClick: ($event: any) => (deleteGroup(index))
                      }, [
                        _createVNode(_component_close_square_outlined, { style: {"color":"#fff","font-size":"22px"} })
                      ], 8, _hoisted_32)
                    ]))
                  }), 128))
                ]),
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (addGroup()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_plus_outlined),
                    _createTextVNode(_toDisplayString(_unref(langObj)['新增']), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["okText", "cancelText", "visible", "title", "z-index"]),
    _createVNode(_component_a_modal, {
      okText: _unref(langObj)['确认'],
      cancelText: _unref(langObj)['取消'],
      width: 1500,
      maskClosable: false,
      visible: _unref(ELvisible),
      "onUpdate:visible": _cache[15] || (_cache[15] = ($event: any) => (_isRef(ELvisible) ? (ELvisible).value = $event : ELvisible = $event)),
      title: _unref(langObj)['工厂图'],
      onOk: subEL,
      "z-index": _unref(editfindex),
      "onUpdate:zIndex": _cache[16] || (_cache[16] = ($event: any) => (_isRef(editfindex) ? (editfindex).value = $event : editfindex = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("span", _hoisted_34, [
            _createVNode(_component_a_upload, {
              "file-list": _unref(facFileList),
              "onUpdate:fileList": _cache[14] || (_cache[14] = ($event: any) => (_isRef(facFileList) ? (facFileList).value = $event : facFileList = $event)),
              "custom-request": _unref(customRequest2),
              "auto-upload": false,
              accept: "image",
              "max-count": 1
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_35, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_SwapOutlined, { style: {"font-size":"22px"} })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_unref(langObj)['更换工厂图']), 1)
                ])
              ]),
              _: 1
            }, 8, ["file-list", "custom-request"])
          ]),
          _createElementVNode("div", _hoisted_36, "(*" + _toDisplayString(_unref(langObj)['左键添加标记']) + ")", 1)
        ]),
        _createElementVNode("div", _hoisted_37, [
          _createElementVNode("div", _hoisted_38, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(positionELList), (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                style: _normalizeStyle([{"position":"absolute"}, {'left': item.ELX + 'px', 'top': item.ELY+'px'}])
              }, _cache[28] || (_cache[28] = [
                _createElementVNode("img", {
                  style: {"width":"50px"},
                  src: _imports_1,
                  alt: ""
                }, null, -1)
              ]), 4))
            }), 128)),
            (_unref(facPic2))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  id: "myDiv",
                  onClick: clickEL,
                  style: {"width":"100%"},
                  src: _unref(facPic2),
                  alt: ""
                }, null, 8, _hoisted_39))
              : _createCommentVNode("", true),
            (!_unref(facPic2))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  id: "myDiv",
                  onClick: clickEL,
                  style: {"width":"100%"},
                  src: _imports_2,
                  alt: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_40, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(positionELList), (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "flex items-center mt-2",
                key: index
              }, [
                _createElementVNode("div", null, _toDisplayString(index + 1), 1),
                _createElementVNode("div", _hoisted_41, _toDisplayString(_unref(langObj)['坐标']) + ":", 1),
                _createElementVNode("div", _hoisted_42, "(" + _toDisplayString(item.x) + ", " + _toDisplayString(item.y) + ")", 1),
                _createVNode(_component_delete_outlined, {
                  onClick: ($event: any) => (deleteEL(index)),
                  class: "ml-4 cursor-pointer"
                }, null, 8, ["onClick"])
              ]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }, 8, ["okText", "cancelText", "visible", "title", "z-index"]),
    _createVNode(_component_a_modal, {
      okText: _unref(langObj)['确认'],
      cancelText: _unref(langObj)['取消'],
      visible: _unref(groupVisible),
      "onUpdate:visible": _cache[19] || (_cache[19] = ($event: any) => (_isRef(groupVisible) ? (groupVisible).value = $event : groupVisible = $event)),
      closable: false,
      footer: null,
      bodyStyle: { background: '#020F46' },
      style: {"top":"18%"},
      width: "1400px",
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_43, [
          _createElementVNode("div", _hoisted_44, [
            _cache[29] || (_cache[29] = _createElementVNode("div", { class: "flex-1" }, null, -1)),
            _createElementVNode("div", {
              class: "cursor-pointer",
              onClick: _cache[17] || (_cache[17] = ($event: any) => (_isRef(groupVisible) //@ts-ignore
 ? groupVisible.value = false : groupVisible = false))
            }, [
              _createVNode(_component_close_outlined, { style: {"font-size":"20px"} })
            ])
          ]),
          _createElementVNode("div", _hoisted_45, _toDisplayString(_unref(groupInfo).name), 1),
          _createElementVNode("div", _hoisted_46, _toDisplayString(_unref(langObj)['左键按住可拖动']) + "，" + _toDisplayString(_unref(langObj)['右键添加标记']), 1),
          _createElementVNode("div", _hoisted_47, [
            (_unref(groupVisible) && _unref(groupVisible))
              ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                  _createElementVNode("img", {
                    src: _unref(config_3d).picture
                  }, null, 8, _hoisted_49),
                  _createElementVNode("span", _hoisted_50, [
                    _createVNode(_component_a_upload, {
                      onPreview: _ctx.handlePreview,
                      "custom-request": _unref(customRequest3D),
                      "auto-upload": false,
                      accept: "image",
                      "max-count": 1
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", _hoisted_51, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_SwapOutlined, { style: {"font-size":"22px"} })
                          ]),
                          _createTextVNode(" " + _toDisplayString(_unref(langObj)['更换3D图']), 1)
                        ])
                      ]),
                      _: 1
                    }, 8, ["onPreview", "custom-request"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(markList), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "item",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_54, _toDisplayString(item.id), 1),
                    _createElementVNode("div", _hoisted_55, [
                      _createVNode(_component_a_select, {
                        value: item.bind,
                        "onUpdate:value": ($event: any) => ((item.bind) = $event),
                        style: {"width":"200px"},
                        options: _unref(machineOptions)
                      }, null, 8, ["value", "onUpdate:value", "options"])
                    ]),
                    (index == _unref(markList).length - 1)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "licon",
                          onClick: ($event: any) => (deleteTag(item, index))
                        }, [
                          _createVNode(_component_DeleteOutlined)
                        ], 8, _hoisted_56))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_57, [
            _createElementVNode("div", {
              onClick: _cache[18] || (_cache[18] = ($event: any) => (_isRef(groupVisible) //@ts-ignore
 ? groupVisible.value = false : groupVisible = false)),
              class: "btn-default py-1 px-6 btn-reset-noradius"
            }, _toDisplayString(_unref(langObj)['关闭']), 1),
            _createElementVNode("div", {
              onClick: save3D,
              class: "btn-default py-1 px-6 mrs btn-submit-noradius"
            }, _toDisplayString(_unref(langObj)['保存']), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["okText", "cancelText", "visible"])
  ]))
}
}

})